import React from "react";

// reactstrap components
import {
    Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Label,
    FormGroup,
    Input, FormText,
    Form, Button,
    UncontrolledTooltip
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import axios from 'axios';
import 'moment/locale/es';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ReactBSAlert from "react-bootstrap-sweetalert";
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ResponsiveBar } from '@nivo/bar'
import SipsOption from "../../components/Sips/SipsOption";

import { GET_COMERCIAL_DETAIL } from "../../queries/comercial/detail";
import { GET_USER_DATA } from "../../queries/users/users.queries";
//import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import { ResponsiveLine } from '@nivo/line'

//import * as echarts from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';

// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
//import ReactECharts from 'echarts-for-react';
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, LineChart, PieChart, LegendComponent, VisualMapComponent, DatasetComponent, CanvasRenderer]
);


echarts.registerTheme('roma', {
    "color": [
        "#3fb1e3",
        "#6be6c1",
        "#626c91",
        "#a0a7e6",
        "#c4ebad",
        "#96dee8"
    ],
    "backgroundColor": "rgba(252,252,252,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#666666"
        },
        "subtextStyle": {
            "color": "#999999"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#e6a0d2",
            "color0": "transparent",
            "borderColor": "#e6a0d2",
            "borderColor0": "#3fb1e3",
            "borderWidth": "2"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": "1",
            "color": "#cccccc"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#3fb1e3",
            "#6be6c1",
            "#626c91",
            "#a0a7e6",
            "#c4ebad",
            "#96dee8"
        ],
        "label": {
            "color": "#ffffff"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999999"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#999999"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#cccccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#cccccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#626c91",
            "width": 1
        },
        "itemStyle": {
            "color": "#626c91",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#626c91",
            "borderColor": "#626c91",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#3fb1e3",
            "borderColor": "#3fb1e3"
        },
        "label": {
            "color": "#626c91"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#626c91"
            },
            "controlStyle": {
                "color": "#626c91",
                "borderColor": "#626c91",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#626c91"
            }
        }
    },
    "visualMap": {
        "color": [
            "#2a99c9",
            "#afe8ff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(255,255,255,0)",
        "dataBackgroundColor": "rgba(222,222,222,1)",
        "fillerColor": "rgba(114,230,212,0.25)",
        "handleColor": "#cccccc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#999999"
        }
    },
    "markPoint": {
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "label": {
                "color": "#ffffff"
            }
        }
    }
});


echarts.registerTheme('macarons', {
    "color": [
        "#2ec7c9",
        "#b6a2de",
        "#5ab1ef",
        "#ffb980",
        "#d87a80",
        "#8d98b3",
        "#e5cf0d",
        "#97b552",
        "#95706d",
        "#dc69aa",
        "#07a2a4",
        "#9a7fd1",
        "#588dd5",
        "#f5994e",
        "#c05050",
        "#59678c",
        "#c9ab00",
        "#7eb00a",
        "#6f5553",
        "#c14089"
    ],
    "backgroundColor": "rgba(0,0,0,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#008acd"
        },
        "subtextStyle": {
            "color": "#aaaaaa"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#d87a80",
            "color0": "#2ec7c9",
            "borderColor": "#d87a80",
            "borderColor0": "#2ec7c9",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaaaaa"
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true,
        "color": [
            "#2ec7c9",
            "#b6a2de",
            "#5ab1ef",
            "#ffb980",
            "#d87a80",
            "#8d98b3",
            "#e5cf0d",
            "#97b552",
            "#95706d",
            "#dc69aa",
            "#07a2a4",
            "#9a7fd1",
            "#588dd5",
            "#f5994e",
            "#c05050",
            "#59678c",
            "#c9ab00",
            "#7eb00a",
            "#6f5553",
            "#c14089"
        ],
        "label": {
            "color": "#eeeeee"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#2ec7c9"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#18a4a6"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#333333"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#008acd",
                "width": "1"
            },
            "crossStyle": {
                "color": "#008acd",
                "width": "1"
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#008acd",
            "width": 1
        },
        "itemStyle": {
            "color": "#008acd",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#008acd",
            "borderColor": "#008acd",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#2ec7c9",
            "borderColor": "#2ec7c9"
        },
        "label": {
            "color": "#008acd"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#a9334c"
            },
            "controlStyle": {
                "color": "#008acd",
                "borderColor": "#008acd",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#008acd"
            }
        }
    },
    "visualMap": {
        "color": [
            "#5ab1ef",
            "#e0ffff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "#efefff",
        "fillerColor": "rgba(182,162,222,0.2)",
        "handleColor": "#008acd",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333333"
        }
    },
    "markPoint": {
        "label": {
            "color": "#eeeeee"
        },
        "emphasis": {
            "label": {
                "color": "#eeeeee"
            }
        }
    }
});

const colors = scaleOrdinal(schemeCategory10).range();

const sortIcon = <ArrowDownward />;

const columnsDatosComision = [
    { selector: row => row.nombreCompleto, name: 'COMERCIAL', sortable: true, width: '200px', format: (row => capitalize(row.nombreCompleto)) },
    { selector: row => row.comercial, name: 'ID', sortable: true, width: '140px', format: (row => capitalize(row.comercial)) },
    { selector: row => row.IdOferta, name: 'ID OFERTA', sortable: true, width: '200px', center: true, format: (row => capitalize(row.IdOferta)) },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '200px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.totalImporteComision, name: 'COMISION PREVISTA', width: '150px', sortable: true, center: true, format: (row => row.totalImporteComision?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) || "0" + " €") },
    { selector: row => row.totalImporteComisionComercial, name: 'COMISION COMERCIAL PREVISTA', width: '150px', sortable: true, center: true, format: (row => row.totalImporteComisionComercial?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) || "0" + " €") },
    { selector: row => row.producto, name: 'PRODUCTO', sortable: true, width: '200px', format: (row => capitalize(row.producto)) },
    { selector: row => row.comercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '200px', format: (row => capitalize(row.comercializadora)) },
    { selector: row => row.nombreTitular, name: 'TITULAR', sortable: true, width: '200px', format: (row => capitalize(row.nombreTitular)) },
    { selector: row => row.tipoCliente, name: 'TIPO CLIENTE', sortable: true, width: '210px', center: true, format: (row => capitalize(row.tipoCliente)) },
    { selector: row => row.fechaPropuesta, name: 'FECHA PROPUESTA', sortable: true, width: '120px', center: true, format: (row => capitalize(row.fechaPropuesta)) },
    { selector: row => row.consumo, name: 'CONSUMO', sortable: true, width: '130px', center: true },
];

const columnsDatosComisionFactura = [

    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '180px', format: (row => capitalize(row.cups)) },
    { selector: row => row.idFactura, name: 'ID FACTURA', sortable: true, width: '150px', center: true, format: (row => capitalize(row.idFactura)) },
    { selector: row => row.fechAlta, name: 'FECHA FACTURA', sortable: true, width: '150px', center: true },
    { selector: row => row.tipolecdescripcion, name: 'LECTURA', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipolecdescripcion)) },
    { selector: row => row.consumoM3, name: 'CONSUMO M3', sortable: true, width: '180px', center: true },
    { selector: row => row.consumokwh, name: 'COMISION kw', width: '180px', sortable: true, center: true },
    { selector: row => row.comision, name: 'COMISION SOBRE CONSUMO', sortable: true, width: '180px', center: true, format: (row => row.comision.toLocaleString("es-ES", { maximumFractionDigits: 2 }) + " €") }
];



const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const format = (data) => {
    //data = parseFloat(data);
    return data.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' });
}


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class ComisionesComercialesAdmin extends Reflux.Component<any, any> {
    state = {

        items: [],
        result: {
            data: [{
                comercial: "",
                comisionContratoConsumoAgente1: 0,
                comisionContratoConsumoAgente2: 0,
                comisionContratoFija: 0,
                comisionYear1: 0,
                comisionYear2: 0,
                consumo: 0,
                cups: "",
                fechaFirmaContrato: "",
                fechaPropuesta: "",
                idContrato: "",
                tipoCliente: ""
            }]
        },
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        page: 1,
        limit: 200,
        UserStore: {},
        companyIdUser: '',
        userName: '',
        companyNameUser: '',
        userGroupId: 1000,
        userId: '',
        idCard: "",
        first_name1: "",
        last_name1: "",
        toggledClearRows: false,
        showIndexButton: false,
        showFreeMarketButton: false,
        isLoading2: false,
        inputDisabled: false,
        comisionDetailTotal: [{
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: "",
            apellido1Titular: "",
            nombreTitular: "",
            nombreCompleto: "",
            totalImporteComision: 0,
            totalImporteComisionComercial: 0,
            IdOferta: "",
            año: 0,
            mes: 1,
            comercializadora: 0,
            producto: 0
        }],
        comisionDetailTotalAprobadas: [],
        comisionDetail: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: "",
            apellido1Titular: "",
            nombreTitular: "",
            nombreCompleto: "",
            totalImporteComision: 0,
            totalImporteComisionComercial: 0,
            IdOferta: "",
            año: 0,
            mes: 1,
            comercializadora: 0,
            producto: 0
        }, comisionDetailAprobadas: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: "",
            apellido1Titular: "",
            nombreTitular: "",
            nombreCompleto: "",
            totalImporteComision: 0,
            totalImporteComisionComercial: 0,
            IdOferta: "",
            año: 0,
            mes: 1,
            comercializadora: 0,
            producto: 0
        },
        comisionFacturaTotal: [{
            consumoM3: 0,
            consumokwh: 0,
            cups: "",
            fechAlta: "",
            idFactura: "",
            tipolecdescripcion: "",
            comision: 0
        }],
        totalFacturas: 0,
        sumTotalComisionFacturas: 0,
        ultimaFactura: {
            consumoM3: 0,
            consumokwh: 0,
            cups: "",
            fechAlta: "",
            idFactura: "",
            tipolecdescripcion: "",
            comision: 0
        },
        comisionSeleccionado: false,
        idContrato: '',
        formComision: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: "",
            apellido1Titular: "",
            nombreTitular: "",
            nombreCompleto: "",
            totalImporteComision: 0,
            totalImporteComisionComercial: 0,
            IdOferta: "",
            año: 0,
            mes: 1,
            comercializadora: 0,
            producto: 0
        },
        formComisionAprobadas: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: "",
            apellido1Titular: "",
            nombreTitular: "",
            nombreCompleto: "",
            totalImporteComision: 0,
            totalImporteComisionComercial: 0,
            IdOferta: "",
            año: 0,
            mes: 1,
            comercializadora: 0,
            producto: 0
        },
        dataResumenContratos: {
            totalAceptados: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            ultimaFecha: ''
        },
        dataResumenContratosChart0: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosChart1: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosFirmados: {
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        },
        dataResumenContratosFirmadosChart: [{
            name: "Contrato Totales",
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        }],
        dataChart1: [{
            Aceptados: 0,
            Anno: 0,
            Baja: 0,
            Contratos: 0,
            Denegados: 0,
            EnEspera: 0,
            Mes: 1,
            Renovacion: 0
        }],
        dataChartContrato1G: [{
            ConsumoAnual: 0,
            ConsumoAnual2: 0,
            Fecha: "",
            Producto: "",
            TarifaPeaje: "B6",
            TotalProducto: 0,
            TotalTarifa: 0,
            TotalTipoEnergia: 0,
            tipoEnergia: "GAS"
        }],
        dataChartContrato1E: [{
            ConsumoAnual: 0,
            ConsumoAnual2: 0,
            Fecha: "",
            Producto: "",
            TarifaPeaje: "B6",
            TotalProducto: 0,
            TotalTarifa: 0,
            TotalTipoEnergia: 0,
            tipoEnergia: "GAS"
        }],
        isLoading: false,
        data: {
            supplier_id: 0,
            num_sites: 0,
            kwhanual: 0,
            supplier_name: "",
            supplier_code_ree: "",
            persona_fisica_total_sites: 0,
            persona_fisica_sumkwhanual: 0,
            persona_juridica_total_sites: 0,
            persona_juridica_sumkwhanual: 0,
        },
        dataAux: [{
            label: "",
            B5: 0
        }],
        datosAgrupadosComisiones: [],
        comisionesAgrupadasPorAño: [],
        datosAgrupadosComisionesGlobal: [],
        datosAgrupadosComisionesAprobadas: [],
        comisionesAgrupadasPorAñoAprobadas: [],
        datosAgrupadosComisionesGlobalAprobadas: [],
        setFilters: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codeComercializadoraGas: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            nombreOferta: ''

        },
        setFiltersAprobadas: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codeComercializadoraGas: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            nombreOferta: ''

        },
        usuariosPropuestas: [{
            email: "SIN DATOS",
            first_name: "SIN DATOS",
            id: 0,
            last_name: "SIN DATOS",
            query_limit: 0,
            user_group_id: 0
        }],
        alert: null,
        comerListDisabled: false,
        comerGasListDisabled: false,
        comerListAprobadasDisabled: false,
        comerGasListAprobadasDisabled: false
    };


    constructor(props) {
        super(props);
        this.store = UserStore;
    }

    componentDidMount() {

        this.getUserData();
        this.fetchItems();

    };

    async getUserData() {

        const resp = await this.props.client.query({
            query: GET_USER_DATA,
        });
        const user = resp.data.userData;

        this.setState({

            first_name1: user.first_name,
            last_name1: user.last_name,
        });

        this.fetchComisiones(user.idCard, user.companyId, user.id, user.user_group_id);

    }

    UNSAFE_componentWillReceiveProps() {


        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.first_name + " " + usuario.last_name
        const idCard = usuario.email

        let showIndexButton = usuario.indexada;
        let showFreeMarketButton = usuario.mercadolibre;
        let companyNameUser = usuario.company;

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            showIndexButton,
            userName,
            showFreeMarketButton,
            companyNameUser,
            idCard

        });
    }

    UNSAFE_componentWillMount() {


        var userFromStorageTemp = localStorage.getItem("user");
        var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
        var userIdTemp = parsedTemp.id;
        var companyIdUserTemp = parsedTemp.companyId;
        var userGroupId = parsedTemp.user_group_id;
        var company = parsedTemp.company;
        var idCard = parsedTemp.email;

        this.setState({

            companyIdUser: companyIdUserTemp,
            userId: userIdTemp,
            userGroupId: userGroupId,
            companyNameUser: company,
            idCard: idCard

        });

        this.usuariosPermitidos();

    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    usuariosPermitidos = async () => {

        const userFromStorage: any = localStorage.getItem("user");
        const parsed = JSON.parse(userFromStorage);

        var companyIdUser = parsed.companyId;
        var user = Number(parsed.id)
        var userQuery = parsed.id
        var user_group_id = parsed.user_group_id;

        var dataFilters = {
            'id': userQuery,
            'user_group_id': user_group_id,
        };

        if (user_group_id >= 400) {
            dataFilters = {
                'id': null,
                'user_group_id': user_group_id,
            };

        }

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/usuario/usuariospermitidos?id=" + companyIdUser + "&user=" + user;
        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result) {
                    let data = result.data
                    this.setState({ usuariosPropuestas: data });
                }
            }).catch((error) => {
                console.log('Failure')
            });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////




    submit = () => {

        if (this.state.setFilters.fechaInicio && !this.state.setFilters.fechaFin || !this.state.setFilters.fechaInicio && this.state.setFilters.fechaFin) {
            this.warningAlert();
        } else {
            this.QueryPropuestas();
        }
    };



    submitAprobadas = () => {

        if (this.state.setFiltersAprobadas.fechaInicio && !this.state.setFiltersAprobadas.fechaFin || !this.state.setFiltersAprobadas.fechaInicio && this.state.setFiltersAprobadas.fechaFin) {
            this.warningAlert();
        } else {
            this.QueryPropuestasAprobadas();
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en las fechas"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Debe poner rango de búsqueda en los campos de fechas. Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertNoData = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    No existen registros en la base de datos que cumplan los criterios de búsqueda.
                </ReactBSAlert>
            )
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    export = async () => {

        var productValuesArrays = this.state.comisionDetailTotal.map(comisionDetailTotal => Object.values(comisionDetailTotal));
        productValuesArrays.unshift(Object.keys(this.state.comisionDetailTotal[0]));
        const csvContent = "data:text/csv;charset=utf-8,"
            + productValuesArrays.map(row => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);

    };

    exportToExcel = async (exceldata, filename) => {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(exceldata);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, filename + fileExtension);

    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////

    QueryPropuestas = async () => {

        let isLoading = true;

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = this.state.idCard
        var nombre = ""
        var apellidos = ""

        if (this.state.userGroupId == 1000) {
            CIF = ""
        }

        var codeComercializadora = ''
        var datos = this.state.setFilters


        if (!datos.codeComercializadora && datos.codeComercializadoraGas) {
            codeComercializadora = datos.codeComercializadoraGas

        } else if (!datos.codeComercializadoraGas && datos.codeComercializadora) {
            codeComercializadora = datos.codeComercializadora

        }

        var dataFilters = {
            'idCompany': "",
            'NIFComercial': CIF,
            'fechaPropuestaDesde': datos.fechaInicio,
            'fechaPropuestaHasta': datos.fechaFin,
            'idComercializadora': datos.codeComercializadora,
            'nombreOferta': datos.nombreOferta,
            'Usuario': datos.comercial
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listado/admin?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    let datosComisionesComercial: any = []

                    for (var i = 0, iLen = result.data.rows.length; i < iLen; i++) {
                        let totalImporteComisionEnergia = Number(result.data.rows[i].comisionTEP1) + result.data.rows[i].comisionTEP2 + result.data.rows[i].comisionTEP3 + result.data.rows[i].comisionTEP4 + result.data.rows[i].comisionTEP5 + result.data.rows[i].comisionTEP6
                        let totalImporteComisionPotencia = result.data.rows[i].comisionTPP1 + result.data.rows[i].comisionTPP2 + result.data.rows[i].comisionTPP3 + result.data.rows[i].comisionTPP4 + result.data.rows[i].comisionTPP5 + result.data.rows[i].comisionTPP6
                        let totalImporteComision = totalImporteComisionEnergia + totalImporteComisionPotencia
                        let totalImporteComisionEnergiaComercial = Number(result.data.rows[i].comisionAgenteTEP1) + result.data.rows[i].comisionAgenteTEP2 + result.data.rows[i].comisionAgenteTEP3 + result.data.rows[i].comisionAgenteTEP4 + result.data.rows[i].comisionAgenteTEP5 + result.data.rows[i].comisionAgenteTEP6
                        let totalImporteComisionPotenciaComercial = result.data.rows[i].comisionAgenteTPP1 + result.data.rows[i].comisionAgenteTPP2 + result.data.rows[i].comisionAgenteTPP3 + result.data.rows[i].comisionAgenteTPP4 + result.data.rows[i].comisionAgenteTPP5 + result.data.rows[i].comisionAgenteTPP6
                        let totalImporteComisionComercial = totalImporteComisionEnergiaComercial + totalImporteComisionPotenciaComercial
                        datosComisionesComercial.push({ ...result.data.rows[i], totalImporteComisionEnergia: totalImporteComisionEnergia, totalImporteComisionPotencia: totalImporteComisionPotencia, totalImporteComision: totalImporteComision, totalImporteComisionComercial: totalImporteComisionComercial, totalImporteComisionEnergiaComercial: totalImporteComisionEnergiaComercial, totalImporteComisionPotenciaComercial: totalImporteComisionPotenciaComercial })
                    }

                    const datosAgrupadosComisiones = [...datosComisionesComercial.reduce((r, o) => {
                        const key = o.Anno + '-' + o.Mes;

                        const item = r.get(key) || Object.assign({}, o, {
                            totalImporteComision: 0,
                            totalImporteComisionComercial: 0,
                            propuestasComisionadas: 0
                        });

                        item.totalImporteComision += o.totalImporteComision;
                        item.totalImporteComisionComercial += o.totalImporteComisionComercial;
                        item.propuestasComisionadas += 1;

                        return r.set(key, item);
                    }, new Map).values()];

                    var datosAgrupadosComisiones2 = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, y: e.totalImporteComision ? e.totalImporteComision : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].y += e.totalImporteComision;
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2Ordenados = datosAgrupadosComisiones2.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);
                    
                    var datosAgrupadosComisiones2Global = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, comisionEmpresa: e.totalImporteComision ? e.totalImporteComision : 0, comisionComercial: e.totalImporteComisionComercial ? e.totalImporteComisionComercial : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].comisionEmpresa += e.totalImporteComision;
                            r[key].comisionComercial += e.totalImporteComisionComercial
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2OrdenadosGlobal = datosAgrupadosComisiones2Global.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);

                    var groupKey = 0;
                    var groups: any = datosAgrupadosComisiones2Ordenados.reduce(function (r: any, o: any) {
                        var m = o.anno;
                        (r[m]) ? r[m].data.push(o) : r[m] = { id: String(m), data: [o] };
                        return r;
                    }, {});

                    var comisionesAgrupadasPorAño: any = Object.keys(groups).map(function (k) { return groups[k]; });

                    this.setState({
                        comisionDetailTotal: datosComisionesComercial,
                        datosAgrupadosComisiones: datosAgrupadosComisiones2Ordenados,
                        datosAgrupadosComisionesGlobal: datosAgrupadosComisiones2OrdenadosGlobal,
                        comisionesAgrupadasPorAño: comisionesAgrupadasPorAño
                    });
                } else {
                    console.log('Sin datos')
                    this.setState({
                        comisionDetailTotal: [],
                        datosAgrupadosComisiones: [],
                        datosAgrupadosComisionesGlobal: [],
                        comisionesAgrupadasPorAño: []
                    });
                    this.warningAlertNoData()
                };
            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    QueryPropuestasAprobadas = async () => {

        let isLoading = true;

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = this.state.idCard
        var nombre = ""
        var apellidos = ""

        if (this.state.userGroupId == 1000) {
            CIF = ""
        }
        var codeComercializadora = ''
        var datos = this.state.setFilters

        if (!datos.codeComercializadora && datos.codeComercializadoraGas) {
            codeComercializadora = datos.codeComercializadoraGas

        } else if (!datos.codeComercializadoraGas && datos.codeComercializadora) {
            codeComercializadora = datos.codeComercializadora
        }

        var dataFilters = {
            'idCompany': "",
            'NIFComercial': CIF,
            'fechaPropuestaDesde': datos.fechaInicio,
            'fechaPropuestaHasta': datos.fechaFin,
            'idComercializadora': datos.codeComercializadora,
            'nombreOferta': datos.nombreOferta,
            'Usuario': datos.comercial
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listado/aprobadasadmin?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    //this.state.result.data

                    let datosComisionesComercial: any = []

                    for (var i = 0, iLen = result.data.rows.length; i < iLen; i++) {

                        let totalImporteComisionEnergia = Number(result.data.rows[i].comisionTEP1) + result.data.rows[i].comisionTEP2 + result.data.rows[i].comisionTEP3 + result.data.rows[i].comisionTEP4 + result.data.rows[i].comisionTEP5 + result.data.rows[i].comisionTEP6
                        let totalImporteComisionPotencia = result.data.rows[i].comisionTPP1 + result.data.rows[i].comisionTPP2 + result.data.rows[i].comisionTPP3 + result.data.rows[i].comisionTPP4 + result.data.rows[i].comisionTPP5 + result.data.rows[i].comisionTPP6
                        let totalImporteComision = totalImporteComisionEnergia + totalImporteComisionPotencia
                        let totalImporteComisionEnergiaComercial = Number(result.data.rows[i].comisionAgenteTEP1) + result.data.rows[i].comisionAgenteTEP2 + result.data.rows[i].comisionAgenteTEP3 + result.data.rows[i].comisionAgenteTEP4 + result.data.rows[i].comisionAgenteTEP5 + result.data.rows[i].comisionAgenteTEP6
                        let totalImporteComisionPotenciaComercial = result.data.rows[i].comisionAgenteTPP1 + result.data.rows[i].comisionAgenteTPP2 + result.data.rows[i].comisionAgenteTPP3 + result.data.rows[i].comisionAgenteTPP4 + result.data.rows[i].comisionAgenteTPP5 + result.data.rows[i].comisionAgenteTPP6
                        let totalImporteComisionComercial = totalImporteComisionEnergiaComercial + totalImporteComisionPotenciaComercial

                        datosComisionesComercial.push({ ...result.data.rows[i], totalImporteComisionEnergia: totalImporteComisionEnergia, totalImporteComisionPotencia: totalImporteComisionPotencia, totalImporteComision: totalImporteComision, totalImporteComisionComercial: totalImporteComisionComercial, totalImporteComisionEnergiaComercial: totalImporteComisionEnergiaComercial, totalImporteComisionPotenciaComercial: totalImporteComisionPotenciaComercial })
                    }

                    const datosAgrupadosComisiones = [...datosComisionesComercial.reduce((r, o) => {
                        const key = o.Anno + '-' + o.Mes;

                        const item = r.get(key) || Object.assign({}, o, {
                            totalImporteComision: 0,
                            totalImporteComisionComercial: 0,
                            propuestasComisionadas: 0
                        });

                        item.totalImporteComision += o.totalImporteComision;
                        item.totalImporteComisionComercial += o.totalImporteComisionComercial;
                        item.propuestasComisionadas += 1;

                        return r.set(key, item);
                    }, new Map).values()];

                    var datosAgrupadosComisiones2 = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, y: e.totalImporteComision ? e.totalImporteComision : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].y += e.totalImporteComision;
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2Ordenados = datosAgrupadosComisiones2.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);

                    var datosAgrupadosComisiones2Global = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, comisionEmpresa: e.totalImporteComision ? e.totalImporteComision : 0, comisionComercial: e.totalImporteComisionComercial ? e.totalImporteComisionComercial : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].comisionEmpresa += e.totalImporteComision;
                            r[key].comisionComercial += e.totalImporteComisionComercial
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2OrdenadosGlobal = datosAgrupadosComisiones2Global.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);

                    var groupKey = 0;
                    var groups: any = datosAgrupadosComisiones2Ordenados.reduce(function (r: any, o: any) {
                        var m = o.anno;
                        (r[m]) ? r[m].data.push(o) : r[m] = { id: String(m), data: [o] };
                        return r;
                    }, {});

                    var comisionesAgrupadasPorAño: any = Object.keys(groups).map(function (k) { return groups[k]; });

                    this.setState({
                        comisionDetailTotalAprobadas: datosComisionesComercial,
                        datosAgrupadosComisionesAprobadas: datosAgrupadosComisiones2Ordenados,
                        datosAgrupadosComisionesGlobalAprobadas: datosAgrupadosComisiones2OrdenadosGlobal,
                        comisionesAgrupadasPorAñoAprobadas: comisionesAgrupadasPorAño
                    });
                } else {
                    console.log('Sin datos')

                    this.setState({
                        comisionDetailTotalAprobadas: [],
                        datosAgrupadosComisionesAprobadas: [],
                        datosAgrupadosComisionesGlobalAprobadas: [],
                        comisionesAgrupadasPorAñoAprobadas: []
                    });
                    this.warningAlertNoData()
                };
            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });


    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {

        const name = event.target.name;
        let value = event.target.value;
        var setFilters = this.state.setFilters;

        setFilters[name] = value;

        if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codegas: '',
                comerListDisabled: false
            })
        } else if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codegas: value,
                comerListDisabled: true
            })
        }

        if (name == 'codeComercializadora' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codeele: '',
                comerGasListDisabled: false
            })
        } else if (name == 'codeComercializadora' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codeele: value,
                comerGasListDisabled: true
            })
        }

        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
            value = ""
        }

        if (value === "0" || value === null) {
            //delete setFilters[name];
            value = ""
        }

        setFilters[name] = value;
        this.setState({ setFilters });
    };


    onChangeFieldAprobadas = (event) => {

        const name = event.target.name;
        let value = event.target.value;
        var setFiltersAprobadas = this.state.setFiltersAprobadas;

        setFiltersAprobadas[name] = value;

        if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codegasApobadas: '',
                comerListAprobadasDisabled: false
            })
        } else if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codegasAprobadas: value,
                comerListAprobadasDisabled: true
            })
        }

        if (name == 'codeComercializadora' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codeeleAprobadas: '',
                comerGasListAprobadasDisabled: false
            })
        } else if (name == 'codeComercializadora' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codeeleAprobadas: value,
                comerGasListAprobadasDisabled: true
            })
        }

        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
            value = ""
        }

        if (value === "0" || value === null) {
            //delete setFilters[name];
            value = ""
        }

        setFiltersAprobadas[name] = value;
        this.setState({ setFiltersAprobadas });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {
        let isLoading = true;
        this.setState({ isLoading });

        const sips = await this.props.client.query({
            query: GET_COMERCIAL_DETAIL,
        });
        const data = sips.data.getComercialDetail;

        isLoading = false;
        this.setState({ data, isLoading });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    fetchComisiones = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = idcard1
        var nombre = ""
        var apellidos = ""

        if (userGroupId1 == 1000) {
            CIF = ""
        }

        var dataFilters = {
            'idCompany': "",
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listado/admin?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    //this.state.result.data

                    let datosComisionesComercial: any = []

                    for (var i = 0, iLen = result.data.rows.length; i < iLen; i++) {

                        let totalImporteComisionEnergia = Number(result.data.rows[i].comisionTEP1) + result.data.rows[i].comisionTEP2 + result.data.rows[i].comisionTEP3 + result.data.rows[i].comisionTEP4 + result.data.rows[i].comisionTEP5 + result.data.rows[i].comisionTEP6
                        let totalImporteComisionPotencia = result.data.rows[i].comisionTPP1 + result.data.rows[i].comisionTPP2 + result.data.rows[i].comisionTPP3 + result.data.rows[i].comisionTPP4 + result.data.rows[i].comisionTPP5 + result.data.rows[i].comisionTPP6
                        let totalImporteComision = totalImporteComisionEnergia + totalImporteComisionPotencia
                        let totalImporteComisionEnergiaComercial = Number(result.data.rows[i].comisionAgenteTEP1) + result.data.rows[i].comisionAgenteTEP2 + result.data.rows[i].comisionAgenteTEP3 + result.data.rows[i].comisionAgenteTEP4 + result.data.rows[i].comisionAgenteTEP5 + result.data.rows[i].comisionAgenteTEP6
                        let totalImporteComisionPotenciaComercial = result.data.rows[i].comisionAgenteTPP1 + result.data.rows[i].comisionAgenteTPP2 + result.data.rows[i].comisionAgenteTPP3 + result.data.rows[i].comisionAgenteTPP4 + result.data.rows[i].comisionAgenteTPP5 + result.data.rows[i].comisionAgenteTPP6
                        let totalImporteComisionComercial = totalImporteComisionEnergiaComercial + totalImporteComisionPotenciaComercial

                        datosComisionesComercial.push({ ...result.data.rows[i], totalImporteComisionEnergia: totalImporteComisionEnergia, totalImporteComisionPotencia: totalImporteComisionPotencia, totalImporteComision: totalImporteComision, totalImporteComisionComercial: totalImporteComisionComercial, totalImporteComisionEnergiaComercial: totalImporteComisionEnergiaComercial, totalImporteComisionPotenciaComercial: totalImporteComisionPotenciaComercial })
                    }


                    const datosAgrupadosComisiones = [...datosComisionesComercial.reduce((r, o) => {
                        const key = o.Anno + '-' + o.Mes;

                        const item = r.get(key) || Object.assign({}, o, {
                            totalImporteComision: 0,
                            totalImporteComisionComercial: 0,
                            propuestasComisionadas: 0
                        });

                        item.totalImporteComision += o.totalImporteComision;
                        item.totalImporteComisionComercial += o.totalImporteComisionComercial;
                        item.propuestasComisionadas += 1;

                        return r.set(key, item);
                    }, new Map).values()];

                    var datosAgrupadosComisiones2 = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, y: e.totalImporteComision ? e.totalImporteComision : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].y += e.totalImporteComision;
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2Ordenados = datosAgrupadosComisiones2.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);
                    var datosAgrupadosComisiones2Global = Object.values(datosComisionesComercial.reduce(function (r, e) {
                        var key = e.Anno + '|' + e.Mes;
                        if (!r[key]) {
                            let titulo = "Enero"
                            if (e.Mes == 1) { titulo = "Enero" } else if (e.Mes == 2) { titulo = "Febrero" } else if (e.Mes == 3) { titulo = "Marzo" } else if (e.Mes == 4) { titulo = "Abril" } else if (e.Mes == 5) { titulo = "Mayo" } else if (e.Mes == 6) { titulo = "Junio" } else if (e.Mes == 7) { titulo = "Julio" } else if (e.Mes == 8) { titulo = "Agosto" } else if (e.Mes == 9) { titulo = "Septiembre" } else if (e.Mes == 10) { titulo = "Octubre" } else if (e.Mes == 11) { titulo = "Noviembre" } else if (e.Mes == 12) { titulo = "Diciembre" }
                            let fecha = e.Anno + "-" + titulo
                            let fechaNumerico = e.Anno.toString() + e.Mes.toString().padStart(2, "0")
                            r[key] = { anno: e.Anno, mes: e.Mes, fecha: fecha, fechaNumerico: fechaNumerico, x: titulo, comisionEmpresa: e.totalImporteComision ? e.totalImporteComision : 0, comisionComercial: e.totalImporteComisionComercial ? e.totalImporteComisionComercial : 0, propuestasComisionadas: 1 };
                        } else {
                            r[key].comisionEmpresa += e.totalImporteComision;
                            r[key].comisionComercial += e.totalImporteComisionComercial
                            r[key].propuestasComisionadas += 1
                        }
                        return r;
                    }, {}))

                    let datosAgrupadosComisiones2OrdenadosGlobal = datosAgrupadosComisiones2Global.sort((a: any, b: any) => a.fechaNumerico - b.fechaNumerico);

                    var groupKey = 0;
                    var groups: any = datosAgrupadosComisiones2Ordenados.reduce(function (r: any, o: any) {
                        var m = o.anno;
                        (r[m]) ? r[m].data.push(o) : r[m] = { id: String(m), data: [o] };
                        return r;
                    }, {});

                    var comisionesAgrupadasPorAño: any = Object.keys(groups).map(function (k) { return groups[k]; });

                    this.setState({
                        comisionDetailTotal: datosComisionesComercial,
                        datosAgrupadosComisiones: datosAgrupadosComisiones2Ordenados,
                        datosAgrupadosComisionesGlobal: datosAgrupadosComisiones2OrdenadosGlobal,
                        comisionesAgrupadasPorAño: comisionesAgrupadasPorAño
                    });
                } else {
                    console.log('Sin datos')

                    this.setState({
                        comisionDetailTotal: [],
                        datosAgrupadosComisiones: [],
                        datosAgrupadosComisionesGlobal: [],
                        comisionesAgrupadasPorAño: []
                    });
                };
            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });
    };

    groupBy = async (data, keys) => {

        return Object.values(
            data.reduce((acc, val) => {

                const name = val['Mes']
                const name2 = val['Anno']

                if (acc[name2]) {
                    if (acc[name]) {
                        acc[name].values.push(val.totalImporteComision);
                        acc[name].sum += val.totalImporteComision;
                    }
                } else {
                    acc[name2] = {
                        name2,
                        name,
                        sum: val.totalImporteComision,
                        values: [val.totalImporteComision]
                    };;
                }
                return acc;
            }, {})
        );
    };




    aggregate = async (data: any, quarterly: boolean) => {
        var hash: any = Object.create(null),
            result: any = [];

        let dataAnalisis: any = data

        dataAnalisis.forEach(function (o: any) {

            var period = o.Mes;
            var month = o.Mes

            if (quarterly) {
                period += 'Q' + Math.floor((+month + 2) / 3);
            }
            if (!hash[period]) {
                hash[period] = { period, totalComisiones: 0, totalPropuestas: 0 };
                result.push(hash[period]);
            }
            hash[period].totalComisiones += o.totalImporteComision;
            hash[period].totalPropuestas += 1;
        });

        return result;
    }





    fetchComisionesFacturas = async (idcard1, companyIdUser1, userId1, userGroupId1, cups1) => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = ""
        var nombre = ""
        var apellidos = ""
        var cups = cups1

        var dataFilters = {
            'idCompany': "",
            'cupsFactura': cups,
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listadofacturas?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    let dataNew = []
                    //this.state.result.data

                    data.forEach(value => {
                        let existe = false;
                        // @ts-ignore
                        var comisionCalculo = value.consumoM3 * this.state.comisionDetail.comisionYear1
                        // @ts-ignore
                        value.comision = comisionCalculo
                        //dataNew.push({data, comision:comisionCalculo} )

                    });

                    var mostRecentDate = new Date(Math.max.apply(null, data.map(e => {
                        return new Date(e.fechAlta);
                    })));
                    var mostRecentObject = data.filter(e => {
                        var d = new Date(e.fechAlta);
                        return d.getTime() == mostRecentDate.getTime();
                    })[0];

                    const sumTotalComisionFacturas = data.reduce((accumulator, object) => {
                        return accumulator + object.comision;
                    }, 0);


                    this.setState({
                        comisionFacturaTotal: data,
                        totalFacturas: data.length,
                        ultimaFactura: mostRecentObject,
                        sumTotalComisionFacturas: sumTotalComisionFacturas
                    });

                    /*            comisionFacturaTotal: {
                                    consumoM3: 0,
                                    consumokwh: 0,
                                    cups: "",
                                    fechAlta: "",
                                    idFactura: "",
                                    tipolecdescripcion: "",
                                    comision: 0
                        },

            */

                } else {
                    console.log('Failure')
                    //this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });

    };




    QueryResumenChart = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        let isLoading = true;
        const isSearching = true;
        const companyId = companyIdUser1.toString()
        const userId = '-1'


        var userIdAux = userId1.toString();
        var userQuery = userId1.toString();
        var idCard = idcard1;

        if (userGroupId1 === 1000) {
            userIdAux = "1000"
        } else {
            userIdAux = userId1.toString();
        }

        var userTime = "S"

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/cuadro/chartAceptados?id=" + companyId + "&user=" + userIdAux + "&time=" + userTime + "";


        await axios.get(direccionFile, { headers }).
            then(result => {

                const data = result.data.rows

                this.setState({
                    dataChart1: data
                });
            }).catch((error) => {
                console.log('Failure ', error)
            });

    };
    //////////////////////////////////////////////////////////////////////////////////////



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };


    handleChange = state => {
        // eslint-disable-next-line no-console

        /*    comisionDetail: {
                comercial: "",
                comisionContratoConsumoAgente1: 0,
                comisionContratoConsumoAgente2: 0,
                comisionContratoFija: 0,
                comisionYear1: 0,
                comisionYear2: 0,
                consumo: 0,
                cups: "",
                fechaFirmaContrato: "",
                fechaPropuesta: "",
                idContrato: "",
                tipoCliente: ""
            },
    */

        var comercial = '';

        if (state.selectedCount === 1) {
            var existeComercial = ''
            comercial = state.selectedRows[0].comercial;

            this.setState({ comisionDetail: state.selectedRows[0] });

            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeComercial = state.selectedRows[0].comercial

            if (existeComercial) {

                let data = state.selectedRows[0]


                this.setState({ idContrato: this.state.comisionDetail.idContrato });
                this.setState({

                    formComision: {
                        comercial: state.selectedRows[0].comercial,
                        comisionContratoConsumoAgente1: state.selectedRows[0].comisionContratoConsumoAgente1,
                        comisionContratoConsumoAgente2: state.selectedRows[0].comisionContratoConsumoAgente2,
                        comisionContratoFija: state.selectedRows[0].comisionContratoFija,
                        comisionYear1: state.selectedRows[0].comisionYear1,
                        comisionYear2: state.selectedRows[0].comisionYear2,
                        consumo: state.selectedRows[0].consumo,
                        cups: state.selectedRows[0].cups,
                        fechaFirmaContrato: state.selectedRows[0].fechaFirmaContrato,
                        fechaPropuesta: state.selectedRows[0].fechaPropuesta,
                        idContrato: state.selectedRows[0].idContrato,
                        tipoCliente: state.selectedRows[0].tipoCliente,
                        idOferta: state.selectedRows[0].IdOferta,
                        apellido1Titular: state.selectedRows[0].apellido1Titular,
                        nombreTitular: state.selectedRows[0].nombreTitular,
                        nombreCompleto: state.selectedRows[0].nombreCompleto,
                        totalImporteComision: state.selectedRows[0].totalImporteComision,
                        totalImporteComisionComercial: state.selectedRows[0].totalImporteComisionComercial,
                        año: state.selectedRows[0].Anno,
                        mes: state.selectedRows[0].Mes,
                        comercializadora: state.selectedRows[0].comercializadora,
                        producto: state.selectedRows[0].producto

                    },

                    comisionSeleccionado: true
                });

//this.fetchComisionesFacturas(this.state.idCard, this.state.companyIdUser, this.state.userId, this.state.userGroupId, state.selectedRows[0].cups)

            } else {
                this.setState({
                    comisionSeleccionado: false,
                    formComision: {
                        comercial: "",
                        comisionContratoConsumoAgente1: 0,
                        comisionContratoConsumoAgente2: 0,
                        comisionContratoFija: 0,
                        comisionYear1: 0,
                        comisionYear2: 0,
                        consumo: 0,
                        cups: "",
                        fechaFirmaContrato: "",
                        fechaPropuesta: "",
                        idContrato: "",
                        tipoCliente: "",
                        comercializadora: "",
                        producto: "",
                        apellido1Titular: "",
                        nombreTitular: "",
                        nombreCompleto: "",
                        totalImporteComision: 0,
                        totalImporteComisionComercial: 0,
                        IdOferta: "",
                        año: 0,
                        mes: 1
                    }
                });
            }
        } else {

            this.setState({
                comisionSeleccionado: false,
                inputDisabled: false,
                updateRepresentanteButtonDisabled: true,
                formComision: {
                    comercial: "",
                    comisionContratoConsumoAgente1: 0,
                    comisionContratoConsumoAgente2: 0,
                    comisionContratoFija: 0,
                    comisionYear1: 0,
                    comisionYear2: 0,
                    consumo: 0,
                    cups: "",
                    fechaFirmaContrato: "",
                    comercializadora: "",
                    producto: "",
                    fechaPropuesta: "",
                    idContrato: "",
                    tipoCliente: "",
                    apellido1Titular: "",
                    nombreTitular: "",
                    nombreCompleto: "",
                    totalImporteComision: 0,
                    totalImporteComisionComercial: 0,
                    IdOferta: "",
                    año: 0,
                    mes: 1
                }
            });



        };

    }

    handleChangeAprobadas = state => {
        // eslint-disable-next-line no-console

        /*    comisionDetail: {
                comercial: "",
                comisionContratoConsumoAgente1: 0,
                comisionContratoConsumoAgente2: 0,
                comisionContratoFija: 0,
                comisionYear1: 0,
                comisionYear2: 0,
                consumo: 0,
                cups: "",
                fechaFirmaContrato: "",
                fechaPropuesta: "",
                idContrato: "",
                tipoCliente: ""
            },
    */

        var comercial = '';

        if (state.selectedCount === 1) {
            var existeComercial = ''
            comercial = state.selectedRows[0].comercial;

            this.setState({ comisionDetailAprobadas: state.selectedRows[0] });

            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeComercial = state.selectedRows[0].comercial

            if (existeComercial) {

                let data = state.selectedRows[0]


                this.setState({ idContrato: this.state.comisionDetail.idContrato });
                this.setState({

                    formComisionAprobadas: {
                        comercial: state.selectedRows[0].comercial,
                        comisionContratoConsumoAgente1: state.selectedRows[0].comisionContratoConsumoAgente1,
                        comisionContratoConsumoAgente2: state.selectedRows[0].comisionContratoConsumoAgente2,
                        comisionContratoFija: state.selectedRows[0].comisionContratoFija,
                        comisionYear1: state.selectedRows[0].comisionYear1,
                        comisionYear2: state.selectedRows[0].comisionYear2,
                        consumo: state.selectedRows[0].consumo,
                        cups: state.selectedRows[0].cups,
                        fechaFirmaContrato: state.selectedRows[0].fechaFirmaContrato,
                        fechaPropuesta: state.selectedRows[0].fechaPropuesta,
                        idContrato: state.selectedRows[0].idContrato,
                        tipoCliente: state.selectedRows[0].tipoCliente,
                        idOferta: state.selectedRows[0].IdOferta,
                        apellido1Titular: state.selectedRows[0].apellido1Titular,
                        nombreTitular: state.selectedRows[0].nombreTitular,
                        nombreCompleto: state.selectedRows[0].nombreCompleto,
                        totalImporteComision: state.selectedRows[0].totalImporteComision,
                        totalImporteComisionComercial: state.selectedRows[0].totalImporteComisionComercial,
                        año: state.selectedRows[0].Anno,
                        mes: state.selectedRows[0].Mes,
                        comercializadora: state.selectedRows[0].comercializadora,
                        producto: state.selectedRows[0].producto

                    },

                    comisionSeleccionadoAprobadas: true
                });

                //this.fetchComisionesFacturas(this.state.idCard, this.state.companyIdUser, this.state.userId, this.state.userGroupId, state.selectedRows[0].cups)

            } else {
                this.setState({
                    comisionSeleccionadoAprobadas: false,
                    formComisionAprobadas: {
                        comercial: "",
                        comisionContratoConsumoAgente1: 0,
                        comisionContratoConsumoAgente2: 0,
                        comisionContratoFija: 0,
                        comisionYear1: 0,
                        comisionYear2: 0,
                        consumo: 0,
                        cups: "",
                        fechaFirmaContrato: "",
                        fechaPropuesta: "",
                        idContrato: "",
                        tipoCliente: "",
                        comercializadora: "",
                        producto: "",
                        apellido1Titular: "",
                        nombreTitular: "",
                        nombreCompleto: "",
                        totalImporteComision: 0,
                        totalImporteComisionComercial: 0,
                        IdOferta: "",
                        año: 0,
                        mes: 1
                    }
                });
            }
        } else {

            this.setState({
                comisionSeleccionadoAprobadas: false,
                inputDisabled: false,
                updateRepresentanteButtonDisabled: true,
                formComisionAprobadas: {
                    comercial: "",
                    comisionContratoConsumoAgente1: 0,
                    comisionContratoConsumoAgente2: 0,
                    comisionContratoFija: 0,
                    comisionYear1: 0,
                    comisionYear2: 0,
                    consumo: 0,
                    cups: "",
                    fechaFirmaContrato: "",
                    comercializadora: "",
                    producto: "",
                    fechaPropuesta: "",
                    idContrato: "",
                    tipoCliente: "",
                    apellido1Titular: "",
                    nombreTitular: "",
                    nombreCompleto: "",
                    totalImporteComision: 0,
                    totalImporteComisionComercial: 0,
                    IdOferta: "",
                    año: 0,
                    mes: 1
                }
            });



        };

    }


    onChangeFieldComision = (event) => {

    };

    ///////////////////////////////////////////////////////////////////////////////////////////



    render() {

        var datosAux: any = [];
        var datosAuxE: any = [];
        const formComision: any = this.state.formComision;

        const formComisionAprobadas: any = this.state.formComisionAprobadas

        const datosAgrupadosComisiones: any = this.state.datosAgrupadosComisiones

        const styleFullWith = { "width": "100%" };

        const dataComisionesAnuales: any = this.state.comisionesAgrupadasPorAño

        const dataComisionesAnualesAprobadas: any = this.state.comisionesAgrupadasPorAñoAprobadas


        this.state.comisionDetailTotal.forEach(value => {
            let existe = false;
            if (datosAux.length > 0) {
                datosAux.forEach(value2 => {


                    // @ts-ignore
                    //if( value.Fecha === value2.Fecha && value.TarifaPeaje === value2.TarifaPeaje) {
                    if (value.nombreCompleto === value2.name) {

                        // @ts-ignore
                        value2.value = value2.value + value.totalImporteComision;
                        // @ts-ignore

                        value2.contratos = value2.contratos + 1
                        existe = true;
                        return existe;
                    }
                });
                if (!existe) {

                    datosAux.push({
                        // @ts-ignore
                        //date : value.Fecha,
                        // @ts-ignore
                        name: value.nombreCompleto,
                        // @ts-ignore
                        value: value.totalImporteComision,
                        // @ts-ignore
                        contratos: 1
                    })

                }
            } else {

                datosAux.push({
                    // @ts-ignore
                    //date : value.Fecha,
                    // @ts-ignore
                    name: value.nombreCompleto,
                    // @ts-ignore
                    value: value.totalImporteComision,
                    // @ts-ignore
                    contratos: 1
                })

            }
        });

        const showListFull = { "width": "100%" };

        var option4 = {
            title: {
                text: 'Resumen de comisiones/comerciales',
                left: 'left'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%'
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    var val = format(params.value);
                    return val;
                }
            },
            legend: {
                top: 'middle',
                left: 'right',
                orient: 'vertical'
            },
            series: [
                {
                    name: 'Comercial',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: datosAux
                }
            ]
        };

        var option5 = {
            title: {
                text: 'Relación comercial/propuestas/comisión',
                left: 'left'
            },
            dataset: {
                dimensions: ['name', 'value', 'contratos'],
                source: datosAux
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return ` ${params[0].name} <br />
                Comision: ${params[0].data.value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}<br />
                Propuestas: ${params[1].data.contratos.toLocaleString("es-ES", { maximumFractionDigits: 2 }) + "  propuestas"}`;
                }
            },
            grid: {
                left: '15%',
                right: '14%'
            },
            xAxis: {
                type: 'category'
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Comisión €',
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[0]
                        }
                    },
                },
                {
                    type: 'value',
                    name: 'Propuestas Realizadas',
                    position: 'right',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[1]
                        },
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                }],
            series: [
                {
                    name: 'Comisión',
                    type: 'bar',
                    yAxisIndex: 0,
                    barWidth: '60%',
                    encode: {
                        x: 'name',

                    }

                },
                {
                    name: 'Propuestas',
                    type: 'line',
                    yAxisIndex: 1,
                    barWidth: '60%',
                    encode: {
                        y: 'contratos',

                    }

                }
            ]
        };

        if (this.state.userGroupId == 1000) {
            return (
                <>
                    <Header title={'Información Comisiones'} />

                    <Container className=" mt--7" fluid>
                        <Row id="filtros_propuestas_pendientes" className="card-deck mt-5">
                            <Card className="shadow">
                                <CardHeader>

                                    <h3 className="mb-0">{this.state.userGroupId === 1000 ? "Filtrar propuestas" : "Filtrar tus propuestas"}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>

                                            <Col lg="auto">
                                                <Label className="form-control-label" htmlFor="comercial">
                                                    Agente Comercial
                                                </Label>
                                                <Input type="select" defaultValue={'DEFAULT'} name="comercial" id="comercial" onChange={this.onChangeField}>
                                                    <option value=""> --Selecciona un comercial-- </option>
                                                    {this.state.usuariosPropuestas.map((item: any, key) => (
                                                        <option key={key} value={item.id}>{item.last_name} {item.first_name}</option>
                                                    ))
                                                    }
                                                </Input>
                                            </Col>



                                            <Col lg="auto">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="nombreOferta">
                                                        Nombre Propuesta
                                                    </Label>
                                                    <Input type="text" name="nombreOferta" id="nombreOferta"
                                                        placeholder="Nombre de la propuesta..."
                                                        value={this.state.setFilters.nombreOferta}
                                                        onChange={this.onChangeField}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercializadora">
                                                        Comercializadora Electricidad
                                                    </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadora"
                                                        name="codeComercializadora"
                                                        value={this.state.setFilters.codeComercializadora}
                                                        query="LIST_MARKETERS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="ComercializadoraGas"
                                                    >
                                                        Comercializadora Gas
                                                    </Label>
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadoraGas"
                                                        name="codeComercializadoraGas"
                                                        value={this.state.setFilters.codeComercializadoraGas}
                                                        query="LIST_MARKETERS_GAS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerGasListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaInicio">Fecha inicio</Label>
                                                    <Input type="date" name="fechaInicio" id="fechaInicio" placeholder="Fecha inicio"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaInicio}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFin">Fecha fin</Label>
                                                    <Input type="date" name="fechaFin" id="fechaFin" placeholder="Fecha fin"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaFin}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button color="success" size="sm" className="float-right"
                                            onClick={this.submit}
                                        >
                                            <i className={"bi bi-search"} /> Buscar
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Row>

                        <Row >
                            <div className="col m-2 d-flex">
                                <Button color="primary" size="sm" type="button"
                                    className="btn-icon btn-sm btn-circle"
                                    onClick={this.props.history.goBack}
                                >
                                    <span className="bi bi-box-arrow-left"> Volver</span>
                                </Button>

                            </div>
                        </Row>
                        <Card className="shadow">
                            <CardBody>
                                <DataTable
                                    title="LISTADO DE COMISIONES PENDIENTES DE APROBACION"
                                    columns={columnsDatosComision}
                                    data={this.state.comisionDetailTotal}
                                    selectableRows
                                    //selectableRowsComponent={radio} // Pass the function only
                                    selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                    selectableRowsSingle
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChange}
                                    clearSelectedRows={this.state.toggledClearRows}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                    subHeader
                                    subHeaderComponent={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 18 }}
                                                onClick={(e) => this.exportToExcel(this.state.comisionDetailTotal, "ListadoComisionesPendienteAprobacion")}
                                                size="sm"
                                            >
                                                Excel
                                            </Button>
                                        </div>}
                                    progressComponent={<Circular />}
                                />
                            </CardBody>
                        </Card>

                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>

                                        <div>
                                            <CardTitle>DATOS DE LA COMISION PREVISTA</CardTitle>
                                        </div>

                                        <Row>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreCompleto">
                                                        Comercial
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreCompleto"
                                                        placeholder=""
                                                        name="nombreCompleto"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.nombreCompleto}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="idOferta">
                                                        Oferta
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="idOferta"
                                                        placeholder=""
                                                        name="idOferta"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.idOferta}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comercializadora">
                                                        Comercializadora
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="comercializadora"
                                                        placeholder=""
                                                        name="comercializadora"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.comercializadora}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="producto">
                                                        Producto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="producto"
                                                        placeholder=""
                                                        name="producto"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.producto}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="cups">
                                                        CUPS
                                                    </Label>
                                                    <Input
                                                        id="cups"
                                                        placeholder=""
                                                        name="cups"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.cups}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="totalImporteComision">
                                                        Comisión Prevista
                                                    </Label>
                                                    <Input
                                                        id="totalImporteComision"
                                                        placeholder=""
                                                        name="totalImporteComision"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.totalImporteComision?.toLocaleString("es-ES", { style: 'currency', currency: 'EUR' }) || "0" + " €"}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="totalImporteComisionComercial">
                                                        Comisión Comercial Prevista
                                                    </Label>
                                                    <Input
                                                        id="totalImporteComisionComercial"
                                                        placeholder=""
                                                        name="totalImporteComisionComercial"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.totalImporteComisionComercial?.toLocaleString("es-ES", { style: 'currency', currency: 'EUR' }) || "0" + " €"}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreTitular">
                                                        Nombre
                                                    </Label>
                                                    <Input
                                                        id="nombreTitular"
                                                        placeholder=""
                                                        name="nombreTitular"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.nombreTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Titular">
                                                        Apellidos
                                                    </Label>
                                                    <Input
                                                        id="apellido1Titular"
                                                        placeholder=""
                                                        name="apellido1Titular"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.apellido1Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>

                                            { /*   comisionYear1: 0,
                                                    comisionYear2: 0,
                                                    fechaPropuesta: "",

                                                    tipoCliente: "" */}

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                        Fecha Propuesta
                                                    </Label>
                                                    <Input
                                                        id="fechaPropuesta"
                                                        placeholder=""
                                                        name="fechaPropuesta"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.fechaPropuesta}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="consumo">
                                                        Consumo Anual
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="consumo"
                                                        placeholder=""
                                                        name="consumo"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComision.consumo}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Row className="card-deck">
                                    <Card className="card-stats" style={{ height: 400 }}>

                                        <ResponsiveLine
                                            data={dataComisionesAnuales}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{

                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'meses',
                                                legendOffset: 36,
                                                legendPosition: 'middle'
                                            }}
                                            axisLeft={{

                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'comision prevista €',
                                                legendOffset: -40,
                                                legendPosition: 'middle'
                                            }}
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'bottom-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 100,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />





                                    </Card>
                                </Row>

                                <CardTitle className="info-title"><h2>Historial de comisiones</h2></CardTitle>

                                <Row className="card-deck">
                                    <Card className="card-stats" style={{ height: 400 }}>
                                        <ResponsiveBar
                                            data={this.state.datosAgrupadosComisionesGlobal}
                                            keys={[
                                                'comisionEmpresa',
                                                'comisionComercial'
                                            ]}
                                            indexBy="fecha"
                                            groupMode="grouped"
                                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                            padding={0.3}
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            colors={{ scheme: 'nivo' }}
                                            borderColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'fechas',
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'comisión prevista €',
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            valueFormat={value =>
                                                `${Number(value).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })} €`
                                            }
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 120,
                                                    translateY: 0,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }

                                            ]}
                                            role="application"
                                        />
                                    </Card>
                                </Row>


                                <Container fluid>
                                    <Row className="margin-reset w-100">
                                        <Col md="6" xl="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <ReactEChartsCore
                                                        echarts={echarts}
                                                        option={option4}
                                                        style={{ height: 300, width: 400 }}
                                                        theme={"macarons"}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <ReactEChartsCore
                                                        echarts={echarts}
                                                        option={option5}
                                                        style={{ height: 300, width: 400 }}
                                                        theme={"roma"}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>


                            </div>
                        </Row>

                    </Container>
                    {/*comercial: "",
                                      comisionContratoConsumoAgente1: 0,
                                      comisionContratoConsumoAgente2: 0,
                                      comisionContratoFija: 0,
                                      comisionYear1: 0,
                                      comisionYear2: 0,
                                      consumo: 0,
                                      cups: "",
                                      fechaFirmaContrato: "",
                                      fechaPropuesta: "",
                                      idContrato: "",
                                      tipoCliente: ""*/}

                    <Container fluid>


                        <Row id="filtros_propuestas_aprobadas" className="card-deck mt-5">
                            <Card className="shadow">
                                <CardHeader>

                                    <h3 className="mb-0">{this.state.userGroupId === 1000 ? "Filtrar propuestas aprobadas" : "Filtrar tus propuestas aprobadas"}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>

                                            <Col lg="auto">
                                                <Label className="form-control-label" htmlFor="comercial">
                                                    Agente Comercial
                                                </Label>
                                                <Input type="select" defaultValue={'DEFAULT'} name="comercial" id="comercial" onChange={this.onChangeFieldAprobadas}>
                                                    <option value=""> --Selecciona un comercial-- </option>
                                                    {this.state.usuariosPropuestas.map((item: any, key) => (
                                                        <option key={key} value={item.id}>{item.last_name} {item.first_name}</option>
                                                    ))
                                                    }
                                                </Input>
                                            </Col>



                                            <Col lg="auto">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="nombreOferta">
                                                        Nombre Propuesta
                                                    </Label>
                                                    <Input type="text" name="nombreOferta" id="nombreOferta"
                                                        placeholder="Nombre de la propuesta..."
                                                        value={this.state.setFiltersAprobadas.nombreOferta}
                                                        onChange={this.onChangeFieldAprobadas}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercializadora">
                                                        Comercializadora Electricidad
                                                    </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadora"
                                                        name="codeComercializadora"
                                                        value={this.state.setFiltersAprobadas.codeComercializadora}
                                                        query="LIST_MARKETERS"
                                                        onChange={this.onChangeFieldAprobadas}
                                                        disabled={this.state.comerListAprobadasDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="ComercializadoraGas"
                                                    >
                                                        Comercializadora Gas
                                                    </Label>
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadoraGas"
                                                        name="codeComercializadoraGas"
                                                        value={this.state.setFiltersAprobadas.codeComercializadoraGas}
                                                        query="LIST_MARKETERS_GAS"
                                                        onChange={this.onChangeFieldAprobadas}
                                                        disabled={this.state.comerGasListAprobadasDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaInicio">Fecha inicio</Label>
                                                    <Input type="date" name="fechaInicio" id="fechaInicio" placeholder="Fecha inicio"
                                                        onChange={this.onChangeFieldAprobadas}
                                                        value={this.state.setFiltersAprobadas.fechaInicio}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFin">Fecha fin</Label>
                                                    <Input type="date" name="fechaFin" id="fechaFin" placeholder="Fecha fin"
                                                        onChange={this.onChangeFieldAprobadas}
                                                        value={this.state.setFiltersAprobadas.fechaFin}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button color="success" size="sm" className="float-right"
                                            onClick={this.submitAprobadas}
                                        >
                                            <i className={"bi bi-search"} /> Buscar
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Row>


                        <Card className="shadow">
                            <CardBody>
                                <DataTable
                                    title="LISTADO DE COMISIONES DE PROPUESTAS APROBADAS"
                                    columns={columnsDatosComision}
                                    data={this.state.comisionDetailTotalAprobadas}
                                    selectableRows
                                    //selectableRowsComponent={radio} // Pass the function only
                                    selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                    selectableRowsSingle
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChangeAprobadas}
                                    clearSelectedRows={this.state.toggledClearRows}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                    subHeader
                                    subHeaderComponent={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 18 }}
                                                onClick={(e) => this.exportToExcel(this.state.comisionDetailTotalAprobadas, "ListadoComisionesConAprobacion")}
                                                size="sm"
                                            >
                                                Excel
                                            </Button>
                                        </div>}
                                    progressComponent={<Circular />}
                                />
                            </CardBody>
                        </Card>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>

                                        <div>
                                            <CardTitle>DATOS DE LA COMISION PREVISTA DE LA PROPUESTA APROBADA</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreCompletoAprobada">
                                                        Comercial
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreCompleto"
                                                        placeholder=""
                                                        name="nombreCompleto"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.nombreCompleto}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="idOfertaAprobada">
                                                        Oferta
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="idOferta"
                                                        placeholder=""
                                                        name="idOferta"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.idOferta}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comercializadoraAprobada">
                                                        Comercializadora
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="comercializadora"
                                                        placeholder=""
                                                        name="comercializadora"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.comercializadora}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="productoAprobada">
                                                        Producto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="producto"
                                                        placeholder=""
                                                        name="producto"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.producto}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="cupsAprobada">
                                                        CUPS
                                                    </Label>
                                                    <Input
                                                        id="cups"
                                                        placeholder=""
                                                        name="cups"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.cups}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="totalImporteComisionAprobada">
                                                        Comisión Prevista
                                                    </Label>
                                                    <Input
                                                        id="totalImporteComision"
                                                        placeholder=""
                                                        name="totalImporteComision"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.totalImporteComision?.toLocaleString("es-ES", { style: 'currency', currency: 'EUR' }) || "0" + " €"}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="totalImporteComisionComercialAprobada">
                                                        Comisión Comercial Prevista
                                                    </Label>
                                                    <Input
                                                        id="totalImporteComisionComercial"
                                                        placeholder=""
                                                        name="totalImporteComisionComercial"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.totalImporteComisionComercial?.toLocaleString("es-ES", { style: 'currency', currency: 'EUR' }) || "0" + " €"}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreTitularAprobada">
                                                        Nombre
                                                    </Label>
                                                    <Input
                                                        id="nombreTitular"
                                                        placeholder=""
                                                        name="nombreTitular"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.nombreTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1TitularAprobada">
                                                        Apellidos
                                                    </Label>
                                                    <Input
                                                        id="apellido1Titular"
                                                        placeholder=""
                                                        name="apellido1Titular"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.apellido1Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>

                                            { /*   comisionYear1: 0,
                                                    comisionYear2: 0,
                                                    fechaPropuesta: "",

                                                    tipoCliente: "" */}

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPropuestaAprobada">
                                                        Fecha Propuesta
                                                    </Label>
                                                    <Input
                                                        id="fechaPropuesta"
                                                        placeholder=""
                                                        name="fechaPropuesta"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.fechaPropuesta}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="consumoAprobada">
                                                        Consumo Anual
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="consumo"
                                                        placeholder=""
                                                        name="consumo"
                                                        onChange={this.onChangeFieldComision}
                                                        value={formComisionAprobadas.consumo}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Row className="card-deck">
                                    <Card className="card-stats" style={{ height: 400 }}>

                                        <ResponsiveLine
                                            data={dataComisionesAnualesAprobadas}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{

                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'meses',
                                                legendOffset: 36,
                                                legendPosition: 'middle'
                                            }}
                                            axisLeft={{

                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'comision prevista €',
                                                legendOffset: -40,
                                                legendPosition: 'middle'
                                            }}
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'bottom-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 100,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />





                                    </Card>
                                </Row>

                                <CardTitle className="info-title"><h2>Historial de comisiones con propuestas aprobadas</h2></CardTitle>

                                <Row className="card-deck">
                                    <Card className="card-stats" style={{ height: 400 }}>
                                        <ResponsiveBar
                                            data={this.state.datosAgrupadosComisionesGlobalAprobadas}
                                            keys={[
                                                'comisionEmpresa',
                                                'comisionComercial'
                                            ]}
                                            indexBy="fecha"
                                            groupMode="grouped"
                                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                            padding={0.3}
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            colors={{ scheme: 'nivo' }}
                                            borderColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'fechas',
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'comisión prevista €',
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            valueFormat={value =>
                                                `${Number(value).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })} €`
                                            }
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 120,
                                                    translateY: 0,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }

                                            ]}
                                            role="application"
                                        />
                                    </Card>
                                </Row>





                            </div>
                        </Row>

                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado"} />
                </>
            );
        }
    }
}

export default withApollo(ComisionesComercialesAdmin);

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
// Apollo
import { withApollo } from "react-apollo";
import { LOGIN } from "../../queries/users/users.queries";
// Utils
import { setToken } from "../../utils";

class Login extends React.Component<any, any> {
  state = {
    token: null,
    email: "",
    password: "",
    type: localStorage.getItem("type"),
    loginError: false,
    networkError: false,
    sessionError: false,
    userBlockedError: false,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.updateEmail = this.updateEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.login = this.login.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  public componentDidMount() {
    document.title = "Gestión Puntos Suministro Electridad/Gas | LOGIN";
  }

  public render() {
    return (
      <>
        <Col lg="5" md="7">
          {this._renderAlert()}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent text-center">
              Acceso
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="Off"
                      onChange={this.updateEmail}
                      onKeyDown={this.keyPress}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Contraseña"
                      type="password"
                      autoComplete="Off"
                      onChange={this.updatePassword}
                      onKeyDown={this.keyPress}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Recordarme</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.state.loading}
                    onClick={(e) => this.login(e)}
                  >
                    Entrar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="12">
              <a className="text-light" href="/auth/recover">
                <small>Olvidaste la contraseña?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      const password = this.state.password;

      let validEmail = false;
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (pattern.test(this.state.email)) {
        validEmail = true;
      }

      if (validEmail && password.length >= 8) {
        this.login(e);
      }
    }
  }

  private _renderAlert() {
    if (this.state.networkError) {
      return <Alert color="danger">Problema de red, intentelo mas tarde.</Alert>;
    }
    if (this.state.loginError) {
      return <Alert color="danger">Email o contraseña incorrecto</Alert>;
    }
    if (this.state.sessionError) {
      return (
        <Alert color="danger">
          Tiene mas de 3 sesiones abiertas, cierrelas o intente mas tarde
        </Alert>
      );
    }
    if (this.state.userBlockedError) {
      return (
        <Alert color="danger">
          Hemos bloqueado su usuario, intente nuevamente más tarde o contacte al
          administrador
        </Alert>
      );
    }
    return null;
  }

  private async login(event) {
    await this.setState({
      loginError: false,
      loading: true,
      sessionError: false,
      userBlockedError: false,
    });
    const email = this.state.email;
    const password = this.state.password;
    try {
      const queryUserResult = await this.props.client.mutate({
        mutation: LOGIN,
        variables: { email, password },
      });
      await this.setState({ loading: false });
      if (queryUserResult.data.login) {
        const token = queryUserResult.data.login;
        setToken(token);
        this.props.history.push(`/admin/index`);
      } else {
        this.setState({
          loading: false,
          loginError: true,
          networkError: false,
          sessionError: false,
          userBlockedError: false
        });
      }
    } catch (e:any) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const error = e.graphQLErrors[0];
        if (error.message === "user blocked") {
          await this.setState({
            loading: false,
            loginError: false,
            networkError: false,
            sessionError: false,
            userBlockedError: true
          });
        } else if (error.message === "no more than three sessions") {
          await this.setState({
            loading: false,
            loginError: false,
            networkError: false,
            sessionError: true,
            userBlockedError: false
          });
        } else {
          await this.setState({
            loading: false,
            loginError: true,
            networkError: false,
            sessionError: false,
            userBlockedError: false
          });
        }
      } else {
        await this.setState({
          loading: false,
          loginError: false,
          networkError: true,
          sessionError: false,
          userBlockedError: false
        });
      }
    }
  }

  private updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  private updatePassword(evt) {
    this.setState({
      password: evt.target.value,
    });
  }
}

export default withApollo(Login);

import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Col, Button,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import {
    CHANGE_APP_LOGO
} from "../../queries/users/users.queries";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore, UserStoreActions } from "../../store/UserStore";

class empresasEnergiaUpdate extends Reflux.Component<any, any> {
    public formRef: any = null;
    public maxFileSize = 3;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        empresaDetail: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: "",
            logoApp: "",
            logoPropuesta: "",
            licencias:1
        },
        formEmpresa: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: "",
            logoApp: "",
            logoPropuesta: "",
            licencias: 1
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataEmpresa: false,
        updateDataEmpresa: false,
        updateEmpresaButtonDisabled: true,
        saveEmpreButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null

    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        //this.onFileChange = this.onFileChange.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    }


       componentDidMount() {

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = 0;
        let userId = '';
        let userName = '';
        let esComercializadora = '';
        let esAgencia = '';
        let esAdFincas = '';
        let activeUser = false



        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);

                if (userGroupId === 1000) {
                    this.setState({
                        activeUser: true
                    })
                };
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;

                if (userGroupId === 1000) {

                    activeUser = true

                };
                userId = parsed.id
                userName = parsed.fullname
                esComercializadora = parsed.comercializadora
                esAgencia = parsed.agencia
                esAdFincas = parsed.adFincas


            } catch (e) {
                console.log(e)
            }
        }

        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName, esComercializadora, activeUser, esAgencia, esAdFincas });

        this.buscarEmpresa(companyIdUser)
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningFicheroGrande = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado supera el lìmite de tamaño"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El fichero seleccionado supera el límite de tamaño
                </ReactBSAlert>
            )
        });
    };


    warningFicheroPermitido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado no permitido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Solo se permiten las siguientes extensiones: jpg, jpeg, png
                </ReactBSAlert>
            )
        });
    };


    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos de la empresas?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa actualizada correctamente
                </ReactBSAlert>
            )
        });
    };

    successAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Logo actualizado correctamente
                </ReactBSAlert>
            )
        });
    };



    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };


    //////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {

            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })

        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: false
            })

        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()

        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        const nameFilters = ['idEmpresa', 'nif', 'tipo', 'tipoServicio', 'numOrden', 'empresa', 'direccion', 'codigoPostal', 'municipio', 'provincia', 'telefonoAtt', 'telefonoUrgencias', 'ambito', 'fechaAlta', 'fechaBaja', 'web', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'fechAltaDatos', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        var municipio = ""

        if (this.state.empresaSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }
            if (event.target.name == 'empresa' && event.target.value.length > 0) {

                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }


            if (event.target.name === 'nif' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'nif' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        saveDataEmpresa: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: false
                    })
                } else {
                    this.setState({
                        NIFClienteError: true,
                        saveDataEmpresa: false,
                        updateEmpresaButtonDisabled: true
                    })
                }
            }


            if (event.target.name === 'correoContacto') {

                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);

                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            };

            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            }

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

            /*    if (name == 'nombreMunicipioPS') {
                    const municipio: any = this.state.dicMunicipios
                    const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                    const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                    formData['nombreProvinciaPS'] = resultado.DescProvincia
                    formData['AutonomiaTitular'] = resultado.DescAutonomia
                }
            */

            formData[name] = value;
            //if (value === "" || value === null) {
            //    formData[name] = 0;
            //
            //}
            this.setState({
                saveDataEmpresa: true
            })
            this.setState({ formEmpresa: formData });


        }


    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            filters: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            passwordModificado: false,
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                licencias: 1,
                logoApp: "",
                logoPropuesta: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataEmpresa: false
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarEmpresa = async (compañia) => {
        this.setState({
            isLoading: true,
        })

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(compañia);


        var dataFilters = {
            'id': companyIdUser
        };

        const valores = JSON.stringify(dataFilters)

        ///// para GET REST //////

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        //let direccionFile = "https://datapi.psgestion.es/cliente/create?id="+this.state.companyIdUser+"&user="+userId;

        let direccionFile = "https://datapi.psgestion.es/empresas/empresadetalle?id=" + companyIdUser + "&user=" + userId;


        await axios.get(direccionFile, { headers, params: { valores } }).
            then((result) => {
                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({
                        empresaDetailTotal: result.data.rows,
                        formEmpresa: {
                            id: result.data.rows[0].id,
                            idEmpresa: result.data.rows[0].idEmpresa,
                            nif: result.data.rows[0].nif,
                            tipo: result.data.rows[0].tipo,
                            tipoServicio: result.data.rows[0].tipoServicio,
                            numOrden: result.data.rows[0].numOrden,
                            empresa: result.data.rows[0].empresa?.toUpperCase() || '',
                            direccion: result.data.rows[0].direccion?.toUpperCase() || '',
                            codigoPostal: result.data.rows[0].codigoPostal,
                            municipio: result.data.rows[0].municipio?.toUpperCase() || '',
                            provincia: result.data.rows[0].provincia?.toUpperCase() || '',
                            telefonoAtt: result.data.rows[0].telefonoAtt,
                            telefonoUrgencias: result.data.rows[0].telefonoUrgencias,
                            ambito: result.data.rows[0].ambito?.toUpperCase() || '',
                            fechaAlta: result.data.rows[0].fechaAlta,
                            fechaBaja: result.data.rows[0].fechaBaja,
                            web: result.data.rows[0].web,
                            estado: result.data.rows[0].estado,
                            correoContacto: result.data.rows[0].correoContacto,
                            nombreContacto: result.data.rows[0].nombreContacto?.toUpperCase() || '',
                            telefonoContacto: result.data.rows[0].telefonoContacto,
                            fechAltaDatos: result.data.rows[0].fechAltaDatos,
                            usuario: result.data.rows[0].usuario,
                            logoApp: result.data.rows[0].logoApp,
                            logoPropuesta: result.data.rows[0].logoPropuesta,
                            licencias: result.data.rows[0].licencias
                        },
                        empresaSeleccionado: true,
                        inputDisabled: false,
                        createdAt: result.data.rows[0].createdAt,
                        isLoading: false
                    });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataEmpresa && (this.state.formEmpresa.empresa && this.state.formEmpresa.nif)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(parsed.companyId);

        if (this.state.saveDataEmpresa == true) {

            var empresaDataUpdate = {
                'id': this.state.formEmpresa.id,
                'idEmpresa': this.state.formEmpresa.idEmpresa,
                'nif': this.state.formEmpresa.nif,
                'tipo': this.state.formEmpresa.tipo,
                'tipoServicio': this.state.formEmpresa.tipoServicio,
                'numOrden': this.state.formEmpresa.numOrden,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'direccion': this.state.formEmpresa.direccion?.toUpperCase() || '',
                'codigoPostal': this.state.formEmpresa.codigoPostal,
                'municipio': this.state.formEmpresa.municipio?.toUpperCase() || '',
                'provincia': this.state.formEmpresa.provincia?.toUpperCase() || '',
                'telefonoAtt': this.state.formEmpresa.telefonoAtt,
                'telefonoUrgencias': this.state.formEmpresa.telefonoUrgencias,
                'ambito': this.state.formEmpresa.ambito?.toUpperCase() || '',
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'fechaBaja': this.state.formEmpresa.fechaBaja,
                'web': this.state.formEmpresa.web,
                'estado': this.state.formEmpresa.estado,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'fechAltaDatos': this.state.formEmpresa.fechAltaDatos,
                'usuario': this.state.formEmpresa.usuario,
                'logoApp': this.state.formEmpresa.logoApp,
                'logoPropuesta': this.state.formEmpresa.logoPropuesta,
                'licencias': this.state.formEmpresa.licencias
            }

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                'Content-Type': 'application/json',
            };

            let direccionFile = "https://datapi.psgestion.es/empresas/empresaupdate?id=" + companyIdUser + "&user=" + userId;


            axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        empresaDetailTotal: [],
                        saveDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        formEmpresa: {
                            id: "",
                            idEmpresa: "",
                            nif: "",
                            tipo: "",
                            tipoServicio: "",
                            numOrden: "",
                            empresa: "",
                            direccion: "",
                            codigoPostal: "",
                            municipio: "",
                            provincia: "",
                            telefonoAtt: "",
                            telefonoUrgencias: "",
                            ambito: "",
                            fechaAlta: "",
                            fechaBaja: "",
                            web: "",
                            estado: "a",
                            correoContacto: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            fechAltaDatos: "",
                            usuario: "",
                            logoApp: "",
                            logoPropuesta: "",
                            licencias:1
                        }

                    });

                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                    });
                });

            await this.buscarEmpresa(companyIdUser);
        };
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    validateFile(file) {
        const extension = file.name.split(".").pop() || "";
        // Get length of file in bytes
        const fileSizeInBytes = file.size;
        // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
        const fileSizeInKB = fileSizeInBytes / 1024;
        // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
        const fileSizeInMB = fileSizeInKB / 1024;
        if (!this.validFileExtensions.includes(extension)) {
            const extensionsAllowed = this.validFileExtensions.join(", ");
            this.warningFicheroPermitido()
            return false;
        }
        if (fileSizeInMB > this.maxFileSize) {
            this.warningFicheroGrande()
            return false;
        }

        return true;

        // onChangeFile(file);
    }


    // On App Logo Change

    onAppLogoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""


            //var user: any = [];

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []


            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/empresa?id=" + this.state.companyIdUser + "&user=" + this.state.userId;


            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoApp'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    };


    onAppLogoChangePropuesta = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []

            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/propuesta?id=" + this.state.companyIdUser + "&user=" + this.state.userId;

            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoPropuesta'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    }
    eventFire = (el, etype) => {
        if (el.fireEvent) {
            el.fireEvent("on" + etype);
        } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };
    onAppLogoButtonClick = () => {
        const input = document.getElementById("label-upload-button");
        this.eventFire(input, "click");
    };

    onAppLogoPropuestaClick= () => {
        const input = document.getElementById("label-upload-propuesta-button");
        this.eventFire(input, "click");
    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    render() {

        var formEmpresa = this.state.formEmpresa
        var previewImage = this.state.formEmpresa.logoApp ? this.state.formEmpresa.logoApp : ""
        var previewImagePropuesta = this.state.formEmpresa.logoPropuesta ? this.state.formEmpresa.logoPropuesta : ""
        return (
            <>
                <Header title={'Información Empresas'} />
                <Container className=" mt--7" fluid>

                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.updateEmpresaButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.saveDataEmpresa) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Actualizar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                            GUARDA LAS MODIFICACIONES REALZIADAS EN LOS DATOS
                                                        </UncontrolledTooltip>
                                                    </Button>


                                                </FormGroup>

                                            </Card>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="id"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="empresa">
                                                    Razón Social
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="empresa"
                                                    placeholder=""
                                                    name="empresa"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.empresa}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numOrden">
                                                    N Orden
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="numOrden"
                                                    placeholder=""
                                                    name="numOrden"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.numOrden}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nif">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="nif"
                                                    placeholder=""
                                                    name="nif"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nif}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="direccion">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    id="direccion"
                                                    placeholder=""
                                                    name="direccion"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.direccion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="codigoPostal">
                                                    C. Postal
                                                </Label>
                                                <Input
                                                    id="codigoPostal"
                                                    placeholder=""
                                                    name="codigoPostal"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.codigoPostal}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="municipio">
                                                    Municipio
                                                </Label>
                                                <Input
                                                    id="municipio"
                                                    placeholder=""
                                                    name="municipio"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.municipio}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="provincia">
                                                    Provincia
                                                </Label>
                                                <Input
                                                    id="provincia"
                                                    placeholder=""
                                                    name="provincia"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.provincia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>



                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreContacto">
                                                    Nombre Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreContacto"
                                                    placeholder=""
                                                    name="nombreContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nombreContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="telefonoContacto">
                                                    Teléfono Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoContacto"
                                                    placeholder=""
                                                    name="telefonoContacto"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.telefonoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="correoContacto">
                                                    Correo Contacto
                                                </Label>
                                                <Input
                                                    id="correoContacto"
                                                    placeholder=""
                                                    name="correoContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.correoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                            </Row>
                                </CardBody>
                            </Card>


                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>LOGOS</CardTitle>
                                    </div>

                                    <Row>

                                        <Col md="3" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipo">Logo Empresa</Label>
                                                {previewImage && (
                                                    <div>
                                                        <img className="preview" src={previewImage} alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button" id="label-upload-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChange}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>

                                            </FormGroup>
                                        </Col>



                                        <Col md="3" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipo">Logo Propuesta</Label>
                                                {previewImagePropuesta && (
                                                    <div>
                                                        <img className="preview" src={previewImagePropuesta} alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button-propuesta" id="label-upload-propuesta-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button-propuesta"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChangePropuesta}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                </Row>
                            </CardBody>
                        </Card>


                        <Card className="shadow">
                            <CardBody>
                                <div>
                                    <CardTitle>OTROS DATOS</CardTitle>
                                </div>

                                <Row>
                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipo">Tipo Empresa</Label>
                                            <Input type="select" name="tipo" id="tipo"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipo}>
                                                <option selected>Selecciona...</option>
                                                <option value="COM"> Comercializadora</option>
                                                <option value="DIS"> Distribuidora</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipoverde">Tipo Servicio</Label>
                                            <Input type="select" name="tipoServicio" id="tipoServicio"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipoServicio}>
                                                <option selected>Selecciona...</option>
                                                <option value="ELEC">Electricidad</option>
                                                <option value="GAS">Gas</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>




                                </Row>
                            </CardBody>
                        </Card>
                        {this.state.alert}

                    </div>
                </Row>
            </Container >

            </>
        );
    }
};
export default withApollo(empresasEnergiaUpdate);

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
} from "reactstrap";
// Apollo
import { withApollo } from "react-apollo";
import { RESET } from "../../queries/users/users.queries";

class Reset extends React.Component<any, any> {
  state = {
    token: '',
    email: "",
    password: "",
    rePassword: "",
    type: localStorage.getItem("type"),
    passReset: false,
    passResetError: false,
    formInvalid: true,
  };

  constructor(props) {
    super(props);

    this.updateEmail = this.updateEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateRePassword = this.updateRePassword.bind(this);
    this.reset = this.reset.bind(this);
  }

  public componentDidMount() {
    document.title = "PsGestion | RESET PASSWORD";

    const queryString = require('query-string');
    const urlParams = queryString.parse(this.props.location.search);
    this.setState({
      token: urlParams.token
    });
  }

  public render() {
    return (
      <>
        <Col lg="5" md="7">
          {this._renderAlert()}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent text-center">
              Recuperar contraseña
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <input type="hidden" name="token" value={this.state.token} />
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={this.updateEmail}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Contraseña"
                      type="password"
                      autoComplete="Off"
                      onChange={this.updatePassword}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Repetir Contraseña"
                      type="password"
                      autoComplete="Off"
                      onChange={this.updateRePassword}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.state.formInvalid}
                    onClick={(e) => this.reset(e)}
                  >
                    Recuperar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }

  private _renderAlert() {
    if (this.state.passReset) {
      return <Alert color="success">Se ha modificado su contraseña.</Alert>;
    }

    if (this.state.passResetError) {
      return <Alert color="danger">{this.state.passResetError}</Alert>;
    }

    return null;
  }

  private async reset(event) {
    await this.setState({
      passReset: false,
      passResetError: false
    });

    const variables = {
      email: this.state.email,
      password: this.state.password,
      token: this.state.token
    };
    try {
      const queryUserResult = await this.props.client.mutate({
        mutation: RESET,
        variables: { data: variables },
      });
      await this.setState({ loading: false });
      if (queryUserResult.data.resetPassword) {
        this.setState({
          passReset: true,
          passResetError: false,
          email: '',
          password: '',
          rePassword: ''
        });
      }
    } catch (e:any) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const error = e.graphQLErrors[0];
        await this.setState({
          passResetError: error.message,
          passReset: false,
          loading: false
        });
      } else {
        await this.setState({
          passResetError: 'Unknow error',
          passReset: false,
          loading: false
        });
      }
    }
  }

  async updateEmail(evt) {
    await this.setState({
      email: evt.target.value,
    });
    this._validateForm();
  }

  async updatePassword(evt) {
    await this.setState({
      password: evt.target.value,
    });
    this._validateForm();
  }

  async updateRePassword(evt) {
    await this.setState({
      rePassword: evt.target.value,
    });
    this._validateForm();
  }

  private _validateForm() {
    const password = this.state.password;
    const rePassword = this.state.rePassword;

    let validEmail = false;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (pattern.test(this.state.email)) {
      validEmail = true;
    }

    if (!validEmail || rePassword.length < 8 || password.length < 8 || password !== rePassword) {
      this.setState({
        formInvalid: true
      });
    } else {
      this.setState({
        formInvalid: false
      });
    }
  }
}

export default withApollo(Reset);

import gql from 'graphql-tag'

export const GET_USER_DATA = gql`
    query userData{
        userData {
            id
            email
            first_name
            last_name
            fullname
            profile_photo
            app_logo
            company
            query_limit
            user_group_id
            companyId
            newCompanyId,
            indexada,
            mercadolibre,
            gas,
            electricidad,
            empresa,
            informes,
            mercadolibregas,
            contratos,
            aprobarContratos,
            verContratos,
            productos,
            comisiones,
            comercial,
            comercializadora,
            agencia,
            adFincas,
            jefeEquipo,
            comision1,
            comision2,
            idCard,
            logoApp,
            logoPropuesta,
            eslogoApp,
            eslogoPropuesta,
            idAlianza
        }
    }`;


export const CHANGE_APP_LOGO = gql`
    mutation changeAppLogo($data: ChangeAppLogoInput!){
        changeAppLogo(data: $data){
            id
            email
            first_name
            last_name
            fullname
            profile_photo
            app_logo
            company
            query_limit
            user_group_id
            companyId
            newCompanyId,
            indexada,
            mercadolibre,
            gas,
            electricidad,
            empresa,
            informes,
            mercadolibregas,
            contratos,
            aprobarContratos,
            verContratos,
            productos,
            comisiones,
            comercial,
            comercializadora,
            agencia,
            adFincas,
            jefeEquipo,
            comision1,
            comision2,
            idCard,
            logoApp,
            logoPropuesta,
            eslogoApp,
            eslogoPropuesta
        }
    }
`;

export const CHANGE_PROPUESTA_LOGO = gql`
    mutation changePropuestaLogo($data: ChangeAppLogoInput!){
        changePropuestaLogo(data: $data){
            id
            email
            first_name
            last_name
            fullname
            profile_photo
            app_logo
            company
            query_limit
            user_group_id
            companyId
            newCompanyId,
            indexada,
            mercadolibre,
            gas,
            electricidad,
            empresa,
            informes,
            mercadolibregas,
            contratos,
            aprobarContratos,
            verContratos,
            productos,
            comisiones,
            comercial,
            comercializadora,
            agencia,
            adFincas,
            jefeEquipo,
            comision1,
            comision2,
            idCard,
            logoApp,
            logoPropuesta,
            eslogoApp,
            eslogoPropuesta
        }
    }
`;

/*export const UPLOAD_FILE_APP = gql`
    mutation uploadFileApp($data: uploadFileInput!){
        uploadFileApp(data: $data){
            id
            email
            first_name
            last_name
            fullname
            profile_photo
            app_logo
            company
        }
    }
`;
*/
export const UPDATE_PROFILE = gql`
    mutation updateProfile($data: RegisterInput!){
        updateProfile(data: $data){
            id
            email
            first_name
            last_name
            fullname
            profile_photo
            app_logo
            company
            query_limit
            user_group_id
            companyId
            newCompanyId,
            indexada,
            mercadolibre,
            gas,
            electricidad,
            empresa,
            informes,
            mercadolibregas,
            contratos,
            aprobarContratos,
            verContratos,
            productos,
            comisiones,
            comercial,
            comercializadora,
            agencia,
            adFincas,
            jefeEquipo,
            comision1,
            comision2,
            idCard,
            logoApp,
            logoPropuesta,
            eslogoApp,
            eslogoPropuesta
        }
    }
`;

export const LOGIN = gql`
    mutation login($email: String!, $password: String!){
        login(email: $email, password: $password)
    }
`;

export const RECOVER = gql`
    mutation recoverPassword($email: String!){
        recoverPassword(email: $email)
    }
`;

export const RESET = gql`
    mutation resetPassword($data: ResetInput!){
        resetPassword(data: $data) {
            id,
            email
        }
    }
`;

export const LOGOUT = gql`
    mutation logout{
        logout{
            session_id
            user_id
            active
        }
    }
`;

export const GET_COMPANY = gql`
    query companyInfo($companyId: String!) {
	    companyInfo(companyId: $companyId){
	        companyId,
	        company
	    }
    }
`;

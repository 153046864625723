import React from "react";
import Reflux from "reflux";
import axios from "axios";
import { customAlphabet } from "nanoid";
import {
  Button,
  Card,
  CardBody,
  Label,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Col,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import { withApollo } from "react-apollo";

import moment from "moment";
import "moment/locale/es";
//import { uuid } from "uuidv4";
//import { uuid } from 'uuidv4';
import { validateSpanishId, spainIdType } from "spain-id";
// core components

import Header from "../../components/Headers/Header";
import { ThunderboltFilled, FireFilled } from "@ant-design/icons";
import propuestaGasUnica from "../../assets/img/icons/custom/propuestaGasUnica.svg";
import propuestaElectricidadUnica from "../../assets/img/icons/custom/propuestaElectricidadUnica.svg";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";
const alphabet = "0123456789ABCD";
const nanoid = customAlphabet(alphabet, 11);

interface IComparativeFreeState {
  cups: string;
  UserStore: any;
  user: any;
  sip: any;
  nombrePropuesta: string;
  ubicacion: any;
  sipsConsumos: any[];
  periodos: number[];
  producto: {
    active: boolean;
    productoSeleccionado: any;
    costeOperativo: number;
    costeOperativoPotencia: number;
    esNuevoContrato: boolean;
  };
  productoMulti: {
    active: boolean;
    productoSeleccionado: any[];
    costeOperativo: number;
    costeOperativoPotencia: number;
    esNuevoContrato: boolean;
  };
  tableHeaders: any[];
  comparatives: any[];
  isDisabled: boolean;
  isDisabledProductos: boolean;
  isLoading: boolean;
  isLoadingConsumos: boolean;
  isSavingPropuesta: boolean;
  itemsSelected: any[];
  openModalComparative: boolean;
  openModalMultiComparative: boolean;
  comercialProductoSelected: any;
  showList: boolean;
  dataComparativeTarget: any;
  datosContrato: {
    Usuario: string;
    fechaPropuesta: string;
    FechaAprobacion: string;
    IdOferta: string;
    T_Titular: {
      RazonSocial: string; //":"MANUEL GONZALEZ",--> nombre_completo_titular
      NIF: string; //"C19345456", --> CIF
      DireccionFiscal: string; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
      CodigoPostalTitular: string; //"08630",----> codigo_postal_titular
      TelefonoFijo: string; //"949201893", ----> telefono_fijo
      TelefonMovil: string; //"689105332", ----> telefono
      Email: string; //"demo@demo.com", --->email_contacto
      TipoCliente: string; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
      ObservacionesCliente: string;
      GDPR: boolean; //"OPCIONAL O NULL",----> observaciones
    };
    T_Representante: {
      nombreRepresentante: string;
      apellido1Representante: string;
      apellido2Representante: string;
      NIFRepresentante: string;
      idRepresentante: string;
      idRepresentanteInterno: number;
      CargoRepresentante: string;
      telefonoRepresentante: string;
      correoRepresentante: string;
    };
    T_PuntoSuministro: {
      Direccion: string; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
      CodigoPostal: string; //"08630", ----> codigo_postal_ps
      Telefono: string; //"689105332", ----> telefono;
      ObservacionesPuntoSuministro: string; // "opcional o null" ----> observaciones_ps
    };
    T_CUPs: {
      Distribuidora: string; //"IBERDROLA", ----> distribuidora
      CUPsEle: string; //"ES0022000008945731EY", ------> cupShort
      Tarifa: string; //"2.0 DHS", ----> tarifaATR
      P1: string; //"35.25", ----> potenciasContratadasEnWP1
      P2: string; //"35.25", ----> potenciasContratadasEnWP2
      P3: string; //"35.25", ----> potenciasContratadasEnWP3
      P4: string; //"35.25", ----> potenciasContratadasEnWP4
      P5: string; //"35.25", ----> potenciasContratadasEnWP5
      P6: string; //"25.25", ----> potenciasContratadasEnWP6
      PotMaxBie: string; //"5265.25",   ----->Bie
      TipoServicio: string; //"1 Eléctrico, 2 Gas",  ----> TipoServicio
      Consumo: string; //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
    };
    T_PropuestaComercial: {
      PorcentajeAhorro: string; //"0.00",
      ImporteAhorro: string; //"0.00",
      Estado: string; //"P",
      Comercializadora: string; //"AUDAX RENOVABLES S.A.",
      Producto: string; //"CLASSIC",
      IdProducto: string;
      Anexo: string; //"12 MESES T2",
      TipoPrecio: string; //"0 Fijo 1 Indexado",
      ObservacionesProducto: string; //"opcinal o null",
      GastoOpertativo: string; //"0.00 SOLO CUANDO ES CORPORATE",
      idservicio: string;
      idComercializadora: string;
      nombreServicio: string;
      precioServicio: string;
      productSelected: any;
    };
  };
  rates2X: any[];
  rates3X: any[];
  rates2XP: any[];
  rates1XP: any[];
  rates3XP: any[];
  rates6XP: any[];
  rates1XE: any[];
  rates2XE: any[];
  rates3XE: any[];
  companyID: any;
  userId: any;
  nombreAgente: any;
  companyName: any;
  correoAgente: any;
  rates6XE: any[];
  comparativeTemp: {
    producto: any;
    enabledSave: boolean;
    actual: any;
    comparativaGlobal: any;
    propuestasAnalizadas: any;
    datosResumenContrato: any;
    datosComision: any;
  };
  alert: any;
  fechaInicioMesConsumo: any;
  fechaFinMesConsumo: any;
  comerciales: any;
  consumo1: number;
  consumo2: number;
  consumosEnergia: any[];
  consumosPotencia: any[];
  showCupsBusqueda: boolean;
  filters: any;
}

interface IComparativeFreeProps {}

class propuestasEnergia extends Reflux.Component<any, any> {
  private dataToSave = {
    companyId: null,
    nombreOferta: null,
    idComercializadora: null,
    nombreComercializadora: null,
    idComercializadoraActual: null,
    nombreComercializadoraActual: null,
    datosPreciosActual: null,
    userId: null,
    fechaPropuesta: null,
    codigoPostalPS: null,
    codigoMunicipioPS: null,
    nombreMunicipioPS: null,
    codigoProvinciaPS: null,
    nombreProvinciaPS: null,
    codigoAutonomiaPS: null,
    nombreAutonomiaPS: null,
    esAprobada: false,
    datosPropuesta: null,
    correoContacto: null,
    cups: null,
    //datosContrato: null,
  };
  //public comparativeTemp: any = null;
  //this.state.comparativeTemp.producto
  public enabledSave: boolean = false;
  public formComparativeRef: any = null;
  public formSaveComparativeRef: any = null;
  public UserStore: any = {};
  public busquedaCups: any = null;
  public _isMounted: boolean = false;

  public state: IComparativeFreeState = {
    comparativeTemp: {
      producto: [{ tarifaATR: "000" }],
      enabledSave: true,
      actual: [],
      comparativaGlobal: {},
      datosComision: {},
      propuestasAnalizadas: {},
      datosResumenContrato: {},
    },
    cups: "",
    user: {},
    sip: {},
    ubicacion: {},
    nombrePropuesta: "",
    sipsConsumos: [],
    showCupsBusqueda: false,
    consumosEnergia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    consumosPotencia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    itemsSelected: [],
    comercialProductoSelected: null,
    tableHeaders: [],
    comparatives: [],
    periodos: [],
    producto: {
      active: false,
      productoSeleccionado: null,
      costeOperativo: 0,
      costeOperativoPotencia: 0,
      esNuevoContrato: true,
    },
    productoMulti: {
      active: false,
      productoSeleccionado: [],
      costeOperativo: 0,
      costeOperativoPotencia: 0,
      esNuevoContrato: true,
    },

    // --- View State
    isDisabled: true,
    isDisabledProductos: false,
    isLoading: true,
    isLoadingConsumos: true,
    isSavingPropuesta: false,
    openModalComparative: false,
    openModalMultiComparative: false,
    showList: true,
    dataComparativeTarget: null,
    datosContrato: {
      Usuario: "",
      fechaPropuesta: "",
      FechaAprobacion: "",
      IdOferta: "",
      T_Titular: {
        RazonSocial: "", //":"MANUEL GONZALEZ",--> nombre_completo_titular
        NIF: "", //"C19345456", --> CIF
        DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
        CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
        TelefonoFijo: "", //"949201893", ----> telefono_fijo
        TelefonMovil: "", //"689105332", ----> telefono
        Email: "", //"demo@demo.com", --->email_contacto
        TipoCliente: "", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
        ObservacionesCliente: "",
        GDPR: false, //"OPCIONAL O NULL",----> observaciones
      },
      T_Representante: {
        nombreRepresentante: "",
        apellido1Representante: "",
        apellido2Representante: "",
        NIFRepresentante: "",
        idRepresentante: "",
        idRepresentanteInterno: 0,
        CargoRepresentante: "",
        telefonoRepresentante: "",
        correoRepresentante: "",
      },
      T_PuntoSuministro: {
        Direccion: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
        CodigoPostal: "", //"08630", ----> codigo_postal_ps
        Telefono: "", //"689105332", ----> telefono;
        ObservacionesPuntoSuministro: "", // "opcional o null" ----> observaciones_ps
      },
      T_CUPs: {
        Distribuidora: "", //"IBERDROLA", ----> distribuidora
        CUPsEle: "", //"ES0022000008945731EY", ------> cupShort
        Tarifa: "", //"2.0 DHS", ----> tarifaATR
        P1: "0", //"35.25", ----> potenciasContratadasEnWP1
        P2: "0", //"35.25", ----> potenciasContratadasEnWP2
        P3: "0", //"35.25", ----> potenciasContratadasEnWP3
        P4: "0", //"35.25", ----> potenciasContratadasEnWP4
        P5: "0", //"35.25", ----> potenciasContratadasEnWP5
        P6: "0", //"25.25", ----> potenciasContratadasEnWP6
        PotMaxBie: "", //"5265.25",   ----->Bie
        TipoServicio: "", //"1 Eléctrico, 2 Gas",  ----> TipoServicio
        Consumo: "", //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
      },
      T_PropuestaComercial: {
        PorcentajeAhorro: "", //"0.00",
        ImporteAhorro: "", //"0.00",
        Estado: "", //"P",
        Comercializadora: "", //"AUDAX RENOVABLES S.A.",
        Producto: "", //"CLASSIC",
        IdProducto: "",
        Anexo: "", //"12 MESES T2",
        TipoPrecio: "", //"0 Fijo 1 Indexado",
        ObservacionesProducto: "", //"opcinal o null",
        GastoOpertativo: "", //"0.00 SOLO CUANDO ES CORPORATE",
        idservicio: "",
        idComercializadora: "",
        nombreServicio: "",
        precioServicio: "",
        productSelected: null,
      },
    },
    rates2X: ["001", "004", "005", "006", "007", "008"],
    rates3X: ["011", "003", "012", "013", "014", "015", "016"],
    rates2XP: ["018"],
    rates1XP: ["001", "004", "005", "006", "007", "008"],
    rates3XP: ["011", "003"],
    rates6XP: [
      "012",
      "013",
      "014",
      "015",
      "016",
      "019",
      "020",
      "021",
      "022",
      "023",
      "024",
      "025",
    ],
    rates1XE: ["001", "005"],
    rates2XE: ["004", "006"],
    rates3XE: ["011", "003", "007", "008", "018"],
    rates6XE: [
      "012",
      "013",
      "014",
      "015",
      "016",
      "019",
      "020",
      "021",
      "022",
      "023",
      "024",
      "025",
    ],
    companyID: "",
    userId: "",
    nombreAgente: "",
    companyName: "",
    correoAgente: "",
    UserStore: {},
    alert: null,
    fechaInicioMesConsumo: "",
    fechaFinMesConsumo: "",
    consumo1: 0,
    consumo2: 0,
    comerciales: {
      NIFComercial: "",
      Responsable: "",
      apellido1Comercial: "",
      apellido2Comercial: "",
      comisionYear1: 0,
      comisionYear2: 0,
      correoComercial: "",
      idComercial: "",
      idComercialInterno: "",
      idCompany: 2,
      idResponsable: "",
      nombreComercial: "",
      telefonoComercial: "",
      zonaGeograficaComercial: "",
    },
    filters: { cups: "" },
  };

  constructor(props: IComparativeFreeProps) {
    super(props);
    this.store = UserStore;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    //this._isMounted = true;

    const usuario: any = JSON.parse(
      JSON.stringify(this.state.UserStore["data"])
    );
    const companyNameGlobal = usuario.company;
    const companyIdUser = usuario.companyId;

    const userGroupId = usuario.user_group_id;
    const userId = usuario.id;
    const nombreAgente = usuario.fullname;
    const correoAgente = usuario.email;
    //let cups = "ES0021000000176475SQ";
    let cups = "";

    this.setState({
      //cups: params.cups,
      cups: cups,
      companyID: companyIdUser,
      userId: userId,
      user: userId,
      companyName: companyNameGlobal,
      nombreAgente: nombreAgente,
      correoAgente: correoAgente,
    });

    this.formComparativeRef = React.createRef();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  warningAlertCups = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="El CUPS introducido es incorrecto"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Compruebe el CUPS introducido. Tiene error en los dígitos
          introducidos.
        </ReactBSAlert>
      ),
    });
  };

  warningAlertCupsGas = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="NO DISPONIBLE"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Próximamente estará disponible la creación de propuestas de gas
        </ReactBSAlert>
      ),
    });
  };

  validarCUPS = (cups) => {
    let ret = false;
    const reCUPS = /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i;
    if (reCUPS.test(cups)) {
      const mCUPS = cups.toUpperCase().match(reCUPS);
      const [, cups16, control] = mCUPS;
      const letters = [
        "T",
        "R",
        "W",
        "A",
        "G",
        "M",
        "Y",
        "F",
        "P",
        "D",
        "X",
        "B",
        "N",
        "J",
        "Z",
        "S",
        "Q",
        "V",
        "H",
        "L",
        "C",
        "K",
        "E",
      ];

      const cup16Mod = +cups16 % 529,
        quotient = Math.floor(cup16Mod / letters.length),
        remainder = cup16Mod % letters.length;

      ret = control === letters[quotient] + letters[remainder];
    }

    return ret;
  };

  buscarCups = async () => {
    const result = this.validarCUPS(this.state.filters.cups);
    if (result === true) {
      
      this.props.history.push(
        `/admin/sip-comparative-free/` + this.state.filters.cups.toUpperCase()
      );
    } else {
      this.warningAlertCups();
    }
  };

  handleClickImage = (tipo) => {
    //if (this._isMounted) {

    if (tipo == "G") {
      this.warningAlertCupsGas();
    } else {
      if (this.state.showCupsBusqueda === false) {
        this.setState({
          showCupsBusqueda: true,
        });
        const reftp = document.getElementById("busquedaCups");
        reftp?.scrollIntoView({ behavior: "smooth" });
      } else {
        this.setState({
          showCupsBusqueda: false,
        });
      }
    }
  };

  clearForm = () => {
    this.setState({

      filters: {cups:""},
      showCupsBusqueda: false,
      productSelected: null,
      isDisabled: true,
      isDisabledProductos: false,
      nombrePropuesta: "",

    });

  };

  onChangeFieldBuscar = (event) => {
    event.persist();

    let filters = { ...this.state.filters };
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;

    filters["cups"] = value;

    this.setState({
      filters: filters,
    });
  };

  render() {
    return (
      <>
        <Header title="Comparativa de Contrato Precio Fijo" />
        <Row>
          <Container fluid className="mb-4 mt-2">
            <Row className="card-deck">
              <Col className="px-1 py-1" sm="4" md={true}>
                <Card
                  className="card-stats mb-4 mb-xl-0 h-80 btn p-1"
                  onClick={() => this.handleClickImage("E")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody className="p-0">
                    <Row>
                      <ThunderboltFilled
                        style={{ fontSize: "14px", color: "#ffffff" }}
                        className="col-auto ml-auto mr-3 p-1 align-self-end badge rounded-circle bg-yellow "
                      />
                    </Row>
                    <Row>
                      <Col className="col col-3 m-auto text-center ">
                        <h3>
                          <img src={propuestaElectricidadUnica} />
                        </h3>
                      </Col>
                    </Row>
                    <Row className="m-auto">
                      <div className="col">
                        <CardTitle className="text-center mb-0">
                          Propuesta Electricidad
                        </CardTitle>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col className="px-1 py-1" sm="4" md={true}>
                <Card
                  className="card-stats mb-4 mb-xl-0 h-80 btn p-1"
                  onClick={() => this.handleClickImage("G")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody className="p-0">
                    <Row>
                      <FireFilled
                        style={{ fontSize: "14px", color: "#ffffff" }}
                        className="col-auto ml-auto mr-3 p-1 align-self-end badge rounded-circle bg-blue "
                      />
                    </Row>
                    <Row>
                      <Col className="col col-3 m-auto text-center ">
                        <h3>
                          <img src={propuestaGasUnica} />
                        </h3>
                      </Col>
                    </Row>
                    <Row className="m-auto">
                      <div className="col">
                        <CardTitle className="text-center mb-0">
                          Propuesta Gas
                        </CardTitle>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>

        <div id="busquedaCups">
          <Row>
            {this.state.showCupsBusqueda && (
              <React.Fragment>
                <Container fluid className="mb-4 mt-2">
                  <Card className="mb-4">
                    <CardBody>
                      <Row>
                        <Col md="4" lg="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="cupsCliente"
                            >
                              CUPS
                            </Label>
                            <Input
                              id="cups"
                              placeholder="Ingresar cups del cliente"
                              name="cupsBuscar"
                              onChange={this.onChangeFieldBuscar}
                              value={this.state.filters.cups}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12" lg="6" className="text-right">
                          <Button className="mb-4 mt-2" onClick={this.clearForm}>
                            <i className={"ni ni-fat-remove"} /> Limpiar
                          </Button>
                          <Button
                            className="mb-4 mt-2"
                            color="primary"
                            onClick={this.buscarCups}
                            disabled={false}
                          >
                            <i className={"bi bi-search"} /> Buscar
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Container>
              </React.Fragment>
            )}
          </Row>
        </div>

        <Row>
          <Container id="comparative" fluid>
            <Row>
              <div className="col m-2 d-flex">
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  className="btn-icon btn-sm btn-circle"
                  onClick={() => this.props.history.push(`/admin/index`)}
                >
                  <span className="bi bi-box-arrow-left"> Volver</span>
                </Button>
              </div>
            </Row>

            {this.state.alert}
          </Container>
        </Row>
      </>
    );
  }
}

export default withApollo(propuestasEnergia);

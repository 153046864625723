import React from "react";
import { Card, CardBody, Form, Input, Row, Table, Col, FormGroup, Label, FormText, FormFeedback, Button } from "reactstrap";
//import { Bar } from '@reactchartjs/react-chart.js';
//import { withApollo } from "react-apollo";
//import client from "../../../api/client";
//import { Bar,  HorizontalBar  } from "react-chartjs-2";
import SipsTable from "../../../components/Sips/SipsTableNew";
import { PieChart, Pie, Sector, BarChart, Bar, ReferenceLine, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//import { LIST_COMERCIAL_SERVICIOS} from "../../../queries/comercial-producto/comercial-producto";
import moment from 'moment';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import client from "../../../api/client";
import axios from 'axios';
import ReactBSAlert from "react-bootstrap-sweetalert";

import SipsOption from "../../../components/Sips/SipsOption";
import { validateSpanishId, spainIdType } from 'spain-id'
import { LIST_DICMUNICIPIOS, LIST_DICMUNICIPIOSPOSTALSHORT } from "../../../queries/options/dic-option.graphql";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "./es.json";
const ibantools = require('ibantools');
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(esMessages, "es-ES");


const options = [
    { value: '0', label: 'Sin Impuestos' },
    { value: '0.21', label: '21 %' },
    { value: '0.10', label: '10%' },
];

const reactSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        height: '25px',
        zIndex: 4
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        minHeight: '25px',
        height: '25px'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px'
    }),
    input: (provided, state) => ({
        ...provided,
        height: '20px'
    })
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '25px',
        height: '25px',
        boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '25px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px',
    }),
};

const rates6XPotencia = [
    '012',
    '013',
    '014',
    '015',
    '016',
    '019',
    '020',
    '021',
    '022',
    '023',
    '024',
    '025'
];

const notifyBIE = () => toast.error(
    "La potencia introducida es mayor que la autorizada en BIE",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


const notifyP1Error = () => toast.error(
    "La potencia introducida para ese periodo no puede ser mayor que las existentes para otro periodos",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyPError = () => toast.error(
    "La potencia introducida para ese periodo no puede ser menor que las existentes para otro periodos",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyCoError = () => toast.error(
    "El coste operativo introducido en uno de los campos excede el máximo recomendado",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyError = () => toast.error(
    "No existe cliente con el CIF/NIF introducido",
    {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyErrorCIF = () => toast.error(
    "Introduzca CIF/NIF para poder hacer la búsqueda",
    {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

//const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
//const COLORS = ['#0088FE', '#887cf5', '#c16ee1', '#e861c5', '#ff59a4','#ff5e82,'#ff6c60','#ff8042' ];
const COLORS = ['#0088FE', '#a177ef', '#e163cb', '#ff599d', '#ff656e', '#ff8042'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={COLORS[name % COLORS.length]}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={COLORS[name % COLORS.length]}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`ENERGIA ${payload.periodo} ${value} Kwh`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

interface IComercialProductoMultiProps {
    sip: any,
    periods: number[],
    productoMulti: {
        active: boolean,
        productoSeleccionado: any[],
        costeOperativo: number,
        costeOperativoPotencia: number,
        esNuevoContrato: boolean
    };
    itemsConsumo: any[],
    output: any,
    comercial: any,
    ubi: any,
    onSave?: Function;
}

interface IComercialProductoMultiState {
    sip: any,
    ubi: any,
    periods: number[],

    active: boolean
    producto: any[]
    adFincas: boolean,
    agencia: boolean,
    comercialUsuario: boolean,
    comercialServicios: any[],
    costeOperativo: number,
    costeOperativoPotencia: number,
    daysOperacion: number,
    cambioPotencia: number,
    rates1XP: any[],
    rates2X: any[],
    rates3X: any[],
    rates2XP: any[],
    rates3XP: any[],
    rates6XP: any[],
    rates1XE: any[],
    rates2XE: any[],
    rates3XE: any[],
    rates6XE: any[],
    ratesMonth1: any[],
    ratesMonth2: any[],
    ratesMonth3: any[],
    ratesMonth4: any[],

    itemsTabla: any[];
    itemsTablaComision: any[];
    multiTableDataComision: any[];
    multiTableDataComisionConCo: any[];

    idservicio: any,
    idComercializadora: any,
    nombreComercializadora: any,
    nombreServicio: any,
    precioServicio: any,

    esNuevoContrato: boolean,
    alert: any,

    formMulti: any[],
    tablaComision: any,
    tablaComisionConCo: any,
    formDatosContrato: {
        tarifaATR: any,
        descATR: any,
        bie: number,
        potenciaP1: number,
        potenciaP2: number,
        potenciaP3: number,
        potenciaP4: number,
        potenciaP5: number,
        potenciaP6: number,
        potenciaContratoP1: number,

        potenciaContratoP2: number,
        potenciaContratoP3: number,
        potenciaContratoP4: number,
        potenciaContratoP5: number,
        potenciaContratoP6: number,
        consumoAnualActivaP1: number,
        consumoAnualActivaP2: number,
        consumoAnualActivaP3: number,
        consumoAnualActivaP4: number,
        consumoAnualActivaP5: number,
        consumoAnualActivaP6: number,
        consumoAnualTotalActiva: number
    },

    formMultiProducto: {
        sumPotencia: number,
        sumEnergia: number,
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;
        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;
        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;
        penalizacionPotencia: number,
        penalizacionEnergia: number,
        datasetProducto: number[],
        EnergiaActiva: any[],
        Potencia: any[],
        potenciaP1: number,
        potenciaP2: number,
        potenciaP3: number,
        potenciaP4: number,
        potenciaP5: number,
        potenciaP6: number,

        potenciaContratoP1: number,
        potenciaContratoP2: number,
        potenciaContratoP3: number,
        potenciaContratoP4: number,
        potenciaContratoP5: number,
        potenciaContratoP6: number,

        energiaActivaP1: number,
        energiaActivaP2: number,
        energiaActivaP3: number,
        energiaActivaP4: number,
        energiaActivaP5: number,
        energiaActivaP6: number,

        energiaReactivaP1: number,
        energiaReactivaP2: number,
        energiaReactivaP3: number,
        energiaReactivaP4: number,
        energiaReactivaP5: number,
        energiaReactivaP6: number,


        precioPotenciaP1: number,
        precioPotenciaP2: number,
        precioPotenciaP3: number,
        precioPotenciaP4: number,
        precioPotenciaP5: number,
        precioPotenciaP6: number,

        precioEnergiaP1: number,
        precioEnergiaP2: number,
        precioEnergiaP3: number,
        precioEnergiaP4: number,
        precioEnergiaP5: number,
        precioEnergiaP6: number,


        idServicio: any,
        idComercializadora: any,
        nombreComercializadora: any,
        nombreServicio: any,
        precioServicio: number,
        nombreProducto: any,

        comisionContratoFija: number,
        comisionContratoConsumoAgente1: number,
        comisionContratoConsumoAgente2: number,
        comisionContratoConsumoAdmin: number

    },
    formActual: {

        precioTP1: number,
        precioTP2: number,
        precioTP3: number,
        precioTP4: number,
        precioTP5: number,
        precioTP6: number,

        precioTEP1: number,
        precioTEP2: number,
        precioTEP3: number,
        precioTEP4: number,
        precioTEP5: number,
        precioTEP6: number,

        consumoAnualActivaP1: number,
        consumoAnualActivaP2: number,
        consumoAnualActivaP3: number,
        consumoAnualActivaP4: number,
        consumoAnualActivaP5: number,
        consumoAnualActivaP6: number,
        consumoAnualTotalActiva: number

        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;

        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;

        sumPotencia: number;
        sumEnergia: number;
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;

        penalizacionPotencia: number,
        penalizacionEnergia: number;

        datasetActual: number[],
        datasetLeyenda: any[],
        descuentoMonth: number,
        descuento: number,
        descuentoPotencia: number,
        descuentoPotenciaMonth: number
    },
    totalComparacion: any[];
    propuestasAnalizadas: any[];
    datosResumenContrato: any[];

    /*  {
          ahorroAnual: number,
          ahorroPercent: number,
          ahorroPeriodo: number,
          datosAhorro: number[]
      },
    */
    itemsConsumo: any[];
    productSelected: any;
    selectedOption: string;
    activeIndex: number,
    penalizacionPotenciaCalculo: number;
    penalizacionReactivaCalculo: number;
    enabledSave: boolean;
    dataComisionesProducto: any[];
    dataComisionesProductoIncluidoCo: any;
    consumoPotenciaTotal: number,
    editID: string;
    comercialAgente: any;

    consumosMaxMin: {
        max: {
            P1: number,
            P2: number,
            P3: number,
            P4: number,
            P5: number,
            P6: number,
        },
        min: {
            P1: number,
            P2: number,
            P3: number,
            P4: number,
            P5: number,
            P6: number,
        }
    },
    form: {
        nombreOferta: string;
        correoContacto: string;
        idComercializadora: string;
        nombreComercializadora: string;
        esAprobada: boolean;
        tieneRepresentante: boolean;
        datosps: boolean;
        datosContacto: boolean;
        razonSocial: any;
        NIF: any;
        DireccionFiscal: any;
        nombreTitular: any;
        apellido1Titular: any;
        apellido2Titular: any;
        tipoTitular: any;
        tipoTitularRepresentante: any;
        nombreRepresentante: any;
        apellido1Representante: any;
        apellido2Representante: any;
        NIFRepresentante: any;
        CargoRepresentante: any;
        idRepresentante: any;
        idRepresentanteInterno: any;
        telefonoRepresentante: any;
        correoRepresentante: any;
        companyIdRepresentante: any;
        desAutonomiaTitular: any;
        desProvinciaTitular: any;
        desMunicipioTitular: any;
        tipoViaTitular: any;
        viaTitular: any;
        numFincaTitular: any;
        portalTitular: any;
        escaleraTitular: any;
        pisoTitular: any;
        puertaTitular: any;
        iban: any;
        CodigoPostalTitular: any; //"08630",----> codigo_postal_titular
        TelefonoFijo: any; //"949201893", ----> telefono_fijo
        TelefonMovil: any; //"689105332", ----> telefono
        Email: any; //"demo@demo.com", --->email_contacto
        TipoCliente: any; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
        ObservacionesCliente: any; //"OPCIONAL O NULL",----> observaciones
        DireccionPS: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps

        nombreMunicipioPS: any;
        nombreProvinciaPS: any;
        //nombreAutonomiaPS: any;
        tipoViaPS: any;
        viaPS: any;
        numFincaPS: any;
        portalPS: any;
        escaleraPS: any;
        pisoPS: any;
        puertaPS: any;

        CodigoPostalPS: any; //"08630", ----> codigo_postal_ps
        TelefonoPS: any; //"689105332", ----> telefono;
        ObservacionesPuntoSuministro: any;
        GDPR: boolean; // "opcional o null" ----> observaciones_ps
    },
    client: {
        idCliente: string;
        idTipoTitular: string;
        descIdTipoTitular: string;
        nombreTitular: string;
        apellido1Titular: string;
        apellido2Titular: string;
        codigoProvinciaTitular: string;
        desProvinciaTitular: string;
        codigoPostalTitular: string;
        municipioTitular: string;
        desMunicipioTitular: string;
        tipoViaTitular: string;
        viaTitular: string;
        numFincaTitular: string;
        portalTitular: string;
        escaleraTitular: string;
        pisoTitular: string;
        puertaTitular: string;
        esViviendaHabitual: string;
        CNAE: string;
        actividadCNAE: string;
        CIF: string;
        codigoAutonomiaPS: string;
        desAutonomiaPS: string;
        idBanco: string;
        descBanco: string;
        iban: string;
        idCompany: string
    };
    dicMunicipios: any[];
    dicMunicipiosList: any[];
    isDisabledCIF: boolean;
    isDisabledCIFRepresentante: boolean;
    isDisabledRepresentante: boolean;
    show: boolean;
    showps: boolean;
    showContacto: boolean;
    TipoCliente: any;
    errores: {
        nombreOferta: boolean,
        correoContacto: boolean,
        idComercializadora: boolean,
        nombreComercializadora: boolean,
        esAprobada: boolean,
        tieneRepresentante: boolean,
        razonSocial: boolean,
        NIF: boolean,
        DireccionFiscal: boolean,
        nombreTitular: boolean,
        apellido1Titular: boolean,
        apellido2Titular: boolean,
        nombreRepresentante: boolean,
        apellido1Representante: boolean,
        apellido2Representante: boolean,
        tipoTitular: boolean,
        tipoTitularRepresentante: boolean,
        NIFRepresentante: boolean,
        CargoRepresentante: boolean,
        idRepresentante: boolean,
        telefonoRepresentante: boolean,
        correoRepresentante: boolean,
        companyIdRepresentante: boolean,
        desAutonomiaTitular: boolean,
        desProvinciaTitular: boolean,
        desMunicipioTitular: boolean,
        tipoViaTitular: boolean,
        viaTitular: boolean,
        numFincaTitular: boolean,
        portalTitular: boolean,
        escaleraTitular: boolean,
        pisoTitular: boolean,
        puertaTitular: boolean,
        iban: boolean,
        CodigoPostalTitular: boolean,
        TelefonoFijo: boolean,
        TelefonMovil: boolean,
        Email: boolean,
        TipoCliente: boolean,
        ObservacionesCliente: boolean,
        DireccionPS: boolean,
        nombreMunicipioPS: boolean,
        nombreProvinciaPS: boolean,
        tipoViaPS: boolean,
        viaPS: boolean,
        numFincaPS: boolean,
        portalPS: boolean,
        escaleraPS: boolean,
        pisoPS: boolean,
        puertaPS: boolean,
        CodigoPostalPS: boolean,
        TelefonoPS: boolean,
        ObservacionesPuntoSuministro: boolean,
        GDPR: boolean
    },
    textErrores: {
        nombreOferta: string,
        correoContacto: string
        idComercializadora: string,
        nombreComercializadora: string,
        esAprobada: string,
        tieneRepresentante: string,
        razonSocial: string,
        NIF: string,
        DireccionFiscal: string,
        nombreTitular: string,
        apellido1Titular: string,
        apellido2Titular: string,
        nombreRepresentante: string,
        apellido1Representante: string,
        apellido2Representante: string,
        tipoTitular: string,
        tipoTitularRepresentante: string,
        NIFRepresentante: string,
        CargoRepresentante: string,
        idRepresentante: string,
        telefonoRepresentante: string,
        correoRepresentante: string,
        companyIdRepresentante: string,
        desAutonomiaTitular: string,
        desProvinciaTitular: string,
        desMunicipioTitular: string,
        tipoViaTitular: string,
        viaTitular: string,
        numFincaTitular: string,
        portalTitular: string,
        escaleraTitular: string,
        pisoTitular: string,
        puertaTitular: string,
        iban: string,
        CodigoPostalTitular: string,
        TelefonoFijo: string,
        TelefonMovil: string,
        Email: string,
        TipoCliente: string,
        ObservacionesCliente: string,
        DireccionPS: string,
        nombreMunicipioPS: string,
        nombreProvinciaPS: string,
        tipoViaPS: string,
        viaPS: string,
        numFincaPS: string,
        portalPS: string,
        escaleraPS: string,
        pisoPS: string,
        puertaPS: string,
        CodigoPostalPS: string,
        TelefonoPS: string,
        ObservacionesPuntoSuministro: string,
        GDPR: string
    },
    companyId: any;
    userId: any;
    cups: string;
    user: any;
    isDisabled: boolean;
    nombreOfertaError: boolean;
    emailError: boolean;
    emailRepreError: boolean;
    nombreTitularError: boolean;
    NIFError: boolean;
    NIFRepreError: boolean;
    DireccionFiscalError: boolean;
    CodigoPostalTitularError: boolean;
    TelefonoFijoError: boolean;
    TelefonMovilError: boolean;
    DireccionPSError: boolean;
    CodigoPostalPSError: boolean;
    TelefonoPSError: boolean;
    idComercializadoraError: boolean;
    apellido1TitularError: boolean;
    ibanError: boolean;
    isDisabledSave: boolean;



}


export default class ComercialProductoMulti extends React.Component<IComercialProductoMultiProps, IComercialProductoMultiState>
{
    public formActualRef: any = null;
    public formMultiProductoRef: any = null;
    public formDatosPropuestaRef: any = null;
    public representanteRef: any = [];
    public state: IComercialProductoMultiState = {
        alert: null,
        formMulti: [],
        tablaComision: {},
        tablaComisionConCo: {},

        formDatosContrato: {
            tarifaATR: '',
            descATR: '',
            bie: 0,
            potenciaP1: 0,
            potenciaP2: 0,
            potenciaP3: 0,
            potenciaP4: 0,
            potenciaP5: 0,
            potenciaP6: 0,
            potenciaContratoP1: 0,

            potenciaContratoP2: 0,
            potenciaContratoP3: 0,
            potenciaContratoP4: 0,
            potenciaContratoP5: 0,
            potenciaContratoP6: 0,
            consumoAnualActivaP1: 0,
            consumoAnualActivaP2: 0,
            consumoAnualActivaP3: 0,
            consumoAnualActivaP4: 0,
            consumoAnualActivaP5: 0,
            consumoAnualActivaP6: 0,
            consumoAnualTotalActiva: 0
        },
        formMultiProducto: {
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            EnergiaActiva: [],
            Potencia: [],

            potenciaP1: 0,

            potenciaP2: 0,
            potenciaP3: 0,
            potenciaP4: 0,
            potenciaP5: 0,
            potenciaP6: 0,

            potenciaContratoP1: 0,

            potenciaContratoP2: 0,
            potenciaContratoP3: 0,
            potenciaContratoP4: 0,
            potenciaContratoP5: 0,
            potenciaContratoP6: 0,

            energiaActivaP1: 0,
            energiaActivaP2: 0,
            energiaActivaP3: 0,
            energiaActivaP4: 0,
            energiaActivaP5: 0,
            energiaActivaP6: 0,

            energiaReactivaP1: 0,
            energiaReactivaP2: 0,
            energiaReactivaP3: 0,
            energiaReactivaP4: 0,
            energiaReactivaP5: 0,
            energiaReactivaP6: 0,

            precioPotenciaP1: 0,
            precioPotenciaP2: 0,
            precioPotenciaP3: 0,
            precioPotenciaP4: 0,
            precioPotenciaP5: 0,
            precioPotenciaP6: 0,

            precioEnergiaP1: 0,
            precioEnergiaP2: 0,
            precioEnergiaP3: 0,
            precioEnergiaP4: 0,
            precioEnergiaP5: 0,
            precioEnergiaP6: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetProducto: [],
            idServicio: null,
            idComercializadora: null,
            nombreComercializadora: "",
            nombreServicio: null,
            precioServicio: 0,
            nombreProducto: "",

            comisionContratoFija: 0,
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoConsumoAdmin: 0



        },
        formActual: {

            descuento: 0,
            descuentoMonth: 0,
            descuentoPotencia: 0,
            descuentoPotenciaMonth: 0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetActual: [],
            datasetLeyenda: [],

            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
            consumoAnualActivaP1: 0,
            consumoAnualActivaP2: 0,
            consumoAnualActivaP3: 0,
            consumoAnualActivaP4: 0,
            consumoAnualActivaP5: 0,
            consumoAnualActivaP6: 0,
            consumoAnualTotalActiva: 0

        },
        totalComparacion: [],
        propuestasAnalizadas: [],
        datosResumenContrato: [],
        /*
                {
                    ahorroAnual: 0,
                    ahorroPercent: 0,
                    ahorroPeriodo: 0,
                    datosAhorro:[]
                },
          */
        comercialServicios: [],
        esNuevoContrato: true,
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        ratesMonth1: [1, 2, 7, 12],
        ratesMonth2: [3, 11],
        ratesMonth3: [4, 5, 10],
        ratesMonth4: [6, 8, 9],

        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: null,
        daysOperacion: 0,

        adFincas: false,
        agencia: true,
        comercialUsuario: false,

        active: false,
        sip: {},
        ubi: {},
        periods: [],
        itemsTabla: [],
        itemsTablaComision: [],
        multiTableDataComision: [],
        multiTableDataComisionConCo: [],
        itemsConsumo: [],
        producto: [],
        comercialAgente: [],
        costeOperativo: 0,
        costeOperativoPotencia: 0,
        productSelected: null,
        selectedOption: '',
        cambioPotencia: 0,
        penalizacionPotenciaCalculo: 0,
        penalizacionReactivaCalculo: 0,
        activeIndex: 0,
        enabledSave: false,
        dataComisionesProducto: [{
            idProducto: "000", idComisionTabla: "000",
            coTE1: 0,
            coTE2: 0,
            coTE3: 0,
            coTE4: 0,
            coTE5: 0,
            coTE6: 0,
            coTEP1: 0,
            coTEP2: 0,
            coTEP3: 0,
            coTEP4: 0,
            coTEP5: 0,
            coTEP6: 0,
        }],
        dataComisionesProductoIncluidoCo: [{
            idProducto: "000", idComisionTabla: "000",
            coTE1: 0,
            coTE2: 0,
            coTE3: 0,
            coTE4: 0,
            coTE5: 0,
            coTE6: 0,
            coTEP1: 0,
            coTEP2: 0,
            coTEP3: 0,
            coTEP4: 0,
            coTEP5: 0,
            coTEP6: 0,
        }],

        editID: "0",
        consumoPotenciaTotal: 0,
        consumosMaxMin: {
            max: {
                P1: 0,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                P6: 0
            },
            min: {
                P1: 0,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                P6: 0
            }
        },
        form: {
            nombreOferta: "",
            correoContacto: "",
            idComercializadora: "",
            nombreComercializadora: "",
            esAprobada: false,
            tieneRepresentante: false,
            datosps: false,
            datosContacto: false,
            razonSocial: null,
            NIF: "", //"C19345456", --> CIF
            DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
            nombreTitular: "",
            apellido1Titular: "",
            apellido2Titular: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            tipoTitular: "",
            tipoTitularRepresentante: "",
            NIFRepresentante: "",
            CargoRepresentante: "",
            idRepresentante: "",
            idRepresentanteInterno: 0,
            telefonoRepresentante: "",
            correoRepresentante: "",
            companyIdRepresentante: "",
            desAutonomiaTitular: "",
            desProvinciaTitular: "",
            desMunicipioTitular: "",
            tipoViaTitular: "",
            viaTitular: "",
            numFincaTitular: "",
            portalTitular: "",
            escaleraTitular: "",
            pisoTitular: "",
            puertaTitular: "",
            iban: "",
            CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
            TelefonoFijo: "", //"949201893", ----> telefono_fijo
            TelefonMovil: "", //"689105332", ----> telefono
            Email: "", //"demo@demo.com", --->email_contacto
            TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
            ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones
            DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
            nombreMunicipioPS: "",
            nombreProvinciaPS: "",
            //nombreAutonomiaPS: "",
            tipoViaPS: "",
            viaPS: "",
            numFincaPS: "",
            portalPS: "",
            escaleraPS: "",
            pisoPS: "",
            puertaPS: "",
            CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
            TelefonoPS: "", //"689105332", ----> telefono;
            ObservacionesPuntoSuministro: "",
            GDPR: true, // "opcional o null" ----> observaciones_ps
        },
        show: false,
        showps: false,
        showContacto: false,
        isDisabledCIF: true,
        isDisabledCIFRepresentante: true, 
        isDisabledRepresentante: false,
        dicMunicipios: [],
        dicMunicipiosList: [],
        client: {
            idCliente: "",
            idTipoTitular: "",
            descIdTipoTitular: "",
            nombreTitular: "",
            apellido1Titular: "",
            apellido2Titular: "",
            codigoProvinciaTitular: "",
            desProvinciaTitular: "",
            codigoPostalTitular: "",
            municipioTitular: "",
            desMunicipioTitular: "",
            tipoViaTitular: "",
            viaTitular: "",
            numFincaTitular: "",
            portalTitular: "",
            escaleraTitular: "",
            pisoTitular: "",
            puertaTitular: "",
            esViviendaHabitual: "",
            CNAE: "",
            actividadCNAE: "",
            CIF: "",
            codigoAutonomiaPS: "",
            desAutonomiaPS: "",
            idBanco: "",
            descBanco: "",
            iban: "",
            idCompany: ""
        },
        TipoCliente: [{ key: "0", cat: "NO DEFINIDO" },
        { key: "1", cat: "PARTICULAR" },
        { key: "2", cat: "PYME INDUSTRIA" },
        { key: "3", cat: "PYME SERVICIO" },
        { key: "4", cat: "ADMINISTRACION PUBLICA" },
        { key: "5", cat: "COMUNIDAD DE PROPIETARIO" }
        ],
        errores: {
            nombreOferta: false,
            correoContacto: true,
            idComercializadora: false,
            nombreComercializadora: true,
            esAprobada: true,
            tieneRepresentante: true,
            razonSocial: true,
            NIF: false,
            DireccionFiscal: true,
            nombreTitular: false,
            apellido1Titular: true,
            apellido2Titular: true,
            nombreRepresentante: true,
            apellido1Representante: true,
            apellido2Representante: true,
            tipoTitular: true,
            tipoTitularRepresentante: true,
            NIFRepresentante: true,
            idRepresentante: true,
            CargoRepresentante: true,
            telefonoRepresentante: true,
            correoRepresentante: true,
            companyIdRepresentante: true,
            desAutonomiaTitular: true,
            desProvinciaTitular: true,
            desMunicipioTitular: true,
            tipoViaTitular: true,
            viaTitular: true,
            numFincaTitular: true,
            portalTitular: true,
            escaleraTitular: true,
            pisoTitular: true,
            puertaTitular: true,
            iban: true,
            CodigoPostalTitular: true,
            TelefonoFijo: false,
            TelefonMovil: false,
            Email: false,
            TipoCliente: true,
            ObservacionesCliente: true,
            DireccionPS: true,
            nombreMunicipioPS: true,
            nombreProvinciaPS: true,
            tipoViaPS: true,
            viaPS: true,
            numFincaPS: true,
            portalPS: true,
            escaleraPS: true,
            pisoPS: true,
            puertaPS: true,
            CodigoPostalPS: false,
            TelefonoPS: true,
            ObservacionesPuntoSuministro: true,
            GDPR: true
        },
        textErrores: {
            nombreOferta: "INTRODUZCA NOMBRE DE LA OFERTA",
            correoContacto: "",
            idComercializadora: "SELECCIONE COMERCIALIZADORA DE PROCEDENCIA",
            nombreComercializadora: "",
            esAprobada: "",
            tieneRepresentante: "",
            razonSocial: "",
            NIF: "INTRODUZCA NIF/CIF DEL CLIENTE",
            DireccionFiscal: "",
            nombreTitular: "INTRODUZCA NOMBRE/RAZON SOCIAL",
            apellido1Titular: "",
            apellido2Titular: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            tipoTitular: "",
            tipoTitularRepresentante: "",
            NIFRepresentante: "",
            CargoRepresentante: "",
            idRepresentante: "",
            telefonoRepresentante: "",
            correoRepresentante: "SIN DATOS",
            companyIdRepresentante: "",
            desAutonomiaTitular: "",
            desProvinciaTitular: "",
            desMunicipioTitular: "",
            tipoViaTitular: "",
            viaTitular: "",
            numFincaTitular: "",
            portalTitular: "",
            escaleraTitular: "",
            pisoTitular: "",
            puertaTitular: "",
            iban: "",
            CodigoPostalTitular: "",
            TelefonoFijo: "INTRODUZCA TELEFONO FIJO DE CONTACTO",
            TelefonMovil: "INTRODUZA TELEFONO MOVIL DE CONTACTO",
            Email: "INTRODUZCA CORREO ELECTRONICO",
            TipoCliente: "",
            ObservacionesCliente: "",
            DireccionPS: "",
            nombreMunicipioPS: "",
            nombreProvinciaPS: "",
            tipoViaPS: "",
            viaPS: "",
            numFincaPS: "",
            portalPS: "",
            escaleraPS: "",
            pisoPS: "",
            puertaPS: "",
            CodigoPostalPS: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
            TelefonoPS: "",
            ObservacionesPuntoSuministro: "",
            GDPR: ""
        },
        cups: "",
        user: {},
        companyId: "",
        userId: "",
        isDisabled: true,
        nombreOfertaError: false,
        emailError: true,
        emailRepreError: false,
        nombreTitularError: true,
        NIFError: true,
        NIFRepreError: true,
        DireccionFiscalError: true,
        CodigoPostalTitularError: true,
        TelefonoFijoError: true,
        TelefonMovilError: true,
        DireccionPSError: true,
        CodigoPostalPSError: true,
        TelefonoPSError: true,
        idComercializadoraError: true,
        apellido1TitularError: true,
        ibanError: false,
        isDisabledSave: true
    };

    constructor(props) {
        super(props);
        this.showTable = this.showTable.bind(this);
        this.showps = this.showps.bind(this)
        this.showContacto = this.showContacto.bind(this)
        this.representanteRef = React.createRef();
    };

    componentWillReceiveProps(newProps: IComercialProductoMultiProps) {

        let inputState: any = { ...newProps };

        console.log('valor de newProps: ', newProps)

        if (newProps.sip) {
            var state = Object.assign(this.state, {
                formActual: Object.assign(this.state.formActual, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0),

                }),
                formMulti: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0)
                }),
                formMultiProducto: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0)
                })
            });

            this.setState(state);
        };

        var totalConsumoPotencia = 0
        if (newProps.sip) {
            totalConsumoPotencia = Number(newProps.sip['potenciasContratadasEnWP1'] || 0) + Number(newProps.sip['potenciasContratadasEnWP2'] || 0) + Number(newProps.sip['potenciasContratadasEnWP3'] || 0) + Number(newProps.sip['potenciasContratadasEnWP4'] || 0) + Number(newProps.sip['potenciasContratadasEnWP5'] || 0) + Number(newProps.sip['potenciasContratadasEnWP6'] || 0)
            this.setState({ consumoPotenciaTotal: totalConsumoPotencia });
        };



        this.setState({
            productSelected: null,
        });


        if (newProps.productoMulti.productoSeleccionado) {

            var newItemsTabla: any[] = [];
            var itemsTablaComision: any[] = [];
            var itemsTablaComisionIncluidoCo: any[] = [];
            var itemsTablaComisionTemp: any[] = [];
            var itemsTablaComisionTempConCo: any[] = [];

            delete inputState.producto;
            this.fetchItems()

            let maxP1 = 0;
            let minP1 = 0;
            let maxP2 = 0;
            let minP2 = 0;
            let maxP3 = 0;
            let minP3 = 0;
            let maxP4 = 0;
            let minP4 = 0;
            let maxP5 = 0;
            let minP5 = 0;
            let maxP6 = 0;
            let minP6 = 0;

            const producto: any[] = { ...newProps.productoMulti.productoSeleccionado };
            const costeOperativo = (newProps.productoMulti.costeOperativo) / 1000;
            const costeOperativoPotencia = (newProps.productoMulti.costeOperativoPotencia) / 1000;
            const esNuevoContrato = newProps.productoMulti.esNuevoContrato
            let itemsConsumo = { ...newProps.itemsConsumo }

            if (rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor)) {

                minP1 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP1;
                }));
                maxP1 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP1;
                }));

                minP2 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP2;
                }));
                maxP2 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP2;
                }));
                minP3 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP3;
                }));
                maxP3 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP3;
                }));
                minP4 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP4;
                }));
                maxP4 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP4;
                }));
                minP5 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP5;
                }));
                maxP5 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP5;
                }));
                minP6 = Math.min.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP6;
                }));
                maxP6 = Math.max.apply(null, this.state.itemsConsumo.map(function (item) {
                    return item.potenciaDemandadaEnWP6;
                }));

            };

            let periodos: number[] = [];

            newProps.productoMulti.productoSeleccionado.map(row => {
                itemsTablaComisionTempConCo = [];
                itemsTablaComisionTemp = [];

                let idProducto = row['id']
                let idTipoComision = row['tipoComisionProducto']

                var comision = row['comisionProducto']

                var tipoContratoAsociado = row['denominacionProducto']

                var zonaGeografica = row['zona']

                let resultado: any = []

                ////////////////////////// Proceso que busca las comisiones que cumplen con las condiciones de consumos /////////////////////////////////////////

                if (idTipoComision != "A" && idTipoComision != "B" && idTipoComision != "C" && idTipoComision != "D"  && idTipoComision != "G") {
                    if (!row['eurosMwh']) {
                        //resultado = comision.find((comision: any) => (comision.energiaDesde >= Number(newProps.sip['ConsumoAnualTotalActiva']) && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision.energiaHasta));
                        
                        console.log('VALOR DE ROW: ', row)


                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if (Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTemp.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    } else {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if (Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta * 1000) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTemp.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    }


                    const minimun = itemsTablaComisionTemp.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComision.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })

                } else  if (idTipoComision == "G") {
                    if (!row['eurosMwh']) {
                        //resultado = comision.find((comision: any) => (comision.energiaDesde >= Number(newProps.sip['ConsumoAnualTotalActiva']) && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision.energiaHasta));
                        
                        console.log('VALOR DE ROW en tipo G: ', row)


                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            
                                resultadoTemp = comision[i]
                                itemsTablaComisionTemp.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
    
                        };
                    } else {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            
                                resultadoTemp = comision[i]
                                itemsTablaComisionTemp.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            
                        };
                    }


                    const minimun = itemsTablaComisionTemp.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComision.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })

                } else if (idTipoComision == "D") {
                    if (!row['eurosMwh']) {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if (Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    } else {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if (Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta * 1000) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    }
                    const minimun = itemsTablaComisionTempConCo.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComisionIncluidoCo.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })

                } else if (idTipoComision == "B") {

                    if (!row['eurosMwh']) {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta) && (totalConsumoPotencia >= comision[i].potenciaDesde && totalConsumoPotencia <= comision[i].potenciaHasta)) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    } else {
                        resultado = comision.find((comision: any) => (comision.energiaDesde * 1000 >= Number(newProps.sip['ConsumoAnualTotalActiva']) && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision.energiaHasta * 1000));
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde * 1000 && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta * 1000) && (totalConsumoPotencia >= comision[i].potenciaDesde * 1000 && totalConsumoPotencia <= comision[i].potenciaHasta * 1000)) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    }
                    const minimun = itemsTablaComisionTempConCo.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComisionIncluidoCo.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })

                } else if (idTipoComision == "A") {

                    if (!row['eurosMwh']) {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta) && (totalConsumoPotencia >= comision[i].potenciaDesde && totalConsumoPotencia <= comision[i].potenciaHasta)) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    } else {
                        resultado = comision.find((comision: any) => (comision.energiaDesde * 1000 >= Number(newProps.sip['ConsumoAnualTotalActiva']) && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision.energiaHasta * 1000));
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde * 1000 && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta * 1000) && (totalConsumoPotencia >= comision[i].potenciaDesde * 1000 && totalConsumoPotencia <= comision[i].potenciaHasta * 1000)) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    }
                    const minimun = itemsTablaComisionTempConCo.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComisionIncluidoCo.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })

                } else if (idTipoComision == "C") {
                    if (!row['eurosMwh']) {
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta) && (totalConsumoPotencia >= comision[i].potenciaDesde && totalConsumoPotencia <= comision[i].potenciaHasta) && comision[i].zona == zonaGeografica) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    } else {
                        resultado = comision.find((comision: any) => (comision.energiaDesde * 1000 >= Number(newProps.sip['ConsumoAnualTotalActiva']) && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision.energiaHasta * 1000));
                        let resultadoTemp: any = []
                        for (var i = 0, iLen = comision.length; i < iLen; i++) {
                            if ((Number(newProps.sip['ConsumoAnualTotalActiva']) >= comision[i].energiaDesde * 1000 && Number(newProps.sip['ConsumoAnualTotalActiva']) <= comision[i].energiaHasta * 1000) && (totalConsumoPotencia >= comision[i].potenciaDesde * 1000 && totalConsumoPotencia <= comision[i].potenciaHasta * 1000) && comision[i].zona == zonaGeografica) {
                                resultadoTemp = comision[i]
                                itemsTablaComisionTempConCo.push({ ...resultadoTemp, eurosMwh: row['eurosMwh'], tipoContratoAsociado: tipoContratoAsociado })
                            }
                        };
                    }
                    const minimun = itemsTablaComisionTempConCo.reduce((previous, current) => {
                        return current.energiaHasta < previous.energiaHasta ? current : previous;
                    });
                    itemsTablaComisionIncluidoCo.push({ idProducto: idProducto, idTipoComision: idTipoComision, ...minimun })
                }
                return row
            });

            let datacomisiones: any = []
            let datacomisionescoincluido: any = []
            for (var i = 0, iLen = itemsTablaComision.length; i < iLen; i++) {

                if (itemsTablaComision[i].idTipoComision == "E") {

                    if (itemsTablaComision[i].eurosMwh) {

                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: (itemsTablaComision[i].coReferencia / 1000) * 100,
                            coReferenciaPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coStandard: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coStandardPorcentaje: itemsTablaComision[i].coStandardPorcentaje,
                            coTE1: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTE2: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTE3: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTE4: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTE5: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTE6: (itemsTablaComision[i].coStandard / 1000) * 100,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    } else {

                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: itemsTablaComision[i].coReferencia,
                            coReferenciaPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coStandard: itemsTablaComision[i].coStandard,
                            coStandardPorcentaje: itemsTablaComision[i].coStandardPorcentaje,
                            coTE1: itemsTablaComision[i].coStandard,
                            coTE2: itemsTablaComision[i].coStandard,
                            coTE3: itemsTablaComision[i].coStandard,
                            coTE4: itemsTablaComision[i].coStandard,
                            coTE5: itemsTablaComision[i].coStandard,
                            coTE6: itemsTablaComision[i].coStandard,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    }

                } else if (itemsTablaComision[i].idTipoComision == "F") {

                    if (itemsTablaComision[i].eurosMwh) {

                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: (itemsTablaComision[i].co1 / 1000) * 100,
                            coReferenciaPorcentaje: itemsTablaComision[i].porcentajeco1,
                            coStandard: (itemsTablaComision[i].co3 / 1000) * 100,
                            coStandardPorcentaje: itemsTablaComision[i].porcentajeco3,
                            coTE1: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTE2: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTE3: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTE4: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTE5: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTE6: (itemsTablaComision[i].co3 / 1000) * 100,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    } else {
                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: itemsTablaComision[i].co1,
                            coReferenciaPorcentaje: itemsTablaComision[i].porcentajeco1,
                            coStandard: itemsTablaComision[i].co3,
                            coStandardPorcentaje: itemsTablaComision[i].porcentajeco3,
                            coTE1: itemsTablaComision[i].co3,
                            coTE2: itemsTablaComision[i].co3,
                            coTE3: itemsTablaComision[i].co3,
                            coTE4: itemsTablaComision[i].co3,
                            coTE5: itemsTablaComision[i].co3,
                            coTE6: itemsTablaComision[i].co3,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    }


                } else if (itemsTablaComision[i].idTipoComision == "G") {

                    if (itemsTablaComision[i].eurosMwh) {

                        console.log('cote1: ', (itemsTablaComision[i].coReferenciaMax  / 50 ))
                        console.log('itemsTablaComision[i].coReferenciaMax : ', itemsTablaComision[i].coReferenciaMax  )

                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: itemsTablaComision[i].coReferenciaMax,
                            coReferenciaPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coStandard: itemsTablaComision[i].coReferenciaMax,
                            coStandardPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coTE1: itemsTablaComision[i].coReferenciaMax,
                            coTE2: itemsTablaComision[i].coReferenciaMax,
                            coTE3: itemsTablaComision[i].coReferenciaMax,
                            coTE4: itemsTablaComision[i].coReferenciaMax,
                            coTE5: itemsTablaComision[i].coReferenciaMax,
                            coTE6: itemsTablaComision[i].coReferenciaMax ,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    } else {

                        console.log('cote1: ', (itemsTablaComision[i].coReferenciaMax  ))
                        console.log('itemsTablaComision[i].coReferenciaMax : ', itemsTablaComision[i].coReferenciaMax  )
                        datacomisiones.push({
                            idProducto: itemsTablaComision[i].idProducto, idComisionTabla: itemsTablaComision[i].comisionID,
                            idComision: itemsTablaComision[i].idTipoComision, coReferencia: itemsTablaComision[i].coReferenciaMax,
                            coReferenciaPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coStandard: itemsTablaComision[i].coReferenciaMax  ,
                            coStandardPorcentaje: itemsTablaComision[i].coReferenciaPorcentaje,
                            coTE1: itemsTablaComision[i].coReferenciaMax ,
                            coTE2: itemsTablaComision[i].coReferenciaMax ,
                            coTE3: itemsTablaComision[i].coReferenciaMax  ,
                            coTE4: itemsTablaComision[i].coReferenciaMax  ,
                            coTE5: itemsTablaComision[i].coReferenciaMax   ,
                            coTE6: itemsTablaComision[i].coReferenciaMax   ,
                            coTEP1: 0,
                            coTEP2: 0,
                            coTEP3: 0,
                            coTEP4: 0,
                            coTEP5: 0,
                            coTEP6: 0
                        })
                    }
                }
            };

            for (var i = 0, iLen = itemsTablaComisionIncluidoCo.length; i < iLen; i++) {

                if (itemsTablaComisionIncluidoCo[i].idTipoComision == "D") {

                    var valueTipoContratoAsociado = itemsTablaComisionIncluidoCo[i].tipoContratoAsociado

                    var key = Object.keys(itemsTablaComisionIncluidoCo[i]).find(key => itemsTablaComisionIncluidoCo[i][key] == valueTipoContratoAsociado)

                    var lastLetter = key ? key.charAt(key.length - 1) : ""

                    var comision = itemsTablaComisionIncluidoCo[i][`coTarifa${lastLetter}`]

                    if (itemsTablaComisionIncluidoCo[i].eurosMwh) {

                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    } else {

                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    }

                } else if (itemsTablaComisionIncluidoCo[i].idTipoComision == "A") {

                    var valueTipoContratoAsociado = itemsTablaComisionIncluidoCo[i].tipoContratoAsociado
                    var key = Object.keys(itemsTablaComisionIncluidoCo[i]).find(key => itemsTablaComisionIncluidoCo[i][key] == valueTipoContratoAsociado)
                    var lastLetter = key ? key.charAt(key.length - 1) : ""
                    var comision = itemsTablaComisionIncluidoCo[i][`coTarifa${lastLetter}`]
                    if (itemsTablaComisionIncluidoCo[i].eurosMwh) {
                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    } else {
                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    }

                } else if (itemsTablaComisionIncluidoCo[i].idTipoComision == "B") {

                    var comision = itemsTablaComisionIncluidoCo[i][`coTarifa`]
                    if (itemsTablaComisionIncluidoCo[i].eurosMwh) {
                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: comision, coStandard: comision
                        })
                    } else {
                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: comision, coStandard: comision
                        })
                    }

                } else if (itemsTablaComisionIncluidoCo[i].idTipoComision == "C") {

                    var valueTipoContratoAsociado = itemsTablaComisionIncluidoCo[i].tipoContratoAsociado

                    var key = Object.keys(itemsTablaComisionIncluidoCo[i]).find(key => itemsTablaComisionIncluidoCo[i][key] == valueTipoContratoAsociado)

                    var lastLetter = key ? key.charAt(key.length - 1) : ""

                    var comision = itemsTablaComisionIncluidoCo[i][`coTarifa${lastLetter}`]

                    if (itemsTablaComisionIncluidoCo[i].eurosMwh) {

                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    } else {

                        datacomisionescoincluido.push({
                            idProducto: itemsTablaComisionIncluidoCo[i].idProducto, idComisionTabla: itemsTablaComisionIncluidoCo[i].comisionID,
                            idComision: itemsTablaComisionIncluidoCo[i].idTipoComision, idTipoProductoComision: key, coStandard: comision
                        })
                    }

                };
            };

            newProps.productoMulti.productoSeleccionado.map(row => {
                var periodosNuevos = 0
                if (this.state.sip.codigoTarifaATREnVigor == '001' || this.state.sip.codigoTarifaATREnVigor == '004' || this.state.sip.codigoTarifaATREnVigor == '005' || this.state.sip.codigoTarifaATREnVigor == '006' || this.state.sip.codigoTarifaATREnVigor == '007' || this.state.sip.codigoTarifaATREnVigor == '008') {
                    periodosNuevos = 3
                } else if (this.state.sip.codigoTarifaATREnVigor == '018') {
                    periodosNuevos = 3
                } else { periodosNuevos = 6 }

                for (let i = 1; i <= periodosNuevos; i++) {

                    let consumoAnual = `ConsumoAnualActivaP${i}`

                    if (this.state.sip[consumoAnual] >= 0 && i <= periodosNuevos) {
                        let precioPotenciaField = `precioTP${i}`;
                        let precioEnergiaField = `precioTEP${i}`;
                        let periodo = `P${i}`;
                        periodos.push(i);
                        let precioPotencia = 0;
                        let precioEnergia = 0;
                        if (row['coIncluido'] === false) {

                            precioPotencia = row[precioPotenciaField] + costeOperativoPotencia;
                            precioEnergia = row[precioEnergiaField] + costeOperativo;
                        } else {
                            precioPotencia = row[precioPotenciaField];
                            precioEnergia = row[precioEnergiaField];
                        }
                        let id = row['id']

                        /*switch(row['precioTPTipo']) {
                            case 'DIARIO':
                                // // console.log('DENTRO DE DIARIO')
                                if (i===1){
                                precioPotencia = row['precioTPPeriodo'];}
                                else {precioPotencia = null};
                                break;
                            case 'MENSUAL':
                                // // console.log('DENTRO DE MENSUAL')
                                if (i===1){
                                precioPotencia = row['precioTPPeriodo'];}
                                else {precioPotencia = null};
                                break;
                        }
                        */

                        newItemsTabla.push({ id: id, periodo: periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia });
                    }
                }
                return row;
            });


            var consumosMaxMin = {
                max: {
                    P1: maxP1,
                    P2: maxP2,
                    P3: maxP3,
                    P4: maxP4,
                    P5: maxP5,
                    P6: maxP6
                },
                min: {
                    P1: minP1,
                    P2: minP2,
                    P3: minP3,
                    P4: minP4,
                    P5: minP5,
                    P6: minP6
                }
            };

            let potenciaContratoP1 = Number(newProps.sip.potenciasContratadasEnWP1)
            let potenciaContratoP2 = Number(newProps.sip.potenciasContratadasEnWP2)
            let potenciaContratoP3 = Number(newProps.sip.potenciasContratadasEnWP3)
            let potenciaContratoP4 = Number(newProps.sip.potenciasContratadasEnWP4)
            let potenciaContratoP5 = Number(newProps.sip.potenciasContratadasEnWP5)
            let potenciaContratoP6 = Number(newProps.sip.potenciasContratadasEnWP6)

            if (this.state.cambioPotencia == 1) {

                potenciaContratoP1 = this.state.formDatosContrato.potenciaContratoP1
                potenciaContratoP2 = this.state.formDatosContrato.potenciaContratoP2
                potenciaContratoP3 = this.state.formDatosContrato.potenciaContratoP3
                potenciaContratoP4 = this.state.formDatosContrato.potenciaContratoP4
                potenciaContratoP5 = this.state.formDatosContrato.potenciaContratoP5
                potenciaContratoP6 = this.state.formDatosContrato.potenciaContratoP6

            }

            let ConsumoAnualActivaP1 = newProps.sip.ConsumoAnualActivaP1
            let ConsumoAnualActivaP2 = newProps.sip.ConsumoAnualActivaP2
            let ConsumoAnualActivaP3 = newProps.sip.ConsumoAnualActivaP3
            let ConsumoAnualActivaP4 = newProps.sip.ConsumoAnualActivaP4
            let ConsumoAnualActivaP5 = newProps.sip.ConsumoAnualActivaP5
            let ConsumoAnualActivaP6 = newProps.sip.ConsumoAnualActivaP6
            let ConsumoAnualTotalActiva = newProps.sip.ConsumoAnualTotalActiva
            let descATR = newProps.sip.tarifaATR

            let tarifaATR = newProps.sip.codigoTarifaATREnVigor
            let bie = Number(newProps.sip.potenciaMaximaBIEW) || 0;
            var formDatosContrato = {
                tarifaATR: tarifaATR,
                descATR: descATR,
                bie: bie,
                potenciaP1: 0,
                potenciaP2: 0,
                potenciaP3: 0,
                potenciaP4: 0,
                potenciaP5: 0,
                potenciaP6: 0,
                potenciaContratoP1: potenciaContratoP1,
                potenciaContratoP2: potenciaContratoP2,
                potenciaContratoP3: potenciaContratoP3,
                potenciaContratoP4: potenciaContratoP4,
                potenciaContratoP5: potenciaContratoP5,
                potenciaContratoP6: potenciaContratoP6,
                consumoAnualActivaP1: ConsumoAnualActivaP1,
                consumoAnualActivaP2: ConsumoAnualActivaP2,
                consumoAnualActivaP3: ConsumoAnualActivaP3,
                consumoAnualActivaP4: ConsumoAnualActivaP4,
                consumoAnualActivaP5: ConsumoAnualActivaP5,
                consumoAnualActivaP6: ConsumoAnualActivaP6,
                consumoAnualTotalActiva: ConsumoAnualTotalActiva
            }
            inputState.producto = producto;
            inputState.itemsTabla = newItemsTabla;
            inputState.costeOperativo = costeOperativo;
            inputState.costeOperativoPotencia = costeOperativoPotencia;
            inputState.esNuevoContrato = newProps.productoMulti.esNuevoContrato;
            //inputState.active = newProps.producto.active;
            let comercialAgente = newProps.comercial

            this.setState({
                ...this.state,
                ...inputState,
                consumosMaxMin: consumosMaxMin,
                formDatosContrato: formDatosContrato,
                tablaComision: itemsTablaComision,
                tablaComisionConCo: itemsTablaComisionIncluidoCo,
                dataComisionesProducto: datacomisiones,
                dataComisionesProductoIncluidoCo: datacomisionescoincluido,
                comercialAgente: comercialAgente,
                totalComparacion: []
            }, () => {
                this.renderFacturaProducto();
                this.renderFacturaActual();
            })
        }
        else {
            this.setState({
                ...this.state,
                ...inputState
            })
        }

    };

    componentDidMount() {

        this.formActualRef = React.createRef();
        this.formMultiProductoRef = React.createRef();
        this.formDatosPropuestaRef = React.createRef();
        let adFincas = false
        let agencia = true
        let comercialUsuario = false
        let companyId = ""

        this.fetchItems();

        const userFromStorage = localStorage.getItem("user");

        console.log('valor de userFromStorage: ', userFromStorage)
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);

                console.log('parsed: ', parsed)

                adFincas = parsed.adFincas;
                agencia = parsed.agencia;
                comercialUsuario = parsed.comercial;
                companyId = parsed.companyId


            } catch (e) { }
        }

        this.setState({
            productSelected: null, adFincas, agencia, comercialUsuario, companyId
        });
    };

    //////////////////////////////////////////////////////////////////////////////

    errorAlertAprobacion = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Faltan datos obligatorios en los campos del formulario"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existen campos en el formulario sin valores. Revise los datos introducidos. Los datos obligatorios son nombre de la oferta, nombre del titular, identificador fiscal, correo electrónico y código postal del punto de suministro
                </ReactBSAlert>
            )
        });
    };



    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {

        // aquí empieza la selección de servicios por Comercializadora
        /*
            const companyUser = await JSON.parse(await localStorage.getItem("user")!);
            var idComercializadora = this.state.producto.idComercializadora;
            if (idComercializadora == null){
              idComercializadora = '0000'};
        
        
           await client.query({
                  query: LIST_COMERCIAL_SERVICIOS,
            variables: { idComercializadora: idComercializadora},
              })
              .then((result) => {
                  let data = result.data.comercialServicios;
        
                  this.setState({ comercialServicios: data})
              });
              */

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    fetchItemsMunicipio = async (codigoMunicipio) => {
        const codigoPostal = codigoMunicipio
        await client.query({
            query: LIST_DICMUNICIPIOSPOSTALSHORT,
            variables: { codigoPostal: codigoPostal }
        })
            .then((result) => {
                let data = result.data.listDicMunicipiosPostalShort;
                this.setState({ dicMunicipiosList: data })
            });
    };

    //////////////////////////////////////////////////////////////////////////

    onChangeFieldDatosClientes = (event) => {
        event.persist()

        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        let formData = { ...this.state.form };

        let target = event.target;

        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        if (name == "nombreTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['nombreTitular'] = false
            textErroresData['nombreTitular'] = "INTRODUZCA NOMBRE/RAZON SOCIAL"
        } else if (name == "nombreTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['nombreTitular'] = true
            textErroresData['nombreTitular'] = ""
        };

        if (name == "nombreOferta" && (!value || value.length == 0 || value == "")) {
            errorsData['nombreOferta'] = false
            textErroresData['nombreOferta'] = "INTRODUZCA NOMBRE DE LA OFERTA"
        } else if (name == "nombreOferta" && (value || value.length > 0 || value !== "")) {
            errorsData['nombreOferta'] = true
            textErroresData['nombreOferta'] = ""
        };

        if (name == "NIF" && (!value || value.length == 0 || value == "")) {
            errorsData['NIF'] = false
            textErroresData['NIF'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
        } else if (name == "NIF" && (value || value.length > 0 || value !== "")) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                const resultTipo = spainIdType(event.target.value)
                formData['tipoTitular'] = resultTipo.toUpperCase();
                errorsData['NIF'] = true
                textErroresData['NIF'] = ""
                this.setState({
                    isDisabledCIF: false
                })
            } else {
                errorsData['NIF'] = false
                textErroresData['NIF'] = "NIF/CIF INCORRECTO"
                this.setState({
                    isDisabledCIF: true
                })
            }
        };
        if (name == "NIFRepresentante" && (!value || value.length == 0 || value == "")) {
            errorsData['NIFRepresentante'] = false
            textErroresData['NIF'] = "INTRODUZCA NIF/CIF DEL REPRESENTANTE"
            this.setState({
                isDisabledCIFRepresentante: true
            })
        } else if (name == "NIF" && (value || value.length > 0 || value !== "")) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                const resultTipo = spainIdType(event.target.value)
                formData['TipoTitularRepresentante'] = resultTipo.toUpperCase();
                errorsData['NIFRepresentante'] = true
                textErroresData['NIFRepresentante'] = ""
                this.setState({
                    isDisabledCIFRepresentante: false
                })
            } else {
                errorsData['NIFRepresentante'] = false
                textErroresData['NIFRepresentante'] = "NIF/CIF INCORRECTO"
                this.setState({
                    isDisabledCIFRepresentante: true
                })
            }
        };

        if (name == "Email" && (!value || value.length == 0 || value == "")) {
            errorsData['Email'] = false
            textErroresData['Email'] = "SIN DATOS"
        } else if (name == "Email" && (value || value.length > 0 || value !== "")) {

            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {

                errorsData['Email'] = true
                textErroresData['Email'] = ""
            } else {
                errorsData['Email'] = false
                textErroresData['Email'] = "CORREO ELECTRONICO INCORRECTO"
            }
        };

        if (name == "correoRepresentante" && (!value || value.length == 0 || value == "")) {
            errorsData['correoRepresentante'] = false
            textErroresData['correoRepresentante'] = "SIN DATOS"
        } else if (name == "correoRepresentante" && (value || value.length > 0 || value !== "")) {

            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                errorsData['correoRepresentante'] = true
                textErroresData['correoRepresentante'] = ""
            } else {
                errorsData['correoRepresentante'] = false
                textErroresData['correoRepresentante'] = "CORREO ELECTRONICO INCORRECTO"
            }
        };


        if (name == "TelefonoFijo" && (!value || value.length == 0 || value == "")) {
            errorsData['TelefonoFijo'] = false
            textErroresData['TelefonoFijo'] = "SIN DATOS"
        } else if (name == "TelefonoFijo" && (value || value.length > 0 || value !== "")) {

            const pattern = /^[679]{1}[0-9]{8}$/
            var strPhone = ""

            strPhone = event.target.value.toString().replace(/\s/g, '');
            const result = strPhone.length === 9 && pattern.test(event.target.value);
            if (result === true) {
                errorsData['TelefonoFijo'] = true
                textErroresData['TelefonoFijo'] = ""
            } else {
                errorsData['TelefonoFijo'] = false
                textErroresData['TelefonoFijo'] = "TELEFONO INCORRECTO"
            }

        };

        if (event.target.name === 'CodigoPostalPS') {

            if (event.target.value) {
                errorsData['CodigoPostalPS'] = true
                textErroresData['CodigoPostalPS'] = ""
            } else {
                errorsData['CodigoPostalPS'] = false
                textErroresData['CodigoPostalPS'] = "SELECCIONE CODIGO POSTAL"
            }
        }

        if (name === "idComercializadora" && value !== "0") {
            if (value !== "0") {
                formData['nombreComercializadora'] = [
                    ...event.target.querySelectorAll("option"),
                ].filter((item) => item.value === value)[0].innerText;
                formData['idComercializadora'] = value;
                errorsData['idComercializadora'] = true
                textErroresData['idComercializadora'] = ""
            } else {
                errorsData['idComercializadora'] = false
                textErroresData['idComercializadora'] = "SELECCIONE COMERCIALIZADORA DE PROCEDENCIA"
                value = null;
            }
        }
        
        var valueTemp: any = [];

        if (name === "TipoCliente") {
            const valorIndex = event.target.options.selectedIndex.toString();
            valueTemp = this.state.TipoCliente.find(x => x.key === valorIndex)
            value = valueTemp.cat;
        }

        if (name == 'desMunicipioTitular') {
            const valueArray = event.target.value.split("*");
            value = valueArray[1]
            let codigoMunicipio = valueArray[0]
            const municipio = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
            const valorEntidad = resultado.Cmunicipio
            formData['desProvinciaTitular'] = resultado.DescProvincia
            formData['desAutonomiaTitular'] = resultado.DescAutonomia
            this.fetchItemsMunicipio(valorEntidad);
            formData['CodigoPostalTitular'] = 'Selecciona CP'
        }

        if (name == 'nombreMunicipioPS') {
            const valueArray = event.target.value.split("*");
            value = valueArray[1]
            let codigoMunicipio = valueArray[0]
            const municipio = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
            //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
            const valorEntidad = resultado.Cmunicipio
            formData['nombreProvinciaPS'] = resultado.DescProvincia
            formData['desAutonomiaPS'] = resultado.DescAutonomia
            this.fetchItemsMunicipio(valorEntidad);
            formData['CodigoPostalPS'] = 'Selecciona CP'
        }

        if (name == 'tieneRepresentante') {
            if (value == false) {
                this.showTable()
                this.setState({
                    isDisabledRepresentante: true
                })

            } else {
                this.showTable()
                this.setState({
                    isDisabledRepresentante: false
                })
            }
        }

        if (name == 'datosps') {
            if (value == false) {
                this.showps()
                this.setState({
                    isDisabledRepresentante: true
                })

            } else {
                this.showps()
                this.setState({
                    isDisabledRepresentante: false
                })
            }
        }

        if (name == 'datosContacto') {
            if (value == false) {
                this.showContacto()
                this.setState({
                    isDisabledRepresentante: true
                })

            } else {
                this.showContacto()
                this.setState({
                    isDisabledRepresentante: false
                })
            }
        }


        formData[name] = value;
        this.setState({
            form: formData,
            errores: errorsData,
            textErrores: textErroresData
        });
    };

    /////////////////////////////////////////////////////////////////////////////////

    generateComparativeControl = (event) => {

        event.preventDefault();

        if (this.state.errores['nombreOferta'] && this.state.errores['nombreTitular'] && this.state.errores['NIF'] && this.state.errores['Email'] && this.state.errores['CodigoPostalPS'] && this.state.enabledSave) {

            if (this.props.onSave) {

                if (this.state.form.nombreOferta) {
                    this.props.onSave(this.state.form);

                    this.setState({
                        form: {
                            nombreOferta: "",
                            correoContacto: "",
                            idComercializadora: "",
                            nombreComercializadora: "",
                            esAprobada: false,
                            tieneRepresentante: false,
                            razonSocial: "",
                            NIF: "", //"C19345456", --> CIF
                            DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                            nombreTitular: "",
                            apellido1Titular: "",
                            apellido2Titular: "",
                            tipoTitular: "",
                            tipoTitularRepresentante: "",
                            nombreRepresentante: "",
                            apellido1Representante: "",
                            apellido2Representante: "",
                            NIFRepresentante: "",
                            CargoRepresentante: "",
                            idRepresentante: "",
                            idRepresentanteInterno: 0,
                            telefonoRepresentante: "",
                            correoRepresentante: "",
                            companyIdRepresentante: "",
                            desAutonomiaTitular: "",
                            desProvinciaTitular: "",
                            desMunicipioTitular: "",
                            tipoViaTitular: "",
                            viaTitular: "",
                            numFincaTitular: "",
                            portalTitular: "",
                            escaleraTitular: "",
                            pisoTitular: "",
                            puertaTitular: "",
                            iban: "",
                            CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
                            TelefonoFijo: "", //"949201893", ----> telefono_fijo
                            TelefonMovil: "", //"689105332", ----> telefono
                            Email: "", //"demo@demo.com", --->email_contacto
                            TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                            ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones

                            DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                            nombreMunicipioPS: "",
                            nombreProvinciaPS: "",
                            //nombreAutonomiaPS: "",
                            tipoViaPS: "",
                            viaPS: "",
                            numFincaPS: "",
                            portalPS: "",
                            escaleraPS: "",
                            pisoPS: "",
                            puertaPS: "",
                            CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
                            TelefonoPS: "", //"689105332", ----> telefono;
                            ObservacionesPuntoSuministro: "",
                            GDPR: true, // "opcional o null" ----> observaciones_ps
                            datosps: true,
                            datosContacto: true

                        },

                        nombreOfertaError: false,
                        /* emailError: emailError,
                         nombreTitularError: nombreTitularError,
                         NIFError: NIFError,
                         DireccionFiscalError: DireccionFiscalError,
                         CodigoPostalTitularError: CodigoPostalTitularError,
                         TelefonoFijoError: TelefonoFijoError,
                         TelefonMovilError: TelefonMovilError,
                         DireccionPSError: direccionPSError,
                         CodigoPostalPSError: CodigoPostalPSError,
                         TelefonoPSError: TelefonoPSError,
                         apellido1TitularError: apellido1TitularError,*/
                        emailError: true,
                        nombreTitularError: true,
                        NIFError: true,
                        DireccionFiscalError: true,
                        CodigoPostalTitularError: true,
                        TelefonoFijoError: true,
                        TelefonMovilError: true,
                        DireccionPSError: true,
                        CodigoPostalPSError: true,
                        TelefonoPSError: true,
                        apellido1TitularError: true,
                        idComercializadoraError: true
                    });
                    this.forceUpdate();
                    return;
                }
            }


        } else {

            this.errorAlertAprobacion();

        }



    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };


    ////////////////////////////////////////////////////////////////////////////////

    getClient = async () => {
        var companyId = this.state.companyId.toString();
        var user = this.state.userId
        var CIF = this.state.form.NIF

        const userFromStorage = localStorage.getItem("user");

        console.log('valor de userFromStorage: ', userFromStorage)
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyId = parsed.companyId
            } catch (e) { }
        }

        console.log('valor de companyId: ', companyId)

        this.setState({
            companyId
        });


        if (CIF !== null && CIF != '') {
            let errorsData = { ...this.state.errores }
            let textErroresData = { ...this.state.textErrores }


            var dataFilters = {
                'idCompany': companyId,
                'cups': "",
                'Usuario': "",
                'NIF': CIF,
                'idContrato': ""
            };

            const valores = JSON.stringify(dataFilters)
            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };

            let direccionFile = "https://datapi.psgestion.es/cliente/listadoID?id=" + companyId + "&user=" + user;

            await axios.get(direccionFile, { headers, params: { valores } }).
                then(result => {

                    if (result.data.rows.length > 0) {
                        let data = result.data.rows[0]
                      

 
                        this.setState({ client: data })
                        if (data.NombreTitular !== null && data.NombreTitular != '') {
                            errorsData['nombreTitular'] = true
                            textErroresData['nombreTitular'] = ""
                        } else {
                            errorsData['nombreTitular'] = false
                            textErroresData['nombreTitular'] = "INTRODUZCA NOMBRE/RAZON SOCIAL"
                        }
                                             
                        if (!data.TelefonoFijo || data.TelefonoFijo.length == 0 || data.TelefonoFijo == "") {
                            errorsData['TelefonoFijo'] = false
                            textErroresData['TelefonoFijo'] = "SIN DATOS"
                        } else if (data.TelefonoFijo || data.TelefonoFijo.length > 0 || data.TelefonoFijo !== "") {
                
                            const pattern = /^[679]{1}[0-9]{8}$/
                            var strPhone = ""
                
                            strPhone = data.TelefonoFijo.toString().replace(/\s/g, '');
                            const result = strPhone.length === 9 && pattern.test(data.TelefonoFijo);
                            if (result === true) {
                                errorsData['TelefonoFijo'] = true
                                textErroresData['TelefonoFijo'] = ""
                            } else {
                                errorsData['TelefonoFijo'] = false
                                textErroresData['TelefonoFijo'] = "TELEFONO INCORRECTO"
                            }
                        };

                        if (data.Email || data.Email.length == 0 || data.Email == "") {
                            errorsData['Email'] = false
                            textErroresData['Email'] = "SIN DATOS"
                        } else if (data.Email || data.Email.length > 0 || data.Email !== "") {
                            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                            const result = pattern.test(data.Email);
                
                            if (result === true) {
                
                                errorsData['Email'] = true
                                textErroresData['Email'] = ""
                            } else {
                                errorsData['Email'] = false
                                textErroresData['Email'] = "CORREO ELECTRONICO INCORRECTO"
                            }
                        };

                        let tipoTitular = ""

                        if (!data.NIF || data.NIF.length == 0 || data.NIF == "") {
                            errorsData['NIF'] = false
                            textErroresData['NIF'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
                            this.setState({
                                isDisabledCIF: true
                            })
                        } else if (data.NIF || data.NIF.length > 0 || data.NIF !== "") {
                            const result = validateSpanishId(data.NIF)
                            if (result === true) {
                                const resultTipo = spainIdType(data.NIF)
                                tipoTitular = resultTipo.toUpperCase();
                                errorsData['NIF'] = true
                                textErroresData['NIF'] = ""
                                this.setState({
                                    isDisabledCIF: false
                                })
                            } else {
                                errorsData['NIF'] = false
                                textErroresData['NIF'] = "NIF/CIF INCORRECTO"
                                this.setState({
                                    isDisabledCIF: true
                                })
                            }
                        };

                        let nombreOferta = this.state.form.nombreOferta
                        //let correoContacto  = this.state.form.correoContacto
                        let idComercializadora = this.state.form.idComercializadora
                        let nombreComercializadora = this.state.form.nombreComercializadora
                        let esAprobada = this.state.form.esAprobada
                        let tieneRepresentante = this.state.form.tieneRepresentante
                        let razonSocial = this.state.form.razonSocial
                        let NIF = this.state.form.NIF
                        let DireccionFiscal = this.state.form.DireccionFiscal
                        let TipoCliente = this.state.form.TipoCliente
                        let ObservacionesCliente = this.state.form.ObservacionesCliente
                        let DireccionPS = this.state.form.DireccionPS
                        let nombreMunicipioPS = this.state.form.nombreMunicipioPS
                        let nombreProvinciaPS = this.state.form.nombreProvinciaPS
                        //   let nombreAutonomiaPS = this.state.form.nombreAutonomiaPS
                        let tipoViaPS = this.state.form.tipoViaPS
                        let viaPS = this.state.form.viaPS
                        let numFincaPS = this.state.form.numFincaPS
                        let portalPS = this.state.form.portalPS
                        let escaleraPS = this.state.form.escaleraPS
                        let pisoPS = this.state.form.pisoPS
                        let puertaPS = this.state.form.puertaPS
                        let CodigoPostalPS = this.state.form.CodigoPostalPS?.padStart(4, "0") || ''
                        let TelefonoPS = this.state.form.TelefonoPS
                        let ObservacionesPuntoSuministro = this.state.form.ObservacionesPuntoSuministro
                        let GDPR = this.state.form.GDPR

                        this.setState({
                            form: {
                                nombreOferta: nombreOferta,
                                correoContacto: data.correoElectronico,
                                idComercializadora: idComercializadora,
                                nombreComercializadora: nombreComercializadora,
                                esAprobada: esAprobada,
                                tieneRepresentante: tieneRepresentante,
                                datosps: this.state.form.datosps,
                                datosContacto: this.state.form.datosContacto,
                                razonSocial: razonSocial,
                                NIF: NIF,
                                DireccionFiscal: DireccionFiscal,
                                nombreTitular: data.NombreTitular,
                                apellido1Titular: data.Apellido1Titular,
                                apellido2Titular: data.Apellido2Titular,
                                tipoTitular: tipoTitular,
                                tipoTitularRepresentante: "",
                                nombreRepresentante: "",
                                apellido1Representante: "",
                                apellido2Representante: "",
                                NIFRepresentante: "",
                                CargoRepresentante: "",
                                idRepresentante: "",
                                idRepresentanteInterno: 0,
                                telefonoRepresentante: "",
                                correoRepresentante: "",
                                companyIdRepresentante: "",
                                desProvinciaTitular: data.ProvinciaTitular,
                                desMunicipioTitular: data.MunicipioTitular,
                                desAutonomiaTitular: data.AutonomiaTitular,
                                tipoViaTitular: data.TipoViaTitular,
                                viaTitular: data.ViaTitular,
                                numFincaTitular: data.NumFincaTitular,
                                portalTitular: data.PortalTitular,
                                escaleraTitular: data.EscaleraTitular,
                                pisoTitular: data.PisoTitular,
                                puertaTitular: data.PuertaTitular,
                                iban: data.IBAN,
                                CodigoPostalTitular: data.CodigoPostalTitular?.padStart(4, "0") || '', //"08630",----> codigo_postal_titular
                                TelefonoFijo: data.TelefonoFijo, //"data.codigoPostalTitular49201893", ----> telefono_fijo
                                TelefonMovil: data.TelefonMovil, //"689105332", ----> telefono
                                Email: data.Email, //"demo@demo.com", --->email_contacto
                                TipoCliente: TipoCliente, //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                                ObservacionesCliente: ObservacionesCliente, //"OPCIONAL O NULL",----> observaciones

                                DireccionPS: DireccionPS, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                                nombreMunicipioPS: nombreMunicipioPS,
                                nombreProvinciaPS: nombreProvinciaPS,
                                //               nombreAutonomiaPS: nombreAutonomiaPS,
                                tipoViaPS: tipoViaPS,
                                viaPS: viaPS,
                                numFincaPS: numFincaPS,
                                portalPS: portalPS,
                                escaleraPS: escaleraPS,
                                pisoPS: pisoPS,
                                puertaPS: puertaPS,
                                CodigoPostalPS: CodigoPostalPS?.padStart(4, "0") || '', //"08630", ----> codigo_postal_ps
                                TelefonoPS: TelefonoPS, //"689105332", ----> telefono;
                                ObservacionesPuntoSuministro: ObservacionesPuntoSuministro,
                                GDPR: GDPR, // "opcional o null" ----> observaciones_ps

                            },
                            errores: errorsData,
                            textErrores: textErroresData
                        });
                    } else {
                        notifyError();
                    };

                }).catch((error) => {
                    //this.warningAlertDB()
                    notifyError();
                });
        } else {
            notifyErrorCIF();


        };


    };

    ///////////////////////////////////////////////////////////////////////////

    getRepresentante = async () => {

        var companyId = this.state.companyId.toString();
        var user = this.state.userId
        var CIF = this.state.form.NIFRepresentante

        const userFromStorage = localStorage.getItem("user");

        console.log('valor de userFromStorage: ', userFromStorage)
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyId = parsed.companyId
                user = parsed.id
            } catch (e) { }
        }

        console.log('valor de companyId: ', companyId)

        this.setState({
            companyId,
            user
        });

        if (CIF !== null && CIF != '') {

            let errorsData = { ...this.state.errores }
            let textErroresData = { ...this.state.textErrores }

            var dataFilters = {
                'idCompany': companyId,
                'cups': "",
                'Usuario': "",
                'NIFRepresentante': CIF,
                'idContrato': ""
            };

            const valores = JSON.stringify(dataFilters)
            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };

            let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyId + "&user=" + user;

            await axios.get(direccionFile, { headers, params: { valores } }).
                then(result => {

                    if (result.data.rows.length > 0) {
                        let data = result.data.rows
                        //this.setState({ representantes: data })
                        let formData = { ...this.state.form };
                        formData['nombreRepresentante'] = data[0].nombreRepresentante
                        formData['apellido1Representante'] = data[0].apellido1Representante
                        formData['apellido2Representante'] = data[0].apellido2Representante
                        formData['NIFRepresentante'] = data[0].NIFRepresentante
                        formData['idRepresentante'] = data[0].idRepresentante
                        formData['idRepresentanteInterno'] = data[0].idRepresentanteInterno
                        formData['CargoRepresentante'] = data[0].CargoRepresentante
                        formData['telefonoRepresentante'] = data[0].telefonoRepresentante
                        formData['correoRepresentante'] = data[0].correoRepresentante


                        if (!data[0].NIFRepresentante || data[0].NIFRepresentante.length == 0 || data[0].NIFRepresentante == "") {
                            errorsData['NIFRepresentante'] = false
                            textErroresData['NIFRepresentante'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
                            this.setState({
                                isDisabledCIFRepresentante: true
                            })

                        } else if (data[0].NIFRepresentante || data[0].NIFRepresentante.length > 0 || data[0].NIFRepresentante.NIF !== "") {
                            const result = validateSpanishId(data[0].NIFRepresentante)
                            if (result === true) {
                                const resultTipo = spainIdType(data[0].NIFRepresentante)
                                formData['TipoTitularRepresentante'] = resultTipo.toUpperCase();
                                errorsData['NIFRepresentante'] = true
                                textErroresData['NIFRepresentante'] = ""
                                this.setState({
                                    isDisabledCIFRepresentante: false
                                })
                            } else {
                                errorsData['NIFRepresentante'] = false
                                textErroresData['NIFRepresentante'] = "NIF/CIF INCORRECTO"
                                this.setState({
                                    isDisabledCIFRepresentante: true
                                })
                            }
                        };

                        this.setState({ form: formData,
                            errores: errorsData,
                            textErrores: textErroresData });
                    } else {
                        notifyError();
                    };

                }).catch((error) => {
                    notifyError();
                });
        } else {
            notifyErrorCIF();
        };
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    validateEmail = (email) => {

        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        const result = pattern.test(email);
        if (result === true) {
            this.setState({
                emailError: false,
            })
        } else {
            this.setState({
                emailError: true
            })
        }
    }

    validatePhone = (TelefonoFijo) => {
        //const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = ^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}
        const pattern = /^[679]{1}[0-9]{8}$/
        var strPhone = ""
        if (TelefonoFijo) {
            strPhone = TelefonoFijo.toString().replace(/\s/g, '');
        }

        const result = strPhone.length === 9 && pattern.test(TelefonoFijo);
        if (result === true) {
            this.setState({
                TelefonoFijoError: false,
            })
        } else {
            this.setState({
                TelefonoFijoError: true
            })
        }
    }


    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });
            const reftp = document.getElementById("datosRepresentante")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {

            this.setState({
                show: false
            });
        }
    };

    showps = () => {
        if (this.state.showps === false) {
            this.setState({
                showps: true
            });
            const reftp = document.getElementById("datosPS")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {

            this.setState({
                showps: false
            });
        }
    };

    showContacto = () => {
        if (this.state.showContacto === false) {
            this.setState({
                showContacto: true
            });
            const reftp = document.getElementById("datosContacto")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {

            this.setState({
                showContacto: false
            });
        }
    };


    onChangeFieldFormActual = async (event) => {
        event.persist()
        let inputs: any = await this.onChangeField(event);
        //let outputData = { ...this.stateOutput };
        let enabledSave: boolean = this.state.enabledSave

        if (event.target.name.includes("precioT")) {
            //outputData['enabledSave'] = true;
            enabledSave = true
        }

        if (inputs) {
            /*   this.setState({
                   formActual: {
                       ...this.state.formActual,
                       ...inputs
                   }
               }, () => {
                   this.renderFacturaActual();
                   this.stateOutput();
               })*/
            this.setState({
                enabledSave: enabledSave,
                formActual: {
                    ...this.state.formActual,
                    ...inputs
                }
            }, this.renderFacturaActual)

        }
    };


    onChangeFieldformMultiProducto = async (event) => {
        let inputs: any = await this.onChangeField(event);

        let cambioPotencia = this.state.cambioPotencia || 0
        if (inputs) {

            /*        if (inputs.potenciaContratoP1 || inputs.potenciaContratoP2 || inputs.potenciaContratoP3 || inputs.potenciaContratoP4 || inputs.potenciaContratoP5 || inputs.potenciaContratoP6) {
                        cambioPotencia = 1
                    }*/
            this.setState({
                formMultiProducto: {
                    ...this.state.formMultiProducto,
                    ...inputs
                },
                //cambioPotencia: cambioPotencia
            }, this.renderFacturaProducto);
        }
    };

    onChangeFieldformMultiProductoPotencia = async (event) => {
        let inputs: any = await this.onChangeField(event);

        let cambioPotencia = 0
        let bie = this.state.formDatosContrato.bie || 0
        let tarifaATR = this.state.formDatosContrato.tarifaATR || 0
        let formDatosContrato = this.state.formDatosContrato

        if (inputs) {

            if (this.state.periods.length > 3) {
                if (inputs.potenciaContratoP1) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (inputs.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyP1Error();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP2) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= inputs.potenciaContratoP2 && inputs.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP3) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= inputs.potenciaContratoP3 && inputs.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP4) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= inputs.potenciaContratoP4 && inputs.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP5) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= inputs.potenciaContratoP5 && inputs.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP6) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie || inputs.potenciaContratoP6 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= inputs.potenciaContratoP6) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {

                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }
            } else {

                if (inputs.potenciaContratoP1) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie) {
                        if (inputs.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyP1Error();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }

                if (inputs.potenciaContratoP2) {
                    if (inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie) {
                        if (formDatosContrato.potenciaContratoP1 <= inputs.potenciaContratoP2) {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato,
                                    ...inputs
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                        } else {
                            cambioPotencia = 1
                            this.setState({
                                formDatosContrato: {
                                    ...this.state.formDatosContrato
                                },
                                cambioPotencia: cambioPotencia
                            }, this.renderFacturaProducto);
                            notifyPError();

                        };
                    } else {
                        cambioPotencia = 1
                        this.setState({
                            formDatosContrato: {
                                ...this.state.formDatosContrato
                            },
                            cambioPotencia: cambioPotencia
                        }, this.renderFacturaProducto);
                        notifyBIE();
                    }
                }


            }
        }
    }

    onChangeFieldformMultiProductoServicio = async (event) => {
        let inputs: any = await this.onChangeField(event);
        let opcion = parseInt(inputs.idServicio)
        let idservicio = null;
        let idComercializadora = null;
        let nombreComercializadora = null;
        let nombreServicio = null;
        let precioServicio = null;

        /*
                idservicio: null,
                idComercializadora: null,
                nombreComercializadora: null,
                nombreServicio: null,
                precioServicio: 0,
        
                id: "09180001"
        idComercializadora: "0918"
        infoAdicional: null
        nombreComercializadora: "ALDRO ENERGIA Y SOLUCIONES S.L"
        nombreServicio: "Exprés24"
        precio: 5.9
        */

        this.state.comercialServicios.map(row => {
            let fila = parseInt(row['id']);
            if (fila === opcion) {
                idservicio = row['id'];
                idComercializadora = row['idComercializadora'];
                nombreComercializadora = row['nombreComercializadora'];
                nombreServicio = row['nombreServicio'];
                precioServicio = row['precio'];
            }
            return row;
        });


        {
            this.setState({
                idservicio: idservicio,
                idComercializadora: idComercializadora,
                nombreComercializadora: nombreComercializadora,
                nombreServicio: nombreServicio,
                precioServicio: precioServicio

            }, this.renderFacturaProducto);
        }

    };

    private calculateAhorro = (periodos: number[], facturaActual: any, facturaProducto: any) => {

        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total: any = facturaProducto

        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100 / totalFacturaActual);
        let datosAhorro = [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length] = totalFacturaActual;
        datosAhorro[datosAhorro.length] = totalFacturaProducto;
        datosAhorro[datosAhorro.length] = ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent) ? 0 : ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            idProducto: facturaProducto.id,
            nombreOferta: facturaProducto.nombreProducto,
            idComercializadora: facturaProducto.idComercializadora,
            nombreComercializadora: facturaProducto.nombreComercializadora,
            precioEnergiaP1: facturaProducto.precioEnergiaP1,
            precioEnergiaP2: facturaProducto.precioEnergiaP2,
            precioEnergiaP3: facturaProducto.precioEnergiaP3,
            precioEnergiaP4: facturaProducto.precioEnergiaP4,
            precioEnergiaP5: facturaProducto.precioEnergiaP5,
            precioEnergiaP6: facturaProducto.precioEnergiaP6,
            precioPotenciaP1: facturaProducto.precioPotenciaP1,
            precioPotenciaP2: facturaProducto.precioPotenciaP2,
            precioPotenciaP3: facturaProducto.precioPotenciaP3,
            precioPotenciaP4: facturaProducto.precioPotenciaP4,
            precioPotenciaP5: facturaProducto.precioPotenciaP5,
            precioPotenciaP6: facturaProducto.precioPotenciaP6,
            datosAhorro: datosAhorro
        }
    };


    private calculateAhorroMulti = (periodos: number[], facturaActual: any, facturaProducto: any) => {

        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total: any = facturaProducto

        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100 / totalFacturaActual);
        let datosAhorro = [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length] = totalFacturaActual;
        datosAhorro[datosAhorro.length] = totalFacturaProducto;
        datosAhorro[datosAhorro.length] = ahorroPeriodo;



        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent) ? 0 : ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    };

    private calculatePrecioProducto = (periodos: number[], producto: any, factura: any) => {
        let {
            serviciosAdicionales,
            serviciosAdicionalesMonth,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            peajeATR,
            codigoATRActual,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            Potencia,
            idComercializadora,
            idServicio,
            nombreComercializadora,
            nombreServicio,
            precioServicio,
            idProducto,
            nombreProducto,
            comisionContratoFija,
            comisionContratoConsumoAgente1,
            comisionContratoConsumoAgente2,
            comisionContratoConsumoAdmin
            //datasetProducto
        } = factura;
        var costeOperativo = this.state.costeOperativo;
        var costeOperativoPotencia = this.state.costeOperativoPotencia
        const sip = this.state.sip;
        const ubi = this.state.ubi;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;

        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        let potenciaP1 = 0;
        let potenciaP2 = 0;
        let potenciaP3 = 0;
        let potenciaP4 = 0;
        let potenciaP5 = 0;
        let potenciaP6 = 0;

        let potenciaContratoP1 = 0;
        let potenciaContratoP2 = 0;
        let potenciaContratoP3 = 0;
        let potenciaContratoP4 = 0;
        let potenciaContratoP5 = 0;
        let potenciaContratoP6 = 0;

        let energiaActivaP1 = 0;
        let energiaActivaP2 = 0;
        let energiaActivaP3 = 0;
        let energiaActivaP4 = 0;
        let energiaActivaP5 = 0;
        let energiaActivaP6 = 0;

        let energiaReactivaP1 = 0;
        let energiaReactivaP2 = 0;
        let energiaReactivaP3 = 0;
        let energiaReactivaP4 = 0;
        let energiaReactivaP5 = 0;
        let energiaReactivaP6 = 0;

        let energiaReactivaExcesoP1 = 0;
        let energiaReactivaExcesoP2 = 0;
        let energiaReactivaExcesoP3 = 0;
        let energiaReactivaExcesoP4 = 0;
        let energiaReactivaExcesoP5 = 0;
        let energiaReactivaExcesoP6 = 0;

        let penalizacionP1 = 0;
        let penalizacionP2 = 0;
        let penalizacionP3 = 0;
        let penalizacionP4 = 0;
        let penalizacionP5 = 0;
        let penalizacionP6 = 0;

        let penalizacionReactivaP1 = 0;
        let penalizacionReactivaP2 = 0;
        let penalizacionReactivaP3 = 0;
        let penalizacionReactivaP4 = 0;
        let penalizacionReactivaP5 = 0;
        let penalizacionReactivaP6 = 0;

        let precioPotenciaP1 = 0;
        let precioPotenciaP2 = 0;
        let precioPotenciaP3 = 0;
        let precioPotenciaP4 = 0;
        let precioPotenciaP5 = 0;
        let precioPotenciaP6 = 0;

        let precioEnergiaP1 = 0
        let precioEnergiaP2 = 0
        let precioEnergiaP3 = 0
        let precioEnergiaP4 = 0
        let precioEnergiaP5 = 0
        let precioEnergiaP6 = 0
        let idProductoAux = ""

        sumEnergia = 0;
        sumPotencia = 0;
        let sumPenalizacionPotencia = 0
        let sumPenalizacionReactiva = 0

        numDaysNew = 0;
        let numDaysNewP = 0;
        let numDaysPotencia = 0;
        let numDaysP1 = 0;
        let numDaysP2 = 0;
        let numDaysP3 = 0;
        let numDaysP4 = 0;
        let numDaysP5 = 0;
        let numDaysP6 = 0;

        let numDaysPE1 = 0;
        let numDaysPE2 = 0;
        let numDaysPE3 = 0;
        let numDaysPE4 = 0;
        let numDaysPE5 = 0;
        let numDaysPE6 = 0;
        let sumatotalPotencia = 0;
        let sumatotalReactiva = 0;

        numMonthNew = 0;
        let monthLectura = 0;
        let yearLectura = 0;
        let dayscheck = 0;
        let valorPhi = 0;

        this.state.itemsConsumo.map(row => {
            if (fechaDesdeConsumo == null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            }
            return row;
        });

        this.setState({
            daysOperacion: numDaysNew,
        })

        //// REALIZAMOS LA LOGICA PARA REPRESENTAR LOS DATOS DE Potencia

        let numberRowConsumo = Object.keys(this.state.itemsConsumo).length;

        if (this.state.cambioPotencia === 1) {
            if (this.state.rates1XP.includes(this.state.sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
            } else if (this.state.rates1XP.includes(this.state.sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)) {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
            } else if (this.state.rates2XP.includes(this.state.sip.codigoTarifaATREnVigor)) {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
            } else if (this.state.rates3XP.includes(this.state.sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020")) {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                potenciaContratoP4 += (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                potenciaContratoP5 += (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                potenciaContratoP6 += (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
            } else if (this.state.rates3XP.includes(this.state.sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)) {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
            } else if (rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) && rates6XPotencia.includes(producto.tarifaATR)) {
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                potenciaContratoP4 += (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                potenciaContratoP5 += (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                potenciaContratoP6 += (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
            }
            else {
                // console.log('TARIFA PRODUCTO ', producto.tarifaATR )
            }

        } else {
            if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP1) || 0);
            } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)) {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
            } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)) {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
            } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020")) {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP4 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP5 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP6 = (Number(sip.potenciasContratadasEnWP3) || 0);
            } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)) {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP3) || 0);
            } else {
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP3) || 0);
                potenciaContratoP4 = (Number(sip.potenciasContratadasEnWP4) || 0);
                potenciaContratoP5 = (Number(sip.potenciasContratadasEnWP5) || 0);
                potenciaContratoP6 = (Number(sip.potenciasContratadasEnWP6) || 0);
            }
        };

        //// HACEMOS LOS CALCULOS DE TOTALES DE POTENCIA //

        if (this.state.cambioPotencia === 1) {

            sumatotalPotencia = 0;

            this.state.itemsConsumo.map(row => {

                if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                    potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)) {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)) {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                    potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020")) {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                    potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                    potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                    potenciaP4 = (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                    potenciaP5 = (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                    potenciaP6 = (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
                } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)) {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                    potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                    potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                } else {
                    potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                    potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                    potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                    potenciaP4 = (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                    potenciaP5 = (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                    potenciaP6 = (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
                }

                if (rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15)) {

                    if ((Number(row['potenciaDemandadaEnWP1']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP1)) {
                        penalizacionP1 = ((2 * ((Number(row['potenciaDemandadaEnWP1'])) - Number(this.state.formDatosContrato.potenciaContratoP1)) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP2']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP2)) {
                        penalizacionP2 = ((2 * ((Number(row['potenciaDemandadaEnWP2'])) - Number(this.state.formDatosContrato.potenciaContratoP2)) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP3']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP3)) {
                        penalizacionP3 = ((2 * ((Number(row['potenciaDemandadaEnWP3'])) - Number(this.state.formDatosContrato.potenciaContratoP3)) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP4']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP4)) {
                        penalizacionP4 = ((2 * ((Number(row['potenciaDemandadaEnWP4'])) - Number(this.state.formDatosContrato.potenciaContratoP4)) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP5']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP5)) {
                        penalizacionP5 = ((2 * ((Number(row['potenciaDemandadaEnWP5'])) - Number(this.state.formDatosContrato.potenciaContratoP5)) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP6']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP6)) {
                        penalizacionP6 = ((2 * ((Number(row['potenciaDemandadaEnWP6'])) - Number(this.state.formDatosContrato.potenciaContratoP6)) * 1.406400))
                    }

                }
                sumatotalPotencia += penalizacionP1 + penalizacionP2 + penalizacionP3 + penalizacionP4 + penalizacionP5 + penalizacionP6

                return row;
            });

        } else {

            this.state.itemsConsumo.map(row => {
                if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {
                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                    potenciaP2 += (Number(sip.potenciasContratadasEnWP1) || 0);
                } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)) {
                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)) {
                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                    potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020")) {
                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                    potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP3 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP4 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP5 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP6 += (Number(sip.potenciasContratadasEnWP3) || 0);
                } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)) {
                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                    potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP3 += (Number(sip.potenciasContratadasEnWP3) || 0);
                } else if (rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor || sip.codigoTarifaATREnVigor === "003" || sip.codigoTarifaATREnVigor === "019") && rates6XPotencia.includes(producto.tarifaATR)) {

                    potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                    potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP3 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP4 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP5 += (Number(sip.potenciasContratadasEnWP2) || 0);
                    potenciaP6 += (Number(sip.potenciasContratadasEnWP3) || 0);
                } else {
                    /*potenciaP1 += (Number(row['potenciaDemandadaEnWP1']) || 0);
                    potenciaP2 += (Number(row['potenciaDemandadaEnWP2']) || 0);
                    potenciaP3 += (Number(row['potenciaDemandadaEnWP3']) || 0);
                    potenciaP4 += (Number(row['potenciaDemandadaEnWP4']) || 0);
                    potenciaP5 += (Number(row['potenciaDemandadaEnWP5']) || 0);
                    potenciaP6 += (Number(row['potenciaDemandadaEnWP6']) || 0);*/
                }

                if (rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15)) {

                    if ((Number(row['potenciaDemandadaEnWP1']) || 0) > (Number(sip.potenciasContratadasEnWP1) || 0)) {
                        penalizacionP1 = (((2 * ((Number(row['potenciaDemandadaEnWP1'])) - (Number(sip.potenciasContratadasEnWP1) || 0))) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP2']) || 0) > (Number(sip.potenciasContratadasEnWP2) || 0)) {
                        penalizacionP2 = (((2 * ((Number(row['potenciaDemandadaEnWP2'])) - (Number(sip.potenciasContratadasEnWP2) || 0))) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP3']) || 0) > (Number(sip.potenciasContratadasEnWP3) || 0)) {
                        penalizacionP3 = (((2 * ((Number(row['potenciaDemandadaEnWP3'])) - (Number(sip.potenciasContratadasEnWP3) || 0))) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP4']) || 0) > (Number(sip.potenciasContratadasEnWP4) || 0)) {
                        penalizacionP4 = (((2 * ((Number(row['potenciaDemandadaEnWP4'])) - (Number(sip.potenciasContratadasEnWP4) || 0))) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP5']) || 0) > (Number(sip.potenciasContratadasEnWP5) || 0)) {
                        penalizacionP5 = (((2 * ((Number(row['potenciaDemandadaEnWP5'])) - (Number(sip.potenciasContratadasEnWP5) || 0))) * 1.406400))
                    }

                    if ((Number(row['potenciaDemandadaEnWP6']) || 0) > (Number(sip.potenciasContratadasEnWP6) || 0)) {
                        penalizacionP6 = (((2 * ((Number(row['potenciaDemandadaEnWP6'])) - (Number(sip.potenciasContratadasEnWP6) || 0))) * 1.406400))
                    }

                }
                sumatotalPotencia += penalizacionP1 + penalizacionP2 + penalizacionP3 + penalizacionP4 + penalizacionP5 + penalizacionP6
                return row;
            });


        }


        this.state.itemsConsumo.map(row => {
            if (this.state.rates1XE.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {
                energiaActivaP1 += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0) * 0.289);
                energiaActivaP2 += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0) * 0.264);
                energiaActivaP3 += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0) * 0.447);
            } else if (this.state.rates1XE.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XE.includes(producto.tarifaATR)) {
                energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
            } else if (this.state.rates2XE.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018") {

                energiaActivaP1 += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0)) * 0.289);
                energiaActivaP2 += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0)) * 0.264);
                energiaActivaP3 += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0)) * 0.447);
            } else if (this.state.rates2XE.includes(sip.codigoTarifaATREnVigor) && this.state.rates2XE.includes(producto.tarifaATR)) {
                energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
            } else if ((sip.codigoTarifaATREnVigor === "003" || sip.codigoTarifaATREnVigor === "019") && producto.tarifaATR === "019") {
                var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                monthLectura = Number(check.format('M'));
                yearLectura = Number(check.format('Y'));
                numDaysPE1 += dayscheck;
                numDaysPE2 += dayscheck;
                numDaysPE3 += dayscheck;
                numDaysPE4 += dayscheck;
                numDaysPE5 += dayscheck;
                numDaysPE6 += dayscheck;

                /*  ratesMonth1:[1,2,7,12],
                  ratesMonth2:[3,11],
                  ratesMonth3:[4,5,10],
                  ratesMonth4:[6,8,9],*/
                if (monthLectura < 6 && yearLectura <= 2021) {
                    if (this.state.ratesMonth1.includes(monthLectura)) {
                        energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                        energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                        energiaActivaP3 += 0;
                        energiaActivaP4 += 0;
                        energiaActivaP5 += 0;
                        energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                    } else if (this.state.ratesMonth2.includes(monthLectura)) {
                        energiaActivaP1 += 0;
                        energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0)
                        energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                        energiaActivaP4 += 0;
                        energiaActivaP5 += 0;
                        energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                    } else if (this.state.ratesMonth3.includes(monthLectura)) {
                        energiaActivaP1 += 0;
                        energiaActivaP2 += 0;
                        energiaActivaP3 += 0;
                        energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                        energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                        energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                    } else {
                        energiaActivaP1 += 0;
                        energiaActivaP2 += 0;
                        energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                        energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                        energiaActivaP5 += 0
                        energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                    };

                } else {
                    energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                    energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                    energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);
                    energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                    energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);


                }

            } else if (sip.codigoTarifaATREnVigor === "011" && producto.tarifaATR === "020") {
                var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                monthLectura = Number(check.format('M'));

                /*  ratesMonth1:[1,2,7,12],
                  ratesMonth2:[3,11],
                  ratesMonth3:[4,5,10],
                  ratesMonth4:[6,8,9],*/

                if (this.state.ratesMonth1.includes(monthLectura)) {
                    energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                    energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                    energiaActivaP3 += 0;
                    energiaActivaP4 += 0;
                    energiaActivaP5 += 0;
                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);




                } else if (this.state.ratesMonth2.includes(monthLectura)) {
                    energiaActivaP1 += 0;
                    energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0)
                    energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                    energiaActivaP4 += 0;
                    energiaActivaP5 += 0;
                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);


                } else if (this.state.ratesMonth3.includes(monthLectura)) {
                    energiaActivaP1 += 0;
                    energiaActivaP2 += 0;
                    energiaActivaP3 += 0;
                    energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                    energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                } else {
                    energiaActivaP1 += 0;
                    energiaActivaP2 += 0;
                    energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                    energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                    energiaActivaP5 += 0
                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                };

            } else if (this.state.rates3XE.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XE.includes(producto.tarifaATR) && producto.tarifaATR !== "019" && producto.tarifaATR !== "020") {

                energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0) + (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) + (Number(row['consumoEnergiaActivaEnWhP6']) || 0);

            } else if (sip.codigoTarifaATREnVigor === '018') {
                energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

            } else {
                var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                monthLectura = Number(check.format('M'));
                energiaActivaP1 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);
                energiaActivaP4 += (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP6']) || 0);
            };

            return row;
        });

        this.state.itemsConsumo.map(row => {
            if (this.state.rates3XE.includes(sip.codigoTarifaATREnVigor)) {
                energiaReactivaP1 += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0) + (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0) + (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                //energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnWhP3']) || 0) + (Number(row['consumoEnergiaReactivaEnWhP4']) || 0);
            } else if (this.state.rates6XE.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15)) {

                energiaReactivaP1 += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0);
                energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0);
                energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                energiaReactivaP4 += (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                energiaReactivaP5 += (Number(row['consumoEnergiaReactivaEnVArhP5']) || 0);
                energiaReactivaP6 += (Number(row['consumoEnergiaReactivaEnVArhP6']) || 0);

                energiaReactivaExcesoP1 = ((Number(row['consumoEnergiaReactivaEnVArhP1']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP1']) || 0) * 0, 33))
                energiaReactivaExcesoP2 = ((Number(row['consumoEnergiaReactivaEnVArhP2']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP2']) || 0) * 0, 33))
                energiaReactivaExcesoP3 = ((Number(row['consumoEnergiaReactivaEnVArhP3']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP3']) || 0) * 0, 33))
                energiaReactivaExcesoP4 = ((Number(row['consumoEnergiaReactivaEnVArhP4']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP4']) || 0) * 0, 33))
                energiaReactivaExcesoP5 = ((Number(row['consumoEnergiaReactivaEnVArhP5']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP6']) || 0) * 0, 33))
                energiaReactivaExcesoP6 = ((Number(row['consumoEnergiaReactivaEnVArhP6']) || 0) - ((Number(row['consumoEnergiaActivaEnWhP6']) || 0) * 0, 33))


                //let sumPenalizacionReactiva = 0

                if (energiaReactivaExcesoP1 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP1'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP1'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP1'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP1 = energiaReactivaExcesoP1 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP1 = energiaReactivaExcesoP1 * 0.062332
                    } else {
                        penalizacionReactivaP1 = 0
                    }
                }

                if (energiaReactivaExcesoP2 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP2'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP2'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP2'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP2 = energiaReactivaExcesoP2 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP2 = energiaReactivaExcesoP2 * 0.062332
                    } else {
                        penalizacionReactivaP2 = 0
                    }
                }

                if (energiaReactivaExcesoP3 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP3'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP3'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP3'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP3 = energiaReactivaExcesoP3 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP3 = energiaReactivaExcesoP3 * 0.062332
                    } else {
                        penalizacionReactivaP3 = 0
                    }
                }
                if (energiaReactivaExcesoP4 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP4'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP4'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP4'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP4 = energiaReactivaExcesoP4 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP4 = energiaReactivaExcesoP4 * 0.062332
                    } else {
                        penalizacionReactivaP4 = 0
                    }
                }
                if (energiaReactivaExcesoP5 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP5'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP5'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP5'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP5 = energiaReactivaExcesoP5 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP5 = energiaReactivaExcesoP5 * 0.062332
                    } else {
                        penalizacionReactivaP5 = 0
                    }
                }
                if (energiaReactivaExcesoP6 > 0) {
                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP6'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP6'])) ** 2) + (((Number(row['consumoEnergiaReactivaEnVArhP6'])) ** 2)))

                    if (valorPhi >= 0.80 && valorPhi <= 0.95) {

                        penalizacionReactivaP6 = energiaReactivaExcesoP6 * 0.041554
                    } else if (valorPhi < 0.80) {
                        penalizacionReactivaP6 = energiaReactivaExcesoP6 * 0.062332
                    } else {
                        penalizacionReactivaP6 = 0
                    }
                }


            } else {
                energiaReactivaP1 += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0);
                energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0);
                energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                energiaReactivaP4 += (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                energiaReactivaP5 += (Number(row['consumoEnergiaReactivaEnVArhP5']) || 0);
                energiaReactivaP6 += (Number(row['consumoEnergiaReactivaEnVArhP6']) || 0);
            }

            sumatotalReactiva += penalizacionReactivaP1 + penalizacionReactivaP2 + penalizacionReactivaP3 + penalizacionReactivaP4 + penalizacionReactivaP5 + penalizacionReactivaP6
            return row;
        });


        this.state.itemsConsumo.map(row => {
            if (fechaHastaConsumo == null) {
                fechaHastaConsumo = row['fechaFinMesConsumo'];
            } else if (moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaFinMesConsumo'];
            }

            return row;
        });


        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;

            //potencia[`P${periodo}`] = 0;
            //numDays = 0;

            Object.keys(producto).map(key => {

                if (key === `id`) {
                    idProductoAux = producto['id']
                }

                const costeComision = this.state.dataComisionesProducto.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                //Producto Energia
                if (key === `precioTEP${periodo}`) {
                    if (producto['coIncluido'] === false) {
                        precioEnergia[`P${periodo}`] = Number((Number(producto[key]) + (costeComision[`coTE${periodo}`] / 100)).toFixed(6));
                    } else {
                        precioEnergia[`P${periodo}`] = Number(Number(producto[key]).toFixed(6));
                    }

                    if (periodo == 1) {

                        if (producto['coIncluido'] === false) {
                            precioEnergiaP1 = Number((Number(producto[key]) + (costeComision[`coTE1`] / 100)).toFixed(6))
                        } else {
                            precioEnergiaP1 = Number(Number(producto[key]).toFixed(6));
                        }

                    } else if (periodo == 2) {
                        if (producto['coIncluido'] === false) {
                            precioEnergiaP2 = Number((Number(producto[key]) + (costeComision[`coTE2`] / 100)).toFixed(6))
                        } else {
                            precioEnergiaP2 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 3) {
                        if (producto['coIncluido'] === false) {
                            precioEnergiaP3 = Number((Number(producto[key]) + (costeComision[`coTE3`] / 100)).toFixed(6))
                        } else {
                            precioEnergiaP3 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 4) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioEnergiaP4 = Number((Number(producto[key]) + (costeComision[`coTE4`] / 100)).toFixed(6))
                            } else {
                                precioEnergiaP4 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioEnergiaP4 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 5) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioEnergiaP5 = Number((Number(producto[key]) + (costeComision[`coTE5`] / 100)).toFixed(6))
                            } else {
                                precioEnergiaP5 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioEnergiaP5 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 6) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioEnergiaP6 = Number((Number(producto[key]) + (costeComision[`coTE6`] / 100)).toFixed(6))
                            } else {
                                precioEnergiaP6 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioEnergiaP6 = Number(Number(producto[key]).toFixed(6));
                        }
                    }

                } else if ((key === `precioTP${periodo}`)) {

                    if (periodo == 1) {

                        if (producto['coIncluido'] === false) {
                            precioPotenciaP1 = Number((Number(producto[key]) + (costeComision[`coTEP1`] / 100)).toFixed(6));
                        } else {
                            precioPotenciaP1 = Number(Number(producto[key]).toFixed(6));
                        }

                    } else if (periodo == 2) {
                        if (producto['coIncluido'] === false) {
                            precioPotenciaP2 = Number((Number(producto[key]) + (costeComision[`coTEP2`] / 100)).toFixed(6));
                        } else {
                            precioPotenciaP2 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 3) {
                        if (producto['coIncluido'] === false) {
                            precioPotenciaP3 = Number((Number(producto[key]) + (costeComision[`coTEP3`] / 100)).toFixed(6));
                        } else {
                            precioPotenciaP3 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 4) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioPotenciaP4 = Number((Number(producto[key]) + (costeComision[`coTEP4`] / 100)).toFixed(6));
                            } else {
                                precioPotenciaP4 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioPotenciaP4 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 5) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioPotenciaP5 = Number((Number(producto[key]) + (costeComision[`coTEP5`] / 100)).toFixed(6));
                            } else {
                                precioPotenciaP5 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioPotenciaP5 = Number(Number(producto[key]).toFixed(6));
                        }
                    } else if (periodo == 6) {
                        if (Number(producto[key]) > 0) {
                            if (producto['coIncluido'] === false) {
                                precioPotenciaP6 = Number((Number(producto[key]) + (costeComision[`coTEP6`] / 100)).toFixed(6));
                            } else {
                                precioPotenciaP6 = Number(Number(producto[key]).toFixed(6));
                            }
                        } else {
                            precioPotenciaP6 = Number(Number(producto[key]).toFixed(6));
                        }
                    }
                }
                //Producto Potencia

                if (key === `precioTP${periodo}`) {
                    if (producto['coIncluido'] === false) {
                        precioPotencia[`P${periodo}`] = Number((Number(producto[key]) + (costeComision[`coTEP${periodo}`] / 100)).toFixed(6));
                    } else {
                        precioPotencia[`P${periodo}`] = Number(Number(producto[key]).toFixed(6));
                    }
                }

                return key;
            });



            this.state.itemsConsumo.map(row => {

                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0);

                return row;

            });


            //datasetProducto.push(this.roundNumber((consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`])));

            sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);


            if (producto.tarifaATR === '018' || producto.tarifaATR === '019' || producto.tarifaATR === '020') {

                if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor) || this.state.rates1XP.includes(sip.codigoTarifaATREnVigor)) {
                    if (periodo === 1) {
                        const potenciaContratada = potenciaContratoP1;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                    if (periodo === 2) {
                        const potenciaContratada = potenciaContratoP2;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                    if (periodo === 3) {
                        const potenciaContratada = potenciaContratoP3;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                } else if (sip.codigoTarifaATREnVigor === '003') {

                    if (periodo === 1) {
                        const potenciaContratada = potenciaContratoP1;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                    if (periodo === 2) {
                        const potenciaContratada = potenciaContratoP2;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                    if (periodo === 3) {
                        const potenciaContratada = potenciaContratoP3;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }
                    if (periodo === 4) {
                        const potenciaContratada = potenciaContratoP4;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }
                    if (periodo === 5) {
                        const potenciaContratada = potenciaContratoP5;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }
                    if (periodo === 6) {
                        const potenciaContratada = potenciaContratoP6;
                        sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                    }

                }
            }

            if (producto.tarifaATR === '019' || producto.tarifaATR === '021' || producto.tarifaATR === '022' || producto.tarifaATR === '023' || producto.tarifaATR === '024' || producto.tarifaATR === '025') {

                if (periodo === 1) {
                    const potenciaContratada = potenciaContratoP1;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

                if (periodo === 2) {
                    const potenciaContratada = potenciaContratoP2;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

                if (periodo === 3) {
                    const potenciaContratada = potenciaContratoP3;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 4) {
                    const potenciaContratada = potenciaContratoP4;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 5) {
                    const potenciaContratada = potenciaContratoP5;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 6) {
                    const potenciaContratada = potenciaContratoP6;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

            }
            return periodo;
        });

        serviciosAdicionalesMonth = 0;
        penalizacionPotencia = this.roundNumber(Number(sumatotalPotencia || 0));
        //sumPenalizacionPotencia = this.roundNumber(penalizacionP1 + penalizacionP2 +penalizacionP3 +penalizacionP4 +penalizacionP5+penalizacionP6);
        penalizacionEnergia = this.roundNumber(Number(sumatotalReactiva || 0));

        sumEnergia = this.roundNumber(sumEnergia);
        sumPotencia = this.roundNumber(sumPotencia);

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber((sumEnergia + sumPotencia));
        serviciosAdicionales = this.roundNumber(this.state.precioServicio || 0 * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth || 0 * numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));

        var isbefore = moment().isBefore('20220101')


        if (isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP4) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP5) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP6) < 10)) {
            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;

        } else if (isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP4) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP5) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP6) < 15) && sip.perfilPersona == 'J') {

            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;
        } else if (isbefore && this.state.sip.aplicacionBonoSocial == "1") {
            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;
        } else {
            iva = this.roundNumber((bi * 0.21));
            ivaPorcentaje = 21;
        }



        if (producto.coIncluido) {
            if (this.state.esNuevoContrato) {
                comisionContratoFija = producto.comisionContratoNuevo ? producto.comisionContratoNuevo : 0
            } else {
                comisionContratoFija = producto.comisionContratoRenovacion ? producto.comisionContratoRenovacion : 0
            };

            let comisionAgente1 = producto.comisionConsumoAgente1 ? producto.comisionConsumoAgente1 : 0
            let comisionAgente2 = producto.comisionConsumoAgente2 ? producto.comisionConsumoAgente2 : 0

            comisionContratoConsumoAgente1 = parseFloat(sip.ConsumoAnualTotalActiva) * (comisionAgente1)
            comisionContratoConsumoAgente2 = parseFloat(sip.ConsumoAnualTotalActiva) * (comisionAgente2)
            comisionContratoConsumoAdmin = parseFloat(sip.ConsumoAnualTotalActiva) * producto.comisionConsumoAdmin ? producto.comisionConsumoAdmin : 0


        } else if (!producto.coIncluido) {

            if (this.state.esNuevoContrato) {
                comisionContratoFija = producto.comisionContratoNuevo ? producto.comisionContratoNuevo : 0
            } else {
                comisionContratoFija = producto.comisionContratoRenovacion ? producto.comisionContratoRenovacion : 0
            };

            let comisionAgente1 = producto.comisionGOAgente1 ? producto.comisionGOAgente1 : 0
            let comisionAgente2 = producto.comisionGOAgente2 ? producto.comisionGOAgente2 : 0
            comisionContratoConsumoAgente1 = (this.state.costeOperativo * parseFloat(sip.ConsumoAnualTotalActiva)) * (comisionAgente1 / 100)
            comisionContratoConsumoAgente2 = 0
            comisionContratoConsumoAdmin = comisionContratoConsumoAgente1 * (comisionAgente2 / 100)

        }




        total = this.roundNumber((bi + iva));
        codigoATR = producto.tarifaATR
        peajeATR = producto.TarifaDesc
        codigoATRActual = this.state.sip.codigoTarifaATREnVigor;
        idServicio = this.state.idservicio;
        idComercializadora = producto.idComercializadora;
        nombreComercializadora = producto.nombreComercializadora;
        nombreServicio = this.state.nombreServicio;

        precioServicio = this.roundNumber(this.state.precioServicio);
        idProducto = producto.id;
        nombreProducto = producto.nombreOferta


        this.setState({
            penalizacionPotenciaCalculo: penalizacionPotencia,
            penalizacionReactivaCalculo: penalizacionEnergia
        })


        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            peajeATR: peajeATR,
            codigoATRActual: codigoATRActual,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            EnergiaActiva: ["0"],
            Potencia: ["0"],
            potenciaP1: potenciaP1,
            potenciaP2: potenciaP2,
            potenciaP3: potenciaP3,
            potenciaP4: potenciaP4,
            potenciaP5: potenciaP5,
            potenciaP6: potenciaP6,
            potenciaContratoP1: potenciaContratoP1,
            potenciaContratoP2: potenciaContratoP2,
            potenciaContratoP3: potenciaContratoP3,
            potenciaContratoP4: potenciaContratoP4,
            potenciaContratoP5: potenciaContratoP5,
            potenciaContratoP6: potenciaContratoP6,
            energiaActivaP1: energiaActivaP1,
            energiaActivaP2: energiaActivaP2,
            energiaActivaP3: energiaActivaP3,
            energiaActivaP4: energiaActivaP4,
            energiaActivaP5: energiaActivaP5,
            energiaActivaP6: energiaActivaP6,

            energiaReactivaP1: energiaReactivaP1,
            energiaReactivaP2: energiaReactivaP2,
            energiaReactivaP3: energiaReactivaP3,
            energiaReactivaP4: energiaReactivaP4,
            energiaReactivaP5: energiaReactivaP5,
            energiaReactivaP6: energiaReactivaP6,

            precioPotenciaP1: precioPotenciaP1,
            precioPotenciaP2: precioPotenciaP2,
            precioPotenciaP3: precioPotenciaP3,
            precioPotenciaP4: precioPotenciaP4,
            precioPotenciaP5: precioPotenciaP5,
            precioPotenciaP6: precioPotenciaP6,

            precioEnergiaP1: precioEnergiaP1,
            precioEnergiaP2: precioEnergiaP2,
            precioEnergiaP3: precioEnergiaP3,
            precioEnergiaP4: precioEnergiaP4,
            precioEnergiaP5: precioEnergiaP5,
            precioEnergiaP6: precioEnergiaP6,
            idServicio: idServicio,
            idComercializadora: idComercializadora,
            //nombreComercializadora: nombreComercializadora,
            nombreServicio: nombreServicio,
            precioServicio: precioServicio,
            id: idProducto,
            nombreProducto: nombreProducto,
            nombreComercializadora: nombreComercializadora,
            comisionContratoFija: this.roundNumber(comisionContratoFija),
            comisionContratoConsumoAgente1: this.roundNumber(comisionContratoConsumoAgente1),
            comisionContratoConsumoAgente2: comisionContratoConsumoAgente2,
            comisionContratoConsumoAdmin: this.roundNumber(comisionContratoConsumoAdmin)
        }

    }


    private calculatePrecioActual = (periodos: number[], factura: any) => {

        let {
            serviciosAdicionalesMonth,
            serviciosAdicionales,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            descuento,
            descuentoMonth,
            descuentoPotencia,
            descuentoPotenciaMonth

        } = factura;

        const sip = this.state.sip;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;


        sumEnergia = 0;
        sumPotencia = 0;


        numDaysNew = 0;
        numMonthNew = 0;

        this.state.itemsConsumo.map(row => {
            if (fechaDesdeConsumo == null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            }
            return row;
        });

        this.state.itemsConsumo.map(row => {
            if (fechaHastaConsumo == null) {
                fechaHastaConsumo = row['fechaFinMesConsumo'];
            } else if (moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaFinMesConsumo'];
            }

            return row;
        });


        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //numDays = 0;

            this.state.itemsConsumo.map(row => {
                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                //consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0) + (Number(row[`consumoEnergiaReactivaEnVArhP${periodo}`]) || 0);
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0)
                //numDays += moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays();



                return row;

            });

            sumEnergia += (consumoEnergia[`P${periodo}`] * this.state.formActual[`precioTEP${periodo}`]);

            if (consumoPotencia[`P${periodo}`] === 0 && this.state.rates2X.includes(sip.codigoTarifaATREnVigor)) {

                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            } else if (consumoPotencia[`P${periodo}`] === 0 && sip.codigoTarifaATREnVigor === '018') {
                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            } else if (sip.codigoTarifaATREnVigor === '019' || sip.codigoTarifaATREnVigor === '020' || sip.codigoTarifaATREnVigor === '021' || sip.codigoTarifaATREnVigor === '022' || sip.codigoTarifaATREnVigor === '023' || sip.codigoTarifaATREnVigor === '024' || sip.codigoTarifaATREnVigor === '025') {
                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
            } else {
                if (this.state.rates3X.includes(sip.codigoTarifaATREnVigor)) {
                    if (sip.codigoTarifaATREnVigor === '003' || sip.codigoTarifaATREnVigor === '018') {
                        const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                        sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;

                    } else {
                        sumPotencia += (consumoPotencia[`P${periodo}`] * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                    }

                }
            }

            return periodo;
        });
        penalizacionPotencia = this.roundNumber(Number(penalizacionPotencia));
        penalizacionEnergia = this.roundNumber(Number(penalizacionEnergia));
        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth) * numMonthNew);
        descuentoPotencia = this.roundNumber(this.percentage(sumPotencia, descuentoPotenciaMonth) * numMonthNew);
        sumEnergia = this.roundNumber(this.roundNumber(sumEnergia) - descuento);
        sumPotencia = this.roundNumber(sumPotencia);
        serviciosAdicionalesMonth = 0;

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber((sumEnergia + sumPotencia));
        serviciosAdicionales = this.roundNumber(serviciosAdicionalesMonth * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth * numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));
        iva = this.roundNumber((bi * this.state.formActual.ivaPorcentaje));
        ivaPorcentaje = this.state.formActual.ivaPorcentaje;
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoTarifaATREnVigor;

        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            descuento: descuento,
            descuentoMonth: descuentoMonth,
            descuentoPotencia: descuentoPotencia,
            descuentoPotenciaMonth: descuentoPotenciaMonth
        }

    }


    private roundNumber(number: number) {
        return (Math.round(number * 100) / 100)
    }


    private percentage(num, per) {
        return (num / 100) * per;
    }

    public renderFacturaProducto = () => {
        let valorlongitud = Object.keys(this.state.producto).length
        let newfacturaTabla: any[] = [];
        let newAhorroTabla: any[] = [];
        let propuestasAnalizadas: any[] = []
        let newItemsTablaComision: any[] = [];
        let newItemsTabla: any[] = [];

        var comision1Agente: number = this.state.comercialAgente.comision1 ? this.state.comercialAgente.comision1 : 0
        var comision2Agente: number = this.state.comercialAgente.comision2 ? this.state.comercialAgente.comision2 : 0

        if (this.state && valorlongitud > 0) {

            Object.keys(this.state.producto).map((item, i) => {
                let factura: any = this.calculatePrecioProducto(this.state.periods, this.state.producto[item], this.state.formMulti);
                newfacturaTabla.push(factura)
                let ahorro: any = this.calculateAhorro(this.state.periods, this.state.formActual, factura);
                newAhorroTabla.push(ahorro)
                propuestasAnalizadas.push({ ...factura, ahorro })
                return item;
            });


            /////// CREACION DEL ITEMSTABLA

            Object.keys(this.state.producto).map((item, i) => {

                var periodosNuevos = 0
                let idProductoAux = this.state.producto[item]['id']

                if (this.state.sip.codigoTarifaATREnVigor == '001' || this.state.sip.codigoTarifaATREnVigor == '004' || this.state.sip.codigoTarifaATREnVigor == '005' || this.state.sip.codigoTarifaATREnVigor == '006' || this.state.sip.codigoTarifaATREnVigor == '007' || this.state.sip.codigoTarifaATREnVigor == '008') {
                    periodosNuevos = 3
                } else if (this.state.sip.codigoTarifaATREnVigor == '018') {
                    periodosNuevos = 3
                } else { periodosNuevos = 6 }

                const costeComision = this.state.dataComisionesProducto.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                for (let i = 1; i <= periodosNuevos; i++) {

                    let consumoAnual = `ConsumoAnualActivaP${i}`

                    if (this.state.sip[consumoAnual] >= 0 && i <= periodosNuevos) {
                        let precioPotenciaField = `precioTP${i}`;
                        let precioEnergiaField = `precioTEP${i}`;
                        let periodo = `P${i}`;
                        let precioPotencia = 0;
                        let precioEnergia = 0;
                        if (this.state.producto[item]['coIncluido'] === false) {

                            precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                            precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));
                        } else {
                            precioPotencia = Number(this.state.producto[item][precioPotenciaField].toFixed(6));
                            precioEnergia = Number(this.state.producto[item][precioEnergiaField].toFixed(6));
                        }
                        let id = this.state.producto[item]['id']

                        newItemsTabla.push({ id: idProductoAux, periodo: periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia });
                    }
                }
                return item;
            });

            /////// CALCULO DE COMISIONES

            Object.keys(this.state.producto).map((item, i) => {

                var periodosNuevos = 0
                let idProductoAux = this.state.producto[item]['id']
                let eurosMwh = this.state.producto[item]['eurosMwh']

                if (this.state.sip.codigoTarifaATREnVigor == '001' || this.state.sip.codigoTarifaATREnVigor == '004' || this.state.sip.codigoTarifaATREnVigor == '005' || this.state.sip.codigoTarifaATREnVigor == '006' || this.state.sip.codigoTarifaATREnVigor == '007' || this.state.sip.codigoTarifaATREnVigor == '008') {
                    periodosNuevos = 3
                } else if (this.state.sip.codigoTarifaATREnVigor == '018') {
                    periodosNuevos = 3
                } else { periodosNuevos = 6 }

                var costeComision: any = []

                if (this.state.producto[item].tipoComisionProducto == "E" || this.state.producto[item].tipoComisionProducto == "F" || this.state.producto[item].tipoComisionProducto == "G") {

                    costeComision = this.state.dataComisionesProducto.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                } else {

                    costeComision = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);
                }

                for (let i = 1; i <= periodosNuevos; i++) {

                    let consumoAnual = this.state.sip[`ConsumoAnualActivaP${i}`]
                    //let consumoPotenciaAnual = this.state.sip[`potenciasContratadasEnWP${i}`]
                    let consumoPotenciaAnual = this.state.formDatosContrato[`potenciaContratoP${i}`]

                    if (consumoAnual >= 0 && i <= periodosNuevos) {
                        let precioPotenciaField = `precioTP${i}`;
                        let precioEnergiaField = `precioTEP${i}`;
                        let periodo = `P${i}`;
                        let precioPotencia = 0;
                        let precioEnergia = 0;
                        let comision = 0;
                        let comisionPotencia = 0;


                        // const costeComisionIncluidoCo = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                        if (this.state.producto[item]['coIncluido'] === false) {

                            if (costeComision[`idComision`] == "E") {

                                if (costeComision[`coTE${i}`] >= costeComision[`coStandard`] && costeComision[`coTE${i}`] < costeComision[`coReferencia`]) {

                                    
                                    precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));
                                    comision = (Number(consumoAnual) * (costeComision[`coTE${i}`] / 100)) * (costeComision[`coStandardPorcentaje`] / 1000)
                                } else if (costeComision[`coTE${i}`] >= costeComision[`coReferencia`]) {

                                    precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));
                                    comision = (Number(consumoAnual) * (costeComision[`coTE${i}`] / 100)) * (costeComision[`coReferenciaPorcentaje`] / 1000)
                                } if (costeComision[`coTEP${i}`] >= costeComision[`coStandard`] && costeComision[`coTEP${i}`] < costeComision[`coReferencia`]) {
                                    let consumoPotenciaAnualPeriodo = this.state.sip[`potenciasContratadasEnWP${i}`]
                                    precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                                    comisionPotencia = (Number(consumoPotenciaAnualPeriodo) * (costeComision[`coTEP${i}`] / 1000)) * 0.5

                                } else if (costeComision[`coTEP${i}`] >= costeComision[`coReferencia`]) {
                                    let consumoPotenciaAnualPeriodo = this.state.sip[`potenciasContratadasEnWP${i}`]
                                    precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                                    comisionPotencia = (Number(consumoPotenciaAnualPeriodo) * (costeComision[`coTEP${i}`] / 1000)) * 0.5
                                } else if (costeComision[`coTE${i}`] < costeComision[`coStandard`]) {

                                    precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));
                                    comision = 0
                                } else if (costeComision[`coTEP${i}`] < costeComision[`coStandard`]) {
                                    let consumoPotenciaAnualPeriodo = this.state.sip[`potenciasContratadasEnWP${i}`]
                                    precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                                    comisionPotencia = (Number(consumoPotenciaAnualPeriodo) * (costeComision[`coTEP${i}`] / 1000)) * 0.5
                                }
                            } else if (costeComision[`idComision`] == "F") {

                                const comisionAuxiliar = this.state.tablaComision.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                                var porcentaje = 0;
                                var porcentajePotencia = 0;

                                if (costeComision[`coTE${i}`] >= comisionAuxiliar['co1']) {
                                    porcentaje = comisionAuxiliar['porcentajeco1']
                                } else if (costeComision[`coTE${i}`] >= comisionAuxiliar['co2'])  {
                                    porcentaje = comisionAuxiliar['porcentajeco2']
                                } else if (costeComision[`coTE${i}`] >= comisionAuxiliar['co3'] ){
                                    porcentaje = comisionAuxiliar['porcentajeco3']
                                } else if (costeComision[`coTE${i}`] >= comisionAuxiliar['co4']) {
                                    porcentaje = comisionAuxiliar['porcentajeco4']
                                } else if (costeComision[`coTE${i}`] >= comisionAuxiliar['co5'] ) {
                                    porcentaje = comisionAuxiliar['porcentajeco5']
                                } else if (costeComision[`coTE${i}`] >= comisionAuxiliar['co6'] ) {
                                    porcentaje = comisionAuxiliar['porcentajeco6']
                                }

                                /*                         if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co1'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco1']
                                                                                        } else if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co2'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco2']
                                                                                        } else if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co3'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco3']
                                                                                        } else if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co4'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco4']
                                                                                        } else if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co5'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco5']
                                                                                        } else if (costeComision[`coTEP${i}`] >= (comisionAuxiliar['co6'] / 1000) * 100) {
                                                                                            porcentajePotencia = comisionAuxiliar['porcentajeco6']
                                                                                        } else {
                                                                                            console.log('ENTRA EN VACIO')
                                                                                            porcentaje = 0
                                                                                            porcentajePotencia = 0
                                                                                            comision = 0
                                                                                            comisionPotencia = 0
                                                                                        }
                               */
                                //console.log('valor de coTE${i} ', i, " valor: ", costeComision[`coTE${i}`] )

                                if (costeComision[`coTEP${i}`] >= 0) {
                                    porcentajePotencia = 50
                                }

                                if (porcentaje > 0) {
                                    precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));
                                    comision = (Number(consumoAnual) * (costeComision[`coTE${i}`] / 100)) * (porcentaje / 100)
                                }

                                if (porcentajePotencia > 0) {
                                    let consumoPotenciaAnualPeriodo = this.state.sip[`potenciasContratadasEnWP${i}`]
                                    precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                                    //comisionPotencia = (Number(consumoPotenciaAnual) * (costeComision[`coTEP${i}`] / 1000)) * (porcentajePotencia / 100)
                                    comisionPotencia = (Number(consumoPotenciaAnualPeriodo) * (costeComision[`coTEP${i}`] / 1000)) * 0.5
                                }

                            } else if (costeComision[`idComision`] == "G") {

                                console.log('valor de costeComision[coTE${i}] : ', costeComision[`coTE${i}`], " costeComision[coReferencia]  : ", costeComision[`coReferencia`] )

                                if (costeComision[`coTE${i}`] <= costeComision[`coReferencia`] && costeComision[`coTEP${i}`] <= costeComision[`coReferencia`]) {

                                    let consumoPotenciaAnualPeriodo = this.state.sip[`potenciasContratadasEnWP${i}`]

                                    console.log('valor de precio: ', this.state.producto[item][precioEnergiaField] , ' comision: ', costeComision[`coTE${i}`] , " ", costeComision[`coTE${i}`] / 1000 )

                                    precioPotencia = Number((this.state.producto[item][precioPotenciaField] + (costeComision[`coTEP${i}`] / 1000)).toFixed(6));
                                    precioEnergia = Number((this.state.producto[item][precioEnergiaField] + (costeComision[`coTE${i}`] / 1000)).toFixed(6));

                                    console.log('valor de precio: ', this.state.producto[item][precioEnergiaField] , ' comision: ', costeComision[`coTE${i}`] , " ", costeComision[`coTE${i}`] / 1000 )

                                    console.log('valor de precioEnergia: ', precioEnergia)


                                    comision = (Number(consumoAnual) * (costeComision[`coTE${i}`] / 1000)) * (costeComision[`coReferenciaPorcentaje`] / 100)
                                    comisionPotencia = (Number(consumoPotenciaAnualPeriodo) * (costeComision[`coTEP${i}`] / 1000))


                                } else if (costeComision[`coTE${i}`] >= costeComision[`coReferencia`] || costeComision[`coTEP${i}`] >= costeComision[`coReferencia`]) {
                                    notifyCoError();
                                } else {
                                    comision = 0
                                    comisionPotencia = 0
                                }

                            }
                        } else {
                            if (costeComision[`idComision`] == "D") {

                                const comisionAuxiliar = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                                if (i == 1) {
                                    porcentaje = 100
                                    comision = comisionAuxiliar.coStandard
                                    comisionPotencia = 0
                                } else {
                                    porcentaje = 0
                                    comision = 0
                                    comisionPotencia = 0
                                }
                            } else if (costeComision[`idComision`] == "B") {

                                const comisionAuxiliar = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                                if (i == 1) {
                                    porcentaje = 100
                                    comision = comisionAuxiliar.coStandard
                                    comisionPotencia = 0
                                } else {
                                    porcentaje = 0
                                    comision = 0
                                    comisionPotencia = 0
                                }
                            } else if (costeComision[`idComision`] == "C") {

                                const comisionAuxiliar = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                                if (i == 1) {
                                    porcentaje = 100
                                    comision = comisionAuxiliar.coStandard
                                    comisionPotencia = 0
                                } else {
                                    porcentaje = 0
                                    comision = 0
                                    comisionPotencia = 0
                                }
                            } else if (costeComision[`idComision`] == "A") {

                                const comisionAuxiliar = this.state.dataComisionesProductoIncluidoCo.find(dataComisiones => dataComisiones.idProducto === idProductoAux);

                                if (i == 1) {
                                    porcentaje = 100
                                    comision = comisionAuxiliar.coStandard
                                    comisionPotencia = 0
                                } else {
                                    porcentaje = 0
                                    comision = 0
                                    comisionPotencia = 0
                                }
                            }

                            precioPotencia = Number(this.state.producto[item][precioPotenciaField].toFixed(6));
                            precioEnergia = Number(this.state.producto[item][precioEnergiaField].toFixed(6));
                        }
                        let id = this.state.producto[item]['id']

                        newItemsTablaComision.push({ id: idProductoAux, periodo: periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia, comisionEnergia: comision, comisionPotencia: comisionPotencia });
                    }
                }
                return item;
            });




            var multiTableDataComision: any = [];

            var multiTableDataComisionConCo: any = []

            var productosSinCo: any = []
            var productoConCo: any = []

            Object.keys(this.state.producto).map((item, i) => {

                if (this.state.producto[item].tipoComisionProducto == "E" || this.state.producto[item].tipoComisionProducto == "F" || this.state.producto[item].tipoComisionProducto == "G") {

                    productosSinCo.push({ ...this.state.producto[item] })

                } else if (this.state.producto[item].tipoComisionProducto == "D" || this.state.producto[item].tipoComisionProducto == "B" || this.state.producto[item].tipoComisionProducto == "A" || this.state.producto[item].tipoComisionProducto == "C") {

                    productoConCo.push({ ...this.state.producto[item] })
                }

                return item;
            });

            multiTableDataComision = Object.keys(productosSinCo).map((row, index) => {

                let valorComision = productosSinCo[index].tipoComisionProducto

                let comisionAgenteTEP1 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P1').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTEP2 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P2').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTEP3 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P3').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTEP4 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P4').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTEP5 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P5').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTEP6 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P6').comisionEnergia) * comision1Agente) / 100
                let comisionAgenteTPP1 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P1').comisionPotencia) * comision1Agente) / 100
                let comisionAgenteTPP2 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P2').comisionPotencia) * comision1Agente) / 100
                let comisionAgenteTPP3 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P3').comisionPotencia) * comision1Agente) / 100
                let comisionAgenteTPP4 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P4').comisionPotencia) * comision1Agente) / 100
                let comisionAgenteTPP5 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P5').comisionPotencia) * comision1Agente) / 100
                let comisionAgenteTPP6 = ((newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P6').comisionPotencia) * comision1Agente) / 100


                return {
                    id: productosSinCo[index].id,
                    idTipoComision: productosSinCo[index].tipoComisionProducto,
                    comisionTEP1: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P1').comisionEnergia,
                    comisionTEP2: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P2').comisionEnergia,
                    comisionTEP3: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P3').comisionEnergia,
                    comisionTEP4: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P4').comisionEnergia,
                    comisionTEP5: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P5').comisionEnergia,
                    comisionTEP6: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P6').comisionEnergia,
                    comisionTPP1: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P1').comisionPotencia,
                    comisionTPP2: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P2').comisionPotencia,
                    comisionTPP3: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P3').comisionPotencia,
                    comisionTPP4: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P4').comisionPotencia,
                    comisionTPP5: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P5').comisionPotencia,
                    comisionTPP6: newItemsTablaComision.find(element => element.id == productosSinCo[index].id && element.periodo == 'P6').comisionPotencia,
                    comisionAgenteTEP1: comisionAgenteTEP1,
                    comisionAgenteTEP2: comisionAgenteTEP2,
                    comisionAgenteTEP3: comisionAgenteTEP3,
                    comisionAgenteTEP4: comisionAgenteTEP4,
                    comisionAgenteTEP5: comisionAgenteTEP5,
                    comisionAgenteTEP6: comisionAgenteTEP6,
                    comisionAgenteTPP1: comisionAgenteTPP1,
                    comisionAgenteTPP2: comisionAgenteTPP2,
                    comisionAgenteTPP3: comisionAgenteTPP3,
                    comisionAgenteTPP4: comisionAgenteTPP4,
                    comisionAgenteTPP5: comisionAgenteTPP5,
                    comisionAgenteTPP6: comisionAgenteTPP6
                }

            });



            multiTableDataComisionConCo = Object.keys(productoConCo).map((row, index) => {

                let valorComision = productoConCo[index].tipoComisionProducto

                let comisionAgenteTEP1 = ((newItemsTablaComision.find(element => element.id == productoConCo[index].id && element.periodo == 'P1').comisionEnergia) * comision1Agente) / 100

                return {
                    id: productoConCo[index].id,
                    idTipoComision: productoConCo[index].tipoComisionProducto,
                    idTipoProductoComision: newItemsTablaComision.find(element => element.id == productoConCo[index].id && element.periodo == 'P1').comisionEnergia,
                    comisionTEP1: newItemsTablaComision.find(element => element.id == productoConCo[index].id && element.periodo == 'P1').comisionEnergia,
                    comisionAgenteTEP1: comisionAgenteTEP1
                }
            });

            this.setState({
                formMulti: newfacturaTabla,
                totalComparacion: newAhorroTabla,
                propuestasAnalizadas: propuestasAnalizadas,
                itemsTabla: newItemsTabla,
                itemsTablaComision: newItemsTablaComision,
                multiTableDataComision: multiTableDataComision,
                multiTableDataComisionConCo: multiTableDataComisionConCo,
            }
                , this.stateOutput)
        }
    };

    public stateOutput() {
        var { formActual,
            formMultiProducto, producto, itemsTabla, costeOperativo, costeOperativoPotencia, totalComparacion, periods, esNuevoContrato, propuestasAnalizadas } = this.state;
        let totalComp;
        let periodos;
        var save: boolean = false;
        if (this.state.productSelected !== null) {

            //if para asegurar de pasar un solo producto: el seleccionado
            producto = Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]);
            if (producto !== undefined) {
                let index: string = Object.keys(this.state.producto).find(key => this.state.producto[key] === producto)!;
                totalComp = this.state.totalComparacion[parseInt(index)];
                //delete totalComp.datosAhorro;
                if (this.state.enabledSave) {
                    save = true;
                };
            }
            //periodos
            periodos = itemsTabla.filter(item => {
                if (item.id === this.state.productSelected[0]) {
                    return item
                }
            });
            formMultiProducto = Object.values(this.state.formMulti).find((item) => item.id === this.state.productSelected[0]);
        } else {

            if (this.state.enabledSave) {

                producto = []
                totalComp = []
                formMultiProducto = {
                    serviciosAdicionalesMonth: 0,
                    serviciosAdicionales: 0,
                    alquilerEquipoMedidaMonth: 0,
                    alquilerEquipoMedida: 0,
                    codigoATR: 0,
                    sumPotencia: 0,
                    sumEnergia: 0,
                    iePercent: 0,
                    impuestoElectrico: 0,
                    iva: 0,
                    ivaPorcentaje: 0,
                    bi: 0,
                    total: 0,
                    EnergiaActiva: [],
                    Potencia: [],
                    potenciaP1: 0,
                    potenciaP2: 0,
                    potenciaP3: 0,
                    potenciaP4: 0,
                    potenciaP5: 0,
                    potenciaP6: 0,
                    potenciaContratoP1: 0,
                    potenciaContratoP2: 0,
                    potenciaContratoP3: 0,
                    potenciaContratoP4: 0,
                    potenciaContratoP5: 0,
                    potenciaContratoP6: 0,
                    energiaActivaP1: 0,
                    energiaActivaP2: 0,
                    energiaActivaP3: 0,
                    energiaActivaP4: 0,
                    energiaActivaP5: 0,
                    energiaActivaP6: 0,
                    energiaReactivaP1: 0,
                    energiaReactivaP2: 0,
                    energiaReactivaP3: 0,
                    energiaReactivaP4: 0,
                    energiaReactivaP5: 0,
                    energiaReactivaP6: 0,
                    precioPotenciaP1: 0,
                    precioPotenciaP2: 0,
                    precioPotenciaP3: 0,
                    precioPotenciaP4: 0,
                    precioPotenciaP5: 0,
                    precioPotenciaP6: 0,
                    precioEnergiaP1: 0,
                    precioEnergiaP2: 0,
                    precioEnergiaP3: 0,
                    precioEnergiaP4: 0,
                    precioEnergiaP5: 0,
                    precioEnergiaP6: 0,
                    numDaysNew: 0,
                    numMonthNew: 0,
                    fechaDesdeConsumo: null,
                    fechaHastaConsumo: null,
                    penalizacionPotencia: 0,
                    penalizacionEnergia: 0,
                    datasetProducto: [],
                    idServicio: null,
                    idComercializadora: null,
                    nombreComercializadora: "",
                    nombreServicio: null,
                    precioServicio: 0,
                    nombreProducto: "",
                    comisionContratoFija: 0,
                    comisionContratoConsumoAgente1: 0,
                    comisionContratoConsumoAgente2: 0,
                    comisionContratoConsumoAdmin: 0
                };
                save = true;
                periodos = {};
            }
        }

        this.props.output(
            {
                actual: {
                    datos: formActual,
                    datosAdicionales: {
                        serviciosAdicionalesMonth: formActual.serviciosAdicionalesMonth,
                        serviciosAdicionales: formActual.serviciosAdicionales,
                        alquilerEquipoMedida: formActual.alquilerEquipoMedida,
                        codigoATR: formActual.codigoATR,
                        sumPotencia: formActual.sumPotencia,
                        sumEnergia: formActual.sumEnergia,
                        iePercent: formActual.iePercent,
                        impuestoElectrico: formActual.impuestoElectrico,
                        iva: formActual.iva,
                        ivaPorcentaje: formActual.ivaPorcentaje,
                        bi: formActual.bi,
                        total: formActual.total,
                        penalizacionPotencia: formActual.penalizacionPotencia,
                        penalizacionEnergia: formActual.penalizacionEnergia,
                    }
                },
                producto: {
                    datos: formMultiProducto,
                    producto: producto,//Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]),
                    itemsPeriodos: periodos,
                    periodos: periods,
                    costeOperativo: costeOperativo,
                    costeOperativoPotencia: costeOperativoPotencia,
                    esNuevoContrato: esNuevoContrato,
                    totalComparacion: totalComp,
                },
                datosComision: {
                    dataComisionCo: this.state.multiTableDataComision,
                    dataComision: this.state.multiTableDataComisionConCo,
                    dataComisionCoTarifa: this.state.dataComisionesProducto
                },
                comparativaGlobal: this.state.totalComparacion,
                propuestasAnalizadas: this.state.propuestasAnalizadas,
                datosResumenContrato: { ...this.state.formDatosContrato },
                enabledSave: save
            }
        );
    };

    public renderFacturaActual = () => {

        let factura: any = this.calculatePrecioActual(this.state.periods, this.state.formActual);

        let valorlongitud = Object.keys(this.state.producto).length
        let valorlongitudMulti = Object.keys(this.state.formMulti).length
        let newAhorroTabla: any[] = [];
        if (this.state && valorlongitud > 0 && valorlongitudMulti !== 68) {
            Object.keys(this.state.formMulti).map((item, i) => {
                let ahorro: any = this.calculateAhorroMulti(this.state.periods, this.state.formActual, this.state.formMulti[item]);
                newAhorroTabla.push(ahorro)
                return item;
            });

            let codigoATR: any = this.state.sip.codigoTarifaATREnVigor;

            this.setState({
                formActual: {
                    ...this.state.formActual,
                    serviciosAdicionales: factura.serviciosAdicionales,
                    serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                    alquilerEquipoMedida: factura.alquilerEquipoMedida,
                    alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                    codigoATR: codigoATR,
                    sumPotencia: factura.sumPotencia,
                    sumEnergia: factura.sumEnergia,
                    iePercent: factura.iePercent,
                    impuestoElectrico: factura.impuestoElectrico,
                    iva: factura.iva,
                    ivaPorcentaje: factura.ivaPorcentaje,
                    bi: factura.bi,
                    total: factura.total,
                    penalizacionPotencia: factura.penalizacionPotencia,
                    penalizacionEnergia: factura.penalizacionEnergia,
                    numDaysNew: factura.numDaysNew,
                    numMonthNew: factura.numMonthNew,
                    fechaDesdeConsumo: factura.fechaDesdeConsumo,
                    fechaHastaConsumo: factura.fechaHastaConsumo,
                    descuento: factura.descuento,
                    descuentoMonth: factura.descuentoMonth,
                    descuentoPotencia: factura.descuentoPotencia,
                    descuentoPotenciaMonth: factura.descuentoPotenciaMonth
                },
                totalComparacion: newAhorroTabla
                //                ...ahorro
                //            }
            }, this.stateOutput);

        }
    }

    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    private onChangeField = async (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;

        let inputs = {};
        if (value !== "" || value !== null) {
            inputs[name] = Number(value);
            return inputs;

        }
        return false;
    }

    selectedItem = async (event) => {

        let outputData = { ...this.stateOutput };
        outputData['enabledSave'] = true;

        /*        this.setState(
                ({ productSelected }) => ({
                  productSelected:event
                }), () => {
                  this.setState(({ productSelected }) => ({
                    productSelected:event
                  }))
                },this.stateOutput  )
        */
        this.setState({ productSelected: event }, this.stateOutput)
    };


    handleChange = (e) => {
        const valor = e.value;
        this.setState({ selectedOption: valor }, () =>
        console.log(`Option selected:`)
        );
        this.setState({
            formActual: {
                ...this.state.formActual,
                ...{ ivaPorcentaje: valor }
            }
        }, this.renderFacturaActual);
    };

    /*idProducto: "000", idComisionTabla: "000",
    coTE1: 0,
    coTE2: 0,
    coTE3: 0,
    coTE4: 0,
    coTE5: 0,
    coTE6: 0,
    coTEP1: 0,
    coTEP2: 0,
    coTEP3: 0,
    coTEP4: 0,
    coTEP5: 0,
    coTEP6: 0, */

    rowClick = (event) => {
        this.setState({
            editID: event.dataItem.idProducto,
        });
    };

    itemChange = (event) => {
        const field = event.field || "";
        const inEditID = event.dataItem.idProducto;
        const data = this.state.dataComisionesProducto.map((item) =>
            item.idProducto === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        this.setState({
            dataComisionesProducto: data,
        }, this.renderFacturaProducto);
    };
    closeEdit = (event) => {
        if (event.target === event.currentTarget) {
            this.setState({
                editID: "",
            });
        }
    };



    /*  , () => {
          this.renderFacturaProducto();
          this.renderFacturaActual();
  
      }*/

    tipoContrato = (props) => {
        const tipoTarifa = props.tipoTarifa;
        var form: any = this.state.formDatosContrato;
        const consumosMaxMin = this.state.consumosMaxMin

        let arr = Object.values(consumosMaxMin.min);
        let maxOfMin = Math.max(...arr);

        let arr2 = Object.values(consumosMaxMin.max);
        let maxOfMax = Math.max(...arr2);

        const getBackgroundColor = (value) => {

            let color;
            if (value >= maxOfMax) {
                color = '#82be4e'
            }
            else {
                color = '';
            }
            return color;
        };

        const getBackgroundColorMin = (value) => {

            let color;
            if (value >= maxOfMin) {
                color = '#82be4e'
            }
            else {
                color = '';
            }
            return color;
        };


        if (tipoTarifa === '018') {
            return (
                <div>
                    <Row>
                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp1">
                                    POTENCIA P1
                                </Label>
                                <Input
                                    id="potenciaContratoP1"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP1"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP1 || ''}
                                    type="number"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp2">
                                    POTENCIA P2
                                </Label>
                                <Input
                                    id="potenciaContratoP2"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP2"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP2 || ''}
                                    type="number"
                                    required
                                />
                            </FormGroup>
                        </Col>


                    </Row>
                </div>);
        } else {
            return (
                <div>
                    <Row>
                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp1">
                                    POTENCIA P1
                                </Label>
                                <Input
                                    id="potenciaContratoP1"
                                    placeholder=""
                                    name="potenciaContratoP1"
                                    step="0.5"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP1 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc1">
                                    POT. MAX CONSUMO P1
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P1) }} className="form-control-alternative">{consumosMaxMin.max.P1 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin1">
                                    POT. MIN CONSUMO P1
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P1) }} className="form-control-alternative">{consumosMaxMin.min.P1 || 0}</h5>
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="pp2">
                                    POTENCIA P2
                                </Label>
                                <Input
                                    id="potenciaContratoP2"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP2"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP2 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc2">
                                    POT. MAX CONSUMO P2
                                </Label>
                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P2) }} className="form-control-alternative">{consumosMaxMin.max.P2 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin2">
                                    POT. MIN CONSUMO P2
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P2) }} className="form-control-alternative">{consumosMaxMin.min.P2 || 0}</h5>
                                </div>


                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp3">
                                    POTENCIA P3
                                </Label>
                                <Input
                                    id="potenciaContratoP3"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP3"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP3 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc3">
                                    POT. MAX CONSUMO P3
                                </Label>
                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P3) }} className="form-control-alternative">{consumosMaxMin.max.P3 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin3">
                                    POT. MIN CONSUMO P3
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P3) }} className="form-control-alternative">{consumosMaxMin.min.P3 || 0}</h5>
                                </div>

                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp4">
                                    POTENCIA P4
                                </Label>
                                <Input
                                    id="potenciaContratoP4"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP4"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP4 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc4">
                                    POT. MAX CONSUMO P4
                                </Label>
                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P4) }} className="form-control-alternative">{consumosMaxMin.max.P4 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin4">
                                    POT. MIN CONSUMO P4
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P4) }} className="form-control-alternative">{consumosMaxMin.min.P4 || 0}</h5>
                                </div>

                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp5">
                                    POTENCIA P5
                                </Label>
                                <Input
                                    id="potenciaContratoP5"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP5"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP5 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc5">
                                    POT. MAX CONSUMO P5
                                </Label>
                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P5) }} className="form-control-alternative">{consumosMaxMin.max.P5 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin5">
                                    POT. MIN CONSUMO P5
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P5) }} className="form-control-alternative">{consumosMaxMin.min.P5 || 0}</h5>
                                </div>

                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="pp6">
                                    POTENCIA P6
                                </Label>
                                <Input
                                    id="potenciaContratoP6"
                                    placeholder=""
                                    step="0.5"
                                    name="potenciaContratoP6"
                                    onChange={this.onChangeFieldformMultiProductoPotencia}
                                    value={form.potenciaContratoP6 || ''}
                                    type="number"
                                    required
                                />

                                <Label className="form-control-label" htmlFor="ppc6">
                                    POT. MAX CONSUMO P6
                                </Label>
                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P6) }} className="form-control-alternative">{consumosMaxMin.max.P6 || 0}</h5>
                                </div>


                                <Label className="form-control-label" htmlFor="ppcmin6">
                                    POT. MIN CONSUMO P6
                                </Label>

                                <div className="p-2 bg-secondary">
                                    <h5 style={{ textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P6) }} className="form-control-alternative">{consumosMaxMin.min.P6 || 0}</h5>
                                </div>

                            </FormGroup>
                        </Col>

                    </Row>

                </div>)
        }
    }





    render() {

        console.log('This.state en render: ', this.state)

        const itemsTabla = this.state.itemsTabla;
        //const precioServicio = this.state.precioServicio;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.producto;
        const formActual = this.state.formActual;
        const bie = this.state.formDatosContrato.bie
        var tarifaATRProducto = '';
        let penalizacionPotenciaActual = this.state.penalizacionPotenciaCalculo || 0;
        let penalizacionReactivaCalculo = this.state.penalizacionReactivaCalculo || 0;

        const dicMunicipios: any[] = this.state.dicMunicipios;
        const form: any = this.state.form;
        var nombre: string = form.nombreOferta;
        const radioStyle = {
            display: "flex",
            justifyContent: "space-between",
        };

        const tipoCliente: any[] = this.state.TipoCliente;

        let valorlongitud = Object.keys(this.state.periods).length

        var multiFields: any = {}
        if (valorlongitud < 6) {
            multiFields = {
                oferta: 'Oferta',
                ahorroAnual: 'Ahorro Anual',
                ahorroPercent: 'Ahorro Percent',
                ahorroPeriodo: 'Ahorro Periodo',
                P1energia: 'P1 Energia',
                P2energia: 'P2 Energia',
                P3energia: 'P3 Energia',
                sumEnergia: 'Suma Energia',
                P1potencia: 'P1 Potencia',
                P2potencia: 'P2 Potencia',
                sumPotencia: 'Suma potencia',

            }
        } else {
            multiFields = {
                oferta: 'Oferta',
                ahorroAnual: 'Ahorro Anual',
                ahorroPercent: 'Ahorro Percent',
                ahorroPeriodo: 'Ahorro Periodo',
                P1energia: 'P1 Energia',
                P2energia: 'P2 Energia',
                P3energia: 'P3 Energia',
                P4energia: 'P4 Energia',
                P5energia: 'P5 Energia',
                P6energia: 'P6 Energia',
                sumEnergia: 'Suma Energia',
                P1potencia: 'P1 Potencia',
                P2potencia: 'P2 Potencia',
                P3potencia: 'P3 Potencia',
                P4potencia: 'P4 Potencia',
                P5potencia: 'P5 Potencia',
                P6potencia: 'P6 Potencia',
                sumPotencia: 'Suma potencia',

            }
        }

        var multiTableData: any = [];
        if (valorlongitud < 6) {

            multiTableData = this.state.totalComparacion.map((row, index) => {
                return {
                    comercializadora: this.state.producto[index].idComercializadora,
                    oferta: this.state.producto[index].nombreOferta,
                    id: this.state.producto[index].id,
                    ahorroAnualNum: row['ahorroAnual'],
                    ahorroAnual: row['ahorroAnual'].toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' €',
                    ahorroPercent: row['ahorroPercent'].toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' %',
                    ahorroPeriodo: this.state.formActual ? (row['ahorroPeriodo'] * (this.state.formActual.numDaysNew / 365)).toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' €' : null,
                    P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    sumPotencia: this.state.formMulti[index] ? this.state.formMulti[index].sumPotencia : null,
                    sumEnergia: this.state.formMulti[index] ? this.state.formMulti[index].sumEnergia : null,
                }
            });
        } else {

            //isFinite(value) ? value : 0.0;

            multiTableData = this.state.totalComparacion.map((row, index) => {

                return {
                    comercializadora: this.state.producto[index].idComercializadora,
                    oferta: this.state.producto[index].nombreOferta,
                    id: this.state.producto[index].id,
                    ahorroAnualNum: row['ahorroAnual'],
                    ahorroAnual: row['ahorroAnual'].toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' €',
                    ahorroPercent: row['ahorroPercent'].toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' %',
                    ahorroPeriodo: this.state.formActual ? (row['ahorroPeriodo'] * (this.state.formActual.numDaysNew / 365)).toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }) + ' €' : null,
                    P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P3potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P4potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P4energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P5potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P5energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P6potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioPotencia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    P6energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioEnergia.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true }),
                    sumPotencia: this.state.formMulti[index] ? this.state.formMulti[index].sumPotencia : null,
                    sumEnergia: this.state.formMulti[index] ? this.state.formMulti[index].sumEnergia : null,
                }
            });

        }
        //multiTableData.sort((a, b) => parseFloat(b.ahorroAnual) - parseFloat(a.ahorroAnual));
        multiTableData.sort((a, b) => parseFloat(b.comisionConsumo) - parseFloat(a.comisionConsumo));
        var datosConsumosPotencia: any = []
        var datosConsumosEnergia: any = []

        for (let i = 1; i <= valorlongitud; i++) {
            let consumoAnual = `ConsumoAnualActivaP${i}`
            if (this.state.sip[consumoAnual] >= 0) {
                let periodoP = `P${i}`;
                let valor = 0;
                if (i == 1) {
                    valor = this.state.sip.potenciasContratadasEnWP1
                } else if (i == 2) {
                    valor = this.state.sip.potenciasContratadasEnWP2
                } else if (i == 3) {
                    valor = this.state.sip.potenciasContratadasEnWP3
                } else if (i == 4) {
                    valor = this.state.sip.potenciasContratadasEnWP4
                } else if (i == 5) {
                    valor = this.state.sip.potenciasContratadasEnWP5
                } else if (i == 6) {
                    valor = this.state.sip.potenciasContratadasEnWP6
                }
                datosConsumosPotencia.push({ periodo: periodoP, potencia: valor });
            }
        }

        for (let i = 1; i <= valorlongitud; i++) {

            let periodoPE = `P${i}`;
            let valor = 0;

            this.state.itemsConsumo.map(row => {
                //consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                //consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0) + (Number(row[`consumoEnergiaReactivaEnVArhP${periodo}`]) || 0);
                valor += (Number(row[`consumoEnergiaActivaEnWhP${i}`]) || 0)
                return row;
            });

            datosConsumosEnergia.push({ periodo: periodoPE, energia: valor });
        };

        //if (this.state.sip.NumeroPeriodos==="3"){
        //var periodos = [1,2,3]} else {
        var periodos = this.state.periods;
        //};

        // onChangeFieldDatosClientes
        const { selectedOption } = this.state;
        return (
            <>
                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es">

                        <Row className="margin-reset w-100">
                            <Card className="mt-3 mb-3 shadow">
                                <Form innerRef={this.formDatosPropuestaRef}>
                                    <CardBody>
                                        <Row className="">
                                            <h3 className="col item-header">
                                                DATOS CLIENTES
                                            </h3>
                                        </Row>
                                        <Row>

                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="propuesta">
                                                        Nombre Comparativa
                                                    </Label>
                                                    <Input
                                                        id="nombreOferta"
                                                        className="form-control-alternative"
                                                        placeholder="Identificador de la comparativa"
                                                        name="nombreOferta"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.nombreOferta}
                                                        type="text"
                                                        required
                                                        invalid={this.state.errores.nombreOferta == false}
                                                    > </Input>

                                                    <FormFeedback>{this.state.textErrores.nombreOferta}</FormFeedback>
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreTitular">
                                                        Nombre
                                                    </Label>
                                                    <Input
                                                        className="text-primary form-control-alternative"
                                                        id="nombreTitular"
                                                        placeholder=""
                                                        name="nombreTitular"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.nombreTitular}
                                                        type="text"
                                                        required
                                                        invalid={this.state.errores.nombreTitular == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.nombreTitular}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Titular">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido1Titular"
                                                        placeholder=""
                                                        name="apellido1Titular"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.apellido1Titular}
                                                       type="text"
                                                        required
                                                        invalid={this.state.errores.apellido1Titular == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.apellido1Titular}</FormFeedback>
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido2Titular">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido2Titular"
                                                        placeholder=""
                                                        name="apellido2Titular"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.apellido2Titular}
                                                        type="text"
                                                        required

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="tipoTitular">
                                                        TIPO
                                                    </Label>
                                                    <Input
                                                        id="tipoTitular"
                                                        placeholder=""
                                                        name="tipoTitular"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.tipoTitular}
                                                        type="text"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIF">
                                                        NIF/CIF
                                                    </Label>
                                                    <Input
                                                        id="NIF"
                                                        placeholder=""
                                                        name="NIF"
                                                        onChange={this.onChangeFieldDatosClientes}
                                                        value={form.NIF}
                                                        type="text"
                                                        required
                                                        invalid={this.state.errores.NIF == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.NIF}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" sm="2" lg="2" >
                                                <Button
                                                    className="mt-4"
                                                    position="center"
                                                    margin="true"
                                                    onClick={this.getClient}
                                                    color="primary"
                                                    type="button"
                                                    disabled={this.state.isDisabledCIF}
                                                >
                                                    <i className={"bi bi-search"} /> CIF/NIF
                                                </Button>


                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg="auto">

                                                <FormGroup style={radioStyle}>
                                                    <Label className="form-control-label" style={{ paddingRight: '10px' }} htmlFor="tieneRepresentante">
                                                        Datos representante
                                                    </Label>
                                                    <Label className="custom-toggle">
                                                        <Input
                                                            id="tieneRepresentante"
                                                            name="tieneRepresentante"
                                                            onChange={this.onChangeFieldDatosClientes}
                                                            checked={form.tieneRepresentante}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" />
                                                    </Label>

                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">

                                                <FormGroup style={radioStyle}>
                                                    <Label className="form-control-label" style={{ paddingRight: '10px' }} htmlFor="datosps">
                                                        Datos Punto Suministro
                                                    </Label>
                                                    <Label className="custom-toggle">
                                                        <Input
                                                            id="datosps"
                                                            name="datosps"
                                                            onChange={this.onChangeFieldDatosClientes}
                                                            checked={form.datosps}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" />
                                                    </Label>

                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">

                                                <FormGroup style={radioStyle}>
                                                    <Label className="form-control-label" style={{ paddingRight: '10px' }} htmlFor="datosContacto">
                                                        Datos contacto
                                                    </Label>
                                                    <Label className="custom-toggle">
                                                        <Input
                                                            id="datosContacto"
                                                            name="datosContacto"
                                                            onChange={this.onChangeFieldDatosClientes}
                                                            checked={form.datosContacto}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" />
                                                    </Label>

                                                </FormGroup>
                                            </Col>
                                            <Col lg="auto">
                                                <FormGroup style={radioStyle}>
                                                    <Label className="form-control-label" style={{ paddingRight: '10px' }} htmlFor="GDPR">
                                                        Autoriza GDPR
                                                    </Label>
                                                    <Label className="custom-toggle">
                                                        <Input
                                                            id="GDPR"
                                                            name="GDPR"
                                                            onChange={this.onChangeField}
                                                            checked={form.GDPR}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" />
                                                    </Label>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <div id="datosRepresentante">
                                            {
                                                this.state.show &&
                                                <React.Fragment>

                                                    <Row>
                                                        <h2 className="col item-header">
                                                            REPRESENTANTE
                                                        </h2>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                                    Nombre
                                                                </Label>
                                                                <Input
                                                                    id="nombreRepresentante"
                                                                    placeholder=""
                                                                    name="nombreRepresentante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.nombreRepresentante}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="apellido1Representante">
                                                                    Primer Apellido
                                                                </Label>
                                                                <Input
                                                                    id="apellido1Representante"
                                                                    placeholder=""
                                                                    name="apellido1Representante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.apellido1Representante}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                />
                                                            </FormGroup>
                                                        </Col>


                                                        <Col>
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="apellido2Representante">
                                                                    Segundo Apellido
                                                                </Label>
                                                                <Input
                                                                    id="apellido2Representante"
                                                                    placeholder=""
                                                                    name="apellido2Representante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.apellido2Representante}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                />
                                                            </FormGroup>
                                                        </Col>



                                                        <Col>
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                                    NIF
                                                                </Label>
                                                                <Input
                                                                    id="NIFRepresentante"
                                                                    placeholder="Introduzca NIF"
                                                                    name="NIFRepresentante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.NIFRepresentante || ''}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                    invalid={this.state.errores.NIFRepresentante == false}
                                                                >  </Input>
                                                                <FormFeedback>{this.state.textErrores.NIFRepresentante}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2" sm="2" lg="2" >

                                                            <Button
                                                                className="mt-4"
                                                                position="center"
                                                                margin={true}
                                                                onClick={this.getRepresentante}
                                                                color="primary"
                                                                type="button"
                                                                disabled={this.state.isDisabledCIFRepresentante}
                                                            >
                                                                <i className={"bi bi-search"} /> REPRES.
                                                            </Button>

                                                        </Col>


                                                    </Row>

                                                    <Row>
                                                        <Col md="2">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="cargoRepresentante">
                                                                    Cargo
                                                                </Label>
                                                                <Input
                                                                    id="cargoRepresentante"
                                                                    placeholder=""
                                                                    name="cargoRepresentante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.cargoRepresentante}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <FormGroup>
                                                                <Label
                                                                    className="form-control-label"
                                                                    htmlFor="correoRepresentante"
                                                                >
                                                                    Correo de contacto
                                                                </Label>
                                                                <Input
                                                                    id="correoRepresentante"
                                                                    placeholder=""
                                                                    name="correoRepresentante"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.correoRepresentante}
                                                                    type="text"
                                                                    disabled={this.state.isDisabledRepresentante}
                                                                >  </Input>
                                                                {this.state.emailRepreError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                                                            </FormGroup>
                                                        </Col>


                                                    </Row>
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div id="datosPS">
                                            {
                                                this.state.showps &&
                                                <React.Fragment>
                                                    <Row>
                                                        <h3 className="col item-header">
                                                            DATOS PUNTO DE SUMINISTRO
                                                        </h3>
                                                    </Row>
                                                    <Row>
                                                        <Col md="2">
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="tipoViaPS">
                                                                    Tipo Via
                                                                </Label>
                                                                <Input
                                                                    id="tipoViaPS"
                                                                    placeholder=""
                                                                    name="tipoViaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.tipoViaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="6">
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="viaPS">
                                                                    Dirección Suministro
                                                                </Label>
                                                                <Input
                                                                    id="viaPS"
                                                                    placeholder=""
                                                                    name="viaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.viaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>


                                                        <Col md="2">
                                                            <FormGroup>

                                                                <Label className="form-control-label" htmlFor="numFincaPS">
                                                                    Nº Finca
                                                                </Label>
                                                                <Input
                                                                    id="numFincaPS"
                                                                    placeholder=""
                                                                    name="numFincaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.numFincaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>



                                                        <Col md="2">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="portalPS">
                                                                    Portal
                                                                </Label>
                                                                <Input
                                                                    id="portalPS"
                                                                    placeholder=""
                                                                    name="portalPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.portalPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="1">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="escaleraPS">
                                                                    ESC.
                                                                </Label>
                                                                <Input
                                                                    id="escaleraPS"
                                                                    placeholder=""
                                                                    name="escaleraPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.escaleraPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="1">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="pisoPS">
                                                                    Piso
                                                                </Label>
                                                                <Input
                                                                    id="pisoPS"
                                                                    placeholder=""
                                                                    name="pisoPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.pisoPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="1">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="puertaPS">
                                                                    Puerta
                                                                </Label>
                                                                <Input
                                                                    id="puertaPS"
                                                                    placeholder=""
                                                                    name="puertaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.puertaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <Label className="form-control-label" htmlFor="CodigoPostalPS">
                                                                C.P.
                                                            </Label>
                                                            <Input type="select" value={form.CodigoPostalPS} name="CodigoPostalPS" id="codigoPostalPS" invalid={this.state.errores.CodigoPostalPS == false} onChange={this.onChangeFieldDatosClientes}>
                                                                <option value={form.CodigoPostalPS} disabled>{form.CodigoPostalPS}</option>
                                                                {this.state.dicMunicipiosList.map((item, key) => (
                                                                    <option key={key} value={item.codigoPostal}>{item.codigoPostal} - {item.entidadsingularnombre}</option>
                                                                ))
                                                                }
                                                            </Input>
                                                            <FormFeedback>{this.state.textErrores.CodigoPostalPS}</FormFeedback>
                                                        </Col>

                                                        <Col md="3">
                                                            <Label className="form-control-label" htmlFor="nombreMunicipioPS">
                                                                Municipio Suministro
                                                            </Label>
                                                            <Input type="select" value={form.nombreMunicipioPS} name="nombreMunicipioPS" id="nombreMunicipioPS" onChange={this.onChangeFieldDatosClientes}>
                                                                <option value={form.nombreMunicipioPS} disabled>{form.nombreMunicipioPS}</option>
                                                                {this.state.dicMunicipios.map((item, key) => (
                                                                    <option key={key} value={item.Cmunicipio + "*" + item.Nombre}>{item.Nombre}</option>
                                                                ))
                                                                }
                                                            </Input>
                                                        </Col>

                                                        <Col md="2">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="nombreProvinciaPS">
                                                                    Provincia
                                                                </Label>
                                                                <Input
                                                                    id="nombreProvinciaPS"
                                                                    placeholder=""
                                                                    name="nombreProvinciaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.nombreProvinciaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="desAutonomiaPS">
                                                                    C. Autónoma
                                                                </Label>
                                                                <Input
                                                                    id="desAutonomiaPS"
                                                                    placeholder=""
                                                                    name="desAutonomiaPS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.desAutonomiaPS}
                                                                    type="text"

                                                                />
                                                            </FormGroup>
                                                        </Col>


                                                    </Row>
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div id="datosContacto">
                                            {
                                                this.state.showContacto &&
                                                <React.Fragment>
                                                    <Row>
                                                        <h3 className="col item-header">
                                                            DATOS CONTACTO
                                                        </h3>
                                                    </Row>
                                                    <Row>
                                                        <Col md="5">
                                                            <FormGroup>
                                                                <Label
                                                                    className="form-control-label"
                                                                    htmlFor="idComercializadora"
                                                                >
                                                                    Comercializadora de procedencia
                                                                </Label>
                                                                <SipsOption
                                                                    placeholder=""
                                                                    id="idComercializadora"
                                                                    name="idComercializadora"
                                                                    value={form.idComercializadora}
                                                                    query="LIST_MARKETERS"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                />
                                                                <FormFeedback>{this.state.textErrores.idComercializadora}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <FormGroup>
                                                                <Label
                                                                    className="form-control-label"
                                                                    htmlFor="Email"
                                                                >
                                                                    Correo de contacto
                                                                </Label>
                                                                <Input
                                                                    id="Email"
                                                                    placeholder=""
                                                                    name="Email"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    value={form.Email}
                                                                    type="email"
                                                                    invalid={this.state.errores.Email == false}
                                                                >  </Input>
                                                                <FormFeedback>{this.state.textErrores.Email}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <FormGroup>
                                                                <Label className="form-control-label" htmlFor="cliente">
                                                                    Tipo Cliente
                                                                </Label>
                                                                <Input
                                                                    id="TipoCliente"
                                                                    placeholder=""
                                                                    name="TipoCliente"
                                                                    onChange={this.onChangeFieldDatosClientes}
                                                                    hidden={tipoCliente.length > 0 ? false : true}
                                                                    type="select" multiselect="true">
                                                                    {tipoCliente.map((item: any, index) => <option value={item.cat} key={index}>{item.cat}</option>)}

                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="3">
                                                            <Label className="form-control-label" htmlFor="TelefonoFijo">
                                                                Teléfono Fijo
                                                            </Label>
                                                            <Input
                                                                id="TelefonoFijo"
                                                                placeholder=""
                                                                name="TelefonoFijo"
                                                                onChange={this.onChangeFieldDatosClientes}
                                                                value={form.TelefonoFijo}
                                                                type="text"
                                                                invalid={this.state.errores.TelefonoFijo == false}
                                                            > </Input>
                                                            <FormFeedback>{this.state.textErrores.TelefonoFijo}</FormFeedback>

                                                        </Col>
                                                        <Col md="3">
                                                            <Label className="form-control-label" htmlFor="TelefonMovil">
                                                                Teléfono Móvil
                                                            </Label>
                                                            <Input
                                                                id="TelefonMovil"
                                                                placeholder=""
                                                                name="TelefonMovil"
                                                                onChange={this.onChangeFieldDatosClientes}
                                                                value={form.TelefonMovil}
                                                                type="text"

                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Label className="form-control-label" htmlFor="ObservacionesCliente">
                                                                Observaciones Cliente
                                                            </Label>
                                                            <Input
                                                                id="ObservacionesCliente"
                                                                placeholder=""
                                                                name="ObservacionesCliente"
                                                                onChange={this.onChangeFieldDatosClientes}
                                                                value={form.ObservacionesCliente}
                                                                type="text"
                                                            />

                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            }
                                        </div>

                                    </CardBody>
                                    <div className="modal-footer">
                                        <Button
                                            onClick={this.generateComparativeControl}
                                            color="primary"
                                            type="button"
                                        >
                                            Guardar Comparativa
                                        </Button>
                                    </div>

                                </Form>

                            </Card>
                        </Row>
                        <Row className="margin-reset w-100">
                            <Card className="mt-3 mb-3 shadow card-billing-template">
                                <Form innerRef={this.formActualRef}>
                                    <CardBody>
                                        <Row className="">
                                            <h2 className="col item-header">
                                                Simulación factura comercializadora actual
                                            </h2>
                                        </Row>
                                        <Row>
                                            <Table className="table-actual align-items-center table-flush" responsive key="table-data">
                                                <thead>
                                                    <th>Periodo Lectura</th>
                                                    <th>Precio potencia €/kW día</th>
                                                    <th>Precio Energía €/kWh</th>
                                                </thead>
                                                <tbody>
                                                    {periodos.includes(1) ?
                                                        <tr>
                                                            <td>P1</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP1" name="precioTP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTP1} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP1" name="precioTEP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP1} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                    {periodos.includes(2) ?
                                                        <tr>
                                                            <td>P2</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP2" name="precioTP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTP2} />

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP2" name="precioTEP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP2} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                    {periodos.includes(3) ?
                                                        <tr>
                                                            <td>P3</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP3" name="precioTP3" onChange={this.onChangeFieldFormActual} value={formActual.precioTP3} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP3" name="precioTEP3" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP3} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                    {periodos.includes(4) ?
                                                        <tr>
                                                            <td>P4</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP4" name="precioTP4" onChange={this.onChangeFieldFormActual} value={formActual.precioTP4} />

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP4" name="precioTEP4" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP4} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                    {periodos.includes(5) ?
                                                        <tr>
                                                            <td>P5</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP5" name="precioTP5" onChange={this.onChangeFieldFormActual} value={formActual.precioTP5} />

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP5" name="precioTEP5" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP5} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                    {periodos.includes(6) ?
                                                        <tr>
                                                            <td>P6</td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTP6" name="precioTP6" onChange={this.onChangeFieldFormActual} value={formActual.precioTP6} />

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-control">
                                                                    <Input type="number" step="0.01" id="precioTEP6" name="precioTEP6" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP6} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : ''}
                                                </tbody>
                                            </Table>
                                        </Row>
                                        <Row className="panel-data-resolve">
                                            <div className="col">
                                                <div className="item-data">
                                                    <h5 className="">Término Potencia {this.state.daysOperacion} días</h5>
                                                    <div className="">{formActual.sumPotencia}€</div>
                                                </div>

                                                <div className="item-data">
                                                    <h5 className="">Descuento sobre consumo Potencia</h5>
                                                    <div className="">
                                                        <input type="number" step="1.0" name="descuentoPotenciaMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoPotenciaMonth}></input>€

                                                    </div>
                                                    <h5 className="">Total</h5>
                                                    <div>{formActual.descuentoPotencia}%</div>
                                                </div>


                                                <div className="item-data">
                                                    <h5 className="">Descuento sobre consumo Energía</h5>
                                                    <div className="">
                                                        <input type="number" step="1.0" name="descuentoMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoMonth}></input>€

                                                    </div>
                                                    <h5 className="">Total</h5>
                                                    <div>{formActual.descuento}%</div>
                                                </div>


                                                <div className="item-data">
                                                    <h5 className="">Penalización Potencia</h5>
                                                    <div className="">{formActual.penalizacionPotencia}€</div>
                                                </div>
                                                <div className="item-data">
                                                    <h5 className="">I.E. 5,11%</h5>
                                                    <div className="">{formActual.iePercent}€</div>
                                                </div>
                                                <div className="item-data">
                                                    <h5 className="">Servicios Adicionales por mes</h5>
                                                    <div className="">
                                                        <input type="number" step="0.01" name="serviciosAdicionalesMonth" onChange={this.onChangeFieldFormActual} value={formActual.serviciosAdicionalesMonth}></input>€

                                                    </div>
                                                    <h5 className="">Total Servicios</h5>
                                                    <div>{formActual.serviciosAdicionales}€</div>
                                                </div>


                                                <div className="item-data">
                                                    <h5 className="">Impuestos </h5>
                                                    <div style={{ "width": '190px', "height": '20px' }}>
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Selecciona Imp."

                                                            value={options.filter(obj => obj.value === selectedOption)}
                                                            onChange={this.handleChange}
                                                            options={options}

                                                        />
                                                    </div>
                                                    <h5 className="">IVA</h5>
                                                    <div className="">{formActual.iva}€</div>
                                                </div>
                                            </div>




                                            <div className="col">
                                                <div className="item-data">
                                                    <h5>Término Energía {this.state.daysOperacion} días</h5>
                                                    <div className="">{formActual.sumEnergia}€</div>
                                                </div>

                                                <div className="item-data">
                                                    <h5>Penalización Energía</h5>
                                                    <div className="">
                                                        <input type="number" step="5.0" name="penalizacionEnergia" onChange={this.onChangeFieldFormActual} value={formActual.penalizacionEnergia}></input> €
                                                    </div>
                                                </div>
                                                <div className="item-data">
                                                    <h5 className="">Base Impuesto Eléctrico</h5>
                                                    <div className="">{formActual.impuestoElectrico} €</div>
                                                </div>
                                                <div className="item-data">
                                                    <h5 className="">Alquiler del Equipo a Medida por mes</h5>
                                                    <div className="">
                                                        <input type="number" step="0.01" name="alquilerEquipoMedidaMonth" onChange={this.onChangeFieldFormActual} value={formActual.alquilerEquipoMedidaMonth}></input>€
                                                    </div>
                                                    <h5 className="">Total Alquiler</h5>
                                                    <div>{formActual.alquilerEquipoMedida}€</div>
                                                </div>


                                                <div className="item-data">
                                                    <h5 className="">BASE IMPONIBLE</h5>
                                                    <div className="">{formActual.bi}€</div>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="">
                                            <div className="col item-total">
                                                <h2>
                                                    TOTAL
                                                </h2>
                                                <div>
                                                    {formActual.total}€
                                                </div>
                                            </div>
                                        </Row>

                                    </CardBody>
                                </Form>
                            </Card>

                        </Row>

                        <Row className="margin-reset w-100">
                            <Card className="mt-3 mb-3 shadow card-billing-template">
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col item-header">
                                            Propuesta Contrato.
                                        </h2>
                                    </Row>


                                    <this.tipoContrato tipoTarifa={this.state.formDatosContrato.tarifaATR} />


                                    <Row className="">


                                        <Col md="2">
                                            <FormGroup>


                                                <Label className="form-control-label" htmlFor="cp1">
                                                    Penalización Potencia
                                                </Label>
                                                <div className="p-2 bg-secondary">
                                                    <h5 style={{ textAlign: "center", backgroundColor: "white" }} className="form-control-alternative">{penalizacionPotenciaActual || 0} €</h5>
                                                </div>


                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>


                                                <Label className="form-control-label" htmlFor="cp2">
                                                    Penalización Reactiva
                                                </Label>
                                                <div className="p-2 bg-secondary">
                                                    <h5 style={{ textAlign: "center", backgroundColor: "white" }} className="form-control-alternative">{penalizacionReactivaCalculo || 0} €</h5>
                                                </div>


                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>


                                                <Label className="form-control-label" htmlFor="cp2">
                                                    Valor Max. BIE
                                                </Label>
                                                <div className="p-2 bg-secondary">
                                                    <h5 style={{ textAlign: "center", backgroundColor: "white" }} className="form-control-alternative">{bie || 0}</h5>
                                                </div>


                                            </FormGroup>
                                        </Col>


                                    </Row>

                                    <Row className="">
                                        <h2 className="col item-header">
                                            Ahorros por cada uno de los productos seleccionados
                                        </h2>
                                    </Row>

                                    <SipsTable
                                        checkBoxButton={true}
                                        hideViewMoreButton={true}
                                        items={multiTableData}
                                        fields={multiFields}
                                        selectedItem={this.selectedItem}
                                        expandableFields={false}
                                        showFreeMarketButton={true}
                                    ></SipsTable>
                                </CardBody>
                            </Card>

                        </Row>
                        <Row className="margin-reset w-100">
                            <Card>
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col item-header">
                                            Coste Operativo por periodo (€/Mw)
                                        </h2>
                                    </Row>
                                    <Row className="">
                                        <Grid
                                            style={{ height: '100%' }}
                                            data={this.state.dataComisionesProducto.map((item) => ({
                                                ...item,
                                                inEdit: item.idProducto === this.state.editID,
                                            }))}
                                            editField="inEdit"
                                            onRowClick={this.rowClick}
                                            onItemChange={this.itemChange}
                                            resizable={true}
                                        >
                                            <Column field="idProducto" title="Id" width="150px" editable={false} className="p" />
                                            <Column field="ProductName" title="Name" width="50px" />
                                            <Column
                                                field="coTE1"
                                                title="coTE1"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTE2"
                                                title="coTE2"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTE3"
                                                title="coTE3"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTE4"
                                                title="coTE4"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTE5"
                                                title="coTE5"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTE6"
                                                title="coTE6"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP1"
                                                title="coTEP1"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP2"
                                                title="coTEP2"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP3"
                                                title="coTEP3"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP4"
                                                title="coTEP4"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP5"
                                                title="coTEP5"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="coTEP6"
                                                title="coTEP6"
                                                editor="numeric"
                                                format="{0:c2}"
                                            />
                                        </Grid>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>

                        <Row className="margin-reset w-100">
                            <Card>
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col item-header">
                                            Comisiones por periodo
                                        </h2>
                                    </Row>
                                    <Row className="">
                                        <Grid
                                            style={{ height: '100%' }}
                                            data={this.state.multiTableDataComision.map((item) => ({
                                                ...item
                                            }))}
                                            resizable={true}
                                            scrollable="scrollable"
                                        >
                                            <Column field="id" title="Id" width="150px" editable={false} className="p" />
                                            <Column field="idTipoComision" title="Tipo" width="50px" />
                                            <Column
                                                field="comisionAgenteTEP1"
                                                title="coTE1"

                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTEP2"
                                                title="coTE2"

                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTEP3"
                                                title="coTE3"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTEP4"
                                                title="coTE4"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTEP5"
                                                title="coTE5"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTEP6"
                                                title="coTE6"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP1"
                                                title="coTEP1"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP2"
                                                title="coTEP2"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP3"
                                                title="coTEP3"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP4"
                                                title="coTEP4"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP5"
                                                title="coTEP5"
                                                format="{0:c2}"
                                            />
                                            <Column
                                                field="comisionAgenteTPP6"
                                                title="coTEP6"
                                                format="{0:c2}"
                                            />
                                        </Grid>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>

                        <Row className="margin-reset w-100">
                            <Card>
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col item-header">
                                            Comisiones productos sin Coste Operativo
                                        </h2>
                                    </Row>
                                    <Row className="">
                                        <Grid
                                            style={{ height: '100%' }}
                                            data={this.state.multiTableDataComisionConCo.map((item) => ({
                                                ...item
                                            }))}
                                            resizable={true}
                                            scrollable="scrollable"
                                        >
                                            <Column field="id" title="Id" width="150px" editable={false} className="p" />
                                            <Column field="idTipoComision" title="Tipo" width="50px" />
                                            <Column
                                                field="comisionAgenteTEP1"
                                                title="comision Producto"

                                                format="{0:c2}"
                                            />


                                        </Grid>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>

                        <Row className="margin-reset w-100">
                            <Card className="mt-3 mb-3 shadow card-billing-template">
                                <ResponsiveContainer width="100%" height="100%">

                                    <BarChart
                                        width={700}
                                        height={300}
                                        data={multiTableData}
                                        margin={{
                                            top: 40,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="oferta" />
                                        <YAxis label={{ value: 'Importe en €', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                                        <Tooltip />
                                        <Legend />

                                        <ReferenceLine y={0} stroke="#000" />
                                        <Bar dataKey="sumEnergia" fill="#0088fe" legendType="none" name='Importe Energía' />
                                        <Bar dataKey="sumPotencia" fill="#f65cb5" legendType="none" name='Importe Potencia' />
                                        <Bar dataKey="ahorroAnualNum" fill="#ff8042" legendType="none" name='Importe Ahorro Anual' /></BarChart>
                                </ResponsiveContainer>

                            </Card>

                            <Card className="mt-3 mb-3 shadow card-billing-template">
                                <ResponsiveContainer aspect={2}>
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            activeIndex={this.state.activeIndex}
                                            activeShape={renderActiveShape}
                                            data={datosConsumosEnergia}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="energia"
                                            onMouseEnter={this.onPieEnter}
                                        />
                                    </PieChart>


                                </ResponsiveContainer>




                            </Card>
                        </Row>
                        {this.state.alert}
                    </IntlProvider>
                </LocalizationProvider>
            </>
        );
    }
};

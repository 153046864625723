import React from "react";
import {
    Button,
    Container,
    CardBody,
    FormGroup,
    Modal,
    Label,
    Col,
    Input,
    Row,
    Card,
    Form,
} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from 'axios';

interface IModalComisionProps {
    comercializadora: string;
    tipoProducto: string;
    tipoComision: string;
    companyId: string;
    isOpen: boolean;
    toggle: any;
    dataComisiones: Function;
}

interface IModalComisionState {

    //representanteSel:any[],
    user: any,
    companyId: string,
    comercializadora: string,
    tipoProducto: string,
    tipoComision: string,
    comisiones: any[],
    comisionSeleccionada: any[],
    alert: any
}
const sortIcon = <ArrowDownward />;

const columnsDatosComisiones = [
    { selector: row => row.id, name: 'ID', sortable: true, width: '180px', center: true, format: (row => capitalize(row.id)) },
    { selector: row => row.nombreComision, name: 'COMISION', sortable: true, width: '200px', format: (row => capitalize(row.nombreComision)) },
    { selector: row => row.tipoComisionProducto, name: 'TIPO', sortable: true, width: '180px', center: true, format: (row => capitalize(row.tipoComisionProducto)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '100px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row.esActiva, name: 'ACTIVA', sortable: true, width: '180px', center: true },
    { selector: row => row.coIncluido, name: 'CO INCLUIDO', sortable: true, width: '100px', center: true },
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

export default class ComisionProductoFreeModal extends React.Component<IModalComisionProps, IModalComisionState> {
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public state: IModalComisionState = {
        // --- Data
        user: {},
        companyId: "",
        comercializadora: "",
        tipoProducto: "",
        tipoComision: "",
        comisiones: [],
        comisionSeleccionada: [],
        alert: null
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            companyId: newProps.companyId,
            comercializadora: newProps.comercializadora,
            tipoProducto: newProps.tipoProducto,
            tipoComision: newProps.tipoComision

        })


    }

    componentDidMount() {
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Representante No seleccionado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Debe selecccionar un representante para el contrato
                </ReactBSAlert>
            )
        });
    };

    warningAlertDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    fetchItems = async () => {

        const companyIdUser = Number(this.props.companyId);

        var idComercializadoraAux = ''
        //if (this.state.filters.codeComercializadora != '0' && this.state.filters.codeComercializadora != '') {
        //    idComercializadoraAux = this.state.filters.codeComercializadora
        //}

        var dataFilters = {
            'idComercializadora': this.props.comercializadora,
            'tipoContrato': this.props.tipoProducto,
            'tipoComisionProducto': this.props.tipoComision,
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };


        let direccionFile = "https://datapi.psgestion.es/comision/listacomisiones?id=" + companyIdUser;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                let data = result.data.rows
                this.setState({ comisiones: data })

            }).catch((error) => {
                console.log('Failure')
                this.warningAlertDB()
            });
    };


    handleChange = state => {
        // eslint-disable-next-line no-console
        console.log('state', state.selectedRows[0]);
        console.log(state.selectedCount)

        var contrato = '';

        console.log('VALOR DE this.state', this.state)    //
        if (state.selectedCount === 1) {
            var existeRepre = ''
            this.setState({ comisionSeleccionada: state.selectedRows[0] });
        }
    };

    comision = async (event) => {
        if (this.state.comisionSeleccionada) {
            let seleccion = this.state.comisionSeleccionada;
            console.log(this.props)
            this.props.dataComisiones({
                comision: seleccion
            });
            this.props.toggle();
            return;
        }
        this.warningAlert()
    };

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}>

                    <Container>


                        <Card className="shadow">

                            <Row>
                                <DataTable
                                    title="COMISIONES"
                                    columns={columnsDatosComisiones}
                                    data={this.state.comisiones}
                                    selectableRows
                                    selectableRowsComponent={Checkbox} // Pass the function only
                                    selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                    selectableRowsSingle
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChange}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                    subHeader
                                    progressComponent={<Circular />}
                                />

                            </Row>
                        </Card>
                    </Container>


                    {this.state.alert}
                    <div className="modal-footer">
                        <Button
                            onClick={this.props.toggle}
                            color="secondary"
                            data-dismiss="modal"
                            type="button">
                            Cerrar
                        </Button>
                        <Button onClick={this.comision} color="primary" type="button">
                            Añadir Comisión
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

import React from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
//import '@progress/kendo-theme-bootstrap/dist/all.scss';
import '@progress/kendo-theme-bootstrap/dist/all.scss';
//import 'enercom/dist/scss/index.scss'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/vendor/stroke-icons/Pe-icon-7-stroke.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/argon-dashboard-pro-react.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';


import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/Admin";
import PrintLayout from "./layouts/Print";
import AuthLayout from "./layouts/Auth";
import ConsumosLayout from "./layouts/Consumos";
import { ApolloProvider } from '@apollo/react-hooks';
import client from './api/client';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks';
import PublicRoute from './components/Auth/PublicRoute';
import PrivateRoute from './components/Auth/PrivateRoute';
import * as Icon from 'react-feather';

// Toast
import { ToastContainer } from 'react-toastify';

// Locale
import { registerLocale, setDefaultLocale } from "react-datepicker";
import  es  from 'date-fns/locale/es';
registerLocale('es', es)
setDefaultLocale('es', es)
const process = require('process');
process.title = "sipsApp";

const App: React.FC = () => {
  return (
    <ApolloProviderHooks client={client}>
      <ApolloProvider client={client}>
        <BrowserRouter basename="/" >
          <Switch>
            <PrivateRoute path="/print" component={PrintLayout} />
<PrivateRoute path="/consumos" component={ConsumosLayout} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <PublicRoute path="/auth" restricted={true} component={AuthLayout} />
            <Redirect from="/" to="/auth/login" />
          </Switch>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </ApolloProvider>
    </ApolloProviderHooks>
  );
}

export default App;

import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import { Link } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";

// core components
import Header from "../../components/Headers/Header";
import SipsFilter from "../../components/Sips/SipsFilter";
//import SipsTable from "../../components/Sips/SipsTable";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/Pagination";
import { LIST_SIPS, EXPORT_SIPS } from "../../queries/sips/sips.graphql";
import Environment from "../../constants/environment";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import memoize from 'memoize-one';
import moment from "moment";
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";


const sortIcon = <ArrowDownward />;

const customStyles = {
	headRow: {
		style: {
			border: 'none',

		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '12px',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      paddingLeft: '8px',
      paddingRight: '8px',
      minWidth: '55px'

		},
	},
  cells: {
  style: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '55px',
    wordBreak: 'break-word',
    fontFamily: '"Open Sans", sans-serif;'
  },
},
	/*rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 250, 250)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
      fontSize: '12px',
		},
	},*/
	pagination: {
		style: {
			border: 'none',
		},
	},
};

class Sips extends Reflux.Component<any, any> {
  lastFilters = {};
  state = {
    UserStore:{},
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 10,
    electricidad: false,
    paginatedData: {
      items: [],
    },
    showIndexButton: false,
    showFreeMarketButton: false
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
  }


  componentWillReceiveProps (){

  const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))


  const companyNameGlobal = usuario.company;
  const companyIdUser = usuario.companyId;

  const userGroupId = usuario.user_group_id
  const userId = usuario.id
  const electricidad = usuario.electricidad


    if (userGroupId === 1000){
      this.setState({
        activeUser: true
      })};

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      electricidad

    });
  }


  componentDidMount() {

    let showIndexButton = false;
    let showFreeMarketButton = false;
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        showIndexButton = !parsed.indexada;
        showFreeMarketButton = !parsed.mercadolibre;
      } catch (e) {}
    }
    this.setState({ showIndexButton, showFreeMarketButton });
  }

  onSubmit = (filters) => {
    this.lastFilters = filters;
    this.setState({ page: 1 }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  export = async () => {
    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFilters;
    let { page, limit } = this.state;
    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SIPS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

  fetchItems = async (filters) => {
    let isLoading = true;
    const isSearching = true;
    this.setState({ isLoading, isSearching });
    this.lastFilters = filters;

    if (this.lastFilters.hasOwnProperty("limit")) {
      this.state.limit = parseInt(filters.limit, 10);
    }
    if (
      (!this.state.limit || this.state.limit === 0) &&
      (this.state.pageSize !== null || this.state.pageSize > 1)
    ) {
      this.state.limit = this.state.pageSize;
    }

    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      delete filters["limit"];
    }

    if (limit === 0 && this.state.pageSize > 1) {
      limit = this.state.pageSize;
    }

    this.props.client.query({
      query: LIST_SIPS,
      variables: { page, limit, filters },
    }).then( res => {

      const {
        currentPage,
        pageSize,
        pageCount,
        count,
        items,
      } = res.data.listSips;

      const paginatedData = { items };
      isLoading = false;

      this.setState({
        paginatedData,
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
      });
    })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })

  };

  onPageClick = (page) => {
    this.setState({ page }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  handleChange = state => {
  // eslint-disable-next-line no-console
  // eslint-disable-next-line react/no-unused-state
  this.setState({ selectedRows: state.selectedRows });
};

handleButtonClick = () => {
  // eslint-disable-next-line no-console
  console.log('clicked');
};

  beforeRenderItem = (index, item) => {
    if (index.includes("fecha") && item.value) {
      const dateValue = new Date(item.value);
      item.value = dateValue.toISOString().split("T")[0];
    }
    return true;
  };

  render() {
    //const data = this.state.paginatedData.items;
    let noResults = <></>;
    if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
      if (this.state.isSearching) {
        noResults = (
          <p className="alert alert-danger">No se encontraron resultados</p>
        );
      } else {
        noResults = (
          <p className="alert alert-info">
            Realice su busqueda utilizando el formulario de arriba
          </p>
        );
      }
    }

    const columns = memoize(clickHandler => [
      {
      // eslint-disable-next-line react/button-has-type
      //cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
      //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,
      
      cell: row => <Link
        className="btn btn-primary btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-details/" + row.cups }}
        id = "buscartooltip"
      >
        <i className="pe-7s-search text-white"></i>
      </Link>,
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      name: 'INFO',
      minWidth: '55px'
      
      },
      {
      cell: row => <Link
        className="btn btn-light btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-comparative-index/" + row.cups }}
         id = "indextooltip"
      >
        <i className="pe-7s-date text-white"></i>
      </Link>,
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: this.state.showIndexButton,
      name: 'INDEX',
      minWidth: '55px'
      
      },
      {
      cell: row => <Link
        className="btn btn-info btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-comparative-free/" + row.cups }}
        id = "fijotooltip"
      >
        <i className="pe-7s-calculator text-white"></i>
      </Link>,
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: this.state.showFreeMarketButton,
      name: 'FIJO',
      minWidth: '55px'
      
      },
      
      
      {
        selector: row => row['ProblemaIdString'],
        //cell: row => <Icon style={{ fill: '#43a047' }} />,
        name: 'P',
        sortable: true,
        width: '40px',
        center: true,
        conditionalCellStyles: [
          {
            when: row => row.ProblemaIdString === "",
      
            style: {
              backgroundColor: 'rgba(255, 255, 255, 0)',
              color: 'white',
              fontSize: '8px',
              width: '20px',
              borderRadius: '5px',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
      
            {
              when: row => row.ProblemaIdString !== "",
              style: {
                backgroundImage: 'url(/error.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '30px',
                backgroundPosition: 'center',
                //height: "50vh",
                //backgroundColor: 'rgba(248, 148, 6, 0.9)',
                color: 'rgba(250, 250, 250, 0)',
                fontSize: '8px',
                //width: '48px',
                //width: `calc(100vw + 48px)`,
                borderRadius: '5px',
                '&:hover': {
                  cursor: 'pointer',
      
                },
              },
            },
          ]
      
      
      },
      
      
        {selector: row => row['cups'], name: 'CUPS',  width: '190px',		style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 500,
      
          },},
      
        {selector: row => row['nombreCompletoTitular'] , name: 'TITULAR', sortable: true, style: {
            color: 'rgba(0,0,0,.54)',
          },},
        {selector: row => row['CIF'] , name: 'CIF', sortable: true,},
        {selector: row => row['nombreEmpresaDistribuidora']  , name: 'DISTRIBUIDORA PRINCIPAL', sortable: true, center: true, wrap: true},
        {selector: row => row['empresaDistribuidora'] , name: 'DISTRIBUIDORA', sortable: true, },
        {selector: row => row['comercializadora'] , name: 'COMERCIALIZADORA',sortable: true, width: '210px', center: true, wrap: true },
        {selector: row => row['direccion_ps']  , name: 'DIRECCION' },
        {selector: row => row['localidad_ps'] , name: 'LOCALIDAD', sortable: true },
        {selector: row => row['codigoPostalPS'] , name: 'COD. POSTAL', sortable: true, width: '120px', center: true, },
        {selector: row => row['nombreProvinciaPS'] , name: 'PROVINCIA', sortable: true, width: '130px', center: true, },
        {selector: row => row['tarifaATR'], name: 'TARIFA', sortable: true, width: '100px', center: true, },
        {selector: row => row['kWhAnual'] , name: 'kWh ANUAL', sortable: true,  width: '110px', center: true, },
        {selector: row => row['fechaUltimoMovimientoContrato'] , name: 'ULT. MOVIMIENTO' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaUltimoMovimientoContrato).isValid() ? moment(row.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "")},
        {selector: row => row['fechaUltimoCambioComercializador'] , name: 'ULT. CAMBIO COMERCIAL', sortable: true, width: '110px', right:true,  format: (row => moment(row.fechaUltimoCambioComercializador).isValid() ? moment(row.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "")},
        {selector: row => row['fechaAltaSuministro'] , name: 'FECHA ALTA' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaAltaSuministro).isValid() ? moment(row.fechaAltaSuministro).format('DD-MM-YYYY') : "")},
        {selector: row => row['fechaLimiteExtension']  , name: 'FECHA LIMITE EXT.', sortable: true, width: '110px', right:true,  format: (row => moment(row.fechaLimiteExtension).isValid() ? moment(row.fechaLimiteExtension).format('DD-MM-YYYY') : "")},
        {selector: row => row['fechaUltimaLectura']  , name: 'FECHA ULT. LECTURA', sortable: true, width: '110px', right:true,  format: (row => moment(row.fechaUltimaLectura).isValid() ? moment(row.fechaUltimaLectura).format('DD-MM-YYYY') : "")},
      
        {selector: row => row['CNAE']  , name: 'CNAE',  width: '120px', center: true, },
        {selector: row => row['actividadCNAE']  , name: 'DESC. CNAE',  width: '120px', center: true, },
        {selector: row => row['contactoTitular'], name: 'CONTACTO TITULAR',  width: '130px', center: true, },
        {selector: row => row['telefono'] , name: 'TELEFONO',  width: '120px', center: true, },
        {selector: row => row['potenciaMaximaBIEW'] , name: 'POT. MAX. BIE',  width: '120px', center: true, },
      
        //{ selector: 'esViviendaHabitual', name: 'PRIM. VIVIENDA', sortable: true, width: '120px', center: true, },
        //{ selector: 'informacionImpagos', name: 'IMPAGO',  width: '130px', center: true, },
      
        //{ selector: 'trimestreCambioContador', name: 'TRIMESTRE CAMBIO CONTADOR', sortable: true, width: '120px', center: true, },
        //{ selector: 'codigoAutoconsumo', name: 'COD. AUTOCONSUMO', sortable: true, width: '120px', center: true, },
        {selector: row => row['potenciasContratadasEnWP1'] , name: 'POTENCIA CONTRATADA KW P1',  width: '130px', center: true, },
        {selector: row => row['potenciasContratadasEnWP2']  , name: 'POTENCIA CONTRATADA KW P2',  width: '130px', center: true, },
        {selector: row => row['potenciasContratadasEnWP3'] , name: 'POTENCIA CONTRATADA KW P3',  width: '130px', center: true, },
        {selector: row => row['potenciasContratadasEnWP4']  , name: 'POTENCIA CONTRATADA KW P4',  width: '130px', center: true, },
        {selector: row => row['potenciasContratadasEnWP5']  , name: 'POTENCIA CONTRATADA KW P5',  width: '130px', center: true, },
        {selector: row => row['potenciasContratadasEnWP6'] , name: 'POTENCIA CONTRATADA KW P6', width: '130px', center: true, },
        {selector: row => row['ConsumoAnualTotalActiva'] , name: 'TOTAL CONSUMO AÑO ACTUAL', sortable: true, width: '120px', center: true, },
        {selector: row => row['ConsumoAnualActivaP1']  , name: 'CONSUMO ANUAL KW P1', width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP2'] , name: 'CONSUMO ANUAL KW P2',   width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP3']  , name: 'CONSUMO ANUAL KW P3',  width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP4'] , name: 'CONSUMO ANUAL KW P4',  width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP5']  , name: 'CONSUMO ANUAL KW P5',  width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP6']  , name: 'CONSUMO ANUAL KW P6', width: '130px', center: true, },
      
        {selector: row => row['ConsumoAnualTotalActivaAnterior'] , name: 'TOTAL CONSUMO AÑO ANTERIOR', sortable: true, width: '120px', center: true, },
        {selector: row => row['ConsumoAnualActivaP1Anterior'] , name: 'CONSUMO AÑO ANTERIOR KW P1',  width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP2Anterior'] , name: 'CONSUMO AÑO ANTERIOR KW P2', width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP3Anterior'] , name: 'CONSUMO AÑO ANTERIOR KW P3', width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP4Anterior'] , name: 'CONSUMO AÑO ANTERIOR KW P4', width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP5Anterior'], name: 'CONSUMO AÑO ANTERIOR KW P5',  width: '130px', center: true, },
        {selector: row => row['ConsumoAnualActivaP6Anterior'] , name: 'CONSUMO AÑO ANTERIOR KW P6', width: '130px', center: true, },
      
        {selector: row => row['precioOptimizacionPotencia'], name: 'PRECIO OPTIMIZACION POTENCIA', sortable: true, width: '130px', center: true, },
        {selector: row => row['precioOptimizacionReactiva'] , name: 'PRECIO OPTIMIZACION REACTIVA', sortable: true, width: '130px', center: true, },
      {selector: row => row['aplicacionBonoSocial']  , name: 'BONO SOCIAL', sortable: true, width: '130px', center: true, },
      
      ]);
      


if(this.state.electricidad) {
    return (
      <>
        <Header title="Listado" />
        <Container className=" mt--7" fluid>
          <SipsFilter onSubmit={this.onSubmit}></SipsFilter>
          <Row>
            <div className="col">
              <Card className="shadow">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text="Cargando sips..."
                >
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="8">
                        <h3 className="mb-0">SIPS</h3>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button
                          onClick={this.export}
                          size="sm"
                          disabled={this.state.paginatedData.items.length === 0}
                        >
                          Exportar a CSV
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <DataTable
                       title="Puntos de Suministros"
                       columns={columns(this.handleButtonClick)}
                       data = {this.state.paginatedData.items}
                       sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                       fixedHeader = {true}
                       onSelectedRowsChange={this.handleChange}
                       keyField = "cups"
                       highlightOnHover
         				      striped
         				      pointerOnHover
                       selectableRowsHighlight
                       customStyles={customStyles}
                       contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                     />
                  <CardFooter className="py-4">
                    {noResults}

                    <nav aria-label="...">
                      <Pagination
                        {...this.state}
                        onPageClick={this.onPageClick}
                      ></Pagination>
                    </nav>
                  </CardFooter>
                </LoadingOverlay>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }


  }
}

export default withApollo(Sips);

//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import React from "react";
import Reflux from "reflux";
import axios from "axios";
import { withApollo } from "react-apollo";
import {
  Card,
  CardHeader,
  Container,
  CardTitle,
  CardBody,
  Form,
  ListGroup,
  ListGroupItem,
  Input,
  Row,
  Table,
  FormText,
  Col,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import LoadingOverlay from "react-loading-overlay";
import RateInformation from "../../components/Cards/RateInformationDocument";
import { VIEW_SIPS } from "../../queries/sips/sips.graphql";
import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/documento.scss";
import DataTable from "react-data-table-component";
import "./styles.css";

const columnsDatosPorcentajes = [
  {
    selector: (row) => row["id"],
    name: "PRODUCTO",
    sortable: false,
    width: "300px",
    style: {
      color: "rgba(0,0,0,.54)",
    },
  },
  {
    selector: (row) => row["importeAhorro"],
    name: "IMPORTE AHORRO PERIODO",
    sortable: false,
    width: "220px",
    center: true,
    format: (row) =>
      `${Number(row.importeAhorro ? row.importeAhorro : 0).toLocaleString(
        "es-ES",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6,
          useGrouping: true,
        }
      )} €`,
  },

  {
    selector: (row) => row["porcentaje"],
    name: "PORCENTAJE AHORRO PERIODO",
    width: "220px",
    sortable: false,
    center: true,
    format: (row) =>
      `${Number(row.porcentaje ? row.porcentaje : 0).toLocaleString("es-ES", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
        useGrouping: true,
      })} %`,
  },
];

const notify = () =>
  toast.success("Correo enviado correctamente", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = () =>
  toast.error("Existe un problema con el servicio de correos", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyEnvio = () =>
  toast.info("Preparando el fichero para su envío por correo", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "12px",
      fontWeight: "bold",
      wordBreak: "break-word",
      paddingLeft: "8px",
      paddingRight: "8px",
      minWidth: "55px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      minWidth: "55px",
      wordBreak: "break-word",
      fontFamily: '"Open Sans", sans-serif;',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 250, 250)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
      fontSize: "12px",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const colores = ["#E2C1A2", "#E57863", "#EDE063", "#DEA841"];

class ComercialProductoPropuestaDocumento extends Reflux.Component {
  public container: any = null;
  public fileInput: any = null;
  public pdfExportComponent: any = null;
  public formActualRef: any = null;
  public formProductoRef: any = null;
  public state: any = {
    logoAppPrint: "",
    logoPropuestaPrint: "",
    imagenTarifa: "",
    id: null,
    // --- Data
    formProducto: {
      serviciosAdicionales: 0,
      alquilerEquipoMedida: 0,

      sumPotencia: 0,
      sumEnergia: 0,
      iePercent: 0,
      impuestoElectrico: 0,
      iva: 0,
      bi: 0,
      total: 0,

      penalizacionPotencia: 0,
      penalizacionEnergia: 0,
    },
    formActual: {
      serviciosAdicionales: 0,
      alquilerEquipoMedida: 0,

      sumPotencia: 0,
      sumEnergia: 0,
      iePercent: 0,
      impuestoElectrico: 0,
      iva: 0,
      bi: 0,
      total: 0,

      penalizacionPotencia: 0,
      penalizacionEnergia: 0,

      precioTP1: 0,
      precioTP2: 0,
      precioTP3: 0,
      precioTP4: 0,
      precioTP5: 0,
      precioTP6: 0,
      precioTEP1: 0,
      precioTEP2: 0,
      precioTEP3: 0,
      precioTEP4: 0,
      precioTEP5: 0,
      precioTEP6: 0,
    },
    totalComparacion: {
      ahorroAnual: 0,
      ahorroPercent: 0,
      ahorroPeriodo: 0,
    },
    isLoading: true,
    sip: {},

    periods: [],
    consumosEnergia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    consumosPotencia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    datosContrato: {
      T_CUPs: {
        P1: 0,
        P2: 0,
        P3: 0,
        P4: 0,
        P5: 0,
        P6: 0,
      },
      T_Titular: {
        NombreTitular: "",
        Apellido1Titular: "",
        NIF: "",
      },
      T_PuntoSuministro: {
        ViaPS: "",
        PortalPS: "",
      },
    },
    itemsTabla: [],
    itemsTablaActual: [],
    producto: {},
    costeOperativo: 0,
    costeOperativoPotencia: 0,
    correoContacto: "",
    nombreFichero: "",
    datafile: "",
    uploadedFileContents: null,
    control: 0,
    datosPropuestaTest: [
      {
        producto: "SIN DATOS",
        itemsPeriodos: [],
      },
    ],
    datosPropuestaGeneral: { Tarifa: "000" },
    productoTarifas: {},
    compania: "2",
    consumos: [{}],
    outputDatosTarifa: [
      {
        id: "SIN DATOS",
        label: "SIN DATOS",
        value: 100,
        color: "hsl(145, 70%, 50%)",
      },
    ],

    potenciaContratadaP1: 0,
    potenciaContratadaP2: 0,
    potenciaContratadaP3: 0,
    potenciaContratadaP4: 0,
    potenciaContratadaP5: 0,
    potenciaContratadaP6: 0,
    consumoEnergiaP1: 0,
    consumoEnergiaP2: 0,
    consumoEnergiaP3: 0,
    consumoEnergiaP4: 0,
    consumoEnergiaP5: 0,
    consumoEnergiaP6: 0,
    potenciaRevisadaP1: 0,
    potenciaRevisadaP2: 0,
    potenciaRevisadaP3: 0,
    potenciaRevisadaP4: 0,
    potenciaRevisadaP5: 0,
    potenciaRevisadaP6: 0,
    dias: 0,
    datosComparativas: [{ id: "SIN DATOS", value: 0 }],
    datosComparativasPorcentajes: [
      { id: "SIN DATOS", importeAhorro: 0, porcentaje: 0 },
    ],
    productoActual: {
      datos: {
        descuento: 0,
        descuentoMonth: 0,
        descuentoPotencia: 0,
        descuentoPotenciaMonth: 0,
        serviciosAdicionalesMonth: 0,
        serviciosAdicionales: 0,
        alquilerEquipoMedidaMonth: 0,
        alquilerEquipoMedida: 0,
        codigoATR: "019",
        numDaysNew: 0,
        numMonthNew: 0,
        fechaDesdeConsumo: "30-10-2022",
        fechaHastaConsumo: "29-11-2022",
        sumPotencia: 0,
        sumEnergia: 0,
        iePercent: 0,
        impuestoElectrico: 0,
        iva: 0,
        ivaPorcentaje: 0,
        bi: 10,
        total: 0,
        penalizacionPotencia: 0,
        penalizacionEnergia: 0,
        datasetActual: [],
        datasetLeyenda: [],
        precioTP1: 0.02,
        precioTP2: 0,
        precioTP3: 0,
        precioTP4: 0,
        precioTP5: 0,
        precioTP6: 0,
        precioTEP1: 0,
        precioTEP2: 0,
        precioTEP3: 0,
        precioTEP4: 0,
        precioTEP5: 0,
        precioTEP6: 0,
        consumoAnualActivaP1: 0,
        consumoAnualActivaP2: 0,
        consumoAnualActivaP3: 0,
        consumoAnualActivaP4: 0,
        consumoAnualActivaP5: 0,
        consumoAnualActivaP6: 0,
        consumoAnualTotalActiva: 0,
      },
      datosAdicionales: {
        serviciosAdicionalesMonth: 0,
        serviciosAdicionales: 0,
        alquilerEquipoMedida: 0,
        codigoATR: "019",
        sumPotencia: 0,
        sumEnergia: 0,
        iePercent: 0,
        impuestoElectrico: 0,
        iva: 0,
        ivaPorcentaje: 0,
        bi: 0,
        total: 0,
        penalizacionPotencia: 0,
        penalizacionEnergia: 0,
      },
    },

    //paginatedData: {items:[{CIF:''}]},
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
    this.container = React.createRef<HTMLDivElement>();
    this.pdfExportComponent = React.createRef<PDFExport>();
    this.fileInput = React.createRef();
  }

  UNSAFE_componentWillReceiveProps() {
    const usuario: any = JSON.parse(
      JSON.stringify(this.state.UserStore["data"])
    );

    const companyNameGlobal = usuario.company;
    const companyIdUser = usuario.companyId;

    const userGroupId = usuario.user_group_id;
    const userId = usuario.id;
    const esComercializadora = usuario.comercializadora;
    const esAgencia = usuario.agencia;
    const esAdFincas = usuario.adFincas;

    if (userGroupId === 1000) {
      this.setState({
        activeUser: true,
      });
    }

    const {
      match: { params },
    } = this.props;

    const valueArray = params.id.split("_");
    let id = valueArray[0];
    let compania = valueArray[1];

    this.setState({
      ...this.state,
      id: id,
      compania: compania,
    });

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      esComercializadora,
      esAgencia,
      esAdFincas,
    });
  }

  componentDidMount() {
    this.formActualRef = React.createRef();
    this.formProductoRef = React.createRef();
    const {
      match: { params },
    } = this.props;

    const user = localStorage.getItem("user");

    let logoApp = "";
    let logoPropuesta = "";
    if (user) {
      const parsed = JSON.parse(user);
      logoApp = parsed.logoApp;
      logoPropuesta = parsed.logoPropuesta;
      this.setState({ user: parsed });
    }
    const valueArray = params.id.split("_");
    let id = valueArray[0];
    let compania = valueArray[1];

    this.setState({
      ...this.state,
      id: id,
      compania: compania,
      logoApp: logoApp,
      logoPropuesta: logoPropuesta,
    });

    this.fetchComparativaLibre(id, compania);

    this.fetchImage(logoApp, logoPropuesta);

    // this.fetchImage(logoApp, logoPropuesta)

    this.setState({
      control: 1,
    });
  }

  exportPDFWithMethod = () => {
    let element = this.container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };

  exportPDFWithComponentclick = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };

  SendEmail = async (event) => {
    event.persist();
    var selectedFile: any = [];
    var evento: any = [];
    if (event !== null && event.target.files !== null) {
      selectedFile = event.target.files;
      evento = event;
      var fileContents: string = "";
    }

    var file: string | ArrayBuffer = "";
    var fileName = "";
    if (selectedFile.length > 0) {
      let fileToLoad = selectedFile[0];
      try {
        const fileContents = await this.readUploadedFileAsText(fileToLoad);
        this.setState({
          uploadedFileContents: fileContents,
        });
      } catch (e) {
        console.warn(e.message);
      }
    }

    var templateParams = {
      to_name: this.state.correoContacto,
      from_name: this.state.producto.nombreComercializadora,
      producto: this.state.producto.nombreOferta,
      cup: this.state.cups,
      correoContacto: this.state.correoContacto,
      nombrePropuesta: this.state.nombreFichero + ".pdf",
      variable_myhirj5: this.state.uploadedFileContents,
    };

    notifyEnvio();

    emailjs
      .send(
        "service_dynargyMail",
        "template_ctuhpkd",
        templateParams,
        "user_9fdpYny52CeShK5yj0B3u"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          notify();
        },
        function (error) {
          console.log("FAILED...", error);
          notifyError();
        }
      );
  };

  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  fetchImage = async (logoApp, logoPropuesta) => {
    const response = await axios(logoApp, { responseType: "arraybuffer" });
    const buffer64App = Buffer.from(response.data, "binary").toString("base64");

    const response2 = await axios(logoPropuesta, {
      responseType: "arraybuffer",
    });
    const buffer64Propuesta = Buffer.from(response2.data, "binary").toString(
      "base64"
    );

    this.setState({
      logoAppPrint: buffer64App,
      logoPropuestaPrint: buffer64Propuesta,
    });
  };

  fetchImageTarifa = async (imagenUrl) => {
    const response = await axios(imagenUrl, { responseType: "arraybuffer" });
    const buffer64App = Buffer.from(response.data, "binary").toString("base64");

    this.setState({
      imagenTarifa: buffer64App,
    });
  };

  fetchSip = async () => {
    await this.props.client
      .query({
        query: VIEW_SIPS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        //Sip
        const sip = result.data.viewSips[0];
        this.setState({
          ...this.state,
          sip: sip,
          isLoading: false,
        });
      });
  };

  fetchComparativaLibre = async (id, compania) => {
    const idOferta = id;
    const idCompania = compania;
    //const IdOferta = this.state.contrato;

    var dataFilters = {
      idOferta: idOferta,
      idCompania: idCompania,
    };

    const valores = JSON.stringify(dataFilters);
    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };
    let direccionFile =
      "https://datapi.psgestion.es/propuesta/detallePropuestasCups?id=" +
      idOferta;

    axios
      .get(direccionFile, { headers, params: { valores } })
      .then((result) => {
        const data = result.data.rows[0];

        let datosPreciosActual = data.T_Propuesta.actual.datosAdicionales;
        let datosPropuesta = data.T_PropuestasAnalizadas;
        let datosPropuestaGeneral = data.T_PropuestaComercial;
        let datosContrato = data;
        let datosConsumos = data.T_Consumos;
        //let test = JSON.stringify(data.datosPropuesta);
        let test2 = data.T_Propuesta.producto;

        var imagenTarifa = "";
        if (datosPropuestaGeneral.Tarifa == "3.0TD") {
          imagenTarifa =
            "https://comercial.psgestion.es/imagen/reparto30TD.png";
        } else {
          imagenTarifa =
            "https://comercial.psgestion.es/imagen/reparto20TD.png";
        }

        this.fetchImageTarifa(imagenTarifa);

        if (datosContrato.OfertaTipo == "P") {
          let formActual = datosContrato.T_Propuesta.actual.datos;

          var itemsTablaActual: any = [];
          var datosComparativas: any = [];
          var datosComparativasPorcentajes: any = [];

          itemsTablaActual[0] = {
            periodo: "P1",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP1,
            precioEnergia: formActual.precioTEP1,
            potenciaP1: datosContrato.T_CUPs.P1,
            precioPotencia: formActual.precioTP1,
          };
          itemsTablaActual[1] = {
            periodo: "P2",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP2,
            precioEnergia: formActual.precioTEP2,
            potenciaP1: datosContrato.T_CUPs.P2,
            precioPotencia: formActual.precioTP2,
          };
          itemsTablaActual[2] = {
            periodo: "P3",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP3,
            precioEnergia: formActual.precioTEP3,
            potenciaP1: datosContrato.T_CUPs.P3,
            precioPotencia: formActual.precioTP3,
          };
          itemsTablaActual[3] = {
            periodo: "P4",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP4,
            precioEnergia: formActual.precioTEP4,
            potenciaP1: datosContrato.T_CUPs.P4,
            precioPotencia: formActual.precioTP4,
          };
          itemsTablaActual[4] = {
            periodo: "P5",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP5,
            precioEnergia: formActual.precioTEP5,
            potenciaP1: datosContrato.T_CUPs.P5,
            precioPotencia: formActual.precioTP5,
          };
          itemsTablaActual[5] = {
            periodo: "P6",
            dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            energiaP1: datosContrato.T_PropuestasAnalizadas[0].energiaActivaP6,
            precioEnergia: formActual.precioTEP6,
            potenciaP1: datosContrato.T_CUPs.P6,
            precioPotencia: formActual.precioTP6,
          };

          let nombreFichero =
            "Propuesta_" + data.cups + new Date().getFullYear().toString();

          for (let i = 0; i < datosPropuesta.length; i++) {
            let value = datosPropuesta[i].ahorro.ahorroPeriodo;
            let valuePorcentaje = datosPropuesta[i].ahorro.ahorroPercent;
            let id = datosPropuesta[i].nombreProducto;
            datosComparativasPorcentajes.push({
              id: id,
              importeAhorro: value,
              porcentaje: valuePorcentaje,
            });
          }

          let consumosPotencia: any = [];

          var monthOldPotencia = "";

          for (let i = 0; i < datosConsumos.length; i++) {
            var newdate = datosConsumos[i].fechaInicioMesConsumo
              .split("-")
              .reverse()
              .join("-");
            let d = new Date(newdate);
            let d2 = new Date(newdate);

            let month = new Intl.DateTimeFormat("es", {
              month: "short",
            }).format(d);
            let year = new Intl.DateTimeFormat("es", {
              year: "numeric",
            }).format(d);
            let day = new Intl.DateTimeFormat("es", {
              day: "numeric",
            }).format(d);

            var newdatedate = new Date();

            if (Number(day) > 20) {
              newdatedate = this.addDays(d2, 5);
              var fechaNueva = newdatedate.toISOString();

              month = new Intl.DateTimeFormat("es", {
                month: "short",
              }).format(newdatedate);
              year = new Intl.DateTimeFormat("es", {
                year: "numeric",
              }).format(newdatedate);
            }

            if (i == 0) {
              monthOldPotencia = month;
            }

            let P1 = datosConsumos[i].potenciaDemandadaEnWP1;
            let P2 = datosConsumos[i].potenciaDemandadaEnWP2;
            let P3 = datosConsumos[i].potenciaDemandadaEnWP3;
            let P4 = datosConsumos[i].potenciaDemandadaEnWP4;
            let P5 = datosConsumos[i].potenciaDemandadaEnWP5;
            let P6 = datosConsumos[i].potenciaDemandadaEnWP6;

            let fecha = `${month} ${year}`;

            if (i > 0) {
              if (month == monthOldPotencia) {
                P1 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP1) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP1);
                P2 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP2) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP2);
                P3 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP3) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP3);
                P4 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP4) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP4);
                P5 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP5) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP5);
                P6 =
                  Number(datosConsumos[i].potenciaDemandadaEnWP6) +
                  Number(datosConsumos[i - 1].potenciaDemandadaEnWP6);
              } else {
                monthOldPotencia = month;
              }
            }

            consumosPotencia = consumosPotencia.filter(
              (consumosPotencia) => consumosPotencia.fecha != fecha
            );

            consumosPotencia.push({
              fecha: fecha,
              P1: P1,
              P1Color: "hsl(22, 70%, 50%)",
              P2: P2,
              P2Color: "hsl(303, 70%, 50%)",
              P3: P3,
              P3Color: "hsl(37, 70%, 50%)",
              P4: P4,
              P4Color: "hsl(338, 70%, 50%)",
              P5: P5,
              P5Color: "hsl(176, 70%, 50%)",
              P6: P6,
              P6Color: "hsl(121, 70%, 50%)",
            });
          }

          let consumosEnergia: any = [];

          var monthOld = "";

          for (let i = 0; i < datosConsumos.length; i++) {
            var newdate = datosConsumos[i].fechaInicioMesConsumo
              .split("-")
              .reverse()
              .join("-");
            let d = new Date(newdate);

            let d2 = new Date(newdate);

            var day = new Intl.DateTimeFormat("es", {
              day: "numeric",
            }).format(d);

            var month = new Intl.DateTimeFormat("es", {
              month: "short",
            }).format(d);

            var year = new Intl.DateTimeFormat("es", {
              year: "numeric",
            }).format(d);

            var newdatedate = new Date();

            if (Number(day) > 20) {
              newdatedate = this.addDays(d2, 5);
              var fechaNueva = newdatedate.toISOString();

              month = new Intl.DateTimeFormat("es", {
                month: "short",
              }).format(newdatedate);
              year = new Intl.DateTimeFormat("es", {
                year: "numeric",
              }).format(newdatedate);
            }

            if (i == 0) {
              monthOld = month;
            }

            let P1 = datosConsumos[i].consumoEnergiaActivaEnWhP1;
            let P2 = datosConsumos[i].consumoEnergiaActivaEnWhP2;
            let P3 = datosConsumos[i].consumoEnergiaActivaEnWhP3;
            let P4 = datosConsumos[i].consumoEnergiaActivaEnWhP4;
            let P5 = datosConsumos[i].consumoEnergiaActivaEnWhP5;
            let P6 = datosConsumos[i].consumoEnergiaActivaEnWhP6;

            if (i > 0) {
              if (month == monthOld) {
                P1 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP1) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP1);
                P2 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP2) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP2);
                P3 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP3) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP3);
                P4 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP4) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP4);
                P5 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP5) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP5);
                P6 =
                  Number(datosConsumos[i].consumoEnergiaActivaEnWhP6) +
                  Number(datosConsumos[i - 1].consumoEnergiaActivaEnWhP6);
              } else {
                monthOld = month;
              }
            }

            let fecha = `${month} ${year}`;

            consumosEnergia = consumosEnergia.filter(
              (consumosEnergia) => consumosEnergia.fecha != fecha
            );

            consumosEnergia.push({
              fecha: fecha,
              P1: P1,
              P1Color: "hsl(22, 70%, 50%)",
              P2: P2,
              P2Color: "hsl(303, 70%, 50%)",
              P3: P3,
              P3Color: "hsl(37, 70%, 50%)",
              P4: P4,
              P4Color: "hsl(338, 70%, 50%)",
              P5: P5,
              P5Color: "hsl(176, 70%, 50%)",
              P6: P6,
              P6Color: "hsl(121, 70%, 50%)",
            });
          }

          var outputDatosTarifa: any = [];
          var auxDatosTarifa: any = data.T_CUPs;
          var sumaTotalEnergia = auxDatosTarifa.consumoAnualTotalActiva;

          let porcentajeP1 =
            (auxDatosTarifa.consumoAnualActivaP1 * 100) / sumaTotalEnergia;
          let porcentajeP2 =
            (auxDatosTarifa.consumoAnualActivaP2 * 100) / sumaTotalEnergia;
          let porcentajeP3 =
            (auxDatosTarifa.consumoAnualActivaP3 * 100) / sumaTotalEnergia;
          let porcentajeP4 =
            (auxDatosTarifa.consumoAnualActivaP4 * 100) / sumaTotalEnergia;
          let porcentajeP5 =
            (auxDatosTarifa.consumoAnualActivaP5 * 100) / sumaTotalEnergia;
          let porcentajeP6 =
            (auxDatosTarifa.consumoAnualActivaP6 * 100) / sumaTotalEnergia;

          outputDatosTarifa[0] = {
            id: "P1",
            label: "P1",
            value: porcentajeP1,
            color: "hsl(32, 70%, 50%)",
          };
          outputDatosTarifa[1] = {
            id: "P2",
            label: "P2",
            value: porcentajeP2,
            color: "hsl(330, 70%, 50%)",
          };
          outputDatosTarifa[2] = {
            id: "P3",
            label: "P3",
            value: porcentajeP3,
            color: "hsl(357, 70%, 50%)",
          };
          outputDatosTarifa[3] = {
            id: "P4",
            label: "P4",
            value: porcentajeP4,
            color: "hsl(127, 70%, 50%)",
          };
          outputDatosTarifa[4] = {
            id: "P5",
            label: "P5",
            value: porcentajeP5,
            color: "hsl(326, 70%, 50%)",
          };
          outputDatosTarifa[5] = {
            id: "P6",
            label: "P6",
            value: porcentajeP6,
            color: "hsl(145, 70%, 50%)",
          };

          for (let i = 0; i < datosPropuesta.length; i++) {
            let value = datosPropuesta[i].total;
            let id = datosPropuesta[i].nombreProducto;
            datosComparativas.push({ id: id, value: value });
          }

          this.setState(
            {
              ...this.state,
              formProducto: datosPropuesta,
              formActual: datosPreciosActual,
              totalComparacion: data.T_Propuesta.producto.totalComparacion,
              cups: data.cups,
              periods: datosPropuesta.periodos,
              itemsTabla: test2.itemsPeriodos,
              itemsTablaActual: itemsTablaActual,
              producto: datosContrato.T_Propuesta.producto.producto,
              costeOperativo: datosPropuesta.costeOperativo,
              costeOperativoPotencia: datosPropuesta.costeOperativoPotencia,
              correoContacto: data.correoContacto,
              nombreFichero: nombreFichero,
              datosContrato: datosContrato,
              datosPropuestaTest: datosPropuesta,
              datosPropuestaGeneral: datosPropuestaGeneral,
              outputDatosTarifa: outputDatosTarifa,
              productoActual: datosContrato.T_Propuesta.actual,
              potenciaContratadaP1:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP1,
              potenciaContratadaP2:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP2,
              potenciaContratadaP3:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP3,
              potenciaContratadaP4:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP4,
              potenciaContratadaP5:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP5,
              potenciaContratadaP6:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP6,
              consumoEnergiaP1:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP1,
              consumoEnergiaP2:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP2,
              consumoEnergiaP3:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP3,
              consumoEnergiaP4:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP4,
              consumoEnergiaP5:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP5,
              consumoEnergiaP6:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP6,
              potenciaRevisadaP1:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP1,
              potenciaRevisadaP2:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP2,
              potenciaRevisadaP3:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP3,
              potenciaRevisadaP4:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP4,
              potenciaRevisadaP5:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP5,
              potenciaRevisadaP6:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP6,
              dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
              datosComparativas: datosComparativas,
              datosComparativasPorcentajes: datosComparativasPorcentajes,
              consumosPotencia: consumosPotencia,
              consumosEnergia: consumosEnergia,
            },
            this.fetchSip
          );
        } else if (datosContrato.OfertaTipo == "MP") {
          let nombreFichero =
            "Propuesta_" + data.cups + new Date().getFullYear().toString();

          this.setState(
            {
              ...this.state,
              formProducto: datosPropuesta.datos,
              totalComparacion: data.T_Propuesta.producto.totalComparacion,
              cups: data.cups,
              periods: datosPropuesta.periodos,
              itemsTabla: datosPropuesta.itemsPeriodos,
              itemsTablaActual: itemsTablaActual,
              producto: datosContrato.T_PropuestaComercial,
              productoTarifas:
                datosContrato.T_PropuestaComercialDatosIndividual,
              costeOperativo: datosContrato.T_PropuestaComercial.GastoOperativo,
              costeOperativoPotencia:
                datosContrato.T_PropuestaComercial.GastoOperativoPotencia,
              correoContacto: data.correoContacto,
              nombreFichero: nombreFichero,
              datosContrato: datosContrato,
              datosPropuestaTest: datosPropuesta,
              productoActual: datosContrato.T_Propuesta.actual,
              potenciaContratadaP1:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP1,
              potenciaContratadaP2:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP2,
              potenciaContratadaP3:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP3,
              potenciaContratadaP4:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP4,
              potenciaContratadaP5:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP5,
              potenciaContratadaP6:
                datosContrato.T_PropuestasAnalizadas[0].potenciaContratoP6,
              consumoEnergiaP1:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP1,
              consumoEnergiaP2:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP2,
              consumoEnergiaP3:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP3,
              consumoEnergiaP4:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP4,
              consumoEnergiaP5:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP5,
              consumoEnergiaP6:
                datosContrato.T_PropuestasAnalizadas[0].energiaActivaP6,
              potenciaRevisadaP1:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP1,
              potenciaRevisadaP2:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP2,
              potenciaRevisadaP3:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP3,
              potenciaRevisadaP4:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP4,
              potenciaRevisadaP5:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP5,
              potenciaRevisadaP6:
                datosContrato.T_PropuestasAnalizadas[0].potenciaP6,
              dias: datosContrato.T_Propuesta.actual.datos.numDaysNew,
            },
            this.fetchSip
          );
        }
      })
      .catch(function (error) {
        console.log("Failure");
        console.log("Valor de error: ", error);
        //notifyErrorDB();
      });
  };

  addDays = (date, days) => {
    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + days);
    return dateCopy;
  };

  tipoContrato = (props) => {
    const tipoTarifa = props.tipoTarifa;
    const totalComparacion = props.totalComparacion;
    const totalComparacionProducto = props.producto;
    const styleFullWith = { width: "100%" };
    if (tipoTarifa === "P") {
      return <></>;
    } else {
      return (
        <Row className="card-deck w-100">
          <Card style={styleFullWith} className="mt-3 mb-3 card-document">
            <CardBody>
              <Row className="">
                <h1 className="col">Resumen costes</h1>
              </Row>
              <Row className="mt-4">
                <Col className="col-auto pt-2 pb-2 ml-6">
                  <h2>Coste energía:</h2>
                </Col>

                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                  <h3 className="mt-2 mb-2 justify-content-center align-self-center">
                    {totalComparacionProducto.costeTotalPropuestaEnergia.toFixed(
                      2
                    )}
                    €
                  </h3>
                </Col>

                <Col className="col-auto pt-2 pb-2 ml-6">
                  <h2>Coste potencia:</h2>
                </Col>

                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                  <h3 className="mt-2 mb-2 justify-content-center align-self-center">
                    {totalComparacionProducto.costeTotalPropuestaPotencia.toFixed(
                      2
                    )}
                    €
                  </h3>
                </Col>

                <Col className="col-auto pt-2 pb-2 ml-6">
                  <h2>Coste anual:</h2>
                </Col>

                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                  <h3 className="mt-2 mb-2 justify-content-center align-self-center">
                    {totalComparacionProducto.costeTotalPropuesta.toFixed(2)}€
                  </h3>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="col-auto pt-2 pb-2 ml-6">
                  <h4>
                    Los costes reflejados en el documento llevan incluidos las
                    diferentes tasas e impuestos asociados al consumo eléctrico
                  </h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      );
    }
  };

  datosPropuesta = (props) => {
    const tipoTarifa = props.tipoTarifa;
    const formProducto = props.formProducto;

    if (tipoTarifa === "P") {
      return <></>;
    } else {
      return <div></div>;
    }
  };

  datosPropuestaActual = (props) => {
    const tipoTarifa = props.tipoTarifa;
    const formActual = props.formActual;

    if (tipoTarifa === "P") {
      return (
        <React.Fragment>
          <Row className="panel-data-resolve pt-5 pb-1">
            <Col lg="auto">
              <h4 className="">Término Potencia</h4>
            </Col>

            <Col lg="auto">
              <div className="">{formActual.sumPotencia}€</div>
            </Col>

            <Col lg="auto">
              <h4>Término Energía</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.sumEnergia}€</div>
            </Col>

            <Col lg="auto">
              <h4 className="">Penalización Potencia</h4>
            </Col>

            <Col lg="auto">
              <div className="">{formActual.penalizacionPotencia}€</div>
            </Col>
            <Col lg="auto">
              <h4>Penalización Energía</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.penalizacionEnergia}€</div>
            </Col>
          </Row>

          <Row className="panel-data-resolve pb-1 pt-1">
            <Col lg="auto">
              <h4 className="">Alquiler contador</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.alquilerEquipoMedida}€</div>
            </Col>

            <Col lg="auto">
              <h4 className="">Servicios Adicionales</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.serviciosAdicionales}€</div>
            </Col>
          </Row>
          <Row className="panel-data-resolve pb-4 pt-1">
            <Col lg="auto">
              <h4 className="">I.E. 5,11%</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.iePercent}€</div>
            </Col>

            <Col lg="auto">
              <h4 className="">IVA 21%</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.iva}€</div>
            </Col>
            <Col lg="auto">
              <h4 className="">Base imponible</h4>
            </Col>
            <Col lg="auto">
              <div className="">{formActual.bi}€</div>
            </Col>
            <Col lg="auto" className="bg-total">
              <h2>Total</h2>
            </Col>
            <Col lg="auto">
              <div>
                <h2>{formActual.total}€</h2>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  };

  graficoAhorro = (props) => {
    const tipoTarifa = props.tipoTarifa;
    const dataAhorro = props.data;
    const options = props.options;
    const datosContrato = props.datosContrato;

    if (tipoTarifa === "P") {
      return (
        <div>
          <h2 className="title">Resumen costes</h2>
          <div>
            <Bar data={dataAhorro} width={80} height={40} options={options} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className="ml-5 mb-3">
            Esta propuesta ha sido realizada por el representante:<br></br>
          </p>

          <ListGroup>
            <ListGroupItem className="list-item-documento">
              <strong>Nombre:</strong>{" "}
              {datosContrato.T_Representante.nombreRepresentante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>Apellido:</strong>{" "}
              {datosContrato.T_Representante.apellido1Representante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>NIF/CIF:</strong>{" "}
              {datosContrato.T_Representante.NIFRepresentante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>Cargo:</strong>{" "}
              {datosContrato.T_Representante.CargoRepresentante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>Correo Elec.:</strong>{" "}
              {datosContrato.T_Representante.correoRepresentante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>Teléfono:</strong>{" "}
              {datosContrato.T_Representante.telefonoRepresentante}
            </ListGroupItem>
            <ListGroupItem className="list-item-documento">
              <strong>Fecha Propuesta:</strong> {datosContrato.fechaPropuesta}
            </ListGroupItem>
          </ListGroup>
        </div>
      );
    }
  };

  render() {
    const isLoading = this.state.isLoading;
    const sip = this.state.sip;
    //var itemsTablapro = this.state.datosPropuestaTest.itemsPeriodos;
    const totalComparacion = this.state.totalComparacion;
    const producto = this.state.formProducto;
    const productoTarifas = this.state.productoTarifas;
    const formActual = this.state.formActual;
    const formProducto = this.state.formProducto;
    const periodos = this.state.periods;
    const cups = this.state.cups;
    const styleFullWith = { width: "100%" };
    const correoContacto = this.state.correoContacto;
    const datosContrato = this.state.datosContrato;

    const nombreFichero = this.state.nombreFichero;
    //              fileName={`Report for ${cups} ${new Date().getFullYear()}`}

    //PLOTS DATA

    const logo = this.state.logoPropuestaPrint
      ? this.state.logoPropuestaPrint
      : this.state.logoAppPrint;

      const imagenTarifa = this.state.imagenTarifa?this.state.imagenTarifa:""


    let ofertaTipo = "";

    if (this.state.datosContrato === undefined) {
      ofertaTipo = "";
    } else {
      ofertaTipo = this.state.datosContrato.OfertaTipo;
    }

    var data: any = {};
    var dataAhorro: any = {};
    let options = {};

    console.log("VALOR DE THIS STATE: ", this.state);

    if (ofertaTipo === "P") {
      var datasetProducto = [0];
      datasetProducto.length = 0;
      datasetProducto[datasetProducto.length] = formProducto.sumEnergia;
      datasetProducto[datasetProducto.length] = formProducto.sumPotencia;
      datasetProducto[datasetProducto.length] =
        formProducto.serviciosAdicionales;
      datasetProducto[datasetProducto.length] =
        formProducto.alquilerEquipoMedida;
      datasetProducto[datasetProducto.length] =
        formProducto.sumEnergia +
        formProducto.sumPotencia +
        formProducto.serviciosAdicionales +
        formProducto.alquilerEquipoMedida;
      var datasetActual = [0];
      datasetActual.length = 0;
      datasetActual[datasetActual.length] = formActual.sumEnergia;
      datasetActual[datasetActual.length] = formActual.sumPotencia;
      datasetActual[datasetActual.length] = formActual.serviciosAdicionales;
      datasetActual[datasetActual.length] = formActual.alquilerEquipoMedida;
      var datasetAhorro = this.state.totalComparacion.datosAhorro;
      datasetActual[datasetActual.length] =
        formActual.sumEnergia +
        formActual.sumPotencia +
        formActual.serviciosAdicionales +
        formActual.alquilerEquipoMedida;

      data = {
        labels: [
          "Coste Energía",
          "Costes Potencia",
          "Costes Servicios",
          "Costes Equipos",
          "Total",
        ],
        datasets: [
          {
            label: "Resumen Producto Ofertado",
            data: datasetProducto,
            backgroundColor: "rgb(255, 99, 132)",
          },
          {
            label: "Resumen Factura Actual",
            data: datasetActual,
            backgroundColor: "rgb(54, 162, 235)",
          },
        ],
      };

      dataAhorro = {
        labels: ["Total Coste Actual", "Total Propuesta", "Ahorro"],
        datasets: [
          {
            label: "Resumen de coste",
            data: datasetAhorro,
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      options = {
        maintainAspectRatio: true,
        legend: {
          display: true,
          labels: {
            fontColor: "rgb(255, 99, 132)",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    } else if (ofertaTipo === "MP") {
      var datasetProducto = [0];
      datasetProducto.length = 0;
      datasetProducto[datasetProducto.length] =
        datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuestaEnergia;
      datasetProducto[datasetProducto.length] =
        datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuestaPotencia;
      datasetProducto[datasetProducto.length] = 0;
      datasetProducto[datasetProducto.length] = 0;
      datasetProducto[datasetProducto.length] =
        datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuesta;

      data = {
        labels: [
          "Coste Energía",
          "Costes Potencia",
          "Costes Servicios",
          "Costes Equipos",
          "Total",
        ],
        datasets: [
          {
            label: "Resumen Producto Ofertado",
            data: datasetProducto,
            backgroundColor: "rgb(255, 99, 132)",
          },
        ],
      };

      dataAhorro = {
        labels: ["Total Coste Actual", "Total Propuesta", "Ahorro"],
        datasets: [
          {
            label: "Resumen de coste",
            data: datasetAhorro,
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      options = {
        maintainAspectRatio: true,
        legend: {
          display: true,
          labels: {
            fontColor: "rgb(255, 99, 132)",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    }

    var nombreproducto = producto.nombreProducto;

    let ofertaTipoContrato = "P";
    if (this.state.datosContrato === undefined) {
      ofertaTipo = "P";
    } else {
      ofertaTipo = this.state.datosContrato.OfertaTipo;
    }
    const compañialogo = this.state.compania ? this.state.compania : 2;

    var direccionPS =
      this.state.datosContrato.T_PuntoSuministro.ViaPS +
      " " +
      this.state.datosContrato.T_PuntoSuministro.PortalPS;

    if (!this.state.datosContrato.T_PuntoSuministro.ViaPS) {
      direccionPS = this.state.sip.direccion_ps;
    }

    return (
      <>
        <GetUserData></GetUserData>

        <Row className="card-deck mb-3">
          <Card id="supplyInformation" className={this.props.cardClass}>
            <CardBody className="p-4">
              <Row>
                <Col md="2">
                  <div className="col">
                    <CardBody>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        id="imprimirProptooltip"
                        onClick={this.exportPDFWithComponentclick}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-file-pdf fa-3x" />
                        </span>
                        <span className="btn-inner--text">Exportar PDF</span>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="imprimirProptooltip"
                        >
                          EXPORTAR PDF
                        </UncontrolledTooltip>
                      </Button>
                    </CardBody>
                  </div>
                </Col>
                <Col md="6">
                  <div className="col">
                    <CardBody>
                      <Form>
                        <div className="custom-file">
                          <input
                            className="custom-file-input lg"
                            id="customFileLang"
                            lang="es"
                            type="file"
                            ref={this.fileInput}
                            onChange={this.SendEmail}
                          />
                          <FormText color="muted">
                            Seleccionar propuesta para su envío por correo
                            electrónico
                          </FormText>
                        </div>
                      </Form>
                    </CardBody>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <PDFExport
          ref={this.pdfExportComponent}
          paperSize="A4"
          margin="1cm"
          scale={0.4}
          fileName={`${nombreFichero}`}
          author="PsGestion"
          keepTogether="Row"
          forcePageBreak=".page-break"
        >
          <div
            id="document-page"
            ref={this.container}
            className="border p-4 mb-5 shadow"
          >
            <LoadingOverlay active={isLoading} spinner text="Cargando...">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="4">
                  <img
                    alt=""
                    className="img-fluid"
                    src={"data:image/jpeg;base64," + logo}
                  />
                </Col>
              </Row>
              <h1 className="mt-5 ml-5 mr-5 mb-4">
                Documento resumen propuesta. {sip.nombreCompletoTitular}
              </h1>

              <p className="ml-5 mb-3">
                Hola!<br></br>
                Gracias por confiar en nuestro servicio. A continuación le
                mostramos los datos más relevantes de la propuesta realizada por
                uno de nuestros agentes.
              </p>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="mt-3 mb-3 p-3 card-document">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <CardTitle className="info-title">
                          <h2>Información del suministro</h2>
                        </CardTitle>
                        <ListGroup>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>CUPS:</strong> {sip.cups}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Titular:</strong>{" "}
                            {this.state.datosContrato.T_Titular.NombreTitular +
                              " " +
                              this.state.datosContrato.T_Titular
                                .Apellido1Titular}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>NIF/CIF:</strong>{" "}
                            {this.state.datosContrato.T_Titular.NIF}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Direccion:</strong> {direccionPS}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Provincia:</strong> {sip.nombreProvinciaPS}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Localidad:</strong> {sip.nombreMunicipioPS}
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Consumo Anual:</strong> {sip.kWhAnual} Kw
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-item-documento"
                            style={{ fontSize: "1.0rem" }}
                          >
                            <strong>Tarifa actual:</strong> {sip.tarifaATR}
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                      <Col md="6">
                        <CardTitle className="info-title">
                          <h2>Consumos punto suministro</h2>
                        </CardTitle>
                        <RateInformation
                          isLoading={isLoading}
                          sip={sip}
                          cardClass="col-6 card-document"
                        ></RateInformation>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="card-deck" style={styleFullWith}>
                <Card className="mt-3 mb-3 p-3 card-document">
                  <img
                    alt=""
                    className="img-fluid"
                    style={{
                      height: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    src={"data:image/jpeg;base64," + imagenTarifa}
                    width="auto"
                  />
                </Card>
              </Row>
              <CardTitle className="info-title">
                <h2>Consumo segmentado por periodos</h2>
              </CardTitle>
              <Row className="card-deck" style={styleFullWith}>
                <Card className="card-stats" style={{ height: 450 }}>
                  <ResponsivePie
                    data={this.state.outputDatosTarifa}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "P1",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "P3",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "P5",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "P2",
                        },
                        id: "lines",
                      },
                      {
                        match: {
                          id: "P4",
                        },
                        id: "lines",
                      },
                      {
                        match: {
                          id: "P6",
                        },
                        id: "lines",
                      },
                    ]}
                    valueFormat={(value) =>
                      `${Number(value).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })} %`
                    }
                    legends={[
                      {
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: "#999",
                        itemDirection: "left-to-right",
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: "circle",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemTextColor: "#000",
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </Card>
              </Row>

              <h3 className="page-break"></h3>

              {/*             <Row className="card-deck" style={styleFullWith}>
                            { ofertaTipo == 'P' &&
                                this.state.datosContrato.T_Propuesta.actual.map(producto =>
                                    <Row className="card-deck" style={styleFullWith}>
                                        <Col sm="6">
                                        <Card className="mt-3 mb-3 p-3 card-document">
                                            <h2 className='title'>PRODUCTO ACTUAL</h2>
                                            <h2 className='title'> POTENCIA</h2>
                                            <Row>
                                                <Col>
                                                <h4 className="">P1</h4>
                                                </Col>
                                                <Col> {producto.datos.precioTP1} € </Col>
                                                <Col> {this.state.cups.potenciasContratadasEnWP1.potenciaP1} € </Col>
                                                <Col> {producto.datos.numDaysNew} € </Col>
                                                <Col> {(this.state.cups.potenciasContratadasEnWP1.potenciaP1 * producto.datos.precioTP1)*producto.datos.numDaysNew} € </Col>
                                            </Row>
                                         </Card>
                                         </Col>
                                    </Row>
                                )
                            }
                        </Row> */}

              <CardTitle className="info-title">
                <h2>Historial de consumos. ENERGIA</h2>
              </CardTitle>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="card-stats" style={{ height: 400 }}>
                  <ResponsiveBar
                    data={this.state.consumosEnergia}
                    keys={["P1", "P2", "P3", "P4", "P5", "P6"]}
                    indexBy="fecha"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={{ scheme: "nivo" }}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "fechas",
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "consumo KWh",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    role="application"
                  />
                </Card>
              </Row>

              <CardTitle className="info-title">
                <h2>Historial de consumos. POTENCIA</h2>
              </CardTitle>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="card-stats" style={{ height: 400 }}>
                  <ResponsiveBar
                    data={this.state.consumosPotencia}
                    keys={["P1", "P2", "P3", "P4", "P5", "P6"]}
                    indexBy="fecha"
                    groupMode="grouped"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={{ scheme: "nivo" }}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "fechas",
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "consumo KWh",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    role="application"
                  />
                </Card>
              </Row>

              <CardTitle className="info-title">
                <h2>Facturación comercializadora actual</h2>
              </CardTitle>

              <Row className="card-deck" style={styleFullWith}>
                <Col sm="6">
                  <Card className="mt-3 mb-3 p-3 card-document">
                    <h3 className="title">COMERCIALIZADORA ACTUAL</h3>
                    <h3 className="title"> POTENCIA</h3>
                    <Row>
                      <Col>
                        <h4 className="">P1</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP1.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P1}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P1 *
                            this.state.productoActual.datos.precioTP1 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P2</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP2.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P2}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P2 *
                            this.state.productoActual.datos.precioTP2 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P3</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP3.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P3}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P3 *
                            this.state.productoActual.datos.precioTP3 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P4</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP4.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P4}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P4 *
                            this.state.productoActual.datos.precioTP4 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P5</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP5.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P5}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P5 *
                            this.state.productoActual.datos.precioTP5 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P6</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTP6.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.datosContrato.T_CUPs.P6}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.dias} días
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.datosContrato.T_CUPs.P6 *
                            this.state.productoActual.datos.precioTP6 *
                            this.state.dias
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">total</h4>
                      </Col>
                      <Col>
                        <h4 className="">0</h4>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="mt-3 mb-3 p-3 card-document">
                    <h3 className="title">COMERCIALIZADORA ACTUAL</h3>
                    <h3 className="title"> ENERGIA</h3>
                    <Row>
                      <Col>
                        <h4 className="">P1</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP1.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP1}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP1 *
                            this.state.productoActual.datos.precioTEP1
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P2</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP2.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP2}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP2 *
                            this.state.productoActual.datos.precioTEP2
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P3</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP3.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP3}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP3 *
                            this.state.productoActual.datos.precioTEP3
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P4</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP4.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP4}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP4 *
                            this.state.productoActual.datos.precioTEP4
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P5</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP5.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP5}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP5 *
                            this.state.productoActual.datos.precioTEP5
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">P6</h4>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.productoActual.datos.precioTEP6.toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }
                          )}{" "}
                          €{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {this.state.consumoEnergiaP6}{" "}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                          {" "}
                          {(
                            this.state.consumoEnergiaP6 *
                            this.state.productoActual.datos.precioTEP6
                          ).toFixed(2)}{" "}
                          €{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="">total</h4>
                      </Col>
                      <Col>
                        <h4 className="">0</h4>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {/* <Container className="pt-3">
                <Row className="panel-data-resolve pt-2 pb-1">
                    <Col lg="auto">
                        <h4 className="">Término Potencia</h4>

                    </Col>

                    <Col lg="auto">
                        <div className="">{producto.sumPotencia}€</div>
                    </Col>

                    <Col lg="auto">
                        <h4>Término Energía</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.sumEnergia}€</div>
                    </Col>

                    <Col lg="auto">

                        <h4 className="">Penalización Potencia</h4>
                    </Col>

                    <Col lg="auto">
                        <div className="">{producto.penalizacionPotencia}€</div>
                    </Col>
                    <Col lg="auto">
                        <h4>Penalización Energía</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.penalizacionEnergia}€</div>
                    </Col>

                </Row>


                <Row lg="auto" className="panel-data-resolve pb-1 pt-1">

                    <Col lg="auto">
                        <h4 className="">Alquiler contador</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.alquilerEquipoMedida}€</div>
                    </Col>

                    <Col lg="auto">
                        <h4 className="">Servicios Adicionales</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.serviciosAdicionales}€</div>
                    </Col>
                </Row>

                <Row lg="auto" className="panel-data-resolve pb-0 pt-1">

                    <Col lg="auto">
                        <h4 className="">I.E. 5,11%</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.iePercent}€</div>
                    </Col>

                    <Col lg="auto">
                        <h4 className="">IVA 21%</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.iva}€</div>
                    </Col>
                    <Col lg="auto">
                        <h4 className="">Base imponible</h4>
                    </Col>
                    <Col lg="auto">
                        <div className="">{producto.bi}€</div>
                    </Col>
                    <Col lg="auto" className="bg-total">
                        <h2>Total</h2>
                    </Col>
                    <Col lg="auto">
                        <div><h2>{producto.total}€</h2></div>
                    </Col>

                </Row>
        </Container>*/}
                <Container className="pt-3">
                  <this.datosPropuestaActual
                    tipoTarifa={ofertaTipo}
                    formActual={formActual}
                  />
                </Container>
              </Row>

              <h3 className="page-break"></h3>

              <CardTitle className="info-title">
                <h2>Propuestas. ESTUDIO DETALLES</h2>
              </CardTitle>

              {this.state.datosPropuestaTest.map((producto, index) => (
                <Row className="card-deck" style={styleFullWith}>
                  <Col sm="6">
                    <Card className="mt-3 mb-3 p-3 card-document">
                      <h3
                        style={{ backgroundColor: colores[index] }}
                        className="title"
                      >
                        {producto.nombreProducto}
                      </h3>
                      <h3 className="title"> POTENCIA</h3>
                      <Row>
                        <Col>
                          <h4 className="">P1</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP1?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP1}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP1 *
                              producto.precioPotenciaP1 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P2</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP2?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP2}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP2 *
                              producto.precioPotenciaP2 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €
                          </p>{" "}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P3</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP3?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP3}{" "}
                          </p>{" "}
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP3 *
                              producto.precioPotenciaP3 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P4</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP4?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP4}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP4 *
                              producto.precioPotenciaP4 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P5</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP5?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP5}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP5 *
                              producto.precioPotenciaP5 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P6</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.86rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioPotenciaP6?.toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6,
                                useGrouping: true,
                              }
                            ) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.potenciaP6}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.numDaysNew} días
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.potenciaP6 *
                              producto.precioPotenciaP6 *
                              producto.numDaysNew
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">total</h4>
                        </Col>
                        <Col>
                          <h4 className="">0</h4>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="mt-3 mb-3 p-3 card-document">
                      <h3
                        style={{ backgroundColor: colores[index] }}
                        className="title"
                      >
                        {producto.nombreProducto}
                      </h3>
                      <h3 className="title"> ENERGIA</h3>
                      <Row>
                        <Col>
                          <h4 className="">P1</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP1?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP1}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP1 *
                              producto.energiaActivaP1
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P2</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP2?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP2}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP2 *
                              producto.energiaActivaP2
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P3</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP3?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP3}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP3 *
                              producto.energiaActivaP3
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P4</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP4?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP4}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP4 *
                              producto.energiaActivaP4
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P5</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP5?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP5}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP5 *
                              producto.energiaActivaP5
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">P6</h4>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.precioEnergiaP6?.toLocaleString("es-ES", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                              useGrouping: true,
                            }) || 0}{" "}
                            €{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {producto.energiaActivaP6}{" "}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <p style={{ fontSize: "0.9rem", marginBottom: "0" }}>
                            {" "}
                            {(
                              producto.precioEnergiaP6 *
                              producto.energiaActivaP6
                            ).toFixed(2)}{" "}
                            €{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h4 className="">total</h4>
                        </Col>
                        <Col>
                          <h4 className="">0</h4>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Container className="pt-3">
                    <Row className="panel-data-resolve pt-2 pb-1">
                      <Col lg="auto">
                        <h4 className="">Término Potencia</h4>
                      </Col>

                      <Col lg="auto">
                        <div className="">{producto.sumPotencia}€</div>
                      </Col>

                      <Col lg="auto">
                        <h4>Término Energía</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.sumEnergia}€</div>
                      </Col>

                      <Col lg="auto">
                        <h4 className="">Penalización Potencia</h4>
                      </Col>

                      <Col lg="auto">
                        <div className="">{producto.penalizacionPotencia}€</div>
                      </Col>
                      <Col lg="auto">
                        <h4>Penalización Energía</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.penalizacionEnergia}€</div>
                      </Col>
                    </Row>

                    <Row lg="auto" className="panel-data-resolve pb-1 pt-1">
                      <Col lg="auto">
                        <h4 className="">Alquiler contador</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.alquilerEquipoMedida}€</div>
                      </Col>

                      <Col lg="auto">
                        <h4 className="">Servicios Adicionales</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.serviciosAdicionales}€</div>
                      </Col>
                    </Row>

                    <Row lg="auto" className="panel-data-resolve pb-0 pt-1">
                      <Col lg="auto">
                        <h4 className="">I.E. 5,11%</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.iePercent}€</div>
                      </Col>

                      <Col lg="auto">
                        <h4 className="">IVA 21%</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.iva}€</div>
                      </Col>
                      <Col lg="auto">
                        <h4 className="">Base imponible</h4>
                      </Col>
                      <Col lg="auto">
                        <div className="">{producto.bi}€</div>
                      </Col>
                      <Col lg="auto" className="bg-total">
                        <h2>Total</h2>
                      </Col>
                      <Col lg="auto">
                        <div>
                          <h2>{producto.total}€</h2>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Row>
              ))}

              <h3 className="page-break"></h3>
              <CardTitle className="info-title">
                <h2>Propuestas. ESTUDIO RESUMEN COSTES</h2>
              </CardTitle>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="card-stats" style={{ height: 400 }}>
                  <ResponsiveBar
                    layout="horizontal"
                    margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                    data={this.state.datosComparativas}
                    indexBy="id"
                    keys={["value"]}
                    colors={{ scheme: "nivo" }}
                    colorBy="indexValue"
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 2.6]],
                    }}
                    enableGridX
                    enableGridY={false}
                    axisTop={{
                      format: "~s",
                    }}
                    axisBottom={null}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    padding={0.3}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.4]],
                    }}
                    valueFormat={(value) =>
                      `${Number(value).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })} €`
                    }
                  />
                </Card>
              </Row>

              <CardTitle className="info-title">
                <h2>Propuestas. ESTUDIO RESUMEN AHORROS</h2>
              </CardTitle>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="mt-3 mb-3 card-billing-documento card-document">
                  <CardBody>
                    <DataTable
                      columns={columnsDatosPorcentajes}
                      data={this.state.datosComparativasPorcentajes}
                      fixedHeader={true}
                      striped
                      pointerOnHover
                      customStyles={customStyles}
                      noDataComponent={"Sin datos para mostrar"}
                    />
                  </CardBody>
                </Card>
              </Row>

              <Row className="card-deck" style={styleFullWith}>
                <Card className="card-stats" style={{ height: 300 }}>
                  <ResponsiveBar
                    layout="horizontal"
                    margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                    data={this.state.datosComparativasPorcentajes}
                    indexBy="id"
                    keys={["importeAhorro"]}
                    colors={{ scheme: "nivo" }}
                    colorBy="indexValue"
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 2.6]],
                    }}
                    enableGridX
                    enableGridY={false}
                    axisTop={{
                      format: "~s",
                    }}
                    axisBottom={null}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    padding={0.3}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.4]],
                    }}
                    valueFormat={(value) =>
                      `${Number(value).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })} €`
                    }
                  />
                </Card>
              </Row>

              <Row className="m-3 border-top">
                <p>
                  <small>
                    Los precios de los productos que se muestran en este
                    documento son vigentes para los próximos cinco (5) días a
                    partir de la fecha de la propuesta. Se han calculado en base
                    a los datos existentes de consumos suministrados por las
                    distribuidoras eléctricas, las comercializadoras y el
                    cliente, si no se especifica de otro modo. Estos cálculos
                    son orientativos y pueden sufrir variaciones ajenas al
                    agente.
                    <br></br>
                    Las opciones de pago y de cancelación dependen de las
                    condiciones particulares de la comercializadora elegída.
                    Comprueba sus condiciones antes de reservar.
                    <br></br>
                    Te recordamos que estos e-mails son automáticos: no los
                    respondas, por favor.
                  </small>
                </p>
              </Row>
            </LoadingOverlay>
          </div>
        </PDFExport>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default withApollo(ComercialProductoPropuestaDocumento);

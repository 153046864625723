import Environment from '../../constants/environment';
import ContratoRepresentanteFreeModal from "./ContratoRepresentanteModal";


import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
//import DatePicker from "react-datepicker";
import { validateSpanishId, spainIdType } from 'spain-id'
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import {
    VIEW_SIPS_CONSUMOS_TOTAL,
    LIST_SIPS_CONSUMOS,
} from "../../queries/sips-consumo/sip-consumo";
import { VIEW_CONTRATO_FILTER, VIEW_CONTRATO, UPDATE_VIEW_CONTRATO, DATA_REPRE_CONTRATO, UPDATE_REPRE_CONTRATO } from "../../queries/sips-comparativa/contratacion";


import SipsOption from "../../components/Sips/SipsOption";
import {
    LIST_ESTADOS_CONTRATOS
} from "../../components/Sips/SipsOption.graphql";
import moment from "moment";

import ReactBSAlert from "react-bootstrap-sweetalert";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import { parse, isValid, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { saveAs } from 'file-saver';

import path from 'path';



var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];



const notify = () => toast.success(
    "Contrato actualizado correctamente",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
const notifyError = () => toast.error(
    "Solo puedes escoger un producto",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });






const uploadRef = React.createRef<Upload>();

const hint = <span>My custom hint</span>;
const note = <span>My custom note</span>;
const sortIcon = <ArrowDownward />;

const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);

const columnsFilesLecturas = memoize(clickHandler => [
    {
        selector: row => row.nombreFicheroOrigen.slice(-65), name: 'NOMBRE DEL FICHERO', sortable: true, width: '210px', style: {
            color: 'rgba(0,0,0,.54)'
        }
    },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '240px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.importetotal, name: 'IMPORTE FACTURA TOTAL', sortable: true, width: '210px', center: true },
    { selector: row => row.nombre, name: 'NOMBRE', sortable: true, width: '150px', center: true, format: (row => capitalize(row.nombre)) },
    { selector: row => row.apellido1, name: 'APELLIDO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.apellido1)) },
    { selector: row => row.numdocumento, name: 'NIF/CIF', sortable: true, width: '150px', center: true },
    { selector: row => row.numfactura, name: 'FACTURA', sortable: true, width: '150px', center: true },
    { selector: row => row.numdocumento, name: 'NIF/CIF', sortable: true, width: '150px', center: true },
    { selector: row => row.fecinicioperiodo, name: 'INICIO', sortable: true, width: '150px', center: true, format: (row => moment(row.fecinicioperiodo, 'YYYY-MM-DD', true).isValid() ? row.fecinicioperiodo : "") },
    { selector: row => row.fecfinperiodo, name: 'FINAL', sortable: true, width: '150px', center: true, format: (row => moment(row.fecfinperiodo, 'YYYY-MM-DD', true).isValid() ? row.fecfinperiodo : "") }

]);

const columnsFilesFacturas = memoize(clickHandler => [
    {
        selector: row => row.nombreFicheroOrigen.slice(-65), name: 'NOMBRE DEL FICHERO', sortable: true, width: '210px', style: {
            color: 'rgba(0,0,0,.54)'
        }
    },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '240px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.importetotal, name: 'IMPORTE FACTURA TOTAL', sortable: true, width: '210px', center: true },
    { selector: row => row.nombre, name: 'NOMBRE', sortable: true, width: '150px', center: true, format: (row => capitalize(row.nombre)) },
    { selector: row => row.apellido1, name: 'APELLIDO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.apellido1)) },
    { selector: row => row.numdocumento, name: 'NIF/CIF', sortable: true, width: '150px', center: true },
    { selector: row => row.numfactura, name: 'FACTURA', sortable: true, width: '150px', center: true },
    { selector: row => row.numdocumento, name: 'NIF/CIF', sortable: true, width: '150px', center: true },
    { selector: row => row.fecinicioperiodo, name: 'INICIO', sortable: true, width: '150px', center: true, format: (row => moment(row.fecinicioperiodo, 'YYYY-MM-DD', true).isValid() ? row.fecinicioperiodo : "") },
    { selector: row => row.fecfinperiodo, name: 'FINAL', sortable: true, width: '150px', center: true, format: (row => moment(row.fecfinperiodo, 'YYYY-MM-DD', true).isValid() ? row.fecfinperiodo : "") }

]);


const columnsDatosContrato = [
    /*  {
      // eslint-disable-next-line react/button-has-type
      cell: () => <button onClick={clickHandler}>Aprobar</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      name: 'APROBAR'
    },*/
    /*{cell: row =>  <Link target="_blank" data-toggle="modal" to={{pathname : "/print/sip-comparative-print/" + row.id}}>{row.nombreOferta}</Link>
    ,
    ignoreRowClick: true,
      allowOverflow: true,
      name: 'NOMBRE DEL CONTRATO',
      minWidth: '55px',
      width: '210px'

    },*/
    { selector: row => row.idContrato, name: 'ID CONTRATO', sortable: true, width: '150px', format: (row => capitalize(row.idContrato)) },
    {
        // eslint-disable-next-line react/button-has-type
    },
    { selector: row => row.T_Titular.Apellido1Titular, sortable: true, width: '240px', center: true, format: (row => capitalize(row.T_Titular.Apellido1Titular) + " , " + capitalize(row.T_Titular.NombreTitular)) },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '210px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.ofertaTipo, name: 'TIPO CONTRATO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.idTipoContrato)) },
    { selector: row => row.nombreProducto, name: 'PRODUCTO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Producto)) },
    {
        selector: row => row.esRenovacion, name: 'RENOVACION', sortable: true, width: '140px', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esRenovacion ? "RENOVACION" : "NUEVO")
    },
    {
        selector: row => row.esBajaContrato, name: 'BAJA', sortable: true, width: '140x', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esBajaContrato ? "BAJA" : "ACTIVO")
    },
    { selector: row => row.empleado, name: 'AGENTE COMERCIAL', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_DatosAgenteComercial.nombreUsuario)) },
    { selector: row => row.ofertaTipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.ofertaTipo)) },
    { selector: row => row.ofertaTipoId, name: 'SEQ', sortable: true, width: '100px', center: true },
];

const columnsEstados = memoize(clickHandler => [
    { selector: row => row.accion, name: 'ESTADO', sortable: true, width: '140px', center: true,  style: {
        color: 'rgba(0,0,0,.54)'
    },format: (row => capitalize(row.accion)) },
    { selector: row => row.fecha, name: 'FECHA', sortable: true, width: '120px', center: true, format: (row => moment(row.fecha, 'YYYY-MM-DD', true).isValid() ? moment(row.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")},
    { selector: row => row.hora, name: 'HORA', sortable: true, width: '120px', center: true, format: (row => capitalize(row.hora)) },
    { selector: row => row.observacion, name: 'OBSERVACIONES', sortable: true, width: '250px', center: true, format: (row => capitalize(row.observacion)) },
    { selector: row => row.nombreUsuario, name: 'AGENTE', sortable: true, width: '160px', center: true, format: (row => capitalize(row.nombreUsuario)) }
    ]);

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

/*
const CustomInput = forwardRef((inputProps, ref) => {
const { value, onChange } = inputProps
return (
<div>
<input
value={value}
onChange={onChange}
placeholder='Pick a date'
ref={ref}
id="fechaActivacion3"
placeholder="DD/MM/YYYY"
/>
</div>
)
})
*/


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class Contrato extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            cupsSearch: "",
            contratoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        page: 1,
        limit: 10,
        sipsConsumosTotal: [],
        sipsConsumos: [],
        sipsDetail: [],
        contratoDetailTotal: [],
        contratoDetail: {
            fechaActivacion: "",
            fechaFirmaContrato: '',
            fechaVencimientoContrato: '',
            CIF: '',
            desMunicipioTitular: '',
            tipoViaTitular: '',
            viaTitular: '',
            numFincaTitular: '',
            portalTitular: '',
            escaleraTitular: '',
            pisoTitular: '',
            puertaTitular: '',
            cups: '',
            idContrato: '',
            idTarifaPeaje: '',
            nombreProducto: '',
            precioTEP1: 0,
            precioTEP3: 0,
            nombreTitular: '',
            apellido1Titular: '',
            apellido2Titular: '',
            desProvinciaTitular: '',
            iban: '',
            idTipoContrato: '',
            idRepresentante: '',
            datosContrato: '',
            CNAE: '',
            fechaInicioContrato: ''


        },
        isLoadingSips: false,
        isLoadingConsumos: true,
        isLoadingConsumosTotales: true,
        changeLogoLoading: false,
        updateProfileLoading: false,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        filesContrato: [],
        LecturasContrato: [],
        facturasContrato: [],
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        userName: '',

        fechaActivacion: '',
        fechaBajaContrato: '',
        fechaContrato: '',
        fechaFinContrato: '',
        fechaFirmaContrato: '',
        fechaInicioContrato: '',
        fechaVencimientoContrato: '',
        fechaActivacionFlag: 0,
        fechaFirmaContratoFlag: 0,
        fechaVencimientoContratoFlag: 0,
        saveData: false,
        saveDataRepre: false,
        saveProductButtonDisabled: true,
        contratoButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: false,
        idComercializadoraActualContrato: '',
        nombreComercializadoraActualContrato: '',
        cups: '',
        CIF: '',
        consumoAnualCup: '',
        idRepresentante: '',
        representanteDetail: [],
        formRepre: {
            CIFRepre: '',
            CargoRepre: '',
            apellido1Representante: '',
            apellido2Representante: '',
            companyIdRepresentante: '',
            correoRepresentante: '',
            idRepresentante: '',
            idRepresentanteInterno: 0,
            nombreRepresentante: '',
            telefonoRepresentante: '',
            fechaAltaRepre: null,
            fechaBajaRepre: null,
            codigoAutonomiaRepre: "",
            codigoPostalRepre: "",
            codigoProvinciaRepre: "",
            desAutonomiaRepre: "",
            desMunicipioRepre: "",
            desProvinciaRepre: "",
            escaleraRepre: "",
            municipioRepre: "",
            numFincaRepre: "",
            pisoRepre: "",
            portalRepre: "",
            puertaRepre: "",
            tipoViaRepre: "",
            viaRepre: ""
        },
        T_Titular: {
            NombreTitular: "",
            Apellido1Titular: "",
            Apellido2Titular: "",
            NIF: "",
            TipoTitular: "",
            TipoViaTitular: "",
            ViaTitular: "",
            NumFincaTitular: "",
            PortalTitular: "",
            EscaleraTitular: "",
            PisoTitular: "",
            PuertaTitular: "",
            CodigoPostalTitular: "",
            AutonomiaTitular: "",
            ProvinciaTitular: "",
            MunicipioTitular: "",
            TelefonoFijo: "",
            TelefonMovil: "",
            Email: "",
            TipoCliente: "",
            ObservacionesCliente: "",
            GDPR: true,
            IBAN: "",
            CNAE: "",
            actividadCNAE: ""
        },
        T_Representante: {
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            NIFRepresentante: "",
            TipoTitularRepresentante: "",
            idRepresentante: "",
            idRepresentanteInterno: 0,
            CargoRepresentante: "",
            telefonoRepresentante: "",
            correoRepresentante: "",
            codigoPostalRepresentante: "",
            AutonomiaRepresentante: "",
            MunicipioRepresentante: "",
            ProvinciaRepresentante: "",
            escaleraRepresentante: "",
            numFincaRepresentante: "",
            pisoRepresentante: "",
            portalRepresentante: "",
            puertaRepresentante: "",
            tipoViaRepresentante: "",
            viaRepresentante: ""
        },
        T_PuntoSuministro: {
            TipoViaPS: "",
            ViaPS: "",
            NumFincaPS: "",
            PortalPS: "",
            EscaleraPS: "",
            PisoPS: "",
            PuertaPS: "",
            CodigoPostalPS: "",
            NombreMunicipioPS: "",
            codigoMunicipioPS: "",
            NombreProvinciaPS: "",
            codigoProvinciaPS: "",
            AutonomiaPS: "",
            codigoAutonomiaPS: "",
            TelefonoPS: "",
            ObservacionesPuntoSuministro: ""
        },
        T_CUPs: {
            Distribuidora: "",
            CUPsEle: "",
            Tarifa: "",
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            P6: 0,
            PotMaxBie: "",
            TipoServicio: "",
            Consumo: "",
            esViviendaHabitual: "",
            fechaCaducidadAPM: null,
            fechaCaducidadBIE: null,
            fechaEmisionAPM: null,
            fechaEmisionBIE: null,
            fechaLimiteDerechosReconocidos: null,
            fechaUltimaLectura: null,
            fechaUltimoCambioComercializador: null,
            fechaUltimoMovimientoContrato: null,
            importeDepositoGarantiaEuros: 0,
            informacionImpagos: null,
            codigoAutoconsumo: "",
            descAutoconsumo: "",
            codigoTelegestion: "",
            tipoTelegestion: "",
            proEquipoMedida: "",
            propiedadICP: "",
            relacionTransformacionIntensidad: "",
            tarifaATR: "",
            tensionEm: "",
            tipoContrato: "",
            valorDerechosAccesoW: "",
            valorDerechosExtensionW: "",
            Tension: "",
            accesoContador: ""
        },
        T_PropuestaComercial: {
            PorcentajeAhorro: "",
            ImporteAhorro: "",
            Estado: "",
            Comercializadora: "",
            Producto: "",
            TarifaPeaje: "",
            Tarifa: "",
            IdProducto: "",
            Anexo: "",
            TipoPrecio: "",
            ObservacionesProducto: "",
            GastoOperativo: "",
            GastoOperativoPotencia: "",
            idServicio: "",
            idComercializadora: "",
            nombreServicio: "",
            precioServicio: "",
            ComercializadoraProcedencia: "",
            idComercializadoraProcedencia: ""
        },
        T_DatosAgenteComercial: {
            usuario: "",
            nombreUsuario: "",
            compania: 0,
            nombreCompania: "",
            correoAgente: "",
            zonaGeografica: ""
        },
        contratoDataGeneralElectricidad: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTEP3: 0,
            precioContratoTEP4: 0,
            precioContratoTEP5: 0,
            precioContratoTEP6: 0,
            precioContratoTP1: 0,
            precioContratoTP3: 0,
            precioContratoTP2: 0,
            precioContratoTP4: 0,
            precioContratoTP5: 0,
            precioContratoTP6: 0,
            costeOperativo: "",
            costeOperativoPotencia: "",
            descuentoTerminoEnergia: 0,
            descuentoTerminoPotencia: 0,
            duracionContrato: ""
        },
        contratoDataGeneralGas: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTFijo: 0,
            coeficienteK: 0,
            costeOperativo: 0,
            descuentoTerminoEnergia: 0,
            descuentoCoeficientek: 0,
            duracionContrato: "",
            caudalDiarioGas: 0
        },
        T_General: {
            Usuario: "",
            compania: 0,
            fechaPropuesta: "",
            horaPropuesta: "",
            mesPropuesta: 0,
            anioPropuesta: 0,
            fechaAprobacion: "",
            horaAprobacion: "",
            mesAprobacion: 0,
            anioAprobacion: 0,
            esAprobada: true,
            fechaDenegacion: "",
            horaDenegacion: "",
            mesDenegacion: 0,
            anioDenegacion: 0,
            esDenegada: false,
            IdOferta: "",
            IdOfertaInterna: "",
            cups: "",
            tipoCliente: "",
            tipoEnergia: "",
            estado: "",
            OfertaTipo: "",
            ofertaTipoId: "",
            idContrato: "",
            idInternoContrato: "",
            idCliente: "",
            idTipoContrato: "",
            fechaContrato: "",
            esRenovacion: false,
            modRenovacion: "",
            existePropRenovacion: false,
            fechaInicioContrato: null,
            duracionContrato: "12",
            fechaVencimientoContrato: null,
            fechaFirmaContrato: null,
            fechaFinContrato: null,
            referenciaContrato: "",
            observacionesContrato: "",
            idTipoSeguimiento: "",
            contratoGeneradoEnviado: false,
            esBajaContrato: false,
            fechaBajaContrato: "",
            justificacionBajaContrato: "",
            rutaDocumento: "",
            createdAt: "",
            updatedAt: "",
            createdBy: "",
            updatedBy: "",
            estadoCups: "",
            idContratoComercializadora: "",
            estadoSinComercialiazadora: "",
            estadoId: "",
            fechaActivacion: ""

        }, T_Seguimiento: [{
            accion: null,
            anio: null,
            compania: null,
            fecha: null,
            hora: null,
            idAccion: null,
            mes: null,
            nombreUsuario: null,
            observacion: "",
            usuario: null
        }],
        T_SeguimientoAux: {
            accion: null,
            anio: null,
            compania: null,
            fecha: null,
            hora: null,
            idAccion: null,
            mes: null,
            nombreUsuario: null,
            observacion: "",
            usuario: null
        },
        T_PropuestaComercialDatosIndividual: {},
        propuestaData: {
            precioPotenciaP1: 0,
            precioPotenciaP2: 0,
            precioPotenciaP3: 0,
            precioPotenciaP4: 0,
            precioPotenciaP5: 0,
            precioPotenciaP6: 0,
            precioEnergiaP1: 0,
            precioEnergiaP2: 0,
            precioEnergiaP3: 0,
            precioEnergiaP4: 0,
            precioEnergiaP5: 0,
            precioEnergiaP6: 0

        },
        T_Propuesta: [{

        }],
        observacionAux: "",
        estadoAux: "",
        estadoIdAux: "",
        saveButtonDisabledEstado: true,
        CIFRepreError: false,
        inputDisabled: false,
        show: false,
        showFacturas: false,
        showEstados: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        inputCifDisabled: false,
        inputCupsDisabled: false,
        inputContratoDisabled: false,
        openModalRepresentante: false,
        representanteSeleccionado: [],
        openModalComparative: false,
        openModalConsumos: false,
        representante: [],
        locale: 'es',
        apiOptions: [],
        alert: null,
        dicEstados: {},
        estadoDisabled: false,
        productoTarifas: {},
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.lecturasRef = React.createRef();
        this.inputEl = React.createRef();

        this.showModal = this.showModal.bind(this);

        this.toggle = this.toggle.bind(this);

    }


    componentWillReceiveProps() {

        //console.log(this.props)
        //console.log(this.state)

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        //console.log('VALOR DE USUARIO ----------------->', usuario)



        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.fullname

        var direccion = ''
        if (companyIdUser) {
            direccion = `http://78.47.44.246:3020/filemanager/` + companyIdUser
        }



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            userName

        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////


    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        console.log('VALORES DE MATCH ENVIADOS POR GESTION CONTRATO', params)

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = '';
        let userId = '';
        let userName = '';

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;
                userId = parsed.id
                userName = parsed.fullname
            } catch (e) {
                console.log(e)
            }
        }
        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName });
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    fetchRepreId = async (representante) => {
        console.log('fetchRepre VALOR DE THIS.STATE -------> ', representante)
        const idRepresentante = representante
        if (idRepresentante) {
            console.log('VALOR DE this.state.contratoDetail.idRepresentante en el fetchRepre ', idRepresentante)    // Sips
            await this.props.client
                .query({
                    query: DATA_REPRE_CONTRATO,
                    variables: { idRepresentante: idRepresentante },
                    fetchPolicy: 'no-cache'
                })
                .then((result) => {
                    this.setState({ representanteDetail: result.data.representanteData[0] });
                    //    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                    console.log('VALORE DE representanteDetail  ------> ', result.data.representanteData[0])
                    //    this.setState({fechaContrato: result.data.contratoInfoById[0].fechaContrato});
                    // let existeFechaFirma = result.data.getRepresentanteInfo[0].fechaFirmaContrato
                    // let existeFechaActivacion = result.data.getRepresentanteInfo[0].fechaActivacion
                    this.setState({ idRepresentante: result.data.representanteData[0].idRepresentante });
                    this.setState({
                        formRepre: {
                            CIFRepre: result.data.representanteData[0].CIFRepre,
                            CargoRepre: result.data.representanteData[0].CargoRepre,
                            apellido1Representante: result.data.representanteData[0].apellido1Representante,
                            apellido2Representante: result.data.representanteData[0].apellido2Representante,
                            companyIdRepresentante: result.data.representanteData[0].companyIdRepresentante,
                            correoRepresentante: result.data.representanteData[0].correoRepresentante,
                            idRepresentante: result.data.representanteData[0].idRepresentante,
                            idRepresentanteInterno: result.data.representanteData[0].idRepresentanteInterno,
                            nombreRepresentante: result.data.representanteData[0].nombreRepresentante,
                            telefonoRepresentante: result.data.representanteData[0].telefonoRepresentante,
                            codigoAutonomiaRepre: result.data.representanteData[0].codigoAutonomiaRepre,
                            codigoPostalRepre: result.data.representanteData[0].codigoPostalRepre,
                            codigoProvinciaRepre: result.data.representanteData[0].codigoProvinciaRepre,
                            desAutonomiaRepre: result.data.representanteData[0].desAutonomiaRepre,
                            desMunicipioRepre: result.data.representanteData[0].desMunicipioRepre,
                            desProvinciaRepre: result.data.representanteData[0].desProvinciaRepre,
                            escaleraRepre: result.data.representanteData[0].escaleraRepre,
                            municipioRepre: result.data.representanteData[0].municipioRepre,
                            numFincaRepre: result.data.representanteData[0].numFincaRepre,
                            pisoRepre: result.data.representanteData[0].pisoRepre,
                            portalRepre: result.data.representanteData[0].portalRepre,
                            puertaRepre: result.data.representanteData[0].puertaRepre,
                            tipoViaRepre: result.data.representanteData[0].tipoViaRepre,
                            viaRepre: result.data.representanteData[0].viaRepre
                        }
                    });
                    /*
                    if((typeof(existeFechaFirma) != 'undefined' && existeFechaFirma !=  null && existeFechaFirma != '')&& (typeof(existeFechaActivacion) != 'undefined' && existeFechaActivacion !=  null && existeFechaActivacion != '')){
                    this.setState({saveXMLButtonDisabled:false})
                }
                */

                });

        }
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////7

    fetchRepre = async () => {
        console.log('fetchRepre VALOR DE THIS.STATE -------> ', this.state)
        if (this.state.contratoDetail.idRepresentante != null && this.state.contratoDetail.idRepresentante != "") {
            console.log('VALOR DE this.state.contratoDetail.idRepresentante en el fetchRepre ', this.state.idRepresentante)    // Sips
            await this.props.client
                .query({
                    query: DATA_REPRE_CONTRATO,
                    variables: { idRepresentante: this.state.idRepresentante },
                    fetchPolicy: 'no-cache'
                })
                .then((result) => {
                    this.setState({ representanteDetail: result.data.representanteData[0] });
                    //    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                    console.log('VALORE DE representanteDetail  ------> ', result.data.representanteData[0])
                    //    this.setState({fechaContrato: result.data.contratoInfoById[0].fechaContrato});
                    // let existeFechaFirma = result.data.getRepresentanteInfo[0].fechaFirmaContrato
                    // let existeFechaActivacion = result.data.getRepresentanteInfo[0].fechaActivacion
                    this.setState({ idRepresentante: result.data.representanteData[0].idRepresentante });
                    this.setState({
                        formRepre: {
                            CIFRepre: result.data.representanteData[0].CIFRepre,
                            CargoRepre: result.data.representanteData[0].CargoRepre,
                            apellido1Representante: result.data.representanteData[0].apellido1Representante,
                            apellido2Representante: result.data.representanteData[0].apellido2Representante,
                            companyIdRepresentante: result.data.representanteData[0].companyIdRepresentante,
                            correoRepresentante: result.data.representanteData[0].correoRepresentante,
                            idRepresentante: result.data.representanteData[0].idRepresentante,
                            idRepresentanteInterno: result.data.representanteData[0].idRepresentanteInterno,
                            nombreRepresentante: result.data.representanteData[0].nombreRepresentante,
                            telefonoRepresentante: result.data.representanteData[0].telefonoRepresentante,
                            fechaAltaRepre: result.data.representanteData[0].fechaAltaRepre,
                            fechaBajaRepre: result.data.representanteData[0].fechaBajaRepre,
                            codigoAutonomiaRepre: result.data.representanteData[0].codigoAutonomiaRepre,
                            codigoPostalRepre: result.data.representanteData[0].codigoPostalRepre,
                            codigoProvinciaRepre: result.data.representanteData[0].codigoProvinciaRepre,
                            desAutonomiaRepre: result.data.representanteData[0].desAutonomiaRepre,
                            desMunicipioRepre: result.data.representanteData[0].desMunicipioRepre,
                            desProvinciaRepre: result.data.representanteData[0].desProvinciaRepre,
                            escaleraRepre: result.data.representanteData[0].escaleraRepre,
                            municipioRepre: result.data.representanteData[0].municipioRepre,
                            numFincaRepre: result.data.representanteData[0].numFincaRepre,
                            pisoRepre: result.data.representanteData[0].pisoRepre,
                            portalRepre: result.data.representanteData[0].portalRepre,
                            puertaRepre: result.data.representanteData[0].puertaRepre,
                            tipoViaRepre: result.data.representanteData[0].tipoViaRepre,
                            viaRepre: result.data.representanteData[0].viaRepre

                        }
                    });
                    /*
                    if((typeof(existeFechaFirma) != 'undefined' && existeFechaFirma !=  null && existeFechaFirma != '')&& (typeof(existeFechaActivacion) != 'undefined' && existeFechaActivacion !=  null && existeFechaActivacion != '')){
                    this.setState({saveXMLButtonDisabled:false})
                }
                */

                });

        }
    }


    /////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {


        var existeRepre = 0;
        var tipoContrato = 'L'

        console.log('VALOR DE this.state.companyIdUser en buscarContrato/Fetch Items ', this.state)
        const companyIdAux = this.state.companyIdUser.toString();
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = ""
        }

        var dataFilters = {
            'compania': "",
            'cups': "",
            'Usuario': userQuery,
            'NIF': "",
            'idContrato': this.state.contrato
        };
        var contratoDato: any = []

        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/listado?id=" + companyIdAux + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                contratoDato = result.data.rows[0]
                let contratoDatosGenerales = { ...result.data.rows[0] }
                tipoContrato = result.data.rows[0].idTipoContrato
                delete contratoDatosGenerales.T_Titular
                delete contratoDatosGenerales.T_Representante
                delete contratoDatosGenerales.T_PuntoSuministro
                delete contratoDatosGenerales.T_CUPs
                delete contratoDatosGenerales.T_PropuestaComercial
                delete contratoDatosGenerales.T_DatosAgenteComercial
                delete contratoDatosGenerales.contratoDataGeneralElectricidad
                delete contratoDatosGenerales.contratoDataGeneralGas
                //delete contratoDatosGenerales.T_Propuesta
                delete contratoDatosGenerales.T_Seguimiento


                var estadoDisabledButton = false
                if (contratoDato.estadoId == '90') {
                    estadoDisabledButton = true
                }

                this.setState({
                    //contratoDetailTotal: result.data.rows[0],
                    tipoContrato : tipoContrato,
                    T_Titular: result.data.rows[0].T_Titular,
                    T_General: contratoDatosGenerales,
                    T_Representante: result.data.rows[0].T_Representante,
                    T_PuntoSuministro: result.data.rows[0].T_PuntoSuministro,
                    T_CUPs: result.data.rows[0].T_CUPs,
                    T_PropuestaComercial: result.data.rows[0].T_PropuestaComercial,
                    T_DatosAgenteComercial: result.data.rows[0].T_DatosAgenteComercial,
                    contratoDataGeneralElectricidad: result.data.rows[0].contratoDataGeneralElectricidad,
                    contratoDataGeneralGas: result.data.rows[0].contratoDataGeneralGas,
                    T_Seguimiento: result.data.rows[0].T_Seguimiento,
                    estadoDisabled: estadoDisabledButton
                    //propuestaData: propuestaDataAux
                })

            }).catch((error) => {
                console.log('Failure')
                this.warningAlert();
            });

        console.log('result.data.rows[0] en contratoDato --->', contratoDato)


                    let fechaFirmaContratoAux = ""
                    let fechaActivacionAux = ""
                    let fechaVencimientoContratoAux = ""

                    if (contratoDato.fechaFirmaContrato) {
                        fechaFirmaContratoAux = contratoDato.fechaFirmaContrato
                    }

                    if (contratoDato.fechaActivacion) {

                        fechaActivacionAux = contratoDato.fechaActivacion
                    }

                    if (contratoDato.fechaVencimientoContrato) {
                        fechaVencimientoContratoAux = contratoDato.fechaVencimientoContrato
                        //let existefechatest = moment(moment(new Date(result.data.contratoInfoById[0].fechaVencimientoContrato.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))).set("hour", hora).set("minute", minutos)).toISOString();
                    }

                    let idComercializadoraActualContrato = "";
                    let nombreComercializadoraActualContrato = ""

                    if (contratoDato.T_PropuestaComercial.hasOwnProperty('idComercializadoraProcedencia')) {
                        idComercializadoraActualContrato = contratoDato.T_PropuestaComercial.idComercializadoraProcedencia
                        nombreComercializadoraActualContrato = contratoDato.T_PropuestaComercial.ComercializadoraProcedencia
                    };

                    this.setState({
                        idComercializadoraActualContrato: idComercializadoraActualContrato,
                        nombreComercializadoraActualContrato: nombreComercializadoraActualContrato,
                        CIF: contratoDato.T_Titular.NIF,
                        cups: contratoDato.cups,
                        fechaFirmaContrato: fechaFirmaContratoAux,
                        fechaVencimientoContrato: fechaVencimientoContratoAux,
                        fechaActivacion: fechaActivacionAux,
                        idRepresentante: contratoDato.T_Representante.idRepresentante,
                        consumoAnualCup: contratoDato.T_CUPs.Consumo
                    });

                    if ((typeof (fechaFirmaContratoAux) != 'undefined' && fechaFirmaContratoAux != null && fechaFirmaContratoAux != '') && (typeof (fechaActivacionAux) != 'undefined' && fechaActivacionAux != null && fechaActivacionAux != '')) {
                        this.setState({
                            saveXMLButtonDisabled: false,
                            inputDisabled: false,
                            contratoButtonDisabled: false
                        })
                    } else {
                        this.setState({
                            saveXMLButtonDisabled: true,
                            inputDisabled: true,
                            contratoButtonDisabled: true
                        })

                    };

                    if (typeof (fechaFirmaContratoAux) != 'undefined' && fechaFirmaContratoAux != null && fechaFirmaContratoAux != '') {
                        this.setState({ inputDisabled: true })
                    } else {
                        this.setState({ inputDisabled: false })
                    }

                    existeRepre = contratoDato.T_Representante.idRepresentanteInterno
                    this.setState({ isLoadingSips: false });

                    console.log('existeRepre ', existeRepre)
                    console.log('fechaFirmaContratoAux ', fechaFirmaContratoAux)
                    if (existeRepre != 0 && !fechaFirmaContratoAux) {


                        var dataFiltersRepre = {
                            'compania': "",
                            'cups': "",
                            'Usuario': userQuery,
                            'NIF': "",
                            'idContrato': "",
                            'NIFRepresentante': contratoDato.T_Representante.NIFRepresentante
                        };
                        var contratoDato: any = []

                        let valores = JSON.stringify(dataFiltersRepre)

                        let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdAux + "&user=" + userIdAux;

                        axios.get(direccionFile, { headers, params: { valores } }).
                            then(result => {
                                console.log('resultado del listados')
                                console.log(result.data.rows)

                                this.setState({
                                    idRepresentante: result.data.rows[0].idRepresentanteInterno,
                                    T_Representante: result.data.rows[0]
                                });

                            }).catch((error) => {
                                console.log('Failure')
                                this.warningAlert()
                            });
                    }


                    let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;
                    axios.get(direccionFileList, { headers }).
                        then(result => {
                            console.log('resultado del listados')
                            console.log(result)
                            this.setState({ filesContrato: result.data.Contents})
                        }).catch(function() {
                            console.log('Failure')
                        });

        console.log('VALOR DE THIS STATE EN FETCH LECTURAS !!!!!!!!!!------->', this.state)

        await this.fetchLecturas();
        await this.fetchFacturas();

    };


    ///////////////////////////////////////////////////////////////////////////////////////


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos. Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertDBdata = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    No existen registros con el criterio indicado. Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };


    warningAlertService = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error de comunicación con el servicio de generación de contratos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error de comunicación con el servicio. Si persiste comuniquelo al soporte técnico
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiles = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en el servidor de ficheros"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en el servidor. Comunique el problema a soporte
     </ReactBSAlert>
            )
        });
    };


    confirmAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de eliminar el representante?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.borrar()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Borrar!"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };
    confirmedAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Borrado!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    EL representante se ha eliminado del contrato.
            </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de actualizar el contrato?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Actualizar!"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    confirmAlertSavePDF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea generar el contrato en PDF?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onPdf()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Actualizar!"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    pdfAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="contrato Generado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El contrato se ha generado correctamente. Consulta el directorio de descargas.
            </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Contrato actualizado correctamente
        </ReactBSAlert>
            )
        });
    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////

    onSave = async () => {

        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveData)
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveDataRepre)

        //console.log('ESTAMOS EN ONSAVE VALOR DE THIS.PROPS -----------------> ', this.props)

        //const fechaFirmaContrato = this.state.fechaFirmaContrato;
        //const fechaVencimientoContrato = this.state.fechaVencimientoContrato;

        const companyIdAux = this.state.companyIdUser.toString();
        const userIdAux = this.state.userId.toString();
        //const idContrato = this.state.contrato;

        var datosContratoP = {
            ...this.state.T_General,
            T_Titular: this.state.T_Titular,
            T_Representante: this.state.T_Representante,
            T_PuntoSuministro: this.state.T_PuntoSuministro,
            T_CUPs: this.state.T_CUPs,
            T_PropuestaComercial: this.state.T_PropuestaComercial,
            T_DatosAgenteComercial: this.state.T_DatosAgenteComercial,
            contratoDataGeneralElectricidad: this.state.contratoDataGeneralElectricidad,
            contratoDataGeneralGas: this.state.contratoDataGeneralGas,
            T_Seguimiento: this.state.T_Seguimiento,
            //T_Propuesta:this.state.T_Propuesta
        };

        console.log('VALORES DE CONTRATO ANTES DE UPDATE ------>', datosContratoP)



        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/update?id=" + companyIdAux + "&user=" + userIdAux;


        axios.post(direccionFile, datosContratoP, { headers, responseType: 'json' })
            .then(res => {
                console.log('Actualización correcta', res)
                console.log('Datos del registro: ', res.data)
                //notify();
                this.setState({
                    saveProductButtonDisabled: true,
                    saveButtonDisabledEstado: false
                })
                this.successAlert()
            }).catch(error => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
                //notifyErrorDB();
            });

        this.fetchItems();



        if (this.state.saveDataRepre == true) {

            console.log('ENTRA EN SAVE DATOS REPRESENTANTES')
            console.log('VALORES DE THIS STATE ----- >  ', this.state)
        }

    };

    //////////////////////////////////////////////////FUNCION PDF //////////////////////////////////////

    onPdf = async () => {


        const CIF = this.state.T_Titular.NIF;
        var razonSocial = '';
        if (this.state.T_Titular.NombreTitular == "" && this.state.T_Titular.Apellido2Titular == "" && this.state.T_Titular.Apellido1Titular != "") {

            razonSocial = this.state.T_Titular.Apellido1Titular
        } else {

            razonSocial = this.state.T_Titular.NombreTitular + " " + this.state.T_Titular.Apellido1Titular + " " + this.state.T_Titular.Apellido2Titular
        }
        //const datosContrato = JSON.parse(this.state.contratoDetail.datosContrato)
        //console.log('VALOR DE DATOS DE CONTRATO ANTES DEL PDF ', datosContrato)

        const IBAN = this.state.T_Titular.IBAN

        const municipioTitular = this.state.T_Titular.MunicipioTitular
        const provinciaTitular = this.state.T_Titular.ProvinciaTitular
        const terminoFijo = this.state.contratoDataGeneralGas.precioContratoTFijo
        var terminoVariable = this.state.contratoDataGeneralGas.precioContratoTEP1 ?.toString() || '0'
        if (terminoVariable == '0') {
            terminoVariable = 'Según anexo'
        }
        const nombreProducto = this.state.T_PropuestaComercial.Producto
        const idTarifaPeaje = this.state.T_PropuestaComercial.Tarifa
        const contrato = this.state.T_General.idContrato
        const CUPS = this.state.T_General.cups

        const direccion = this.state.T_Titular.TipoViaTitular + ' ' + this.state.T_Titular.ViaTitular + ' ' + this.state.T_Titular.NumFincaTitular + ' ' + this.state.T_Titular.PortalTitular + ' ' + this.state.T_Titular.PisoTitular + ' ' + this.state.T_Titular.PuertaTitular
        const codigoPostal = this.state.T_Titular.CodigoPostalTitular
        const representante = this.state.T_Representante.nombreRepresentante + ' ' + this.state.T_Representante.apellido1Representante + ' ' + this.state.T_Representante.apellido2Representante
        const NIF = this.state.T_Representante.NIFRepresentante
        const correoContacto = this.state.T_Representante.correoRepresentante
        const telefono = this.state.T_Representante.telefonoRepresentante
        const direccionPS = this.state.T_PuntoSuministro.TipoViaPS + ' ' + this.state.T_PuntoSuministro.ViaPS + ' ' + this.state.T_PuntoSuministro.NumFincaPS + ' ' + this.state.T_PuntoSuministro.PortalPS + ' ' + this.state.T_PuntoSuministro.PisoPS + ' ' + this.state.T_PuntoSuministro.PuertaPS
        const codigoPostalPS = this.state.T_PuntoSuministro.CodigoPostalPS
        const localidadPS = this.state.T_PuntoSuministro.NombreMunicipioPS
        const provinciaPS = this.state.T_PuntoSuministro.NombreProvinciaPS
        const consumoAnualPrevisto = this.state.T_CUPs.Consumo
        const caudalDiario = 0
        const CNAE = this.state.T_Titular.CNAE
        const condiciones = this.state.T_PropuestaComercial.ObservacionesProducto
        const fechaEntradaVigor = this.state.T_General.fechaActivacion
        const fechaIncioContrato = this.state.T_General.fechaActivacion
        const correoelectronicocontacto = this.state.T_Titular.Email
        const titularCuenta = razonSocial
        var firma = ""


        if (!NIF) {
            firma = razonSocial
        } else {
            firma = representante
        }

        const CIFCuenta = CIF
        const direccionCuenta = direccion
        const codigoPostalCuenta = codigoPostal
        const localidadCuenta = municipioTitular
        const provinciaCuenta = provinciaTitular
        const paisCuenta = 'ESPAÑA'
        const fechaFirmaContrato = this.state.T_General.fechaFirmaContrato

        const CargoRepre = this.state.T_Representante.CargoRepresentante

        const municipioRepre = this.state.T_Representante.MunicipioRepresentante
        const viaRepre = this.state.T_Representante.viaRepresentante
        const numFincaRepre = this.state.T_Representante.numFincaRepresentante


        //let direccionFileList = "https://datapi.psgestion.es/pdfContrato?id="+this.state.companyIdUser+"&contrato="+this.state.contrato+"&file="+file;
        let direccionFileList = "https://datapi.psgestion.es/pdfContrato?id=" + this.state.companyIdUser;

        console.log(direccionFileList)





        var dataPdf2 = {
            'CIF': CIF,
            'razonSocial': razonSocial,
            'IBAN': IBAN,
            'municipioTitular': municipioTitular,
            'provinciaTitular': provinciaTitular,
            'pais': 'ESPAÑA',
            'terminoFijo': terminoFijo,
            'terminoVariable': terminoVariable,
            'nombreProducto': nombreProducto,
            'idTarifaPeaje': idTarifaPeaje,
            'contrato': contrato,
            'CUPS': CUPS,
            'direccion': direccion,
            'codigoPostal': codigoPostal,
            'representante': representante,
            'NIF': NIF,
            'correoContacto': correoContacto,
            'telefono': telefono,
            'direccionPS': direccionPS,
            'codigoPostalPS': codigoPostalPS,
            'localidadPS': localidadPS,
            'provinciaPS': provinciaPS,
            'consumoAnualPrevisto': consumoAnualPrevisto,
            'caudalDiario': caudalDiario,
            'CNAE': CNAE,
            'condiciones': condiciones,
            'fechaEntradaVigor': fechaEntradaVigor,
            'fechaIncioContrato': fechaIncioContrato,
            'correoelectronicocontacto': correoelectronicocontacto,
            'titularCuenta': titularCuenta,
            'firma': firma,
            'CIFCuenta': CIFCuenta,
            'direccionCuenta': direccionCuenta,
            'codigoPostalCuenta': codigoPostalCuenta,
            'localidadCuenta': localidadCuenta,
            'provinciaCuenta': provinciaCuenta,
            'paisCuenta': paisCuenta,
            'fechaFirmaContrato': fechaFirmaContrato,
            'CargoRepre': CargoRepre



        };
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/upload?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;


        axios.post(direccionFileList, dataPdf2, { headers, responseType: 'blob' }
        ).then(res => {
            const blob = res.data
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'contrato-' + this.state.contratoDetail.cups + '.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.pdfAlert();
        }).catch((error) => {
            console.log('Failure')
            this.warningAlertService()
        });


    };


    ////////////////////// Función para subir Ficheros /////////////////////////


    fetchLecturas = () => {


        console.log('VALOR DE THIS STATE EN FETCH LECTURAS------->', this.state)

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        //let direccionFile = "https://datapi.psgestion.es/upload?id="+this.state.companyIdUser+"&contrato="+this.state.contrato;
        let direccionFileList = "https://datapi.psgestion.es/lecturas/gas?id=" + this.state.companyIdUser + "&cups=" + this.state.contratoDetail.cups;
        //console.log(direccionFile)

        axios.get(direccionFileList, { headers }).
            then(result => {
                console.log('resultado del listados')
                console.log(result)
                this.setState({ LecturasContrato: result.data })
            }).catch(function() {
                console.log('Failure')
            });
    }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////

    fetchFacturas = () => {


        console.log('VALOR DE THIS STATE EN FETCH FACTURAS------->', this.state)

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        //let direccionFile = "https://datapi.psgestion.es/upload?id="+this.state.companyIdUser+"&contrato="+this.state.contrato;
        let direccionFileList = "https://datapi.psgestion.es/facturas/creadas/cups?id=" + this.state.companyIdUser + "&cups=" + this.state.contratoDetail.cups;
        //console.log(direccionFile)

        axios.get(direccionFileList, { headers }).
            then(result => {
                console.log('resultado del listados')
                console.log(result)
                this.setState({ facturasContrato: result.data })
            }).catch(function() {
                console.log('Failure')
            });
    }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

    validarCUPS = (cups) => {
        let ret = false;
        const reCUPS = /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i;
        if (reCUPS.test(cups)) {
            const mCUPS = cups.toUpperCase().match(reCUPS);
            const [, cups16, control] = mCUPS;
            const letters = [
                'T', 'R', 'W', 'A', 'G', 'M',
                'Y', 'F', 'P', 'D', 'X', 'B',
                'N', 'J', 'Z', 'S', 'Q', 'V',
                'H', 'L', 'C', 'K', 'E',
            ];

            const cup16Mod = +cups16 % 529,
                quotient = Math.floor(cup16Mod / letters.length),
                remainder = cup16Mod % letters.length;

            ret = (control === letters[quotient] + letters[remainder]);
        }

        return ret
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onPageClick = (page) => { };


    onChangeFieldEstado = (event) => {
        event.persist()

        //let formData = { ...this.state.T_General};
        let formdataSeguimiento = {...this.state.T_SeguimientoAux}
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var botonDisabled = true

        if (name === "estado" && value !== "0") {

            if (value !== "0") {
                formdataSeguimiento['accion'] = [
                    ...event.target.querySelectorAll("option"),
                ].filter((item) => item.value === value)[0].innerText;
                //formData['idComercializadora'] = value;

            }
            formdataSeguimiento['idAccion'] = value

            botonDisabled = false
            var valor = false
            if (value == '40' || value == '50'){

            valor = true
            }

            this.setState({estadoDisabled : valor })

        }


        if (name === "observacion"){
            formdataSeguimiento[name]=value

                }


            this.setState({ T_SeguimientoAux: formdataSeguimiento,
                            saveButtonDisabledEstado: botonDisabled,
                            estadoIdAux: formdataSeguimiento['idAccion']

                });

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////


    onChangeField = (event) => {
        event.persist()
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
    }

    onChangeFieldSearch = (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;
        console.log(event)
        console.log(name)
        console.log(value)

        filters[name] = value;
        /* if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/


        if (event.target.name === 'cupsSearch') {
            const result = this.validarCUPS(event.target.value)
            console.log('VALIDACION DE CUPS ', result)
            if (result === true) {
                this.setState({
                    cupsError: false,
                    buscarButtonDisabled: false

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    cupsError: true,
                    buscarButtonDisabled: true
                })
            }

        }
        console.log('VALOR DE LENGTH DE VALUE ------> ', event.target.name, event.target.value.length, event.target.value)

        if (event.target.name === 'cupsSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputCupsDisabled: false,
                inputContratoDisabled: false
            })
        } else if (event.target.name === 'cupsSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputContratoDisabled: true,
                buscarButtonDisabled: false

            })

        }

        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {
            this.setState({
                cifError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputCupsDisabled: false,
                inputContratoDisabled: false
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {
            this.setState({

                inputCupsDisabled: true,
                inputContratoDisabled: true,
                buscarButtonDisabled: false

            })


        }


        if (event.target.name === 'contratoSearch' && event.target.value.length == 0) {
            this.setState({
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputCupsDisabled: false,
                inputContratoDisabled: false
            })
        } else if (event.target.name === 'contratoSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputCupsDisabled: true,
                buscarButtonDisabled: false
            })

        }





        if (event.target.name === 'cifSearch') {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    cifError: false,
                    buscarButtonDisabled: false

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    cifError: true,
                    buscarButtonDisabled: true
                })
            }
        }


        this.setState({ filters });

    }


    sipConsumosGasSelected = () => {
        let id = this.state.T_CUPs.CUPsEle;
        const win = window.open("/consumos/sip-detalles-extendidos/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    sipConsumosSelected = () => {
        let id = this.state.T_CUPs.CUPsEle;
        const win = window.open("/consumos/sip-detalles-extendidos-elec/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    onChangeFieldTitular = (event) => {
        event.persist()
        let formData = { ...this.state.T_Titular };
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log(event.target.name)
        console.log(event.target.value)
        var CIFRepreError = false
        var saveProductButtonDisabled = false

        if (event.target.name === 'NIF') {
            const result = validateSpanishId(event.target.value)

            if (result === true) {

                CIFRepreError = false
                saveProductButtonDisabled = false


            } else {

                CIFRepreError = true
                saveProductButtonDisabled = true

            }
        } else {
            saveProductButtonDisabled = false

        };

        console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({
            T_Titular: formData,
            CIFRepreError: CIFRepreError,
            saveProductButtonDisabled: saveProductButtonDisabled
        });



    }


    onChangeFieldRepre = (event) => {
        event.persist()
        let formData = { ...this.state.T_Representante };
        let target = event.target;
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log(event.target.name)
        console.log(event.target.value)

        if (event.target.name == 'nombreRepresentante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name === 'CIFRepresentante') {
            const result = validateSpanishId(event.target.value)
            console.log('ERRORE EN VALIDATE ID ---->', result)
            if (result === true) {
                this.setState({
                    CIFRepreError: false,
                    saveProductButtonDisabled: false

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    CIFRepreError: true,
                    saveProductButtonDisabled: true
                })
            }
        }

        if (event.target.name == 'apellido1Representante') {
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name == 'apellido2Representante') {
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }

        if (event.target.name == 'correoRepresentante') {
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }

        if (event.target.name == 'telefonoRepresentante') {
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name == 'CargoRepre') {
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }


        console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formRepre: formData });

    };

    onChangeFieldPuntoSuministro = (event) => {
        event.persist()
        let formData = { ...this.state.T_PuntoSuministro };
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log(event.target.name)
        console.log(event.target.value)
        var saveProductButtonDisabled = false

        console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({
            T_PuntoSuministro : formData,
            saveProductButtonDisabled: saveProductButtonDisabled
        });


    }

    onChangeFieldCUPs = (event) => {
        event.persist()
        let formData = { ...this.state.T_CUPs };
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
    }

    onChangeFieldPropuestaComercial = (event) => {
        event.persist()
        let formData = { ...this.state.T_PropuestaComercial };
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
    }



    handleChangeDateFilter = (value, formattedValue, id) => {

        let formData = { ...this.state.T_General };
        console.log('FORMDATA EN FECHAS ---> ', formData)
        let target = id;


        const name = id;
        var valueDay = formattedValue

        if (name == "fechaActivacion" && value != null) {
            this.setState({ fechaActivacion: value });
            this.setState({ fechaActivacionFlag: 1 });
            this.setState({ saveData: true });
            this.setState({ saveProductButtonDisabled: false })
        } else if (name != "fechaVencimientoContrato" && name != "fechaFirmaContrato") {
            this.setState({ fechaActivacion: "" });
            this.setState({ fechaActivacionFlag: 1 });
            this.setState({ saveData: true });
            this.setState({
                saveProductButtonDisabled: false,
                saveXMLButtonDisabled: true,
            })
        }

        if (name == "fechaVencimientoContrato" && value != null) {
            this.setState({ fechaVencimientoContrato: value });
            this.setState({ fechaVencimientoContratoFlag: 1 });
            this.setState({ saveData: true });
            this.setState({ saveProductButtonDisabled: false })

        } else if (name != "fechaActivacion" && name != "fechaFirmaContrato") {
            this.setState({ fechaVencimientoContrato: "" });
            this.setState({ fechaVencimientoContratoFlag: 1 });
            this.setState({ saveData: true });
            this.setState({ saveProductButtonDisabled: false })

        }


        if (name == "fechaFirmaContrato" && value != null) {
            this.setState({ fechaFirmaContrato: value });
            this.setState({ saveData: true });
            this.setState({ fechaFirmaContratoFlag: 1 });
            this.setState({ saveProductButtonDisabled: false })

        } else if (name != "fechaActivacion" && name != "fechaVencimientoContrato") {
            this.setState({ fechaFirmaContrato: "" });
            this.setState({ fechaFirmaContratoFlag: 1 });
            this.setState({ saveData: true });
            this.setState({
                saveProductButtonDisabled: false,
                saveXMLButtonDisabled: true,
            })

        }


        formData[name] = valueDay.split("-").reverse().join("-");
        this.setState({ T_General: formData });
    }


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    tipoLectura2 = (props) => {
        const tipoContrato = this.state.tipoContrato;
        const cupsId = this.state.contratoDetail.idContrato
        var { path, url } = this.props.match;
        //{url} = "/admin/sip-contratos/"
        console.log(path)
        console.log(url)
        // this.props.history.push('/admin/sip-contratos/');

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>
                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button disabled={this.state.saveXMLButtonDisabled} id="generarXMLProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={() => { if (window.confirm('Quieres generar el fichero XML?')) { this.XMLapprovedHandler(this.state.saveData) }; }}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-code fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text">Generar XML SCTD</span>
                                <UncontrolledTooltip delay={0} placement="top" target="generarXMLProptooltip">
                                    GENERAR XML CNMC
                                    </UncontrolledTooltip>
                            </Button>


                            <Button id="gpsProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosGasSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO
                                    </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Lect/Fact</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    LECTURAS Y FACTURACION CONTRATO
                                    </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTableEstados}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    HISTORICO ESTADOS DE SEGUIMIENTO DEL CONTRATO
                                    </UncontrolledTooltip>
                            </Button>

                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="elecProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="elecProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO
                                        </UncontrolledTooltip>
                            </Button>

                            <Button id="electuraProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Lect/Fact</span>
                                <UncontrolledTooltip delay={0} placement="top" target="electuraProptooltip">
                                    LECTURAS Y FACTURACION CONTRATO
                                        </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTableEstados}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    HISTORICO ESTADOS DE SEGUIMIENTO DEL CONTRATO
                                    </UncontrolledTooltip>
                            </Button>

                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        }
    }



    tipoCup = (props) => {
        console.log('VALOR DE PROPS EN TIPOCUP ------->', props)
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;

        console.log('VALOR DE form_T_CUPs en tipoCup -------------> ', this.state.T_CUPs)
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        if (tipoCup === 'L') {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEle">
                                    CUPS ELECTRICIDAD
                                </Label>
                                <Input
                                    id="CUPsEle"
                                    placeholder=""
                                    name="CUPsEle"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>


                        <Col sm="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="P1">
                                    P1
                                </Label>
                                <Input
                                    id="P1"
                                    placeholder=""
                                    name="P1"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P1}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P2">
                                    P2
                                </Label>
                                <Input
                                    id="P2"
                                    placeholder=""
                                    name="P2"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P2}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P3">
                                    P3
                                </Label>
                                <Input
                                    id="P3"
                                    placeholder=""
                                    name="P3"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P3}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P4">
                                    P4
                                </Label>
                                <Input
                                    id="P4"
                                    placeholder=""
                                    name="P4"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P4}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P5">
                                    P5
                                </Label>
                                <Input
                                    id="P5"
                                    placeholder=""
                                    name="P5"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P5}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P6">
                                    P6
                                </Label>
                                <Input
                                    id="P6"
                                    placeholder=""
                                    name="P6"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P6}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo Anual
                                </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Consumo}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>);
        } else {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEle">
                                    CUPS GAS
                                    </Label>
                                <Input
                                    id="CUPsEle"
                                    placeholder=""
                                    name="CUPsEle"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                    </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>


                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo
                                    </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Consumo}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-auto d-flex float-right">
                            <Card className="p-3 bg-light">


                                <Button disabled={this.state.saveXMLButtonDisabled} id="generarXMLProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                    onClick={() => { if (window.confirm('Quieres generar el fichero XML?')) { this.XMLapprovedHandler(this.state.saveData) }; }}
                                >
                                    <span className="btn-inner--icon">
                                        <i className="fas fa-file-code fa-2x" style={{ fontSize: 35 }}></i>
                                    </span>
                                    <span className="btn-inner--text">Generar XML SCTD</span>
                                    <UncontrolledTooltip delay={0} placement="top" target="generarXMLProptooltip">
                                        GENERAR XML CNMC
                                            </UncontrolledTooltip>
                                </Button>

                            </Card>
                        </Col>

                    </Row>
                </div>)
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoPrecioTarifa = (props) => {
        console.log('VALORES DE PROPS EN TIPOPRECIOTARIFA -----> ', props)
        console.log('THIS.STATE -----> ', this.state)
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_PropuestaLuz:any = this.state.contratoDataGeneralElectricidad;
        const form_T_PropuestaGas:any = this.state.contratoDataGeneralGas;
        if (tipoCup === 'L') {
            if(form_T_PropuestaComercial.TipoPrecio == "1"){
            return (
                <div>
                    <Row>
                        <Col>
                            <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                            <Row>
                                <Col><strong>Precio P1: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP1||0}</Col>
                                <Col><strong>Precio P2: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP2||0}</Col>
                                <Col><strong>Precio P3: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP3||0}</Col>
                                <Col><strong>Precio P4: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP4||0}</Col>
                                <Col><strong>Precio P5: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP5||0}</Col>
                                <Col><strong>Precio P6: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP6||0}</Col>
                            </Row>
                            <Row className="mt-2">
                                <Col><strong>Precio E1: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP1||0}</Col>
                                <Col><strong>Precio E2: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP2||0}</Col>
                                <Col><strong>Precio E3: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP3||0}</Col>
                                <Col><strong>Precio E4: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP4||0}</Col>
                                <Col><strong>Precio E5: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP5||0}</Col>
                                <Col><strong>Precio E6: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP6||0}</Col>
                            </Row>

                    </Col>

                    </Row>
                </div>)} else {
                    return (
                        <div>
                            <Row>
                                <Col>
                                    <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                    <Row>
                                        <Col><strong>Precio P1: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP1||0}</Col>
                                        <Col><strong>Precio P2: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP2||0}</Col>
                                        <Col><strong>Precio P3: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP3||0}</Col>
                                        <Col><strong>Precio P4: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP4||0}</Col>
                                        <Col><strong>Precio P5: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP5||0}</Col>
                                        <Col><strong>Precio P6: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTP6||0}</Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col><strong>Precio E1: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP1||0}</Col>
                                        <Col><strong>Precio E2: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP2||0}</Col>
                                        <Col><strong>Precio E3: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP3||0}</Col>
                                        <Col><strong>Precio E4: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP4||0}</Col>
                                        <Col><strong>Precio E5: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP5||0}</Col>
                                        <Col><strong>Precio E6: </strong><br></br>{this.state.contratoDataGeneralElectricidad.precioContratoTEP6||0}</Col>
                                    </Row>

                            </Col>

                            </Row>
                        </div>)

                    };
        } else {
            if(form_T_PropuestaComercial.TipoPrecio == "1"){
            return (
                <div>
                    <Row>
                        <Col>
                            <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                            <Row>
                                <Col><strong>Modificador Termino Variable Consumo Coeficiente K: </strong><br></br>{form_T_PropuestaGas.coeficienteK||0}</Col>
                            </Row>
                        </Col>

                    </Row>
                </div>) } else {
                    return (
                        <div>
                            <Row>
                                <Col>
                                    <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                    <Row>
                                        <Col><strong>Precio Término Fijo: </strong><br></br>{this.state.contratoDataGeneralGas.precioContratoTFijo||0}</Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col><strong>Precio Término Variable 1: </strong><br></br>{this.state.contratoDataGeneralGas.precioContratoTEP1||0}</Col>
                                        <Col><strong>Precio Término Variable 2: </strong><br></br>{this.state.contratoDataGeneralGas.precioContratoTEP2||0}</Col>
                                    </Row>

                            </Col>

                            </Row>
                        </div>)

                    };
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



        tipoLectura = (props) => {
        const tipoContrato = props.tipoContrato;
        const cupsId = this.state.contratoDetail.idContrato
        var { path, url } = this.props.match;
        //{url} = "/admin/sip-contratos/"
        console.log(path)
        console.log(url)
        // this.props.history.push('/admin/sip-contratos/');

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>
                    <Card className="p-3 bg-light">
                        <FormGroup>
                            <Button disabled={this.state.saveXMLButtonDisabled} id="generarXMLProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={() => { if (window.confirm('Quieres generar el fichero XML?')) { this.XMLapprovedHandler(this.state.saveData) }; }}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-code fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text">Generar XML SCTD</span>
                                <UncontrolledTooltip delay={0} placement="top" target="generarXMLProptooltip">
                                    GENERAR XML CNMC
                            </UncontrolledTooltip>
                            </Button>

                            <Button id="gpsProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosGasSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO
                            </UncontrolledTooltip>
                            </Button>


                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Lect/Fact</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    LECTURAS Y FACTURACION ENVIADOS POR DISTRIBUIDORA
                            </UncontrolledTooltip>
                            </Button>




                            <Button id="gfacturasProptooltip" color="info" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTableFacturas}
                            >
                                <span className="btn-inner--icon">
                                    <i className="bi bi-file-earmark-medical" style={{ fontSize: 25 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Lect/Fact</span>
                                <UncontrolledTooltip delay={0} placement="top" target="gfacturasProptooltip">
                                    FACTURAS GENERADAS CLIENTE
                        </UncontrolledTooltip>
                            </Button>
                        </FormGroup>


                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="elecProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="elecProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO
                                        </UncontrolledTooltip>
                            </Button>

                            <Button id="electuraProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Lect/Fact</span>
                                <UncontrolledTooltip delay={0} placement="top" target="electuraProptooltip">
                                    LECTURAS Y FACTURACION CONTRATO
                                        </UncontrolledTooltip>
                            </Button>

                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        }
    }



    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                cupsSearch: '',
                cifSearch: '',
                contratoSearch: ''
            },
            inputCifDisabled: false,
            inputCupsDisabled: false,
            inputContratoDisabled: false,
            contratoDetailTotal: [],
            contratoDetail: {
                fechaActivacion: "",
                fechaFirmaContrato: '',
                fechaVencimientoContrato: '',
                CIF: '',
                desMunicipioTitular: '',
                tipoViaTitular: '',
                viaTitular: '',
                numFincaTitular: '',
                portalTitular: '',
                escaleraTitular: '',
                pisoTitular: '',
                puertaTitular: '',
                cups: '',
                idContrato: '',
                idTarifaPeaje: '',
                nombreProducto: '',
                precioTEP1: 0,
                precioTEP3: 0,
                nombreTitular: '',
                apellido1Titular: '',
                apellido2Titular: '',
                desProvinciaTitular: '',
                iban: '',
                idTipoContrato: '',
                idRepresentante: '',
                datosContrato: '',
                CNAE: '',
                fechaInicioContrato: ''


            },
            toggledClearRows: !this.state.toggledClearRows

        });

        this.formRef.current.reset();

    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    buscarContrato = async () => {
        console.log('VALOR DE this.state.companyIdUser', this.state.companyIdUser)
        console.log('valor de this.state.userId ', this.state.userId)
        //
        //

        var existeRepre = ''

        console.log('VALOR DE this.state.companyIdUser en buscarContrato ', this.state)
        const companyIdAux = this.state.companyIdUser.toString();
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = ""
        }

        var dataFilters = {
            'compania': companyIdAux,
            'cups': this.state.filters.cupsSearch,
            'Usuario': userQuery,
            'NIF': this.state.filters.cifSearch,
            'idContrato': this.state.filters.contratoSearch
        };
        var contratoDato: any = []

        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/listado?id=" + companyIdAux + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados en buscarContrato')
                console.log(result.data.rows)
                if (result.data.rows.length > 0) {
                    this.setState({ contratoDetailTotal: result.data.rows });
                } else {
                    this.warningAlertDBdata()
                }



            }).catch((error) => {
                console.log('Failure')
                this.warningAlert();
            });

    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    };

    showTableEstados = () => {
        if (this.state.showEstados === false) {
            this.setState({
                showEstados: true
            });

            const reftpEstados = document.getElementById("tablaEstados")
            console.log('VALORES DE REFTP', reftpEstados)
            reftpEstados ?.scrollIntoView({ behavior: "smooth" })

            } else {

            this.setState({
                showEstados: false
            });
        }
    };


    showTableFacturas = () => {
        if (this.state.showFacturas === false) {
            this.setState({
                showFacturas: true
            });

            const reftpfacturas = document.getElementById("tablaFacturas")
            console.log('VALORES DE REFTP', reftpfacturas)
            reftpfacturas ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                showFacturas: false
            });
        }
    }



    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    }


    showModal() {
        this.setState({
            openModalComparative: true,
        });
    }


    openModalComparativa = async (event) => {
        //if (this.state.itemsSelected.length) {
        this.showModal();
        return;
        //return;
        //}
        //alert("Seleccione consumos para realizar la comparativa");
    };



    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const id = state.target.id;
        const file = state.target.value

        console.log(state)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/download?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato + "&file=" + file;

        console.log(direccionFileList)
        axios.get(direccionFileList,
            {
                headers,
                responseType: 'blob'
            }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch(function() {
                console.log('Failure')
            });
    };


    ////////////////////////////////////////////////////////////////////////////////

    approvedHandler(props) {

        this.confirmAlertSave();

    };


    contratoHandler(props) {

        //console.log('VALORES DE LA SELECCION DE FILAS', props);
        //this.onSave(props)
        this.confirmAlertSavePDF();

    };

    /////////////////////////////////////////////////////////////////////////////////

    XMLapprovedHandler(props) {

        var parser = new DOMParser();
        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
        var partes = currentDate.slice(0).split('T');
        const now = new Date().toISOString();
        var dateXML = partes[0]
        var hourXML = partes[1]
        console.log('FECHA DE ACTIVACION EN XML ------> ', this.state.fechaActivacion)
        var reqtransferdate = moment(this.state.fechaActivacion).utc().format('YYYY-MM-DD')
        console.log('FECHA DE ACTIVACION EN XML reqtransferdate ------> ', reqtransferdate)
        var tipoID = spainIdType(this.state.CIF)
        var tipoPersona = ''
        var tipoDocumento = '1'
        if (tipoID == 'dni') {
            tipoPersona = 'F'
            tipoDocumento = '01'
        } else if (tipoID == 'cif') {
            tipoPersona = 'J'
            tipoDocumento = '02'
        } else if (tipoID == 'nie') {
            tipoPersona = 'J'
            tipoDocumento = '04'
        }


        var xml = "<?xml version=\"1.0\" encoding=\"ISO-8859-1\"?>";
        xml = xml + "<sctdapplication xmlns=\"http://localhost/sctd/A102\">";
        xml = xml + "<heading>"
        xml = xml + "<dispatchingcode>GML</dispatchingcode>"
        xml = xml + "<dispatchingcompany>0740</dispatchingcompany>"
        xml = xml + "<destinycompany>" + this.state.idComercializadoraActualContrato + "</destinycompany>"
        xml = xml + "<communicationsdate>" + dateXML + "</communicationsdate>"
        xml = xml + "<communicationshour>" + hourXML + "</communicationshour>"
        xml = xml + "<processcode>02</processcode>"
        xml = xml + "<messagetype>A1</messagetype>"
        xml = xml + "</heading>"
        xml = xml + "<a102>"
        xml = xml + "<comreferencenum>" + this.state.contrato.slice(-12) + "</comreferencenum>"
        xml = xml + "<reqdate>" + dateXML + "</reqdate>"
        xml = xml + "<reqhour>" + hourXML + "</reqhour>"
        xml = xml + "<titulartype>" + tipoPersona + "</titulartype>"
        xml = xml + "<nationality>ES</nationality>"
        xml = xml + "<documenttype>" + tipoDocumento + "</documenttype>"
        xml = xml + "<documentnum>" + this.state.CIF + "</documentnum>"
        xml = xml + "<cups>" + this.state.cups + "</cups>"
        xml = xml + "<reqestimatedqa>" + this.state.consumoAnualCup + "</reqestimatedqa>"
        xml = xml + "<modeffectdate>04</modeffectdate>"
        xml = xml + "<reqtransferdate>" + reqtransferdate + "</reqtransferdate>"
        xml = xml + "<disconnectedserviceaccepted>N</disconnectedserviceaccepted>"
        xml = xml + "</a102>"
        xml = xml + "</sctdapplication>"




        console.log('XML EN BRUTO')
        console.log(xml)
        var xmlDoc = parser.parseFromString(xml, "application/xml");

        console.log('VALOR DE XML')
        console.log(xmlDoc)
        var formattedXml = formato(xml);
        var formattedXml2 = formato(xml, {
            indentation: '        ',
            collapseContent: true,
            lineSeparator: '\n'
        });

        var blob = new Blob([formattedXml2], { type: "text/xml" });

        saveAs(blob, this.state.contrato + "_" + currentDate + ".xml");


    }

    //////////////////////////////////////////////////////////////////////////////////////////////////

    onEstado = async () => {

        let formData = { ...this.state.T_General };

        const now = new Date().toISOString();

        var fechaEstado = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaEstado, "YYYY-MM-DD"));
        var monthEstado = Number(check.format('M'));
        var yearEstado = check.year();

        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

        var partes = currentDate.slice(0).split('T');


        var date = partes[0]
        var hour = partes[1]

        if(this.state.T_SeguimientoAux.idAccion == '90'){

                formData['estado'] = 'BAJA'
                formData['estadoId'] = '90'
                }

                if(this.state.T_SeguimientoAux.idAccion == '66'){

                        formData['estado'] = 'FIRMA CONTRATO'
                        formData['estadoId'] = '66'
                        }
                        if(this.state.T_SeguimientoAux.idAccion == '70'){

                                formData['estado'] = 'FALTA DOCUMENTACION'
                                formData['estadoId'] = '70'
                                }

                                if(this.state.T_SeguimientoAux.idAccion == '85'){

                                        formData['estado'] = 'INICIO SERVICIO'
                                        formData['estadoId'] = '85'
                                        }



            var estado = {

                    "usuario": this.state.userId,
                    "nombreUsuario": this.state.userName,
                    "compania": this.state.companyIdUser,
                    "fecha": date,
                    "hora": hour,
                    "mes": monthEstado,
                    "anio": yearEstado,
                    "accion": this.state.T_SeguimientoAux.accion,
                    "idAccion":  this.state.T_SeguimientoAux.idAccion,
                    "observacion": this.state.T_SeguimientoAux.observacion

                }

                this.setState({
                     T_General:formData,
                     T_Seguimiento: [...this.state.T_Seguimiento, estado],
                    saveProductButtonDisabled: false,
                    saveButtonDisabledEstado: true
                 });


    };

/////////////////////////////////////////////////////////////////////////////////

    deleteRepresentante = async (event) => {
        this.confirmAlert();
    }

    borrar = async () => {

        this.setState({
            T_Representante: {
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                NIFRepresentante: "",
                TipoTitularRepresentante: "",
                idRepresentante: "",
                idRepresentanteInterno: 0,
                CargoRepresentante: "",
                telefonoRepresentante: "",
                correoRepresentante: "",
                codigoPostalRepresentante: "",
                AutonomiaRepresentante: "",
                MunicipioRepresentante: "",
                ProvinciaRepresentante: "",
                escaleraRepresentante: "",
                numFincaRepresentante: "",
                pisoRepresentante: "",
                portalRepresentante: "",
                puertaRepresentante: "",
                tipoViaRepresentante: "",
                viaRepresentante: ""
            },
            saveProductButtonDisabled: false
        });
        this.confirmedAlert()

    };



    ////////////////////////////////////////////////////////////////////////////////


    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var contrato = '';
        var cups = '';
        var tipoContrato = "L"
        var contratoDato: any = []
        const companyIdAux = this.state.companyIdUser.toString();
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = ""
        }

        if (state.selectedCount === 1) {
            var existeRepre = 0
            contrato = state.selectedRows[0].idContrato;
            tipoContrato = state.selectedRows[0].idTipoContrato
            cups = state.selectedRows[0].cups
            console.log(contrato)
            contratoDato = state.selectedRows[0];
            let contratoDatosGenerales = { ...state.selectedRows[0] }
            delete contratoDatosGenerales.T_Titular
            delete contratoDatosGenerales.T_Representante
            delete contratoDatosGenerales.T_PuntoSuministro
            delete contratoDatosGenerales.T_CUPs
            delete contratoDatosGenerales.T_PropuestaComercial
            delete contratoDatosGenerales.T_DatosAgenteComercial
            delete contratoDatosGenerales.contratoDataGeneralElectricidad
            delete contratoDatosGenerales.contratoDataGeneralGas
            //delete contratoDatosGenerales.T_Propuesta
            delete contratoDatosGenerales.T_Seguimiento

            var estadoDisabledButton =  false
            if(contratoDato.estadoId == '90'){
                    estadoDisabledButton = true
                }

                        this.setState({
                            tipoContrato : tipoContrato,
                            T_Titular: state.selectedRows[0].T_Titular,
                            T_General: contratoDatosGenerales,
                            T_Representante: state.selectedRows[0].T_Representante,
                            T_PuntoSuministro: state.selectedRows[0].T_PuntoSuministro,
                            T_CUPs: state.selectedRows[0].T_CUPs,
                            T_PropuestaComercial: state.selectedRows[0].T_PropuestaComercial,
                            T_DatosAgenteComercial: state.selectedRows[0].T_DatosAgenteComercial,
                            contratoDataGeneralElectricidad: state.selectedRows[0].contratoDataGeneralElectricidad,
                            contratoDataGeneralGas: state.selectedRows[0].contratoDataGeneralGas,
                            T_Seguimiento: state.selectedRows[0].T_Seguimiento,
                            estadoDisabled :estadoDisabledButton
                            //propuestaData: propuestaDataAux
                        })

                        console.log('result.data.rows[0] en contratoDato --->', contratoDatosGenerales)

                        let fechaFirmaContratoAux = ""
                        let fechaActivacionAux = ""
                        let fechaVencimientoContratoAux = ""

                        if (contratoDato.fechaFirmaContrato) {
                            fechaFirmaContratoAux = contratoDato.fechaFirmaContrato
                        }

                        if (contratoDato.fechaActivacion) {

                            fechaActivacionAux = contratoDato.fechaActivacion
                        }

                        if (contratoDato.fechaVencimientoContrato) {
                            fechaVencimientoContratoAux = contratoDato.fechaVencimientoContrato
                            //let existefechatest = moment(moment(new Date(result.data.contratoInfoById[0].fechaVencimientoContrato.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))).set("hour", hora).set("minute", minutos)).toISOString();
                        }

                        let idComercializadoraActualContrato = "";
                        let nombreComercializadoraActualContrato = ""

                        if (contratoDato.T_PropuestaComercial.hasOwnProperty('idComercializadoraProcedencia')) {
                            idComercializadoraActualContrato = contratoDato.T_PropuestaComercial.idComercializadoraProcedencia
                            nombreComercializadoraActualContrato = contratoDato.T_PropuestaComercial.ComercializadoraProcedencia
                        };

                        this.setState({
                            idComercializadoraActualContrato: idComercializadoraActualContrato,
                            nombreComercializadoraActualContrato: nombreComercializadoraActualContrato,
                            CIF: contratoDato.T_Titular.NIF,
                            cups: contratoDato.cups,
                            fechaFirmaContrato: fechaFirmaContratoAux,
                            fechaVencimientoContrato: fechaVencimientoContratoAux,
                            fechaActivacion: fechaActivacionAux,
                            idRepresentante: contratoDato.T_Representante.idRepresentante,
                            consumoAnualCup: contratoDato.T_CUPs.Consumo
                        });

                        if ((typeof (fechaFirmaContratoAux) != 'undefined' && fechaFirmaContratoAux != null && fechaFirmaContratoAux != '') && (typeof (fechaActivacionAux) != 'undefined' && fechaActivacionAux != null && fechaActivacionAux != '')) {
                            this.setState({
                                saveXMLButtonDisabled: false,
                                inputDisabled: false,
                                contratoButtonDisabled: false
                            })
                        } else {
                            this.setState({
                                saveXMLButtonDisabled: true,
                                inputDisabled: true,
                                contratoButtonDisabled: true
                            })

                        };

                        if (typeof (fechaFirmaContratoAux) != 'undefined' && fechaFirmaContratoAux != null && fechaFirmaContratoAux != '') {
                            this.setState({ inputDisabled: true })
                        } else {
                            this.setState({ inputDisabled: false })
                        }

                        existeRepre = contratoDato.T_Representante.idRepresentanteInterno
                        this.setState({ isLoadingSips: false });

                        console.log('existeRepre ', existeRepre)
                        console.log('fechaFirmaContratoAux ', fechaFirmaContratoAux)
                        if (existeRepre != 0 && !fechaFirmaContratoAux) {


                            var dataFiltersRepre = {
                                'compania': "",
                                'cups': "",
                                'Usuario': userQuery,
                                'NIF': "",
                                'idContrato': "",
                                'NIFRepresentante': contratoDato.T_Representante.NIFRepresentante
                            };
                            var contratoDato: any = []

                            let valores = JSON.stringify(dataFiltersRepre)

                            let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdAux + "&user=" + userIdAux;

                            axios.get(direccionFile, { headers, params: { valores } }).
                                then(result => {
                                    console.log('resultado del listados')
                                    console.log(result.data.rows)

                                    this.setState({
                                        idRepresentante: result.data.rows[0].idRepresentanteInterno,
                                        T_Representante: result.data.rows[0]
                                    });

                                }).catch((error) => {
                                    console.log('Failure')
                                    this.warningAlert()
                                });
                        }


                        let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;
                        axios.get(direccionFileList, { headers }).
                            then(result => {
                                console.log('resultado del listados')
                                console.log(result)
                                this.setState({ filesContrato: result.data.Contents})
                            }).catch(function() {
                                console.log('Failure')
                            });

            console.log('VALOR DE THIS STATE EN FETCH LECTURAS !!!!!!!!!!------->', this.state)

            this.fetchLecturas();



            //let direccionFile = "https://datapi.psgestion.es/upload?id="+this.state.companyIdUser+"&contrato="+this.state.contrato;
            let direccionFacturaList = "https://datapi.psgestion.es/facturas/creadas/cups?id=" + this.state.companyIdUser + "&cups=" + cups;
            //console.log(direccionFile)

            axios.get(direccionFacturaList, { headers }).
                then(result => {
                    console.log('resultado del listados')
                    console.log(result)
                    this.setState({ facturasContrato: result.data })
                }).catch(function() {
                    console.log('Failure')
                });






        }





        // eslint-disable-next-line react/no-unused-state
        /*this.setState({ selectedRows: state.selectedRows });
        if(state.selectedRows.length > 0){
          this.setState({selectProductButtonDisabled:false})
        }else{
          this.setState({selectProductButtonDisabled:true})
        }
*/
    };


    ////////////////////// Función para subir Ficheros /////////////////////////

    onSubmit(e) {
        e.preventDefault()

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }


        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/uploadDocS3?id=" + this.state.companyIdUser + "&contrato=" + this.state.contratoDetail.idContrato;

        let direccionFileb = "http://78.47.44.246:4000/api/upload-images";
        //let direccionFileList = "https://datapi.psgestion.es/files?id="+this.state.companyIdUser;
        let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contratoDetail.idContrato;

        console.log(direccionFile)
        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            //console.log('resultado de la subida')
            //console.log (result);
            this.setState({
                filesConvert: result.data.Contents,
                filesContrato: result.data.Contents
            })
            this.setState({ imgCollection: [] })
            this.setState({ saveButtonDisabled: true })
            this.inputEl.current.value = ''
        })
    };

    //////////////////////////////////

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        //this.setState({ imgCollection: e.target.files })
        //console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

        let tamañoFiles = e.target.files.length;
        //console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }

    };

    ///////////////////////////////////////////////


    representanteSeleccionado = async (event) => {

        this.setState({
            representanteSeleccionado: event.representante,
            idRepresentante: event.representante.idRepresentante,
            T_Representante: event.representante,
            saveDataRepre: true,
            saveProductButtonDisabled: false,
        });
    };

    ////////////////////////////////////////////////////



    showFile = (blob) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "contratoGasCalordom.pdf";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }





    render() {


        const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
        const disableExportClass = disableExport ? " disabled" : "";
        const form: any = this.state.contratoDetail;

        const formDetalle: any = this.state.contratoDetailTotal;

        const form_T_General: any = this.state.T_General;
        const form_T_Titular: any = this.state.T_Titular;
        const form_T_Representante: any = this.state.T_Representante;
        const form_T_PuntoSuministro: any = this.state.T_PuntoSuministro;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_DatosAgenteComercial: any = this.state.T_DatosAgenteComercial;;
        const form_contratoDataGeneralElectricidad: any = this.state.contratoDataGeneralElectricidad;
        const form_contratoDataGeneralGas: any = this.state.contratoDataGeneralGas;




        const formRepre: any = this.state.T_Representante;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        console.log('VALOR DE FORM ---------> ', form)

        const fechaActivacion = this.state.fechaActivacion
        const fechaFirmaContrato = this.state.fechaFirmaContrato
        const fechaVencimientoContrato = this.state.fechaVencimientoContrato


        var textoTipoContrato = '';
        if (this.state.tipoContrato == 'G') {
            textoTipoContrato = 'Gas';
        } else {
            textoTipoContrato = 'Eléctrico';
        }



        let freeModal = <></>;


        var iconAtencion;

        if (this.state.T_PropuestaComercial.nombreServicio) {
            iconAtencion = this.state.T_PropuestaComercial.nombreServicio
        }
        else {
            iconAtencion = "NO"
        }

        var fechaFirmaContratoFormateo = ''

        if (!this.state.T_General.fechaFirmaContrato) {
            fechaFirmaContratoFormateo = "SIN DATOS"

        } else {
            fechaFirmaContratoFormateo = moment(this.state.T_General.fechaFirmaContrato).format("DD-MM-YYYY")
        }


        var fechaVencimientoContratoFormateo = ''

        if (!this.state.T_General.fechaVencimientoContrato) {
            fechaVencimientoContratoFormateo = "SIN DATOS"

        } else {
            fechaVencimientoContratoFormateo = moment(this.state.T_General.fechaVencimientoContrato).format("DD-MM-YYYY")
        }

        if (this.state.openModalComparative == true) {

            freeModal = (
                <ContratoRepresentanteFreeModal
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    cifEmpresa={this.state.contratoDetail.CIF}
                    companyId={this.state.companyIdUser}
                    representante={this.representanteSeleccionado}


                ></ContratoRepresentanteFreeModal>
            );
        }


    return (
            <>
                <Header title={'Información Contrato'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cupsSearch">
                                                    CUPS CONTRATO
                                                  </Label>
                                                <Input
                                                    id="cupsSearch"
                                                    placeholder="Ingresar cups del contrato"
                                                    name="cupsSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cupsSearch}
                                                    type="text"
                                                    disabled={this.state.inputCupsDisabled}
                                                />
                                                {this.state.cupsError ? <FormText color="red">Comprobar CUPS</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="contratoSearch">
                                                    CONTRATO
                                                  </Label>
                                                <Input
                                                    id="contratoSearch"
                                                    placeholder="Ingresar contrato del cliente"
                                                    name="contratoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.contratoSearch}
                                                    type="text"
                                                    disabled={this.state.inputContratoDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifSearch">
                                                    DNI/CIF
                                                  </Label>
                                                <Input
                                                    id="cifSearch"
                                                    placeholder="Ingresar contrato del cliente"
                                                    name="cifSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifSearch}
                                                    type="text"
                                                    disabled={this.state.inputCifDisabled}
                                                />
                                                {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}

                                            </FormGroup>
                                        </Col>
                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                                </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarContrato}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                                </Button>
                                        </Col>

                                    </Row>
                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE CONTRATOS"
                                            columns={columnsDatosContrato}
                                            data={this.state.contratoDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'Contrato', plural: 'Contratos', message: 'seleccionado' }}
                                            subHeader
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>



                    {this.state.alert}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                                                </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>


                                                    <Button disabled={this.state.saveProductButtonDisabled} id="guardarProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.approvedHandler(this.state.saveData) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="fas fa-save fa-2x" style={{ fontSize: 35 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text"> Guardar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="guardarProptooltip">
                                                            GUARDAR DATOS DE CONTRATO ACTUAL
                                                                        </UncontrolledTooltip>
                                                    </Button>

                                                    <Button disabled={this.state.contratoButtonDisabled} id="gcontratoProptooltip" color="success" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.contratoHandler(this.state.saveData) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="fas fa-file-contract fa-2x" style={{ fontSize: 35 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Contrato</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="gcontratoProptooltip">
                                                            GENERAR CONTRATO
                                                                                    </UncontrolledTooltip>
                                                    </Button>
                                                </FormGroup>


                                            </Card>
                                        </Col>


                                        <this.tipoLectura tipoContrato={this.state.tipoContrato} />

                                    </Row>


                                </CardBody>
                            </Card>

                            <Row className="margin-reset w-100">


                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        TARIFA
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {this.state.T_PropuestaComercial.Tarifa}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="bi bi-clock-history" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className="text-nowrap">Tarifa ATR</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        SERVICIOS
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {iconAtencion}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                        <i className="bi bi-headset" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Con servicios adicionales</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        FIRMA CONTRATO
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {fechaFirmaContratoFormateo}

                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                        <i className="bi bi-calendar-event" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Fecha de firma contrato</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>


                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        VENCIMIENTO CONTRATO
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">

                                                        {fechaVencimientoContratoFormateo}

                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="bi bi-calendar-event" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Fecha de vencimiento contrato</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>

                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>DATOS DEL TITULAR DEL SUMINISTRO</CardTitle>
                                    </div>

                                    <LoadingOverlay
                                        active={this.state.isLoadingSips}
                                        spinner
                                        text="Cargando..."
                                    >
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="NombreTitular">
                                                        Nombre Titular
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="NombreTitular"
                                                        placeholder=""
                                                        name="NombreTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NombreTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Apellido1Titular">
                                                        Primer Apellido Titular
                                                                                    </Label>
                                                    <Input
                                                        id="Apellido1Titular"
                                                        placeholder=""
                                                        name="Apellido1Titular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.Apellido1Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Apellido2Titular">
                                                        Segundo Apellido Titular
                                                                                    </Label>
                                                    <Input
                                                        id="Apellido2Titular"
                                                        placeholder=""
                                                        name="Apellido2Titular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.Apellido2Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="TipoTitular">
                                                        TIPO
                                                                                    </Label>
                                                    <Input
                                                        id="TipoTitular"
                                                        placeholder=""
                                                        name="TipoTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.TipoTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIF">
                                                        NIF/CIF
                                                                                    </Label>
                                                    <Input
                                                        id="NIF"
                                                        placeholder=""
                                                        name="NIF"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NIF}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <div>
                                            <CardTitle>DATOS DEL REPRESENTANTE</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                        Nombre Representante
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreRepresentante"
                                                        placeholder=""
                                                        name="nombreRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.nombreRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Representante">
                                                        Primer Apellido
                                                                                    </Label>
                                                    <Input
                                                        id="apellido1Representante"
                                                        placeholder=""
                                                        name="apellido1Representante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.apellido1Representante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido2Representante">
                                                        Segundo Apellido
                                                                                    </Label>
                                                    <Input
                                                        id="apellido2Representante"
                                                        placeholder=""
                                                        name="apellido2Representante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.apellido2Representante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                        NIF/CIF
                                                                                    </Label>
                                                    <Input
                                                        id="NIFRepresentante"
                                                        placeholder=""
                                                        name="NIFRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.NIFRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.CIFRepreError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>


                                            <Col md="1" sm="1" lg="1" className="align-self-center">

                                                <Button
                                                    id="UpdateRepreProptooltip"
                                                    className="mx-auto align-self-center mt-2"
                                                    position="center"
                                                    margin
                                                    onClick={this.openModalComparativa}
                                                    color="primary"
                                                    type="button"
                                                    disabled={this.state.inputDisabled}
                                                >
                                                    <i className={"bi bi-person-plus"} style={{fontSize: 15}}/>
                                                <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                        AÑADIR REPRESENTANTE AL CONTRATO
                                                </UncontrolledTooltip>
                                                </Button>

                                            </Col>


                                            <Col md="1" sm="1" lg="1"  className="align-self-center">

                                                <Button
                                                    id="deleteRepreProptooltip"
                                                    className="mx-auto align-self-center mt-2 bi bi-person-x"
                                                    position="center"
                                                    margin
                                                    onClick={this.deleteRepresentante}
                                                    color="danger"
                                                    type="button"
                                                    disabled={this.state.inputDisabled}
                                                    style={{fontSize: 15}}
                                                >

                                                <UncontrolledTooltip delay={0} placement="top" target="deleteRepreProptooltip">
                                                        BORRAR REPRESENTANTE DEL CONTRATO
                                                </UncontrolledTooltip>
                                                </Button>

                                            </Col>


                                        </Row>
                                        <Row>

                                            <Col md="2" sm="2" lg="2" >
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="CargoRepresentante">
                                                        Cargo
                                                                                    </Label>
                                                    <Input
                                                        id="CargoRepresentante"
                                                        placeholder=""
                                                        name="CargoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.CargoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefonoRepresentante">
                                                        Teléfono Contacto
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoRepresentante"
                                                        placeholder=""
                                                        name="telefonoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.telefonoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="correoRepresentante">
                                                        Correo Contacto
                                                                                    </Label>
                                                    <Input
                                                        id="correoRepresentante"
                                                        placeholder=""
                                                        name="correoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.correoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="IdOferta">
                                                        ID. Oferta
                                                                                    </Label>
                                                    <Input
                                                        id="IdOferta"
                                                        placeholder=""
                                                        name="IdOferta"
                                                        onChange={this.onChangeField}
                                                        value={form_T_General.IdOferta}
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                        Fecha Propuesta
                                                                                    </Label>
                                                    <Input
                                                        id="fechaPropuesta"
                                                        placeholder=""
                                                        name="fechaPropuesta"
                                                        onChange={this.onChangeField}
                                                        value={form_T_General.fechaPropuesta}
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="TipoViaTitular">
                                                        Tipo Via
                                                                                    </Label>
                                                    <Input
                                                        id="TipoViaTitular"
                                                        placeholder=""
                                                        name="TipoViaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.TipoViaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="ViaTitular">
                                                        Dirección Titular
                                                                                    </Label>
                                                    <Input
                                                        id="ViaTitular"
                                                        placeholder=""
                                                        name="ViaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.ViaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="NumFincaTitular">
                                                        Nº Finca
                                                                                    </Label>
                                                    <Input
                                                        id="NumFincaTitular"
                                                        placeholder=""
                                                        name="NumFincaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NumFincaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PortalTitular">
                                                        Portal Tit.
                                                                                    </Label>
                                                    <Input
                                                        id="PortalTitular"
                                                        placeholder=""
                                                        name="PortalTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PortalTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="EscaleraTitular">
                                                        ESC.
                                                                                    </Label>
                                                    <Input
                                                        id="EscaleraTitular"
                                                        placeholder=""
                                                        name="EscaleraTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.EscaleraTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PisoTitular">
                                                        Piso
                                                                                    </Label>
                                                    <Input
                                                        id="PisoTitular"
                                                        placeholder=""
                                                        name="PisoTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PisoTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PuertaTitular">
                                                        Puerta
                                                                                    </Label>
                                                    <Input
                                                        id="PuertaTitular"
                                                        placeholder=""
                                                        name="PuertaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PuertaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                                                        C.P. Titular
                                                                                    </Label>
                                                    <Input
                                                        id="CodigoPostalTitular"
                                                        placehclder=""
                                                        name="CodigoPostalTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.CodigoPostalTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Municipio:
                                                                                    </Label>
                                                    <Input
                                                        placeholder=""
                                                        //                     invalid={forceUseLocality}
                                                        id="MunicipioTitular"
                                                        name="MunicipioTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.MunicipioTitular}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="ProvinciaTitular">
                                                        Provincia
                                                                                    </Label>
                                                    <Input
                                                        id="ProvinciaTitular"
                                                        placeholder=""
                                                        name="ProvinciaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.ProvinciaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="AutonomiaTitular">
                                                        C. Autónoma
                                                                                    </Label>
                                                    <Input
                                                        id="AutonomiaTitular"
                                                        placeholder=""
                                                        name="AutonomiaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.AutonomiaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                    </LoadingOverlay>
                                </CardBody>
                            </Card>
                            {this.state.alert}
                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>DATOS DEL PUNTO DE SUMINISTRO</CardTitle>

                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TipoViaPS">
                                                    Tipo Via
                                                                                </Label>
                                                <Input
                                                    id="TipoViaPS"
                                                    placeholder=""
                                                    name="TipoViaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.TipoViaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="ViaPS">
                                                    Dirección Suministro
                                                                                </Label>
                                                <Input
                                                    id="ViaPS"
                                                    placeholder=""
                                                    name="ViaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.ViaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="NumFincaPS">
                                                    Nº Finca
                                                                                </Label>
                                                <Input
                                                    id="NumFincaPS"
                                                    placeholder=""
                                                    name="NumFincaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NumFincaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PortalPS">
                                                    Portal
                                                                                </Label>
                                                <Input
                                                    id="PortalPS"
                                                    placeholder=""
                                                    name="PortalPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PortalPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="EscaleraPS">
                                                    ESC.
                                                                                </Label>
                                                <Input
                                                    id="EscaleraPS"
                                                    placeholder=""
                                                    name="EscaleraPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.EscaleraPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PisoPS">
                                                    Piso
                                                                                </Label>
                                                <Input
                                                    id="PisoPS"
                                                    placeholder=""
                                                    name="PisoPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PisoPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PuertaPS">
                                                    Puerta
                                                                                </Label>
                                                <Input
                                                    id="PuertaPS"
                                                    placeholder=""
                                                    name="PuertaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PuertaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="CodigoPostalPS">
                                                    C.P.
                                                                                </Label>
                                                <Input
                                                    id="CodigoPostalPS"
                                                    placeholder=""
                                                    name="CodigoPostalPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.CodigoPostalPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label">
                                                    Localidad:
                                                                                </Label>
                                                <Input
                                                    placeholder=""
                                                    //                     invalid={forceUseLocality}
                                                    id="NombreMunicipioPS"
                                                    name="NombreMunicipioPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NombreMunicipioPS}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NombreProvinciaPS">
                                                    Provincia
                                                                                </Label>
                                                <Input
                                                    id="NombreProvinciaPS"
                                                    placeholder=""
                                                    name="NombreProvinciaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NombreProvinciaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="AutonomiaPS">
                                                    C. Autónoma
                                                                                </Label>
                                                <Input
                                                    id="AutonomiaPS"
                                                    placeholder=""
                                                    name="AutonomiaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.AutonomiaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <this.tipoCup tipoCup={this.state.tipoContrato} />
                                    <Row>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaContrato">
                                                    Entrada Contrato
                                                                                </Label>
                                                <Input
                                                    id="fechaContrato"
                                                    placeholder=""
                                                    name="fechaContrato"
                                                    onChange={this.onChangeField}
                                                    value={moment(form_T_General.fechaContrato).format("DD-MM-YYYY")}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaFirmaContrato">
                                                    Firma Contrato
                                                                                </Label>
                                                <DatePicker id="fechaFirmaContrato"
                                                    value={fechaFirmaContrato}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "fechaFirmaContrato")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaVencimientoContrato">
                                                    Vencimiento Contrato
                                                                                </Label>
                                                <DatePicker id="fechaVencimientoContrato"
                                                    value={fechaVencimientoContrato}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "fechaVencimientoContrato")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaActivacion">
                                                    Activación Contrato
                                                                                </Label>
                                                <DatePicker id="fechaActivacion"
                                                    value={fechaActivacion}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "fechaActivacion")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Comercializadora">
                                                    Comercializadora
                                                                                </Label>
                                                <Input
                                                    id="Comercializadora"
                                                    placeholder=""
                                                    name="Comercializadora"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.Comercializadora}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Producto">
                                                    Producto
                                                                                </Label>
                                                <Input
                                                    id="Producto"
                                                    placeholder=""
                                                    name="Producto"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.Producto}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TipoPrecio">
                                                    Precio
                                                                                </Label>
                                                <Input
                                                    id="TipoPrecio"
                                                    placeholder=""
                                                    name="TipoPrecio"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.TipoPrecio}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreServicio">
                                                    Servicio Adicional
                                                                                </Label>
                                                <Input
                                                    id="nombreServicio"
                                                    placeholder=""
                                                    name="nombreServicio"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.nombreServicio}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="GastoOperativo">
                                                    CO En. c€/kw
                                                                                </Label>
                                                <Input
                                                    id="GastoOperativo"
                                                    placeholder=""
                                                    name="GastoOperativo"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.GastoOperativo}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="GastoOperativoPotencia">
                                                    CO Pot. c€/kw
                                                                                </Label>
                                                <Input
                                                    id="GastoOperativoPotencia"
                                                    placeholder=""
                                                    name="GastoOperativoPotencia"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.GastoOperativoPotencia}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                    <Row>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TelefonoPS">
                                                    Teléfono Contacto PS
                                                                    </Label>
                                                <Input
                                                    id="TelefonoPS"
                                                    placeholder=""
                                                    name="TelefonoPS"
                                                    onChange={this.onChangeField}
                                                    value={form.TelefonoPS}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <div id="tablaLecturas">
                                        {
                                            this.state.show &&

                                            <DataTable
                                                title="Lecturas y Facturas recibidas"
                                                columns={columnsFilesLecturas(this.handleButtonClick)}
                                                data={this.state.LecturasContrato}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight

                                                customStyles={customStyles}

                                                subHeader
                                                subHeaderComponent={
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <CSVLink
                                                            data={this.state.LecturasContrato}
                                                            filename={"FicherosLecturas.csv"}
                                                            className="btn btn-default btn-sm btn-circle"
                                                            color="default"
                                                            target="_blank"
                                                            separator={";"}
                                                        >

                                                            <span className="btn-inner--icon">
                                                                <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Fichero CSV</span>

                                                        </CSVLink>
                                                    </div>

                                                }

                                            />

                                        }

                                    </div>

                                </CardBody>
                            </Card>
                            <div id="tablaEstados">
                                {
                                    this.state.showEstados &&
                            <React.Fragment>
                            <Card className="mt-3 shadow">
                            <CardBody>
                            <CardTitle>ESTADOS DEL PUNTO DE SUMINISTRO</CardTitle>
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label className=" form-control-label text-primary" htmlFor="estado">
                                                Estado
                                            </Label>
                                            <SipsOption
                                                className="text-primary"
                                                placeholder="Seleccionar estado"
                                                id="estado"
                                                name="estado"
                                                query="LIST_ESTADOS_CONTRATOS"
                                                onChange={this.onChangeFieldEstado}
                                                value={this.state.estadoIdAux}
                                                disabled={this.state.estadoDisabled}
                                            ></SipsOption>
                                        </FormGroup>
                                    </Col>
                                    <Col md="5">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="observacion">
                                                Observación Estado
                                                                            </Label>
                                            <Input
                                                id="observacion"
                                                placeholder=""
                                                name="observacion"
                                                onChange={this.onChangeFieldEstado}
                                                value={this.state.T_SeguimientoAux.observacion}
                                                type="text"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md="1" sm="1" lg="1" className="align-self-center" >

                                        <Button
                                            id="insertEstado"
                                            color ="primary"
                                            size="m"
                                            className="mx-auto align-self-center mt-2"
                                            position="center"
                                            margin
                                            onClick={this.onEstado}
                                            type="button"
                                            disabled={this.state.saveButtonDisabledEstado}
                                        >
                                            <i className={"bi bi-plus-circle"} />
                                        <UncontrolledTooltip delay={0} placement="top" target="insertEstado">
                                                AÑADIR ESTADO AL CONTRATO
                                        </UncontrolledTooltip>
                                        </Button>

                                    </Col>

                                </Row>


                                    <DataTable
                                        columns={columnsEstados(this.handleButtonClick)}
                                        data={this.state.T_Seguimiento}
                                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                        highlightOnHover
                                        striped
                                        pointerOnHover
                                        selectableRowsHighlight
                                        customStyles={customStyles}
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <CSVLink
                                                    data={this.state.LecturasContrato}
                                                    filename={"FicherosLecturas.csv"}
                                                    className="btn btn-default btn-sm btn-circle"
                                                    color="default"
                                                    target="_blank"
                                                    separator={";"}
                                                >

                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Fichero CSV</span>

                                                </CSVLink>
                                            </div>

                                        }

                                    />
                                </CardBody>
                            </Card>
                            </React.Fragment>
                                }

                            </div>


                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>TARIFAS DEL CONTRATO</CardTitle>

<this.tipoPrecioTarifa tipoCup={this.state.tipoContrato} />

                            </CardBody>
                            </Card>

                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>DOCUMENTOS ASOCIADOS AL CONTRATO DE SUMINISTRO</CardTitle>

                                    <Row>

                                        <Col md="12" lg="5">
                                            <Form onSubmit={this.onSubmit}>

                                                <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl} multiple />

                                                <button disabled={this.state.saveButtonDisabled} id="gpsProptooltip" type="submit" color="#2dce89" className="btn btn-primary btn-sm btn-circle" >
                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Subir Ficheros</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                        SUBIR FICHEROS AL SERVIDOR
                                                                 </UncontrolledTooltip>
                                                </button>
                                            </Form>
                                        </Col>
                                    </Row>

                                    <FormText color="muted">
                                        Seleccionar los fichero para subir al servidor
                                                                        </FormText>


                                    <Row id="contratos_resumen" className="card-deck mt-2">
                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE DOCUMENTOS"
                                                columns={columnsFiles(this.handleButtonClick)}
                                                data={this.state.filesContrato}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight

                                                customStyles={customStyles}

                                            />
                                        </Card>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
                {freeModal}

            </>
        );
    }
}


export default withApollo(Contrato);

import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from 'react-router-dom';

import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
//import { uuid } from 'uuidv4';
import uuid from "react-uuid";
import { Chart,
  Interval,
  Tooltip,
  Axis,
  Coordinate,
  registerTheme,
  Geom,
  Interaction} from 'bizcharts';
  import DataSet from "@antv/data-set";

// core components
import Header from "../../components/Headers/Header";
import SipsGasFilter from "../../components/SipsGas/SipsFilterMultiGas";
//import SipComparativeFreeModalMultips from "./SipComparativeFreeModalMultips";
import SaveGasModalMultips from "./SaveGasModalMultips";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/Pagination";
import { LIST_BUSINESS} from "../../queries/sips/sips.graphql";
import { LIST_SIPSGAS, EXPORT_SIPSGAS } from "../../queries/sips/sipsGas.graphql";
//import { LIST_SIPS_UBICACION_CIF } from "../../queries/ubicacion/sip-ubicacion";
//import { LIST_COMERCIAL_PRODUCTOS } from "../../queries/comercial-producto/comercial-producto";
import { LIST_COMERCIAL_PRODUCTOSGAS } from "../../queries/comercial-producto/comercial-producto";

import Environment from "../../constants/environment";

import {
  CREATE_SIPS_COMPARATIVAS_LIBRE,
  LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
} from "../../queries/sips-comparativa/sip-comparativa-libre";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import memoize from 'memoize-one';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";



registerTheme('default', {
  defaultColor: '#505050',
  colors10: ["#2ec7c9",
  "#b6a2de",
  "#5ab1ef",
  "#ffb980",
  "#d87a80",
  "#8d98b3",
  "#e5cf0d",
  "#97b552",
  "#95706d",
  "#dc69aa"],
  geometries: {
    interval: {
      rect: {
        active: { style: { stroke: '#5AD8A6', lineWidth: 1 } },
        inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
selected: {},
}
    }
  }
});

const notify = () => toast.success(
  "Ofertas guardadas correctamente",
  {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const notifyError = () => toast.error(
  "Solo puedes escoger un producto",
  {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const notifyWarning = () => toast.error(
  "Tienes seleccionados CUPS con tarifas incompatibles",
  {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage||'{}');

const showIndexButtonCol = parsed.indexada;
const showFreeMarketButtonCol = parsed.mercadolibre;

const sortIcon = <ArrowDownward />;

const columns = memoize(clickHandler => [
{
// eslint-disable-next-line react/button-has-type
//cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
//cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

cell: row => <Link
  className="btn btn-primary btn-sm btn-circle"
  data-toggle="modal"
  to={{ pathname: "sipGas-details/" + row.cups }}
  id = "buscartooltip"
>
  <i className="pe-7s-search text-white"></i>
</Link>,

ignoreRowClick: true,
allowOverflow: true,
button: true,
name: 'INFO',
minWidth: '55px'

},
{
cell: row => <Link
  className="btn btn-info btn-sm btn-circle"
  data-toggle="modal"
  to={{ pathname: "sipGas-comparative-free/" + row.cups }}
  id = "fijotooltip"
>
  <i className="pe-7s-calculator text-white"></i>
</Link>,

ignoreRowClick: true,
allowOverflow: true,
button: true,
omit: showFreeMarketButtonCol,
name: 'FIJO',
minWidth: '55px'

},

  {selector: row => row['cups'] , name: 'CUPS',  width: '190px',		style: {
      color: '#202124',
      fontSize: '12px',
      fontWeight: 500,

    },},

  {selector: row => row['nombreCompletoTitular'], name: 'TITULAR', sortable: true, style: {
      color: 'rgba(0,0,0,.54)',
    },},
  {selector: row => row['CIF'], name: 'CIF', sortable: true,},
  {selector: row => row['nombreEmpresaDistribuidora'], name: 'DISTRIBUIDORA', sortable: true, center: true, wrap: true},
{selector: row => row['desCodigoPeajeEnVigor'], name: 'ATR', sortable: true, center: true, wrap: true},
{selector: row => row['ConsumoAnualTotal'], name: 'TOTAL CONSUMO AÑO ACTUAL', sortable: true, width: '120px', center: true, },
{selector: row => row['ConsumoAnualEnWhP1'] , name: 'CONSUMO ANUAL KW P1', width: '130px', center: true, },
{selector: row => row['ConsumoAnualEnWhP2'] , name: 'CONSUMO ANUAL KW P2',   width: '130px', center: true, },
{selector: row => row['ConsumoAnualTotalAnterior'] , name: 'TOTAL CONSUMO AÑO ANTERIOR', sortable: true, width: '120px', center: true, },
{selector: row => row['ConsumoAnualAnteriorEnWhP1'] , name: 'CONSUMO AÑO ANTERIOR KW P1',  width: '130px', center: true, },
{selector: row => row['ConsumoAnualAnteriorEnWhP2'] , name: 'CONSUMO AÑO ANTERIOR KW P2', width: '130px', center: true, },
{selector: row => row['fechaUltimoMovimientoContrato'] , name: 'ULT. MOVIMIENTO' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaUltimoMovimientoContrato).isValid() ? moment(row.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "")},
{selector: row => row['fechaUltimoCambioComercializador'] , name: 'ULT. CAMBIO COMERCIAL', sortable: true, width: '110px', right:true,  format: (row => moment(row.fechaUltimoCambioComercializador).isValid() ? moment(row.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "")},
{selector: row => row['fechaUltimaInspeccion'] , name: 'FECHA ULT. INSPECCION' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaAltaSuministro).isValid() ? moment(row.fechaAltaSuministro).format('DD-MM-YYYY') : "")},
{selector: row => row['descCodigoResultadoInspeccion'] , name: 'RESULTADO INSPECCION', sortable: true, center: true, wrap: true},
{selector: row => row['informacionImpagos'] , name: 'IMPAGOS', sortable: true, center: true, wrap: true},
{selector: row => row['desProvinciaPS'] , name: 'DISTRIBUIDORA', sortable: true, },
{selector: row => row['desMunicipioPS'] , name: 'MUNICIPIO', sortable: true },
{selector: row => row['codigoPostalPS'], name: 'COD. POSTAL', sortable: true, width: '120px', center: true, },
{selector: row => row['apellido1Titular'] , name: 'APELLIDO1 TITULAR',  width: '120px', center: true, },
{selector: row => row['apellido2Titularr'] , name: 'APELLIDO2 TITULAR',  width: '120px', center: true, },
{selector: row => row['nombreTitular'] , name: 'NONMBRE TITULAR',  width: '120px', center: true, },
]);

const columnsDatosR1 = [
  {selector: row => row['Id'], name: 'ATR', sortable: true, width: '120px', style: {
      color: 'rgba(0,0,0,.54)',
    },},
  {selector: row => row['TotalContador'] , name: 'Nº Contratos', sortable: true, width: '120px', center: true},
  {selector: row => row['TotalConsumoAnual'] , name: 'T. Energía Contratos MW', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual)/1000))},
];

const columnsDatosR3 = [
  {selector: row => row['nombreOferta'] , name: 'Producto', width: '230px', style: {
      color: 'rgba(0,0,0,.54)',
    },},
  {selector: row => row['nombreComercializadora'] , name: 'Comercializadora', width: '200px', center: true},
  {selector: row => row['tarifaATR'] , name: 'ATR', width: '100px', center: true },
  {selector: row => row['verde'] , name: 'E. Verde', width: '100px', center: true },
  {selector: row => row['precioTE1'] , name: 'P.T.E. P1', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTE1)) },
  {selector: row => row['precioTE2'] , name: 'P.T.E. P2', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTE2)) },

  {selector: row => row['precioTFijo'] , name: 'P.T. Fijo', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTFijo)) }

];

const columnsDatosR2 = [
  {selector: row => row['Id'] , name: 'Comercializadora', sortable: true, width: '230px', style: {
      color: 'rgba(0,0,0,.54)',
    },},
  {selector: row => row['TotalContador'] , name: 'Nº Contratos', sortable: true,width: '120px', center: true},
  {selector: row => row['TotalConsumoAnual'] , name: 'T. Energía Contratos MW', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual)/1000)) },
];


const customStyles = {
	headRow: {
		style: {
			border: 'none',

		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '12px',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      paddingLeft: '8px',
      paddingRight: '8px',
      minWidth: '55px'

		},
	},
  cells: {
  style: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '55px',
    wordBreak: 'break-word',
    fontFamily: '"Open Sans", sans-serif;'
  },
},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 250, 250)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
      fontSize: '12px',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};




interface IAtrState {
  form: {
      idProducto: any
      costeOperativo: number
  },
  producto: {
    productoSeleccionado:any
  };
  UserStore:any;
  gas:boolean;
    isLoading: boolean;
  isSearching: boolean;
  copiedText: string;
  currentPage: number;
  pageSize: number;
  pageCount: number;
  count: number;
  page: number;
  limit: number;
  //producto: {productoSeleccionado:[{tarifaATR:'000'}]},
  productoVisual:any;
  user:any;
  paginatedDataTemp:{
  items:any[]};
  CIFTEMP:string;
  paginatedData:{
    items:any[]};
  showIndexButton: boolean;
  showFreeMarketButton: boolean;
  sumOfEnergy: number;
  sumOfEnergyLast: number;
  cupsGroupedBydistri: any[];
  cupsGroupedBydistriEnergy: any[];
  cupsGroupedByATREnergy: any;
  cupsGroupedByComer: any[];
  cupsGroupedByATR: any[];
  cupsGroupedByProvincia: any[];
  cupsGroupedByComercialEnergy :any[];
  cupsGroupedByComercialEnergyCont:any[];
  EnergiaTotalATRCont:any[];
  datosEmpresa:any;
  itemsSelected:any[];
  openModalComparative: boolean;
  numPeriods: number;
  numPeriodsPotencia: number;
  saveButtonDisabled:boolean;
  selectProductButtonDisabled: boolean;
  saveModalOpened: boolean;
  titular: {
    data:{
      nombre_completo_titular: string,
      CIF:string,
      direccion_titular:string,
      codigo_postal_titular:string,
      telefono_fijo:string,
      telefono:string,
      email_contacto:string,
      tipo_cliente:string,
      observaciones:string,
      nombreTitular: string,
      apellido1Titular: string,
      apellido2Titular: string,
      desAutonomiaTitular: string,
      desProvinciaTitular: string,
      desMunicipioTitular: string,
      tipoViaTitular: string,
      viaTitular: string,
      numFincaTitular: string,
      portalTitular: string,
      escaleraTitular: string,
      pisoTitular: string,
      puertaTitular: string
    },
};
  productSelected: any;
  productId: string;
  comercializadoraId: string;
  nombreComercializadora: string;
  tarifaATR: string;
  selectedRows:any[];
  rates2X: any[];
  rates3X: any[];
  rates2XP: any[];
  rates1XP:any[];

 rates1XE: any[];
rates2XE: any[];


tarifaATRContrato:any[];
comercialProductos:any;
toggledClearRows:boolean ;
toggledClearRowsPS:boolean;

productosPrecios:any[];
productosPreciosPeriodoDia:any[];
periodosCupStringTemp: number;
//productosPrecios:{
//  [[{producto:string,  precioEnergiaP1:number,  precioEnergiaP2:number, precioEnergiaP3:number,  precioEnergiaP4:number, precioEnergiaP5:number, precioEnergiaP6:number,precioPotenciaP1: number, precioPotenciaP2: number, precioPotenciaP3: number, precioPotenciaP4: number, precioPotenciaP5: number, precioPotenciaP6: number },
//]]};

};



class MultipsGas extends Reflux.Component<any, any> {

  public lastFiltersMultiGas: any = null;


  public state: IAtrState = {
    form: {
        costeOperativo: 0,
        idProducto: null
    },
UserStore:{},
gas:false,
    producto: {productoSeleccionado:[{tarifaATR:'000'}]},
    isLoading: false,
    isSearching: false,
    copiedText: '',
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 200,
    //producto: {productoSeleccionado:[{tarifaATR:'000'}]},
    productoVisual:{productoSeleccionado: []},
    user: {id:''},
    paginatedDataTemp: {items:[]},
    CIFTEMP: '',
    paginatedData: {items:[{CIF:''}]},
    showIndexButton: false,
    showFreeMarketButton: false,

    sumOfEnergy: 0,
    sumOfEnergyLast: 0,
    cupsGroupedBydistri: [],
    cupsGroupedBydistriEnergy: [],
    cupsGroupedByATREnergy: [{Id:"SIN DATOS",  TotalConsumoAnual:0},],
    cupsGroupedByComer: [],
    cupsGroupedByATR: [],
    cupsGroupedByProvincia: [],
    cupsGroupedByComercialEnergy :[],
    cupsGroupedByComercialEnergyCont:[],
    EnergiaTotalATRCont:[],
    datosEmpresa:{},
    itemsSelected:[],
    openModalComparative: false,
    numPeriods: 0,
    numPeriodsPotencia: 0,
    saveButtonDisabled:true,
    selectProductButtonDisabled: true,
    saveModalOpened: false,
    titular: {
      data:{
        nombre_completo_titular: '',
        CIF:'',
        direccion_titular:'',
        codigo_postal_titular:'',
        telefono_fijo:'',
        telefono:'',
        email_contacto:'',
        tipo_cliente:'',
        observaciones:'',
        nombreTitular: "",
        apellido1Titular: "",
        apellido2Titular: "",
        desAutonomiaTitular: "",
        desProvinciaTitular: "",
        desMunicipioTitular: "",
        tipoViaTitular: "",
        viaTitular: "",
        numFincaTitular: "",
        portalTitular: "",
        escaleraTitular: "",
        pisoTitular: "",
        puertaTitular: ""
      },
  },
    productSelected: null,
    productId: "",
    comercializadoraId: "",
    nombreComercializadora: "",
    tarifaATR :"",
    selectedRows: [],
    rates2X: [
        '001',
        '004',
        '005',
        '006',
        '007',
        '008'
    ],
    rates3X: [
        '011',
        '003',
        '012',
        '013',
        '014',
        '015',
        '016'
    ],
    rates2XP: [
        '018',
    ],
    rates1XP:[
      '31',
      '32',
      '33',
      '34',
      '35',
      'RL01',
      'RL02',
'RL02',
'RL03',
'RL04',
'RL05',
'RL06',
'RL07',
'RL08',
'RL09',
'RL10',
'RL11',
'00',
'11',
'12',
'41',
'42',
'43',
'44',
'45',
'B2',
'46',
'47',
'A1',
'A2',
'A3',
'B1',
'C2',
'C1',
'D1',
'D2',
'M1',
'M2',
'99',
'13',
'1B',
'2B',
'3B',
'21',
'22',
'23',
'24',
'25',
'26',
'4B',
'5B',
'6B'
    ],

     rates1XE: [
        '001',
        '005'
    ],
    rates2XE: [
        '004',
        '006'
],
    tarifaATRContrato:[
      {id:"018",
        name: "2.0TD"},
      {id:"019",
        name: "3.0TD"},
      {id:"020",
        name: "6.1TD"},
      {id:"021",
        name: "6.2TD"},
      {id:"022",
        name: "6.3TD"},
      {id:"023",
        name: "6.4TD"},
      {id:"024",
        name: "3.0TDVE"},
      {id:"025",
        name: "6.1TDVE"},
    ],
    comercialProductos:[],
    toggledClearRows: false,
    toggledClearRowsPS:false,
    productosPrecios:[{producto:"",  precioEnergiaP1:0,  precioEnergiaP2:0,precioTerminoFijo: 0},
  ],
  //productosPreciosPeriodoDia:[{temporada:"",  mes:0,  hora:0, precioEnergía:0},]
  productosPreciosPeriodoDia:[],
  periodosCupStringTemp:0,
};

constructor(props) {
  super(props);
  this.store = UserStore;
}


UNSAFE_componentWillReceiveProps (){
  //console.log(this.props)
console.log(this.props)
console.log(this.state)
//console.log('VALOR DE STATE.USERSTORE ANTES  EN PROPS -------------->', this.state.UserStore)

//  const userFromStorage = localStorage.getItem("user");

//  const parsed = JSON.parse(userFromStorage||'{}');

//  const showIndexButtonCol = parsed.indexada;
//  const showFreeMarketButtonCol = parsed.mercadolibre;
//
//  const companyNameGlobal = parsed.company;
//  const companyIdUser = parsed.companyId;

//  const userGroupId = parsed.user_group_id
//  const userId = parsed.id
const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

console.log('VALOR DE USUARIO ----------------->', usuario)


//const showIndexButtonCol = usuario.indexada;
//const showFreeMarketButtonCol = usuario.mercadolibre;
const companyNameGlobal = usuario.company;
const companyIdUser = usuario.companyId;

const userGroupId = usuario.user_group_id
const userId = usuario.id
const gas = usuario.gas


console.log('VALORES DE GAS --->',gas)
console.log('VALORES DE indexbutton  ',showIndexButtonCol )
const showFreeMarketButtonCol = usuario.mercadolibregas;

//const companyNameGlobal = this.state.UserStore.data.company;
//const companyIdUser = this.state.UserStore.data.companyId;

//const userGroupId = this.state.UserStore.data.user_group_id
//const userId = this.state.UserStore.data.id

//  console.log('VALORES DE ',usuario)

  if (userGroupId === 1000){
    this.setState({
      activeUser: true
    })};

  this.setState({
    companyNameGlobal,
    companyIdUser,
    userGroupId,
    userId,
    gas,
    showFreeMarketButton : showFreeMarketButtonCol

  });
}


  UNSAFE_componentWillMount (){
    if(Object.keys(this.state.paginatedData.items[0]).length > 2){
      //console.log('ENTRA EN EL OBJETO y TIENE DATOS en el componente')
      //console.log(this.state)
      //console.log(this.state.paginatedData.items)
      const data2 = this.state.paginatedData.items
      this.setState({
            paginatedDataTemp: data2})

    } else {
      //console.log('ENTRA EN EL OBJETO y no TIENE DATOS en el componente')
      //console.log(this.state.paginatedData.items)
      const data2:any[] = [];}

  };


componentDidMount() {
let showIndexButton = false;
    let showFreeMarketButton = false;
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        showIndexButton = parsed.indexada;
        showFreeMarketButton = parsed.mercadolibre;
      } catch (e) {}
    }
    this.setState({ showIndexButton, showFreeMarketButton });
   }



    onSubmit = (filters) => {
      this.lastFiltersMultiGas = filters;
      this.setState({ page: 1 }, () => {
        this.fetchItems(this.lastFiltersMultiGas);
      });
    };

  export = async () => {
    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFiltersMultiGas;
    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SIPSGAS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsGasExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

   fetchItems = async (filters) => {
    let cif = ""
    let isLoading = true;
    const isSearching = true;
    this.setState({ isLoading, isSearching });
    this.lastFiltersMultiGas = filters;

    if (this.lastFiltersMultiGas.hasOwnProperty("limit")) {
      this.state.limit = parseInt(filters.limit, 200);
    }
    if (
      (!this.state.limit || this.state.limit === 0) &&
      (this.state.pageSize !== null || this.state.pageSize > 1)
    ) {
      this.state.limit = this.state.pageSize;
    }

    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      delete filters["limit"];
    }

    if(filters["CIF"]){
      cif = filters["CIF"];
    }

    if (limit === 0 && this.state.pageSize > 1) {
      limit = this.state.pageSize;
    }

    await this.props.client.query({
      query: LIST_SIPSGAS,
      variables: { page, limit, filters },
    }).then( res => {

      const {
        currentPage,
        pageSize,
        pageCount,
        count,
        items,
      } = res.data.listSipsGas;

      //console.log('ESTOY DENTRO DEL QUERY')
      //console.log(res.data.listSips)
      //console.log('VALORE DE items---->', items)


        const paginatedData = { items };

        //console.log('VALOR DE paginateData en QUERY ------>', paginatedData)


          let onePeriods = ['31','32','33','34'];
          let twoPeriods = ['35']
          var periodosenergia = '0'
      if(onePeriods.includes(paginatedData.items[0]['codigoPeajeEnVigor'])){
        periodosenergia ='1'
        this.setState({
          numPeriods: '1',
          numnumPeriodsPotencia: '1',
        });
      } else   if(twoPeriods.includes(paginatedData.items[0]['codigoPeajeEnVigor'])){
        periodosenergia = '2'
        this.setState({
          numPeriods: '2',
          numnumPeriodsPotencia: '1',
        });
      }else{
        periodosenergia ='2'
        this.setState({
          numPeriods: '2',
          numnumPeriodsPotencia: '1',
        });
      }


      isLoading = false;
     if (count === 0){

      this.setState({
        paginatedData: {items:[{CIF:''}]},
        paginatedDataTemp:  {items:[]},
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
        numPeriods: periodosenergia,
          numnumPeriodsPotencia: '2',
        datosEmpresa:[]
      })
    }else{

      let onePeriods = ['31','32','33','34'];
      let twoPeriods = ['35']
      let periodos = ''
      let periodosPotencia=''
      if(onePeriods.includes(paginatedData.items[0]['codigoPeajeEnVigor'])){
periodos = '1'
periodosPotencia = '1'
      } else   if(twoPeriods.includes(paginatedData.items[0]['codigoPeajeEnVigor'])){
periodos = '2'
periodosPotencia = '1'
      }else{
periodos = '2'
periodosPotencia = '1'
      }

      this.setState({
        paginatedData:paginatedData,
        paginatedDataTemp: paginatedData,
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
        numPeriods: periodos,
          numnumPeriodsPotencia: periodosPotencia,
        datosEmpresa:[]
        });
    };
    })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })

  // this.setNumPeriods();
if(this.state.count>0){
  // if(Object.keys(this.state.paginatedData.items[0]).length > 2){

     //console.log('AHORA ESTOY DENTRO DEL IF SI EL CIF NO ES NULO')
     cif = this.state.paginatedData.items[0].CIF;

        if (cif != undefined || null){

          await this.props.client.query({
            query: LIST_BUSINESS,
            variables: {cif},
          }).then( res => {

            const data = res.data.listBusiness;


            const datosEmpresa = { data };


            this.setState({
              datosEmpresa: datosEmpresa,


            });
          })
          .catch( err => {
            isLoading = false;
            this.setState({isLoading});
          })

/*
          await this.props.client
            .query({
              query: LIST_SIPS_UBICACION_CIF,
              variables: { cif: cif },
            })
            .then((result) => {
              //Sip
              const data = result.data.findTitular;



              const titular = { data };

              this.setState({
                  titular: titular,
                });
            }).catch( err => {
              isLoading = false;
              this.setState({isLoading});
            })
*/

        }

   }
/*   else {
       this.setState({paginatedData: {
         items: [{CIF:''}],
       },})}
*/

this.setState({
  titular: {
    data:{
      nombre_completo_titular: this.state.paginatedData.items[0].nombreTitular +' '+this.state.paginatedData.items[0].apellido1Titular+' '+this.state.paginatedData.items[0].apellido2Titularr,
      CIF:this.state.paginatedData.items[0].CIF,
      direccion_titular: this.state.paginatedData.items[0].tipoViaTitular+' '+ this.state.paginatedData.items[0].viaTitular+' '+this.state.paginatedData.items[0].numFincaTitular+' '+this.state.paginatedData.items[0].portalTitular+ ' '+this.state.paginatedData.items[0].pisoTitular+ ' '+this.state.paginatedData.items[0].puertaTitular,
      codigo_postal_titular:this.state.paginatedData.items[0].codigoPostalTitular,
      telefono_fijo:'',
      telefono:'',
      email_contacto:'',
      tipo_cliente:'',
      observaciones:'',
      nombreTitular:this.state.paginatedData.items[0].nombreTitular ,
      apellido1Titular: this.state.paginatedData.items[0].apellido1Titular,
      apellido2Titular: this.state.paginatedData.items[0].apellido2Titularr,
      desAutonomiaTitular: this.state.paginatedData.items[0].desAutonomiaTitular,
      desProvinciaTitular: this.state.paginatedData.items[0].desProvinciaTitular,
      desMunicipioTitular: this.state.paginatedData.items[0].desMunicipioTitular,
      tipoViaTitular:  this.state.paginatedData.items[0].tipoViaTitular,
      viaTitular:  this.state.paginatedData.items[0].viaTitular,
      numFincaTitular:  this.state.paginatedData.items[0].numFincaTitular,
      portalTitular:  this.state.paginatedData.items[0].portalTitular,
      escaleraTitular:  this.state.paginatedData.items[0].escaleraTitular,
      pisoTitular:  this.state.paginatedData.items[0].pisoTitular,
      puertaTitular:  this.state.paginatedData.items[0].puertaTitular
    },
},

});

  };

  onSave = async (modalData) => {

    //console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state)
    //console.log('ESTAMOS EN ONSAVE VALOR DE THIS.PROPS -----------------> ', this.props)

const idOferta = uuid();

console.log('VALOR DE MODALDATA -------------------------------------------------------------------------> ', modalData)

    for(var i=0;i<this.state.selectedRows.length;i++){
      let potenciaP1 = 0;
      let potenciaP2 = 0;



      const item: any = this.state.selectedRows[i];
      const now = new Date().toISOString();
      const userCompany = localStorage.getItem("user");

          var usuario = 0;
          var compañia = 0;

      if(userCompany) {
        const parsedUserS = JSON.parse(userCompany);

        usuario = parsedUserS.id;
        compañia = parsedUserS.companyId;
      } else {
        usuario =0;
        compañia = 0;

      }

      const user: any = this.state.user;
      const sip: any = this.state;


      const producto = this.state.producto
      ? JSON.stringify(this.state.producto)
      : null;

      const datosContrato = JSON.stringify("");

      const titular = this.state.titular.data;

      const dataModal: any = {
        ...{

        },
        ...modalData,
      };


console.log('VALOR DE DATAMODAL---------------------------------------------------   -------> ', dataModal)
console.log('VALOR DE This.State ----------------------------------------> ', this.state)


var tarifaATRContrato = "";
//console.log(typeof producto.productoSeleccionado.tarifaATR)
//console.log(this.state.producto.productoSeleccionado)
var seleccion = ''
if(this.state.producto.productoSeleccionado.tarifaATR){
  seleccion = this.state.producto.productoSeleccionado.tarifaATR

}

console.log('VALOR DE SELECCiON-----> ', seleccion)
//producto: {productoSeleccionado:[{tarifaATR:"000"}]},
//const seleccion = this.state.producto.productoSeleccionado.tarifaATR
//producto.productoSeleccionado.tarifaATR:'000'


var tarifaATRContrato='';

if (seleccion != undefined){
const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
 tarifaATRContrato = insertAt(seleccion, '.', 1);}




/*
var atr ="";
  if(this.state.tarifaATR){
    atr =   this.state.tarifaATRContrato.find(x => x.id === this.state.tarifaATR ).map(x=>x.name)? this.state.tarifaATRContrato.find(x => x.id === this.state.tarifaATR ).map(x=>x.name):""
  } ;
*/

//console.log('VALOR DE ATR ------------------------------>', tarifaATRContrato)



var codigoPostal = dataModal.CodigoPostalTitular.padStart(5, "0")
var codigoPostalPS = item.codigoPostalPS.padStart(5, "0")

if (dataModal.esAprobada===false){
      var datosContratoP = {
        Usuario : usuario.toString(),
        fechaPropuesta: moment
          .parseZone(now.split("T")[0])
          .format("YYYY-MM-DD"),
        FechaAprobacion: '',
          IdOferta: idOferta,
          OfertaTipo: "MP",
          ofertaTipoId: i,
        T_Titular: {
            RazonSocial: dataModal.razonSocial,
            NIF: dataModal.NIF,
            DireccionFiscal: dataModal.DireccionFiscal,
            CodigoPostalTitular: codigoPostal,
            TelefonoFijo: dataModal.TelefonoFijo,
            TelefonMovil: dataModal.TelefonMovil,
            Email: dataModal.correoContacto,
            TipoCliente: dataModal.TipoCliente,
            ObservacionesCliente: dataModal.ObservacionesCliente,
            GDPR: dataModal.GDPR,
        },
        T_PuntoSuministro: { //Pendiente
            Direccion: item.direccion_ps, //this.state.ubicacion.direccion_ps,
            CodigoPostal: codigoPostalPS, //this.state.ubicacion.codigo_postal_ps,
            Telefono: item.TelefonoPS, //this.state.ubicacion.telefono,
            ObservacionesPuntoSuministro: ""
          },

        T_CUPs: {
            Distribuidora: item.distribuidora,
            CUPsEle: item.cups,
            Tarifa: tarifaATRContrato,
            caudalMedioEnWhdia: "0",
            TipoServicio: "2",
            Consumo: item.ConsumoAnualTotal.toString(),
          },

          T_PropuestaComercial: {
            PorcentajeAhorro: "0",
            ImporteAhorro: "0",
            Estado: "P", //"P",
            Comercializadora: this.state.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
            Producto: this.state.productSelected, //"CLASSIC",
            IdProducto:  this.state.productId.toString(),
            Anexo: "",
            //this.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
            TipoPrecio: "0", //"0 Fijo 1 Indexado",
            ObservacionesProducto: "",
            //this.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",st
            GastoOperativo: "0.00", //this.state.producto.costeOperativo.toString(), //"0.00 SOLO CUANDO ES CORPORATE",
            //idServicio: this.comparativeTemp.producto.datos.idServicio,
            idServicio: "",
            idComercializadora: this.state.comercializadoraId,
            //nombreServicio: this.comparativeTemp.producto.datos.nombreServicio,
            //precioServicio: this.comparativeTemp.producto.datos.precioServicio.toString() //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
            nombreServicio: "",
            precioServicio: "0.00" //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",

          },
      }
    } else {

      var datosContratoP = {
        Usuario : usuario.toString(),
        fechaPropuesta: moment
          .parseZone(now.split("T")[0])
          .format("YYYY-MM-DD"),
        FechaAprobacion: moment
          .parseZone(now.split("T")[0])
          .format("YYYY-MM-DD"),
          IdOferta: idOferta,
          OfertaTipo: "MP",
          ofertaTipoId: i,
        T_Titular: {
          RazonSocial: dataModal.razonSocial,
          NIF: dataModal.NIF,
          DireccionFiscal: dataModal.DireccionFiscal,
          CodigoPostalTitular: codigoPostal,
          TelefonoFijo: dataModal.TelefonoFijo,
          TelefonMovil: dataModal.TelefonMovil,
          Email: dataModal.correoContacto,
          TipoCliente: dataModal.TipoCliente,
          ObservacionesCliente: dataModal.ObservacionesCliente,
          GDPR: dataModal.GDPR,
        },
        T_PuntoSuministro: { //Pendiente
            Direccion: item.direccion_ps, //this.state.ubicacion.direccion_ps,
            CodigoPostal: codigoPostalPS, //this.state.ubicacion.codigo_postal_ps,
            Telefono: item.TelefonoPS, //this.state.ubicacion.telefono,
            ObservacionesPuntoSuministro: ""
          },

        T_CUPs: {
            Distribuidora: item.distribuidora,
            CUPsEle: item.cups,
            Tarifa: tarifaATRContrato,
            caudalMedioEnWhdia: "0",
            TipoServicio: "2",
            Consumo: item.ConsumoAnualTotal.toString(),
          },

          T_PropuestaComercial: {
            PorcentajeAhorro: "0",
            ImporteAhorro: "0",
            Estado: "P", //"P",
            Comercializadora: this.state.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
            Producto: this.state.productSelected, //"CLASSIC",
            IdProducto:  this.state.productId.toString(),
            Anexo: "",
            //this.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
            TipoPrecio: "0", //"0 Fijo 1 Indexado",
            ObservacionesProducto: "",
            //this.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",st
            GastoOperativo: "0.00", //this.state.producto.costeOperativo.toString(), //"0.00 SOLO CUANDO ES CORPORATE",
            //idServicio: this.comparativeTemp.producto.datos.idServicio,
            idServicio: "",
            idComercializadora: this.state.comercializadoraId,
            //nombreServicio: this.comparativeTemp.producto.datos.nombreServicio,
            //precioServicio: this.comparativeTemp.producto.datos.precioServicio.toString() //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
            nombreServicio: "",
            precioServicio: "0.00" //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",

          },
      }


    }


console.log('VALOR DE datosContratoP ----------------->', datosContratoP)

      const datosContratoJSON:string = JSON.stringify(datosContratoP).toString();


      console.log('VALOR DE datosContratoJSON ----------------->', datosContratoJSON)

      console.log('VALOR DE ITEM ---------------->', item)


      //console.log('VALORES DE ITEM ANTES DE GUARDAR')
      //console.log(item)

      //console.log('VALOR DE USER')
      //console.log(user)
      //console.log(this.state)

      var data:any=[]
      if (dataModal.esAprobada===false){

       data = {
        ...{
          // ...this.dataToSave,
          companyId: Number(compañia),
          nombreOferta: "",
          idComercializadora: dataModal.idComercializadora,
          nombreComercializadora: dataModal.nombreComercializadora,
          idComercializadoraActual: item.codigoComercializadora,
          nombreComercializadoraActual: item.comercializadora,
          // datosPreciosActual: actual,
          userId: Number(usuario),
          fechaPropuesta: moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD"),
          codigoPostalPS: item.codigoPostalPS,
          codigoMunicipioPS: item.municipioPS,
          nombreMunicipioPS: item.desMunicipioPS,
          codigoProvinciaPS: item.codigoProvinciaPS,
          nombreProvinciaPS: item.desProvinciaPS,
          codigoAutonomiaPS: item.codigoAutonomiaPS,
          nombreAutonomiaPS: item.desAutonomiaPS,
          DireccionPS: item.tipoViaPS+' '+ item.viaPS+' '+item.numFincaPS+' '+item.portalPS+ ' '+ item.escaleraPS+' '+item.pisoPS+ ' '+item.puertaPS,
          esAprobada: false,
          datosPropuesta: producto,
          correoContacto: dataModal.correoContacto,
          TelefonoFijo: dataModal.TelefonoFijo,
          TelefonMovil: dataModal.TelefonMovil ,
          Email: dataModal.Email,
          cups: item.cups, //PASAR EL CUPS QUE TOQUE
          tipo: "G",
          datosContrato: datosContratoJSON,
          tipoViaPS: item.tipoViaPS,
          viaPS: item.viaPS,
          numFincaPS: item.numFincaPS,
          portalPS: item.portalPS,
          escaleraPS: item.escaleraPS,
          pisoPS: item.pisoPS,
          puertaPS: item.puertaPS

        },
        ...modalData,
      };
    } else {
      data = {
       ...{
         // ...this.dataToSave,
         companyId: Number(compañia),
         nombreOferta: "",
         idComercializadora: dataModal.idComercializadora,
         nombreComercializadora: dataModal.nombreComercializadora,
         idComercializadoraActual: item.codigoComercializadora,
         nombreComercializadoraActual: item.comercializadora,
         // datosPreciosActual: actual,
         userId: Number(usuario),
         fechaPropuesta: moment
           .parseZone(now.split("T")[0])
           .format("YYYY-MM-DD"),
         codigoPostalPS: item.codigoPostalPS,
         codigoMunicipioPS: item.municipioPS,
         nombreMunicipioPS: item.nombreMunicipioPS,
         codigoProvinciaPS: item.codigoProvinciaPS,
         nombreProvinciaPS: item.nombreProvinciaPS,
         codigoAutonomiaPS: item.codigoAutonomiaPS,
         nombreAutonomiaPS: item.nombreAutonomiaPS,
         DireccionPS: item.tipoViaPS+' '+ item.viaPS+' '+item.numFincaPS+' '+item.portalPS+ ' '+ item.escaleraPS+' '+item.pisoPS+ ' '+item.puertaPS,
         esAprobada: true,
         datosPropuesta: producto,
         correoContacto: dataModal.correoContacto,
         TelefonoFijo: dataModal.TelefonoFijo,
         TelefonMovil: dataModal.TelefonMovil ,
         Email: dataModal.Email,
         cups: item.cups, //PASAR EL CUPS QUE TOQUE
         tipo: "G",
         datosContrato: datosContratoJSON,
         tipoViaPS: item.tipoViaPS,
         viaPS: item.viaPS,
         numFincaPS: item.numFincaPS,
         portalPS: item.portalPS,
         escaleraPS: item.escaleraPS,
         pisoPS: item.pisoPS,
         puertaPS: item.puertaPS

       },
       ...modalData,
     };

console.log('VALOR DE DATA PARA CREAR COMPARATIVA --------->', data)


    }

     //console.log('VALORES DE DATA ANTES DE GUARDARLOS')
     //console.log(data)

      await this.props.client
        .mutate({
          mutation: CREATE_SIPS_COMPARATIVAS_LIBRE,
          variables: { data: data },
        })
        .then((result) => {
          console.log("guardado", result);
          // this.clearInvoices();
          if(i === this.state.selectedRows.length-1){
notify();
//            alert("Ofertas guardadas correctamente.")
          };
          this.fetchComparatives(item.cups); //PASAR EL CUPS QUE TOQUE
        })
        .then(() => {
          this.forceUpdate();
        });


    };

    // AQUI INCIALIZAMOS LOS VALORES DE SELECT ROW y otros.....

    this.setState({
      titular: {
        data:{
          nombre_completo_titular: '',
          CIF:'',
          direccion_titular: '',
          codigo_postal_titular:'',
          telefono_fijo:'',
          telefono:'',
          email_contacto:'',
          tipo_cliente:'',
          observaciones:'',
          nombreTitular: "",
          apellido1Titular: "",
          apellido2Titular: "",
          desAutonomiaTitular: "",
          desProvinciaTitular: "",
          desMunicipioTitular: "",
          tipoViaTitular: "",
          viaTitular: "",
          numFincaTitular: "",
          portalTitular: "",
          escaleraTitular: "",
          pisoTitular: "",
          puertaTitular: "",
        },
    }
});
    this.setState({
      producto: {
        active: true,
        productoSeleccionado: [],
        costeOperativo: 0,
      },
      productoVisual:{productoSeleccionado: []},
      showList: false,
      saveButtonDisabled: true,
      productSelected: "",
      toggledClearRows: !this.state.toggledClearRows,
      toggledClearRowsPS: !this.state.toggledClearRowsPS
 });
 this.forceUpdate();

  };

  openComparativeSection = async (event) => {

    this.setState({
      producto: {
        active: true,
        productoSeleccionado: event.productoSeleccionado,
        costeOperativo: event.costeOperativo,
      },
      productoVisual:{productoSeleccionado: [event.productoSeleccionado]},
      showList: false,
      saveButtonDisabled: false,
      productSelected: event.productoSeleccionado.nombreOferta,
      codigoATR: event.productoSeleccionado.tarifaATR,
      productId: event.productoSeleccionado.id,
      comercializadoraId: event.productoSeleccionado.idComercializadora,
      nombreComercializadora: event.productoSeleccionado.nombreComercializadora

    });
    //console.log(this.state)
  };

  // clearInvoices = async () => {
  //   this.comparativeTemp = null;
  //   this.setState({
  //     producto: {
  //       active: false,
  //       producto: null,
  //       costeOperativo: 0,
  //     },
  //     showList: true,
  //   });
  // };

  selectedItems = async (event) => {
    let items: any[] = [];
    event.map((id) => {
      items.push(
        this.state.paginatedData.items.map(item =>item['cups']).filter((item) => item === id),
      );
    });
    this.setState({
      itemsSelected: items,
    });


  };

  toggle = () => {
    this.setState({
      openModalComparative: !this.state.openModalComparative,
    });
  }

  toggleModal = (event, state) => {

    event.preventDefault();

    this.setState({
      [state]: !this.state[state],
    });

  };

  showModal = () => {
    this.setState({
      openModalComparative: true,
    });
  };

  fetchComparatives = async (cups) => {
    await this.props.client
      .query({
        query: LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
        fetchPolicy: "no-cache",
        variables: { cups: cups }, //
      })
      .then((result) => {
        let data = result.data.comparativasLibresByCups;
        console.log("Comparativas cargadas :::>", data);
        this.setState({ comparatives: data });
      });
  };


  openModalComparativa = async (event) => {

//console.log('VALOR DE This.state  en open modal comparativa------>', this.state)

    const array: any = this.state.selectedRows
      if (array.length > 0) {
        var test:any=[];
        array.map((mapItem)=> {
            test.push(mapItem.codigoPeajeEnVigor)
      })

      var newArray:any = [];
      var newArray = test.filter(function(elem, pos) {
                     return test.indexOf(elem) === pos;
             });

      console.log('VALORES DE newArray ---------->', newArray)

      if (newArray.includes("3.0A") && (newArray.includes("3.1") || newArray.includes("2.0.A") || newArray.includes("2.1.A")|| newArray.includes("2.0DHA")|| newArray.includes("2.1.DHA")|| newArray.includes("2.0 DHS") || newArray.includes("2.0 DHS") || newArray.includes("2.1 DHS")|| newArray.includes("6.1A")|| newArray.includes("6.2") || newArray.includes("6.3") || newArray.includes("6.4") )) {
//console.log( 'ARRAY 1   ---------->', newArray)
  notifyWarning();
  //alert("Tienes seleccionados CUPS con tarifas incompatibles. Por favor revise la selección");
    return;
  }

  if (newArray.includes("3.1") && newArray.includes("6.1A") && (newArray.includes("3.0A") || newArray.includes("2.0.A") || newArray.includes("2.1.A")|| newArray.includes("2.0DHA")|| newArray.includes("2.1.DHA")|| newArray.includes("2.0 DHS") || newArray.includes("2.0 DHS") || newArray.includes("2.1 DHS")|| newArray.includes("6.2") || newArray.includes("6.3") || newArray.includes("6.4"))) {
//console.log( 'ARRAY 2   ---------->', newArray)
notifyWarning();
  //alert("Tienes seleccionados CUPS con tarifas incompatibles. Por favor revise la selección");
  return;
  }

  if (newArray.includes("3.1") && (newArray.includes("3.0A") || newArray.includes("2.0.A") || newArray.includes("2.1.A")|| newArray.includes("2.0DHA")|| newArray.includes("2.1.DHA")|| newArray.includes("2.0 DHS") || newArray.includes("2.0 DHS") || newArray.includes("2.1 DHS")|| newArray.includes("6.2") || newArray.includes("6.3") || newArray.includes("6.4"))) {
//console.log( 'ARRAY 3   ---------->', newArray)
notifyWarning();
  //alert("Tienes seleccionados CUPS con tarifas incompatibles. Por favor revise la selección");
  return;
  }

  if ( (newArray.includes("2.0.A") || newArray.includes("2.1.A")|| newArray.includes("2.0DHA")|| newArray.includes("2.1.DHA")|| newArray.includes("2.0 DHS") || newArray.includes("2.0 DHS") || newArray.includes("2.1 DHS")) && (newArray.includes("3.0A") || newArray.includes("3.1") || newArray.includes("6.1A") || newArray.includes("6.2") || newArray.includes("6.3") || newArray.includes("6.4"))) {
//console.log( 'ARRAY 4   ---------->', newArray)
notifyWarning();
//  alert("Tienes seleccionados CUPS con tarifas incompatibles. Por favor revise la selección");
  return;
  }
}



    if (this.state.selectedRows.length > 0) {
      //this.showModal();
      this.fetchOfertas();

      return;
    } else {
        this.setState({ comercialProductos: [] })
    }
    alert("Seleccione algun punto de suministro antes de seleccionar un producto.");

  };


  onChangeField = async ( event ) => {
      const name = event.target.name;
      let value = event.target.value;
      let inputs = {};

      if ( value !== "" || value !== null)
      {
          inputs[name] = value;
          this.setState({
              form: {
                  ...this.state.form,
                  ...inputs
              }
          });
      }
  }

  onPageClick = (page) => {
    this.setState({ page }, () => {
      this.fetchItems(this.lastFiltersMultiGas);
    //  this.fetchTitular();

    });
  };

  beforeRenderItem = (index, item) => {
    if (index.includes("fecha") && item.value) {
      const dateValue = new Date(item.value);
      item.value = dateValue.toISOString().split("T")[0];
    }
    return true;
  };

  handleChangeContrato = state => {

    console.log(state)
    console.log('DATO DEL PRODUCTO')
  /*  state.setSelectedValue = [state.selectedRows[0]];
    state.selectedRows.splice(1)

    state.allSelected = false;
    state.selectedCount = 1;
    state.selectedRows.length = 1;

    console.log('VALOR DE STATE------>', state)*/

    var oferta :any= {}
    console.log('VALOR DE STATE', state)

//    if(state.selectedRows.length >0 && state.selectedRows.length <2){
      if(state.selectedCount === 1){

     oferta = state.selectedRows[0]
console.log(oferta)


var productosPreciosPeriodoDia:any[] = [];
var productosPreciosPeriodoDiaTemp:any[] = [];




  if(oferta.tarifaATR !=='35'){

productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ENERO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "FEBRERO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MARZO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ABRIL", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MAYO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JUNIO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JULIO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "AGOSTO", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "SEPTIEMBRE", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "OCTUBRE", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "NOVIEMBRE", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})
productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "DICIEMBRE", periodo: "DIARIO", PrecioEnergía: Number(oferta.precioTE1)})



} else {

  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ENERO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "FEBRERO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MARZO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ABRIL", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MAYO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JUNIO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JULIO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "AGOSTO", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "SEPTIEMBRE", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "OCTUBRE", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "NOVIEMBRE", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "DICIEMBRE", periodo: "DIA", PrecioEnergía: Number(oferta.precioTE1)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ENERO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "FEBRERO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MARZO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "ABRIL", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "MAYO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JUNIO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "JULIO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "AGOSTO", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "SEPTIEMBRE", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "OCTUBRE", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "NOVIEMBRE", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})
  productosPreciosPeriodoDia.push({temporada:"ANUAL", mes: "DICIEMBRE", periodo: "NOCTURNO", PrecioEnergía: Number(oferta.precioTE2)})

}


this.setState({
  productosPreciosPeriodoDia: productosPreciosPeriodoDia});





console.log('EStá dentro del producto en el if')
console.log('VALOR PARA GRAFICA-------------->', productosPreciosPeriodoDia)

this.setState({
  titular: {
    data:{
      nombre_completo_titular: this.state.selectedRows[0].nombreTitular +' '+this.state.selectedRows[0].apellido1Titular+' '+this.state.selectedRows[0].apellido2Titularr,
      CIF:this.state.selectedRows[0].CIF,
      direccion_titular: this.state.selectedRows[0].tipoViaTitular+' '+ this.state.selectedRows[0].viaTitular+' '+this.state.selectedRows[0].numFincaTitular+' '+this.state.selectedRows[0].portalTitular+ ' '+this.state.selectedRows[0].pisoTitular+ ' '+this.state.selectedRows[0].puertaTitular,
      codigo_postal_titular:this.state.selectedRows[0].codigoPostalTitular,
      telefono_fijo:'',
      telefono:'',
      email_contacto:'',
      tipo_cliente:'',
      observaciones:'',
      nombreTitular:this.state.paginatedData.items[0].nombreTitular ,
        apellido1Titular: this.state.paginatedData.items[0].apellido1Titular,
        apellido2Titular: this.state.paginatedData.items[0].apellido2Titularr,
        desAutonomiaTitular: this.state.paginatedData.items[0].desAutonomiaTitular,
        desProvinciaTitular: this.state.paginatedData.items[0].desProvinciaTitular,
        desMunicipioTitular: this.state.paginatedData.items[0].desMunicipioTitular,
        tipoViaTitular:  this.state.paginatedData.items[0].tipoViaTitular,
        viaTitular:  this.state.paginatedData.items[0].viaTitular,
        numFincaTitular:  this.state.paginatedData.items[0].numFincaTitular,
        portalTitular:  this.state.paginatedData.items[0].portalTitular,
        escaleraTitular:  this.state.paginatedData.items[0].escaleraTitular,
        pisoTitular:  this.state.paginatedData.items[0].pisoTitular,
        puertaTitular:  this.state.paginatedData.items[0].puertaTitular
    },
},

});


    this.setState({
      producto: {
        active: true,
        productoSeleccionado: oferta,
        costeOperativo: 0,
      },
      productoVisual:{productoSeleccionado: [oferta]},
      showList: false,
      saveButtonDisabled: false,
      productSelected: oferta.nombreOferta,
      codigoATR: oferta.tarifaATR,
      productId: oferta.id,
      comercializadoraId: oferta.idComercializadora,
      nombreComercializadora: oferta.nombreComercializadora,
      productosPreciosPeriodoDia: productosPreciosPeriodoDia


    });}
  else if( state.selectedCount >1){
    console.log('EStá dentro del producto en el if cuando el valor es 0')
    console.log(state)
    this.setState({
      producto: {
        active: true,
        productoSeleccionado: [],
        costeOperativo: 0,
      },
      productoVisual:{productoSeleccionado: []},
      productosPrecios:[],
      showList: false,
      saveButtonDisabled: true,
    comercialProductos: [],
    productosPreciosPeriodoDia: [],
    productSelected: 'SIN PRODUCTO SELECCIONADO',
    toggledClearRows: !this.state.toggledClearRows


    });
    notifyError();
    //notifyError();
  }
  /* else {     console.log('EStá dentro del producto en el if cuando el valor es ??????')
      console.log(state)
      state.selectedCount = 0
      this.setState({
        producto: {
          active: true,
          productoSeleccionado: [],
          costeOperativo: 0,
        },
        productoVisual:{productoSeleccionado: []},
      productosPrecios:[],
        productosPreciosPeriodoDia:[],
        showList: false,
        productSelected: 'SIN PRODUCTO SELECCIONADO',
        saveButtonDisabled: true,
          comercialProductos: [],
          toggledClearRows: !this.state.toggledClearRows
      });

    notifyError();}
*/
};

  handleChange = state => {
  // eslint-disable-next-line no-console


  const sumOfEnergy2 = state.selectedRows.reduce((sum, currentValue) => {
    return sum + Number(currentValue.ConsumoAnualTotal);
    }, 0);
    //console.log('VALOR DE SELECCION')
  //console.log(sumOfEnergy2);
  //console.log('state', state.selectedRows);
  // eslint-disable-next-line react/no-unused-state
  this.setState({ selectedRows: state.selectedRows });
  //console.log("select",state.selectedRows)

  if(state.selectedCount > 1){
    this.setState({selectProductButtonDisabled:false})
  }else{
    this.setState({selectProductButtonDisabled:true})
  }

  //const paginatedData = { items };
  //const paginatedDataTemp = { items };

  const sumOfEnergy = state.selectedRows.reduce((sum, currentValue) => {
    return sum + Number(currentValue.ConsumoAnualTotal);
    }, 0);


  const sumOfEnergyLast = state.selectedRows.reduce((sum, currentValue) => {
    return sum + Number(currentValue.ConsumoAnualTotalAnterior);
    }, 0);


  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue.nombreEmpresaDistribuidora] = result[currentValue.nombreEmpresaDistribuidora] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const groupByComer = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue.nombreEmpresaDistribuidora] = result[currentValue.nombreEmpresaDistribuidora] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const groupByATR = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue.codigoPeajeEnVigor] = result[currentValue.codigoPeajeEnVigor] || []).push(
        currentValue
      );
      return result;
    }, {});
  };


  const cupsGroupedBydistri = groupBy(state.selectedRows, "nombreEmpresaDistribuidora");
  const cupsGroupedByComer = groupByComer(state.selectedRows, "nombreEmpresaDistribuidora");
  const cupsGroupedByATR = groupByATR(state.selectedRows, "codigoPeajeEnVigor");

  var EnergiaTotalDistribuidora  : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.nombreEmpresaDistribuidora]) {
      res[value.nombreEmpresaDistribuidora] = { Id: value.nombreEmpresaDistribuidora, TotalConsumoAnual: 0 };
      EnergiaTotalDistribuidora.push(res[value.nombreEmpresaDistribuidora])
    }
    res[value.nombreEmpresaDistribuidora].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});

  var EnergiaTotalATR : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.codigoPeajeEnVigor]) {
      res[value.codigoPeajeEnVigor] = { Id: value.codigoPeajeEnVigor, TotalConsumoAnual: 0 };
      EnergiaTotalATR.push(res[value.codigoPeajeEnVigor])
    }
    res[value.codigoPeajeEnVigor].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});




  var EnergiaTotalComercial  : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.nombreEmpresaDistribuidora]) {
      res[value.nombreEmpresaDistribuidora] = { Id: value.nombreEmpresaDistribuidora, TotalConsumoAnual: 0 };
      EnergiaTotalComercial.push(res[value.nombreEmpresaDistribuidora])
    }
    res[value.nombreEmpresaDistribuidora].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});


var EnergiaTotalComercialCont  : string[] = [];
state.selectedRows.reduce(function(res, value) {
if (!res[value.nombreEmpresaDistribuidora]) {
res[value.nombreEmpresaDistribuidora] = { Id: value.nombreEmpresaDistribuidora, TotalContador:0, TotalConsumoAnual: 0 };
EnergiaTotalComercialCont.push(res[value.nombreEmpresaDistribuidora])
}
res[value.nombreEmpresaDistribuidora].TotalContador += 1;
res[value.nombreEmpresaDistribuidora].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
return res;
}, {});




  var EnergiaTotalATR : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.codigoPeajeEnVigor]) {
      res[value.codigoPeajeEnVigor] = { Id: value.codigoPeajeEnVigor, TotalConsumoAnual: 0 };
      EnergiaTotalATR.push(res[value.codigoPeajeEnVigor])
    }
    res[value.codigoPeajeEnVigor].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});

  var EnergiaTotalATRCont : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.codigoPeajeEnVigor]) {
      res[value.codigoPeajeEnVigor] = { Id: value.codigoPeajeEnVigor, TotalContador: 0, TotalConsumoAnual: 0  };
      EnergiaTotalATRCont.push(res[value.codigoPeajeEnVigor])
    }
    res[value.codigoPeajeEnVigor].TotalContador += 1;
    res[value.codigoPeajeEnVigor].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});


  var EnergiaTotalProvincia : string[] = [];
  state.selectedRows.reduce(function(res, value) {
    if (!res[value.desProvinciaPS]) {
      res[value.desProvinciaPS] = { Id: value.desProvinciaPS, TotalContador: 0, TotalConsumoAnual: 0  };
      EnergiaTotalProvincia.push(res[value.desProvinciaPS])
    }
    res[value.desProvinciaPS].TotalContador += 1;
    res[value.desProvinciaPS].TotalConsumoAnual += Number(value.ConsumoAnualTotal);
    return res;
  }, {});

    var fixedArray = EnergiaTotalComercial.map(function(item) {
      //console.log(item)
if (item['Id'] === null) {
    item['Id'] = "SIN DATOS";
}
return item;
});


var fixedArrayCont = EnergiaTotalComercialCont.map(function(item) {
//console.log(item)
if (item['Id'] === null) {
item['Id'] = "SIN DATOS";
}
return item;
});


var fixedArrayProvincia = EnergiaTotalProvincia.map(function(item) {
//console.log(item)
if (item['Id'] === null) {
item['Id'] = "SIN DATOS";
}
return item;
});

  const   cupsGroupedBydistriEnergy = EnergiaTotalDistribuidora
  const   cupsGroupedByATREnergy = EnergiaTotalATR
  const   cupsGroupedByComercialEnergy = EnergiaTotalComercial
  const cupsGroupedByComercialEnergyCont = EnergiaTotalComercialCont
  const cupsGroupedByProvincia = EnergiaTotalProvincia






var periodosCupStringTemp= 0;
var periodosCupStringActual= 0;
  if(state.selectedCount === 1){
    if (['31', '32', '33', '34'].indexOf(state.selectedRows[0].codigoPeajeEnVigor) >= 0) {
         periodosCupStringTemp = 1;
       } else  if (state.selectedRows[0].codigoTarifaATREnVigor ==='35') {
         periodosCupStringTemp = 2;
       } else {
         periodosCupStringTemp = 2;


   }

 } else if (state.selectedCount > 1){
   if (['31', '32', '33', '34'].indexOf(state.selectedRows[0].codigoPeajeEnVigor) >= 0) {
        periodosCupStringActual= 1;
      } else  if (state.selectedRows[0].codigoTarifaATREnVigor ==='35') {
        periodosCupStringActual = 2;
      } else {
        periodosCupStringActual = 2;
  }

}


  if (state.selectedCount === 1) {

    this.setState({
        sumOfEnergy: sumOfEnergy,
        sumOfEnergyLast: sumOfEnergyLast,
        cupsGroupedBydistri: cupsGroupedBydistri,
        cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
        cupsGroupedByATREnergy: cupsGroupedByATREnergy,
        cupsGroupedByComer: cupsGroupedByComer,
        cupsGroupedByATR: cupsGroupedByATR,
        cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
        EnergiaTotalATRCont: EnergiaTotalATRCont,
        cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
        cupsGroupedByProvincia: cupsGroupedByProvincia,
        periodosCupStringTemp : periodosCupStringTemp
    });

    this.setState(
        ({ periodosCupStringTemp }) => ({
          periodosCupStringTemp:periodosCupStringTemp
        }), () => {
          this.setState(({ periodosCupStringTemp }) => ({
            periodosCupStringTemp:periodosCupStringTemp
          }))
        } )
  } else if (state.selectedCount > 1 && (this.state.periodosCupStringTemp === periodosCupStringActual)) {

    this.setState({
        sumOfEnergy: sumOfEnergy,
        sumOfEnergyLast: sumOfEnergyLast,
        cupsGroupedBydistri: cupsGroupedBydistri,
        cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
        cupsGroupedByATREnergy: cupsGroupedByATREnergy,
        cupsGroupedByComer: cupsGroupedByComer,
        cupsGroupedByATR: cupsGroupedByATR,
        cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
        EnergiaTotalATRCont: EnergiaTotalATRCont,
        cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
        cupsGroupedByProvincia: cupsGroupedByProvincia,
    });
    this.setState(
        ({ periodosCupStringTemp }) => ({
          periodosCupStringTemp:periodosCupStringTemp
        }), () => {
          this.setState(({ periodosCupStringTemp }) => ({
            periodosCupStringTemp:periodosCupStringTemp
          }))
        } )
  } else if (state.selectedCount === 0) {
  }else {
notifyWarning();
this.setState({
    sumOfEnergy: sumOfEnergy,
    sumOfEnergyLast: sumOfEnergyLast,
    cupsGroupedBydistri: cupsGroupedBydistri,
    cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
    cupsGroupedByATREnergy: cupsGroupedByATREnergy,
    cupsGroupedByComer: cupsGroupedByComer,
    cupsGroupedByATR: cupsGroupedByATR,
    cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
    EnergiaTotalATRCont: EnergiaTotalATRCont,
    cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
    cupsGroupedByProvincia: cupsGroupedByProvincia,
    comercialProductos: [],
    producto: {
      active: true,
      productoSeleccionado: [],
      costeOperativo: 0,
    },
    productoVisual:{productoSeleccionado: []},
    selectProductButtonDisabled:true


});
this.forceUpdate()
}
};


  fetchOfertas = async () => {

    //console.log("FETCH");
    var periodosCupsPotencia = ''
    var periodosCupString = ''

  //console.log('VALOR DE This.props.sip en principal-------------> ', this.props)
  //console.log(this.state)


 //var codigoATRContrato = this.state.selectedRows[0].codigoTarifaATREnVigor
 //console.log(codigoATRContrato

console.log('VAlor de this.state ---------->', this.state)
console.log('VAlor de this.props ---------->', this.props)

 if (['31', '32', '33', '34'].indexOf(this.state.selectedRows[0].codigoPeajeEnVigor) >= 0) {
   periodosCupString = '1';
 } else {
   periodosCupString = '2';
 }


      const companyUser = await JSON.parse(await localStorage.getItem("user")!);
      const companyIdUser = companyUser.companyId;
    //  const periodosCupString = String(this.props.periods);
      //const periodosCupsPotencia = String(this.props.periods);
      const consumoGasAnual= this.state.selectedRows[0].ConsumoAnualTotal;
      const tarifa = this.state.selectedRows[0].codigoPeajeEnVigor;


      //console.log("FETCHHHH",periodosCupString,periodosCupsPotencia);
      var data:any[]=[];
           await this.props.client.query({
                  query: LIST_COMERCIAL_PRODUCTOSGAS,
                  variables: { companyId: companyIdUser, periodos: periodosCupString, consumoGasAnual: consumoGasAnual, tarifa:tarifa },
                    })
                    .then((result) => {
                        data = result.data.comercialGasProductos;
                console.log('VALORES DE LA CONSULTA EN PRINCIPAL -------------------> ', data)

                  this.setState({ comercialProductos: data })
              });

              //console.log(data)
              let productosPrecios:any[] = [];
              console.log(data.length)


                for( let i=0; i<data.length; i++ )
                {
                      //  let precioEnergiaField = `precioTEP${i}`;
                      //  var precioPotencia = producto[precioPotenciaField];
                      //  let precioEnergia = producto[precioEnergiaField] + costeOperativo;
      console.log('ESTOY DENTRO DE IF en productos precios')
      console.log(data[i].nombreOferta)



                        productosPrecios.push({ producto:data[i].nombreOferta, precioTerminoFijo: Number(data[i].precioTFijo), precioEnergiaP1: Number(data[i].precioTE1), precioEnergiaP2: Number(data[i].precioTE2) });
                    }

      console.log('VALOR DE productos precios antes del state', productosPrecios)


      this.setState(  {
                    productosPrecios: productosPrecios
                });


  }

handleButtonClick = () => {
  // eslint-disable-next-line no-console
  //console.log('clicked');
};

private handleRowSelected(element) {
element.setSelectedValue = [element.selectedRows[0]];
element.selectedRows.splice(1)

element.allSelected = false;
element.selectedCount = 1;
element.selectedRows.length = 1;
}

render() {

//const datoProducto:any = this.state.producto.productoSeleccionado
//console.log('VALOR DE THIS.STATE.PRODUCTO.PRODUCTOSELECCIONADO')
//console.log(this.state.producto.productoSeleccionado)



    let noResults = <></>;
    if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
      if (this.state.isSearching) {
        noResults = (
          <p className="alert alert-danger">No se encontraron resultados</p>
        );
      } else {
        noResults = (
          <p className="alert alert-info">
            Realice su busqueda utilizando el formulario de arriba
          </p>
        );
      }
    }

const ds = new DataSet();
    const data4:any[] = this.state.cupsGroupedByATREnergy;
    //console.log('VALORES DE this.state.cupsGroupedByATR---->', this.state.cupsGroupedByATREnergy)
    const dv = ds.createView().source(data4);
    //console.log('valores de dv', dv)
    const cols = {
  		TotalConsumoAnual: {
  			formatter: val => {
  				val = val + 'kW';
  				return val;
  			},
  		},
  	};


//const dsPrecios = new DataSet();
const data10:any[] = this.state.productosPrecios;
//console.log('VALOR DE DATA 10 ', data10)
//console.log('VALOR DE DV', dv)
const dvPrecios = ds.createView().source(data10);
//console.log('VALOR DE DV 2', dv)
//console.log('VALOR DE dvPRECIOS',dvPrecios)
//console.log(dvPrecios);
    dvPrecios.transform({
    	type: 'fold',
    	fields: [
        'precioEnergiaP1',
        'precioEnergiaP2',
        'precioTerminoFijo'
    	],
    	//   fields: ['time'],
    	// 展开字段集
    	key: 'key',
    	// key字段
    	value: 'value', // value字段
    })
    	.transform({
    		type: 'map',
    		callback: (obj) => {
    			if (obj.key.indexOf('Energia') !== -1) {
    				obj.type = 'Precio Energía'
    			} else if (obj.key.indexOf('Potencia') !== -1) {
    				obj.type = 'Precio Term. Fijo'
    			}
    			obj.level = obj.key
    		//	console.log(obj)
    			return obj;
    		},
    	});
  console.log('DVPRECIOS ',dvPrecios.rows)

//console.log('VALOR DE THIS.STATE EN EL RENDER -------<');
//console.log(this.state)
if(Object.keys(this.state.paginatedData.items[0]).length > 2){
  //console.log('ENTRA EN EL OBJETO y TIENE DATOS')
  //console.log(this.state.paginatedData.items)
  const data2 = this.state.paginatedData.items
} else {
  //console.log('ENTRA EN EL OBJETO y no TIENE DATOS')
  //console.log(this.state.paginatedData.items)
  const data2:any[] = [];}

  const contATR = this.state.EnergiaTotalATRCont
  const contComer = this.state.cupsGroupedByComercialEnergyCont

//const productos: any[] = this.state.comercialProductos;
console.log('VALORES DE THIS STATE EN RENDER', this.state)
if(this.state.gas && !this.state.showFreeMarketButton) {
    return (
      <>
        <Header title="PROPUESTAS MULTIPUNTO" />

        <Container className=" mt--7" fluid>
          <div className="row">
            <div className="col-md-6 col-xl-6">

          <SipsGasFilter onSubmit={this.onSubmit}></SipsGasFilter>

  </div>
  <div className="col-md-6 col-xl-6">
    <div className="card-stats card">
  <div className="card-body">
    <div className = "row">

    <h5 className = "text-uppercase text-muted mb-0 card-title"> Tarifa ATR kWh Anual</h5>


    <Chart height={250} data={data4} scale={cols} autoFit onGetG2Instance={c => {
			c.geometries[0].elements.forEach((e, idx) => {
				e.setState('selected', idx === 0 ? true : false);
			})
		}}>
			<Coordinate type="theta" radius={0.75} />
			<Tooltip showTitle={false} />
			<Axis visible={false} />
			<Interval
				position="TotalConsumoAnual"
				adjust="stack"
				color="Id"
				style={{
					lineWidth: 1,
					stroke: '#fff',
				}}
				label={['count', {
					content: (dv) => {
						return `${dv.Id}: ${dv.TotalConsumoAnual / 1000} MW`;
					},
				}]}
			/>
			<Interaction type='element-single-selected' />
		</Chart>

</div>
</div>
</div>
</div>


</div>


        <div className="row">
          <div className="col-md-6 col-xl-6">
            <div className="card-stats card">
          <div className="card-body">
            <div className = "row">
        <DataTable
             title="DATOS GENERALES CONTRATOS"
             columns={columnsDatosR1}
             data = {contATR}
             sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
             fixedHeader = {true}
             onSelectedRowsChange={this.handleChange}
             keyField = "cups"
             highlightOnHover
             striped
             pointerOnHover
             selectableRowsHighlight
             customStyles={customStyles}
             contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
             noDataComponent = {"Sin datos para mostrar"}

           />
         </div>
      </div>
      </div>
      </div>
      <div className="col-md-6 col-xl-6">
      <div className="card-stats card">
        <div className="card-body">
          <div className = "row">

           <DataTable
                title="DATOS GENERALES COMERCIALIZADORA"
                columns={columnsDatosR2}
                data = {contComer}
                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                fixedHeader = {true}
                onSelectedRowsChange={this.handleChange}
                keyField = "cups"
                highlightOnHover
                striped
                pointerOnHover
                selectableRowsHighlight
                customStyles={customStyles}
                contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                noDataComponent = {"Sin datos para mostrar"}

              />
            </div>
      </div>
      </div>
      </div>
      </div>

      <Card className="mb-4">

      <CardHeader>

          {/* <h3 className="mb-0">Datos del registro de empresa</h3> */}
          Productos de comercializadoras disponibles:
          {this.state.productSelected}
        </CardHeader>

        <DataTable
          title="Productos"
          columns={columnsDatosR3}
          data = {this.state.comercialProductos}
          selectableRows
          selectableRowsComponent={<Checkbox/>} // Pass the function only
          onSelectedRowsChange={this.handleChangeContrato}
          selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
          sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
          fixedHeader = {true}
          highlightOnHover
          striped
          pointerOnHover
          selectableRowsHighlight
          selectableRowsSingle
          selectableRowsNoSelectAll={true}
          customStyles={customStyles}
          noDataComponent = {"Sin datos para mostrar"}
          contextMessage = {{ singular: 'Oferta Comercial', plural: 'Ofertas Comerciales', message: 'seleccionada' }}
          subHeader
          subHeaderComponent={
         <div style={{ display: 'flex', alignItems: 'center' }}>

           <Button  disabled = {this.state.saveButtonDisabled || this.state.selectProductButtonDisabled} id = "guardarProptooltip" color="black" size="m" className="float-right"
             onClick={(event) =>
               this.toggleModal(event, "saveModalOpened")
             }

                               >
             <i className={"fas fa-file-contract fa-3x"}></i>
             <UncontrolledTooltip delay={0} placement="top" target="guardarProptooltip">
         GUARDAR PROPUESTAS
       </UncontrolledTooltip>
           </Button>
         </div>
       }

       clearSelectedRows={this.state.toggledClearRows}

        />


      </Card>
<div className="row">
      <div className="col-md-6 col-xl-6">
        <div className="card-stats card">
      <div className="card-body">
        <div className = "row">

        <h5 className = "text-uppercase text-muted mb-0 card-title"> Gráfica comparativa Productos</h5>
      <Chart height={400} data={dvPrecios.rows} autoFit>

				<Geom
					type="interval"
					position="producto*value"
					color="level"
					style={{
						stroke: '#fff',
						lineWidth: 1,
					}}
					adjust={[
						{
							type: 'dodge',
							dodgeBy: 'type',
							marginRatio: 0,
						},
						{
							type: 'stack',
						},
					]}
				>
				</Geom>
			</Chart>

    </div>
    </div>
    </div>
    </div>

    <div className="col-md-6 col-xl-6">
      <div className="card-stats card">
    <div className="card-body">
      <div className = "row">

      <h5 className = "text-uppercase text-muted mb-0 card-title"> Gráfica comparativa Precios Periodos/Mes</h5>




    {/*}<Chart padding={[10, 20, 50, 40]} autoFit height={300} data={this.state.productosPreciosPeriodoDia} >
    		<LineAdvance
    			shape="smooth"
    			point
    			area
    			position="periodo*PrecioEnergía"
    			color="mes"
    		/>

    	</Chart>*/}

      <Chart height={400} padding="auto" data={this.state.productosPreciosPeriodoDia} autoFit>
  <Interval
    adjust={[
     {
        type: 'dodge',
        marginRatio: 1,
      },
    ]}
    color="mes"
    position="periodo*PrecioEnergía"
  />
  <Coordinate type="polar"/>
  <Tooltip shared />
</Chart>


    </div>
    </div>
    </div>
    </div>
      </div>






          <Card className="mb-4">

          <CardHeader>

              {/* <h3 className="mb-0">Datos del registro de empresa</h3> */}
              Producto seleccionado:
              {this.state.productSelected}
            </CardHeader>

            <DataTable
              title="Información del producto seleccionado"
              columns={columnsDatosR3}
              data = {this.state.productoVisual.productoSeleccionado}
              sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
              fixedHeader = {true}
              keyField = "nombreOferta"
              striped
              pointerOnHover
              customStyles={customStyles}
              noDataComponent = {"Sin datos para mostrar"}


            />


          </Card>
          <Row>
            <div className="col">
              <Card className="shadow">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text="Cargando sips..."
                >
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="4">
                        <h3 className="mb-0">SIPS</h3>
                      </Col>
                      <Col md="8" className="text-right">
                        <Button
disabled = {this.state.selectProductButtonDisabled}
                          onClick={this.openModalComparativa}

                          id = "elegirProtooltip"
                          color="black" size="m" className="float-right"
                        >
                          <i className="fas fa-check-square fa-3x "></i>

                          <UncontrolledTooltip delay={0} placement="top" target="elegirProtooltip">
                      ELEGIR PRODUCTO
                    </UncontrolledTooltip>
                  </Button>

                       </Col>
                    </Row>
                  </CardHeader>
                  <DataTable
                    title="Puntos de Suministros"
                    columns={columns(this.handleButtonClick)}
                    data = {this.state.paginatedDataTemp.items}
                    theme="solarized"
                    selectableRows
                    selectableRowsComponent={<Checkbox/>} // Pass the function only
                    selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                    fixedHeader = {true}
                    onSelectedRowsChange={this.handleChange}
                    keyField = "cups"
                    highlightOnHover
      				      striped
      				      pointerOnHover
                    selectableRowsHighlight
                    customStyles={customStyles}
                    contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                    noDataComponent = {"Sin datos para mostrar"}
                    clearSelectedRows={this.state.toggledClearRowsPS}


                  />
                  <CardFooter className="py-4">
                    {noResults}

                    <nav aria-label="...">
                      <Pagination
                        {...this.state}
                        onPageClick={this.onPageClick}
                      ></Pagination>
                    </nav>
</CardFooter>

                </LoadingOverlay>
              </Card>
            </div>

          </Row>
        </Container>

        <SaveGasModalMultips
          isOpen={this.state.saveModalOpened}
          toggle={(event) => this.toggleModal(event, "saveModalOpened")}
          onSave={this.onSave}
          sip={this.state}
          ubi={this.state.titular.data} //titular
          nombrePropuesta={""}
        ></SaveGasModalMultips>

        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
              </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }
  }
}

export default withApollo(MultipsGas);

import React from "react";
import Reflux from "reflux";
import axios from "axios";
import { customAlphabet } from "nanoid";
import {
  Button,
  Card,
  CardBody,
  Label,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Col,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import { withApollo } from "react-apollo";
import LoadingOverlay from "react-loading-overlay";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import "moment/locale/es";
//import { uuid } from "uuidv4";
//import { uuid } from 'uuidv4';
import { validateSpanishId, spainIdType } from "spain-id";
// core components
import SipsTable from "../../../components/Sips/SipsTableNew";
import SupplyInformation from "../../../components/Cards/SupplyInformation";
import RateInformation from "../../../components/Cards/RateInformation";
import Header from "../../../components/Headers/Header";
import { VIEW_SIPS } from "../../../queries/sips/sips.graphql";
import { consumptionsFields, sipsConsumosFields } from "./SipComparativesData";
import { LIST_SIPS_UBICACION } from "../../../queries/ubicacion/sip-ubicacion";
import { LIST_SIPS_CONSUMOS } from "../../../queries/sips-consumo/sip-consumo";
import SipComparativeFreeMultiModal from "./SipComparativeFreeMultiModal";

import ComercialMultiProducto from "./ComercialMultiProducto";
import SaveModal from "./SaveModal";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../../store/UserStore";
var DatePicker = require("reactstrap-date-picker");
const alphabet = "0123456789ABCD";
const nanoid = customAlphabet(alphabet, 11);
const spanishDayLabels = ["Dom", "Lu", "Ma", "Mi", "Ju", "Vi", "Sab"];
const spanishMonthLabels = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

interface IComparativeFreeState {
  cups: string;
  UserStore: any;
  user: any;
  sip: any;
  nombrePropuesta: string;
  ubicacion: any;
  consumptionsFields: any;
  sipsConsumos: any[];
  consumosEntrada: {
    codigoDHEquipoDeMedida: string;
    codigoTarifaATR: string;
    codigoTipoLectura: string;
    consumoEnergiaActivaEnWhP1: number;
    consumoEnergiaActivaEnWhP2: number;
    consumoEnergiaActivaEnWhP3: number;
    consumoEnergiaActivaEnWhP4: number;
    consumoEnergiaActivaEnWhP5: number;
    consumoEnergiaActivaEnWhP6: number;
    consumoEnergiaReactivaEnVArhP1: number;
    consumoEnergiaReactivaEnVArhP2: number;
    consumoEnergiaReactivaEnVArhP3: number;
    consumoEnergiaReactivaEnVArhP4: number;
    consumoEnergiaReactivaEnVArhP5: number;
    consumoEnergiaReactivaEnVArhP6: number;
    fechaFinMesConsumo: string;
    fechaInicioMesConsumo: string;
    id: string;
    potenciaDemandadaEnWP1: number;
    potenciaDemandadaEnWP2: number;
    potenciaDemandadaEnWP3: number;
    potenciaDemandadaEnWP4: number;
    potenciaDemandadaEnWP5: number;
    potenciaDemandadaEnWP6: number;
  };
  periodos: number[];
  producto: {
    active: boolean;
    productoSeleccionado: any;
    costeOperativo: number;
    costeOperativoPotencia: number;
    esNuevoContrato: boolean;
  };
  productoMulti: {
    active: boolean;
    productoSeleccionado: any[];
    costeOperativo: number;
    costeOperativoPotencia: number;
    esNuevoContrato: boolean;
  };
  tableHeaders: any[];
  comparatives: any[];
  isDisabled: boolean;
  isDisabledProductos: boolean;
  isLoading: boolean;
  isLoadingConsumos: boolean;
  isSavingPropuesta: boolean;
  itemsSelected: any[];
  openModalComparative: boolean;
  openModalMultiComparative: boolean;
  comercialProductoSelected: any;
  showList: boolean;
  dataComparativeTarget: any;
  saveModalOpened: boolean;
  datosContrato: {
    Usuario: string;
    fechaPropuesta: string;
    FechaAprobacion: string;
    IdOferta: string;
    T_Titular: {
      RazonSocial: string; //":"MANUEL GONZALEZ",--> nombre_completo_titular
      NIF: string; //"C19345456", --> CIF
      DireccionFiscal: string; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
      CodigoPostalTitular: string; //"08630",----> codigo_postal_titular
      TelefonoFijo: string; //"949201893", ----> telefono_fijo
      TelefonMovil: string; //"689105332", ----> telefono
      Email: string; //"demo@demo.com", --->email_contacto
      TipoCliente: string; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
      ObservacionesCliente: string;
      GDPR: boolean; //"OPCIONAL O NULL",----> observaciones
    };
    T_Representante: {
      nombreRepresentante: string;
      apellido1Representante: string;
      apellido2Representante: string;
      NIFRepresentante: string;
      idRepresentante: string;
      idRepresentanteInterno: number;
      CargoRepresentante: string;
      telefonoRepresentante: string;
      correoRepresentante: string;
    };
    T_PuntoSuministro: {
      Direccion: string; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
      CodigoPostal: string; //"08630", ----> codigo_postal_ps
      Telefono: string; //"689105332", ----> telefono;
      ObservacionesPuntoSuministro: string; // "opcional o null" ----> observaciones_ps
    };
    T_CUPs: {
      Distribuidora: string; //"IBERDROLA", ----> distribuidora
      CUPsEle: string; //"ES0022000008945731EY", ------> cupShort
      Tarifa: string; //"2.0 DHS", ----> tarifaATR
      P1: string; //"35.25", ----> potenciasContratadasEnWP1
      P2: string; //"35.25", ----> potenciasContratadasEnWP2
      P3: string; //"35.25", ----> potenciasContratadasEnWP3
      P4: string; //"35.25", ----> potenciasContratadasEnWP4
      P5: string; //"35.25", ----> potenciasContratadasEnWP5
      P6: string; //"25.25", ----> potenciasContratadasEnWP6
      PotMaxBie: string; //"5265.25",   ----->Bie
      TipoServicio: string; //"1 Eléctrico, 2 Gas",  ----> TipoServicio
      Consumo: string; //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
    };
    T_PropuestaComercial: {
      PorcentajeAhorro: string; //"0.00",
      ImporteAhorro: string; //"0.00",
      Estado: string; //"P",
      Comercializadora: string; //"AUDAX RENOVABLES S.A.",
      Producto: string; //"CLASSIC",
      IdProducto: string;
      Anexo: string; //"12 MESES T2",
      TipoPrecio: string; //"0 Fijo 1 Indexado",
      ObservacionesProducto: string; //"opcinal o null",
      GastoOpertativo: string; //"0.00 SOLO CUANDO ES CORPORATE",
      idservicio: string;
      idComercializadora: string;
      nombreServicio: string;
      precioServicio: string;
      productSelected: any;
    };
  };
  rates2X: any[];
  rates3X: any[];
  rates2XP: any[];
  rates1XP: any[];
  rates3XP: any[];
  rates6XP: any[];
  rates1XE: any[];
  rates2XE: any[];
  rates3XE: any[];
  companyID: any;
  userId: any;
  nombreAgente: any;
  companyName: any;
  correoAgente: any;
  rates6XE: any[];
  comparativeTemp: {
    producto: any;
    enabledSave: boolean;
    actual: any;
    comparativaGlobal: any;
    propuestasAnalizadas: any;
    datosResumenContrato: any;
    datosComision:{
      dataComisionCo: any,
      dataComision: any,
      dataComisionCoTarifa:any
    };
  };
  alert: any;
  fechaInicioMesConsumo: any;
  fechaFinMesConsumo: any;
  comerciales: any;
  consumo1: number;
  consumo2: number;
  consumosEnergia: any[];
  consumosPotencia: any[];
}

interface IComparativeFreeProps {}

class SipComparativeFree extends Reflux.Component<any, any> {
  private dataToSave = {
    companyId: null,
    nombreOferta: null,
    idComercializadora: null,
    nombreComercializadora: null,
    idComercializadoraActual: null,
    nombreComercializadoraActual: null,
    datosPreciosActual: null,
    userId: null,
    fechaPropuesta: null,
    codigoPostalPS: null,
    codigoMunicipioPS: null,
    nombreMunicipioPS: null,
    codigoProvinciaPS: null,
    nombreProvinciaPS: null,
    codigoAutonomiaPS: null,
    nombreAutonomiaPS: null,
    esAprobada: false,
    datosPropuesta: null,
    correoContacto: null,
    cups: null,
    //datosContrato: null,
  };
  //public comparativeTemp: any = null;
  //this.state.comparativeTemp.producto
  public enabledSave: boolean = false;
  public formComparativeRef: any = null;
  public formSaveComparativeRef: any = null;
  public UserStore: any = {};

  public state: IComparativeFreeState = {
    comparativeTemp: {
      producto: [{ tarifaATR: "000" }],
      enabledSave: true,
      actual: [],
      comparativaGlobal: {},
      propuestasAnalizadas: {},
      datosResumenContrato: {},
      datosComision:{
        dataComisionCo: [{}],
        dataComision: [{}],
        dataComisionCoTarifa:[{}]
      }
    },
    cups: "",
    user: {},
    sip: {},
    ubicacion: {},
    nombrePropuesta: "",
    consumptionsFields: {},
    sipsConsumos: [],
    consumosEntrada: {
      codigoDHEquipoDeMedida: "0",
      codigoTarifaATR: "00",
      codigoTipoLectura: "0",
      consumoEnergiaActivaEnWhP1: 0,
      consumoEnergiaActivaEnWhP2: 0,
      consumoEnergiaActivaEnWhP3: 0,
      consumoEnergiaActivaEnWhP4: 0,
      consumoEnergiaActivaEnWhP5: 0,
      consumoEnergiaActivaEnWhP6: 0,
      consumoEnergiaReactivaEnVArhP1: 0,
      consumoEnergiaReactivaEnVArhP2: 0,
      consumoEnergiaReactivaEnVArhP3: 0,
      consumoEnergiaReactivaEnVArhP4: 0,
      consumoEnergiaReactivaEnVArhP5: 0,
      consumoEnergiaReactivaEnVArhP6: 0,
      fechaFinMesConsumo: "",
      fechaInicioMesConsumo: "",
      id: "bb0f5569-c86b-433d-9fa4-8e18f5cdf1e2",
      potenciaDemandadaEnWP1: 0,
      potenciaDemandadaEnWP2: 0,
      potenciaDemandadaEnWP3: 0,
      potenciaDemandadaEnWP4: 0,
      potenciaDemandadaEnWP5: 0,
      potenciaDemandadaEnWP6: 0,
    },
    consumosEnergia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    consumosPotencia: [
      {
        fecha: "",
        P1: 0,
        P1Color: "hsl(22, 70%, 50%)",
        P2: 0,
        P2Color: "hsl(303, 70%, 50%)",
        P3: 0,
        P3Color: "hsl(37, 70%, 50%)",
        P4: 0,
        P4Color: "hsl(338, 70%, 50%)",
        P5: 0,
        P5Color: "hsl(176, 70%, 50%)",
        P6: 0,
        P6Color: "hsl(121, 70%, 50%)",
      },
    ],
    itemsSelected: [],
    comercialProductoSelected: null,
    tableHeaders: [],
    comparatives: [],
    periodos: [],
    producto: {
      active: false,
      productoSeleccionado: null,
      costeOperativo: 0,
      costeOperativoPotencia: 0,
      esNuevoContrato: true,
    },
    productoMulti: {
      active: false,
      productoSeleccionado: [],
      costeOperativo: 0,
      costeOperativoPotencia: 0,
      esNuevoContrato: true,
    },

    // --- View State
    isDisabled: true,
    isDisabledProductos: false,
    isLoading: true,
    isLoadingConsumos: true,
    isSavingPropuesta: false,
    openModalComparative: false,
    openModalMultiComparative: false,
    showList: true,
    dataComparativeTarget: null,
    saveModalOpened: false,
    datosContrato: {
      Usuario: "",
      fechaPropuesta: "",
      FechaAprobacion: "",
      IdOferta: "",
      T_Titular: {
        RazonSocial: "", //":"MANUEL GONZALEZ",--> nombre_completo_titular
        NIF: "", //"C19345456", --> CIF
        DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
        CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
        TelefonoFijo: "", //"949201893", ----> telefono_fijo
        TelefonMovil: "", //"689105332", ----> telefono
        Email: "", //"demo@demo.com", --->email_contacto
        TipoCliente: "", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
        ObservacionesCliente: "",
        GDPR: false, //"OPCIONAL O NULL",----> observaciones
      },
      T_Representante: {
        nombreRepresentante: "",
        apellido1Representante: "",
        apellido2Representante: "",
        NIFRepresentante: "",
        idRepresentante: "",
        idRepresentanteInterno: 0,
        CargoRepresentante: "",
        telefonoRepresentante: "",
        correoRepresentante: "",
      },
      T_PuntoSuministro: {
        Direccion: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
        CodigoPostal: "", //"08630", ----> codigo_postal_ps
        Telefono: "", //"689105332", ----> telefono;
        ObservacionesPuntoSuministro: "", // "opcional o null" ----> observaciones_ps
      },
      T_CUPs: {
        Distribuidora: "", //"IBERDROLA", ----> distribuidora
        CUPsEle: "", //"ES0022000008945731EY", ------> cupShort
        Tarifa: "", //"2.0 DHS", ----> tarifaATR
        P1: "0", //"35.25", ----> potenciasContratadasEnWP1
        P2: "0", //"35.25", ----> potenciasContratadasEnWP2
        P3: "0", //"35.25", ----> potenciasContratadasEnWP3
        P4: "0", //"35.25", ----> potenciasContratadasEnWP4
        P5: "0", //"35.25", ----> potenciasContratadasEnWP5
        P6: "0", //"25.25", ----> potenciasContratadasEnWP6
        PotMaxBie: "", //"5265.25",   ----->Bie
        TipoServicio: "", //"1 Eléctrico, 2 Gas",  ----> TipoServicio
        Consumo: "", //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
      },
      T_PropuestaComercial: {
        PorcentajeAhorro: "", //"0.00",
        ImporteAhorro: "", //"0.00",
        Estado: "", //"P",
        Comercializadora: "", //"AUDAX RENOVABLES S.A.",
        Producto: "", //"CLASSIC",
        IdProducto: "",
        Anexo: "", //"12 MESES T2",
        TipoPrecio: "", //"0 Fijo 1 Indexado",
        ObservacionesProducto: "", //"opcinal o null",
        GastoOpertativo: "", //"0.00 SOLO CUANDO ES CORPORATE",
        idservicio: "",
        idComercializadora: "",
        nombreServicio: "",
        precioServicio: "",
        productSelected: null,
      },
    },
    rates2X: ["001", "004", "005", "006", "007", "008"],
    rates3X: ["011", "003", "012", "013", "014", "015", "016"],
    rates2XP: ["018"],
    rates1XP: ["001", "004", "005", "006", "007", "008"],
    rates3XP: ["011", "003"],
    rates6XP: [
      "012",
      "013",
      "014",
      "015",
      "016",
      "019",
      "020",
      "021",
      "022",
      "023",
      "024",
      "025",
    ],
    rates1XE: ["001", "005"],
    rates2XE: ["004", "006"],
    rates3XE: ["011", "003", "007", "008", "018"],
    rates6XE: [
      "012",
      "013",
      "014",
      "015",
      "016",
      "019",
      "020",
      "021",
      "022",
      "023",
      "024",
      "025",
    ],
    companyID: "",
    userId: "",
    nombreAgente: "",
    companyName: "",
    correoAgente: "",
    UserStore: {},
    alert: null,
    fechaInicioMesConsumo: "",
    fechaFinMesConsumo: "",
    consumo1: 0,
    consumo2: 0,
    comerciales: {
      NIFComercial: "",
      Responsable: "",
      apellido1Comercial: "",
      apellido2Comercial: "",
      comisionYear1: 0,
      comisionYear2: 0,
      correoComercial: "",
      idComercial: "",
      idComercialInterno: "",
      idCompany: 2,
      idResponsable: "",
      nombreComercial: "",
      telefonoComercial: "",
      zonaGeograficaComercial: "",
    },
  };

  constructor(props: IComparativeFreeProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.store = UserStore;
    this.showMultiModal = this.showMultiModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleMulti = this.toggleMulti.bind(this);
    this.toggleMultiClose = this.toggleMultiClose.bind(this);
    this.comparativeDataHandler = this.comparativeDataHandler.bind(this);
    //this.FunctionComponentWithRef = this.FunctionComponentWithRef.bind(this);

    this.changeSave = this.changeSave.bind(this);
  }

  comparativeDataHandler(data) {
    this.state.comparativeTemp = data;
    this.enabledSave = this.state.comparativeTemp.enabledSave;
    let enabledSaveTemp: boolean = this.state.comparativeTemp.enabledSave;
  }

  fetchItemsComercial = async () => {
    const parsed = await JSON.parse(await localStorage.getItem("user")!);
    const userId = parsed.id;
    const companyIdUser = Number(parsed.companyId);

    var dataFilters = {
      idCompany: companyIdUser,
      cups: "",
      Usuario: "",
      NIFComercial: "",
      idContrato: "",
    };

    const valores = JSON.stringify(dataFilters);
    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };

    let direccionFile =
      "https://datapi.psgestion.es/comercial/datos?id=" +
      companyIdUser +
      "&user=" +
      userId;

    await axios
      .get(direccionFile, { headers, params: { valores } })
      .then((result) => {
        let data = result.data.rows[0];
        this.setState({ comerciales: data });
      })
      .catch((error) => {
        console.log("Failure");
      });
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const usuario: any = JSON.parse(
      JSON.stringify(this.state.UserStore["data"])
    );
    const companyNameGlobal = usuario.company;
    const companyIdUser = usuario.companyId;

    const userGroupId = usuario.user_group_id;
    const userId = usuario.id;
    const nombreAgente = usuario.fullname;
    const correoAgente = usuario.email;

    this.setState(
      {
        cups: params.cups,
        consumptionsFields: consumptionsFields,
        companyID: companyIdUser,
        userId: userId,
        user: userId,
        companyName: companyNameGlobal,
        nombreAgente: nombreAgente,
        correoAgente: correoAgente,
      },
      () => {
        this.fetchItemsComercial();
        this.fetchSip();
        this.fetchUbi();
        this.fetchComparatives();
      }
    );
    this.formComparativeRef = React.createRef();
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  addDays = (date, days) => {
    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + days);
    return dateCopy;
  };

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Correcto"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Propuesta comercial guardada correctamente
        </ReactBSAlert>
      ),
    });
  };

  warningAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Sin consumos seleccionados"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Tienes que seleccionar lecturas de consumos para continuar
        </ReactBSAlert>
      ),
    });
  };

  warningAlertProducto = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Sin datos de factura actual introducidos"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Tienes que introducir los datos de costes de la factura a analizar
        </ReactBSAlert>
      ),
    });
  };

  fetchSip = async () => {
    let tableHeaders = {};
    let periodos: number[] = [];
    await this.props.client
      .query({
        query: VIEW_SIPS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        //Sip
        const sip = result.data.viewSips[0];

        //Header tabla
        let codigoEquipo = "codigoDHEquipoDeMedida";
        let codigoTipoLectura = "codigoTipoLectura";
        let fechaInicioMes = "fechaInicioMesConsumo";
        let fechaFinMes = "fechaFinMesConsumo";
        let codigoTarifa = "codigoTarifaATR";

        tableHeaders[fechaInicioMes] = sipsConsumosFields[fechaInicioMes];
        tableHeaders[fechaFinMes] = sipsConsumosFields[fechaFinMes];
        tableHeaders[codigoTarifa] = sipsConsumosFields[codigoTarifa];

        var periodosNuevos = 0;
        if (
          sip.codigoTarifaATREnVigor == "001" ||
          sip.codigoTarifaATREnVigor == "004" ||
          sip.codigoTarifaATREnVigor == "005" ||
          sip.codigoTarifaATREnVigor == "006" ||
          sip.codigoTarifaATREnVigor == "007" ||
          sip.codigoTarifaATREnVigor == "008" ||
          sip.codigoTarifaATREnVigor == "018"
        ) {
          periodosNuevos = 3;
        } else {
          periodosNuevos = 6;
        }

        for (let i = 1; i <= periodosNuevos; i++) {
          let consumoAnual = `ConsumoAnualActivaP${i}`;
          //  if (sip[consumoAnual] > 0) {
          let consumoEnergiaActiva = `consumoEnergiaActivaEnWhP${i}`;
          let consumoEnergiaReactiva = `consumoEnergiaReactivaEnVArhP${i}`;
          let potenciaDemandada = `potenciaDemandadaEnWP${i}`;
          tableHeaders[consumoEnergiaActiva] =
            sipsConsumosFields[consumoEnergiaActiva];
          tableHeaders[consumoEnergiaReactiva] =
            sipsConsumosFields[consumoEnergiaReactiva];
          tableHeaders[potenciaDemandada] =
            sipsConsumosFields[potenciaDemandada];
          periodos.push(i);
          //}
        }

        tableHeaders[codigoEquipo] = sipsConsumosFields[codigoEquipo];
        tableHeaders[codigoTipoLectura] = sipsConsumosFields[codigoTipoLectura];

        this.setState(
          {
            sip: sip,
            isLoading: false,
            tableHeaders: tableHeaders,
            periodos: periodos,
          },
          this.fetchUbi
        );
      });
  };

  //---------------------------------------//

  fetchUbi = async () => {
    await this.props.client
      .query({
        query: LIST_SIPS_UBICACION,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        //Sip
        const ubi = result.data.findUbicacion;

        this.setState(
          {
            ubicacion: ubi,
          },
          this.fetchItems
        );
      });
  };

  fetchItems = async () => {
    await this.props.client
      .query({
        query: LIST_SIPS_CONSUMOS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        let listSipsConsumos = [
          ...result.data.listSipsConsumos.map((row) => {
            //Header tabla

            let rowResult: any = {};

            let codigoEquipo = "codigoDHEquipoDeMedida";
            let codigoTipoLectura = "codigoTipoLectura";
            let fechaInicioMes = "fechaInicioMesConsumo";
            let fechaFinMes = "fechaFinMesConsumo";
            let codigoTarifa = "codigoTarifaATR";

            const dateValueInicio = new Date(row[fechaInicioMes]);
            let dateInicio = dateValueInicio.toISOString().split("T")[0];
            dateInicio = moment
              .parseZone(dateValueInicio.toISOString().split("T")[0])
              .format("DD-MM-YYYY");

            const dateValueFin = new Date(row[fechaFinMes]);
            let dateFin = dateValueFin.toISOString().split("T")[0];
            dateFin = moment
              .parseZone(dateValueFin.toISOString().split("T")[0])
              .format("DD-MM-YYYY");

            rowResult[fechaInicioMes] = dateInicio;
            rowResult[fechaFinMes] = dateFin;
            rowResult[codigoTarifa] = row[codigoTarifa];
            for (let i of this.state.periodos) {
              let consumoEnergiaActiva = `consumoEnergiaActivaEnWhP${i}`;
              let consumoEnergiaReactiva = `consumoEnergiaReactivaEnVArhP${i}`;
              let potenciaDemandada = `potenciaDemandadaEnWP${i}`;

              rowResult[consumoEnergiaActiva] = parseFloat(
                row[consumoEnergiaActiva]
              );

              rowResult[consumoEnergiaReactiva] =
                parseFloat(row[consumoEnergiaReactiva]) || 0;
              rowResult[potenciaDemandada] = parseFloat(row[potenciaDemandada]);
              /*switch(row[codigoTarifa]) {
                              case '3':
                              case '7':
                              case '8':
                              case '11':
                              rowResult[consumoEnergiaActiva] += parseFloat(row[`consumoEnergiaActivaEnWhP${i+3}`]);
                              break;
                            }*/
            }

            rowResult[codigoEquipo] = row[codigoEquipo];
            rowResult[codigoTipoLectura] = row[codigoTipoLectura];

            if (
              !moment()
                .add(-14, "month")
                .isAfter(moment(rowResult[fechaFinMes], "DD-MM-YYYY"))
            ) {
              rowResult.id = this.uuid();

              return rowResult;
            }
            return false;
          }),
        ].filter((item) => item);

        let consumosEnergia: any = [];

        var monthOld = "";

        for (let i = 0; i < listSipsConsumos.length; i++) {
          var newdate = listSipsConsumos[i].fechaInicioMesConsumo
            .split("-")
            .reverse()
            .join("-");

          let d = new Date(newdate);

          let d2 = new Date(newdate);

          var day = new Intl.DateTimeFormat("es", {
            day: "numeric",
          }).format(d);

          var month = new Intl.DateTimeFormat("es", { month: "short" }).format(
            d
          );
          var year = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            d
          );

          var newdatedate = new Date();

          if (Number(day) > 20) {
            newdatedate = this.addDays(d2, 5);
            var fechaNueva = newdatedate.toISOString();

            month = new Intl.DateTimeFormat("es", {
              month: "short",
            }).format(newdatedate);
            year = new Intl.DateTimeFormat("es", {
              year: "numeric",
            }).format(newdatedate);
          }

          if (i == 0) {
            monthOld = month;
          }

          let P1 = listSipsConsumos[i].consumoEnergiaActivaEnWhP1;
          let P2 = listSipsConsumos[i].consumoEnergiaActivaEnWhP2;
          let P3 = listSipsConsumos[i].consumoEnergiaActivaEnWhP3;
          let P4 = listSipsConsumos[i].consumoEnergiaActivaEnWhP4;
          let P5 = listSipsConsumos[i].consumoEnergiaActivaEnWhP5;
          let P6 = listSipsConsumos[i].consumoEnergiaActivaEnWhP6;

          if (i > 0) {
            if (month == monthOld) {
              P1 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP1) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP1);
              P2 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP2) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP2);
              P3 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP3) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP3);
              P4 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP4) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP4);
              P5 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP5) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP5);
              P6 =
                Number(listSipsConsumos[i].consumoEnergiaActivaEnWhP6) +
                Number(listSipsConsumos[i - 1].consumoEnergiaActivaEnWhP6);
            } else {
              monthOld = month;
            }
          }

          let fecha = `${month} ${year}`;

          consumosEnergia = consumosEnergia.filter(
            (consumosEnergia) => consumosEnergia.fecha != fecha
          );

          consumosEnergia.push({
            fecha: fecha,
            P1: P1,
            P1Color: "hsl(22, 70%, 50%)",
            P2: P2,
            P2Color: "hsl(303, 70%, 50%)",
            P3: P3,
            P3Color: "hsl(37, 70%, 50%)",
            P4: P4,
            P4Color: "hsl(338, 70%, 50%)",
            P5: P5,
            P5Color: "hsl(176, 70%, 50%)",
            P6: P6,
            P6Color: "hsl(121, 70%, 50%)",
          });
        }

        let consumosPotencia: any = [];

        var monthOldPotencia = "";

        for (let i = 0; i < listSipsConsumos.length; i++) {
          var newdate = listSipsConsumos[i].fechaInicioMesConsumo
            .split("-")
            .reverse()
            .join("-");
          let d = new Date(newdate);
          let d2 = new Date(newdate);

          let month = new Intl.DateTimeFormat("es", { month: "short" }).format(
            d
          );
          let year = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            d
          );
          let day = new Intl.DateTimeFormat("es", {
            day: "numeric",
          }).format(d);

          var newdatedate = new Date();

          if (Number(day) > 20) {
            newdatedate = this.addDays(d2, 5);
            var fechaNueva = newdatedate.toISOString();

            month = new Intl.DateTimeFormat("es", {
              month: "short",
            }).format(newdatedate);
            year = new Intl.DateTimeFormat("es", {
              year: "numeric",
            }).format(newdatedate);
          }

          if (i == 0) {
            monthOldPotencia = month;
          }

          let P1 = listSipsConsumos[i].potenciaDemandadaEnWP1;
          let P2 = listSipsConsumos[i].potenciaDemandadaEnWP2;
          let P3 = listSipsConsumos[i].potenciaDemandadaEnWP3;
          let P4 = listSipsConsumos[i].potenciaDemandadaEnWP4;
          let P5 = listSipsConsumos[i].potenciaDemandadaEnWP5;
          let P6 = listSipsConsumos[i].potenciaDemandadaEnWP6;

          let fecha = `${month} ${year}`;

          if (i > 0) {
            if (month == monthOldPotencia) {
              P1 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP1) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP1);
              P2 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP2) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP2);
              P3 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP3) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP3);
              P4 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP4) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP4);
              P5 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP5) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP5);
              P6 =
                Number(listSipsConsumos[i].potenciaDemandadaEnWP6) +
                Number(listSipsConsumos[i - 1].potenciaDemandadaEnWP6);
            } else {
              monthOldPotencia = month;
            }
          }

          consumosPotencia = consumosPotencia.filter(
            (consumosPotencia) => consumosPotencia.fecha != fecha
          );

          consumosPotencia.push({
            fecha: fecha,
            P1: P1,
            P1Color: "hsl(22, 70%, 50%)",
            P2: P2,
            P2Color: "hsl(303, 70%, 50%)",
            P3: P3,
            P3Color: "hsl(37, 70%, 50%)",
            P4: P4,
            P4Color: "hsl(338, 70%, 50%)",
            P5: P5,
            P5Color: "hsl(176, 70%, 50%)",
            P6: P6,
            P6Color: "hsl(121, 70%, 50%)",
          });
        }

        this.setState({
          sipsConsumos: listSipsConsumos,
          isLoadingConsumos: false,
          consumosEnergia: consumosEnergia,
          consumosPotencia: consumosPotencia,
        });
        this.forceUpdate();
      });
  };

  selectedItems = async (event) => {
    let items: any[] = [];
    event.map((id) => {
      items.push({
        ...this.state.sipsConsumos.filter((item) => item.id === id)[0],
      });
      return id;
    });

    this.setState({
      itemsSelected: items,
      producto: {
        ...this.state.producto,
        active: false,
      },
    });
  };

  uuid = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        const uidObj = c === "x" ? r : (r & 0x3) | 0x8;

        return uidObj.toString(16);
      }
    );
    return uuid;
  };

  toggle() {
    this.setState({
      openModalComparative: !this.state.openModalComparative,
      isDisabled: !this.state.isDisabled,
    });
  }

  toggleMulti() {
    this.setState({
      openModalMultiComparative: !this.state.openModalMultiComparative,
    });
  }

  toggleMultiClose() {
    this.setState({
      openModalMultiComparative: !this.state.openModalMultiComparative,
      isDisabled: !this.state.isDisabled,
      isDisabledProductos: !this.state.isDisabledProductos,
    });
  }

  toggleModal = (event, state) => {
    event.preventDefault();
    this.setState({
      [state]: !this.state[state],
    });
  };

  fetchComparatives = async () => {
    const companyIdAux = this.state.companyID.toString();
    const userIdAux = this.state.userId.toString();
    const cups = this.state.cups;
    //const idContrato = this.state.contrato;

    var dataFilters = {
      compania: companyIdAux,
      cups: cups,
      usuario: userIdAux,
    };

    var valores = JSON.stringify(dataFilters);

    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };
    let direccionFile =
      "https://datapi.psgestion.es/propuesta/listadoPropuestasCups?id=" +
      companyIdAux +
      "&user=" +
      userIdAux;

    await axios
      .get(direccionFile, { headers, params: { valores } })
      .then((res) => {
        console.log("Actualización correcta");
        let data = res.data.rows;
        this.setState({ comparatives: data });
      })
      .catch((error) => {
        console.log("Failure");
        console.log("Error en el proceso: ", error);
      });
  };

  showModal() {
    this.setState({
      openModalComparative: true,
    });
  }

  showMultiModal() {
    this.setState({
      openModalMultiComparative: true,
    });
  }

  openComparativeSection = async (event) => {
    this.setState({
      producto: {
        active: true,
        productoSeleccionado: event.productoSeleccionado,
        costeOperativo: event.costeOperativo,
        costeOperativoPotencia: event.costeOperativoPotencia,
        esNuevoContrato: event.esNuevoContrato,
      },
      showList: false,
    });
  };

  openComparativeMultiSection = async (event) => {
    this.setState({
      productoMulti: {
        active: true,
        productoSeleccionado: event.productoSeleccionado,
        costeOperativo: event.costeOperativo,
        costeOperativoPotencia: event.costeOperativoPotencia,
        esNuevoContrato: event.esNuevoContrato,
      },
      showList: false,
    });
  };

  onSave = async (modalData) => {
    this.setState({
      isSavingPropuesta: true,
    });

    console.log("VALOR DE modalData");
    console.log('valor de modalData ', modalData)

    console.log('valor de this.state.comparativeTemp: ', this.state.comparativeTemp)

    const idOferta = nanoid();

    //const idUser = nanoid();

    const now = new Date().toISOString();

    const user: any = this.state.user;
    const sip: any = this.state.sip;

    let potenciaP1 = 0;
    let potenciaP2 = 0;
    let potenciaP3 = 0;
    let potenciaP4 = 0;
    let potenciaP5 = 0;
    let potenciaP6 = 0;

    const producto = this.state.comparativeTemp.producto
      ? JSON.stringify(this.state.comparativeTemp.producto)
      : null;
    const productoJSON = this.state.comparativeTemp.producto
      ? this.state.comparativeTemp.producto
      : {};
    const actual = this.state.comparativeTemp.actual
      ? JSON.stringify(this.state.comparativeTemp.actual)
      : null;
    const actualJSON = this.state.comparativeTemp.actual
      ? this.state.comparativeTemp.actual
      : {};
    const datosContrato = JSON.stringify("");

    const globalJSON = this.state.comparativeTemp.comparativaGlobal
      ? this.state.comparativeTemp.comparativaGlobal
      : {};

    const datosResumenContrato = this.state.comparativeTemp.datosResumenContrato
      ? this.state.comparativeTemp.datosResumenContrato
      : {};

    const datosComision = this.state.comparativeTemp.datosComision
      ? this.state.comparativeTemp.datosComision
      : {};

    const propuestasAnalizadas = this.state.comparativeTemp.propuestasAnalizadas
      ? this.state.comparativeTemp.propuestasAnalizadas
      : {};

    const dataModal: any = {
      ...{
        ...this.dataToSave,
      },
      ...modalData,
    };

    var tarifaATRContrato = "";

    var seleccion = "";
    if (this.state.comparativeTemp.producto.producto.tarifaATR) {
      seleccion = this.state.comparativeTemp.producto.producto.tarifaATR;
    }

    if (seleccion == "" || seleccion == "undefined") {
      seleccion = this.state.comparativeTemp.propuestasAnalizadas[0].codigoATR;
    }

    if (seleccion == "018") {
      tarifaATRContrato = "2.0TD";
    } else if (seleccion == "019") {
      tarifaATRContrato = "3.0TD";
    } else if (seleccion == "020") {
      tarifaATRContrato = "6.1TD";
    } else if (seleccion == "021") {
      tarifaATRContrato = "6.2TD";
    } else if (seleccion == "022") {
      tarifaATRContrato = "6.3TD";
    } else if (seleccion == "023") {
      tarifaATRContrato = "6.4TD";
    } else if (seleccion == "024") {
      tarifaATRContrato = "3.0TDVE";
    } else if (seleccion == "025") {
      tarifaATRContrato = "6.1TDVE";
    }

    if (
      this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) &&
      seleccion == "018"
    ) {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
      potenciaP2 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP2
        ) || 0;
    } else if (
      this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) &&
      this.state.rates1XP.includes(seleccion)
    ) {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
    } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)) {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
      potenciaP2 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP2
        ) || 0;
    } else if (
      this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) &&
      (seleccion == "019" || seleccion == "020")
    ) {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
      potenciaP2 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP2
        ) || 0;
      potenciaP3 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP3
        ) || 0;
      potenciaP4 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP4
        ) || 0;
      potenciaP5 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP5
        ) || 0;
      potenciaP6 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP6
        ) || 0;
    } else if (
      this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) &&
      this.state.rates3XP.includes(seleccion)
    ) {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
      potenciaP2 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP2
        ) || 0;
      potenciaP3 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP3
        ) || 0;
    } else {
      potenciaP1 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP1
        ) || 0;
      potenciaP2 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP2
        ) || 0;
      potenciaP3 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP3
        ) || 0;
      potenciaP4 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP4
        ) || 0;
      potenciaP5 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP5
        ) || 0;
      potenciaP6 +=
        Number(
          this.state.comparativeTemp.datosResumenContrato.potenciaContratoP6
        ) || 0;
    }

    var codigoPostal = dataModal.CodigoPostalTitular.padStart(5, "0");
    var codigoPostalPS = dataModal.CodigoPostalPS.padStart(5, "0");

    if (codigoPostal == "Selecciona CP") {
      codigoPostal = "00000";
    }

    if (codigoPostalPS == "Selecciona CP") {
      codigoPostalPS = "00000";
    }

    let gastoOperativo = 0;
    let gastoOperativoPotencia = 0;
    if (!this.state.comparativeTemp.producto.producto.coIncluido) {
      gastoOperativo = this.state.comparativeTemp.producto.costeOperativo; //"0.00 SOLO CUANDO ES CORPORATE",
      gastoOperativoPotencia =
        this.state.comparativeTemp.producto.costeOperativoPotencia;
    }

    var fechaPropuesta = moment
      .parseZone(now.split("T")[0])
      .format("YYYY-MM-DD");
    var check = moment(fechaPropuesta, "YYYY-MM-DD");
    var monthPropuesta = Number(check.format("M"));
    var yearPropuesta = check.year();

    var fechaAprobacion = moment
      .parseZone(now.split("T")[0])
      .format("YYYY-MM-DD");
    check = moment(fechaAprobacion, "YYYY-MM-DD");
    var monthAprobacion = Number(check.format("M"));
    var yearAprobacion = check.year();

    var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

    var partes = currentDate.slice(0).split("T");

    //const now = new Date().toISOString();

    var date = partes[0];
    var hour = partes[1];

    var currentDateAprobacion = moment().format("YYYY-MM-DDTHH:mm:ss");
    //var local_date_aprobacion= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');
    var partesAprobacion = currentDateAprobacion.slice(0).split("T");

    //const now = new Date().toISOString();

    var dateAprobacion = partesAprobacion[0];
    var hourAprobacion = partesAprobacion[1];
    var TipoID = "CIF";

    const result = validateSpanishId(dataModal.NIF);

    if (result === true) {
      const resultTipo = spainIdType(dataModal.NIF);
      TipoID = resultTipo.toUpperCase();
    }

    var idProducto = this.state.comparativeTemp.producto.producto.id
    ? this.state.comparativeTemp.producto.producto.id.toString()
    : ""

    let comision: any = this.state.comparativeTemp.datosComision.dataComisionCo.find((comision: any) => (comision.id == idProducto));

    if (!comision) {

      comision = this.state.comparativeTemp.datosComision.dataComision.find((comision: any) => (comision.id == idProducto));

  }

  if (!comision) {
    comision = {}
}



    var datosContratoP: any = {};

    if (dataModal.esAprobada === false) {
      datosContratoP = {
        Usuario: this.state.userId.toString(),
        compania: this.state.companyID,
        fechaPropuesta: date,
        horaPropuesta: hour,
        mesPropuesta: monthPropuesta,
        anioPropuesta: yearPropuesta,
        fechaAprobacion: "",
        horaAprobacion: "",
        mesAprobacion: 0,
        anioAprobacion: 0,
        esAprobada: false,
        fechaDenegacion: "",
        horaDenegacion: "",
        mesDenegacion: 0,
        anioDenegacion: 0,
        esDenegada: false,
        IdOferta: idOferta,
        IdOfertaInterna: idOferta,
        nombreOferta: dataModal.nombreOferta,
        cups: this.state.sip.cups,
        tipoCliente: dataModal.TipoCliente,
        tipoEnergia: "ELECTRICIDAD",
        estado: "ALTA",
        estadoId: "01",
        OfertaTipo: "P",
        ofertaTipoId: "0",
        T_Titular: {
          NombreTitular: dataModal.nombreTitular?.toUpperCase() || "",
          Apellido1Titular: dataModal.apellido1Titular?.toUpperCase() || "",
          Apellido2Titular: dataModal.apellido2Titular?.toUpperCase() || "",
          NIF: dataModal.NIF, //this.state.ubicacion.CIF,
          TipoTitular: TipoID,
          TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase() || "",
          ViaTitular: dataModal.viaTitular?.toUpperCase() || "",
          NumFincaTitular: dataModal.numFincaTitular?.toUpperCase() || "",
          PortalTitular: dataModal.portalTitular?.toUpperCase() || "",
          EscaleraTitular: dataModal.escaleraTitular?.toUpperCase() || "",
          PisoTitular: dataModal.pisoTitular?.toUpperCase() || "",
          PuertaTitular: dataModal.puertaTitular?.toUpperCase() || "",
          CodigoPostalTitular: codigoPostal,
          AutonomiaTitular: dataModal.desAutonomiaTitular?.toUpperCase() || "",
          ProvinciaTitular: dataModal.desProvinciaTitular?.toUpperCase() || "",
          MunicipioTitular: dataModal.desMunicipioTitular?.toUpperCase() || "",
          TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
          TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
          Email: dataModal.Email, //this.state.ubicacion.email_contacto,
          TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
          ObservacionesCliente: dataModal.ObservacionesCliente,
          GDPR: dataModal.GDPR,
          IBAN: dataModal.iban,
          CNAE: this.state.sip.CNAE,
          actividadCNAE: this.state.sip.actividadCNAE,
        },
        T_Representante: {
          nombreRepresentante:
            dataModal.nombreRepresentante?.toUpperCase() || "",
          apellido1Representante:
            dataModal.apellido1Representante?.toUpperCase() || "",
          apellido2Representante:
            dataModal.apellido2Representante?.toUpperCase() || "",
          NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase() || "",
          TipoTitularRepresentante:
            dataModal.tipoTitularRepresentante?.toUpperCase() || "",
          idRepresentante: dataModal.idRepresentante,
          idRepresentanteInterno: dataModal.idRepresentanteInterno,
          CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase() || "",
          telefonoRepresentante: dataModal.telefonoRepresentante,
          correoRepresentante: dataModal.correoRepresentante,
        },
        T_PuntoSuministro: {
          TipoViaPS: dataModal.tipoViaPS?.toUpperCase() || "",
          ViaPS: dataModal.viaPS?.toUpperCase() || "",
          NumFincaPS: dataModal.numFincaPS?.toUpperCase() || "",
          PortalPS: dataModal.portalPS?.toUpperCase() || "",
          EscaleraPS: dataModal.escaleraPS?.toUpperCase() || "",
          PisoPS: dataModal.pisoPS?.toUpperCase() || "",
          PuertaPS: dataModal.puertaPS?.toUpperCase() || "",
          CodigoPostalPS: codigoPostalPS,
          NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase() || "",
          codigoMunicipioPS: sip.codigoMunicipioPS,
          NombreProvinciaPS: dataModal.nombreProvinciaPS.toUpperCase(),
          codigoProvinciaPS: sip.codigoProvinciaPS,
          AutonomiaPS: dataModal.desAutonomiaPS?.toUpperCase() || "",
          codigoAutonomiaPS: sip.codigoAutonomiaPS,
          TelefonoPS: dataModal.TelefonoPS,
          ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro,
        },
        T_CUPs: {
          Distribuidora: this.state.sip.distribuidora,
          CUPsEle: this.state.sip.cups,
          Tarifa: datosResumenContrato.descATR
            ? datosResumenContrato.descATR
            : "SIN DATOS",
          P1: this.state.sip.potenciasContratadasEnWP1,
          P2: this.state.sip.potenciasContratadasEnWP2,
          P3: this.state.sip.potenciasContratadasEnWP3,
          P4: this.state.sip.potenciasContratadasEnWP4,
          P5: this.state.sip.potenciasContratadasEnWP5,
          P6: this.state.sip.potenciasContratadasEnWP6,
          PotMaxBie: this.state.sip.potenciaMaximaBIEW,
          TipoServicio: "1",
          Consumo: this.state.sip.ConsumoAnualTotalActiva.toString(),
          esViviendaHabitual: this.state.sip.esViviendaHabitual,
          fechaCaducidadAPM: this.state.sip.fechaCaducidadAPM,
          fechaCaducidadBIE: this.state.sip.fechaCaducidadBIE,
          fechaEmisionAPM: this.state.sip.fechaEmisionAPM,
          fechaEmisionBIE: this.state.sip.fechaEmisionBIE,
          fechaLimiteDerechosReconocidos:
            this.state.sip.fechaLimiteDerechosReconocidos,
          fechaUltimaLectura: this.state.sip.fechaUltimaLectura,
          fechaUltimoCambioComercializador:
            this.state.sip.fechaUltimoCambioComercializador,
          fechaUltimoMovimientoContrato:
            this.state.sip.fechaUltimoMovimientoContrato,
          importeDepositoGarantiaEuros:
            this.state.sip.importeDepositoGarantiaEuros,
          informacionImpagos: this.state.sip.informacionImpagos,
          codigoAutoconsumo: this.state.sip.codigoAutoconsumo,
          descAutoconsumo: this.state.sip.descAutoconsumo,
          codigoTelegestion: this.state.sip.codigoTelegestion,
          tipoTelegestion: this.state.sip.tipoTelegestion,
          proEquipoMedida: this.state.sip.proEquipoMedida,
          propiedadICP: this.state.sip.propiedadICP,
          relacionTransformacionIntensidad:
            this.state.sip.relacionTransformacionIntensidad,
          tarifaATR: this.state.sip.tarifaATR,
          tensionEm: this.state.sip.tensionEm,
          tipoContrato: this.state.sip.tipoContrato,
          valorDerechosAccesoW: this.state.sip.valorDerechosAccesoW,
          valorDerechosExtensionW: this.state.sip.valorDerechosExtensionW,
          Tension: this.state.sip.Tension,
          accesoContador: this.state.sip.accesoContador,
          consumoAnualActivaP1: this.state.sip.ConsumoAnualActivaP1,
          consumoAnualActivaP2: this.state.sip.ConsumoAnualActivaP2,
          consumoAnualActivaP3: this.state.sip.ConsumoAnualActivaP3,
          consumoAnualActivaP4: this.state.sip.ConsumoAnualActivaP4,
          consumoAnualActivaP5: this.state.sip.ConsumoAnualActivaP5,
          consumoAnualActivaP6: this.state.sip.ConsumoAnualActivaP6,
          consumoAnualTotalActiva: this.state.sip.ConsumoAnualTotalActiva,
        },
        T_PropuestaComercial: {
          PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion
            .ahorroPercent
            ? this.state.comparativeTemp.producto.totalComparacion.ahorroPercent
            : 0,
          ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion
            .ahorroAnual
            ? this.state.comparativeTemp.producto.totalComparacion.ahorroAnual
            : 0,
          Estado: "P", //"P",
          Comercializadora: this.state.comparativeTemp.producto.producto
            .nombreComercializadora
            ? this.state.comparativeTemp.producto.producto
                .nombreComercializadora
            : "", //"AUDAX RENOVABLES S.A.",
          Producto: this.state.comparativeTemp.producto.producto.nombreOferta
            ? this.state.comparativeTemp.producto.producto.nombreOferta
            : "NO SELECCIONADO", //"CLASSIC",
          TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
          Tarifa: tarifaATRContrato,
          IdProducto: this.state.comparativeTemp.producto.producto.id
            ? this.state.comparativeTemp.producto.producto.id.toString()
            : "",
          Anexo: this.state.comparativeTemp.producto.producto
            .nombreDocumentoContrato
            ? this.state.comparativeTemp.producto.producto
                .nombreDocumentoContrato
            : "",
          //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
          TipoPrecio: "0", //"0 Fijo 1 Indexado",
          ObservacionesProducto: "",
          //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
          GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
          GastoOperativoPotencia: gastoOperativoPotencia,
          idServicio: this.state.comparativeTemp.producto.datos.idServicio
            ? this.state.comparativeTemp.producto.datos.idServicio
            : 0,
          idComercializadora: this.state.comparativeTemp.producto.producto
            .idComercializadora
            ? this.state.comparativeTemp.producto.producto.idComercializadora.padStart(4,"0"): "0000",
          nombreServicio: this.state.comparativeTemp.producto.datos
            .nombreServicio
            ? this.state.comparativeTemp.producto.datos.nombreServicio
            : "",
          precioServicio: this.state.comparativeTemp.producto.datos
            .precioServicio
            ? this.state.comparativeTemp.producto.datos.precioServicio.toString()
            : "", //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
          ComercializadoraProcedencia: dataModal.idComercializadora,
          idComercializadoraProcedencia:
            dataModal.nombreComercializadora?.padStart(4, "0") || "",
          comisionContratoFija: this.state.comparativeTemp.producto.datos
            .comisionContratoFija
            ? this.state.comparativeTemp.producto.datos.comisionContratoFija
            : 0,
          comisionContratoConsumoAgente1: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAgente1
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAgente1
            : 0,
          comisionContratoConsumoAgente2: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAgente2
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAgente2
            : 0,
          comisionContratoConsumoAdmin: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAdmin
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAdmin
            : 0,
          esNuevoContrato: this.state.comparativeTemp.producto.esNuevoContrato,
          propuestaGlobal: globalJSON,
          comision: comision
        },
        T_PropuestasAnalizadas: propuestasAnalizadas,
        T_DatosAgenteComercial: {
          nombreComercial: this.state.comerciales.nombreComercial
            ? this.state.comerciales.nombreComercial
            : "",
          apellido1Comercial: this.state.comerciales.apellido1Comercial,
          apellido2Comercial: this.state.comerciales.apellido2Comercial,
          NIFComercial: this.state.comerciales.NIFComercial,
          idComercial: this.state.comerciales.idComercial,
          idComercialInterno: this.state.comerciales.idComercialInterno,
          telefonoComercial: this.state.comerciales.telefonoComercial,
          correoComercial: this.state.comerciales.correoComercial,
          comisionYear1: this.state.comerciales.comisionYear1,
          comisionYear2: this.state.comerciales.comisionYear2,
          idCompany: this.state.comerciales.idCompany,
          zonaGeograficaComercial:
            this.state.comerciales.zonaGeograficaComercial,
          idResponsable: this.state.comerciales.idResponsable,
          Responsable: this.state.comerciales.Responsable,
          usuarioPlataforma: Number(this.state.userId),
        },
        T_Propuesta: {
          producto: productoJSON,
          actual: actualJSON,
        },
        T_Seguimiento: [
          {
            usuario: this.state.userId,
            nombreUsuario: this.state.nombreAgente,
            compania: this.state.companyID,
            fecha: date,
            hora: hour,
            mes: monthPropuesta,
            anio: yearPropuesta,
            accion: "ALTA",
            idAccion: "01",
            observacion: "",
          },
        ],
        T_Consumos: this.state.sipsConsumos,
        T_Datos_Comision: datosComision,
      };
    } else {
      datosContratoP = {
        Usuario: this.state.userId.toString(),
        compania: this.state.companyID,
        fechaPropuesta: date,
        horaPropuesta: hour,
        mesPropuesta: monthPropuesta,
        anioPropuesta: yearPropuesta,
        fechaAprobacion: dateAprobacion,
        horaAprobacion: hourAprobacion,
        mesAprobacion: monthAprobacion,
        anioAprobacion: yearAprobacion,
        esAprobada: true,
        fechaDenegacion: "",
        horaDenegacion: "",
        mesDenegacion: 0,
        anioDenegacion: 0,
        esDenegada: false,
        IdOferta: idOferta,
        IdOfertaInterna: idOferta,
        nombreOferta: dataModal.nombreOferta,
        cups: this.state.sip.cups,
        tipoCliente: dataModal.TipoCliente,
        tipoEnergia: "ELECTRICIDAD",
        estado: "APROBADA",
        estadoId: "50",
        OfertaTipo: "P",
        ofertaTipoId: "0",
        T_Titular: {
          NombreTitular: dataModal.nombreTitular?.toUpperCase() || "",
          Apellido1Titular: dataModal.apellido1Titular?.toUpperCase() || "",
          Apellido2Titular: dataModal.apellido2Titular?.toUpperCase() || "",
          NIF: dataModal.NIF.toUpperCase(), //this.state.ubicacion.CIF,
          TipoTitular: TipoID,
          TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase() || "",
          ViaTitular: dataModal.viaTitular?.toUpperCase() || "",
          NumFincaTitular: dataModal.numFincaTitular?.toUpperCase() || "",
          PortalTitular: dataModal.portalTitular?.toUpperCase() || "",
          EscaleraTitular: dataModal.escaleraTitular?.toUpperCase() || "",
          PisoTitular: dataModal.pisoTitular?.toUpperCase() || "",
          PuertaTitular: dataModal.puertaTitular?.toUpperCase() || "",
          CodigoPostalTitular: codigoPostal?.padStart(4, "0") || "",
          AutonomiaTitular: dataModal.desAutonomiaTitular?.toUpperCase() || "",
          ProvinciaTitular: dataModal.desProvinciaTitular?.toUpperCase() || "",
          MunicipioTitular: dataModal.desMunicipioTitular?.toUpperCase() || "",
          TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
          TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
          Email: dataModal.Email, //this.state.ubicacion.email_contacto,
          TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
          ObservacionesCliente: dataModal.ObservacionesCliente,
          GDPR: dataModal.GDPR,
          IBAN: dataModal.iban,
          CNAE: this.state.sip.CNAE,
          actividadCNAE: this.state.sip.actividadCNAE,
        },
        T_Representante: {
          nombreRepresentante:
            dataModal.nombreRepresentante?.toUpperCase() || "",
          apellido1Representante:
            dataModal.apellido1Representante?.toUpperCase() || "",
          apellido2Representante:
            dataModal.apellido2Representante?.toUpperCase() || "",
          NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase() || "",
          TipoTitularRepresentante:
            dataModal.tipoTitularRepresentante?.toUpperCase() || "",
          idRepresentante: dataModal.idRepresentante,
          idRepresentanteInterno: dataModal.idRepresentanteInterno,
          CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase() || "",
          telefonoRepresentante: dataModal.telefonoRepresentante,
          correoRepresentante: dataModal.correoRepresentante,
        },
        T_PuntoSuministro: {
          TipoViaPS: dataModal.tipoViaPS?.toUpperCase() || "",
          ViaPS: dataModal.viaPS?.toUpperCase() || "",
          NumFincaPS: dataModal.numFincaPS?.toUpperCase() || "",
          PortalPS: dataModal.portalPS?.toUpperCase() || "",
          EscaleraPS: dataModal.escaleraPS?.toUpperCase() || "",
          PisoPS: dataModal.pisoPS?.toUpperCase() || "",
          PuertaPS: dataModal.puertaPS?.toUpperCase() || "",
          CodigoPostalPS: codigoPostalPS?.padStart(4, "0") || "",
          NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase() || "",
          codigoMunicipioPS: sip.codigoMunicipioPS,
          NombreProvinciaPS: dataModal.nombreProvinciaPS?.toUpperCase() || "",
          codigoProvinciaPS: sip.codigoProvinciaPS,
          AutonomiaPS: dataModal.desAutonomiaPS?.toUpperCase() || "",
          codigoAutonomiaPS: sip.codigoAutonomiaPS,
          TelefonoPS: dataModal.TelefonoPS,
          ObservacionesPuntoSuministro:
            dataModal.ObservacionesPuntoSuministro?.toUpperCase() || "",
        },
        T_CUPs: {
          Distribuidora: this.state.sip.distribuidora,
          CUPsEle: this.state.sip.cups,
          Tarifa: datosResumenContrato.descATR
            ? datosResumenContrato.descATR
            : "SIN DATOS",
          P1: this.state.sip.potenciasContratadasEnWP1,
          P2: this.state.sip.potenciasContratadasEnWP2,
          P3: this.state.sip.potenciasContratadasEnWP3,
          P4: this.state.sip.potenciasContratadasEnWP4,
          P5: this.state.sip.potenciasContratadasEnWP5,
          P6: this.state.sip.potenciasContratadasEnWP6,
          PotMaxBie: this.state.sip.potenciaMaximaBIEW,
          TipoServicio: "1",
          Consumo: this.state.sip.ConsumoAnualTotalActiva.toString(),
          esViviendaHabitual: this.state.sip.esViviendaHabitual,
          fechaCaducidadAPM: this.state.sip.fechaCaducidadAPM,
          fechaCaducidadBIE: this.state.sip.fechaCaducidadBIE,
          fechaEmisionAPM: this.state.sip.fechaEmisionAPM,
          fechaEmisionBIE: this.state.sip.fechaEmisionBIE,
          fechaLimiteDerechosReconocidos:
            this.state.sip.fechaLimiteDerechosReconocidos,
          fechaUltimaLectura: this.state.sip.fechaUltimaLectura,
          fechaUltimoCambioComercializador:
            this.state.sip.fechaUltimoCambioComercializador,
          fechaUltimoMovimientoContrato:
            this.state.sip.fechaUltimoMovimientoContrato,
          importeDepositoGarantiaEuros:
            this.state.sip.importeDepositoGarantiaEuros,
          informacionImpagos: this.state.sip.informacionImpagos,
          codigoAutoconsumo: this.state.sip.codigoAutoconsumo,
          descAutoconsumo: this.state.sip.descAutoconsumo,
          codigoTelegestion: this.state.sip.codigoTelegestion,
          tipoTelegestion: this.state.sip.tipoTelegestion,
          proEquipoMedida: this.state.sip.proEquipoMedida,
          propiedadICP: this.state.sip.propiedadICP,
          relacionTransformacionIntensidad:
            this.state.sip.relacionTransformacionIntensidad,
          tarifaATR: this.state.sip.tarifaATR,
          tensionEm: this.state.sip.tensionEm,
          tipoContrato: this.state.sip.tipoContrato,
          valorDerechosAccesoW: this.state.sip.valorDerechosAccesoW,
          valorDerechosExtensionW: this.state.sip.valorDerechosExtensionW,
          Tension: this.state.sip.Tension,
          accesoContador: this.state.sip.accesoContador,
          consumoAnualActivaP1: this.state.sip.ConsumoAnualActivaP1,
          consumoAnualActivaP2: this.state.sip.ConsumoAnualActivaP2,
          consumoAnualActivaP3: this.state.sip.ConsumoAnualActivaP3,
          consumoAnualActivaP4: this.state.sip.ConsumoAnualActivaP4,
          consumoAnualActivaP5: this.state.sip.ConsumoAnualActivaP5,
          consumoAnualActivaP6: this.state.sip.ConsumoAnualActivaP6,
          consumoAnualTotalActiva: this.state.sip.ConsumoAnualTotalActiva,
        },
        T_PropuestaComercial: {
          PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroPercent ? this.state.comparativeTemp.producto.totalComparacion.ahorroPercent: 0,
          ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroAnual ? this.state.comparativeTemp.producto.totalComparacion.ahorroAnual : 0,
          Estado: "P", //"P",
          Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora ? this.state.comparativeTemp.producto.producto.nombreComercializadora : "", //"AUDAX RENOVABLES S.A.",
          Producto: this.state.comparativeTemp.producto.producto.nombreOferta ? this.state.comparativeTemp.producto.producto.nombreOferta : "NO SELECCIONADO", //"CLASSIC",
          TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
          Tarifa: tarifaATRContrato,
          IdProducto: this.state.comparativeTemp.producto.producto.id
            ? this.state.comparativeTemp.producto.producto.id.toString()
            : "",
          Anexo: this.state.comparativeTemp.producto.producto
            .nombreDocumentoContrato
            ? this.state.comparativeTemp.producto.producto
                .nombreDocumentoContrato
            : "",
          //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
          TipoPrecio: "0", //"0 Fijo 1 Indexado",
          ObservacionesProducto: "",
          //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
          GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
          GastoOperativoPotencia: gastoOperativoPotencia,
          idServicio: this.state.comparativeTemp.producto.datos.idServicio
            ? this.state.comparativeTemp.producto.datos.idServicio
            : 0,
          idComercializadora: this.state.comparativeTemp.producto.datos
            .idComercializadora
            ? this.state.comparativeTemp.producto.datos.idComercializadora.padStart(
                4,
                "0"
              )
            : "0000",
          nombreServicio: this.state.comparativeTemp.producto.datos
            .nombreServicio
            ? this.state.comparativeTemp.producto.datos.nombreServicio
            : "",
          precioServicio: this.state.comparativeTemp.producto.datos
            .precioServicio
            ? this.state.comparativeTemp.producto.datos.precioServicio.toString()
            : "", //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
          ComercializadoraProcedencia: dataModal.idComercializadora,
          idComercializadoraProcedencia:
            dataModal.nombreComercializadora?.padStart(4, "0") || "",
          comisionContratoFija: this.state.comparativeTemp.producto.datos
            .comisionContratoFija
            ? this.state.comparativeTemp.producto.datos.comisionContratoFija
            : 0,
          comisionContratoConsumoAgente1: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAgente1
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAgente1
            : 0,
          comisionContratoConsumoAgente2: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAgente2
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAgente2
            : 0,
          comisionContratoConsumoAdmin: this.state.comparativeTemp.producto
            .datos.comisionContratoConsumoAdmin
            ? this.state.comparativeTemp.producto.datos
                .comisionContratoConsumoAdmin
            : 0,
          esNuevoContrato: this.state.comparativeTemp.producto.esNuevoContrato,
          propuestaGlobal: globalJSON,
        },
        T_PropuestasAnalizadas: propuestasAnalizadas,
        T_DatosAgenteComercial: {
          nombreComercial: this.state.comerciales.nombreComercial,
          apellido1Comercial: this.state.comerciales.apellido1Comercial,
          apellido2Comercial: this.state.comerciales.apellido2Comercial,
          NIFComercial: this.state.comerciales.NIFComercial,
          idComercial: this.state.comerciales.idComercial,
          idComercialInterno: this.state.comerciales.idComercialInterno,
          telefonoComercial: this.state.comerciales.telefonoComercial,
          correoComercial: this.state.comerciales.correoComercial,
          comisionYear1: this.state.comerciales.comisionYear1,
          comisionYear2: this.state.comerciales.comisionYear2,
          idCompany: this.state.comerciales.idCompany,
          zonaGeograficaComercial:
            this.state.comerciales.zonaGeograficaComercial,
          idResponsable: this.state.comerciales.idResponsable,
          Responsable: this.state.comerciales.Responsable,
          usuarioPlataforma: Number(this.state.userId),
        },
        T_Propuesta: {
          producto: productoJSON,
          actual: actualJSON,
        },
        T_Seguimiento: [
          {
            usuario: this.state.userId,
            nombreUsuario: this.state.nombreAgente,
            compania: this.state.companyID,
            fecha: date,
            hora: hour,
            mes: monthPropuesta,
            anio: yearPropuesta,
            accion: "ALTA",
            idAccion: "01",
            observacion: "",
          },
          {
            usuario: this.state.userId,
            nombreUsuario: this.state.nombreAgente,
            compania: this.state.companyID,
            fecha: dateAprobacion,
            hora: hourAprobacion,
            mes: monthAprobacion,
            anio: yearAprobacion,
            accion: "APROBADA",
            idAccion: "50",
            observacion: "",
          },
        ],
        T_Consumos: this.state.sipsConsumos,
        T_Datos_Comision: datosComision,
      };
    }

    const datosContratoJSON: string = JSON.stringify(datosContratoP).toString();

    const companyIdAux = this.state.companyID.toString();
    const userIdAux = this.state.userId.toString();
    //const idContrato = this.state.contrato;

    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };
    let direccionFile =
      "https://datapi.psgestion.es/propuesta/create?id=" +
      companyIdAux +
      "&user=" +
      userIdAux;

    axios
      .post(direccionFile, datosContratoP, { headers, responseType: "json" })
      .then((res) => {
        console.log("Actualización correcta");
        this.setState({
          isSavingPropuesta: false,
        });
        this.successAlert();
        this.clearInvoices();
        this.fetchComparatives();
      })
      .catch((error) => {
        console.log("Failure");
        console.log("Error en el proceso: ", error);
        this.setState({
          isSavingPropuesta: false,
        });
      });
  };

  openModalComparativa = async (event) => {
    await this.closeComparativeSection();
    if (this.state.itemsSelected.length) {
      this.showModal();
      return;
    }
    //alert("Seleccione consumos para realizar la comparativa");
    this.warningAlert();
  };

  openModalMultiComparativa = async (event) => {
    await this.closeComparativeSection();
    if (this.state.itemsSelected.length) {
      this.showMultiModal();
      this.setState({
        isDisabledProductos: true,
      });
      return;
    }
    this.warningAlert();
  };

  closeComparativeSection = async () => {
    this.setState({
      producto: {
        active: false,
        producto: null,
        costeOperativo: 0,
        costeOperativoPotencia: 0,
        esNuevoContrato: true,
      },
    });
  };

  comparativeSelected = async (event) => {
    let idUnico = event.target.value;
    let id = idUnico + "_" + this.state.companyID;
    const win = window.open("/print/sip-comparative-print/" + id, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  clearInvoices = () => {
    this.setState({
      producto: {
        active: false,
        producto: null,
        costeOperativo: 0,
        costeOperativoPotencia: 0,
        esNuevoContrato: true,
      },
      showList: true,
      productSelected: null,
      isDisabled: true,
      isDisabledProductos: false,
      nombrePropuesta: "",
    });
    if (this.state.productoMulti) {
      this.setState({
        productoMulti: {
          active: false,
          productoSeleccionado: null,
          costeOperativo: 0,
          costeOperativoPotencia: 0,
          esNuevoContrato: true,
        },
        showList: true,
        productSelected: null,
        isDisabled: true,
        nombrePropuesta: "",
      });
    }
  };

  changeSave = (e) => {
    let s = true;
    if (e.target.value.length && this.enabledSave == true) {
      s = false;
      this.setState({ nombrePropuesta: e.target.value });
    }
    if (s === true) {
      if (e.target.value.length > 0) {
        //alert("Recuerde seleccionar un producto para guardar la propuesta comercial");}
        this.warningAlertProducto();
      }
    } else {
      this.setState({ isDisabled: s });
    }
  };

  onChangeField = (event) => {
    event.persist();

    let formData = { ...this.state.consumosEntrada };
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;

    formData[name] = value;

    let tarifaATRConsumos = this.state.sip.codigoTarifaATREnVigor;

    formData["codigoTarifaATR"] = tarifaATRConsumos;

    let formDataArray: any = [];

    formDataArray.push(formData);

    var saveProductButtonDisabled = false;

    this.setState({
      itemsSelected: formDataArray,
      consumosEntrada: formData,
      producto: {
        ...this.state.producto,
        active: false,
      },
    });
  };

  handleChangeDateFilter = (value, formattedValue, id) => {
    let formData = { ...this.state.consumosEntrada };
    let fechaInicioMesConsumoOld = this.state.fechaInicioMesConsumo;
    let fechaFinMesConsumoOld = this.state.fechaFinMesConsumo;

    let tarifaATRConsumos = this.state.sip.codigoTarifaATREnVigor;

    formData["codigoTarifaATR"] = tarifaATRConsumos;

    const name = id;
    var valueDay = formattedValue;

    formData[name] = valueDay;

    let formDataArray: any = [];

    formDataArray.push(formData);

    if (name == "fechaInicioMesConsumo") {
      fechaInicioMesConsumoOld = valueDay.split("-").reverse().join("-");
    } else if (name == "fechaFinMesConsumo") {
      fechaFinMesConsumoOld = valueDay.split("-").reverse().join("-");
    }

    //formData[name] = valueDay.split("-").reverse().join("-");

    this.setState({
      fechaInicioMesConsumo: fechaInicioMesConsumoOld,
      fechaFinMesConsumo: fechaFinMesConsumoOld,
      itemsSelected: formDataArray,
      consumosEntrada: formData,
      producto: {
        ...this.state.producto,
        active: false,
      },
    });
  };

  render() {
    console.log("this.state en sipComparativeFree: ", this.state);
    console.log("this.props: ", this.props);

    const showComercialMultiProducto = !this.state.productoMulti.active
      ? { display: "none" }
      : {};

    const showList = !this.state.showList ? { display: "none" } : {};
    const showListFull = !this.state.showList
      ? { display: "none" }
      : { width: "100%" };

    const form_T_CUPs: any = this.state.consumosEntrada;

    let freeModal = <></>;
    if (this.state.sip.NumeroPeriodos) {
      if (this.state.openModalMultiComparative == true) {
        freeModal = (
          <SipComparativeFreeMultiModal
            sip={this.state.sip}
            itemsSelected={this.state.itemsSelected}
            isOpen={this.state.openModalMultiComparative}
            toggleMulti={this.toggleMulti}
            toggleMultiClose={this.toggleMultiClose}
            periods={this.state.periodos}
            generateComparative={this.openComparativeMultiSection}
          ></SipComparativeFreeMultiModal>
        );
      }
    }
    const styleFullWith = { width: "100%" };

    return (
      <>
        <Header title="Comparativa de Contrato Precio Fijo" />
        <Container id="comparative" className=" mt--7" fluid>
          <Row className="card-deck">
            <SupplyInformation
              isLoading={this.state.isLoading}
              sip={this.state.sip}
              cardClass="col-6"
            ></SupplyInformation>
            <RateInformation
              isLoading={this.state.isLoading}
              sip={this.state.sip}
              cardClass="col-6"
            ></RateInformation>
          </Row>

          <Row>
            <div className="col m-2 d-flex">
              <Button
                color="primary"
                size="sm"
                type="button"
                className="btn-icon btn-sm btn-circle"
                onClick={this.props.history.goBack}
              >
                <span className="bi bi-box-arrow-left"> Volver</span>
              </Button>

              <Button
                color="secondary"
                size="sm"
                type="button"
                className="btn-icon btn-sm btn-circle"
                onClick={this.clearInvoices}
              >
                <span className="bi bi-eraser"> Limpiar</span>
              </Button>
            </div>
          </Row>

          <LoadingOverlay
            active={this.state.isSavingPropuesta}
            spinner
            text="Guardando propuesta..."
          >
            <div className="card-deck">
              <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                <CardBody></CardBody>
              </Card>

              <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                <CardBody>
                  <FormGroup>
                    <Input
                      id="comparativaId"
                      type="select"
                      onChange={this.comparativeSelected}
                      hidden={this.state.comparatives.length > 0 ? false : true}
                    >
                      <option value="none">Cargar Comparativa</option>
                      {this.state.comparatives.map((item: any, index) => (
                        <option value={item.id} key={index}>
                          {item.nombreOferta}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                <CardBody>
                  <FormGroup>
                    <Button
                      id="glecturaProptooltip"
                      color="primary"
                      size="m"
                      type="button"
                      className="btn-icon btn-sm btn-circle"
                      disabled={this.state.isDisabledProductos}
                      onClick={this.openModalMultiComparativa}
                    >
                      <span className="btn-inner--icon">
                        <i
                          className="fas fa-file-invoice fa-2x"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                      <span className="btn-inner--text">
                        {" "}
                        Comparativas Multiproducto
                      </span>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="glecturaProptooltip"
                      >
                        Comparativa de propuesta/factura a partir de varios
                        productos comerciales
                      </UncontrolledTooltip>
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </LoadingOverlay>

          <Row className="card-deck" style={showList}>
            <Card className="mt-3 shadow">
              <CardBody>
                <CardTitle>Consumos manual</CardTitle>
                <Row>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="fechaInicioMesConsumo"
                      >
                        FECHA INICIO
                      </Label>

                      <DatePicker
                        id="fechaInicioMesConsumo"
                        value={this.state.fechaInicioMesConsumo}
                        onChange={(v, f) =>
                          this.handleChangeDateFilter(
                            v,
                            f,
                            "fechaInicioMesConsumo"
                          )
                        }
                        instanceCount={1}
                        dateFormat={"DD-MM-YYYY"}
                        dayLabels={spanishDayLabels}
                        monthLabels={spanishMonthLabels}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="fechaFinMesConsumo"
                      >
                        FECHA FIN
                      </Label>
                      <DatePicker
                        id="fechaFinMesConsumo"
                        value={this.state.fechaFinMesConsumo}
                        onChange={(v, f) =>
                          this.handleChangeDateFilter(
                            v,
                            f,
                            "fechaFinMesConsumo"
                          )
                        }
                        instanceCount={1}
                        dateFormat={"DD-MM-YYYY"}
                        dayLabels={spanishDayLabels}
                        monthLabels={spanishMonthLabels}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <Label
                      className="form-control-label"
                      htmlFor="energiaActiva"
                    >
                      Activa
                    </Label>
                  </Col>

                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P1">
                        P1
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP1"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP1"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP1}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P2">
                        P2
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP2"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP2"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP2}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P3">
                        P3
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP3"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP3"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP3}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P4">
                        P4
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP4"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP4"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP4}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P5">
                        P5
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP5"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP5"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP1}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="P6">
                        P6
                      </Label>
                      <Input
                        id="consumoEnergiaActivaEnWhP6"
                        placeholder=""
                        name="consumoEnergiaActivaEnWhP6"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaActivaEnWhP6}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="4"></Col>
                  <Col sm="1">
                    <Label className="form-control-label" htmlFor="potencia">
                      Potencia
                    </Label>
                  </Col>

                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP1">
                        P1
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP1"
                        placeholder=""
                        name="potenciaDemandadaEnWP1"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP1}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP2">
                        P2
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP2"
                        placeholder=""
                        name="potenciaDemandadaEnWP2"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP2}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP3">
                        P3
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP3"
                        placeholder=""
                        name="potenciaDemandadaEnWP3"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP3}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP4">
                        P4
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP4"
                        placeholder=""
                        name="potenciaDemandadaEnWP4"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP4}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP5">
                        P5
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP5"
                        placeholder=""
                        name="potenciaDemandadaEnWP5"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP5}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="PP6">
                        P6
                      </Label>
                      <Input
                        id="potenciaDemandadaEnWP6"
                        placeholder=""
                        name="potenciaDemandadaEnWP6"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.potenciaDemandadaEnWP6}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="4"></Col>
                  <Col sm="1">
                    <Label
                      className="form-control-label"
                      htmlFor="energiaReactiva"
                    >
                      Reactiva
                    </Label>
                  </Col>

                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP1">
                        P1
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP1"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP1"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP1}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP2">
                        P2
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP2"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP2"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP2}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP3">
                        P3
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP3"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP3"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP3}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP4">
                        P4
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP4"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP4"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP4}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP5">
                        P5
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP5"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP5"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP5}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="RP6">
                        P6
                      </Label>
                      <Input
                        id="consumoEnergiaReactivaEnVArhP6"
                        placeholder=""
                        name="consumoEnergiaReactivaEnVArhP6"
                        onChange={this.onChangeField}
                        value={form_T_CUPs.consumoEnergiaReactivaEnVArhP6}
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>

          {this.state.alert}
          <Row className="card-deck" style={showList}>
            <Card className="mt-3 shadow">
              <CardBody>
                <CardTitle>Consumos</CardTitle>
                <LoadingOverlay
                  active={this.state.isLoadingConsumos}
                  spinner
                  text="Cargando..."
                >
                  <Row>
                    <SipsTable
                      checkBoxButton={true}
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumos}
                      fields={this.state.tableHeaders}
                      selectedItem={this.selectedItems}
                      expandableFields={false}
                    ></SipsTable>
                  </Row>
                </LoadingOverlay>
              </CardBody>
            </Card>
          </Row>
          <CardTitle className="info-title" style={showList}>
            <h2>Historial de consumos. ENERGIA</h2>
          </CardTitle>

          <Row className="card-deck" style={showListFull}>
            <Card className="card-stats" style={{ height: 400 }}>
              <ResponsiveBar
                data={this.state.consumosEnergia}
                keys={["P1", "P2", "P3", "P4", "P5", "P6"]}
                indexBy="fecha"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "fechas",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "consumo KWh",
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
              />
            </Card>
          </Row>

          <CardTitle className="info-title" style={showList}>
            <h2>Historial de consumos. POTENCIA</h2>
          </CardTitle>

          <Row className="card-deck" style={showListFull}>
            <Card className="card-stats" style={{ height: 400 }}>
              <ResponsiveBar
                data={this.state.consumosPotencia}
                keys={["P1", "P2", "P3", "P4", "P5", "P6"]}
                indexBy="fecha"
                groupMode="grouped"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "fechas",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "consumo KWh",
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
              />
            </Card>
          </Row>

          <Row className="card-deck" style={showComercialMultiProducto}>
            <ComercialMultiProducto
              sip={this.state.sip}
              ubi={this.state.ubicacion}
              productoMulti={this.state.productoMulti}
              periods={this.state.periodos}
              itemsConsumo={this.state.itemsSelected}
              comercial={this.state.comerciales}
              output={this.comparativeDataHandler}
              onSave={this.onSave}
            ></ComercialMultiProducto>
          </Row>
        </Container>

        {freeModal}

        <SaveModal
          isOpen={this.state.saveModalOpened}
          toggle={(event) => this.toggleModal(event, "saveModalOpened")}
          onSave={this.onSave}
          sip={this.state.sip}
          ubi={this.state.ubicacion}
          nombrePropuesta={this.state.nombrePropuesta}
          companyId={this.state.companyID}
          userId={this.state.userId}
        ></SaveModal>
      </>
    );
  }
}

export default withApollo(SipComparativeFree);

import ContratoRepresentanteFreeModal from "./ContratoRepresentanteModal";
import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';

//import DatePicker from "react-datepicker";

import { validateSpanishId, spainIdType } from 'spain-id'

import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, FormFeedback
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";
import { LIST_DICMUNICIPIOS, LIST_DICMUNICIPIOSPOSTALSHORT } from "../../queries/options/dic-option.graphql";


import { withApollo } from "react-apollo";
import client from "../../api/client";

import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";
import SipsOption from "../../components/Sips/SipsOption";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import CircularProgress from '@material-ui/core/CircularProgress';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "./es.json";

var DatePicker = require("reactstrap-date-picker");
const FileDownload = require('js-file-download');

LoadingOverlay.propTypes = undefined

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(esMessages, "es-ES");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);

const columnsEstados = memoize(clickHandler => [
    {
        selector: row => row.accion, name: 'ESTADO', sortable: true, width: '140px', center: true, style: {
            color: 'rgba(0,0,0,.54)'
        }, format: (row => capitalize(row.accion))
    },
    { selector: row => row.fecha, name: 'FECHA', sortable: true, width: '120px', center: true, format: (row => moment(row.fecha, 'YYYY-MM-DD', true).isValid() ? moment(row.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row.hora, name: 'HORA', sortable: true, width: '120px', center: true, format: (row => capitalize(row.hora)) },
    { selector: row => row.observacion, name: 'OBSERVACIONES', sortable: true, width: '250px', center: true, format: (row => capitalize(row.observacion)) },
    { selector: row => row.nombreUsuario, name: 'AGENTE', sortable: true, width: '160px', center: true, format: (row => capitalize(row.nombreUsuario)) }
]);

const columnsDatos = [
    { selector: row => row.id, name: 'ID', sortable: true, width: '150px', format: (row => capitalize(row.id)) },
    { selector: row => row.nombreProducto, name: 'PRODUCTO', sortable: true, width: '200px', center: true, format: (row => capitalize(row.nombreProducto)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '200px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    {
        selector: row => row['ahorro'].ahorroAnual, name: 'AHORRO', sortable: true, width: '200px', center: true, format: (row => `${Number(row['ahorro'].ahorroAnual ? row['ahorro'].ahorroAnual : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    }

];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}
const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);
const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



class SipDetallesPropuestas extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    alert: null;
    state = {
        filters: {
            cupsSearch: "",
            contratoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        page: 1,
        limit: 10,
        sipsConsumosTotal: [],
        sipsConsumos: [],
        sipsDetail: [],
        contratoDetail: {
            fechaDenegacion: "",
            fechaFirmaContrato: '',
            fechaVencimientoContrato: '',
            CIF: '',
            desMunicipioTitular: '',
            tipoViaTitular: '',
            viaTitular: '',
            numFincaTitular: '',
            portalTitular: '',
            escaleraTitular: '',
            pisoTitular: '',
            puertaTitular: '',
            cups: '',
            idContrato: '',
            idTarifaPeaje: '',
            nombreProducto: '',
            precioTEP1: 0,
            precioTEP3: 0,
            nombreTitular: '',
            apellido1Titular: '',
            apellido2Titular: '',
            desProvinciaTitular: '',
            iban: '',
            idTipoContrato: '',
            idRepresentante: '',
            datosContrato: '',
            CNAE: '',
            fechaInicioContrato: ''

        },
        contratoDetailTotal: [],
        isLoadingSips: true,
        isLoadingConsumos: true,
        isLoadingConsumosTotales: true,
        changeLogoLoading: false,
        updateProfileLoading: false,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        filesContrato: [],
        LecturasContrato: [],
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        userName: '',
        fechaDenegacion: '',
        fechaBajaContrato: '',
        fechaContrato: '',
        fechaFinContrato: '',
        fechaFirmaContrato: '',
        fechaInicioContrato: '',
        fechaVencimientoContrato: '',
        fechaActivacionFlag: 0,
        fechaFirmaContratoFlag: 0,
        fechaVencimientoContratoFlag: 0,
        saveData: false,
        saveDataRepre: false,
        saveProductButtonDisabled: true,
        contratoButtonDisabled: false,
        saveXMLButtonDisabled: true,
        idComercializadoraActualContrato: '',
        nombreComercializadoraActualContrato: '',
        cups: '',
        CIF: '',
        consumoAnualCup: '',
        idRepresentante: '',
        representanteDetail: [],
        setPending: true,
        T_Titular: {
            NombreTitular: "",
            Apellido1Titular: "",
            Apellido2Titular: "",
            NIF: "",
            TipoTitular: "",
            TipoViaTitular: "",
            ViaTitular: "",
            NumFincaTitular: "",
            PortalTitular: "",
            EscaleraTitular: "",
            PisoTitular: "",
            PuertaTitular: "",
            CodigoPostalTitular: "",
            AutonomiaTitular: "",
            ProvinciaTitular: "",
            MunicipioTitular: "",
            TelefonoFijo: "",
            TelefonMovil: "",
            Email: "",
            TipoCliente: "",
            ObservacionesCliente: "",
            GDPR: true,
            IBAN: "",
            CNAE: "",
            actividadCNAE: ""
        },
        T_Representante: {
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            NIFRepresentante: "",
            TipoTitularRepresentante: "",
            idRepresentante: "",
            idRepresentanteInterno: 0,
            CargoRepresentante: "",
            telefonoRepresentante: "",
            correoRepresentante: "",
            codigoPostalRepresentante: "",
            AutonomiaRepresentante: "",
            MunicipioRepresentante: "",
            ProvinciaRepresentante: "",
            escaleraRepresentante: "",
            numFincaRepresentante: "",
            pisoRepresentante: "",
            portalRepresentante: "",
            puertaRepresentante: "",
            tipoViaRepresentante: "",
            viaRepresentante: ""
        },
        T_PuntoSuministro: {
            TipoViaPS: "",
            ViaPS: "",
            NumFincaPS: "",
            PortalPS: "",
            EscaleraPS: "",
            PisoPS: "",
            PuertaPS: "",
            CodigoPostalPS: "",
            NombreMunicipioPS: "",
            codigoMunicipioPS: "",
            NombreProvinciaPS: "",
            codigoProvinciaPS: "",
            AutonomiaPS: "",
            codigoAutonomiaPS: "",
            TelefonoPS: "",
            ObservacionesPuntoSuministro: ""
        },
        T_CUPs: {
            Distribuidora: "",
            CUPsEle: "",
            Tarifa: "",
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            P6: 0,
            PotMaxBie: "",
            TipoServicio: "",
            Consumo: "",
            esViviendaHabitual: "",
            fechaCaducidadAPM: null,
            fechaCaducidadBIE: null,
            fechaEmisionAPM: null,
            fechaEmisionBIE: null,
            fechaLimiteDerechosReconocidos: null,
            fechaUltimaLectura: null,
            fechaUltimoCambioComercializador: null,
            fechaUltimoMovimientoContrato: null,
            importeDepositoGarantiaEuros: 0,
            informacionImpagos: null,
            codigoAutoconsumo: "",
            descAutoconsumo: "",
            codigoTelegestion: "",
            tipoTelegestion: "",
            proEquipoMedida: "",
            propiedadICP: "",
            relacionTransformacionIntensidad: "",
            tarifaATR: "",
            tensionEm: "",
            tipoContrato: "",
            valorDerechosAccesoW: "",
            valorDerechosExtensionW: "",
            Tension: "",
            accesoContador: ""
        },
        T_PropuestaComercial: {
            PorcentajeAhorro: 0,
            ImporteAhorro: 0,
            Estado: "",
            Comercializadora: "",
            Producto: "",
            TarifaPeaje: "",
            Tarifa: "",
            IdProducto: "",
            Anexo: "",
            TipoPrecio: "",
            ObservacionesProducto: "",
            GastoOperativo: 0,
            GastoOperativoPotencia: 0,
            idServicio: "",
            idComercializadora: "",
            nombreServicio: "",
            precioServicio: "",
            ComercializadoraProcedencia: "",
            idComercializadoraProcedencia: "",
            esNuevoContrato: "",
            propuestaGlobal: [{}],
            comision: {
                idTipoComision: "A",
                comisionAgenteTEP1: 0
            }
        },
        T_DatosAgenteComercial: {
            usuario: "",
            nombreUsuario: "",
            compania: 0,
            nombreCompania: "",
            correoAgente: "",
            zonaGeografica: ""
        },
        contratoDataGeneralElectricidad: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTEP3: 0,
            precioContratoTEP4: 0,
            precioContratoTEP5: 0,
            precioContratoTEP6: 0,
            precioContratoTP1: 0,
            precioContratoTP3: 0,
            precioContratoTP2: 0,
            precioContratoTP4: 0,
            precioContratoTP5: 0,
            precioContratoTP6: 0,
            costeOperativo: "",
            costeOperativoPotencia: "",
            descuentoTerminoEnergia: 0,
            descuentoTerminoPotencia: 0,
            duracionContrato: ""
        },
        contratoDataGeneralGas: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTFijo: 0,
            coeficienteK: 0,
            costeOperativo: 0,
            descuentoTerminoEnergia: 0,
            duracionContrato: "",
            caudalDiarioGas: 0
        },
        T_PropuestasAnalizadas: [{
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: "",
            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,
            penalizacionPotencia: 0,
            penalizacionEnergia: 0,
            numDaysNew: 30,
            numMonthNew: 1,
            fechaDesdeConsumo: "",
            fechaHastaConsumo: "",
            EnergiaActiva: [
                "0"
            ],
            Potencia: [
                "0"
            ],
            potenciaP1: 0,
            potenciaP2: 0,
            potenciaP3: 0,
            potenciaP4: 0,
            potenciaP5: 0,
            potenciaP6: 0,
            potenciaContratoP1: 0,
            potenciaContratoP2: 0,
            potenciaContratoP3: 0,
            potenciaContratoP4: 0,
            potenciaContratoP5: 0,
            potenciaContratoP6: 0,
            energiaActivaP1: 0,
            energiaActivaP2: 0,
            energiaActivaP3: 0,
            energiaActivaP4: 0,
            energiaActivaP5: 0,
            energiaActivaP6: 0,
            energiaReactivaP1: 0,
            energiaReactivaP2: 0,
            energiaReactivaP3: 0,
            energiaReactivaP4: 0,
            energiaReactivaP5: 0,
            energiaReactivaP6: 0,
            precioPotenciaP1: 0,
            precioPotenciaP2: 0,
            precioPotenciaP3: 0,
            precioPotenciaP4: 0,
            precioPotenciaP5: 0,
            precioPotenciaP6: 0,
            precioEnergiaP1: 0,
            precioEnergiaP2: 0,
            precioEnergiaP3: 0,
            precioEnergiaP4: 0,
            precioEnergiaP5: 0,
            precioEnergiaP6: 0,
            idServicio: "",
            idComercializadora: "",
            nombreServicio: "",
            precioServicio: 0,
            id: "",
            nombreProducto: "",
            nombreComercializadora: "",
            comisionContratoFija: 0,
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoConsumoAdmin: 0,
            ahorro: {
                ahorroAnual: 0,
                ahorroPercent: 0,
                ahorroPeriodo: 0,
                idProducto: "",
                nombreOferta: "",
                idComercializadora: "",
                nombreComercializadora: "0",
                precioEnergiaP1: 0,
                precioEnergiaP2: 0,
                precioEnergiaP3: 0,
                precioEnergiaP4: 0,
                precioEnergiaP5: 0,
                precioEnergiaP6: 0,
                precioPotenciaP1: 0,
                precioPotenciaP2: 0,
                precioPotenciaP3: 0,
                precioPotenciaP4: 0,
                precioPotenciaP5: 0,
                precioPotenciaP6: 0,
                datosAhorro: [
                    0,
                    0,
                    0
                ]
            }
        }],
        T_General: {
            Usuario: "",
            compania: 0,
            fechaPropuesta: "",
            horaPropuesta: "",
            mesPropuesta: 0,
            anioPropuesta: 0,
            fechaAprobacion: "",
            horaAprobacion: "",
            mesAprobacion: 0,
            anioAprobacion: 0,
            esAprobada: true,
            fechaDenegacion: "",
            horaDenegacion: "",
            mesDenegacion: 0,
            anioDenegacion: 0,
            esDenegada: false,
            IdOferta: "",
            IdOfertaInterna: "",
            cups: "",
            tipoCliente: "",
            tipoEnergia: "",
            estado: "",
            OfertaTipo: "",
            ofertaTipoId: "",
            idContrato: "",
            idInternoContrato: "",
            idCliente: "",
            idTipoContrato: "",
            fechaContrato: "",
            esRenovacion: false,
            modRenovacion: "",
            existePropRenovacion: false,
            fechaInicioContrato: null,
            duracionContrato: "12",
            fechaVencimientoContrato: null,
            fechaFirmaContrato: null,
            fechaFinContrato: null,
            referenciaContrato: "",
            observacionesContrato: "",
            idTipoSeguimiento: "",
            contratoGeneradoEnviado: false,
            esBajaContrato: false,
            fechaBajaContrato: "",
            justificacionBajaContrato: "",
            rutaDocumento: "",
            createdAt: "",
            updatedAt: "",
            createdBy: "",
            updatedBy: "",
            estadoCups: "",
            idContratoComercializadora: "",
            estadoSinComercialiazadora: "",
            estadoId: "00",
            nombrePropuesta: "",
            esTransferencia:false

        },
        T_Seguimiento: [{
            accion: '',
            anio: 0,
            compania: '',
            fecha: '',
            hora: '',
            idAccion: '',
            mes: 0,
            nombreUsuario: '',
            observacion: "",
            usuario: ''
        }],
        T_SeguimientoAux: {
            accion: null,
            anio: null,
            compania: null,
            fecha: null,
            hora: null,
            idAccion: null,
            mes: null,
            nombreUsuario: null,
            observacion: "",
            usuario: null
        },
        T_Propuesta: {
            producto: {
                datos: {},
                itemsPeriodos: {},
                periodos: [],
                costeOperativo: 0,
                costeOperativoPotencia: 0,
                esNuevoContrato: 0,
                totalComparacion: 0
            },
            actual: {},

        },
        T_PropuestaComercialDatosIndividual: {},
        propuestaData: {
            precioPotenciaP1: 0,
            precioPotenciaP2: 0,
            precioPotenciaP3: 0,
            precioPotenciaP4: 0,
            precioPotenciaP5: 0,
            precioPotenciaP6: 0,
            precioEnergiaP1: 0,
            precioEnergiaP2: 0,
            precioEnergiaP3: 0,
            precioEnergiaP4: 0,
            precioEnergiaP5: 0,
            precioEnergiaP6: 0
        },
        propuestaComision: {
            idTipoComision: "A",
            comisionAgenteTEP1: 0,
            comisionAgenteTEP2: 0,
            comisionAgenteTEP3: 0,
            comisionAgenteTEP4: 0,
            comisionAgenteTEP5: 0,
            comisionAgenteTEP6: 0,
            comisionAgenteTPP1: 0,
            comisionAgenteTPP2: 0,
            comisionAgenteTPP3: 0,
            comisionAgenteTPP4: 0,
            comisionAgenteTPP5: 0,
            comisionAgenteTPP6: 0
        },
        T_Consumos: [{}],
        T_Datos_Comision: [{}],
        T_Transferencia:[],
        observacionAux: "",
        estadoAux: "",
        estadoIdAux: "",
        saveButtonDisabledEstado: true,
        aprobarButtonDisabled: false,
        CIFRepreError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        inputCifDisabled: false,
        inputCupsDisabled: false,
        inputContratoDisabled: false,
        openModalRepresentante: false,
        representanteSeleccionado: [],
        openModalComparative: false,
        openModalConsumos: false,
        representante: [],
        locale: 'es',
        alert: null,
        dicEstados: {},
        estadoDisabled: false,
        productoTarifas: {},
        errors: "",
        errores: {
            NombreTitular: true,
            Apellido1Titular: true,
            NIF: true,
            TipoViaTitular: true,
            ViaTitular: true,
            NumFincaTitular: true,
            CodigoPostalTitular: true,
            AutonomiaTitular: true,
            ProvinciaTitular: true,
            MunicipioTitular: true,
            Email: true,
            TipoViaPS: true,
            ViaPS: true,
            NumFincaPS: true,
            CodigoPostalPS: true,
            NombreMunicipioPS: true,
            NombreProvinciaPS: true,
            AutonomiaPS: true,
            nombreRepresentante: true,
            apellido1Representante: true,
            NIFRepresentante: true,
            CargoRepresentante: true,
            Producto: true
        },
        textErrores: {
            NombreTitular: "",
            Apellido1Titular: "",
            NIF: "",
            TipoViaTitular: "",
            ViaTitular: "",
            NumFincaTitular: "",
            CodigoPostalTitular: "",
            AutonomiaTitular: "",
            ProvinciaTitular: "",
            MunicipioTitular: "",
            Email: "",
            TipoViaPS: "",
            ViaPS: "",
            NumFincaPS: "",
            CodigoPostalPS: "",
            NombreMunicipioPS: "",
            NombreProvinciaPS: "",
            AutonomiaPS: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            NIFRepresentante: "",
            CargoRepresentante: "",
            Producto: ""
        },
        dicMunicipios: [],
        dicMunicipiosList: [],
        tieneRepresentante: false,
        dataComisionCo: [{}],
        dataComision: [{}],
        dataComisionCoTarifa: [{}]

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];




    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.lecturasRef = React.createRef();
        this.inputEl = React.createRef();

        this.showModal = this.showModal.bind(this);

        this.toggle = this.toggle.bind(this);

    }

    UNSAFE_componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('entra en UNSAFE_componentWillReceiveProps ')

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.fullname

        var direccion = ""
        if (companyIdUser) {
            direccion = `http://78.47.44.246:3020/filemanager/` + companyIdUser
        }

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            userName
        });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        const {
            match: { params },
        } = this.props;


        console.log('entra en componentDidMount ')

        this.fetchMunicipios();


        this.setState({
            contrato: params.cups,
            tipoContrato: params.tipo
        }, this.fetchItems);

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = '';
        let userId = '';
        let userName = '';

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;
                userId = parsed.id
                userName = parsed.fullname

            } catch (e) {
                console.log(e)
            }
        }
        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    fetchMunicipios = async () => {
        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };


    fetchItemsMunicipio = async (codigoMunicipio) => {
        const codigoPostal = codigoMunicipio
        await client.query({
            query: LIST_DICMUNICIPIOSPOSTALSHORT,
            variables: { codigoPostal: codigoPostal }
        })
            .then((result) => {
                let data = result.data.listDicMunicipiosPostalShort;
                this.setState({ dicMunicipiosList: data })
            });
    };


    fetchItems = async () => {

        var existeRepre = 0
        const companyIdAux = this.state.companyIdUser.toString();
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = ""
        }

        var dataFilters = {
            'compania': "",
            'cups': "",
            'Usuario': userQuery,
            'NIF': "",
            'IdOferta': this.state.contrato
        };
        var contratoDato: any = []

        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/listado?id=" + companyIdAux + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                contratoDato = result.data.rows[0]
                let contratoDatosGenerales = { ...result.data.rows[0] }
                delete contratoDatosGenerales.T_Titular
                delete contratoDatosGenerales.T_Representante
                delete contratoDatosGenerales.T_PuntoSuministro
                delete contratoDatosGenerales.T_CUPs
                delete contratoDatosGenerales.T_PropuestaComercial
                delete contratoDatosGenerales.T_DatosAgenteComercial
                delete contratoDatosGenerales.contratoDataGeneralElectricidad
                delete contratoDatosGenerales.contratoDataGeneralGas
                delete contratoDatosGenerales.T_Propuesta
                delete contratoDatosGenerales.T_Seguimiento
                delete contratoDatosGenerales.T_PropuestasAnalizadas
                delete contratoDatosGenerales.T_Transferencia

                if (contratoDatosGenerales.T_Datos_Comision) {
                    console.log('VALOR DE T_Datos_Comision: ', contratoDatosGenerales.T_Datos_Comision.lenght)
                    console.log('VALOR DE T_Datos_Comision.dataComisionCo: ', contratoDatosGenerales.T_Datos_Comision.dataComisionCo)
                    console.log('VALOR DE T_Datos_Comision.dataComision: ', contratoDatosGenerales.T_Datos_Comision.dataComision)
                    console.log('VALOR DE T_Datos_Comision.dataComisionCoTarifa: ', contratoDatosGenerales.T_Datos_Comision.dataComisionCoTarifa)


                } else {
                    console.log('VALOR DE T_Datos_Comision en null: ', contratoDatosGenerales.T_Datos_Comision)

                }

                delete contratoDatosGenerales.T_Datos_Comision

                var estadoDisabledButton = false
                var aprobarDisabledButton = false
                if (contratoDato.esAprobada == true || contratoDato.esDenegada == true) {
                    estadoDisabledButton = true
                    aprobarDisabledButton = true
                }

                var propuestaDataAux = {
                    precioPotenciaP1: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP1 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP1 : 0,
                    precioPotenciaP2: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP2 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP2 : 0,
                    precioPotenciaP3: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP3 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP3 : 0,
                    precioPotenciaP4: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP4 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP4 : 0,
                    precioPotenciaP5: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP5 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP5 : 0,
                    precioPotenciaP6: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP6 ? result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP6 : 0,
                    precioEnergiaP1: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP1 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP1 : 0,
                    precioEnergiaP2: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP2 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP2 : 0,
                    precioEnergiaP3: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP3 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP3 : 0,
                    precioEnergiaP4: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP4 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP4 : 0,
                    precioEnergiaP5: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP5 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP5 : 0,
                    precioEnergiaP6: result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP6 ? result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP6 : 0

                }


                if (result.data.rows[0].OfertaTipo == 'MP') {

                    propuestaDataAux = {
                        precioPotenciaP1: result.data.rows[0].T_Propuesta.producto.precioPotenciaP1 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP1 : 0,
                        precioPotenciaP2: result.data.rows[0].T_Propuesta.producto.precioPotenciaP2 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP2 : 0,
                        precioPotenciaP3: result.data.rows[0].T_Propuesta.producto.precioPotenciaP3 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP3 : 0,
                        precioPotenciaP4: result.data.rows[0].T_Propuesta.producto.precioPotenciaP4 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP4 : 0,
                        precioPotenciaP5: result.data.rows[0].T_Propuesta.producto.precioPotenciaP5 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP5 : 0,
                        precioPotenciaP6: result.data.rows[0].T_Propuesta.producto.precioPotenciaP6 ? result.data.rows[0].T_Propuesta.producto.precioPotenciaP6 : 0,
                        precioEnergiaP1: result.data.rows[0].T_Propuesta.producto.precioEnergiaP1 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP1 : 0,
                        precioEnergiaP2: result.data.rows[0].T_Propuesta.producto.precioEnergiaP2 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP2 : 0,
                        precioEnergiaP3: result.data.rows[0].T_Propuesta.producto.precioEnergiaP3 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP3 : 0,
                        precioEnergiaP4: result.data.rows[0].T_Propuesta.producto.precioEnergiaP4 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP4 : 0,
                        precioEnergiaP5: result.data.rows[0].T_Propuesta.producto.precioEnergiaP5 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP5 : 0,
                        precioEnergiaP6: result.data.rows[0].T_Propuesta.producto.precioEnergiaP6 ? result.data.rows[0].T_Propuesta.producto.precioEnergiaP6 : 0

                    }
                }

                let errorsData = { ...this.state.errores }
                let textErroresData = { ...this.state.textErrores }

                if (!result.data.rows[0].T_PropuestaComercial.Producto || result.data.rows[0].T_PropuestaComercial.Producto.length == 0 || result.data.rows[0].T_PropuestaComercial.Producto == "" || result.data.rows[0].T_PropuestaComercial.Producto == "NO SELECCIONADO") {
                    errorsData['Producto'] = false
                    textErroresData['Producto'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PropuestaComercial.Producto || result.data.rows[0].T_PropuestaComercial.Producto.length > 0 || result.data.rows[0].T_PropuestaComercial.Producto != "" || result.data.rows[0].T_PropuestaComercial.Producto != "NO SELECCIONADO") {
                    errorsData['Producto'] = true
                    textErroresData['Producto'] = ""
                };

                if (!result.data.rows[0].T_PuntoSuministro.TipoViaPS || result.data.rows[0].T_PuntoSuministro.TipoViaPS.length == 0 || result.data.rows[0].T_PuntoSuministro.TipoViaPS == "") {
                    errorsData['TipoViaPS'] = false
                    textErroresData['TipoViaPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.TipoViaPS || result.data.rows[0].T_PuntoSuministro.TipoViaPS.length > 0 || result.data.rows[0].T_PuntoSuministro.TipoViaPS != "") {
                    errorsData['TipoViaPS'] = true
                    textErroresData['TipoViaPS'] = ""
                };

                if (!result.data.rows[0].T_PuntoSuministro.ViaPS || result.data.rows[0].T_PuntoSuministro.ViaPS.length == 0 || result.data.rows[0].T_PuntoSuministro.ViaPS == "") {
                    errorsData['ViaPS'] = false
                    textErroresData['ViaPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.ViaPS || result.data.rows[0].T_PuntoSuministro.ViaPS.length > 0 || result.data.rows[0].T_PuntoSuministro.ViaPS != "") {
                    errorsData['ViaPS'] = true
                    textErroresData['ViaPS'] = ""
                };

                if (!result.data.rows[0].T_PuntoSuministro.NumFincaPS || result.data.rows[0].T_PuntoSuministro.NumFincaPS.length == 0 || result.data.rows[0].T_PuntoSuministro.NumFincaPS == "") {
                    errorsData['NumFincaPS'] = false
                    textErroresData['NumFincaPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.NumFincaPS || result.data.rows[0].T_PuntoSuministro.NumFincaPS.length > 0 || result.data.rows[0].T_PuntoSuministro.NumFincaPS != "") {
                    errorsData['NumFincaPS'] = true
                    textErroresData['NumFincaPS'] = ""
                };
                if (!result.data.rows[0].T_PuntoSuministro.CodigoPostalPS || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS.length == 0 || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS == "" || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS == "00000") {
                    errorsData['CodigoPostalPS'] = false
                    textErroresData['CodigoPostalPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.CodigoPostalPS || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS.length > 0 || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS != "" || result.data.rows[0].T_PuntoSuministro.CodigoPostalPS != "00000") {
                    errorsData['CodigoPostalPS'] = true
                    textErroresData['CodigoPostalPS'] = ""
                };
                if (!result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS || result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS.length == 0 || result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS == "") {
                    errorsData['NombreMunicipioPS'] = false
                    textErroresData['NombreMunicipioPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS || result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS.length > 0 || result.data.rows[0].T_PuntoSuministro.NombreMunicipioPS != "") {
                    errorsData['NombreMunicipioPS'] = true
                    textErroresData['NombreMunicipioPS'] = ""
                };
                if (!result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS || result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS.length == 0 || result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS == "") {
                    errorsData['NombreProvinciaPS'] = false
                    textErroresData['NombreProvinciaPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS || result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS.length > 0 || result.data.rows[0].T_PuntoSuministro.NombreProvinciaPS != "") {
                    errorsData['NombreProvinciaPS'] = true
                    textErroresData['NombreProvinciaPS'] = ""
                };
                if (!result.data.rows[0].T_PuntoSuministro.AutonomiaPS || result.data.rows[0].T_PuntoSuministro.AutonomiaPS.length == 0 || result.data.rows[0].T_PuntoSuministro.AutonomiaPS == "") {
                    errorsData['AutonomiaPS'] = false
                    textErroresData['AutonomiaPS'] = "SIN DATOS"
                } else if (result.data.rows[0].T_PuntoSuministro.AutonomiaPS || result.data.rows[0].T_PuntoSuministro.AutonomiaPS.length > 0 || result.data.rows[0].T_PuntoSuministro.AutonomiaPS != "") {
                    errorsData['AutonomiaPS'] = true
                    textErroresData['AutonomiaPS'] = ""
                };

                if (!result.data.rows[0].T_Titular.NombreTitular || result.data.rows[0].T_Titular.NombreTitular.length == 0 || result.data.rows[0].T_Titular.NombreTitular == "") {
                    errorsData['NombreTitular'] = false
                    textErroresData['NombreTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.NombreTitular || result.data.rows[0].T_Titular.NombreTitular.length > 0 || result.data.rows[0].T_Titular.NombreTitular != "") {
                    errorsData['NombreTitular'] = true
                    textErroresData['NombreTitular'] = ""
                };
                if (!result.data.rows[0].T_Titular.Apellido1Titular || result.data.rows[0].T_Titular.Apellido1Titular.length == 0 || result.data.rows[0].T_Titular.Apellido1Titular == "") {
                    errorsData['Apellido1Titular'] = false
                    textErroresData['Apellido1Titular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.Apellido1Titular || result.data.rows[0].T_Titular.Apellido1Titular.length > 0 || result.data.rows[0].T_Titular.Apellido1Titular != "") {
                    errorsData['Apellido1Titular'] = true
                    textErroresData['Apellido1Titular'] = ""
                };

                if (!result.data.rows[0].T_Titular.NIF || result.data.rows[0].T_Titular.NIF.length == 0 || result.data.rows[0].T_Titular.NIF == "") {
                    errorsData['NIF'] = false
                    textErroresData['NIF'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.NIF || result.data.rows[0].T_Titular.NIF.length > 0 || result.data.rows[0].T_Titular.NIF != "") {
                    errorsData['NIF'] = true
                    textErroresData['NIF'] = ""
                };

                if (!result.data.rows[0].T_Titular.TipoViaTitular || result.data.rows[0].T_Titular.TipoViaTitular.length == 0 || result.data.rows[0].T_Titular.TipoViaTitular == "") {
                    errorsData['TipoViaTitular'] = false
                    textErroresData['TipoViaTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.TipoViaTitular || result.data.rows[0].T_Titular.TipoViaTitular.length > 0 || result.data.rows[0].T_Titular.TipoViaTitular != "") {
                    errorsData['TipoViaTitular'] = true
                    textErroresData['TipoViaTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.ViaTitular || result.data.rows[0].T_Titular.ViaTitular.length == 0 || result.data.rows[0].T_Titular.ViaTitular == "") {
                    errorsData['ViaTitular'] = false
                    textErroresData['ViaTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.ViaTitular || result.data.rows[0].T_Titular.ViaTitular.length > 0 || result.data.rows[0].T_Titular.ViaTitular != "") {
                    errorsData['ViaTitular'] = true
                    textErroresData['ViaTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.NumFincaTitular || result.data.rows[0].T_Titular.NumFincaTitular.length == 0 || result.data.rows[0].T_Titular.NumFincaTitular == "") {
                    errorsData['NumFincaTitular'] = false
                    textErroresData['NumFincaTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.NumFincaTitular || result.data.rows[0].T_Titular.NumFincaTitular.length > 0 || result.data.rows[0].T_Titular.NumFincaTitular != "") {
                    errorsData['NumFincaTitular'] = true
                    textErroresData['NumFincaTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.CodigoPostalTitular || result.data.rows[0].T_Titular.CodigoPostalTitular.length == 0 || result.data.rows[0].T_Titular.CodigoPostalTitular == "" || result.data.rows[0].T_Titular.CodigoPostalTitular == "00000") {
                    errorsData['CodigoPostalTitular'] = false
                    textErroresData['CodigoPostalTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.CodigoPostalTitular || result.data.rows[0].T_Titular.CodigoPostalTitular.length > 0 || result.data.rows[0].T_Titular.CodigoPostalTitular != "" || result.data.rows[0].T_Titular.CodigoPostalTitular != "00000") {
                    errorsData['CodigoPostalTitular'] = true
                    textErroresData['CodigoPostalTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.AutonomiaTitular || result.data.rows[0].T_Titular.AutonomiaTitular.length == 0 || result.data.rows[0].T_Titular.AutonomiaTitular == "") {
                    errorsData['AutonomiaTitular'] = false
                    textErroresData['AutonomiaTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.AutonomiaTitular || result.data.rows[0].T_Titular.AutonomiaTitular.length > 0 || result.data.rows[0].T_Titular.AutonomiaTitular != "") {
                    errorsData['AutonomiaTitular'] = true
                    textErroresData['AutonomiaTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.ProvinciaTitular || result.data.rows[0].T_Titular.ProvinciaTitular.length == 0 || result.data.rows[0].T_Titular.ProvinciaTitular == "") {
                    errorsData['ProvinciaTitular'] = false
                    textErroresData['ProvinciaTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.ProvinciaTitular || result.data.rows[0].T_Titular.ProvinciaTitular.length > 0 || result.data.rows[0].T_Titular.ProvinciaTitular != "") {
                    errorsData['ProvinciaTitular'] = true
                    textErroresData['ProvinciaTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.MunicipioTitular || result.data.rows[0].T_Titular.MunicipioTitular.length == 0 || result.data.rows[0].T_Titular.MunicipioTitular == "") {
                    errorsData['MunicipioTitular'] = false
                    textErroresData['MunicipioTitular'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.MunicipioTitular || result.data.rows[0].T_Titular.MunicipioTitular.length > 0 || result.data.rows[0].T_Titular.MunicipioTitular != "") {
                    errorsData['MunicipioTitular'] = true
                    textErroresData['MunicipioTitular'] = ""
                };

                if (!result.data.rows[0].T_Titular.Email || result.data.rows[0].T_Titular.Email.length == 0 || result.data.rows[0].T_Titular.Email == "") {
                    errorsData['Email'] = false
                    textErroresData['Email'] = "SIN DATOS"
                } else if (result.data.rows[0].T_Titular.Email || result.data.rows[0].T_Titular.Email.length > 0 || result.data.rows[0].T_Titular.Email != "") {
                    errorsData['Email'] = true
                    textErroresData['Email'] = ""
                };


                if (result.data.rows[0].T_Titular.TipoTitular == "CIF") {


                    if (!result.data.rows[0].T_Representante.nombreRepresentante || result.data.rows[0].T_Representante.nombreRepresentante.length == 0 || result.data.rows[0].T_Representante.nombreRepresentante == "") {
                        errorsData['nombreRepresentante'] = false
                        textErroresData['nombreRepresentante'] = "SIN DATOS"
                    } else if (result.data.rows[0].T_Representante.nombreRepresentante || result.data.rows[0].T_Representante.nombreRepresentante.length > 0 || result.data.rows[0].T_Representante.nombreRepresentante != "") {
                        errorsData['nombreRepresentante'] = true
                        textErroresData['nombreRepresentante'] = ""
                    };

                    if (!result.data.rows[0].T_Representante.apellido1Representante || result.data.rows[0].T_Representante.apellido1Representante.length == 0 || result.data.rows[0].T_Representante.apellido1Representante == "") {
                        errorsData['apellido1Representante'] = false
                        textErroresData['apellido1Representante'] = "SIN DATOS"
                    } else if (result.data.rows[0].T_Representante.apellido1Representante || result.data.rows[0].T_Representante.apellido1Representante.length > 0 || result.data.rows[0].T_Representante.apellido1Representante != "") {
                        errorsData['apellido1Representante'] = true
                        textErroresData['apellido1Representante'] = ""
                    };

                    if (!result.data.rows[0].T_Representante.NIFRepresentante || result.data.rows[0].T_Representante.NIFRepresentante.length == 0 || result.data.rows[0].T_Representante.NIFRepresentante == "") {
                        errorsData['NIFRepresentante'] = false
                        textErroresData['NIFRepresentante'] = "SIN DATOS"
                    } else if (result.data.rows[0].T_Representante.NIFRepresentante || result.data.rows[0].T_Representante.NIFRepresentante.length > 0 || result.data.rows[0].T_Representante.NIFRepresentante != "") {
                        errorsData['NIFRepresentante'] = true
                        textErroresData['NIFRepresentante'] = ""
                    };
                    if (!result.data.rows[0].T_Representante.CargoRepresentante || result.data.rows[0].T_Representante.CargoRepresentante.length == 0 || result.data.rows[0].T_Representante.CargoRepresentante == "") {
                        errorsData['CargoRepresentante'] = false
                        textErroresData['CargoRepresentante'] = "SIN DATOS"
                    } else if (result.data.rows[0].T_Representante.CargoRepresentante || result.data.rows[0].T_Representante.CargoRepresentante.length > 0 || result.data.rows[0].T_Representante.CargoRepresentante != "") {
                        errorsData['CargoRepresentante'] = true
                        textErroresData['CargoRepresentante'] = ""
                    };

                    errorsData['Apellido1Titular'] = true
                    textErroresData['Apellido1Titular'] = ""

                }

                let datosComisionGlobal: any = []
                let dataComisionCo: any = [{}]
                let dataComision: any = [{}]
                let dataComisionCoTarifa: any = [{}]

                let propuestaComisionAux: any = {
                    idTipoComision: "A",
                    comisionAgenteTEP1: 0,
                }

                console.log('VALOR DE propuestaComisionAux: ', propuestaComisionAux)

                if (result.data.rows[0].T_PropuestaComercial.comision) {
                    propuestaComisionAux = result.data.rows[0].T_PropuestaComercial.comision
                }

                console.log('VALOR DE propuestaComisionAux DEspues: ', propuestaComisionAux)


                if (result.data.rows[0].T_Datos_Comision) {
                    datosComisionGlobal = result.data.rows[0].T_Datos_Comision
                    dataComisionCo = result.data.rows[0].T_Datos_Comision.dataComisionCo
                    dataComision = result.data.rows[0].T_Datos_Comision.dataComision
                    dataComisionCoTarifa = result.data.rows[0].T_Datos_Comision.dataComisionCoTarifa

                }

                this.setState({
                    contratoDetailTotal: result.data.rows[0],
                    T_Titular: result.data.rows[0].T_Titular,
                    T_General: contratoDatosGenerales,
                    T_Representante: result.data.rows[0].T_Representante,
                    T_PuntoSuministro: result.data.rows[0].T_PuntoSuministro,
                    T_CUPs: result.data.rows[0].T_CUPs,
                    T_PropuestaComercial: result.data.rows[0].T_PropuestaComercial,
                    T_DatosAgenteComercial: result.data.rows[0].T_DatosAgenteComercial,
                    contratoDataGeneralElectricidad: result.data.rows[0].contratoDataGeneralElectricidad,
                    contratoDataGeneralGas: result.data.rows[0].contratoDataGeneralGas,
                    T_Seguimiento: result.data.rows[0].T_Seguimiento,
                    T_Propuesta: result.data.rows[0].T_Propuesta,
                    T_PropuestasAnalizadas: result.data.rows[0].T_PropuestasAnalizadas,
                    T_Consumos: result.data.rows[0].T_Consumos,
                    T_Datos_Comision: datosComisionGlobal,
                    T_Transferencia: result.data.rows[0].T_Transferencia,
                    propuestaComision: propuestaComisionAux,
                    dataComisionCo: dataComisionCo,
                    dataComision: dataComision,
                    dataComisionCoTarifa: dataComisionCoTarifa,
                    estadoDisabled: estadoDisabledButton,
                    propuestaData: propuestaDataAux,
                    aprobarButtonDisabled: aprobarDisabledButton,
                    setPending: false,
                    errores: errorsData,
                    textErrores: textErroresData,

                })

            }).catch((error) => {
                console.log('Failure: ', error)
                this.warningAlert();
            });

        let fechaFirmaContratoAux = contratoDato.esAprobada
        let fechaDenegacionAux = contratoDato.esDenegada

        let idComercializadoraActualContrato = "";
        let nombreComercializadoraActualContrato = ""

        if (contratoDato.T_PropuestaComercial.hasOwnProperty('idComercializadoraProcedencia')) {
            idComercializadoraActualContrato = contratoDato.T_PropuestaComercial.idComercializadoraProcedencia
            nombreComercializadoraActualContrato = contratoDato.T_PropuestaComercial.ComercializadoraProcedencia
        };

        this.setState({
            idComercializadoraActualContrato: idComercializadoraActualContrato,
            nombreComercializadoraActualContrato: nombreComercializadoraActualContrato,
            CIF: contratoDato.T_Titular.NIF,
            cups: contratoDato.cups,
            fechaDenegacion: fechaDenegacionAux,
            idRepresentante: contratoDato.T_Representante.idRepresentante,
            consumoAnualCup: contratoDato.T_CUPs.Consumo
        });

        if (fechaFirmaContratoAux || fechaDenegacionAux) {
            this.setState({
                saveXMLButtonDisabled: false,
                inputDisabled: true,
                contratoButtonDisabled: false
            })
        } else {
            this.setState({
                saveXMLButtonDisabled: true,
                inputDisabled: false,
                contratoButtonDisabled: false
            })

        };

        existeRepre = contratoDato.T_Representante.idRepresentanteInterno
        this.setState({ isLoadingSips: false });


        if (existeRepre != 0 && (!fechaDenegacionAux || !fechaFirmaContratoAux)) {


            var dataFiltersRepre = {
                'compania': "",
                'cups': "",
                'Usuario': userQuery,
                'NIF': "",
                'idContrato': "",
                'NIFRepresentante': contratoDato.T_Representante.NIFRepresentante
            };

            valores = JSON.stringify(dataFiltersRepre)

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdAux + "&user=" + userIdAux;

            await axios.get(direccionFile, { headers, params: { valores } }).
                then(result => {
                    this.setState({
                        idRepresentante: result.data.rows[0].idRepresentanteInterno,
                        T_Representante: result.data.rows[0]
                    });
                }).catch((error) => {
                    console.log('Failure')
                    this.warningAlert()
                });
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos. Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    errorAlertAprobacion = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Faltan datos en los campos del formulario"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existen campos en el formulario sin valores. Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    errorAlertAprobacion2 = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existe producto seleccionado para la aprobación"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Para aprobar un contato se necesita asociar un producto
                </ReactBSAlert>
            )
        });
    };

    warningAlertService = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error de comunicación con el servicio de generación de contratos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error de comunicación con el servicio. Si persiste comuniquelo al soporte técnico
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiles = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en el servidor de ficheros"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en el servidor. Comunique el problema a soporte
                </ReactBSAlert>
            )
        });
    };


    confirmAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de eliminar el representante?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.borrar()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Borrar!"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };
    confirmedAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Borrado!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    EL representante se ha eliminado de la propuesta.
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de actualizar la propuesta?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave2 = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de aprobar la propuesta?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onAprobacion()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSavePDF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea generar la propuesta presentada al cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onPdf()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Generar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    pdfAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="contrato Generado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El contrato se ha generado correctamente. Consulta el directorio de descargas.
                </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Contrato actualizado correctamente
                </ReactBSAlert>
            )
        });
    };




    ///////////////////////////////////////////////////////////////////////////////

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    };

    ////////////////////////////////////////////////////////////////////////////

    openModalComparativa = async (event) => {
        this.showModal();
        return;

    };

    //////////////////////////////////////////////////////////////////////////7

    deleteRepresentante = async (event) => {
        this.confirmAlert();
    }

    deleteRepresentantePost = async (event) => {
        this.confirmAlert();
    }


    borrar = async () => {

        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        if (this.state.T_Titular.TipoTitular == "CIF") {

            errorsData['nombreRepresentante'] = false
            textErroresData['nombreRepresentante'] = "SIN DATOS"
            errorsData['apellido1Representante'] = false
            textErroresData['apellido1Representante'] = "SIN DATOS"
            errorsData['NIFRepresentante'] = false
            textErroresData['NIFRepresentante'] = "SIN DATOS"
            errorsData['CargoRepresentante'] = false
            textErroresData['CargoRepresentante'] = "SIN DATOS"
            errorsData['Apellido1Titular'] = true
            textErroresData['Apellido1Titular'] = ""
            errorsData['Apellido1Titular'] = true
            textErroresData['Apellido1Titular'] = ""

        } else {
            errorsData['nombreRepresentante'] = true
            textErroresData['nombreRepresentante'] = ""
            errorsData['apellido1Representante'] = true
            textErroresData['apellido1Representante'] = ""
            errorsData['NIFRepresentante'] = true
            textErroresData['NIFRepresentante'] = ""
            errorsData['CargoRepresentante'] = true
            textErroresData['CargoRepresentante'] = ""
            if (!this.state.T_Titular.Apellido1Titular || this.state.T_Titular.Apellido1Titular.length == 0 || this.state.T_Titular.Apellido1Titular == "") {
                errorsData['Apellido1Titular'] = false
                textErroresData['Apellido1Titular'] = "SIN DATOS"
            } else if (this.state.T_Titular.Apellido1Titular || this.state.T_Titular.Apellido1Titular.length > 0 || this.state.T_Titular.Apellido1Titular != "") {
                errorsData['Apellido1Titular'] = true
                textErroresData['Apellido1Titular'] = ""
            };


        }

        this.setState({
            T_Representante: {
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                NIFRepresentante: "",
                TipoTitularRepresentante: "",
                idRepresentante: "",
                idRepresentanteInterno: 0,
                CargoRepresentante: "",
                telefonoRepresentante: "",
                correoRepresentante: "",
                codigoPostalRepresentante: "",
                AutonomiaRepresentante: "",
                MunicipioRepresentante: "",
                ProvinciaRepresentante: "",
                escaleraRepresentante: "",
                numFincaRepresentante: "",
                pisoRepresentante: "",
                portalRepresentante: "",
                puertaRepresentante: "",
                tipoViaRepresentante: "",
                viaRepresentante: ""
            },
            saveProductButtonDisabled: false,
            errores: errorsData,
            textErrores: textErroresData
        });
        this.confirmedAlert()

    };



    ////////////////////////////////////////////////////////////////////////////////
    sipConsumosGasSelected = () => {
        let id = this.state.T_General.cups;
        const win = window.open("/consumos/sip-detalles-extendidos/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    /////////////////////////////////////////////////////////////////////////////////

    sipConsumosSelected = () => {
        let id = this.state.T_General.cups;
        const win = window.open("/consumos/sip-detalles-extendidos-elec/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    /////////////////////////////////////////////////////////////////////////////////

    onPageClick = (page) => { };

    onChangeFieldEstado = (event) => {
        event.persist()

        //let formData = { ...this.state.T_General};
        let formdataSeguimiento = { ...this.state.T_SeguimientoAux }
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var botonDisabled = true

        if (name === "estado" && value !== "0") {

            if (value !== "0") {
                formdataSeguimiento['accion'] = [
                    ...event.target.querySelectorAll("option"),
                ].filter((item) => item.value === value)[0].innerText;
                //formData['idComercializadora'] = value;

            }
            formdataSeguimiento['idAccion'] = value

            botonDisabled = false
            var valor = false
            if (value == '40' || value == '50') {

                valor = true
            }

            this.setState({ estadoDisabled: valor })
        }

        if (name === "observacion") {
            formdataSeguimiento[name] = value
        }

        this.setState({
            T_SeguimientoAux: formdataSeguimiento,
            saveButtonDisabledEstado: botonDisabled,
            estadoIdAux: formdataSeguimiento['idAccion']
        });

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {
        event.persist()

        let formData = { ...this.state.T_General };
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        if (name === "estado" && value !== "0") {

            if (value !== "0") {
                formData['estado'] = [
                    ...event.target.querySelectorAll("option"),
                ].filter((item) => item.value === value)[0].innerText;
                //formData['idComercializadora'] = value;
            }
            formData['estadoId'] = value
        }

        var saveProductButtonDisabled = false
        this.setState({
            T_General: formData,
            saveProductButtonDisabled: saveProductButtonDisabled
        });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldTitular = (event) => {
        event.persist()

        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        let formData = { ...this.state.T_Titular };

        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var CIFRepreError = false
        var saveProductButtonDisabled = false

        if (name == "NombreTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['NombreTitular'] = false
            textErroresData['NombreTitular'] = "SIN DATOS"
        } else if (name == "NombreTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['NombreTitular'] = true
            textErroresData['NombreTitular'] = ""
        };

        if (name == "Apellido1Titular" && (!value || value.length == 0 || value == "")) {
            errorsData['Apellido1Titular'] = false
            textErroresData['Apellido1Titular'] = "SIN DATOS"
        } else if (name == "Apellido1Titular" && (value || value.length > 0 || value !== "")) {
            errorsData['Apellido1Titular'] = true
            textErroresData['Apellido1Titular'] = ""
        };

        if (name == "TipoViaTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['TipoViaTitular'] = false
            textErroresData['TipoViaTitular'] = "SIN DATOS"
        } else if (name == "TipoViaTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['TipoViaTitular'] = true
            textErroresData['TipoViaTitular'] = ""
        };

        if (name == "ViaTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['ViaTitular'] = false
            textErroresData['ViaTitular'] = "SIN DATOS"
        } else if (name == "ViaTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['ViaTitular'] = true
            textErroresData['ViaTitular'] = ""
        };

        if (name == "NumFincaTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['NumFincaTitular'] = false
            textErroresData['NumFincaTitular'] = "SIN DATOS"
        } else if (name == "NumFincaTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['NumFincaTitular'] = true
            textErroresData['NumFincaTitular'] = ""
        };

        if (name == 'MunicipioTitular') {
            const valueArray = event.target.value.split("*");
            value = valueArray[1]
            let codigoMunicipio = valueArray[0]
            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
            const valorEntidad = resultado.Cmunicipio
            formData['ProvinciaTitular'] = resultado.DescProvincia
            formData['AutonomiaTitular'] = resultado.DescAutonomia
            this.fetchItemsMunicipio(valorEntidad);
            formData['CodigoPostalTitular'] = 'Selecciona CP'
        }

        if (name == "CodigoPostalTitular" && (!value || value.length == 0 || value == "" || value == 'Selecciona CP')) {
            errorsData['CodigoPostalTitular'] = false
            textErroresData['CodigoPostalTitular'] = "SIN DATOS"
        } else if (name == "CodigoPostalTitular" && (value || value.length > 0 || value !== "" || value != 'Selecciona CP')) {
            errorsData['CodigoPostalTitular'] = true
            textErroresData['CodigoPostalTitular'] = ""
        } else if (formData['CodigoPostalTitular'] == 'Selecciona CP') {
            errorsData['CodigoPostalTitular'] = false
            textErroresData['CodigoPostalTitular'] = "INTRODUZCA COD POSTAL VALIDO"

        }

        if (name == "AutonomiaTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['AutonomiaTitular'] = false
            textErroresData['AutonomiaTitular'] = "SIN DATOS"
        } else if (name == "AutonomiaTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['AutonomiaTitular'] = true
            textErroresData['AutonomiaTitular'] = ""
        };

        if (name == "ProvinciaTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['ProvinciaTitular'] = false
            textErroresData['ProvinciaTitular'] = "SIN DATOS"
        } else if (name == "ProvinciaTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['ProvinciaTitular'] = true
            textErroresData['ProvinciaTitular'] = ""
        };

        if (name == "MunicipioTitular" && (!value || value.length == 0 || value == "")) {
            errorsData['MunicipioTitular'] = false
            textErroresData['MunicipioTitular'] = "SIN DATOS"
        } else if (name == "MunicipioTitular" && (value || value.length > 0 || value !== "")) {
            errorsData['MunicipioTitular'] = true
            textErroresData['MunicipioTitular'] = ""
            errorsData['ProvinciaTitular'] = true
            textErroresData['ProvinciaTitular'] = ""
            errorsData['AutonomiaTitular'] = true
            textErroresData['AutonomiaTitular'] = ""

        };

        if (name == "Email" && (!value || value.length == 0 || value == "")) {
            errorsData['Email'] = false
            textErroresData['Email'] = "SIN DATOS"
        } else if (name == "Email" && (value || value.length > 0 || value !== "")) {
            errorsData['Email'] = true
            textErroresData['Email'] = ""
        };


        if (name == "NIF" && (!value || value.length == 0 || value == "")) {
            errorsData['NIF'] = false
            textErroresData['NIF'] = "SIN DATOS"
        } else if (name == "NIF" && (value || value.length > 0 || value !== "")) {
            errorsData['NIF'] = true
            textErroresData['NIF'] = ""
        };

        if (event.target.name === 'NIF') {
            const result = validateSpanishId(event.target.value)

            if (result === true) {

                const resultTipo = spainIdType(event.target.value)
                formData['TipoTitular'] = resultTipo.toUpperCase();
                errorsData['NIF'] = true
                textErroresData['NIF'] = ""

                CIFRepreError = false
                saveProductButtonDisabled = false
                if (resultTipo.toUpperCase() == "CIF") {

                    if (!this.state.T_Representante.nombreRepresentante || this.state.T_Representante.nombreRepresentante.length == 0 || this.state.T_Representante.nombreRepresentante == "") {
                        errorsData['nombreRepresentante'] = false
                        textErroresData['nombreRepresentante'] = "SIN DATOS"
                    } else if (this.state.T_Representante.nombreRepresentante || this.state.T_Representante.nombreRepresentante.length > 0 || this.state.T_Representante.nombreRepresentante != "") {
                        errorsData['nombreRepresentante'] = true
                        textErroresData['nombreRepresentante'] = ""
                    };

                    if (!this.state.T_Representante.apellido1Representante || this.state.T_Representante.apellido1Representante.length == 0 || this.state.T_Representante.apellido1Representante == "") {
                        errorsData['apellido1Representante'] = false
                        textErroresData['apellido1Representante'] = "SIN DATOS"
                    } else if (this.state.T_Representante.apellido1Representante || this.state.T_Representante.apellido1Representante.length > 0 || this.state.T_Representante.apellido1Representante != "") {
                        errorsData['apellido1Representante'] = true
                        textErroresData['apellido1Representante'] = ""
                    };

                    if (!this.state.T_Representante.NIFRepresentante || this.state.T_Representante.NIFRepresentante.length == 0 || this.state.T_Representante.NIFRepresentante == "") {
                        errorsData['NIFRepresentante'] = false
                        textErroresData['NIFRepresentante'] = "SIN DATOS"
                    } else if (this.state.T_Representante.NIFRepresentante || this.state.T_Representante.NIFRepresentante.length > 0 || this.state.T_Representante.NIFRepresentante != "") {
                        errorsData['NIFRepresentante'] = true
                        textErroresData['NIFRepresentante'] = ""
                    };
                    if (!this.state.T_Representante.CargoRepresentante || this.state.T_Representante.CargoRepresentante.length == 0 || this.state.T_Representante.CargoRepresentante == "") {
                        errorsData['CargoRepresentante'] = false
                        textErroresData['CargoRepresentante'] = "SIN DATOS"
                    } else if (this.state.T_Representante.CargoRepresentante || this.state.T_Representante.CargoRepresentante.length > 0 || this.state.T_Representante.CargoRepresentante != "") {
                        errorsData['CargoRepresentante'] = true
                        textErroresData['CargoRepresentante'] = ""
                    };

                    errorsData['Apellido1Titular'] = true
                    textErroresData['Apellido1Titular'] = ""


                } else {
                    //this.deleteRepresentantePost
                    errorsData['nombreRepresentante'] = true
                    textErroresData['nombreRepresentante'] = ""
                    errorsData['apellido1Representante'] = true
                    textErroresData['apellido1Representante'] = ""
                    errorsData['NIFRepresentante'] = true
                    textErroresData['NIFRepresentante'] = ""
                    errorsData['CargoRepresentante'] = true
                    textErroresData['CargoRepresentante'] = ""
                    if (!this.state.T_Titular.Apellido1Titular || this.state.T_Titular.Apellido1Titular.length == 0 || this.state.T_Titular.Apellido1Titular == "") {
                        errorsData['Apellido1Titular'] = false
                        textErroresData['Apellido1Titular'] = "SIN DATOS"
                    } else if (this.state.T_Titular.Apellido1Titular || this.state.T_Titular.Apellido1Titular.length > 0 || this.state.T_Titular.Apellido1Titular != "") {
                        errorsData['Apellido1Titular'] = true
                        textErroresData['Apellido1Titular'] = ""
                    };


                    this.setState({
                        T_Representante: {
                            nombreRepresentante: "",
                            apellido1Representante: "",
                            apellido2Representante: "",
                            NIFRepresentante: "",
                            TipoTitularRepresentante: "",
                            idRepresentante: "",
                            idRepresentanteInterno: 0,
                            CargoRepresentante: "",
                            telefonoRepresentante: "",
                            correoRepresentante: "",
                            codigoPostalRepresentante: "",
                            AutonomiaRepresentante: "",
                            MunicipioRepresentante: "",
                            ProvinciaRepresentante: "",
                            escaleraRepresentante: "",
                            numFincaRepresentante: "",
                            pisoRepresentante: "",
                            portalRepresentante: "",
                            puertaRepresentante: "",
                            tipoViaRepresentante: "",
                            viaRepresentante: ""
                        },
                        saveProductButtonDisabled: false
                    });
                }


            } else {

                errorsData['NIF'] = false
                textErroresData['NIF'] = "ID ERRONEO"

                CIFRepreError = true
                saveProductButtonDisabled = true

            }
        } else {
            saveProductButtonDisabled = false
        };

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({
            errores: errorsData,
            textErrores: textErroresData,
            T_Titular: formData,
            CIFRepreError: CIFRepreError,
            saveProductButtonDisabled: saveProductButtonDisabled
        });

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldRepre = (event) => {
        event.persist()
        let formData = { ...this.state.T_Representante };
        let target = event.target;
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;


        if (event.target.name == 'nombreRepresentante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name === 'CIFRepresentante') {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    CIFRepreError: false,
                    saveProductButtonDisabled: false
                })
            } else {
                this.setState({
                    CIFRepreError: true,
                    saveProductButtonDisabled: true
                })
            }
        }

        if (event.target.name == 'apellido1Representante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name == 'apellido2Representante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }

        if (event.target.name == 'correoRepresentante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }

        if (event.target.name == 'telefonoRepresentante') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }
        if (event.target.name == 'CargoRepre') {
            this.setState({ saveDataRepre: true });
            this.setState({ saveProductButtonDisabled: false })
        }


        formData[name] = value;
        this.setState({ formRepre: formData });

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldPuntoSuministro = (event) => {
        event.persist()
        let formData = { ...this.state.T_PuntoSuministro };
        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var saveProductButtonDisabled = false


        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }


        if (name == "TipoViaPS" && (!value || value.length == 0 || value == "")) {
            errorsData['TipoViaPS'] = false
            textErroresData['TipoViaPS'] = "SIN DATOS"
        } else if (name == "TipoViaPS" && (value || value.length > 0 || value !== "")) {
            errorsData['TipoViaPS'] = true
            textErroresData['TipoViaPS'] = ""
        };

        if (name == "ViaPS" && (!value || value.length == 0 || value == "")) {
            errorsData['ViaPS'] = false
            textErroresData['ViaPS'] = "SIN DATOS"
        } else if (name == "ViaPS" && (value || value.length > 0 || value !== "")) {
            errorsData['ViaPS'] = true
            textErroresData['ViaPS'] = ""
        };

        if (name == "NumFincaPS" && (!value || value.length == 0 || value == "")) {
            errorsData['NumFincaPS'] = false
            textErroresData['NumFincaPS'] = "SIN DATOS"
        } else if (name == "NumFincaPS" && (value || value.length > 0 || value !== "")) {
            errorsData['NumFincaPS'] = true
            textErroresData['NumFincaPS'] = ""
        };


        if (name == 'NombreMunicipioPS') {
            const valueArray = event.target.value.split("*");
            value = valueArray[1]
            let codigoMunicipio = valueArray[0]
            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
            const valorEntidad = resultado.Cmunicipio
            formData['NombreProvinciaPS'] = resultado.DescProvincia
            formData['AutonomiaPS'] = resultado.DescAutonomia
            this.fetchItemsMunicipio(valorEntidad);
            formData['CodigoPostalPS'] = 'Selecciona CP'
        }

        if (name == "CodigoPostalPS" && (!value || value.length == 0 || value == "" || value == 'Selecciona CP')) {
            errorsData['CodigoPostalPS'] = false
            textErroresData['CodigoPostalPS'] = "SIN DATOS"
        } else if (name == "CodigoPostalPS" && (value || value.length > 0 || value !== "" || value != 'Selecciona CP')) {
            errorsData['CodigoPostalPS'] = true
            textErroresData['CodigoPostalPS'] = ""
        } else if (formData['CodigoPostalPS'] == 'Selecciona CP') {
            errorsData['CodigoPostalPS'] = false
            textErroresData['CodigoPostalPS'] = "INTRODUZCA COD POSTAL VALIDO"
        }

        if (name == "NombreMunicipioPS" && (!value || value.length == 0 || value == "")) {
            errorsData['NombreMunicipioPS'] = false
            textErroresData['NombreMunicipioPS'] = "SIN DATOS"
        } else if (name == "NombreMunicipioPS" && (value || value.length > 0 || value !== "")) {
            errorsData['NombreMunicipioPS'] = true
            textErroresData['NombreMunicipioPS'] = ""
            errorsData['NombreProvinciaPS'] = true
            textErroresData['NombreProvinciaPS'] = ""
            errorsData['AutonomiaPS'] = true
            textErroresData['AutonomiaPS'] = ""
        };

        if (name == "NombreProvinciaPS" && (!value || value.length == 0 || value == "")) {
            errorsData['NombreProvinciaPS'] = false
            textErroresData['NombreProvinciaPS'] = "SIN DATOS"
        } else if (name == "NombreProvinciaPS" && (value || value.length > 0 || value !== "")) {
            errorsData['NombreProvinciaPS'] = true
            textErroresData['NombreProvinciaPS'] = ""
        };

        if (name == "AutonomiaPS" && (!value || value.length == 0 || value == "")) {
            errorsData['AutonomiaPS'] = false
            textErroresData['AutonomiaPS'] = "SIN DATOS"
        } else if (name == "AutonomiaPS" && (value || value.length > 0 || value !== "")) {
            errorsData['AutonomiaPS'] = true
            textErroresData['AutonomiaPS'] = ""
        };

        formData[name] = value;
        this.setState({
            errores: errorsData,
            textErrores: textErroresData,
            T_PuntoSuministro: formData,
            saveProductButtonDisabled: saveProductButtonDisabled
        });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldCUPs = (event) => {
        event.persist()
        let formData = { ...this.state.T_CUPs };
    }

    onChangeFieldPropuestaComercial = (event) => {
        event.persist()
        let formData = { ...this.state.T_PropuestaComercial };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////


    handleChangeDateFilter = (value, formattedValue, id) => {

        let formData = { ...this.state.T_General };
        let formDataSeguimiento = { ...this.state.T_SeguimientoAux }
        let target = id;


        const name = id;
        var valueDay = formattedValue
    };

    //////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {

        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        this.setState({ selectedRows: state.selectedRows });

        let propuestaSeleccionada: any = state.selectedRows[0]

        console.log('valor de propuestaSeleccionada en handleChange: ', propuestaSeleccionada)

        // dataComisionCo: [{}],
        // dataComision: [{}],


        if (state.selectedRows.length > 0) {

            var idProducto = propuestaSeleccionada.id

            let comision: any = this.state.dataComisionCo.find((comision: any) => (comision.id == idProducto));

            console.log('VALOR DE COMISION EN HANDLECHANGE: ', comision)

            if (!comision) {

                comision = this.state.dataComision.find((comision: any) => (comision.id == idProducto));

            }
            console.log('VALOR DE COMISION EN HANDLECHANGE2: ', comision)

            if (!comision) {
                comision = {}
            }

            var propuestaComercial: any = {
                PorcentajeAhorro: propuestaSeleccionada.ahorro.ahorroPercent,
                ImporteAhorro: propuestaSeleccionada.ahorro.ahorroAnual,
                Estado: "P",
                Comercializadora: propuestaSeleccionada.nombreComercializadora,
                Producto: propuestaSeleccionada.nombreProducto,
                TarifaPeaje: propuestaSeleccionada.codigoATR,
                Tarifa: propuestaSeleccionada.peajeATR,
                IdProducto: propuestaSeleccionada.id,
                Anexo: "",
                TipoPrecio: propuestaSeleccionada.TipoPrecio,
                ObservacionesProducto: "",
                GastoOperativo: this.state.T_PropuestaComercial.GastoOperativo,
                GastoOperativoPotencia: this.state.T_PropuestaComercial.GastoOperativoPotencia,
                idServicio: propuestaSeleccionada.idServicio,
                idComercializadora: propuestaSeleccionada.idComercializadora,
                nombreServicio: propuestaSeleccionada.nombreServicio,
                precioServicio: propuestaSeleccionada.serviciosAdicionalesMonth,
                ComercializadoraProcedencia: this.state.T_PropuestaComercial.ComercializadoraProcedencia,
                idComercializadoraProcedencia: this.state.T_PropuestaComercial.idComercializadoraProcedencia,
                comisionContratoFija: propuestaSeleccionada.comisionContratoFija,
                comisionContratoConsumoAgente1: propuestaSeleccionada.comisionContratoConsumoAgente1,
                comisionContratoConsumoAgente2: propuestaSeleccionada.comisionContratoConsumoAgente2,
                comisionContratoConsumoAdmin: propuestaSeleccionada.comisionContratoConsumoAdmin,
                esNuevoContrato: this.state.T_PropuestaComercial.esNuevoContrato,
                propuestaGlobal: this.state.T_PropuestaComercial.propuestaGlobal,
                comision: comision
            }

            var propuestaDataAux = {
                precioPotenciaP1: propuestaSeleccionada.precioPotenciaP1 ? propuestaSeleccionada.precioPotenciaP1 : 0,
                precioPotenciaP2: propuestaSeleccionada.precioPotenciaP2 ? propuestaSeleccionada.precioPotenciaP2 : 0,
                precioPotenciaP3: propuestaSeleccionada.precioPotenciaP3 ? propuestaSeleccionada.precioPotenciaP3 : 0,
                precioPotenciaP4: propuestaSeleccionada.precioPotenciaP4 ? propuestaSeleccionada.precioPotenciaP4 : 0,
                precioPotenciaP5: propuestaSeleccionada.precioPotenciaP5 ? propuestaSeleccionada.precioPotenciaP5 : 0,
                precioPotenciaP6: propuestaSeleccionada.precioPotenciaP6 ? propuestaSeleccionada.precioPotenciaP6 : 0,
                precioEnergiaP1: propuestaSeleccionada.precioEnergiaP1 ? propuestaSeleccionada.precioEnergiaP1 : 0,
                precioEnergiaP2: propuestaSeleccionada.precioEnergiaP2 ? propuestaSeleccionada.precioEnergiaP2 : 0,
                precioEnergiaP3: propuestaSeleccionada.precioEnergiaP3 ? propuestaSeleccionada.precioEnergiaP3 : 0,
                precioEnergiaP4: propuestaSeleccionada.precioEnergiaP4 ? propuestaSeleccionada.precioEnergiaP4 : 0,
                precioEnergiaP5: propuestaSeleccionada.precioEnergiaP5 ? propuestaSeleccionada.precioEnergiaP5 : 0,
                precioEnergiaP6: propuestaSeleccionada.precioEnergiaP6 ? propuestaSeleccionada.precioEnergiaP6 : 0
            }

            if (this.state.T_General.OfertaTipo == 'MP') {
                propuestaDataAux = {
                    precioPotenciaP1: propuestaSeleccionada.precioPotenciaP1 ? propuestaSeleccionada.precioPotenciaP1 : 0,
                    precioPotenciaP2: propuestaSeleccionada.precioPotenciaP2 ? propuestaSeleccionada.precioPotenciaP2 : 0,
                    precioPotenciaP3: propuestaSeleccionada.precioPotenciaP3 ? propuestaSeleccionada.precioPotenciaP3 : 0,
                    precioPotenciaP4: propuestaSeleccionada.precioPotenciaP4 ? propuestaSeleccionada.precioPotenciaP4 : 0,
                    precioPotenciaP5: propuestaSeleccionada.precioPotenciaP5 ? propuestaSeleccionada.precioPotenciaP5 : 0,
                    precioPotenciaP6: propuestaSeleccionada.precioPotenciaP6 ? propuestaSeleccionada.precioPotenciaP6 : 0,
                    precioEnergiaP1: propuestaSeleccionada.precioEnergiaP1 ? propuestaSeleccionada.precioEnergiaP1 : 0,
                    precioEnergiaP2: propuestaSeleccionada.precioEnergiaP2 ? propuestaSeleccionada.precioEnergiaP2 : 0,
                    precioEnergiaP3: propuestaSeleccionada.precioEnergiaP3 ? propuestaSeleccionada.precioEnergiaP3 : 0,
                    precioEnergiaP4: propuestaSeleccionada.precioEnergiaP4 ? propuestaSeleccionada.precioEnergiaP4 : 0,
                    precioEnergiaP5: propuestaSeleccionada.precioEnergiaP5 ? propuestaSeleccionada.precioEnergiaP5 : 0,
                    precioEnergiaP6: propuestaSeleccionada.precioEnergiaP6 ? propuestaSeleccionada.precioEnergiaP6 : 0
                }
            }

            var propuestaComercialSeleccionada: any = {
                producto: {
                    datos: propuestaSeleccionada,
                    producto: propuestaSeleccionada,
                    itemsPeriodos: this.state.T_Propuesta.producto.itemsPeriodos ? this.state.T_Propuesta.producto.itemsPeriodos : {},
                    periodos: this.state.T_Propuesta.producto.periodos,
                    costeOperativo: this.state.T_Propuesta.producto.costeOperativo,
                    costeOperativoPotencia: this.state.T_Propuesta.producto.costeOperativoPotencia,
                    esNuevoContrato: this.state.T_Propuesta.producto.esNuevoContrato,
                    totalComparacion: this.state.T_Propuesta.producto.totalComparacion,
                    comision: comision
                },
                actual: this.state.T_Propuesta.actual
            };

            errorsData['Producto'] = true
            textErroresData['Producto'] = ""

            this.setState({
                T_PropuestaComercial: propuestaComercial,
                T_Propuesta: propuestaComercialSeleccionada,
                propuestaData: propuestaDataAux,
                propuestaComision: comision,
                saveProductButtonDisabled: false,
                errores: errorsData,
                textErrores: textErroresData,
            })
        } else {

            var propuestaDataAuxDelete = {
                precioPotenciaP1: 0,
                precioPotenciaP2:  0,
                precioPotenciaP3:  0,
                precioPotenciaP4: 0,
                precioPotenciaP5: 0,
                precioPotenciaP6: 0,
                precioEnergiaP1:  0,
                precioEnergiaP2:  0,
                precioEnergiaP3:  0,
                precioEnergiaP4: 0,
                precioEnergiaP5:  0,
                precioEnergiaP6: 0
            }


                var comision = {}

                errorsData['Producto'] = false
                textErroresData['Producto'] = "PRODUCTO NO SELECCIONADO"


            this.setState({

                propuestaData: propuestaDataAuxDelete,
                propuestaComision: comision,
                saveProductButtonDisabled: false

            })

        }
        /*else {


            {
                PorcentajeAhorro: "",
                ImporteAhorro: "",
                Estado: "",
                Comercializadora: "",
                Producto: "",
                TarifaPeaje: "",
                Tarifa: "",
                IdProducto: "",
                Anexo: "",
                TipoPrecio: "",
                ObservacionesProducto: "",
                GastoOperativo: "",
                GastoOperativoPotencia: "",
                idServicio: "",
                idComercializadora: "",
                nombreServicio: "",
                precioServicio: "",
                ComercializadoraProcedencia: "",
                idComercializadoraProcedencia: "",
                esNuevoContrato:"",
                propuestaGlobal:[{}]
            },

            this.setState({ selectProductButtonDisabled: true })
        }*/
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    /////////////////////////////////////////////////////////////////////////////

    export = () => { };

    tipoLectura = (props) => {
        const tipoContrato = props.tipoContrato;
        const cupsId = this.state.contratoDetail.idContrato
        var { path, url } = this.props.match;
        //{url} = "/admin/sip-contratos/"
        // this.props.history.push('/admin/sip-contratos/');

        if (tipoContrato === 'GAS') {
            return (

                <React.Fragment>
                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="gpsProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosGasSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO GAS
                                </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    HISTORICO ESTADOS DE SEGUIMIENTO DE LA PROPUESTA
                                </UncontrolledTooltip>
                            </Button>

                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="elecProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="elecProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO ELECTRICO
                                </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    Muestra el histórico de estados de la propuesta
                                </UncontrolledTooltip>
                            </Button>


                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoCup = (props) => {
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        if (tipoCup === 'ELECTRICIDAD') {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEle">
                                    CUPS ELECTRICIDAD
                                </Label>
                                <Input
                                    id="CUPsEle"
                                    placeholder=""
                                    name="CUPsEle"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>


                        <Col sm="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="P1">
                                    P1
                                </Label>
                                <Input
                                    id="P1"
                                    placeholder=""
                                    name="P1"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P1}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P2">
                                    P2
                                </Label>
                                <Input
                                    id="P2"
                                    placeholder=""
                                    name="P2"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P2}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P3">
                                    P3
                                </Label>
                                <Input
                                    id="P3"
                                    placeholder=""
                                    name="P3"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P3}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P4">
                                    P4
                                </Label>
                                <Input
                                    id="P4"
                                    placeholder=""
                                    name="P4"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P4}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P5">
                                    P5
                                </Label>
                                <Input
                                    id="P5"
                                    placeholder=""
                                    name="P5"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P5}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P6">
                                    P6
                                </Label>
                                <Input
                                    id="P6"
                                    placeholder=""
                                    name="P6"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.P6}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo Anual
                                </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeFieldCUPs}
                                    value={form_T_CUPs.Consumo}
                                    type="text"

                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>);
        } else {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEl">
                                    CUPS GAS
                                </Label>
                                <Input
                                    id="CUPsEl"
                                    placeholder=""
                                    name="CUPsEl"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>


                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo
                                </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Consumo}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>)
        }
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoPrecioTarifa = (props) => {
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_Propuesta: any = this.state.T_Propuesta;
        if (tipoCup === 'ELECTRICIDAD') {
            if (form_T_PropuestaComercial.TipoPrecio == "1") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Precio E1: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP1 ? this.state.propuestaData.precioEnergiaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP2 ? this.state.propuestaData.precioEnergiaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP3 ? this.state.propuestaData.precioEnergiaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP4 ? this.state.propuestaData.precioEnergiaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP5 ? this.state.propuestaData.precioEnergiaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP6 ? this.state.propuestaData.precioEnergiaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Precio P1: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP1 ? this.state.propuestaData.precioPotenciaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP2 ? this.state.propuestaData.precioPotenciaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP3 ? this.state.propuestaData.precioPotenciaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP4 ? this.state.propuestaData.precioPotenciaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP5 ? this.state.propuestaData.precioPotenciaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP6 ? this.state.propuestaData.precioPotenciaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>)
            } else {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Precio E1: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP1 ? this.state.propuestaData.precioEnergiaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP2 ? this.state.propuestaData.precioEnergiaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP3 ? this.state.propuestaData.precioEnergiaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP4 ? this.state.propuestaData.precioEnergiaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP5 ? this.state.propuestaData.precioEnergiaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP6 ? this.state.propuestaData.precioEnergiaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Precio P1: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP1 ? this.state.propuestaData.precioPotenciaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP2 ? this.state.propuestaData.precioPotenciaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP3 ? this.state.propuestaData.precioPotenciaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP4 ? this.state.propuestaData.precioPotenciaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP5 ? this.state.propuestaData.precioPotenciaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP6 ? this.state.propuestaData.precioPotenciaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>

                            </Col>

                        </Row>
                    </div>)

            };
        } else {
            if (form_T_PropuestaComercial.TipoPrecio == "1") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Modificador Termino Variable Consumo Coeficiente K: </strong><br></br>{form_T_Propuesta.producto.datos.factork || 0}</Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>)
            } else {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row className="mt-2">
                                    <Col><strong>Precio E1: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP1 ? this.state.propuestaData.precioEnergiaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP2 ? this.state.propuestaData.precioEnergiaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP3 ? this.state.propuestaData.precioEnergiaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP4 ? this.state.propuestaData.precioEnergiaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP5 ? this.state.propuestaData.precioEnergiaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{Number(this.state.propuestaData.precioEnergiaP6 ? this.state.propuestaData.precioEnergiaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Precio P1: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP1 ? this.state.propuestaData.precioPotenciaP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP2 ? this.state.propuestaData.precioPotenciaP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP3 ? this.state.propuestaData.precioPotenciaP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP4 ? this.state.propuestaData.precioPotenciaP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP5 ? this.state.propuestaData.precioPotenciaP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{Number(this.state.propuestaData.precioPotenciaP6 ? this.state.propuestaData.precioPotenciaP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 6, useGrouping: true })} €</Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>)

            };
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoComisionTarifa = (props) => {

        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_Propuesta: any = this.state.T_Propuesta;
        if (tipoCup === 'ELECTRICIDAD') {
            if (this.state.propuestaComision.idTipoComision == "E" || this.state.propuestaComision.idTipoComision == "F" || this.state.propuestaComision.idTipoComision == "G") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen comisión producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Comision E1: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP1 ? this.state.propuestaComision.comisionAgenteTEP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision E2: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP2 ? this.state.propuestaComision.comisionAgenteTEP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision E3: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP3 ? this.state.propuestaComision.comisionAgenteTEP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision E4: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP4 ? this.state.propuestaComision.comisionAgenteTEP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision E5: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP5 ? this.state.propuestaComision.comisionAgenteTEP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision E6: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP6 ? this.state.propuestaComision.comisionAgenteTEP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Comision P1: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP1 ? this.state.propuestaComision.comisionAgenteTPP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision P2: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP2 ? this.state.propuestaComision.comisionAgenteTPP2 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision P3: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP3 ? this.state.propuestaComision.comisionAgenteTPP3 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision P4: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP4 ? this.state.propuestaComision.comisionAgenteTPP4 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision P5: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP5 ? this.state.propuestaComision.comisionAgenteTPP5 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                    <Col><strong>Comision P6: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTPP6 ? this.state.propuestaComision.comisionAgenteTPP6 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>)
            } else if (this.state.propuestaComision.idTipoComision == "A" || this.state.propuestaComision.idTipoComision == "B" || this.state.propuestaComision.idTipoComision == "C" || this.state.propuestaComision.idTipoComision == "D") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Comision E1: </strong><br></br>{Number(this.state.propuestaComision.comisionAgenteTEP1 ? this.state.propuestaComision.comisionAgenteTEP1 : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €</Col>

                                </Row>
                            </Col>

                        </Row>
                    </div>)

            } else {

                return null;

            };
        } else {
            if (form_T_PropuestaComercial.TipoPrecio == "1") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Modificador Termino Variable Consumo Coeficiente K: </strong><br></br>{form_T_Propuesta.producto.datos.factork || 0}</Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>)
            } else {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Precio P1: </strong><br></br>{this.state.propuestaData.precioPotenciaP1 || 0}</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{this.state.propuestaData.precioPotenciaP2 || 0}</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{this.state.propuestaData.precioPotenciaP3 || 0}</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{this.state.propuestaData.precioPotenciaP4 || 0}</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{this.state.propuestaData.precioPotenciaP5 || 0}</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{this.state.propuestaData.precioPotenciaP6 || 0}</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Precio E1: </strong><br></br>{this.state.propuestaData.precioEnergiaP1 || 0}</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{this.state.propuestaData.precioEnergiaP2 || 0}</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{this.state.propuestaData.precioEnergiaP3 || 0}</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{this.state.propuestaData.precioEnergiaP4 || 0}</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{this.state.propuestaData.precioEnergiaP5 || 0}</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{this.state.propuestaData.precioEnergiaP6 || 0}</Col>
                                </Row>

                            </Col>

                        </Row>
                    </div>)

            };
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    propuestasPresentadas = (props) => {
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_Propuesta: any = this.state.T_Propuesta;
        const propuestasGeneral = this.state.T_PropuestasAnalizadas;
        const estado: string = this.state.T_General.estadoId ? this.state.T_General.estadoId! : "00";

        if (tipoCup === 'ELECTRICIDAD') {
            if (estado != "50") {

                if (form_T_PropuestaComercial.TipoPrecio == "1") {
                    return (
                        <React.Fragment>
                            <Row id="contratos_resumen" className="card-deck mt-2">
                                <Card className="shadow">
                                    <DataTable
                                        title="PRODUCTOS DE LA PROPUESTA"
                                        columns={columnsDatos}
                                        data={propuestasGeneral}
                                        selectableRows
                                        selectableRowsComponent={Checkbox} // Pass the function only
                                        selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        selectableRowsSingle
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                        onSelectedRowsChange={this.handleChange}
                                        clearSelectedRows={this.state.toggledClearRows}
                                        highlightOnHover
                                        striped
                                        pointerOnHover
                                        selectableRowsHighlight
                                        customStyles={customStyles}
                                        contextMessage={{ singular: 'Propuesta', plural: 'Propuestas', message: 'seleccionadas' }}
                                        subHeader
                                        progressPending={this.state.setPending}
                                        progressComponent={<Circular />}
                                    />
                                </Card>
                            </Row>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <Row id="contratos_resumen" className="card-deck mt-2">
                                <Card className="shadow">
                                    <DataTable
                                        title="PROPUESTAS PRESENTADAS AL CLIENTE"
                                        columns={columnsDatos}
                                        data={propuestasGeneral}
                                        selectableRows
                                        selectableRowsComponent={Checkbox} // Pass the function only
                                        selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        selectableRowsSingle
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                        onSelectedRowsChange={this.handleChange}
                                        clearSelectedRows={this.state.toggledClearRows}
                                        highlightOnHover
                                        striped
                                        pointerOnHover
                                        selectableRowsHighlight
                                        customStyles={customStyles}
                                        contextMessage={{ singular: 'Propuesta', plural: 'Propuestas', message: 'seleccionada' }}
                                        subHeader
                                        progressPending={this.state.setPending}
                                        progressComponent={<Circular />}
                                    />
                                </Card>
                            </Row>
                        </React.Fragment>
                    )
                };
            } else {
                return <></>;
            }
        } else {
            if (estado != "50") {
                if (form_T_PropuestaComercial.TipoPrecio == "1") {
                    return (
                        <React.Fragment>
                            <div>
                                <Row>
                                    <Col>
                                        <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                        <Row>
                                            <Col><strong>Modificador Termino Variable Consumo Coeficiente K: </strong><br></br>{form_T_Propuesta.producto.datos.factork || 0}</Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    return <></>;
                }
            } else {
                return <></>;
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });
            const reftp = document.getElementById("tablaLecturas")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {
            this.setState({
                show: false
            });
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const id = state.target.id;
        const file = state.target.value
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/download?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato + "&file=" + file;

        axios.get(direccionFileList,
            {
                headers,
                responseType: 'blob'
            }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch(function () {
                console.log('Failure')
            });
    };

    ////////////////////////////////////////////////////////////////////////////////

    saveHandler(props) {
        this.confirmAlertSave();
    };

    ////////////////////////////////////////////////////////////////////////////////

    aprobacionHandler(props) {

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.errorAlertAprobacion();
        } else {
            if (this.state.T_PropuestaComercial.Producto == "NO SELECCIONADO" || this.state.T_PropuestaComercial.Producto == "" || this.state.T_PropuestaComercial.IdProducto) {

                this.errorAlertAprobacion2();

            } else {

                this.confirmAlertSave2();
            }
        };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    contratoHandler(props) {
        this.onPdf()
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    onEstado = async () => {

        let formData = { ...this.state.T_General };

        const now = new Date().toISOString();

        var fechaEstado = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaEstado, "YYYY-MM-DD"));
        var monthEstado = Number(check.format('M'));
        var yearEstado = check.year();

        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

        var partes = currentDate.slice(0).split('T');


        var date = partes[0]
        var hour = partes[1]

        if (this.state.T_SeguimientoAux.idAccion == '50') {
            formData['fechaAprobacion'] = date
            formData['horaAprobacion'] = hour
            formData['mesAprobacion'] = monthEstado
            formData['anioAprobacion'] = yearEstado
            formData['esAprobada'] = true
            formData['estado'] = 'APROBADA'
            formData['estadoId'] = '50'
        }

        if (this.state.T_SeguimientoAux.idAccion == '40' && this.state.T_SeguimientoAux.idAccion) {
            formData['fechaDenegacion'] = date
            formData['horaDenegacion'] = hour
            formData['mesDenegacion'] = monthEstado
            formData['anioDenegacion'] = yearEstado
            formData['esDenegada'] = true
            formData['estado'] = 'DENEGADA'
            formData['estadoId'] = '40'
        } else if (this.state.T_SeguimientoAux.idAccion == '40' && !this.state.T_SeguimientoAux.idAccion) {
            formData['fechaAprobacion'] = date
            formData['horaAprobacion'] = hour
            formData['mesAprobacion'] = monthEstado
            formData['anioAprobacion'] = yearEstado
            formData['esAprobada'] = false
            formData['fechaDenegacion'] = date
            formData['horaDenegacion'] = hour
            formData['mesDenegacion'] = monthEstado
            formData['anioDenegacion'] = yearEstado
            formData['esDenegada'] = true
            formData['estado'] = 'DENEGADA'
            formData['estadoId'] = '40'
        }

        var estado = {
            "usuario": this.state.userId,
            "nombreUsuario": this.state.userName,
            "compania": this.state.companyIdUser,
            "fecha": date,
            "hora": hour,
            "mes": monthEstado,
            "anio": yearEstado,
            "accion": this.state.T_SeguimientoAux.accion,
            "idAccion": this.state.T_SeguimientoAux.idAccion,
            "observacion": this.state.T_SeguimientoAux.observacion
        }

        this.setState({
            T_General: formData,
            T_Seguimiento: [...this.state.T_Seguimiento, estado],
            saveProductButtonDisabled: false,
            saveButtonDisabledEstado: true
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    onSave = async () => {

        const companyIdAux = this.state.companyIdUser.toString();
        const userIdAux = this.state.userId.toString();
        //const idContrato = this.state.contrato;

        var datosContratoP = {
            ...this.state.T_General,
            T_Titular: this.state.T_Titular,
            T_Representante: this.state.T_Representante,
            T_PuntoSuministro: this.state.T_PuntoSuministro,
            T_CUPs: this.state.T_CUPs,
            T_PropuestaComercial: this.state.T_PropuestaComercial,
            T_DatosAgenteComercial: this.state.T_DatosAgenteComercial,
            contratoDataGeneralElectricidad: this.state.contratoDataGeneralElectricidad,
            contratoDataGeneralGas: this.state.contratoDataGeneralGas,
            T_Seguimiento: this.state.T_Seguimiento,
            T_Propuesta: this.state.T_Propuesta,
            T_PropuestasAnalizadas: this.state.T_PropuestasAnalizadas,
            T_Consumos: this.state.T_Consumos,
            T_Datos_Comision: this.state.T_Datos_Comision,
            T_Transferencia: this.state.T_Transferencia
        };

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/update?id=" + companyIdAux + "&user=" + userIdAux;

        axios.post(direccionFile, datosContratoP, { headers, responseType: 'json' })
            .then(res => {
                this.setState({
                    saveProductButtonDisabled: true,
                    saveButtonDisabledEstado: false
                })
                this.successAlert()
            }).catch(error => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
            });

        this.fetchItems();

    };

    //////////////////////////////////////////////////FUNCION PDF //////////////////////////////////////

    onAprobacion = async () => {

        const now = new Date().toISOString();
        var fechaAprobacion = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaAprobacion, "YYYY-MM-DD"));
        var monthAprobacion = Number(check.format('M'));
        var yearAprobacion = check.year();

        var currentDateAprobacion = moment().format("YYYY-MM-DDTHH:mm:ss");
        //var local_date_aprobacion= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');
        var partesAprobacion = currentDateAprobacion.slice(0).split('T');

        //const now = new Date().toISOString();

        var dateAprobacion = partesAprobacion[0]
        var hourAprobacion = partesAprobacion[1]

        const companyIdAux = this.state.companyIdUser.toString();
        const userIdAux = this.state.userId.toString();
        //const idContrato = this.state.contrato;

        var datosContratoP = {
            ...this.state.T_General,
            T_Titular: this.state.T_Titular,
            T_Representante: this.state.T_Representante,
            T_PuntoSuministro: this.state.T_PuntoSuministro,
            T_CUPs: this.state.T_CUPs,
            T_PropuestaComercial: this.state.T_PropuestaComercial,
            T_DatosAgenteComercial: this.state.T_DatosAgenteComercial,
            contratoDataGeneralElectricidad: this.state.contratoDataGeneralElectricidad,
            contratoDataGeneralGas: this.state.contratoDataGeneralGas,
            T_Seguimiento: this.state.T_Seguimiento,
            T_Propuesta: this.state.T_Propuesta,
            T_PropuestasAnalizadas: this.state.T_PropuestasAnalizadas,
            T_Consumos: this.state.T_Consumos,
            T_Datos_Comision: this.state.T_Datos_Comision,
            T_Transferencia: this.state.T_Transferencia
        };

        datosContratoP.fechaAprobacion = dateAprobacion
        datosContratoP.horaAprobacion = hourAprobacion
        datosContratoP.mesAprobacion = monthAprobacion
        datosContratoP.anioAprobacion = yearAprobacion
        datosContratoP.esAprobada = true
        datosContratoP.estado = 'APROBADA'
        datosContratoP.estadoId = '50'

        var estado = {

            "usuario": this.state.userId,
            "nombreUsuario": this.state.userName,
            "compania": this.state.companyIdUser,
            "fecha": dateAprobacion,
            "hora": hourAprobacion,
            "mes": monthAprobacion,
            "anio": yearAprobacion,
            "accion": 'APROBADA',
            "idAccion": '50',
            "observacion": ''
        }

        datosContratoP.T_Seguimiento.push(estado)

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/createContrato?id=" + companyIdAux + "&user=" + userIdAux;

        axios.post(direccionFile, datosContratoP, { headers, responseType: 'json' })
            .then(res => {
                this.setState({
                    saveProductButtonDisabled: true,
                    saveButtonDisabledEstado: false,
                    aprobarButtonDisabled: true
                })
                this.successAlert()
            }).catch(error => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
            });

        this.fetchItems();

    };

    //////////////////////////////////////////////////FUNCION PDF //////////////////////////////////////

    onPdf = async () => {

        // const tipo = this.state.T_General.tipoEnergia;
        // const id = this.state.T_General.IdOferta
        // const compania = this.state.companyIdUser

        const tipo = this.state.T_General.tipoEnergia;
        const id = this.state.T_General.IdOferta + "_" + this.state.companyIdUser
        const compania = this.state.companyIdUser

        if (tipo == 'ELECTRICIDAD') {
            const win = window.open("/print/sip-propuesta-print/" + id, "_blank");
            if (win != null) {
                win.focus();
            }
        } else {
            const win = window.open("/print/sipgas-comparative-print/" + id, "_blank");
            if (win != null) {
                win.focus();
            }

        }




    };


    ////////////////////// Función para subir Ficheros /////////////////////////

    onSubmit(e) {
        e.preventDefault()

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }


        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/uploadDocS3?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;

        let direccionFileb = "http://78.47.44.246:4000/api/upload-images";
        //let direccionFileList = "https://datapi.psgestion.es/files?id="+this.state.companyIdUser;
        let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;

        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            this.setState({
                filesConvert: result.data.Contents,
                filesContrato: result.data.Contents
            })
            this.setState({ imgCollection: [] })
            this.setState({ saveButtonDisabled: true })
            this.inputEl.current.value = ''

        })

    };


    //////////////////////////////////

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        let tamañoFiles = e.target.files.length;

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })
        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }
    };

    ///////////////////////////////////////////////

    representanteSeleccionado = async (event) => {

        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        if (!event.representante.nombreRepresentante || event.representante.nombreRepresentante.length == 0 || event.representante.nombreRepresentante == "") {
            errorsData['nombreRepresentante'] = false
            textErroresData['nombreRepresentante'] = "SIN DATOS"
        } else if (event.representante.nombreRepresentante || event.representante.nombreRepresentante.length > 0 || event.representante.nombreRepresentante != "") {
            errorsData['nombreRepresentante'] = true
            textErroresData['nombreRepresentante'] = ""
        };

        if (!event.representante.apellido1Representante || event.representante.apellido1Representante.length == 0 || event.representante.apellido1Representante == "") {
            errorsData['apellido1Representante'] = false
            textErroresData['apellido1Representante'] = "SIN DATOS"
        } else if (event.representante.apellido1Representante || event.representante.apellido1Representante.length > 0 || event.representante.apellido1Representante != "") {
            errorsData['apellido1Representante'] = true
            textErroresData['apellido1Representante'] = ""
        };

        if (!event.representante.NIFRepresentante || event.representante.NIFRepresentante.length == 0 || event.representante.NIFRepresentante == "") {
            errorsData['NIFRepresentante'] = false
            textErroresData['NIFRepresentante'] = "SIN DATOS"
        } else if (event.representante.NIFRepresentante || event.representante.NIFRepresentante.length > 0 || event.representante.NIFRepresentante != "") {
            errorsData['NIFRepresentante'] = true
            textErroresData['NIFRepresentante'] = ""
        };
        if (!event.representante.CargoRepresentante || event.representante.CargoRepresentante.length == 0 || event.representante.CargoRepresentante == "") {
            errorsData['CargoRepresentante'] = false
            textErroresData['CargoRepresentante'] = "SIN DATOS"
        } else if (event.representante.CargoRepresentante || event.representante.CargoRepresentante.length > 0 || event.representante.CargoRepresentante != "") {
            errorsData['CargoRepresentante'] = true
            textErroresData['CargoRepresentante'] = ""
        };
        if (this.state.T_Titular.Apellido1Titular || this.state.T_Titular.Apellido1Titular.length > 0 || this.state.T_Titular.Apellido1Titular != "") {
            errorsData['Apellido1Titular'] = false
            textErroresData['Apellido1Titular'] = "ESTE CAMPO TIENE QUE ESTAR VACIO"
        };



        this.setState({
            representanteSeleccionado: event.representante,
            idRepresentante: event.representante.idRepresentante,
            T_Representante: event.representante,
            saveDataRepre: true,
            saveProductButtonDisabled: false,
            errores: errorsData,
            textErrores: textErroresData,
        });
    };

    ////////////////////////////////////////////////////

    showFile = (blob) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "contratoGasCalordom.pdf";
        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(data);
        }, 100);
    }



    render() {

        console.log('RENDER: this.state: ', this.state)

        const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
        const disableExportClass = disableExport ? " disabled" : "";
        const form: any = this.state.contratoDetail;

        const formDetalle: any = this.state.contratoDetailTotal;

        const form_T_General: any = this.state.T_General;
        const form_T_Titular: any = this.state.T_Titular;
        const form_T_Representante: any = this.state.T_Representante;
        const form_T_PuntoSuministro: any = this.state.T_PuntoSuministro;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_DatosAgenteComercial: any = this.state.T_DatosAgenteComercial;;
        const form_contratoDataGeneralElectricidad: any = this.state.contratoDataGeneralElectricidad;
        const form_contratoDataGeneralGas: any = this.state.contratoDataGeneralGas;

        const formRepre: any = this.state.T_Representante;

        const fechaDenegacion = this.state.fechaDenegacion
        const fechaFirmaContrato = this.state.fechaFirmaContrato
        const fechaVencimientoContrato = this.state.fechaVencimientoContrato

        var textoTipoContrato = '';
        if (this.state.tipoContrato == 'GAS') {
            textoTipoContrato = 'Gas';
        } else {
            textoTipoContrato = 'Eléctrico';
        }

        let freeModal = <></>;

        var iconAtencion;

        if (this.state.T_PropuestaComercial.nombreServicio) {
            iconAtencion = this.state.T_PropuestaComercial.nombreServicio
        }
        else {
            iconAtencion = "NO"
        }

        var fechaPropuestaFormateo = ''

        if (!this.state.T_General.fechaPropuesta) {
            fechaPropuestaFormateo = "SIN DATOS"

        } else {
            fechaPropuestaFormateo = moment(this.state.T_General.fechaPropuesta).format("DD-MM-YYYY")
        }


        var fechaVencimientoContratoFormateo = ''

        if (!this.state.T_General.fechaVencimientoContrato) {
            fechaVencimientoContratoFormateo = "SIN DATOS"

        } else {
            fechaVencimientoContratoFormateo = moment(this.state.T_General.fechaVencimientoContrato).format("DD-MM-YYYY")
        }

        if (this.state.openModalComparative == true) {

            freeModal = (
                <ContratoRepresentanteFreeModal
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    cifEmpresa={this.state.contratoDetail.CIF}
                    companyId={this.state.companyIdUser}
                    representante={this.representanteSeleccionado}


                ></ContratoRepresentanteFreeModal>
            );
        };

        return (
            <>

                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es">
                        <Header title={'Propuesta de Suministro ' + textoTipoContrato + ' "' + this.state.contrato + '"'} />
                        <Container className=" mt--7" fluid>
                            <Row>
                                <div className="col">
                                    <Card className="shadow">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={this.props.history.goBack}
                                                    >
                                                        Atrás
                                                    </button>
                                                </Col>

                                                <Col className="col-auto d-flex">
                                                    <Card className="p-3 bg-light">
                                                        <FormGroup>


                                                            <Button disabled={this.state.saveProductButtonDisabled} id="guardarProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                                onClick={() => { this.saveHandler(this.state.saveData) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-save fa-2x" style={{ fontSize: 35 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text"> GUARDAR</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="guardarProptooltip">
                                                                    GUARDAR DATOS DE LA PROPUESTA ACTUAL
                                                                </UncontrolledTooltip>
                                                            </Button>

                                                            <Button disabled={this.state.aprobarButtonDisabled} id="aprobarProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                                onClick={() => { this.aprobacionHandler(this.state.saveData) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="far fa-check-square fa-2x" style={{ fontSize: 35 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text"> APROBAR</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="aprobarProptooltip">
                                                                    APROBACION DE LA PROPUESTA ACTUAL
                                                                </UncontrolledTooltip>
                                                            </Button>

                                                            <Button disabled={this.state.contratoButtonDisabled} id="gcontratoProptooltip" color="success" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                                onClick={() => { this.contratoHandler(this.state.saveData) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-file-contract fa-2x" style={{ fontSize: 35 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">PROPUESTA</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="gcontratoProptooltip">
                                                                    VER PROPUESTA
                                                                </UncontrolledTooltip>
                                                            </Button>

                                                        </FormGroup>


                                                    </Card>
                                                </Col>


                                                <this.tipoLectura tipoContrato={this.state.tipoContrato} />

                                            </Row>


                                        </CardBody>
                                    </Card>

                                    <Row className="margin-reset w-100">


                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                TARIFA
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {this.state.T_PropuestaComercial.Tarifa}
                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                <i className="bi bi-clock-history" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        <span className="text-nowrap">Tarifa ATR</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                SERVICIOS
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {iconAtencion}
                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                                <i className="bi bi-headset" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        {" "}
                                                        <span className="text-nowrap">Con servicios adicionales</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                FECHA PROPUESTA
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {fechaPropuestaFormateo}

                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                                <i className="bi bi-calendar-event" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        {" "}
                                                        <span className="text-nowrap">Fecha de realización propuesta</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>


                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                ESTADO PROPUESTA
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">

                                                                {form_T_General.estado || "SIN ESTADO"}

                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                <i className="bi bi-calendar-event" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        {" "}
                                                        <span className="text-nowrap">Situación de la Propuesta</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>

                                    <Card className="shadow">
                                        <CardBody>
                                            <div>
                                                <CardTitle>DATOS DEL TITULAR DEL SUMINISTRO</CardTitle>
                                            </div>

                                            <LoadingOverlay
                                                active={this.state.isLoadingSips}
                                                spinner
                                                text="Cargando..."
                                            >
                                                <Row>
                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="NombreTitular">
                                                                Nombre Titular
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="NombreTitular"
                                                                placeholder=""
                                                                name="NombreTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.NombreTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.NombreTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.NombreTitular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="Apellido1Titular">
                                                                Primer Apellido Titular
                                                            </Label>
                                                            <Input
                                                                id="Apellido1Titular"
                                                                placeholder=""
                                                                name="Apellido1Titular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.Apellido1Titular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.Apellido1Titular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.Apellido1Titular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>


                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="Apellido2Titular">
                                                                Segundo Apellido Titular
                                                            </Label>
                                                            <Input
                                                                id="Apellido2Titular"
                                                                placeholder=""
                                                                name="Apellido2Titular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.Apellido2Titular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="TipoTitular">
                                                                TIPO
                                                            </Label>
                                                            <Input
                                                                id="TipoTitular"
                                                                placeholder=""
                                                                name="TipoTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.TipoTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>


                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="NIF">
                                                                NIF/CIF
                                                            </Label>
                                                            <Input
                                                                id="NIF"
                                                                placeholder=""
                                                                name="NIF"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.NIF}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.NIF == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.NIF}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="TipoViaTitular">
                                                                Tipo Via
                                                            </Label>
                                                            <Input
                                                                id="TipoViaTitular"
                                                                placeholder=""
                                                                name="TipoViaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.TipoViaTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.TipoViaTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.TipoViaTitular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="6">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="ViaTitular">
                                                                Dirección Titular
                                                            </Label>
                                                            <Input
                                                                id="ViaTitular"
                                                                placeholder=""
                                                                name="ViaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.ViaTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.ViaTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.ViaTitular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>


                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="NumFincaTitular">
                                                                Nº Finca
                                                            </Label>
                                                            <Input
                                                                id="NumFincaTitular"
                                                                placeholder=""
                                                                name="NumFincaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.NumFincaTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.NumFincaTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.NumFincaTitular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>



                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="PortalTitular">
                                                                Portal Tit.
                                                            </Label>
                                                            <Input
                                                                id="PortalTitular"
                                                                placeholder=""
                                                                name="PortalTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.PortalTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="EscaleraTitular">
                                                                ESC.
                                                            </Label>
                                                            <Input
                                                                id="EscaleraTitular"
                                                                placeholder=""
                                                                name="EscaleraTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.EscaleraTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="PisoTitular">
                                                                Piso
                                                            </Label>
                                                            <Input
                                                                id="PisoTitular"
                                                                placeholder=""
                                                                name="PisoTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.PisoTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="PuertaTitular">
                                                                Puerta
                                                            </Label>
                                                            <Input
                                                                id="PuertaTitular"
                                                                placeholder=""
                                                                name="PuertaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.PuertaTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                                                            C. Postal
                                                        </Label>
                                                        <Input type="select" value={form_T_Titular.CodigoPostalTitular} name="CodigoPostalTitular" id="CodigoPostalTitular" onChange={this.onChangeFieldTitular} disabled={this.state.inputDisabled} invalid={this.state.errores.CodigoPostalTitular == false}>
                                                            <option value={form_T_Titular.CodigoPostalTitular} disabled>{form_T_Titular.CodigoPostalTitular}</option>
                                                            {this.state.dicMunicipiosList.map((item: any, key) => (
                                                                <option key={key} value={item.codigoPostal}>{item.codigoPostal} - {item.entidadsingularnombre}</option>
                                                            ))
                                                            }
                                                        </Input>
                                                        <FormFeedback>{this.state.textErrores.CodigoPostalTitular}</FormFeedback>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label className="form-control-label" htmlFor="MunicipioTitular">
                                                            Municipio
                                                        </Label>
                                                        <Input type="select" value={form_T_Titular.MunicipioTitular} name="MunicipioTitular" id="MunicipioTitular" onChange={this.onChangeFieldTitular} disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.MunicipioTitular == false}>
                                                            <option value={form_T_Titular.MunicipioTitular} disabled>{form_T_Titular.MunicipioTitular}</option>
                                                            {this.state.dicMunicipios.map((item: any, key) => (
                                                                <option key={key} value={item.Cmunicipio + "*" + item.Nombre}>{item.Nombre}</option>
                                                            ))
                                                            }
                                                        </Input>
                                                        <FormFeedback>{this.state.textErrores.MunicipioTitular}</FormFeedback>
                                                    </Col>


                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="ProvinciaTitular">
                                                                Provincia
                                                            </Label>
                                                            <Input
                                                                id="ProvinciaTitular"
                                                                placeholder=""
                                                                name="ProvinciaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.ProvinciaTitular}
                                                                type="text"
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.ProvinciaTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.ProvinciaTitular}</FormFeedback>                                                </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="AutonomiaTitular">
                                                                C. Autónoma
                                                            </Label>
                                                            <Input
                                                                id="AutonomiaTitular"
                                                                placeholder=""
                                                                name="AutonomiaTitular"
                                                                onChange={this.onChangeFieldTitular}
                                                                value={form_T_Titular.AutonomiaTitular}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.AutonomiaTitular == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.AutonomiaTitular}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                            </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                    <Card className="shadow">
                                        <CardBody>
                                            <div>
                                                <CardTitle>DATOS DEL REPRESENTANTE</CardTitle>
                                            </div>
                                            <LoadingOverlay
                                                active={this.state.isLoadingSips}
                                                spinner
                                                text="Cargando..."
                                            >

                                                <Row>
                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                                Nombre Representante
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="nombreRepresentante"
                                                                placeholder=""
                                                                name="nombreRepresentante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.nombreRepresentante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.nombreRepresentante == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.nombreRepresentante}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="apellido1Representante">
                                                                Primer Apellido
                                                            </Label>
                                                            <Input
                                                                id="apellido1Representante"
                                                                placeholder=""
                                                                name="apellido1Representante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.apellido1Representante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.apellido1Representante == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.apellido1Representante}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>


                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="apellido2Representante">
                                                                Segundo Apellido
                                                            </Label>
                                                            <Input
                                                                id="apellido2Representante"
                                                                placeholder=""
                                                                name="apellido2Representante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.apellido2Representante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>



                                                    <Col>
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                                NIF/CIF
                                                            </Label>
                                                            <Input
                                                                id="NIFRepresentante"
                                                                placeholder=""
                                                                name="NIFRepresentante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.NIFRepresentante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.NIFRepresentante == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.NIFRepresentante}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>


                                                    <Col md="1" sm="1" lg="1" className="align-self-center">

                                                        <Button
                                                            id="UpdateRepreProptooltip"
                                                            className="mx-auto align-self-center mt-2"
                                                            position="center"
                                                            margin = "true"
                                                            onClick={this.openModalComparativa}
                                                            color="primary"
                                                            type="button"
                                                            disabled={this.state.inputDisabled}
                                                        >
                                                            <i className={"bi bi-person-plus"} style={{ fontSize: 15 }} />
                                                            <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                                AÑADIR REPRESENTANTE A LA PROPUESTA
                                                            </UncontrolledTooltip>
                                                        </Button>

                                                    </Col>


                                                    <Col md="1" sm="1" lg="1" className="align-self-center">

                                                        <Button
                                                            id="deleteRepreProptooltip"
                                                            className="mx-auto align-self-center mt-2 bi bi-person-x"
                                                            position="center"
                                                            margin= "true"
                                                            onClick={this.deleteRepresentante}
                                                            color="danger"
                                                            type="button"
                                                            disabled={this.state.inputDisabled}
                                                            style={{ fontSize: 15 }}
                                                        >

                                                            <UncontrolledTooltip delay={0} placement="top" target="deleteRepreProptooltip">
                                                                BORRAR REPRESENTANTE DE LA PROPUESTA
                                                            </UncontrolledTooltip>
                                                        </Button>

                                                    </Col>



                                                </Row>
                                                <Row>

                                                    <Col md="2" sm="2" lg="2" >
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="CargoRepresentante">
                                                                Cargo
                                                            </Label>
                                                            <Input
                                                                id="CargoRepresentante"
                                                                placeholder=""
                                                                name="CargoRepresentante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.CargoRepresentante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                                invalid={this.state.errores.CargoRepresentante == false}
                                                            />
                                                            <FormFeedback>{this.state.textErrores.CargoRepresentante}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="telefonoRepresentante">
                                                                Teléfono Contacto
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="telefonoRepresentante"
                                                                placeholder=""
                                                                name="telefonoRepresentante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.telefonoRepresentante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="correoRepresentante">
                                                                Correo Contacto
                                                            </Label>
                                                            <Input
                                                                id="correoRepresentante"
                                                                placeholder=""
                                                                name="correoRepresentante"
                                                                onChange={this.onChangeFieldRepre}
                                                                value={form_T_Representante.correoRepresentante}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="IdOferta">
                                                                ID. Oferta
                                                            </Label>
                                                            <Input
                                                                id="IdOferta"
                                                                placeholder=""
                                                                name="IdOferta"
                                                                onChange={this.onChangeField}
                                                                value={form_T_General.IdOferta}
                                                                type="text"
                                                                required
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                                Fecha Propuesta
                                                            </Label>
                                                            <Input
                                                                id="fechaPropuesta"
                                                                placeholder=""
                                                                name="fechaPropuesta"
                                                                onChange={this.onChangeField}
                                                                value={form_T_General.fechaPropuesta}
                                                                type="text"
                                                                required
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>

                                            </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                    {this.state.alert}
                                    <Card className="mt-3 shadow">
                                        <CardBody>
                                            <CardTitle>DATOS DEL PUNTO DE SUMINISTRO</CardTitle>

                                            <Row>
                                                <Col md="2">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="TipoViaPS">
                                                            Tipo Via
                                                        </Label>
                                                        <Input
                                                            id="TipoViaPS"
                                                            placeholder=""
                                                            name="TipoViaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.TipoViaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.TipoViaPS == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.TipoViaPS}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="ViaPS">
                                                            Dirección Suministro
                                                        </Label>
                                                        <Input
                                                            id="ViaPS"
                                                            placeholder=""
                                                            name="ViaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.ViaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.ViaPS == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.ViaPS}</FormFeedback>
                                                    </FormGroup>
                                                </Col>


                                                <Col md="2">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="NumFincaPS">
                                                            Nº Finca
                                                        </Label>
                                                        <Input
                                                            id="NumFincaPS"
                                                            placeholder=""
                                                            name="NumFincaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.NumFincaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.NumFincaPS == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.NumFincaPS}</FormFeedback>
                                                    </FormGroup>
                                                </Col>



                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="PortalPS">
                                                            Portal
                                                        </Label>
                                                        <Input
                                                            id="PortalPS"
                                                            placeholder=""
                                                            name="PortalPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.PortalPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="1">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="EscaleraPS">
                                                            ESC.
                                                        </Label>
                                                        <Input
                                                            id="EscaleraPS"
                                                            placeholder=""
                                                            name="EscaleraPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.EscaleraPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="1">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="PisoPS">
                                                            Piso
                                                        </Label>
                                                        <Input
                                                            id="PisoPS"
                                                            placeholder=""
                                                            name="PisoPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.PisoPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="1">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="PuertaPS">
                                                            Puerta
                                                        </Label>
                                                        <Input
                                                            id="PuertaPS"
                                                            placeholder=""
                                                            name="PuertaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.PuertaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>


                                                <Col md="3">
                                                    <Label className="form-control-label" htmlFor="CodigoPostalPS">
                                                        C. Postal
                                                    </Label>
                                                    <Input type="select" value={form_T_PuntoSuministro.CodigoPostalPS} name="CodigoPostalPS" id="CodigoPostalPS" onChange={this.onChangeFieldPuntoSuministro} disabled={this.state.inputDisabled} invalid={this.state.errores.CodigoPostalPS == false}>
                                                        <option value={form_T_PuntoSuministro.CodigoPostalPS} disabled>{form_T_PuntoSuministro.CodigoPostalPS}</option>
                                                        {this.state.dicMunicipiosList.map((item: any, key) => (
                                                            <option key={key} value={item.codigoPostal}>{item.codigoPostal} - {item.entidadsingularnombre}</option>
                                                        ))
                                                        }
                                                    </Input>
                                                    <FormFeedback>{this.state.textErrores.CodigoPostalPS}</FormFeedback>
                                                </Col>

                                                <Col md="3">
                                                    <Label className="form-control-label" htmlFor="NombreMunicipioPS">
                                                        Municipio
                                                    </Label>
                                                    <Input type="select" value={form_T_PuntoSuministro.NombreMunicipioPS} name="NombreMunicipioPS" id="NombreMunicipioPS" onChange={this.onChangeFieldPuntoSuministro} disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.NombreMunicipioPS == false}>
                                                        <option value={form_T_PuntoSuministro.NombreMunicipioPS} disabled>{form_T_PuntoSuministro.NombreMunicipioPS}</option>
                                                        {this.state.dicMunicipios.map((item: any, key) => (
                                                            <option key={key} value={item.Cmunicipio + "*" + item.Nombre}>{item.Nombre}</option>
                                                        ))
                                                        }
                                                    </Input>
                                                    <FormFeedback>{this.state.textErrores.NombreMunicipioPS}</FormFeedback>
                                                </Col>


                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="NombreProvinciaPS">
                                                            Provincia
                                                        </Label>
                                                        <Input
                                                            id="NombreProvinciaPS"
                                                            placeholder=""
                                                            name="NombreProvinciaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.NombreProvinciaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.NombreProvinciaPS == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.NombreProvinciaPS}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="AutonomiaPS">
                                                            C. Autónoma
                                                        </Label>
                                                        <Input
                                                            id="AutonomiaPS"
                                                            placeholder=""
                                                            name="AutonomiaPS"
                                                            onChange={this.onChangeFieldPuntoSuministro}
                                                            value={form_T_PuntoSuministro.AutonomiaPS}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.AutonomiaPS == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.AutonomiaPS}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                            </Row>


                                            <this.tipoCup tipoCup={this.state.tipoContrato} />
                                            <Row>

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                            Entrada Propuesta
                                                        </Label>
                                                        <Input
                                                            id="fechaPropuesta"
                                                            placeholder=""
                                                            name="fechaPropuesta"
                                                            onChange={this.onChangeField}
                                                            value={moment(form_T_General.fechaPropuesta).format("DD-MM-YYYY")}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="fechaAprobacion">
                                                            Aprobación Propuesta
                                                        </Label>
                                                        <Input
                                                            id="fechaAprobacion"
                                                            placeholder=""
                                                            name="fechaAprobacion"
                                                            onChange={this.onChangeField}
                                                            value={moment(form_T_General.fechaAprobacion, 'YYYY-MM-DD', true).isValid() ? moment(form_T_General.fechaAprobacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="fechaDenegacion">
                                                            Propuesta Rechazada
                                                        </Label>
                                                        <Input
                                                            id="fechaDenegacion"
                                                            placeholder=""
                                                            name="fechaDenegacion"
                                                            onChange={this.onChangeField}
                                                            value={moment(form_T_General.fechaDenegacion, 'YYYY-MM-DD', true).isValid() ? moment(form_T_General.fechaDenegacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>


                                            </Row>

                                            <Row>

                                                <Col md="3">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="Comercializadora">
                                                            Comercializadora
                                                        </Label>
                                                        <Input
                                                            id="Comercializadora"
                                                            placeholder=""
                                                            name="Comercializadora"
                                                            onChange={this.onChangeField}
                                                            value={form_T_PropuestaComercial.Comercializadora}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="3">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="Producto">
                                                            Producto
                                                        </Label>
                                                        <Input
                                                            id="Producto"
                                                            placeholder=""
                                                            name="Producto"
                                                            onChange={this.onChangeField}
                                                            value={form_T_PropuestaComercial.Producto}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}
                                                            invalid={this.state.errores.Producto == false}
                                                        />
                                                        <FormFeedback>{this.state.textErrores.Producto}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="1">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="TipoPrecio">
                                                            Precio
                                                        </Label>
                                                        <Input
                                                            id="TipoPrecio"
                                                            placeholder=""
                                                            name="TipoPrecio"
                                                            onChange={this.onChangeField}
                                                            value={form_T_PropuestaComercial.TipoPrecio || "0"}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="nombreServicio">
                                                            Servicio Adicional
                                                        </Label>
                                                        <Input
                                                            id="nombreServicio"
                                                            placeholder=""
                                                            name="nombreServicio"
                                                            onChange={this.onChangeField}
                                                            value={form_T_PropuestaComercial.nombreServicio || ""}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>

                                                <Col md="2">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="TelefonoPS">
                                                            Teléfono Contacto PS
                                                        </Label>
                                                        <Input
                                                            id="TelefonoPS"
                                                            placeholder=""
                                                            name="TelefonoPS"
                                                            onChange={this.onChangeField}
                                                            value={form.TelefonoPS}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>

                                    <div id="tablaLecturas">
                                        {
                                            this.state.show &&
                                            <React.Fragment>
                                                <Card className="mt-3 shadow">
                                                    <CardBody>
                                                        <CardTitle>ESTADOS DEL PUNTO DE SUMINISTRO</CardTitle>
                                                        <Row>
                                                            <Col md="2">
                                                                <FormGroup>
                                                                    <Label className=" form-control-label text-primary" htmlFor="estado">
                                                                        Estado
                                                                    </Label>
                                                                    <SipsOption
                                                                        className="text-primary"
                                                                        placeholder="Seleccionar estado"
                                                                        id="estado"
                                                                        name="estado"
                                                                        query="LIST_ESTADOS_PROPUESTAS"
                                                                        onChange={this.onChangeFieldEstado}
                                                                        value={this.state.estadoIdAux}
                                                                        disabled={this.state.estadoDisabled}
                                                                    ></SipsOption>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="5">
                                                                <FormGroup>

                                                                    <Label className="form-control-label" htmlFor="observacion">
                                                                        Observación Estado
                                                                    </Label>
                                                                    <Input
                                                                        id="observacion"
                                                                        placeholder=""
                                                                        name="observacion"
                                                                        onChange={this.onChangeFieldEstado}
                                                                        value={this.state.T_SeguimientoAux.observacion}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>


                                                            <Col md="1" sm="1" lg="1" className="align-self-center" >

                                                                <Button
                                                                    id="insertEstado"
                                                                    color="primary"
                                                                    size="m"
                                                                    className="mx-auto align-self-center mt-2"
                                                                    position="center"
                                                                    margin= "true"
                                                                    onClick={this.onEstado}
                                                                    type="button"
                                                                    disabled={this.state.saveButtonDisabledEstado}
                                                                >
                                                                    <i className={"bi bi-plus-circle"} />
                                                                    <UncontrolledTooltip delay={0} placement="top" target="insertEstado">
                                                                        AÑADIR ESTADO A LA PROPUESTA
                                                                    </UncontrolledTooltip>
                                                                </Button>

                                                            </Col>

                                                        </Row>


                                                        <DataTable
                                                            columns={columnsEstados(this.handleButtonClick)}
                                                            data={this.state.T_Seguimiento}
                                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                            noDataComponent={"Sin datos para mostrar"}
                                                            pagination
                                                            paginationPerPage={10}
                                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                                            highlightOnHover
                                                            striped
                                                            pointerOnHover
                                                            selectableRowsHighlight
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                    <CSVLink
                                                                        data={this.state.LecturasContrato}
                                                                        filename={"FicherosLecturas.csv"}
                                                                        className="btn btn-default btn-sm btn-circle"
                                                                        color="default"
                                                                        target="_blank"
                                                                        separator={";"}
                                                                    >

                                                                        <span className="btn-inner--icon">
                                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                                        </span>
                                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                                    </CSVLink>
                                                                </div>

                                                            }

                                                        />
                                                    </CardBody>
                                                </Card>
                                            </React.Fragment>
                                        }

                                    </div>

                                    <Card className="mt-3 shadow">
                                        <CardBody>
                                            <CardTitle>TARIFAS DE LA PROPUESTA APROBADA</CardTitle>


                                            <this.tipoPrecioTarifa tipoCup={this.state.tipoContrato} />


                                        </CardBody>
                                    </Card>

                                    <Card className="mt-3 shadow">
                                        <CardBody>
                                            <CardTitle>COMISION PREVISTA DE LA PROPUESTA APROBADA </CardTitle>


                                            <this.tipoComisionTarifa tipoCup={this.state.tipoContrato} />


                                        </CardBody>
                                    </Card>

                                    <this.propuestasPresentadas tipoCup={this.state.tipoContrato} />

                                    <Row className="margin-reset w-100">
                                        <Card>
                                            <CardBody>
                                                <Row className="">
                                                    <h2 className="col item-header">
                                                        Comisiones por periodo
                                                    </h2>
                                                </Row>
                                                <Row className="">
                                                    <Grid
                                                        style={{ height: '100%' }}
                                                        data={this.state.dataComisionCo.map((item) => ({
                                                            ...item
                                                        }))}
                                                        resizable={true}
                                                        scrollable="scrollable"
                                                    >
                                                        <Column field="id" title="Id" width="150px" editable={false} className="p" />
                                                        <Column field="idTipoComision" title="Tipo" width="50px" />
                                                        <Column
                                                            field="comisionAgenteTEP1"
                                                            title="coTE1"

                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTEP2"
                                                            title="coTE2"

                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTEP3"
                                                            title="coTE3"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTEP4"
                                                            title="coTE4"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTEP5"
                                                            title="coTE5"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTEP6"
                                                            title="coTE6"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP1"
                                                            title="coTEP1"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP2"
                                                            title="coTEP2"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP3"
                                                            title="coTEP3"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP4"
                                                            title="coTEP4"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP5"
                                                            title="coTEP5"
                                                            format="{0:c2}"
                                                        />
                                                        <Column
                                                            field="comisionAgenteTPP6"
                                                            title="coTEP6"
                                                            format="{0:c2}"
                                                        />
                                                    </Grid>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Row>

                                    <Row className="margin-reset w-100">
                                        <Card>
                                            <CardBody>
                                                <Row className="">
                                                    <h2 className="col item-header">
                                                        Comisiones productos sin Coste Operativo
                                                    </h2>
                                                </Row>
                                                <Row className="">
                                                    <Grid
                                                        style={{ height: '100%' }}
                                                        data={this.state.dataComision.map((item) => ({
                                                            ...item
                                                        }))}
                                                        resizable={true}
                                                        scrollable="scrollable"
                                                    >
                                                        <Column field="id" title="Id" width="150px" editable={false} className="p" />
                                                        <Column field="idTipoComision" title="Tipo" width="50px" />
                                                        <Column
                                                            field="comisionAgenteTEP1"
                                                            title="comision Producto"

                                                            format="{0:c2}"
                                                        />


                                                    </Grid>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </div>
                            </Row>
                        </Container>

                        {freeModal}
                    </IntlProvider>
                </LocalizationProvider>
            </>
        );
    }
}


export default withApollo(SipDetallesPropuestas);

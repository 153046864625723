import EmpresaClienteFreeModal from "./empresaClienteModal";
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import { customAlphabet } from "nanoid";
import client from "../../api/client";
//import DatePicker from "react-datepicker";
import { validateSpanishId, spainIdType } from 'spain-id';
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse, FormFeedback
} from "reactstrap";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const alphabet = '0123456789';
const nanoid = customAlphabet(alphabet, 11);


const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
];

const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);



const hint = <span>My custom hint</span>;
const note = <span>My custom note</span>;
const sortIcon = <ArrowDownward />;

const columnsDatosComercial = [

    { selector: row => row.nombreComercial, name: 'NOMBRE', sortable: true, width: '200px', format: (row => capitalize(row.nombreComercial)) },
    { selector: row => row.apellido1Comercial, name: 'APELLIDO 1', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Comercial)) },
    { selector: row => row.apellido2Comercial, name: 'APELLIDO 2', sortable: true, width: '210px', center: true, format: (row => capitalize(row.apellido2Comercial)) },
    { selector: row => row.NIFComercial, name: 'CIF', sortable: true, width: '100px', center: true, format: (row => capitalize(row.NIFComercial)) },
    { selector: row => row.CargoComercial, name: 'CARGO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.CargoComercial)) },
    { selector: row => row.telefonoComercial, name: 'TELEFONO', width: '180px', sortable: true, center: true, format: (row => capitalize(row.telefonoComercial)) }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class Comercial extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombreSearch: "",
            apellidoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        ComercialDetailTotal: [],
        ComercialSeleccionado: false,
        idComercial: '',
        ComercialDetail: {
            nombreComercial: '',
            apellido1Comercial: '',
            apellido2Comercial: '',
            NIFComercial: '',
            idComercial: '',
            idComercialInterno: 0,
            telefonoComercial: '',
            correoComercial: '',
            comision1: 0,
            comision2: 0,
            idCompany: 0,
            zonaGeograficaComercial: "",
            idResponsable: "",
            Responsable: "",
            idUser: '',
            createdAt: '',
            updatedAt: '',
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            created_at: "",
            modified_at: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            company: "",
            company_info: "",
            locked_up: "",
            attempts_login: "",
            reset_token: "",
            reset_token_expire: "",
            companyId: "",
            newCompanyId: "",
            indexada: false,
            mercadolibre: false,
            gas: false,
            electricidad: false,
            empresa: false,
            informes: false,
            mercadolibregas: false,
            contratos: false,
            aprobarContratos: false,
            verContratos: false,
            productos: false,
            comisiones: false,
            comercial: false,
            comercializadora: false,
            agencia: false,
            adFincas: false,
            jefeEquipo: false,
            idCard: "",
            idCardEmpresa: "",
            identificacionUsuario: "",
            identificacionEmpresa: "",
            esActivo: true,
            logoApp: "",
            logoPropuesta: "",
            eslogoApp: false,
            eslogoPropuesta: false
        },
        formComercial: {
            nombreComercial: '',
            apellido1Comercial: '',
            apellido2Comercial: '',
            NIFComercial: '',
            idComercial: '',
            idComercialInterno: 0,
            telefonoComercial: '',
            correoComercial: '',
            comision1: 0,
            comision2: 0,
            idCompany: 0,
            zonaGeograficaComercial: "",
            idResponsable: "",
            Responsable: "",
            idUser: '',
            createdAt: '',
            updatedAt: '',
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            created_at: "",
            modified_at: "",
            user_group_id: 300,
            query_limit: 250,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            company: "",
            company_info: "",
            locked_up: "",
            attempts_login: "",
            reset_token: "",
            reset_token_expire: "",
            companyId: "",
            newCompanyId: "",
            indexada: false,
            mercadolibre: false,
            gas: false,
            electricidad: false,
            empresa: false,
            informes: false,
            mercadolibregas: false,
            contratos: false,
            aprobarContratos: false,
            verContratos: false,
            productos: false,
            comisiones: false,
            comercial: false,
            comercializadora: false,
            agencia: false,
            adFincas: false,
            jefeEquipo: false,
            idCard: "",
            idCardEmpresa: "",
            identificacionUsuario: "",
            identificacionEmpresa: "",
            esActivo: true,
            logoApp: "",
            logoPropuesta: "",
            eslogoApp: false,
            eslogoPropuesta: false
        },
        errores: {
            identificacionEmpresa: true,
            nombreComercial: false,
            apellido1Comercial: false,
            correoComercial: false,
            NIFComercial: false,
            password: false,
            companyId: true
        },
        textErrores: {
            identificacionEmpresa: "",
            nombreComercial: "SIN DATOS",
            correoComercial: "SIN DATOS",
            apellido1Comercial: "SIN DATOS",
            NIFComercial: "SIN DATOS",
            password: "SIN DATOS",
            companyId: ""
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',

        saveDataComercial: false,
        updateDataComercial: false,
        saveComercialButtonDisabled: true,
        updateComercialButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFComercialError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        uploadButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreDisabled: false,
        inputCifDisabled: false,
        inputApellidosDisabled: false,
        filesContrato: [],
        createdAt: '',
        fechaBaja: '',
        codigoPostalComercialError: false,
        dicMunicipios: [],
        alert: null,
        openModalComparative: false,
        empresaSeleccionada: [],
        licenciasTotal: {
            totalLicencias: 0,
            usuariosActivos: 0,
            usuariosNoActivo: 0
        },
        inputDisabledComercial: false,
        inputDisabledJefeEquipo: false,
        inputDisabledAdFincas: false,
        inputDisabledComercializadora: false,
        inputDisabledAgencia: false,
        inputDisabledEmpresa: false
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }

    UNSAFE_componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    }

    UNSAFE_componentWillMount() {

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = '';
        let userId = '';
        let userName = '';

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;
                userId = parsed.id
                userName = parsed.fullname

            } catch (e) {
                console.log(e)
            }
        }
        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName });

        this.fetchItems();
        this.licenciasUsuarios();
        this.buscarEmpresa(companyIdUser)

    }

    componentDidMount() {

        const {
            match: { params },
        } = this.props;

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = '';
        let userId = '';
        let userName = '';

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;
                userId = parsed.id
                userName = parsed.fullname

            } catch (e) {
                console.log(e)
            }
        }
        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName });


    }

    licenciasUsuarios = async () => {

        const userFromStorage: any = localStorage.getItem("user");
        const parsed = JSON.parse(userFromStorage);

        var companyIdUser = parsed.companyId;
        var user = parsed.id
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch
        var dataFilters = {
            'idCompany': "",
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };
        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comercial/licencias?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result) {
                    let data = result.data
                    this.setState({ licenciasTotal: data });

                } else {
                    this.warningAlertNIF();
                };
            }).catch((error) => {
                console.log('Failure')
                this.warningAlert()
            });
    };


    buscarEmpresa = async (compañia) => {
        this.setState({
            isLoading: true,
        })

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(compañia);


        var dataFilters = {
            'id': companyIdUser
        };

        const valores = JSON.stringify(dataFilters)

        ///// para GET REST //////

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/empresas/empresadetalle?id=" + companyIdUser + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then((result) => {
                if (result.data.rows.length > 0) {

                    var formData = { ...this.state.formComercial };

                    formData['company'] = result.data.rows[0].empresa?.toUpperCase() || '';
                    formData['companyId'] = result.data.rows[0].id;
                    formData['idCardEmpresa'] = result.data.rows[0].nif;
                    formData['identificacionEmpresa'] = result.data.rows[0].nif;
                    formData['logoApp'] = result.data.rows[0].logoApp;
                    formData['logoPropuesta'] = result.data.rows[0].logoPropuesta;

                    this.setState({
                        formComercial: formData,
                        empresaSeleccionada: result.data.rows[0],
                        saveDataRepre: true,
                        saveProductButtonDisabled: false
                    });


                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertLicencias = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="SIN LICENCIAS LIBRES"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    No tiene actualmente licencias libres para crear usuarios. Si necesitas más usuarios, contacte con el departamento de ventas
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el comercial?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del comercial?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Comercial guardado correctamente
                </ReactBSAlert>
            )
        });
    };

    warningFicheroGrande = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado supera el lìmite de tamaño"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El fichero seleccionado supera el límite de tamaño
                </ReactBSAlert>
            )
        });
    };


    warningFicheroPermitido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado no permitido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Solo se permiten las siguientes extensiones: jpg, jpeg, png
                </ReactBSAlert>
            )
        });
    };

    successAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Logo actualizado correctamente
                </ReactBSAlert>
            )
        });
    };

    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };


    warningAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No se puede subir un logo hasta que no hayas introducidos usuario"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    No se puede subir un logo hasta que no hayas introducidos los datos mínimos para la creación de un comercial
                </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    //////////////////////////////////////////////////////////////////////////////////


    fetchComercial = async () => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch

        var dataFilters = {
            'idCompany': companyIdUser,
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comercial/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({ ComercialDetailTotal: result.data.rows });

                } else {
                    this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                this.warningAlert()
            });
    };


    validarCUPS = (cups) => {
        let ret = false;
        const reCUPS = /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i;
        if (reCUPS.test(cups)) {
            const mCUPS = cups.toUpperCase().match(reCUPS);
            const [, cups16, control] = mCUPS;
            const letters = [
                'T', 'R', 'W', 'A', 'G', 'M',
                'Y', 'F', 'P', 'D', 'X', 'B',
                'N', 'J', 'Z', 'S', 'Q', 'V',
                'H', 'L', 'C', 'K', 'E',
            ];

            const cup16Mod = +cups16 % 529,
                quotient = Math.floor(cup16Mod / letters.length),
                remainder = cup16Mod % letters.length;

            ret = (control === letters[quotient] + letters[remainder]);
        }

        return ret
    };

    onPageClick = (page) => { };

    onChangeField = (event) => {
        event.persist()

    };

    onChangeFieldSearch = (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        filters[name] = value;
        /* if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'nombreSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'nombreSearch' && event.target.value.length > 0) {

            this.setState({
                inputCifDisabled: true,
                inputApellidosDisabled: true,
                buscarButtonDisabled: false
            })
        }



        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {

            this.setState({

                cifError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    cifError: false,
                    buscarButtonDisabled: false,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    cifError: true,
                    buscarButtonDisabled: true,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,
                })
            }
        }


        if (event.target.name === 'apellidoSearch' && event.target.value.length == 0) {
            this.setState({
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'apellidoSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputNombreDisabled: true,
                buscarButtonDisabled: false
            })
        }

        this.setState({ filters });
    };



    onChangeFieldComercial = (event) => {
        event.persist()

        var formData = { ...this.state.formComercial };
        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }


        const nameFilters = ['first_name', 'last_name', 'email', 'password', 'created_at', 'user_group_id', 'query_limit', 'company', 'companyId', 'indexada', 'mercadolibre', 'gas', 'electricidad', 'idCard', 'idCardEmpresa', 'identificacionUsuario', 'identificacionEmpresa', 'esActivo'];

        if (this.state.ComercialSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'nombreComercial' && (!value || value.length == 0 || value == "")) {
                errorsData['nombreComercial'] = false
                textErroresData['nombreComercial'] = "SIN DATOS"
                formData['first_name'] = value

                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "nombreComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['nombreComercial'] = true
                textErroresData['nombreComercial'] = ""
                formData['first_name'] = value
            };

            if (event.target.name == 'companyId' && (!value || value.length == 0 || value == "")) {
                errorsData['companyId'] = false
                textErroresData['companyId'] = "SIN DATOS"


                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "companyId" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['companyId'] = true
                textErroresData['companyId'] = ""

            };

            if (event.target.name == 'apellido1Comercial' && (!value || value.length == 0 || value == "")) {
                errorsData['apellido1Comercial'] = false
                textErroresData['apellido1Comercial'] = "SIN DATOS"
                formData['last_name'] = value

                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "apellido1Comercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['apellido1Comercial'] = true
                textErroresData['apellido1Comercial'] = ""
                formData['last_name'] = value
            };


            if (name == "NIFComercial" && (!value || value.length == 0 || value == "")) {
                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
                errorsData['NIFComercial'] = false
                textErroresData['NIFComercial'] = "SIN DATOS"
                formData['idCard'] = value
                formData['identificacionUsuario'] = value
            } else if (name == "NIFComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['NIFComercial'] = true
                textErroresData['NIFComercial'] = ""
                formData['idCard'] = value
                formData['identificacionUsuario'] = value
            };


            if (event.target.name === 'NIFComercial') {
                const result = validateSpanishId(event.target.value)
                if (result === true) {

                    this.setState({
                        NIFComercialError: false,
                        updateDataComercial: true,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['NIFComercial'] = true
                    textErroresData['NIFComercial'] = ""
                    formData['idCard'] = value
                    formData['identificacionUsuario'] = value

                } else {
                    this.setState({
                        NIFComercialError: true,
                        updateDataComercial: false,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: true
                    })
                    errorsData['NIFComercial'] = false
                    textErroresData['NIFComercial'] = "NIF/CIF ERRONEO"
                    formData['idCard'] = value
                    formData['identificacionUsuario'] = value
                }
            };

            if (event.target.name == 'correoComercial' && (!value || value.length == 0 || value == "")) {
                errorsData['correoComercial'] = false
                textErroresData['correoComercial'] = "SIN DATOS"
                formData['email'] = value

                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "correoComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['correoComercial'] = true
                textErroresData['correoComercial'] = ""
                formData['email'] = value
            };


            if (event.target.name == 'password' && (!value || value.length == 0 || value == "")) {
                errorsData['password'] = false
                textErroresData['password'] = "SIN DATOS"

                this.setState({ updateDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "password" && (value || value.length > 0 || value !== "")) {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
                errorsData['password'] = true
                textErroresData['password'] = ""

            };

            if (event.target.name == 'apellido2Comercial') {
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'telefonoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'comercial' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledJefeEquipo: true,
                    inputDisabledAdFincas: true
                })
            } else if (event.target.name == 'comercial' && value == false) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledJefeEquipo: false,
                    inputDisabledAdFincas: false
                })
            }

            if (event.target.name == 'jefeEquipo' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercial: true,
                    inputDisabledAdFincas: true
                })
            } else if (event.target.name == 'jefeEquipo' && value == false) {
                this.setState({
                    inputDisabledComercial: false,
                    inputDisabledAdFincas: false
                })
            }


            if (event.target.name == 'adFincas' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercial: true,
                    inputDisabledJefeEquipo: true
                })
            } else if (event.target.name == 'adFincas' && value == false) {
                this.setState({
                    inputDisabledComercial: false,
                    inputDisabledJefeEquipo: false
                })
            };
            if (event.target.name == 'comercializadora' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledAgencia: true,
                    inputDisabledEmpresa: true
                })
            } if (event.target.name == 'comercializadora' && value == false) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledAgencia: false,
                    inputDisabledEmpresa: false
                })
            }

            if (event.target.name == 'agencia' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercializadora: true,
                    inputDisabledEmpresa: true
                })
            } else if (event.target.name == 'agencia' && value == false) {
                this.setState({
                    inputDisabledComercializadora: false,
                    inputDisabledEmpresa: false
                })
            }


            if (event.target.name == 'empresa' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercializadora: true,
                    inputDisabledAgencia: true
                })
            } else if (event.target.name == 'empresa' && value == false) {
                this.setState({
                    inputDisabledComercializadora: false,
                    inputDisabledAgencia: false
                })
            }


        } else {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (event.target.name == 'nombreComercial' && (!value || value.length == 0 || value == "")) {
                errorsData['nombreComercial'] = false
                textErroresData['nombreComercial'] = "SIN DATOS"
                formData['first_name'] = value

                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "nombreComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['nombreComercial'] = true
                textErroresData['nombreComercial'] = ""
                formData['first_name'] = value
            };


            if (event.target.name == 'companyId' && (!value || value.length == 0 || value == "")) {
                errorsData['companyId'] = false
                textErroresData['companyId'] = "SIN DATOS"

                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "companyId" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['companyId'] = true
                textErroresData['companyId'] = ""
            };

            if (event.target.name == 'apellido1Comercial' && (!value || value.length == 0 || value == "")) {
                errorsData['apellido1Comercial'] = false
                textErroresData['apellido1Comercial'] = "SIN DATOS"
                formData['last_name'] = value

                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "apellido1Comercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['apellido1Comercial'] = true
                textErroresData['apellido1Comercial'] = ""
                formData['last_name'] = value
            };

            if (name == "NIFComercial" && (!value || value.length == 0 || value == "")) {
                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
                errorsData['NIFComercial'] = false
                textErroresData['NIFComercial'] = "SIN DATOS"
                formData['idCard'] = value
                formData['identificacionUsuario'] = value
            } else if (name == "NIFComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['NIFComercial'] = true
                textErroresData['NIFComercial'] = ""
                formData['idCard'] = value
                formData['identificacionUsuario'] = value
            };


            if (event.target.name === 'NIFComercial') {
                const result = validateSpanishId(event.target.value)
                if (result === true) {

                    this.setState({
                        NIFComercialError: false,
                        saveDataComercial: true,
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: true
                    })
                    errorsData['NIFComercial'] = true
                    textErroresData['NIFComercial'] = ""
                    formData['idCard'] = value
                    formData['identificacionUsuario'] = value

                } else {
                    this.setState({
                        NIFComercialError: true,
                        saveDataComercial: false,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: true
                    })
                    errorsData['NIFComercial'] = false
                    textErroresData['NIFComercial'] = "NIF/CIF ERRONEO"
                    formData['idCard'] = value
                    formData['identificacionUsuario'] = value
                }
            }

            if (event.target.name == 'correoComercial' && (!value || value.length == 0 || value == "")) {
                errorsData['correoComercial'] = false
                textErroresData['correoComercial'] = "SIN DATOS"
                formData['first_name'] = value

                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "correoComercial" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['correoComercial'] = true
                textErroresData['correoComercial'] = ""
                formData['email'] = value
            };

            if (event.target.name == 'password' && (!value || value.length == 0 || value == "")) {
                errorsData['password'] = false
                textErroresData['password'] = "SIN DATOS"

                this.setState({ saveDataComercial: false });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: false
                })
            } else if (name == "password" && (value || value.length > 0 || value !== "")) {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                errorsData['password'] = true
                textErroresData['password'] = ""

            };

            if (event.target.name == 'apellido2Comercial') {
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (event.target.name == 'telefonoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (event.target.name == 'telefonoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'comercial' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledJefeEquipo: true,
                    inputDisabledAdFincas: true
                })
            } else if (event.target.name == 'comercial' && value == false) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledJefeEquipo: false,
                    inputDisabledAdFincas: false
                })
            }

            if (event.target.name == 'jefeEquipo' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercial: true,
                    inputDisabledAdFincas: true
                })
            } else if (event.target.name == 'jefeEquipo' && value == false) {
                this.setState({
                    inputDisabledComercial: false,
                    inputDisabledAdFincas: false
                })
            }


            if (event.target.name == 'adFincas' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercial: true,
                    inputDisabledJefeEquipo: true
                })
            } else if (event.target.name == 'adFincas' && value == false) {
                this.setState({
                    inputDisabledComercial: false,
                    inputDisabledJefeEquipo: false
                })
            }

            if (event.target.name == 'comercializadora' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledAgencia: true,
                    inputDisabledEmpresa: true
                })
            } if (event.target.name == 'comercializadora' && value == false) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledAgencia: false,
                    inputDisabledEmpresa: false
                })
            }

            if (event.target.name == 'agencia' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercializadora: true,
                    inputDisabledEmpresa: true
                })
            } else if (event.target.name == 'agencia' && value == false) {
                this.setState({
                    inputDisabledComercializadora: false,
                    inputDisabledEmpresa: false
                })
            }


            if (event.target.name == 'empresa' && value == true) {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    inputDisabledComercializadora: true,
                    inputDisabledAgencia: true
                })
            } else if (event.target.name == 'empresa' && value == false) {
                this.setState({
                    inputDisabledComercializadora: false,
                    inputDisabledAgencia: false
                })
            }


        };

        formData[name] = value;
        this.setState({
            formComercial: formData,
            errores: errorsData,
            textErrores: textErroresData
        });

    };

    ///////////////////////////////////////////////////////////////////////////////////////////


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };


    ///////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                nombreSearch: '',
                cifSearch: '',
                apellidoSearch: ''
            },
            inputCifDisabled: false,
            inputNombreDisabled: false,
            inputApellidosDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            ComercialSeleccionado: false,
            ComercialDetailTotal: [],
            formComercial: {
                nombreComercial: '',
                apellido1Comercial: '',
                apellido2Comercial: '',
                NIFComercial: '',
                idComercial: '',
                idComercialInterno: 0,
                telefonoComercial: '',
                correoComercial: '',
                comision1: 0,
                comision2: 0,
                idCompany: 0,
                zonaGeograficaComercial: "",
                idResponsable: "",
                Responsable: "",
                idUser: '',
                createdAt: '',
                updatedAt: '',
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                created_at: "",
                modified_at: "",
                user_group_id: 300,
                query_limit: 250,
                profile_photo: "",
                profile_photo_dir: "",
                app_logo: "",
                app_logo_dir: "",
                company: "",
                company_info: "",
                locked_up: null,
                attempts_login: 0,
                reset_token: "",
                reset_token_expire: null,
                companyId: 0,
                newCompanyId: "",
                indexada: false,
                mercadolibre: false,
                gas: false,
                electricidad: false,
                empresa: false,
                informes: false,
                mercadolibregas: false,
                contratos: false,
                aprobarContratos: false,
                verContratos: false,
                productos: false,
                comisiones: false,
                comercial: false,
                comercializadora: false,
                agencia: false,
                adFincas: false,
                jefeEquipo: false,
                idCard: "",
                idCardEmpresa: "",
                identificacionUsuario: "",
                identificacionEmpresa: "",
                esActivo: true,
                eslogoApp: false, 
                eslogoPropuesta:false

            },
            toggledClearRows: !this.state.toggledClearRows,
            saveComercialButtonDisabled: true,
            updateComercialButtonDisabled: true,
            createdAt: '',
            fechaBaja: ''
        });

        var formData = { ...this.state.formComercial };
        var datosEmpresa: any = this.state.empresaSeleccionada
        
        formData['company'] = datosEmpresa.empresa?.toUpperCase() || '';
        formData['companyId'] = datosEmpresa.id;
        formData['idCardEmpresa'] = datosEmpresa.nif;
        formData['identificacionEmpresa'] = datosEmpresa.nif;
        formData['logoApp'] = datosEmpresa.logoApp;
        formData['logoPropuesta'] = datosEmpresa.logoPropuesta;

        this.setState({
            formComercial: formData,
        });


        this.formRef.current.reset();
    };

    buscarComercial = async () => {
        var companyIdUser = this.state.companyIdUser;
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch
        var dataFilters = {
            'idCompany': "",
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };
        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comercial/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({ ComercialDetailTotal: result.data.rows });

                } else {
                    this.warningAlertNIF();
                };
            }).catch((error) => {
                console.log('Failure')
                this.warningAlert()
            });
    };

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });
            const reftp = document.getElementById("tablaLecturas")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {
            this.setState({
                show: false
            });
        }
    };

    updateHandler(props) {

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.warningAlertFiltros();
        } else {
            this.confirmAlertUpdate();
        }
    };

    saveHandler(props) {
        if (this.state.licenciasTotal.usuariosActivos <= this.state.licenciasTotal.totalLicencias) {

            let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

            if (existenErrores == true) {
                this.warningAlertFiltros();
            } else {
                this.confirmAlertSave();
            }
        } else {

            this.warningAlertLicencias();
        }

    };

    onUpdate = async () => {
        if (this.state.updateDataComercial == true) {

            //const fechaActivacion = this.state.fechaActivacion;
            const companyId = this.state.companyIdUser;
            const userId = this.state.userId.toString();

            //const idContrato = this.state.contrato;

            var createdAt = this.state.formComercial.createdAt;
            var updatedAt = this.state.formComercial.updatedAt;

            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }
            if (!updatedAt) {
                updatedAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var datosComercial = {
                nombreComercial: this.state.formComercial.nombreComercial.toUpperCase(),
                apellido1Comercial: this.state.formComercial.apellido1Comercial.toUpperCase(),
                apellido2Comercial: this.state.formComercial.apellido2Comercial.toUpperCase(),
                NIFComercial: this.state.formComercial.NIFComercial.toUpperCase(),
                idComercial: this.state.formComercial.idComercial,
                idComercialInterno: this.state.formComercial.idComercialInterno,
                telefonoComercial: this.state.formComercial.telefonoComercial,
                correoComercial: this.state.formComercial.correoComercial,
                comision1: Number(this.state.formComercial.comision1),
                comision2: Number(this.state.formComercial.comision2),
                idCompany: Number(companyId),
                zonaGeograficaComercial: this.state.formComercial.zonaGeograficaComercial,
                idResponsable: this.state.formComercial.idResponsable,
                Responsable: this.state.formComercial.Responsable.toUpperCase(),
                idUser: userId,
                createdAt: createdAt,
                updatedAt: updatedAt,
                id: this.state.formComercial.id,
                first_name: this.state.formComercial.first_name,
                last_name: this.state.formComercial.last_name,
                email: this.state.formComercial.email,
                password: this.state.formComercial.password,
                created_at: this.state.formComercial.created_at,
                modified_at: null,
                user_group_id: this.state.formComercial.user_group_id,
                query_limit: this.state.formComercial.query_limit,
                profile_photo: this.state.formComercial.profile_photo,
                profile_photo_dir: this.state.formComercial.profile_photo_dir,
                app_logo: this.state.formComercial.logoApp,
                app_logo_dir: this.state.formComercial.app_logo_dir,
                company: this.state.formComercial.company,
                company_info: this.state.formComercial.company_info,
                locked_up: null,
                attempts_login: 0,
                reset_token: this.state.formComercial.reset_token,
                reset_token_expire: null,
                companyId: this.state.formComercial.companyId,
                newCompanyId: null,
                indexada: this.state.formComercial.indexada,
                mercadolibre: this.state.formComercial.mercadolibre,
                gas: this.state.formComercial.gas,
                electricidad: this.state.formComercial.electricidad,
                empresa: this.state.formComercial.empresa,
                informes: this.state.formComercial.informes,
                mercadolibregas: this.state.formComercial.mercadolibregas,
                contratos: this.state.formComercial.contratos,
                aprobarContratos: this.state.formComercial.aprobarContratos,
                verContratos: this.state.formComercial.verContratos,
                productos: this.state.formComercial.productos,
                comisiones: this.state.formComercial.comisiones,
                comercial: this.state.formComercial.comercial,
                comercializadora: this.state.formComercial.comercializadora,
                agencia: this.state.formComercial.agencia,
                adFincas: this.state.formComercial.adFincas,
                jefeEquipo: this.state.formComercial.jefeEquipo,
                idCard: this.state.formComercial.idCard,
                idCardEmpresa: this.state.formComercial.idCardEmpresa,
                identificacionUsuario: this.state.formComercial.identificacionUsuario,
                identificacionEmpresa: this.state.formComercial.identificacionEmpresa,
                esActivo: this.state.formComercial.esActivo,
                logoApp: this.state.formComercial.logoApp,
                logoPropuesta: this.state.formComercial.logoPropuesta,
                eslogoApp: this.state.formComercial.eslogoApp,
                eslogoPropuesta: this.state.formComercial.eslogoPropuesta
            }

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/comercial/create?id=" + companyId + "&user=" + userId;

            axios.post(direccionFile, datosComercial, { headers, responseType: 'json' })
                .then(res => {
                    console.log('Actualización correcta')
                    this.setState({
                        updateDataComercial: false,
                        updateComercialButtonDisabled: true
                    })
                    this.successAlert()

                }).catch(error => {
                    console.log('Failure')
                    //notifyErrorDB();
                });

            await this.fetchComercial();
        }

    };


    onSave = async () => {

        const companyId = this.state.companyIdUser;
        const userId = this.state.userId.toString();

        var newIdUsuario = nanoid();

        var createdAt = this.state.formComercial.createdAt;
        if (!createdAt) {
            createdAt = moment().format("YYYY-MM-DD");
        }

        var updatedAt = null

        if (this.state.saveDataComercial == true) {

            var datosComercial = {

                nombreComercial: this.state.formComercial.nombreComercial.toUpperCase(),
                apellido1Comercial: this.state.formComercial.apellido1Comercial.toUpperCase(),
                apellido2Comercial: this.state.formComercial.apellido2Comercial.toUpperCase(),
                NIFComercial: this.state.formComercial.NIFComercial.toUpperCase(),
                idComercial: this.state.formComercial.idComercial,
                idComercialInterno: this.state.formComercial.idComercialInterno,
                telefonoComercial: this.state.formComercial.telefonoComercial,
                correoComercial: this.state.formComercial.correoComercial,
                comision1: Number(this.state.formComercial.comision1),
                comision2: Number(this.state.formComercial.comision2),
                idCompany: this.state.formComercial.companyId,
                zonaGeograficaComercial: this.state.formComercial.zonaGeograficaComercial,
                idResponsable: this.state.formComercial.idResponsable,
                Responsable: this.state.formComercial.Responsable,
                idUser: userId,
                createdAt: createdAt,
                updatedAt: updatedAt,
                id: Number(newIdUsuario),
                first_name: this.state.formComercial.first_name,
                last_name: this.state.formComercial.last_name,
                email: this.state.formComercial.email,
                password: this.state.formComercial.password,
                created_at: createdAt,
                modified_at: null,
                user_group_id: this.state.formComercial.user_group_id,
                query_limit: this.state.formComercial.query_limit,
                profile_photo: this.state.formComercial.profile_photo,
                profile_photo_dir: this.state.formComercial.profile_photo_dir,
                app_logo: this.state.formComercial.logoApp,
                app_logo_dir: this.state.formComercial.app_logo_dir,
                company: this.state.formComercial.company,
                company_info: this.state.formComercial.company_info,
                locked_up: null,
                attempts_login: 0,
                reset_token: this.state.formComercial.reset_token,
                reset_token_expire: null,
                companyId: this.state.formComercial.companyId,
                newCompanyId: null,
                indexada: this.state.formComercial.indexada,
                mercadolibre: this.state.formComercial.mercadolibre,
                gas: this.state.formComercial.gas,
                electricidad: this.state.formComercial.electricidad,
                empresa: this.state.formComercial.empresa,
                informes: this.state.formComercial.informes,
                mercadolibregas: this.state.formComercial.mercadolibregas,
                contratos: this.state.formComercial.contratos,
                aprobarContratos: this.state.formComercial.aprobarContratos,
                verContratos: this.state.formComercial.verContratos,
                productos: this.state.formComercial.productos,
                comisiones: this.state.formComercial.comisiones,
                comercial: this.state.formComercial.comercial,
                comercializadora: this.state.formComercial.comercializadora,
                agencia: this.state.formComercial.agencia,
                adFincas: this.state.formComercial.adFincas,
                jefeEquipo: this.state.formComercial.jefeEquipo,
                idCard: this.state.formComercial.idCard,
                idCardEmpresa: this.state.formComercial.idCardEmpresa,
                identificacionUsuario: this.state.formComercial.identificacionUsuario,
                identificacionEmpresa: this.state.formComercial.identificacionEmpresa,
                esActivo: this.state.formComercial.esActivo,
                logoApp: this.state.formComercial.logoApp,
                logoPropuesta: this.state.formComercial.logoPropuesta,
                eslogoApp: this.state.formComercial.eslogoApp,
                eslogoPropuesta: this.state.formComercial.eslogoPropuesta

            }

            var resultadoUpdate: any = [];
            var resultadoView: any = [];

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/comercial/create?id=" + companyId + "&user=" + userId;

            axios.post(direccionFile, datosComercial, { headers, responseType: 'json' })
                .then(res => {
                    console.log('Actualización correcta')
                    this.setState({
                        saveDataComercial: false,
                        saveComercialButtonDisabled: true
                    });
                    this.licenciasUsuarios();
                    this.successAlert()

                }).catch(error => {
                    console.log('Failure')
                    //notifyErrorDB();
                });
            this.clearForm();
        }
    };

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formComercial };

        const name = id;
        var valueDay = formattedValue

        var valorFechaFormateado = valueDay.split("-").reverse().join("-");

        if (this.state.ComercialSeleccionado) {
            if (name == 'createdAt') {
                this.setState({
                    updateDataComercial: true,
                    createdAt: value,
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                });
                formData['createdAt'] = valorFechaFormateado;
                formData['created_at'] = valorFechaFormateado;
            };
            if (name == 'fechaBaja') {
                this.setState({
                    updateDataComercial: true,
                    fechaBaja: value,
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                });
                formData['fechaBaja'] = valorFechaFormateado;
                formData['modified_at'] = valorFechaFormateado;
            }
        } else {
            if (name == 'createdAt') {
                this.setState({
                    saveDataComercial: true,
                    createdAt: value,
                });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                formData['createdAt'] = valorFechaFormateado;
                formData['created_at'] = valorFechaFormateado;
            }
            if (name == 'fechaBaja') {
                this.setState({
                    saveDataComercial: true,
                    fechaBaja: value,
                });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
                formData['fechaBaja'] = valorFechaFormateado;
                formData['modified_at'] = valorFechaFormateado;
            }
        };

        this.setState({ formComercial: formData });
    };

    handleChange = state => {
        // eslint-disable-next-line no-console

        var Comercial = '';

        if (state.selectedCount === 1) {
            let errorsData = { ...this.state.errores }
            let textErroresData = { ...this.state.textErrores }
            var existeComercial = ''
            Comercial = state.selectedRows[0].idComercial;

            this.setState({ ComercialDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeComercial = state.selectedRows[0].idComercial

            if (existeFechaAlta && existeFechaBaja) {

                this.setState({
                    inputDisabled: true,
                    updateComercialButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (existeComercial) {

                let data = state.selectedRows[0]
                let fechaAltaAux = ""
                let fechaBajaAux = ""

                if (data.createdAt) {
                    fechaAltaAux = data.createdAt
                }

                if (data.fechaBaja) {
                    fechaBajaAux = data.fechaBajaAux
                }


                if (state.selectedRows[0].nombreComercial && (!state.selectedRows[0].nombreComercial || state.selectedRows[0].nombreComercial.length == 0 || state.selectedRows[0].nombreComercial == "")) {
                    errorsData['nombreComercial'] = false
                    textErroresData['nombreComercial'] = "SIN DATOS"

                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                } else if (state.selectedRows[0].nombreComercial && (state.selectedRows[0].nombreComercial || state.selectedRows[0].nombreComercial.length > 0 || state.selectedRows[0].nombreComercial !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['nombreComercial'] = true
                    textErroresData['nombreComercial'] = ""

                };

                if (state.selectedRows[0].companyId && (!state.selectedRows[0].companyId || state.selectedRows[0].companyId.length == 0 || state.selectedRows[0].companyId == "")) {
                    errorsData['companyId'] = false
                    textErroresData['companyId'] = "SIN DATOS"

                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                } else if (state.selectedRows[0].companyId && (state.selectedRows[0].companyId || state.selectedRows[0].companyId.length > 0 || state.selectedRows[0].companyId !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['companyId'] = true
                    textErroresData['companyId'] = ""
                };

                if (state.selectedRows[0].apellido1Comercial && (!state.selectedRows[0].apellido1Comercial || state.selectedRows[0].apellido1Comercial.length == 0 || state.selectedRows[0].apellido1Comercial == "")) {
                    errorsData['apellido1Comercial'] = false
                    textErroresData['apellido1Comercial'] = "SIN DATOS"

                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                } else if (state.selectedRows[0].apellido1Comercial && (state.selectedRows[0].apellido1Comercial || state.selectedRows[0].apellido1Comercial.length > 0 || state.selectedRows[0].apellido1Comercial !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['apellido1Comercial'] = true
                    textErroresData['apellido1Comercial'] = ""
                };


                if (state.selectedRows[0].NIFComercial && (!state.selectedRows[0].NIFComercial || state.selectedRows[0].NIFComercial.length == 0 || state.selectedRows[0].NIFComercial == "")) {
                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['NIFComercial'] = false
                    textErroresData['NIFComercial'] = "SIN DATOS"

                } else if (state.selectedRows[0].NIFComercial && (state.selectedRows[0].NIFComercial || state.selectedRows[0].NIFComercial.length > 0 || state.selectedRows[0].NIFComercial !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['NIFComercial'] = true
                    textErroresData['NIFComercial'] = ""

                };


                if (state.selectedRows[0].identificacionEmpresa && (!state.selectedRows[0].identificacionEmpresa || state.selectedRows[0].identificacionEmpresa.length == 0 || state.selectedRows[0].identificacionEmpresa == "")) {
                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['identificacionEmpresa'] = false
                    textErroresData['identificacionEmpresa'] = "SIN DATOS"

                } else if (state.selectedRows[0].NIFComercial && (state.selectedRows[0].identificacionEmpresa || state.selectedRows[0].identificacionEmpresa.length > 0 || state.selectedRows[0].identificacionEmpresa !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['identificacionEmpresa'] = true
                    textErroresData['identificacionEmpresa'] = ""

                };



                if (state.selectedRows[0].NIFComercial) {
                    const result = validateSpanishId(state.selectedRows[0].NIFComercial)
                    if (result === true) {

                        this.setState({
                            NIFComercialError: false,
                            updateDataComercial: true,
                            saveComercialButtonDisabled: true,
                            updateComercialButtonDisabled: false
                        })
                        errorsData['NIFComercial'] = true
                        textErroresData['NIFComercial'] = ""


                    } else {
                        this.setState({
                            NIFComercialError: true,
                            updateDataComercial: false,
                            saveComercialButtonDisabled: true,
                            updateComercialButtonDisabled: true
                        })
                        errorsData['NIFComercial'] = false
                        textErroresData['NIFComercial'] = "NIF/CIF ERRONEO"

                    }
                };

                if (state.selectedRows[0].correoComercial && (!state.selectedRows[0].correoComercial || state.selectedRows[0].correoComercial.length == 0 || state.selectedRows[0].correoComercial == "")) {
                    errorsData['correoComercial'] = false
                    textErroresData['correoComercial'] = "SIN DATOS"
                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                } else if (state.selectedRows[0].correoComercial && (state.selectedRows[0].correoComercial || state.selectedRows[0].correoComercial.length > 0 || state.selectedRows[0].correoComercial !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['correoComercial'] = true
                    textErroresData['correoComercial'] = ""
                };


                if (state.selectedRows[0].password && (!state.selectedRows[0].password || state.selectedRows[0].password.length == 0 || state.selectedRows[0].password == "")) {
                    errorsData['password'] = false
                    textErroresData['password'] = "SIN DATOS"

                    this.setState({ updateDataComercial: false });
                    this.setState({
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: false
                    })
                } else if (state.selectedRows[0].password && (state.selectedRows[0].password || state.selectedRows[0].password.length > 0 || state.selectedRows[0].password !== "")) {
                    this.setState({ updateDataComercial: true });
                    this.setState({
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false
                    })
                    errorsData['password'] = true
                    textErroresData['password'] = ""

                };

                let inputDisabledAgencia = false
                let inputDisabledComercializadora = false
                let inputDisabledEmpresa = false
                let inputDisabledComercial = false
                let inputDisabledJefeEquipo = false
                let inputDisabledAdFincas = false

                if (!state.selectedRows[0].comercial) {
                    inputDisabledComercial = true
                }
                else if (state.selectedRows[0].comercial) {
                    inputDisabledJefeEquipo = true
                    inputDisabledComercial = false
                    inputDisabledAdFincas = true
                }

                if (!state.selectedRows[0].jefeEquipo) {
                    inputDisabledJefeEquipo = true
                }
                else if (state.selectedRows[0].jefeEquipo) {
                    inputDisabledJefeEquipo = false
                    inputDisabledComercial = true
                    inputDisabledAdFincas = true
                }

                if (!state.selectedRows[0].adFincas) {
                    inputDisabledAdFincas = true
                }
                else if (state.selectedRows[0].adFincas) {
                    inputDisabledJefeEquipo = true
                    inputDisabledComercial = true
                    inputDisabledAdFincas = false
                }

                if (!state.selectedRows[0].agencia) {
                    inputDisabledAgencia = true
                }
                else if (state.selectedRows[0].agencia) {
                    inputDisabledAgencia = false
                    inputDisabledComercializadora = true
                    inputDisabledEmpresa = true
                }

                if (!state.selectedRows[0].comercializadora) {
                    inputDisabledJefeEquipo = true
                }
                else if (state.selectedRows[0].comercializadora) {
                    inputDisabledAgencia = true
                    inputDisabledComercializadora = false
                    inputDisabledEmpresa = true
                }

                if (!state.selectedRows[0].empresa) {
                    inputDisabledEmpresa = true
                }
                else if (state.selectedRows[0].empresa) {
                    inputDisabledAgencia = true
                    inputDisabledComercializadora = true
                    inputDisabledEmpresa = false
                }

                this.setState({ idComercial: this.state.ComercialDetail.idComercial });
                this.setState({
                    formComercial: {

                        nombreComercial: state.selectedRows[0].nombreComercial,
                        apellido1Comercial: state.selectedRows[0].apellido1Comercial,
                        apellido2Comercial: state.selectedRows[0].apellido2Comercial,
                        NIFComercial: state.selectedRows[0].NIFComercial,
                        idComercial: state.selectedRows[0].idComercial,
                        idComercialInterno: state.selectedRows[0].idComercialInterno,
                        telefonoComercial: state.selectedRows[0].telefonoComercial,
                        correoComercial: state.selectedRows[0].correoComercial,
                        comision1: Number(state.selectedRows[0].comision1),
                        comision2: Number(state.selectedRows[0].comision2),
                        idCompany: Number(state.selectedRows[0].idCompany),
                        zonaGeograficaComercial: state.selectedRows[0].zonaGeograficaComercial,
                        idResponsable: state.selectedRows[0].idResponsable,
                        Responsable: state.selectedRows[0].Responsable,
                        idUser: state.selectedRows[0].idUser,
                        createdAt: state.selectedRows[0].createdAt,
                        updatedAt: state.selectedRows[0].updatedAt,
                        id: state.selectedRows[0].id,
                        first_name: state.selectedRows[0].first_name,
                        last_name: state.selectedRows[0].last_name,
                        email: state.selectedRows[0].email,
                        password: state.selectedRows[0].password,
                        created_at: state.selectedRows[0].created_at,
                        modified_at: null,
                        user_group_id: state.selectedRows[0].user_group_id,
                        query_limit: state.selectedRows[0].query_limit,
                        profile_photo: state.selectedRows[0].profile_photo,
                        profile_photo_dir: state.selectedRows[0].profile_photo_dir,
                        app_logo: state.selectedRows[0].app_logo,
                        app_logo_dir: state.selectedRows[0].app_logo_dir,
                        company: state.selectedRows[0].company,
                        company_info: state.selectedRows[0].company_info,
                        locked_up: null,
                        attempts_login: 0,
                        reset_token: state.selectedRows[0].reset_token,
                        reset_token_expire: null,
                        companyId: state.selectedRows[0].companyId,
                        newCompanyId: null,
                        indexada: state.selectedRows[0].indexada,
                        mercadolibre: state.selectedRows[0].mercadolibre,
                        gas: state.selectedRows[0].gas,
                        electricidad: state.selectedRows[0].electricidad,
                        empresa: state.selectedRows[0].empresa,
                        informes: state.selectedRows[0].informes,
                        mercadolibregas: state.selectedRows[0].mercadolibregas,
                        contratos: state.selectedRows[0].contratos,
                        aprobarContratos: state.selectedRows[0].aprobarContratos,
                        verContratos: state.selectedRows[0].verContratos,
                        productos: state.selectedRows[0].productos,
                        comisiones: state.selectedRows[0].comisiones,
                        comercial: state.selectedRows[0].comercial,
                        comercializadora: state.selectedRows[0].comercializadora,
                        agencia: state.selectedRows[0].agencia,
                        adFincas: state.selectedRows[0].adFincas,
                        jefeEquipo: state.selectedRows[0].jefeEquipo,
                        idCard: state.selectedRows[0].idCard,
                        idCardEmpresa: state.selectedRows[0].idCardEmpresa,
                        identificacionUsuario: state.selectedRows[0].identificacionUsuario,
                        identificacionEmpresa: state.selectedRows[0].identificacionEmpresa,
                        esActivo: state.selectedRows[0].esActivo,
                        logoApp: state.selectedRows[0].logoApp,
                        logoPropuesta: state.selectedRows[0].logoPropuesta,
                        eslogoApp: state.selectedRows[0].eslogoApp,
                        eslogoPropuesta: state.selectedRows[0].eslogoPropuesta
                    },
                    inputDisabledAgencia: inputDisabledAgencia,
                    inputDisabledComercializadora: inputDisabledComercializadora,
                    inputDisabledEmpresa: inputDisabledEmpresa,
                    inputDisabledComercial: inputDisabledComercial,
                    inputDisabledJefeEquipo: inputDisabledJefeEquipo,
                    inputDisabledAdFincas: inputDisabledAdFincas,

                    ComercialSeleccionado: true,
                    createdAt: fechaAltaAux,
                    fechaBaja: fechaBajaAux,
                    errores: errorsData,
                    textErrores: textErroresData
                });

            } else {
                this.setState({
                    ComercialSeleccionado: false,
                    formComercial: {
                        nombreComercial: '',
                        apellido1Comercial: '',
                        apellido2Comercial: '',
                        NIFComercial: '',
                        idComercial: '',
                        idComercialInterno: 0,
                        telefonoComercial: '',
                        correoComercial: '',
                        comision1: 0,
                        comision2: 0,
                        idCompany: 0,
                        zonaGeograficaComercial: "",
                        idResponsable: "",
                        Responsable: "",
                        idUser: '',
                        createdAt: '',
                        updatedAt: '',
                        id: 0,
                        first_name: "",
                        last_name: "",
                        email: "",
                        password: "",
                        created_at: "",
                        modified_at: "",
                        user_group_id: 300,
                        query_limit: 250,
                        profile_photo: "",
                        profile_photo_dir: "",
                        app_logo: "",
                        app_logo_dir: "",
                        company: "",
                        company_info: "",
                        locked_up: null,
                        attempts_login: 0,
                        reset_token: "",
                        reset_token_expire: null,
                        companyId: 0,
                        newCompanyId: "",
                        indexada: false,
                        mercadolibre: false,
                        gas: false,
                        electricidad: false,
                        empresa: false,
                        informes: false,
                        mercadolibregas: false,
                        contratos: false,
                        aprobarContratos: false,
                        verContratos: false,
                        productos: false,
                        comisiones: false,
                        comercial: false,
                        comercializadora: false,
                        agencia: false,
                        adFincas: false,
                        jefeEquipo: false,
                        idCard: "",
                        idCardEmpresa: "",
                        identificacionUsuario: "",
                        identificacionEmpresa: "",
                        esActivo: true,
                        eslogoApp: false,
                        eslogoPropuesta: false
                    },
                    errores: {
                        identificacionEmpresa: true,
                        nombreComercial: false,
                        apellido1Comercial: false,
                        correoComercial: false,
                        NIFComercial: false,
                        password: false,
                        companyId: true
                    },
                    textErrores: {
                        identificacionEmpresa: "",
                        nombreComercial: "SIN DATOS",
                        correoComercial: "SIN DATOS",
                        apellido1Comercial: "SIN DATOS",
                        NIFComercial: "SIN DATOS",
                        password: "SIN DATOS",
                        companyId: ""
                    },
                });
            }
        } else {

            this.setState({
                ComercialSeleccionado: false,
                inputDisabled: false,
                updateComercialButtonDisabled: true,
                formComercial: {
                    nombreComercial: '',
                    apellido1Comercial: '',
                    apellido2Comercial: '',
                    NIFComercial: '',
                    idComercial: '',
                    idComercialInterno: 0,
                    telefonoComercial: '',
                    correoComercial: '',
                    comision1: 0,
                    comision2: 0,
                    idCompany: 0,
                    zonaGeograficaComercial: "",
                    idResponsable: "",
                    Responsable: "",
                    idUser: '',
                    createdAt: '',
                    updatedAt: '',
                    id: 0,
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    created_at: "",
                    modified_at: "",
                    user_group_id: 300,
                    query_limit: 250,
                    profile_photo: "",
                    profile_photo_dir: "",
                    app_logo: "",
                    app_logo_dir: "",
                    company: "",
                    company_info: "",
                    locked_up: null,
                    attempts_login: 0,
                    reset_token: "",
                    reset_token_expire: null,
                    companyId: 0,
                    newCompanyId: "",
                    indexada: false,
                    mercadolibre: false,
                    gas: false,
                    electricidad: false,
                    empresa: false,
                    informes: false,
                    mercadolibregas: false,
                    contratos: false,
                    aprobarContratos: false,
                    verContratos: false,
                    productos: false,
                    comisiones: false,
                    comercial: false,
                    comercializadora: false,
                    agencia: false,
                    adFincas: false,
                    jefeEquipo: false,
                    idCard: "",
                    idCardEmpresa: "",
                    identificacionUsuario: "",
                    identificacionEmpresa: "",
                    esActivo: true,
                    eslogoApp: false,
                    eslogoPropuesta: false
                },
                errores: {
                    identificacionEmpresa: true,
                    nombreComercial: false,
                    apellido1Comercial: false,
                    correoComercial: false,
                    NIFComercial: false,
                    password: false,
                    companyId: true
                },
                textErrores: {
                    identificacionEmpresa: "",
                    nombreComercial: "SIN DATOS",
                    correoComercial: "SIN DATOS",
                    apellido1Comercial: "SIN DATOS",
                    NIFComercial: "SIN DATOS",
                    password: "SIN DATOS",
                    companyId: ""
                },
            });

            var formData = { ...this.state.formComercial };
            var datosEmpresa: any = this.state.empresaSeleccionada
    
            formData['company'] = datosEmpresa.empresa?.toUpperCase() || '';
            formData['companyId'] = datosEmpresa.id;
            formData['idCardEmpresa'] = datosEmpresa.nif;
            formData['identificacionEmpresa'] = datosEmpresa.nif;
            formData['logoApp'] = datosEmpresa.logoApp;
            formData['logoPropuesta'] = datosEmpresa.logoPropuesta;
    
            this.setState({
                formComercial: formData,
            });
        };
    };


    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    //////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    validateFile(file) {
        const extension = file.name.split(".").pop() || "";
        // Get length of file in bytes
        const fileSizeInBytes = file.size;
        // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
        const fileSizeInKB = fileSizeInBytes / 1024;
        // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
        const fileSizeInMB = fileSizeInKB / 1024;
        if (!this.validFileExtensions.includes(extension)) {
            const extensionsAllowed = this.validFileExtensions.join(", ");
            this.warningFicheroPermitido()
            return false;
        }
        if (fileSizeInMB > this.maxFileSize) {
            this.warningFicheroGrande()
            return false;
        }

        return true;

        // onChangeFile(file);
    }


    // On App Logo Change

    onAppLogoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.warningAlertLogo();
        } else {

            if (this.validateFile(file)) {

                var formData = new FormData();

                formData.append('file', file)

                const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
                const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""


                //var user: any = [];

                const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

                const user: any = localStorage.getItem("user");

                var datosUser: any = []


                const headers = {
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                    "Content-Type": "multipart/form-data"
                };
                let direccionFile = "https://datapi.psgestion.es/files/upload/logo/empresa/comercial?id=" + this.state.companyIdUser + "&user=" + this.state.userId;


                axios.post(direccionFile, formData, { headers }
                ).then(result => {
                    let direccionImagen = result.data.resultado
                    var formData = { ...this.state.formComercial };

                    formData['logoApp'] = direccionImagen

                    this.setState({
                        isLoading: false,
                        formComercial: formData
                    })

                    this.successAlertLogo()
                    // UserStoreActions.update(result.data);

                }).catch((error) => {
                    console.log('Failure: ', error)
                    this.errorDB()
                    this.setState({
                        isLoading: false,
                    });

                });
                reader.readAsDataURL(file);
            }
        }
    };


    onAppLogoChangePropuesta = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.warningAlertLogo();
        } else {

            if (this.validateFile(file)) {

                var formData = new FormData();

                formData.append('file', file)

                const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
                const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""

                const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

                const user: any = localStorage.getItem("user");

                var datosUser: any = []

                const headers = {
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                    "Content-Type": "multipart/form-data"
                };
                let direccionFile = "https://datapi.psgestion.es/files/upload/logo/propuesta/comercial?id=" + this.state.companyIdUser + "&user=" + this.state.userId;

                axios.post(direccionFile, formData, { headers }
                ).then(result => {
                    let direccionImagen = result.data.resultado
                    var formData = { ...this.state.formComercial };

                    formData['logoPropuesta'] = direccionImagen

                    this.setState({
                        isLoading: false,
                        formComercial: formData
                    })

                    this.successAlertLogo()
                    // UserStoreActions.update(result.data);

                }).catch((error) => {
                    console.log('Failure: ', error)
                    this.errorDB()
                    this.setState({
                        isLoading: false,
                    });

                });
                reader.readAsDataURL(file);
            }
        }
    };

    eventFire = (el, etype) => {
        if (el.fireEvent) {
            el.fireEvent("on" + etype);
        } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };
    onAppLogoButtonClick = () => {
        const input = document.getElementById("label-upload-button");
        this.eventFire(input, "click");
    };

    onAppLogoPropuestaClick = () => {
        const input = document.getElementById("label-upload-propuesta-button");
        this.eventFire(input, "click");
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////

    render() {

        const formComercial: any = this.state.formComercial;
        const createdAt = this.state.createdAt
        const fechaBaja = this.state.fechaBaja
        var previewImage = this.state.formComercial.logoApp ? this.state.formComercial.logoApp : ""
        var previewImagePropuesta = this.state.formComercial.logoPropuesta ? this.state.formComercial.logoPropuesta : ""

        if (this.state.userGroupId == 1000 || this.state.userGroupId == 500) {
            return (
                <>
                    <Header title={'Información Comercial'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>
                                            <Col md="12" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="ComercialsRef">
                                                        NOMBRE COMERCIAL
                                                    </Label>
                                                    <Input
                                                        id="nombreSearch"
                                                        placeholder="Ingresar nombre del Comercial"
                                                        name="nombreSearch"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.nombreSearch}
                                                        type="text"
                                                        disabled={this.state.inputNombreDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="12" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="apellidoSearch">
                                                        APELLIDOS
                                                    </Label>
                                                    <Input
                                                        id="apellidoSearch"
                                                        placeholder="Ingresar apellidos del Comercial"
                                                        name="apellidoSearch"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.apellidoSearch}
                                                        type="text"
                                                        disabled={this.state.inputApellidosDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cifSearch">
                                                        DNI/CIF
                                                    </Label>
                                                    <Input
                                                        id="cifSearch"
                                                        placeholder="Ingresar CIF del Comercial"
                                                        name="cifSearch"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.cifSearch}
                                                        type="text"
                                                        disabled={this.state.inputCifDisabled}
                                                    />
                                                    {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                                </FormGroup>
                                            </Col>
                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={this.buscarComercial}
                                                    disabled={this.state.buscarButtonDisabled}
                                                >
                                                    <i className={"bi bi-search"} /> Buscar
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE COMERCIALES"
                                                columns={columnsDatosComercial}
                                                data={this.state.ComercialDetailTotal}
                                                selectableRows
                                                //selectableRowsComponent={radio} // Pass the function only
                                                selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                                selectableRowsSingle
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                                onSelectedRowsChange={this.handleChange}
                                                clearSelectedRows={this.state.toggledClearRows}
                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight
                                                customStyles={customStyles}
                                                contextMessage={{ singular: 'Comercial', plural: 'Comercials', message: 'seleccionado' }}
                                                subHeader
                                                progressComponent={<Circular />}
                                            />
                                        </Card>
                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>

                        <Row className="margin-reset w-100">

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    LICENCIAS
                                                </CardTitle>
                                                <span className="h3 font-weight-bold mb-0">
                                                    {this.state.licenciasTotal.totalLicencias || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-files" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap">Licencias</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    ACTIVAS
                                                </CardTitle>

                                                <span className="h3 font-weight-bold mb-0">
                                                    {this.state.licenciasTotal.usuariosActivos || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-check2-circle" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Licencias Activas</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    LIBRES
                                                </CardTitle>
                                                <span className="h3 font-weight-bold mb-0">
                                                    {(this.state.licenciasTotal.totalLicencias - this.state.licenciasTotal.usuariosActivos) || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                    <i className="bi bi-clock-history" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Licencias disponibles</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>


                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>
                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">

                                                    <FormGroup>
                                                        <Col>
                                                            <Button disabled={this.state.updateComercialButtonDisabled} id="UpdateComercialProptooltip" color="danger" size="m" type="button" className="btn-icon btn-2"
                                                                onClick={() => { this.updateHandler(this.state.updateDataComercial) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="bi bi-person-lines-fill" style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">Actualizar</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="UpdateComercialProptooltip">
                                                                    MODIFICAR COMERCIAL EXISTENTE
                                                                </UncontrolledTooltip>
                                                            </Button>

                                                            <Button disabled={this.state.saveComercialButtonDisabled} id="guardarComercialProptooltip" color="info" size="m" type="button" className="btn-icon btn-2"
                                                                onClick={() => { this.saveHandler(this.state.saveDataComercial) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">Insertar</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="guardarComercialProptooltip">
                                                                    CREAR COMERCIAL NUEVO
                                                                </UncontrolledTooltip>
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>

                                                </Card>
                                            </Col>
                                        </Row>


                                        <div>
                                            <CardTitle>DATOS DEL COMERCIAL</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreComercial">
                                                        Nombre Comercial
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreComercial"
                                                        placeholder=""
                                                        name="nombreComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.nombreComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.nombreComercial == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.nombreComercial}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Comercial">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido1Comercial"
                                                        placeholder=""
                                                        name="apellido1Comercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.apellido1Comercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.apellido1Comercial == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.apellido1Comercial}</FormFeedback>

                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido2Comercial">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido2Comercial"
                                                        placeholder=""
                                                        name="apellido2Comercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.apellido2Comercial}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIFComercial">
                                                        NIF/CIF
                                                    </Label>
                                                    <Input
                                                        id="NIFComercial"
                                                        placeholder=""
                                                        name="NIFComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.NIFComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.NIFComercial == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.NIFComercial}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefonoComercial">
                                                        Teléfono Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoComercial"
                                                        placeholder=""
                                                        name="telefonoComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.telefonoComercial}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="correoComercial">
                                                        Correo Contacto
                                                    </Label>
                                                    <Input
                                                        id="correoComercial"
                                                        placeholder=""
                                                        name="correoComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.correoComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.correoComercial == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.correoComercial}</FormFeedback>

                                                </FormGroup>
                                            </Col>


                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comision1">
                                                        COMISION 1
                                                    </Label>
                                                    <Input
                                                        id="comision1"
                                                        placeholder=""
                                                        name="comision1"
                                                        step="0.001"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.comision1 || 0}
                                                        type="number"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comision2">
                                                        COMISION 2
                                                    </Label>
                                                    <Input
                                                        id="comision2"
                                                        placeholder=""
                                                        name="comision2"
                                                        step="0.001"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.comision2 || 0}
                                                        type="number"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="zonaGeograficaComercial">
                                                        ZONA GEOGRAFICA
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="zonaGeograficaComercial"
                                                        placeholder=""
                                                        name="zonaGeograficaComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.zonaGeograficaComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Responsable">
                                                        RESPONSABLE
                                                    </Label>
                                                    <Input
                                                        id="Responsable"
                                                        placeholder=""
                                                        name="Responsable"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.Responsable}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>



                                        <Row>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                                    </Label>

                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}

                                                    />

                                                </FormGroup>

                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="fechaBaja">
                                                        Fecha Baja Registro
                                                    </Label>

                                                    <DatePicker id="fechaBaja"
                                                        value={fechaBaja}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaBaja")}
                                                        instanceCount={2}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}

                                                    />


                                                </FormGroup>
                                            </Col>

                                        </Row>



                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>LOGOS</CardTitle>
                                        </div>

                                        <Row>

                                            <Col md="3" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipo">Logo Empresa</Label>
                                                    {previewImage && (
                                                        <div>
                                                            <img className="preview" src={previewImage} alt="" />
                                                        </div>
                                                    )}
                                                    <FormGroup>
                                                        <label htmlFor="upload-button" id="label-upload-button">
                                                            <span className="btn btn-sm btn-primary mt-3">
                                                                Subir logo
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={this.onAppLogoChange}
                                                            accept="image/*"
                                                        />
                                                    </FormGroup>

                                                </FormGroup>
                                            </Col>

                                            <Col md="3" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipo">Logo Propuesta</Label>
                                                    {previewImagePropuesta && (
                                                        <div>
                                                            <img className="preview" src={previewImagePropuesta} alt="" />
                                                        </div>
                                                    )}
                                                    <FormGroup>
                                                        <label htmlFor="upload-button-propuesta" id="label-upload-propuesta-button">
                                                            <span className="btn btn-sm btn-primary mt-3">
                                                                Subir logo
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="upload-button-propuesta"
                                                            style={{ display: "none" }}
                                                            onChange={this.onAppLogoChangePropuesta}
                                                            accept="image/*"
                                                        />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS ACCESO PLATAFORMA</CardTitle>
                                        </div>

                                        <Row>



                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="id">
                                                        ID
                                                    </Label>
                                                    <Input
                                                        id="id"
                                                        placeholder=""
                                                        name="id"
                                                        maxLength={12}
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.id}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="email">
                                                        Usuario Plataforma
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.email || ''}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="password">
                                                        Clave
                                                    </Label>
                                                    <Input
                                                        id="password"
                                                        placeholder=""
                                                        name="password"
                                                        maxLength={60}
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.password}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                        invalid={this.state.errores.password == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.password}</FormFeedback>

                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="query_limit">
                                                        Límite Consulta
                                                    </Label>
                                                    <Input
                                                        id="query_limit"
                                                        placeholder=""
                                                        name="query_limit"
                                                        maxLength={7}
                                                        value={formComercial.query_limit}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="user_group_id">Grupo Usuario</Label>
                                                    <Input type="select" name="user_group_id" id="user_group_id"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.user_group_id}
                                                        disabled={this.state.inputDisabled}>
                                                        <option selected>Selecciona...</option>
                                                        <option value={1000}>ADMINISTRADOR</option>
                                                        <option value={450}>GERENTE</option>
                                                        <option value={400}>JEFE DE EQUIPO</option>
                                                        <option value={300}>COMERCIAL NIVEL 1</option>
                                                        <option value={200}>COMERCIAL NIVEL 2</option>
                                                        <option value={100}>COMERCIAL NIVEL 3</option>
                                                        <option value={0}>SIN GRUPO</option>

                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="company">
                                                        Empresa
                                                    </Label>
                                                    <Input
                                                        id="company"
                                                        placeholder=""
                                                        name="company"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.company}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="companyId">
                                                        ID Emp.
                                                    </Label>
                                                    <Input
                                                        id="companyId"
                                                        placeholder=""
                                                        name="companyId"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.companyId}
                                                        type="text"
                                                        required
                                                        disabled={true}
                                                        invalid={this.state.errores.companyId == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.companyId}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="identificacionEmpresa">
                                                        NIF Empresa
                                                    </Label>
                                                    <Input
                                                        id="identificacionEmpresa"
                                                        placeholder=""
                                                        name="identificacionEmpresa"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.identificacionEmpresa || ""}
                                                        type="text"
                                                        required
                                                        disabled={true}
                                                        invalid={this.state.errores.identificacionEmpresa == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.identificacionEmpresa}</FormFeedback>

                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <Label className="form-control-label" htmlFor="identificacionEmpresa">
                                                    ACTIVO
                                                </Label>
                                                <FormGroup className="custom-control custom-checkbox">

                                                    <Input
                                                        className="custom-control-input"
                                                        id="esActivo"
                                                        name="esActivo"
                                                        onChange={this.onChangeFieldComercial}
                                                        checked={formComercial.esActivo}
                                                        type="checkbox"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                    <label className="custom-control-label" htmlFor="esActivo">
                                                        Usuario Activo S/N
                                                    </label>
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                    </CardBody>
                                </Card>



                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>PERFIL</CardTitle>
                                        </div>

                                        <Row>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="comercial"
                                                            name="comercial"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.comercial}
                                                            disabled={this.state.inputDisabledComercial}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Comercial </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="jefeEquipo"
                                                            name="jefeEquipo"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.jefeEquipo}
                                                            disabled={this.state.inputDisabledJefeEquipo}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Responsable Equipo </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="adFincas"
                                                            name="adFincas"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.adFincas}
                                                            disabled={this.state.inputDisabledAdFincas}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Administrado Fincas</label>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div>
                                            <CardTitle>TIPO EMPRESA</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="3">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="comercializadora"
                                                            name="comercializadora"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.comercializadora}
                                                            disabled={this.state.inputDisabledComercializadora}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Comercializadora</label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="agencia"
                                                            name="agencia"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.agencia}
                                                            disabled={this.state.inputDisabledAgencia}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Agencia</label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="empresa"
                                                            name="empresa"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.empresa}
                                                            disabled={this.state.inputDisabledEmpresa}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Otras</label>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div>
                                            <CardTitle>PERMISOS</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="electricidad"
                                                            name="electricidad"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.electricidad}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Electricidad </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="indexada"
                                                            name="indexada"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.indexada}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Indexada </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="mercadolibre"
                                                            name="mercadolibre"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.mercadolibre}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Libre </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="gas"
                                                            name="gas"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.gas}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Gas </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="mercadolibregas"
                                                            name="mercadolibregas"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.mercadolibregas}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Fijo Gas </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="informes"
                                                            name="informes"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.informes}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Informes </label>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="contratos"
                                                            name="contratos"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.contratos}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Contratos </label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="aprobarContratos"
                                                            name="aprobarContratos"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.aprobarContratos}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Aprobar Contratos </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="verContratos"
                                                            name="verContratos"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.verContratos}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Ver Contratos </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="productos"
                                                            name="productos"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.productos}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Productos </label>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="comisiones"
                                                            name="comisiones"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.comisiones}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Comisiones </label>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>

                                        <Row>
                                            <Col lg= "auto">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="eslogoApp"
                                                            name="eslogoApp"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.eslogoApp}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Cambiar logo APP </label>
                                                </FormGroup>
                                            </Col>

                                            <Col lg= "auto">
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Label className="custom-toggle custom-toggle-info">
                                                        <Input
                                                            id="eslogoPropuesta"
                                                            name="eslogoPropuesta"
                                                            onChange={this.onChangeFieldComercial}
                                                            checked={formComercial.eslogoPropuesta}
                                                            disabled={this.state.inputDisabled}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="SI" />
                                                    </Label>
                                                    <label style={{ alignItems: 'center', textAlign: "center", marginLeft: "5px", paddingTop: "5px" }}> Cambiar logo propuestas </label>
                                                </FormGroup>
                                            </Col>


                                        </Row>

                                    </CardBody>
                                </Card>
                                {this.state.alert}
                            </div>
                        </Row>
                    </Container >
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}


export default withApollo(Comercial);

import axios from 'axios';
import React from "react";
import Reflux from "reflux";
//import FormData from 'form-data';
// reactstrap components
import { CSVLink } from "react-csv";
import {
    Link
} from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, Container, Form, FormGroup, FormText, Input, Label, Row, UncontrolledTooltip } from "reactstrap";

// core components
import Header from "../../components/Headers/Header";
import moment from "moment";
import 'moment/locale/es';
import { withApollo } from "react-apollo";

import ReactBSAlert from "react-bootstrap-sweetalert";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import { Upload } from "@progress/kendo-react-upload";
import { UserStore } from "../../store/UserStore";
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "./es.json";
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(esMessages, "es-ES");

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const uploadRef = React.createRef<Upload>();

const columnsDatosComisionElectricidad = [
    { selector: row => row.nombreComision, name: 'NOMBRE OFERTA', sortable: true, width: '220px', format: (row => capitalize(row.nombreComision)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row.esActiva, name: 'ACTIVA', sortable: true, width: '100px', center: true },
    { selector: row => row.fechaAltaComision, name: 'ALTA COMISION', sortable: true, width: '200px', center: true },
    { selector: row => row.fechaBajaComision, name: 'BAJA COMISION', sortable: true, width: '200px', center: true },
    { selector: row => row.tipoComisionProducto, name: 'TIPO COMISION', sortable: true, width: '200px', center: true }

];


const columnsDatosComisionGas = [
    { selector: row => row.nombreComision, name: 'NOMBRE OFERTA', sortable: true, width: '220px', format: (row => capitalize(row.nombreComision)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row.esActiva, name: 'ACTIVA', sortable: true, width: '100px', center: true },
    { selector: row => row.fechaAltaComision, name: 'ALTA COMISION', sortable: true, width: '200px', center: true },
    { selector: row => row.fechaBajaComision, name: 'BAJA COMISION', sortable: true, width: '200px', center: true },
    { selector: row => row.tipoComisionProducto, name: 'TIPO COMISION', sortable: true, width: '200px', center: true }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class comision extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombrecomisionSearch: "",
            idSearch: "",
            cifSearch: "",
            tipoContratoSearch: "",
            codeComercializadora: "",
            tipoEstadoSearch: "",
            comisionHasta: "",
            comisionDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        comisionDetailTotal: [],
        comisionSeleccionado: false,
        idcomision: '',
        comisionDetail: {
            coIncluido: false,
            eurosMwh: false,
            companyId: 0,
            esActiva: false,
            id: "",
            idComercializadora: "",
            nombreComercializadora: "",
            nombreComision: "",
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            fechaAltaComision: '',
            fechaBajaComision: '',
            tipocomision: '',
            tipoProducto: "",
            comisionContratoNuevo: 0,
            comisionContratoRenovacion: 0,
            comisionConsumoAgente1: 0,
            comisionConsumoAgente2: 0,
            comisionConsumoAdmin: 0,
            comisionGOAgente1: 0,
            comisionGOAgente2: 0,
            energiaDesde: 0,
            energiaHasta: 0,
            potenciaDesde: 0,
            potenciaHasta: 0,
            nivel: "",
            importe: 0,
            idComision: 1,
            comisionTipoA: false,
            comisionTipoB: false,
            comisionTipoC: false,
            comisionTipoD: false,
            comisionTipoCOA: false,
            comisionTipoCOB: false,
            comisionTipoCOC: false,
            tipoComisionProducto: "",
            comisionProducto: [],
            tarifa: ""
        },
        formComision: {
            coIncluido: false,
            eurosMwh: false,
            companyId: 0,
            esActiva: false,
            id: "",
            idComercializadora: "",
            nombreComercializadora: "",
            nombreComision: "",
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            fechaAltaComision: '',
            fechaBajaComision: '',
            tipocomision: '',
            comisionContratoNuevo: 0,
            comisionContratoRenovacion: 0,
            comisionConsumoAgente1: 0,
            comisionConsumoAgente2: 0,
            comisionConsumoAdmin: 0,
            comisionGOAgente1: 0,
            comisionGOAgente2: 0,
            energiaDesde: 0,
            energiaHasta: 0,
            potenciaDesde: 0,
            potenciaHasta: 0,
            nivel: "",
            importe: 0,
            idComision: 1,
            comisionTipoA: false,
            comisionTipoB: false,
            comisionTipoC: false,
            comisionTipoD: false,
            comisionTipoCOA: false,
            comisionTipoCOB: false,
            comisionTipoCOC: false,
            tipoProducto: "",
            tipoComisionProducto: "",
            comisionProducto: [],
            tarifa: ""
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDatacomision: false,
        updateDataComision: false,
        savecomisionButtonDisabled: true,
        updateComisionButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFRepreError: false,
        inputDisabled: true,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreComisionDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: true,
        comisionDesde: '',
        comisionHasta: '',
        fechaAltaComision: '',
        fechaBajaComision: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        tipocomision: 'E',
        inputDisabledP3456: false,
        inputDisabledE456: false,
        alert: null,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        comisionsCargados: [],
        comisionProducto: [],
        comisionDisabled: false,
        comisionDisabledA: false,
        comisionDisabledB: false,
        comisionDisabledC: false,
        comisionDisabledD: false,
        comisionDisabledCOA: false,
        comisionDisabledCOB: false,
        comisionDisabledCOC: false,
        dataComisiones: [],
        editID: 1

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.inputEl = React.createRef();
    }


    UNSAFE_componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltrosTipoCOntrato = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Tienes que seleccionar el tipo de comision"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Seleccione el tipo de servicio, electricidad o gas, al que se asociará la comisión 
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el comision?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del modelo de comisión?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Comisión actualizada correctamente
                </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    onChangeFieldSearch = (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        if (event.target.name === 'tipoContratoSearch' && event.target.value === 'G') {
            filters['codeComercializadora'] = ""
        } else if (event.target.name === 'tipoContratoSearch' && event.target.value === 'E') {
            filters['codeComercializadora'] = ""
        }

        if (event.target.name === 'nombrecomisionSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreComisionDisabled: false,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'nombrecomisionSearch' && event.target.value.length > 0) {

            this.setState({
                inputCifDisabled: true,
                inputIdDisabled: true,
                buscarButtonDisabled: false
            })
        }

        if (event.target.name === 'idSearch' && event.target.value.length == 0) {
            this.setState({
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreComisionDisabled: false,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'idSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputNombreComisionDisabled: true,
                buscarButtonDisabled: false
            })
        }

        if (event.target.name == 'tipoContratoSearch' || event.target.name == 'codeComercializadora' || event.target.name == 'tipoEstadoSearch' || event.target.name == 'comisionDesde' || event.target.name == 'comisionHasta') {
            this.setState({
                buscarButtonDisabled: false,
                inputCifDisabled: false,
                inputNombreComisionDisabled: false,
                inputIdDisabled: false
            })
        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }
        filters[name] = value;
        this.setState({ filters });
    };

    onChangeFieldComision = (event) => {
        event.persist()

        var formData = { ...this.state.formComision };
        var comisionProducto: any = this.state.dataComisiones
        let comisionDisabledA = this.state.comisionDisabledA
        let comisionDisabledB = this.state.comisionDisabledB
        let comisionDisabledC = this.state.comisionDisabledC
        let comisionDisabledD = this.state.comisionDisabledD
        let comisionDisabledCOA = this.state.comisionDisabledCOA
        let comisionDisabledCOB = this.state.comisionDisabledCOB
        let comisionDisabledCOC = this.state.comisionDisabledCOC

        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
 
        const nameFilters = ['nombreComercializadora', 'nombreComision', 'nombreDocumentoContrato', 'TarifaDesc', 'companyId', 'esActiva', 'fechaAltaComision', 'fechaBajaComision', 'id', 'idComercializadora', 'tarifaATR', 'peaje', 'coIncluido', 'verde', 'facturacion', 'precioTEP1', 'precioTEP2', 'precioTEP3', 'precioTEP5', 'precioTEP4', 'precioTEP6', 'precioTP1', 'precioTP2', 'precioTP3',
            'precioTP4', 'precioTP5', 'precioTP6', 'precioTPPeriodo', 'precioTPTipo', 'periocidadRevisionPrecios', 'periodoValidezDesde', 'periodoValidezHasta', 'ofertaInternet', 'ofertaOficina', 'ofertaTel',
            'serviciosObligatorios', 'infoAdicional', 'caracteristicas', 'comisionContratoNuevo', 'comisionContratoRenovacion', 'comisionConsumoAgente1', 'comisionConsumoAgente2', 'comisionConsumoAdmin', 'comisionGOAgente1', 'comisionGOAgente2'];

        if (this.state.comisionSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataComision: true });
                this.setState({
                    savecomisionButtonDisabled: true,
                    updateComisionButtonDisabled: false
                })
            }

            if (event.target.name == 'idComercializadora') {
                //this.setState ({comisionDetail.nombreComision : event.target.value});
                this.setState({ updateDataComision: true });
                this.setState({
                    savecomisionButtonDisabled: true,
                    updateComisionButtonDisabled: false
                })
                const NombreComercializadora = event.target.selectedOptions[0].label

                formData['nombreComercializadora'] = NombreComercializadora;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formComision: formData });
            }

            if (event.target.name == 'tarifaATR') {
                //this.setState ({comisionDetail.nombreComision : event.target.value});
                this.setState({ updateDataComision: true });
                this.setState({
                    savecomisionButtonDisabled: true,
                    updateComisionButtonDisabled: false
                })
                const NombreTarifa = event.target.selectedOptions[0].label

                if (event.target.name == 'tarifaATR' && event.target.value == '018') {
                    this.setState({
                        inputDisabledP3456: true,
                        inputDisabledE456: true
                    })
                } else if (event.target.name == 'tarifaATR' && event.target.value != '018') {
                    this.setState({
                        inputDisabledP3456: false,
                        inputDisabledE456: false
                    })
                }

                formData['TarifaDesc'] = NombreTarifa;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formComision: formData });
            }

            if (event.target.name == "comisionTipoA") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "A"
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else {
                    comisionDisabledB = false
                    comisionDisabledC = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoB") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "B"
                    comisionDisabledA = true
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else {
                    comisionDisabledA = false
                    comisionDisabledC = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoC") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "C"
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledD = true
                } else {
                    comisionDisabledA = false
                    comisionDisabledB = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoD") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "D"
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = true
                } else {
                    comisionDisabledA = false
                    comisionDisabledB = false
                    comisionDisabledC = false
                    comisionProducto = []
                }
            };

            if (event.target.name == "comisionTipoCOA") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "E"
                    comisionDisabledCOB = true
                    comisionDisabledCOC = true
                } else {
                    comisionDisabledCOB = false
                    comisionDisabledCOC = false
                    comisionProducto = [{
                        id: "",
                        comisionID: 1,
                        energiaDesde: 0,
                        energiaHasta: 0,
                        coReferencia: 0,
                        coReferenciaPorcentaje: 0,
                        coStandard: 0,
                        coStandardPorcentaje: 0
                    }]
                }
            } else if (event.target.name == "comisionTipoCOB") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "F"
                    comisionDisabledCOA = true
                    comisionDisabledCOC = true

                } else {
                    comisionDisabledCOA = false
                    comisionDisabledCOC = false
                    comisionProducto = [{
                        id: "",
                        comisionID: 1,
                        energiaDesde: 0,
                        energiaHasta: 0,
                        co1: 0,
                        co1Porcentaje: 0,
                        co2: 0,
                        co2Porcentaje: 0,
                        co3: 0,
                        co3Porcentaje: 0,
                        co4: 0,
                        co4Porcentaje: 0,
                        co5: 0,
                        co5Porcentaje: 0,
                        co6: 0,
                        co6Porcentaje: 0
                    }]
                }
            } else if (event.target.name == "comisionTipoCOC") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "G"
                    comisionDisabledCOA = true
                    comisionDisabledCOB = true
                } else {
                    comisionDisabledCOA = false
                    comisionDisabledCOB = false
                    comisionProducto = []
                }
            };

        } else {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDatacomision: true });
                this.setState({
                    savecomisionButtonDisabled: false,
                    updateComisionButtonDisabled: true
                })
            }

            if (event.target.name == 'idComercializadora') {
                //this.setState ({comisionDetail.nombreComision : event.target.value});
                this.setState({ saveDatacomision: true });
                this.setState({
                    savecomisionButtonDisabled: false,
                    updateComisionButtonDisabled: true
                })
                const NombreComercializadora = event.target.selectedOptions[0].label

                formData['nombreComercializadora'] = NombreComercializadora;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formComision: formData });
            }

            if (event.target.name == 'tarifaATR') {
                //this.setState ({comisionDetail.nombreComision : event.target.value});
                this.setState({ saveDatacomision: true });
                this.setState({
                    savecomisionButtonDisabled: false,
                    updateComisionButtonDisabled: true
                })
                const NombreTarifa = event.target.selectedOptions[0].label

                if (event.target.name == 'tarifaATR' && event.target.value == '018') {
                    this.setState({
                        inputDisabledP3456: true,
                        inputDisabledE456: true
                    })
                } else if (event.target.name == 'tarifaATR' && event.target.value != '018') {
                    this.setState({
                        inputDisabledP3456: false,
                        inputDisabledE456: false
                    })
                }

                formData['TarifaDesc'] = NombreTarifa;
            }
            if (event.target.name == "comisionTipoA") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "A"
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else {
                    comisionDisabledB = false
                    comisionDisabledC = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoB") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "B"
                    comisionDisabledA = true
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else {
                    comisionDisabledA = false
                    comisionDisabledC = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoC") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "C"
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledD = true
                } else {
                    comisionDisabledA = false
                    comisionDisabledB = false
                    comisionDisabledD = false
                    comisionProducto = []
                }
            } else if (event.target.name == "comisionTipoD") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "D"
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = true
                } else {
                    comisionDisabledA = false
                    comisionDisabledB = false
                    comisionDisabledC = false
                    comisionProducto = []
                }
            };

            if (event.target.name == "comisionTipoCOA") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "E"
                    comisionDisabledCOB = true
                    comisionDisabledCOC = true
                } else {
                    comisionDisabledCOB = false
                    comisionDisabledCOC = false
                    comisionProducto = [{
                        id: "",
                        comisionID: 1,
                        energiaDesde: 0,
                        energiaHasta: 0,
                        coReferencia: 0,
                        coReferenciaPorcentaje: 0,
                        coStandard: 0,
                        coStandardPorcentaje: 0
                    }]
                }
            } else if (event.target.name == "comisionTipoCOB") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "F"
                    comisionDisabledCOA = true
                    comisionDisabledCOC = true
                } else {
                    comisionDisabledCOA = false
                    comisionDisabledCOC = false
                    comisionProducto = [{
                        id: "",
                        comisionID: 1,
                        energiaDesde: 0,
                        energiaHasta: 0,
                        co1: 0,
                        co1Porcentaje: 0,
                        co2: 0,
                        co2Porcentaje: 0,
                        co3: 0,
                        co3Porcentaje: 0,
                        co4: 0,
                        co4Porcentaje: 0,
                        co5: 0,
                        co5Porcentaje: 0,
                        co6: 0,
                        co6Porcentaje: 0
                    }]
                }
            } else if (event.target.name == "comisionTipoCOC") {
                if (event.target.checked == true) {
                    formData['tipoComisionProducto'] = "G"
                    comisionDisabledCOA = true
                    comisionDisabledCOB = true
                } else {
                    comisionDisabledCOA = false
                    comisionDisabledCOB = false
                    comisionProducto = []
                }
            };

            //if (value === "" || value === null) {
            //    formData[name] = 0;
            //}
            this.setState({ formComision: formData });
        };

        if (event.target.name == "tarifa" && value != "0") {
            comisionDisabledA = false
            comisionDisabledB = false
            comisionDisabledC = false
            comisionDisabledD = false
        } else if (event.target.name == "tarifa" && value === "0") {
            comisionDisabledA = true
            comisionDisabledB = true
            comisionDisabledC = true
            comisionDisabledD = true
        }

        if (event.target.type === "select-one" && value === "0") {
            value = ""
        }

        if (value == 'Selecciona...') {
            value = ""
        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({
            formComision: formData,
            comisionDisabledA: comisionDisabledA,
            comisionDisabledB: comisionDisabledB,
            comisionDisabledC: comisionDisabledC,
            comisionDisabledD: comisionDisabledD,
            comisionDisabledCOA: comisionDisabledCOA,
            comisionDisabledCOB: comisionDisabledCOB,
            comisionDisabledCOC: comisionDisabledCOC,
            dataComisiones: comisionProducto
        });
    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };



    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                nombrecomisionSearch: '',
                cifSearch: '',
                idSearch: ''
            },
            inputCifDisabled: false,
            inputNombreComisionDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            comisionSeleccionado: false,
            comisionDetailTotal: [],
            formComision: {
                coIncluido: false,
                companyId: 0,
                esActiva: false,
                id: "",
                idComercializadora: "",
                nombreComercializadora: "",
                nombreComision: "",
                periocidadRevisionPrecios: "",
                periodoValidez: "",
                periodoValidezDesde: "",
                periodoValidezHasta: "",
                fechaAltaComision: '',
                fechaBajaComision: '',
                tipocomision: '',
                comisionContratoNuevo: 0,
                comisionContratoRenovacion: 0,
                comisionConsumoAgente1: 0,
                comisionConsumoAgente2: 0,
                comisionConsumoAdmin: 0,
                comisionGOAgente1: 0,
                comisionGOAgente2: 0,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                nivel: "",
                importe: 0,
                idComision: 1,
                comisionTipoA: false,
                comisionTipoB: false,
                comisionTipoC: false,
                comisionTipoD: false,
                comisionTipoCOA: false,
                comisionTipoCOB: false,
                comisionTipoCOC: false,
                tipoProducto: "",
                tipoComisionProducto: "",
                comisionProducto: [],
                tarifa: ""

            },
            toggledClearRows: !this.state.toggledClearRows,
            savecomisionButtonDisabled: true,
            updateComisionButtonDisabled: true,
            fechaAltaComision: '',
            fechaBajaComision: '',
            inputDisabledP3456: false,
            inputDisabledE456: false,
            comisionsCargados: [],
            comisionProducto: [],
            comisionDisabled: false,
            comisionDisabledA: false,
            comisionDisabledB: false,
            comisionDisabledC: false,
            comisionDisabledD: false,
            comisionDisabledCOA: false,
            comisionDisabledCOB: false,
            comisionDisabledCOC: false
        });
        this.formRef.current.reset();
    };

    buscarComision = async () => {

        if(!this.state.filters.tipoContratoSearch || this.state.filters.tipoContratoSearch =="" || this.state.filters.tipoContratoSearch.length == 0){

            this.warningAlertFiltrosTipoCOntrato()


        } else {

        var idComercializadoraAux = ''
        if (this.state.filters.codeComercializadora != '0' && this.state.filters.codeComercializadora != '') {
            idComercializadoraAux = this.state.filters.codeComercializadora
        }

        var dataFilters = {
            'companyId': this.state.companyIdUser,
            'idComercializadora': idComercializadoraAux,
            'id': this.state.filters.idSearch,
            'nombreComision': this.state.filters.nombrecomisionSearch,
            'tipoContrato': this.state.filters.tipoContratoSearch,
            'esActiva': this.state.filters.tipoEstadoSearch,
            'periodoValidezDesde': this.state.filters.comisionDesde,
            'periodoValidezHasta': this.state.filters.comisionHasta
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/comision/listacomisiones?id=" + this.state.companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows

                    this.setState({ comisionDetailTotal: data });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                console.log('Failure')
                this.warningAlert();
            });

        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });
            const reftp = document.getElementById("tablaLecturas")
            reftp?.scrollIntoView({ behavior: "smooth" })
        } else {
            this.setState({
                show: false
            });
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.updateDataComision && (this.state.formComision.nombreComision && this.state.formComision.nombreComercializadora)) {

            this.confirmAlertUpdate()

        } else {

            this.warningAlertFiltros()
        }

    };

    onUpdate = async () => {
        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser.toString();
        const userId = this.state.userId.toString();

        if (this.state.updateDataComision == true) {

            var fechaAltaComision = this.state.fechaAltaComision;
            if (!fechaAltaComision) {
                fechaAltaComision = moment().format("YYYY-MM-DDTHH:mm:ss");
            };

            var fechaBajaComision = this.state.fechaBajaComision;

            if (!fechaBajaComision) {

                fechaBajaComision = ''
            }

            var comisionDataUpdate = {}

            if (this.state.formComision.tipoProducto == 'L' || this.state.formComision.tipoProducto == 'E') {

                comisionDataUpdate = {
                    'coIncluido': this.state.formComision.coIncluido,
                    'eurosMwh': this.state.formComision.eurosMwh,
                    'companyId': this.state.companyIdUser,
                    'esActiva': this.state.formComision.esActiva,
                    'id': this.state.formComision.id,
                    'idComercializadora': this.state.formComision.idComercializadora,
                    'nombreComercializadora': this.state.formComision.nombreComercializadora.toUpperCase(),
                    'nombreComision': this.state.formComision.nombreComision.toUpperCase(),
                    'periocidadRevisionPrecios': this.state.formComision.periocidadRevisionPrecios,
                    'periodoValidez': this.state.formComision.periodoValidez,
                    'periodoValidezDesde': this.state.formComision.periodoValidezDesde,
                    'periodoValidezHasta': this.state.formComision.periodoValidezHasta,
                    'fechaAltaComision': fechaAltaComision,
                    'fechaBajaComision': fechaBajaComision,
                    'tipoProducto': this.state.formComision.tipoProducto,
                    'comisionContratoNuevo': this.state.formComision.comisionContratoNuevo,
                    'comisionContratoRenovacion': this.state.formComision.comisionContratoRenovacion,
                    'comisionConsumoAgente1': this.state.formComision.comisionConsumoAgente1,
                    'comisionConsumoAgente2': this.state.formComision.comisionConsumoAgente2,
                    'comisionConsumoAdmin': this.state.formComision.comisionConsumoAdmin,
                    'comisionGOAgente1': this.state.formComision.comisionGOAgente1,
                    'comisionGOAgente2': this.state.formComision.comisionGOAgente2,
                    'tipoComisionProducto': this.state.formComision.tipoComisionProducto,
                    'comisionProducto': this.state.dataComisiones,
                    'tarifa': this.state.formComision.tarifa

                };
            } else if (this.state.formComision.tipoProducto == 'G') {

                comisionDataUpdate = {
                    'coIncluido': this.state.formComision.coIncluido,
                    'eurosMwh': this.state.formComision.eurosMwh,
                    'companyId': this.state.companyIdUser,
                    'esActiva': this.state.formComision.esActiva,
                    'id': '',
                    'idComercializadora': this.state.formComision.idComercializadora,
                    'nombreComercializadora': this.state.formComision.nombreComercializadora.toUpperCase(),
                    'nombreComision': this.state.formComision.nombreComision.toUpperCase(),
                    'periocidadRevisionPrecios': this.state.formComision.periocidadRevisionPrecios,
                    'periodoValidez': this.state.formComision.periodoValidez,
                    'periodoValidezDesde': this.state.formComision.periodoValidezDesde,
                    'periodoValidezHasta': this.state.formComision.periodoValidezHasta,
                    'fechaAltaComision': fechaAltaComision,
                    'fechaBajaComision': fechaBajaComision,
                    'tipoProducto': this.state.formComision.tipoProducto,
                    'comisionContratoNuevo': this.state.formComision.comisionContratoNuevo,
                    'comisionContratoRenovacion': this.state.formComision.comisionContratoRenovacion,
                    'comisionConsumoAgente1': this.state.formComision.comisionConsumoAgente1,
                    'comisionConsumoAgente2': this.state.formComision.comisionConsumoAgente2,
                    'comisionConsumoAdmin': this.state.formComision.comisionConsumoAdmin,
                    'comisionGOAgente1': this.state.formComision.comisionGOAgente1,
                    'comisionGOAgente2': this.state.formComision.comisionGOAgente2,
                    'tipoComisionProducto': this.state.formComision.tipoComisionProducto,
                    'comisionProducto': this.state.dataComisiones,
                    'tarifa': this.state.formComision.tarifa

                };
            }

            //const fechaActivacion = this.state.fechaActivacion;
            const companyId = this.state.companyIdUser.toString();
            const userId = this.state.userId.toString();
            const idContrato = this.state.contrato;
            var resultadoUpdate: any = [];
            var resultadoView: any = [];

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/comision/update?id=" + this.state.companyIdUser + "&user=" + userId;


            axios.post(direccionFile, comisionDataUpdate, { headers, responseType: 'blob' })
                .then(res => {
                    console.log('Actualización correcta', res)
                    console.log('Dapos del registro: ', res.data)
                    this.successAlert();
                    this.clearForm();
                }).catch(error => {
                    console.log('Failure')
                    console.log('Error en el proceso: ', error)
                    this.warningAlert();
                });

            this.setState({
                updateDataComision: false,
                updateComisionButtonDisabled: true
            });
            await this.buscarComision()
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////

    onSave = async () => {
    };


    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formComision };

        const name = id;
        var valueDay = formattedValue

        if (name == 'comisionDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ comisionDesde: value });
        }

        if (name == 'comisionHasta') {
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ comisionHasta: value });
        }

        if (name == 'fechaAltaComision') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ fechaAltaComision: value });
        }

        if (name == 'fechaBajaComision') {
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ fechaBajaComision: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ periodoValidezDesde: value });
        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({comisionDetail.nombreComision : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }

        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formComision: formData });
    };

    //////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var comision = '';

        if (state.selectedCount === 1) {
            var existeComision = ''
            comision = state.selectedRows[0].id;
            this.setState({ comisionDetail: state.selectedRows[0] });
            const hora = 6;
            const minutos = 30;

            var existeFechaAlta = state.selectedRows[0].fechaAltaComision
            var FechaAltaAux = ''
            var existeFechaBaja = state.selectedRows[0].fechaBajaComision
            var FechaBajaAux = ''

            existeComision = state.selectedRows[0].id

            this.setState({
                fechaAltaComision: state.selectedRows[0].fechaAltaComision
            });

            if (existeFechaAlta && existeFechaBaja) {
                this.setState({
                    inputDisabled: true,
                    updateComisionButtonDisabled: false
                })
            } else if ((!existeFechaAlta && !existeFechaBaja) || (existeFechaAlta && !existeFechaBaja)) {
                this.setState({
                    inputDisabled: false,
                    updateComisionButtonDisabled: true
                })
            }

            if (existeComision) {

                let tipoComisionProducto = state.selectedRows[0].tipoComisionProducto
                let comisionProducto = state.selectedRows[0].comisionProducto

                if (tipoComisionProducto == "") {
                    tipoComisionProducto = ""
                }

                if (comisionProducto == null) {
                    comisionProducto = []
                };

                let comisionDisabledA = false
                let comisionDisabledB = false
                let comisionDisabledC = false
                let comisionDisabledD = false
                let comisionDisabledCOA = false
                let comisionDisabledCOB = false
                let comisionDisabledCOC = false

                let comisionTipoA = false
                let comisionTipoB = false
                let comisionTipoC = false
                let comisionTipoD = false
                let comisionTipoCOA = false
                let comisionTipoCOB = false
                let comisionTipoCOC = false


                if (tipoComisionProducto == "A") {
                    comisionTipoA = true
                    comisionDisabledA = false
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = true
                } else if (tipoComisionProducto == "B") {
                    comisionTipoB = true
                    comisionDisabledA = true
                    comisionDisabledB = false
                    comisionDisabledC = true
                    comisionDisabledD = true
                } else if (tipoComisionProducto == "C") {
                    comisionTipoC = true
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = false
                    comisionDisabledD = true
                } else if (tipoComisionProducto == "D") {
                    comisionTipoC = true
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = false
                } else if (tipoComisionProducto == "E") {
                    comisionTipoCOA = true
                    comisionDisabledCOA = false
                    comisionDisabledCOB = true
                    comisionDisabledCOC = true

                } else if (tipoComisionProducto == "F") {
                    comisionTipoCOB = true
                    comisionDisabledCOA = true
                    comisionDisabledCOB = false
                    comisionDisabledCOC = true
                } else if (tipoComisionProducto == "G") {
                    comisionTipoCOC = true
                    comisionDisabledCOA = true
                    comisionDisabledCOB = true
                    comisionDisabledCOC = false
                };

                this.setState({ idcomision: this.state.comisionDetail.id });
                this.setState({
                    formComision: {
                        coIncluido: state.selectedRows[0].coIncluido,
                        companyId: state.selectedRows[0].companyId,
                        esActiva: state.selectedRows[0].esActiva,
                        id: state.selectedRows[0].id,
                        idComercializadora: state.selectedRows[0].idComercializadora,
                        nombreComercializadora: state.selectedRows[0].nombreComercializadora,
                        nombreComision: state.selectedRows[0].nombreComision,
                        periocidadRevisionPrecios: state.selectedRows[0].periocidadRevisionPrecios,
                        periodoValidez: state.selectedRows[0].periodoValidez,
                        periodoValidezDesde: state.selectedRows[0].periodoValidezDesde,
                        periodoValidezHasta: state.selectedRows[0].periodoValidezHasta,
                        fechaAltaComision: state.selectedRows[0].fechaAltaComision,
                        fechaBajaComision: state.selectedRows[0].fechaBajaComision,
                        tipoProducto: state.selectedRows[0].tipoProducto,
                        comisionContratoNuevo: state.selectedRows[0].comisionContratoNuevo ? state.selectedRows[0].comisionContratoNuevo : "0",
                        comisionContratoRenovacion: state.selectedRows[0].comisionContratoRenovacion ? state.selectedRows[0].comisionContratoRenovacion : "0",
                        comisionConsumoAgente1: state.selectedRows[0].comisionConsumoAgente1 ? state.selectedRows[0].comisionConsumoAgente1 : "0",
                        comisionConsumoAgente2: state.selectedRows[0].comisionConsumoAgente2 ? state.selectedRows[0].comisionConsumoAgente2 : "0",
                        comisionConsumoAdmin: state.selectedRows[0].comisionConsumoAdmin ? state.selectedRows[0].comisionConsumoAdmin : "0",
                        comisionGOAgente1: state.selectedRows[0].comisionGOAgente1 ? state.selectedRows[0].comisionGOAgente1 : "0",
                        comisionGOAgente2: state.selectedRows[0].comisionGOAgente2 ? state.selectedRows[0].comisionGOAgente2 : "0",
                        comisionTipoA: comisionTipoA,
                        comisionTipoB: comisionTipoB,
                        comisionTipoC: comisionTipoC,
                        comisionTipoD: comisionTipoD,
                        comisionTipoCOA: comisionTipoCOA,
                        comisionTipoCOB: comisionTipoCOB,
                        comisionTipoCOC: comisionTipoCOC,
                        tipoComisionProducto: tipoComisionProducto,
                        comisionProducto: comisionProducto,
                        tarifa: state.selectedRows[0].tarifa ? state.selectedRows[0].tarifa : "0",
                    },
                    editID: state.selectedRows[0].id,
                    comisionSeleccionado: true,
                    dataComisiones: comisionProducto,
                    comisionDisabledA: comisionDisabledA,
                    comisionDisabledB: comisionDisabledB,
                    comisionDisabledC: comisionDisabledC,
                    comisionDisabledD: comisionDisabledD,
                    comisionDisabledCOA: comisionDisabledCOA,
                    comisionDisabledCOB: comisionDisabledCOB,
                    comisionDisabledCOC: comisionDisabledCOC,
                });

            } else {
                this.setState({
                    comisionSeleccionado: false,
                    comisionProducto: [],
                    comisionDisabledA: false,
                    comisionDisabledB: false,
                    comisionDisabledC: false,
                    comisionTipoD: false,
                    comisionDisabledCOA: false,
                    comisionDisabledCOB: false,
                    comisionDisabledCOC: false,
                    dataComisiones: [],
                    editID: 1,
                    formComision: {
                        coIncluido: false,
                        companyId: 0,
                        esActiva: false,
                        id: "",
                        idComercializadora: "",
                        nombreComercializadora: "",
                        nombreComision: "",
                        periocidadRevisionPrecios: "",
                        periodoValidez: "",
                        periodoValidezDesde: "",
                        periodoValidezHasta: "",
                        fechaAltaComision: '',
                        fechaBajaComision: '',
                        tipoProducto: '',
                        comisionContratoNuevo: 0,
                        comisionContratoRenovacion: 0,
                        comisionConsumoAgente1: 0,
                        comisionConsumoAgente2: 0,
                        comisionConsumoAdmin: 0,
                        comisionGOAgente1: 0,
                        comisionGOAgente2: 0,
                        comisionTipoA: false,
                        comisionTipoB: false,
                        comisionTipoC: false,
                        comisionTipoD: false,
                        comisionTipoCOA: false,
                        comisionTipoCOB: false,
                        comisionTipoCOC: false,
                        tipoComisionProducto: "",
                        comisionProducto: [],
                        tarifa: ""
                    }
                });
            }
        } else {

            this.setState({
                comisionSeleccionado: false,
                inputDisabled: false,
                updateComisionButtonDisabled: true,
                comisionProducto: [],
                comisionDisabledA: false,
                comisionDisabledB: false,
                comisionDisabledC: false,
                comisionTipoD: false,
                comisionDisabledCOA: false,
                comisionDisabledCOB: false,
                comisionDisabledCOC: false,
                dataComisiones: [],
                editID: 1,
                formComision: {
                    coIncluido: false,
                    companyId: 0,
                    esActiva: false,
                    id: "",
                    idComercializadora: "",
                    nombreComercializadora: "",
                    nombreComision: "",
                    periocidadRevisionPrecios: "",
                    periodoValidez: "",
                    periodoValidezDesde: "",
                    periodoValidezHasta: "",
                    fechaAltaComision: '',
                    fechaBajaComision: '',
                    tipoProducto: '',
                    comisionContratoNuevo: 0,
                    comisionContratoRenovacion: 0,
                    comisionConsumoAgente1: 0,
                    comisionConsumoAgente2: 0,
                    comisionConsumoAdmin: 0,
                    comisionGOAgente1: 0,
                    comisionGOAgente2: 0,
                    comisionTipoA: false,
                    comisionTipoB: false,
                    comisionTipoC: false,
                    comisionTipoD: false,
                    comisionTipoCOA: false,
                    comisionTipoCOB: false,
                    comisionTipoCOC: false,
                    tipoComisionProducto: "",
                    comisionProducto: [],
                    tarifa: ""
                }
            });
        };
    };

    ///////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    ////////////////////////////////////////////////////////////////////////////////////

    rowClick = (event) => {
        this.setState({
            editID: event.dataItem.comisionID,
        });
    };
    itemChange = (event) => {
        const field = event.field || "";
        const inEditID = event.dataItem.comisionID;
        const data = this.state.dataComisiones.map((item: any) =>
            item.comisionID === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        this.setState({
            dataComisiones: data,
            saveDatacomision: true,
            savecomisionButtonDisabled: false,
            updateComisionButtonDisabled: false,
            updateDataComision: true
        });
    };

    closeEdit = (event) => {
        if (event.target === event.currentTarget) {
            this.setState({
                editID: "",
            });
        }
    };

    addRecord = (tipoComision) => {
        let tipoComisionTabla = tipoComision
        const data = this.state.dataComisiones;
        var newRecord: any = { comisionID: 1 };


        if (tipoComisionTabla == "A") {
            newRecord = {
                id: "",
                tarifa: this.state.formComision.tarifa ? this.state.formComision.tarifa : "000",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                modoTarifa1: "TE1",
                coTarifa1: 0,
                modoTarifa2: "TE2",
                coTarifa2: 0,
                modoTarifa3: "TE3",
                coTarifa3: 0,
                modoTarifa4: "TE4",
                coTarifa4: 0,
                modoTarifa5: "TE5",
                coTarifa5: 0,

            };
        } else if (tipoComisionTabla == "B") {
            newRecord = {
                id: "",
                tarifa: this.state.formComision.tarifa ? this.state.formComision.tarifa : "000",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                coTarifa: 0
            };
        } else if (tipoComisionTabla == "C") {
            newRecord = {
                id: "",
                tarifa: this.state.formComision.tarifa ? this.state.formComision.tarifa : "000",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                modoTarifa1: "TE1",
                coTarifa1: 0,
                modoTarifa2: "TE2",
                coTarifa2: 0,
                modoTarifa3: "TE3",
                coTarifa3: 0,
                modoTarifa4: "TE4",
                coTarifa4: 0,
                modoTarifa5: "TE5",
                coTarifa5: 0,
                zona: "ZAR"

            };
        } else if (tipoComisionTabla == "D") {
            newRecord = {
                id: "",
                tarifa: this.state.formComision.tarifa ? this.state.formComision.tarifa : "000",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                modoTarifa1: "TE1",
                coTarifa1: 0,
                modoTarifa2: "TE2",
                coTarifa2: 0,
                modoTarifa3: "TE3",
                coTarifa3: 0,
                modoTarifa4: "TE4",
                coTarifa4: 0,
                modoTarifa5: "TE5",
                coTarifa5: 0
            };
        } else if (tipoComisionTabla == "E") {
            newRecord = {
                id: "",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                coReferencia: 0,
                coReferenciaPorcentaje: 0,
                coStandard: 0,
                coStandardPorcentaje: 0
            };
        } else if (tipoComisionTabla == "F") {

            newRecord = {
                id: "",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                co1: 0,
                porcentajeco1: 0,
                co2: 0,
                porcentajeco2: 0,
                co3: 0,
                porcentajeco3: 0,
                co4: 0,
                porcentajeco4: 0,
                co5: 0,
                porcentajeco5: 0,
                co6: 0,
                porcentajeco6: 0
            };

        } else if (tipoComisionTabla == "G") {

            newRecord = {
                id: "",
                comisionID: data.length + 1,
                energiaDesde: 0,
                energiaHasta: 0,
                coReferenciaMax: 0,
                coReferenciaPorcentaje: 0
            };

        }

        this.setState({
            dataComisiones: [newRecord, ...data],
            editID: newRecord.comisionID,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////

    tipoContrato = (props) => {
        const tipoContrato = props.tipoContrato;
        if (this.state.esAgencia == true || this.state.esAdFincas == true) {
            if (tipoContrato === 'L' || tipoContrato === 'E') {
                return (
                    <React.Fragment>
                        <Col md="12" lg="4">
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="codigoComercializadora"
                                >
                                    Comercializadora
                                </Label>
                                <SipsOption
                                    placeholder="Seleccionar comercializadora"
                                    id="codeComercializadora"
                                    name="codeComercializadora"
                                    value={this.state.filters.codeComercializadora}
                                    query="LIST_MARKETERS"
                                    onChange={this.onChangeFieldSearch}
                                />
                            </FormGroup>
                        </Col>
                    </React.Fragment>
                )

            } else {
                return (
                    <React.Fragment>
                        <Col md="12" lg="4">
                            <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="codigoComercializadora"
                                >
                                    Comercializadora
                                </Label>
                                <SipsOption
                                    placeholder="Seleccionar comercializadora"
                                    id="codeComercializadora"
                                    name="codeComercializadora"
                                    value={this.state.filters.codeComercializadora}
                                    query="LIST_MARKETERS_GAS"
                                    onChange={this.onChangeFieldSearch}
                                />
                            </FormGroup>
                        </Col>
                    </React.Fragment>
                )

            }
        } else {
            return (
                <React.Fragment>

                </React.Fragment>
            )
        }
    };

    /////////////////////////////////////////////////////////////////////////////////

    tipoLecturaTable = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>

                    <Card className="shadow">
                        <DataTable
                            title="LISTADO DE COMISIONES GAS"
                            columns={columnsDatosComisionGas}
                            data={this.state.comisionDetailTotal}
                            selectableRows
                            //selectableRowsComponent={radio} // Pass the function only
                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                            selectableRowsSingle
                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                            noDataComponent={"Sin datos para mostrar"}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                            onSelectedRowsChange={this.handleChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            highlightOnHover
                            striped
                            pointerOnHover
                            selectableRowsHighlight
                            customStyles={customStyles}
                            contextMessage={{ singular: 'comision', plural: 'comisions', message: 'seleccionado' }}
                            subHeader
                            subHeaderComponent={
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <CSVLink
                                        data={this.state.comisionDetailTotal}
                                        filename={"ListadocomisionsGasDetalle.csv"}
                                        className="btn btn-default btn-sm btn-circle"
                                        color="default"
                                        target="_blank"
                                        separator={";"}
                                    >

                                        <span className="btn-inner--icon">
                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                        </span>
                                        <span className="btn-inner--text">Fichero CSV</span>

                                    </CSVLink>
                                </div>


                            }
                            progressComponent={<Circular />}
                        />
                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="shadow">
                        <DataTable
                            title="LISTADO DE COMISIONES ELECTRICIDAD"
                            columns={columnsDatosComisionElectricidad}
                            data={this.state.comisionDetailTotal}
                            selectableRows
                            //selectableRowsComponent={radio} // Pass the function only
                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                            selectableRowsSingle
                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                            noDataComponent={"Sin datos para mostrar"}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                            onSelectedRowsChange={this.handleChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            highlightOnHover
                            striped
                            pointerOnHover
                            selectableRowsHighlight
                            customStyles={customStyles}
                            contextMessage={{ singular: 'comision', plural: 'comisions', message: 'seleccionado' }}
                            subHeader
                            subHeaderComponent={
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <CSVLink
                                        data={this.state.comisionDetailTotal}
                                        filename={"ListadocomisionsElectricidadDetalle.csv"}
                                        className="btn btn-default btn-sm btn-circle"
                                        color="default"
                                        target="_blank"
                                        separator={";"}
                                    >

                                        <span className="btn-inner--icon">
                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                        </span>
                                        <span className="btn-inner--text">Fichero CSV</span>

                                    </CSVLink>
                                </div>

                            }
                            progressComponent={<Circular />}
                        />
                    </Card>

                </React.Fragment>

            )

        }
    };

    /////////////////////////////////////////////////////////////////////////////////


    //<this.comision tipoComision={formComision.coIncluido} />
    comision = (props) => {
        const tipoComision = props.tipoComision;
        const formComision: any = this.state.formComision;

        if (tipoComision === false) {
            return (

                <React.Fragment>

                    <Card className="shadow">

                        <CardBody>
                            <div>
                                <CardTitle>TIPOS DE COMISIONES CON PRODUCTOS CON COSTE OPERATIVOS</CardTitle>
                            </div>
                            <Row>
                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">

                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoCOA"
                                            name="comisionTipoCOA"
                                            checked={formComision.comisionTipoCOA}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledCOA}

                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoCOA">
                                            COMISIÓN CO TIPO A. DOS NIVELES DE COMISIONES
                                        </label>
                                    </FormGroup>
                                </Col>

                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">

                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoCOB"
                                            name="comisionTipoCOB"
                                            checked={formComision.comisionTipoCOB}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledCOB}
                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoCOB">
                                            COMISIÓN CO TIPO B. + DOS NIVELES DE COMISIONES
                                        </label>
                                    </FormGroup>
                                </Col>

                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">
                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoCOC"
                                            name="comisionTipoCOC"
                                            checked={formComision.comisionTipoCOC}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledCOC}
                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoCOC">
                                            COMISIÓN TIPO C. NIVEL UNICO DE COMISION
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </React.Fragment>
            )

        } else {
            return (
                <React.Fragment>
                    <Card className="shadow">

                        <CardBody>
                            <div>
                                <CardTitle>TIPOS DE COMISIONES</CardTitle>
                            </div>
                            <Row>
                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">

                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoA"
                                            name="comisionTipoA"
                                            checked={formComision.comisionTipoA}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledA}

                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoA">
                                            COMISIÓN TIPO A. ENERGIA/POTENCIA CON PRODUCTOS
                                        </label>
                                    </FormGroup>
                                </Col>

                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">

                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoB"
                                            name="comisionTipoB"
                                            checked={formComision.comisionTipoB}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledB}
                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoB">
                                            COMISIÓN TIPO B. ENERGIA/POTENCIA SIN PRODUCTOS
                                        </label>
                                    </FormGroup>
                                </Col>

                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">
                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoC"
                                            name="comisionTipoC"
                                            checked={formComision.comisionTipoC}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledC}
                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoC">
                                            COMISIÓN TIPO C. ENERGIA/POTENCIA CON PRODUCTOS Y ZONA
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col lg="auto" className="pt-3">
                                    <FormGroup className="custom-control custom-checkbox">
                                        <Input
                                            className="custom-control-input"
                                            id="comisionTipoD"
                                            name="comisionTipoD"
                                            checked={formComision.comisionTipoD}
                                            type="checkbox"
                                            onChange={this.onChangeFieldComision}
                                            disabled={this.state.comisionDisabledD}
                                        />
                                        <label className="custom-control-label" htmlFor="comisionTipoD">
                                            COMISIÓN TIPO D. ENERGIA CON PRODUCTOS
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </React.Fragment>
            )

        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    comisionTipologia = (props) => {

        const tipoComisionProducto = props.idComision;
        const idComision = props.idComision;

        var formComision = { ...this.state.formComision }

        if (tipoComisionProducto === "A") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA CON NIVEL</CardTitle>
                        </div>

                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={() => this.addRecord("A")}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.addRecord}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "B") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA SIN NIVEL</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={() => this.addRecord("B")}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.addRecord}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="coTarifa"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "C") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA, PRODUCTOS Y ZONA</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={() => this.addRecord("C")}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.addRecord}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="zona"
                                    title="Zona"
                                    editor="text"
                                />

                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "D") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR ACTIVA CON PRODUCTOS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={() => this.addRecord("D")}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.addRecord}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "E") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES CO 2 REFERENCIAS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={() => this.addRecord("E")}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.addRecord}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="coReferencia"
                                    title="CO Referencia"
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="coReferenciaPorcentaje"
                                    title="% Referencia"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="coStandard"
                                    title="CO Standard"
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="coStandardPorcentaje"
                                    title="% Referencia"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "F") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES CO +2 REFERENCIAS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                size={"small"}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={this.closeEdit}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.addRecord("F")}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="co1"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco1"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co2"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco2"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co3"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco3"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co4"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco4"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co5"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco5"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co6"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco6"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "G") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES CO CON MARGEN LIBRE</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                size={"small"}
                                editField="inEdit"
                                onRowClick={this.rowClick}
                                onItemChange={this.itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={this.closeEdit}>
                                        <button
                                            title="Add new"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.addRecord("G")}
                                        >
                                            Añadir linea
                                        </button>
                                    </div>
                                </GridToolbar>
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="coReferenciaMax"
                                    title="CO REFERENCIA MAX"
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="coReferenciaPorcentaje"
                                    title="% CO"
                                    editor="numeric"
                                    format="{0:n2}"
                                />

                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div></div>
                </React.Fragment>
            )
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////


    tipoLecturaSelect = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
            return (
                <React.Fragment>
                    <Col md="12" lg="4">
                        <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="idComercializadora"
                            >
                                Comercializadora
                            </Label>
                            <SipsOption
                                placeholder="Seleccionar comercializadora"
                                id="idComercializadora"
                                name="idComercializadora"
                                value={this.state.formComision.idComercializadora}
                                query="LIST_MARKETERS_GAS"
                                onChange={this.onChangeFieldComision}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="nombreComercializadora">
                                Comercializadora
                            </Label>
                            <Input
                                id="nombreComercializadora"
                                placeholder=""
                                name="nombreComercializadora"
                                onChange={this.onChangeFieldComision}
                                value={this.state.formComision.nombreComercializadora}
                                type="text"
                                required
                                disabled={this.state.inputDisabled}
                            />
                        </FormGroup>
                    </Col>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Col md="12" lg="4">
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="idComercializadora">
                                Comercializadora
                            </Label>
                            <SipsOption
                                placeholder="Seleccionar comercializadora"
                                id="idComercializadora"
                                name="idComercializadora"
                                value={this.state.formComision.idComercializadora}
                                query="LIST_MARKETERS"
                                onChange={this.onChangeFieldComision}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>

                            <Label className="form-control-label" htmlFor="nombreComercializadora">
                                Comercializadora
                            </Label>
                            <Input
                                id="nombreComercializadora"
                                placeholder=""
                                name="nombreComercializadora"
                                onChange={this.onChangeFieldComision}
                                value={this.state.formComision.nombreComercializadora}
                                type="text"
                                required
                                disabled={this.state.inputDisabled}
                            />
                        </FormGroup>
                    </Col>
                </React.Fragment>
            )
        }
    };

    //////////////////////////////////////////////////////////////////////////////////

    render() {

        const formComision: any = this.state.formComision;
        const fechaAltaComision = this.state.fechaAltaComision
        const fechaBajaComision = this.state.fechaBajaComision

        const comisionDesde = this.state.comisionDesde
        const comisionHasta = this.state.comisionHasta

        const periodoValidezHasta = this.state.periodoValidezHasta
        const periodoValidezDesde = this.state.periodoValidezDesde

        var textoTipoContrato = '';
        if (this.state.tipoContrato == 'G') {
            textoTipoContrato = 'Gas';
        } else {
            textoTipoContrato = 'Electricidad';
        }

        if (this.state.userGroupId == 1000) {
            return (
                <>
                    <LocalizationProvider language="es-ES">
                        <IntlProvider locale="es">
                            <Header title={'Información Comisión'} />
                            <Container className=" mt--7" fluid>

                                <Card className="mb-4">
                                    <CardHeader>
                                        <Button
                                            onClick={this.showFiltersToggle}
                                            size="sm"
                                            className="float-right"
                                        >
                                            <i className={"ni " + this.getFilterIcon()} />
                                        </Button>
                                        <h3 className="mb-0">Filtrar</h3>
                                    </CardHeader>
                                    <Collapse isOpen={this.state.showFilters}>
                                        <CardBody>
                                            <Form innerRef={this.formRef}>
                                                <Row>
                                                    <Col md="12" lg="3">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="comisionRef">
                                                                NOMBRE COMISION
                                                            </Label>
                                                            <Input
                                                                id="nombrecomisionSearch"
                                                                placeholder="Ingresar nombre del comision"
                                                                name="nombrecomisionSearch"
                                                                maxLength={60}
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.nombrecomisionSearch}
                                                                type="text"
                                                                disabled={this.state.inputNombreComisionDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="idSearch">
                                                                Identificador
                                                            </Label>
                                                            <Input
                                                                id="idSearch"
                                                                placeholder="Ingresar ID del comision"
                                                                name="idSearch"
                                                                maxLength={40}
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.idSearch}
                                                                type="text"
                                                                disabled={this.state.inputIdDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" for="tipoEnergia">TIPO CONTRATO</Label>
                                                            <Input type="select" name="tipoContratoSearch" id="tipoContratoSearch"
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.tipoContratoSearch}>
                                                                <option selected>Selecciona...</option>
                                                                <option value="E">ELECTRICIDAD</option>
                                                                <option value="G">GAS</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>

                                                    <this.tipoContrato tipoContrato={this.state.filters.tipoContratoSearch} />

                                                    <Col md="2" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" for="tipoEstadocomision">ESTADO</Label>
                                                            <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.tipoEstadoSearch}>
                                                                <option selected>Selecciona...</option>
                                                                <option value="true">ACTIVO</option>
                                                                <option value="false">BAJA</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label">Desde</Label>
                                                            <DatePicker id="comisionDesde"
                                                                value={comisionDesde}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "comisionDesde")}
                                                                instanceCount={1}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label">Hasta</Label>
                                                            <DatePicker id="comisionHasta"
                                                                value={comisionHasta}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "comisionHasta")}
                                                                instanceCount={1}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}

                                                            />
                                                        </FormGroup>
                                                    </Col>



                                                    <Col className="text-right">
                                                        <Button onClick={this.clearForm}>
                                                            <i className={"ni ni-fat-remove"} /> Limpiar
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            onClick={this.buscarComision}
                                                            disabled={this.state.buscarButtonDisabled}
                                                        >
                                                            <i className={"bi bi-search"} /> Buscar
                                                        </Button>
                                                    </Col>
                                                </Row>


                                                <this.tipoLecturaTable tipoContrato={this.state.filters.tipoContratoSearch} />


                                            </Form>
                                        </CardBody>
                                    </Collapse>
                                </Card>




                                <Row>
                                    <div className="col">
                                        <Card className="shadow">
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            onClick={this.props.history.goBack}
                                                        >
                                                            Atrás
                                                        </button>
                                                    </Col>

                                                    <Col className="col-auto d-flex">
                                                        <Card className="p-3 bg-light">
                                                            <FormGroup>

                                                                <Button disabled={this.state.updateComisionButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                                    onClick={() => { this.updateHandler(this.state.updateDataComision) }}
                                                                >
                                                                    <span className="btn-inner--icon">
                                                                        <i className="fas fa-save fa-2x" style={{ fontSize: 35 }}></i>
                                                                    </span>
                                                                    <span className="btn-inner--text"> ACTUALIZAR</span>
                                                                    <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                                        GUARDAR DATOS DE LA COMISION ACTUAL
                                                                    </UncontrolledTooltip>
                                                                </Button>

                                                            </FormGroup>


                                                        </Card>
                                                    </Col>


                                                    <Card className="p-3 bg-light">
                                                        <FormGroup>
                                                            <Link
                                                                color="info" type="button" className="btn btn-warning btn-sm btn-circle"
                                                                data-toggle="modal"
                                                                to={{ pathname: "nueva-comision/G" }}
                                                                id="newGasProduct"
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="bi bi-file-earmark-post" style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">+ COMISION GAS</span>

                                                                <UncontrolledTooltip delay={0} placement="top" target="newGasProduct">
                                                                    CREAR COMISION NUEVA GAS
                                                                </UncontrolledTooltip>
                                                            </Link>

                                                            <Link
                                                                color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                                data-toggle="modal"
                                                                to={{ pathname: "nueva-comision/E" }}
                                                                id="newElecProduct"
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="bi bi-file-earmark-post" style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">+ COMISION ELEC</span>

                                                                <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                                    CREAR COMISION NUEVA ELECTRICIDAD
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </FormGroup>
                                                    </Card>


                                                </Row>


                                                <div>
                                                    <CardTitle>COMISION</CardTitle>
                                                </div>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="nombreComision">
                                                                Nombre comision
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="nombreComision"
                                                                placeholder=""
                                                                name="nombreComision"
                                                                maxLength={80}
                                                                onChange={this.onChangeFieldComision}
                                                                value={formComision.nombreComision}
                                                                type="text"
                                                                required
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>


                                                    <this.tipoLecturaSelect tipoContrato={this.state.comisionDetail.tipocomision} />

                                                </Row>
                                                <Row>
                                                    <Col lg="auto">
                                                        <FormGroup>
                                                            <Label
                                                                className="form-control-label"
                                                            >
                                                                Tarifa
                                                            </Label>
                                                            <SipsOption
                                                                placeholder="Seleccionar tarifa"
                                                                id="tarifa"
                                                                name="tarifa"
                                                                query="LIST_RATES"
                                                                value={formComision.tarifa}
                                                                onChange={this.onChangeFieldComision}
                                                            ></SipsOption>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="auto" className="pt-4">
                                                        <FormGroup className="custom-control custom-checkbox">

                                                            <Input
                                                                className="custom-control-input"
                                                                id="esActiva"
                                                                name="esActiva"
                                                                checked={formComision.esActiva}
                                                                type="checkbox"
                                                                onChange={this.onChangeFieldComision}


                                                            />
                                                            <label className="custom-control-label" htmlFor="esActiva">
                                                                ACTIVO
                                                            </label>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg="auto" className="pt-3">
                                                        <FormGroup className="custom-control custom-checkbox">

                                                            <Input
                                                                className="custom-control-input"
                                                                id="coIncluido"
                                                                name="coIncluido"
                                                                checked={formComision.coIncluido}
                                                                type="checkbox"
                                                                onChange={this.onChangeFieldComision}
                                                            />
                                                            <label className="custom-control-label" htmlFor="coIncluido">
                                                                CO INCLUIDO
                                                            </label>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg="auto" className="pt-4">
                                                        <FormGroup className="custom-control custom-checkbox">

                                                            <Input
                                                                className="custom-control-input"
                                                                id="eurosMwh"
                                                                name="eurosMwh"
                                                                checked={formComision.eurosMwh}
                                                                type="checkbox"
                                                                onChange={this.onChangeFieldComision}


                                                            />
                                                            <label className="custom-control-label" htmlFor="eurosMwh">
                                                                €/MWh
                                                            </label>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="fechaAltaComision">
                                                                Fecha Alta Registro
                                                            </Label>

                                                            <DatePicker id="fechaAltaComision"
                                                                value={fechaAltaComision}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "fechaAltaComision")}
                                                                instanceCount={1}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}

                                                            />

                                                        </FormGroup>

                                                    </Col>


                                                    <Col md="2">
                                                        <FormGroup>

                                                            <Label className="form-control-label" htmlFor="fechaBajaComision">
                                                                Fecha Baja Registro
                                                            </Label>

                                                            <DatePicker id="fechaBajaComision"
                                                                value={fechaBajaComision}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "fechaBajaComision")}
                                                                instanceCount={2}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}

                                                            />


                                                        </FormGroup>
                                                    </Col>

                                                </Row>

                                            </CardBody>
                                        </Card>

                                        <Card className="shadow">

                                            <CardBody>
                                                <div>
                                                    <CardTitle>COMISIONES</CardTitle>
                                                </div>
                                                <Row>

                                                    <Col md="2" sm="2" lg="2">
                                                        <FormGroup>
                                                            <Label
                                                                className="form-control-label"
                                                                htmlFor="comisionContratoNuevo"
                                                            >
                                                                COMISION NUEVO CONT.
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="comisionContratoNuevo"
                                                                placeholder=""
                                                                name="comisionContratoNuevo"
                                                                min={0}
                                                                onChange={this.onChangeFieldComision}
                                                                value={formComision.comisionContratoNuevo}
                                                                type="text"
                                                                required
                                                            />

                                                            <FormText color="muted">
                                                                Introduzca la cantidad en euros
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" sm="2" lg="2">
                                                        <FormGroup>
                                                            <Label
                                                                className="form-control-label"
                                                                htmlFor="comisionContratoRenovacion"
                                                            >
                                                                COMISION RENOVACION
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="comisionContratoRenovacion"
                                                                placeholder="0"
                                                                name="comisionContratoRenovacion"
                                                                min={0}
                                                                onChange={this.onChangeFieldComision}
                                                                value={formComision.comisionContratoRenovacion}
                                                                type="number"
                                                                required
                                                            />

                                                            <FormText color="muted">
                                                                Introduzca la cantidad en euros
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" sm="2" lg="2">
                                                        <FormGroup>
                                                            <Label
                                                                className="form-control-label"
                                                                htmlFor="comisionGOAgente1"
                                                            >
                                                                PORCENTAJE GO NIVEL 1
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="comisionGOAgente1"
                                                                placeholder="0"
                                                                name="comisionGOAgente1"
                                                                min={0}
                                                                onChange={this.onChangeFieldComision}
                                                                value={this.state.formComision.comisionGOAgente1}
                                                                type="number"
                                                                required
                                                            />

                                                            <FormText color="muted">
                                                                Introduzca el porcentaje sobre consumos Anual
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" sm="2" lg="2">
                                                        <FormGroup>
                                                            <Label
                                                                className="form-control-label"
                                                                htmlFor="comisionGOAgente2"
                                                            >
                                                                PORCENTAJE GO NIVEL 2
                                                            </Label>
                                                            <Input
                                                                className="text-primary"
                                                                id="comisionGOAgente2"
                                                                placeholder="0"
                                                                name="comisionGOAgente2"
                                                                min={0}
                                                                onChange={this.onChangeFieldComision}
                                                                value={this.state.formComision.comisionGOAgente2}
                                                                type="number"
                                                                required
                                                            />

                                                            <FormText color="muted">
                                                                Introduzca el porcentaje sobre consumos Anual
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>

                                            </CardBody>
                                        </Card>

                                        <this.comision tipoComision={formComision.coIncluido} />

                                        <Card className="shadow">
                                            <this.comisionTipologia tipoComision={formComision.coIncluido} idComision={formComision.tipoComisionProducto} />
                                        </Card>

                                        {this.state.alert}
                                    </div>
                                </Row>
                            </Container>
                        </IntlProvider>
                    </LocalizationProvider>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}


export default withApollo(comision);

import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
//import DatePicker from "react-datepicker";
import { validateSpanishId } from 'spain-id'
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';

import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";
import SipsOption from "../../components/Sips/SipsOption";


import { withApollo } from "react-apollo";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
];


const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);



const hint = <span>My custom hint</span>;
const note = <span>My custom note</span>;
const sortIcon = <ArrowDownward />;

const columnsDatosRepresentante = [

    { selector: row => row.nombreRepresentante, name: 'NOMBRE', sortable: true, width: '200px', format: (row => capitalize(row.nombreRepresentante)) },
    { selector: row => row.apellido1Representante, name: 'APELLIDO 1', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Representante)) },
    { selector: row => row.apellido2Representante, name: 'APELLIDO 2', sortable: true, width: '210px', center: true, format: (row => capitalize(row.apellido2Representante)) },
    { selector: row => row.NIFRepresentante, name: 'CIF', sortable: true, width: '100px', center: true, format: (row => capitalize(row.NIFRepresentante)) },
    { selector: row => row.CargoRepresentante, name: 'CARGO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.CargoRepresentante)) },
    { selector: row => row.telefonoRepresentante, name: 'TELEFONO', width: '180px', sortable: true, center: true, format: (row => capitalize(row.telefonoRepresentante)) }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class RepresentanteConsulta extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombreSearch: "",
            apellidoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        representanteDetailTotal: [],
        representanteSeleccionado: false,
        idRepresentante: '',
        representanteDetail: {
            nombreRepresentante:'',
            apellido1Representante:'',
            apellido2Representante:'',
            NIFRepresentante:'',
            idRepresentante:'',
            idRepresentanteInterno: 0,
            CargoRepresentante:'',
            telefonoRepresentante:'',
            correoRepresentante:'',
            idCompany: 0,
            idUser:'',
            createdAt:'',
            updatedAt:'',
            codigoPostalRepresentante:'',
            AutonomiaRepresentante:'',
            MunicipioRepresentante:'',
            ProvinciaRepresentante:'',
            escaleraRepresentante:'',
            numFincaRepresentante:'',
            pisoRepresentante:'',
            portalRepresentante:'',
            puertaRepresentante:'',
            tipoViaRepresentante:'',
            viaRepresentante:'',
            fechaBaja:'',
            clientesRepresentados:[]
        },
        formRepre: {
            nombreRepresentante:'',
            apellido1Representante:'',
            apellido2Representante:'',
            NIFRepresentante:'',
            idRepresentante:'',
            idRepresentanteInterno: 0,
            CargoRepresentante:'',
            telefonoRepresentante:'',
            correoRepresentante:'',
            idCompany: 0,
            idUser:'',
            createdAt:'',
            updatedAt:'',
            codigoPostalRepresentante:'',
            AutonomiaRepresentante:'',
            MunicipioRepresentante:'',
            ProvinciaRepresentante:'',
            escaleraRepresentante:'',
            numFincaRepresentante:'',
            pisoRepresentante:'',
            portalRepresentante:'',
            puertaRepresentante:'',
            tipoViaRepresentante:'',
            viaRepresentante:'',
            fechaBaja:'',
            clientesRepresentados:[]
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',

        saveDataRepre: false,
        updateDataRepre: false,
        saveRepresentanteButtonDisabled: true,
        updateRepresentanteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFRepresentanteError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        uploadButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreDisabled: false,
        inputCifDisabled: false,
        inputApellidosDisabled: false,
filesContrato: [],
        createdAt: '',
        fechaBaja: '',
codigoPostalRepresentanteError : false,
dicMunicipios: [],
        alert: null
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];






    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.lecturasRef = React.createRef();
        this.inputEl = React.createRef();

    }




    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    }

    componentDidMount() {
        this.fetchItems();
    }

//////////////////////////////////////////////////////////////////////////////////////7

hideAlert = () => {
    this.setState({
        alert: null
    });
};

warningAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Error en la base de datos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                Existe un error en la Base de datos.
 </ReactBSAlert>
        )
    });
};

warningAlertNIF = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="No existen registros que cumplan los requisitos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
             Revise los requisitos introducidos
 </ReactBSAlert>
        )
    });
};

warningAlertFiltros = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Existen campos obligatorios sin información"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
             Revise los datos introducidos
 </ReactBSAlert>
        )
    });
};

confirmAlertSave = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea crear el representante?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onSave()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Guardar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

confirmAlertUpdate = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea actualizar los datos del representante?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onUpdate()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Actualizar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

successAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Correcto"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                Representante guardado correctamente
    </ReactBSAlert>
        )
    });
};

//////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

//////////////////////////////////////////////////////////////////////////////////

    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const id = state.target.id;
        const file = state.target.value

    console.log(state)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/representante/download?id=" + this.state.companyIdUser + "&idRepre=" + this.state.representanteDetail.idRepresentante + "&file=" + file;

        console.log(direccionFileList)
        axios.get(direccionFileList,
            {
                headers,
                responseType: 'blob'
            }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch(function() {
                console.log('Failure')
            });
    };


/////////////////////////////////////////////////////////////////////////////////////



    fetchRepre = async () => {
        console.log('fetchRepre VALOR DE THIS.STATE -------> ', this.state)

        console.log('VALOR DE this.state.companyIdUser', this.state.companyIdUser)

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch

        var dataFilters = {
            'idCompany': companyIdUser,
            'cups': "",
            'Usuario': "",
            'NIFRepresentante': CIF,
            'nombreRepresentante': nombre,
            'apellido1Representante': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if(result.data.rows.length>0){
                let data = result.data.rows

                this.setState({ representanteDetailTotal: result.data.rows});

            } else {
            this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                this.warningAlert()
            });

    };


    validarCUPS = (cups) => {
        let ret = false;
        const reCUPS = /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i;
        if (reCUPS.test(cups)) {
            const mCUPS = cups.toUpperCase().match(reCUPS);
            const [, cups16, control] = mCUPS;
            const letters = [
                'T', 'R', 'W', 'A', 'G', 'M',
                'Y', 'F', 'P', 'D', 'X', 'B',
                'N', 'J', 'Z', 'S', 'Q', 'V',
                'H', 'L', 'C', 'K', 'E',
            ];

            const cup16Mod = +cups16 % 529,
                quotient = Math.floor(cup16Mod / letters.length),
                remainder = cup16Mod % letters.length;

            ret = (control === letters[quotient] + letters[remainder]);
        }

        return ret
    };

    onPageClick = (page) => { };

    onChangeField = (event) => {
        event.persist()
        console.log('VALORES DE EVENT', event)
        console.log(event.target.name)
    };

    onChangeFieldSearch = (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;
        console.log(event)
        console.log(name)
        console.log(value)
        filters[name] = value;
        /* if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/




        if (event.target.name === 'nombreSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'nombreSearch' && event.target.value.length > 0) {

            this.setState({
                inputCifDisabled: true,
                inputApellidosDisabled: true,
                buscarButtonDisabled: false

            })

        }



        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {

            console.log('VALOR DE cifSearch ', event.target.value.length)
            this.setState({

                cifError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {

            console.log('ESTO ESTÁ DENTRO DE CIFSEARCH > 0')


            const result = validateSpanishId(event.target.value)

            console.log('VALOR DE RESULT ', result)
            if (result === true) {
                this.setState({
                    cifError: false,
                    buscarButtonDisabled: false,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    cifError: true,
                    buscarButtonDisabled: true,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,
                })
            }
        }




        if (event.target.name === 'apellidoSearch' && event.target.value.length == 0) {
            this.setState({
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'apellidoSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputNombreDisabled: true,
                buscarButtonDisabled: false
            })
        }


        this.setState({ filters });

    }



    onChangeFieldRepre = (event) => {

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };


    onSubmit(e) {
        e.preventDefault()

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }


        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/uploadDocRepre?id=" + this.state.companyIdUser + "&idRepre=" + this.state.representanteDetail.idRepresentante;

        console.log(direccionFile)
        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            //console.log('resultado de la subida')
            //console.log (result);
            this.setState({ filesConvert: result.data.Contents,
                            filesContrato: result.data.Contents})
            this.setState({ imgCollection: [] })
            this.setState({ uploadButtonDisabled: true })
            this.inputEl.current.value = ''
})
    };

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        //this.setState({ imgCollection: e.target.files })
        //console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

        let tamañoFiles = e.target.files.length;
        //console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

        if (tamañoFiles > 0 && this.state.representanteDetail.idRepresentante) {
            this.setState(
                ({ uploadButtonDisabled }) => ({
                    uploadButtonDisabled: false
                }), () => {
                    this.setState(({ uploadButtonDisabled }) => ({
                        uploadButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ uploadButtonDisabled }) => ({
                    uploadButtonDisabled: true
                }), () => {
                    this.setState(({ uploadButtonDisabled }) => ({
                        uploadButtonDisabled: true
                    }))
                })
        }

    };


    representanteSeleccionado = async (event) => {
        console.log('DENTR DE openRepresentantenSeleccionado 958')
        console.log(event)
        this.setState({
            representanteSeleccionado: event.representante,
            // },
            //showList: false,
        });
        //this.setState({ representanteDetail: result.data.representanteData[0] });
        //    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        //console.log('VALORE DE representanteDetail  ------> ', result.data.representanteData[0])
        //    this.setState({fechaContrato: result.data.contratoInfoById[0].fechaContrato});
        // let existeFechaAlta = result.data.getRepresentanteInfo[0].fechaFirmaContrato
        // let existeFechaBaja = result.data.getRepresentanteInfo[0].fechaActivacion
        this.setState({ idRepresentante: event.representante.idRepresentante });
        this.setState({
            formRepre: {
                NIFRepresentante: event.representante.NIFRepresentante,
                CargoRepresentante: event.representante.CargoRepresentante,
                apellido1Representante: event.representante.apellido1Representante,
                apellido2Representante: event.representante.apellido2Representante,
                companyIdRepresentante: event.representante.companyIdRepresentante,
                correoRepresentante: event.representante.correoRepresentante,
                idRepresentante: event.representante.idRepresentante,
                idRepresentanteInterno: event.representante.idRepresentanteInterno,
                nombreRepresentante: event.representante.nombreRepresentante,
                telefonoRepresentante: event.representante.telefonoRepresentante,
                createdAt: event.representante.createdAt,
                fechaBaja: event.representante.fechaBaja,
                codigoPostalRepresentante: event.representante.codigoPostalRepresentante,
                AutonomiaRepresentante: event.representante.AutonomiaRepresentante,
                MunicipioRepresentante: event.representante.MunicipioRepresentante,
                ProvinciaRepresentante: event.representante.ProvinciaRepresentante,
                escaleraRepresentante: event.representante.escaleraRepresentante,
                numFincaRepresentante: event.representante.numFincaRepresentante,
                pisoRepresentante: event.representante.pisoRepresentante,
                portalRepresentante: event.representante.portalRepresentante,
                puertaRepresentante: event.representante.puertaRepresentante,
                tipoViaRepresentante: event.representante.tipoViaRepresentante,
                viaRepresentante: event.representante.viaRepresentante,
                idUser: event.representante.idUser,
                idCompany: Number(event.representante.idCompany)
            },
            saveDataRepre: true,
            saveRepresentanteButtonDisabled: false,
        });
        //console.log(this.state)
    };


    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                nombreSearch: '',
                cifSearch: '',
                apellidoSearch: ''
            },
            inputCifDisabled: false,
            inputNombreDisabled: false,
            inputApellidosDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            representanteSeleccionado: false,
            representanteDetailTotal: [],
            formRepre: {
                NIFRepresentante: '',
                CargoRepresentante: '',
                apellido1Representante: '',
                apellido2Representante: '',
                companyIdRepresentante: '',
                correoRepresentante: '',
                idRepresentante: '',
                idRepresentanteInterno: 0,
                nombreRepresentante: '',
                telefonoRepresentante: '',
                createdAt: '',
                fechaBaja: '',
                codigoPostalRepresentante: "",
                AutonomiaRepresentante: "",
                MunicipioRepresentante: "",
                ProvinciaRepresentante: "",
                escaleraRepresentante: "",
                numFincaRepresentante: "",
                pisoRepresentante: "",
                portalRepresentante: "",
                puertaRepresentante: "",
                tipoViaRepresentante: "",
                viaRepresentante: "",
                idUser:"",
                idCompany: 0
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveRepresentanteButtonDisabled: true,
            updateRepresentanteButtonDisabled: true,
            createdAt: '',
            fechaBaja: ''
        });

        this.formRef.current.reset();

    };


    buscarRepresentante = async () => {


        console.log('fetchRepre VALOR DE THIS.STATE -------> ', this.state)

        console.log('VALOR DE this.state.companyIdUser', this.state.companyIdUser)

        var companyIdUser = this.state.companyIdUser;
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch

        var dataFilters = {
            'idCompany': Number(companyIdUser),
            'cups': "",
            'Usuario': "",
            'NIFRepresentante': CIF,
            'nombreRepresentante': nombre,
            'apellido1Representante': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if(result.data.rows.length>0){
                let data = result.data.rows
                this.setState({ representanteDetailTotal: result.data.rows });

            } else {
            this.warningAlertNIF();
                };

            }).catch((error) => {
                console.log('Failure')
                this.warningAlert()

            });

    };



    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }




    updateHandler(props) {

        if (this.state.updateDataRepre && (this.state.formRepre.NIFRepresentante && this.state.formRepre.nombreRepresentante && this.state.formRepre.apellido1Representante && this.state.formRepre.apellido2Representante && this.state.formRepre.CargoRepresentante && this.state.formRepre.telefonoRepresentante && this.state.formRepre.correoRepresentante)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };




    saveHandler(props) {

        if (this.state.saveDataRepre && (this.state.formRepre.NIFRepresentante && this.state.formRepre.nombreRepresentante && this.state.formRepre.apellido1Representante && this.state.formRepre.apellido2Representante && this.state.formRepre.CargoRepresentante && this.state.formRepre.telefonoRepresentante && this.state.formRepre.correoRepresentante)) {

            this.confirmAlertSave()

        } else {

            this.warningAlertFiltros()
        }

    };



    onUpdate = async () => {
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.updateDataRepre)


        if (this.state.updateDataRepre == true) {


            console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveDataRepre)

            //const fechaActivacion = this.state.fechaActivacion;
            const companyId = this.state.companyIdUser;
            const userId = this.state.userId.toString();

            //const idContrato = this.state.contrato;

            var createdAt = this.state.formRepre.createdAt;
            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }



                var datosRepresentante = {
                     idRepresentanteInterno: this.state.formRepre.idRepresentanteInterno,
                     idRepresentante: this.state.formRepre.idRepresentante,
                     nombreRepresentante: this.state.formRepre.nombreRepresentante.toUpperCase(),
                     apellido1Representante: this.state.formRepre.apellido1Representante.toUpperCase(),
                     apellido2Representante: this.state.formRepre.apellido2Representante.toUpperCase(),
                     NIFRepresentante: this.state.formRepre.NIFRepresentante.toUpperCase(),
                     CargoRepresentante: this.state.formRepre.CargoRepresentante.toUpperCase(),
                     telefonoRepresentante: this.state.formRepre.telefonoRepresentante,
                     correoRepresentante: this.state.formRepre.correoRepresentante,
                     companyIdRepresentante : this.state.companyIdUser.toString(),
                     codigoPostalRepresentante: this.state.formRepre.codigoPostalRepresentante,
                     AutonomiaRepresentante: this.state.formRepre.AutonomiaRepresentante,
                     MunicipioRepresentante: this.state.formRepre.MunicipioRepresentante,
                     ProvinciaRepresentante: this.state.formRepre.ProvinciaRepresentante,
                     escaleraRepresentante: this.state.formRepre.escaleraRepresentante.toUpperCase(),
                     numFincaRepresentante: this.state.formRepre.numFincaRepresentante.toUpperCase(),
                     pisoRepresentante: this.state.formRepre.pisoRepresentante.toUpperCase(),
                     portalRepresentante: this.state.formRepre.portalRepresentante.toUpperCase(),
                     puertaRepresentante: this.state.formRepre.puertaRepresentante.toUpperCase(),
                     tipoViaRepresentante: this.state.formRepre.tipoViaRepresentante.toUpperCase(),
                     viaRepresentante: this.state.formRepre.viaRepresentante.toUpperCase(),
                     createdAt: createdAt,
                     fechaBaja:this.state.formRepre.fechaBaja,
                     idUser: userId,
                     idCompany: Number(companyId)
                }

                console.log('ENTRA EN SAVE DATOS REPRESENTANTES')
                console.log('DATOS DE THIS.STATE   --------> ', this.state)

                var resultadoUpdate: any = [];
                var resultadoView: any = [];



                const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar',
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
                };
                let direccionFile = "https://datapi.psgestion.es/representante/create?id="+companyId+"&user="+userId;


                axios.post(direccionFile, datosRepresentante, { headers, responseType: 'json'})
                     .then (res=> {
                                console.log('Actualización correcta', res)
                                console.log('Datos del registro: ', res.data)
                                this.setState({
                                    updateDataRepre: false,
                                    updateRepresentanteButtonDisabled: true
                                })
                                this.successAlert()
                                //notify();
                                //this.clearForm();
                                //
                                //
                        /*        this.setState({ representanteDetail: result.data.createRepresentanteData[0] });
                                notify();
                                this.setState({
                                    formRepre: {
                                        NIFRepresentante: result.data.createRepresentanteData[0].NIFRepresentante,
                                        CargoRepresentante: result.data.createRepresentanteData[0].CargoRepresentante,
                                        apellido1Representante: result.data.createRepresentanteData[0].apellido1Representante,
                                        apellido2Representante: result.data.createRepresentanteData[0].apellido2Representante,
                                        companyIdRepresentante: result.data.createRepresentanteData[0].companyIdRepresentante,
                                        correoRepresentante: result.data.createRepresentanteData[0].correoRepresentante,
                                        idRepresentante: result.data.createRepresentanteData[0].idRepresentante,
                                        idRepresentanteInterno: result.data.createRepresentanteData[0].idRepresentanteInterno,
                                        nombreRepresentante: result.data.createRepresentanteData[0].nombreRepresentante,
                                        telefonoRepresentante: result.data.createRepresentanteData[0].telefonoRepresentante,
                                        codigoPostalRepresentante: result.data.createRepresentanteData[0].codigoPostalRepresentante,
                                        AutonomiaRepresentante: result.data.createRepresentanteData[0].AutonomiaRepresentante,
                                        MunicipioRepresentante: result.data.createRepresentanteData[0].MunicipioRepresentante,
                                        ProvinciaRepresentante: result.data.createRepresentanteData[0].ProvinciaRepresentante,
                                        escaleraRepresentante: result.data.createRepresentanteData[0].escaleraRepresentante,
                                        numFincaRepresentante: result.data.createRepresentanteData[0].numFincaRepresentante,
                                        pisoRepresentante: result.data.createRepresentanteData[0].pisoRepresentante,
                                        portalRepresentante: result.data.createRepresentanteData[0].portalRepresentante,
                                        puertaRepresentante: result.data.createRepresentanteData[0].puertaRepresentante,
                                        tipoViaRepresentante: result.data.createRepresentanteData[0].tipoViaRepresentante,
                                        viaRepresentante: result.data.createRepresentanteData[0].viaRepresentante
                                    }
                                });
                                this.setState({
                                    saveDataRepre: false,
                                    saveRepresentanteButtonDisabled: true
                                });
                                            */
                     }).catch (error =>{
                                console.log ('Failure')
                                console.log('Error en el proceso: ', error)
                                //notifyErrorDB();
                     });


                console.log('valores de distintos puntos')
                console.log(companyId)

                // /cliente/create

                //this.clearForm();


            await this.fetchRepre();


        }

    };


    onSave = async () => {
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveDataRepre)

        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser;
        const userId = this.state.userId.toString();


        //const idContrato = this.state.contrato;


        var createdAt = this.state.formRepre.createdAt;
        if (!createdAt) {
            createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
        }


        if (this.state.saveDataRepre == true) {

            var datosRepresentante = {
                 idRepresentanteInterno: this.state.formRepre.idRepresentanteInterno,
                 idRepresentante: this.state.formRepre.idRepresentante,
                 nombreRepresentante: this.state.formRepre.nombreRepresentante.toUpperCase(),
                 apellido1Representante: this.state.formRepre.apellido1Representante.toUpperCase(),
                 apellido2Representante: this.state.formRepre.apellido2Representante.toUpperCase(),
                 NIFRepresentante: this.state.formRepre.NIFRepresentante.toUpperCase(),
                 CargoRepresentante: this.state.formRepre.CargoRepresentante.toUpperCase(),
                 telefonoRepresentante: this.state.formRepre.telefonoRepresentante,
                 correoRepresentante: this.state.formRepre.correoRepresentante,
                 companyIdRepresentante : this.state.companyIdUser.toString(),
                 codigoPostalRepresentante: this.state.formRepre.codigoPostalRepresentante,
                 AutonomiaRepresentante: this.state.formRepre.AutonomiaRepresentante,
                 MunicipioRepresentante: this.state.formRepre.MunicipioRepresentante,
                 ProvinciaRepresentante: this.state.formRepre.ProvinciaRepresentante,
                 escaleraRepresentante: this.state.formRepre.escaleraRepresentante,
                 numFincaRepresentante: this.state.formRepre.numFincaRepresentante,
                 pisoRepresentante: this.state.formRepre.pisoRepresentante,
                 portalRepresentante: this.state.formRepre.portalRepresentante,
                 puertaRepresentante: this.state.formRepre.puertaRepresentante,
                 tipoViaRepresentante: this.state.formRepre.tipoViaRepresentante,
                 viaRepresentante: this.state.formRepre.viaRepresentante,
                 createdAt: createdAt,
                 fechaBaja:this.state.formRepre.fechaBaja,
                 idUser: userId,
                 idCompany: companyId
            }

            console.log('ENTRA EN SAVE DATOS REPRESENTANTES')
            console.log('DATOS DE THIS.STATE   --------> ', this.state)

            var resultadoUpdate: any = [];
            var resultadoView: any = [];



            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/representante/create?id="+companyId+"&user="+userId;


            axios.post(direccionFile, datosRepresentante, { headers, responseType: 'json'})
                 .then (res=> {
                            console.log('Actualización correcta', res)
                            console.log('Datos del registro: ', res.data)
                            this.setState({
                                saveDataRepre: false,
                                saveRepresentanteButtonDisabled: true
                            });
                            this.successAlert()
                            //notify();
                            //this.clearForm();
                            //
                            //
                    /*        this.setState({ representanteDetail: result.data.createRepresentanteData[0] });
                            notify();
                            this.setState({
                                formRepre: {
                                    NIFRepresentante: result.data.createRepresentanteData[0].NIFRepresentante,
                                    CargoRepresentante: result.data.createRepresentanteData[0].CargoRepresentante,
                                    apellido1Representante: result.data.createRepresentanteData[0].apellido1Representante,
                                    apellido2Representante: result.data.createRepresentanteData[0].apellido2Representante,
                                    companyIdRepresentante: result.data.createRepresentanteData[0].companyIdRepresentante,
                                    correoRepresentante: result.data.createRepresentanteData[0].correoRepresentante,
                                    idRepresentante: result.data.createRepresentanteData[0].idRepresentante,
                                    idRepresentanteInterno: result.data.createRepresentanteData[0].idRepresentanteInterno,
                                    nombreRepresentante: result.data.createRepresentanteData[0].nombreRepresentante,
                                    telefonoRepresentante: result.data.createRepresentanteData[0].telefonoRepresentante,
                                    codigoPostalRepresentante: result.data.createRepresentanteData[0].codigoPostalRepresentante,
                                    AutonomiaRepresentante: result.data.createRepresentanteData[0].AutonomiaRepresentante,
                                    MunicipioRepresentante: result.data.createRepresentanteData[0].MunicipioRepresentante,
                                    ProvinciaRepresentante: result.data.createRepresentanteData[0].ProvinciaRepresentante,
                                    escaleraRepresentante: result.data.createRepresentanteData[0].escaleraRepresentante,
                                    numFincaRepresentante: result.data.createRepresentanteData[0].numFincaRepresentante,
                                    pisoRepresentante: result.data.createRepresentanteData[0].pisoRepresentante,
                                    portalRepresentante: result.data.createRepresentanteData[0].portalRepresentante,
                                    puertaRepresentante: result.data.createRepresentanteData[0].puertaRepresentante,
                                    tipoViaRepresentante: result.data.createRepresentanteData[0].tipoViaRepresentante,
                                    viaRepresentante: result.data.createRepresentanteData[0].viaRepresentante
                                }
                            });
                            this.setState({
                                saveDataRepre: false,
                                saveRepresentanteButtonDisabled: true
                            });
                                        */
                 }).catch (error =>{
                            console.log ('Failure')
                            console.log('Error en el proceso: ', error)
                            //notifyErrorDB();
                 });


            console.log('valores de distintos puntos')
            console.log(companyId)

            // /cliente/create

            this.clearForm();

            /*
                                                console.log('ESTAMOS ANTES DEL QUERY EN EL ONSAVE  YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY')
                                                await this.props.client.query({
                                                    query: VIEW_CONTRATO_FILTER,
                                                    variables: { companyId: this.state.companyIdUser.toString(), userId: "", cups: this.state.filters.nombreSearch, CIF: this.state.filters.cifSearch, idContrato: this.state.filters.apellidoSearch},
                                                    fetchPolicy: 'no-cache'
                                                }).then(result => {
                                                console.log('DATOS DEVUELTOS POR VIEW_CONTRATO_FILTER en el save ------>', result)

                                                    this.setState({ representanteDetailTotal: result.data.contratoInfoByfilters});
                                                }).catch( err => {
                                                  console.log(err);
                                                })
                                            this.fetchRepre();

            */

            //await this.fetchRepre();
        }

    };


    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formRepre };


        const name = id;
        var valueDay = formattedValue


        if (this.state.representanteSeleccionado) {

            if (name == 'createdAt') {
                //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
                //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
                this.setState({
                    updateDataRepre: true,
                    createdAt: value,
                    saveRepresentanteButtonDisabled: true,
                    updateRepresentanteButtonDisabled: false
                });

            }

            if (name == 'fechaBaja') {
                //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
                this.setState({
                    updateDataRepre: true,
                    fechaBaja: value,
                    saveRepresentanteButtonDisabled: true,
                    updateRepresentanteButtonDisabled: false
                });
            }
        }
        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formRepre: formData });
    };

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var representante = '';

        if (state.selectedCount === 1) {
            var existeRepre = ''
            representante = state.selectedRows[0].idRepresentante;
            console.log(representante)
            this.setState({ representanteDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeRepre = state.selectedRows[0].idRepresentante

            if (existeFechaAlta && existeFechaBaja) {
                console.log('ENTRA EN EXISTE FECHA DE ALTA Y BAJA')

                this.setState({
                    inputDisabled: true,
                    updateRepresentanteButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                console.log('ENTRA EN no EXISTE FECHA DE ALTA Y BAJA')
                this.setState({
                    inputDisabled: false,
                    updateRepresentanteButtonDisabled: true
                })
            }

            console.log('VALOR DE existeRepre--------------- >>>>>>>>', existeRepre)
            console.log('VALOR DE THIS.STATE.representanteDetail ------>>>>>>>> ', this.state.representanteDetail)

            if (existeRepre) {
                console.log('VALORE DE representanteDetail  ------> ', this.state.representanteDetail)

                let data = state.selectedRows[0]
                let fechaAltaAux = ""
                let fechaBajaAux = ""

                if (data.createdAt) {
                    fechaAltaAux = data.createdAt
                }

                if (data.fechaBaja) {
                    fechaBajaAux = data.fechaBajaAux
                }

                this.setState({ idRepresentante: this.state.representanteDetail.idRepresentante });
                this.setState({
                    formRepre: {
                        NIFRepresentante: state.selectedRows[0].NIFRepresentante,
                        CargoRepresentante: state.selectedRows[0].CargoRepresentante,
                        apellido1Representante: state.selectedRows[0].apellido1Representante,
                        apellido2Representante: state.selectedRows[0].apellido2Representante,
                        companyIdRepresentante: state.selectedRows[0].companyIdRepresentante,
                        correoRepresentante: state.selectedRows[0].correoRepresentante,
                        idRepresentante: state.selectedRows[0].idRepresentante,
                        idRepresentanteInterno: state.selectedRows[0].idRepresentanteInterno,
                        nombreRepresentante: state.selectedRows[0].nombreRepresentante,
                        telefonoRepresentante: state.selectedRows[0].telefonoRepresentante,
                        createdAt: state.selectedRows[0].createdAt,
                        fechaBaja: state.selectedRows[0].fechaBaja,
                        codigoPostalRepresentante: state.selectedRows[0].codigoPostalRepresentante,
                        AutonomiaRepresentante: state.selectedRows[0].AutonomiaRepresentante,
                        MunicipioRepresentante: state.selectedRows[0].MunicipioRepresentante,
                        ProvinciaRepresentante: state.selectedRows[0].ProvinciaRepresentante,
                        escaleraRepresentante: state.selectedRows[0].escaleraRepresentante,
                        numFincaRepresentante: state.selectedRows[0].numFincaRepresentante,
                        pisoRepresentante: state.selectedRows[0].pisoRepresentante,
                        portalRepresentante: state.selectedRows[0].portalRepresentante,
                        puertaRepresentante: state.selectedRows[0].puertaRepresentante,
                        tipoViaRepresentante: state.selectedRows[0].tipoViaRepresentante,
                        viaRepresentante: state.selectedRows[0].viaRepresentante,
                        idUser: state.selectedRows[0].idUser,
                        idCompany: state.selectedRows[0].idCompany
                    },
                    representanteSeleccionado: true,
                    createdAt:fechaAltaAux,
                    fechaBaja: fechaBajaAux
                });

                const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar',
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
                };

                let direccionFileList = "https://datapi.psgestion.es/files/representante?id=" + this.state.companyIdUser + "&idRepre=" + state.selectedRows[0].idRepresentante;
                axios.get(direccionFileList, { headers }).
                    then(result => {
                        //console.log('resultado del listados')
                        //console.log (result)
                        this.setState({ filesContrato: result.data.Contents})
                    }).catch(err => {
                        console.log('Failure')
                    });



            } else {
                console.log('ENTRA EN NO EXISTEREPRE')
                this.setState({
                    representanteSeleccionado: false,
                    formRepre: {
                        NIFRepresentante: '',
                        CargoRepresentante: '',
                        apellido1Representante: '',
                        apellido2Representante: '',
                        companyIdRepresentante: '',
                        correoRepresentante: '',
                        idRepresentante: '',
                        idRepresentanteInterno: 0,
                        nombreRepresentante: '',
                        telefonoRepresentante: '',
                        createdAt: '',
                        fechaBaja: '',
                        codigoPostalRepresentante: "",
                        AutonomiaRepresentante: "",
                        MunicipioRepresentante: "",
                        ProvinciaRepresentante: "",
                        escaleraRepresentante: "",
                        numFincaRepresentante: "",
                        pisoRepresentante: "",
                        portalRepresentante: "",
                        puertaRepresentante: "",
                        tipoViaRepresentante: "",
                        viaRepresentante: "",
                        idUser: 0,
                        idCompany: ""
                    }
                });
            }
        } else {

            this.setState({
                representanteSeleccionado: false,
                inputDisabled: false,
                updateRepresentanteButtonDisabled: true,
                formRepre: {
                    NIFRepresentante: '',
                    CargoRepresentante: '',
                    apellido1Representante: '',
                    apellido2Representante: '',
                    companyIdRepresentante: '',
                    correoRepresentante: '',
                    idRepresentante: '',
                    idRepresentanteInterno: 0,
                    nombreRepresentante: '',
                    telefonoRepresentante: '',
                    createdAt: '',
                    fechaBaja: '',
                    codigoPostalRepresentante: "",
                    AutonomiaRepresentante: "",
                    MunicipioRepresentante: "",
                    ProvinciaRepresentante: "",
                    escaleraRepresentante: "",
                    numFincaRepresentante: "",
                    pisoRepresentante: "",
                    portalRepresentante: "",
                    puertaRepresentante: "",
                    tipoViaRepresentante: "",
                    viaRepresentante: "",
                    idUser: 0,
                    idCompany: ""
                }
            });



        };

    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    render() {

        const formRepre: any = this.state.formRepre;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const createdAt = this.state.createdAt
        const fechaBaja = this.state.fechaBaja


        return (
            <>
                <Header title={'Información Representante'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="representantesRef">
                                                    NOMBRE REPRESENTANTE
                                  </Label>
                                                <Input
                                                    id="nombreSearch"
                                                    placeholder="Ingresar nombre del representante"
                                                    name="nombreSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.nombreSearch}
                                                    type="text"
                                                    disabled={this.state.inputNombreDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="apellidoSearch">
                                                    APELLIDOS
                                  </Label>
                                                <Input
                                                    id="apellidoSearch"
                                                    placeholder="Ingresar apellidos del representante"
                                                    name="apellidoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellidoSearch}
                                                    type="text"
                                                    disabled={this.state.inputApellidosDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifSearch">
                                                    DNI/CIF
                                  </Label>
                                                <Input
                                                    id="cifSearch"
                                                    placeholder="Ingresar CIF del representante"
                                                    name="cifSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifSearch}
                                                    type="text"
                                                    disabled={this.state.inputCifDisabled}
                                                />
                                                {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarRepresentante}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                    </Button>
                                        </Col>
                                    </Row>
                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE REPRESENTANTES"
                                            columns={columnsDatosRepresentante}
                                            data={this.state.representanteDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                            subHeader
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>

                    <Row >
                        <div className="col m-2 d-flex">
                            <Button color="primary" size="sm" type="button"
                                className="btn-icon btn-sm btn-circle"
                                onClick={this.props.history.goBack}
                            >
                                <span className="bi bi-box-arrow-left"> Volver</span>
                            </Button>

                        </div>
                        </Row>


                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>

                                    <div>
                                        <CardTitle>DATOS DEL REPRESENTANTE</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                    Nombre Representante
                                                                                    </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreRepresentante"
                                                    placeholder=""
                                                    name="nombreRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.nombreRepresentante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido1Representante">
                                                    Primer Apellido
                                                                                    </Label>
                                                <Input
                                                    id="apellido1Representante"
                                                    placeholder=""
                                                    name="apellido1Representante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.apellido1Representante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido2Representante">
                                                    Segundo Apellido
                                                                                    </Label>
                                                <Input
                                                    id="apellido2Representante"
                                                    placeholder=""
                                                    name="apellido2Representante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.apellido2Representante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                    NIF/CIF
                                                                                    </Label>
                                                <Input
                                                    id="NIFRepresentante"
                                                    placeholder=""
                                                    name="NIFRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.NIFRepresentante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFRepresentanteError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="CargoRepresentante">
                                                    Cargo
                                                                                    </Label>
                                                <Input
                                                    id="CargoRepresentante"
                                                    placeholder=""
                                                    name="CargoRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.CargoRepresentante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="telefonoRepresentante">
                                                    Teléfono Contacto
                                                                                    </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoRepresentante"
                                                    placeholder=""
                                                    name="telefonoRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.telefonoRepresentante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="correoRepresentante">
                                                    Correo Contacto
                                                                                    </Label>
                                                <Input
                                                    id="correoRepresentante"
                                                    placeholder=""
                                                    name="correoRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.correoRepresentante}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="createdAt">
                                                    Fecha Alta Registro
                                                                                    </Label>

                                                <DatePicker id="createdAt"
                                                    value={createdAt}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled ={true}
                                                />

                                            </FormGroup>

                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechaBaja">
                                                    Fecha Baja Registro
                                                </Label>

                                                <DatePicker id="fechaBaja"
                                                    value={fechaBaja}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled ={true}

                                                />


                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipoViaRepresentante">
                                                    Tipo Via
                                    </Label>
                                                <Input
                                                    id="tipoViaRepresentante"
                                                    placeholder=""
                                                    name="tipoViaRepresentante"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.tipoViaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="viaRepresentante">
                                                    Dirección
                                    </Label>
                                                <Input
                                                    id="viaRepresentante"
                                                    placeholder=""
                                                    name="viaRepresentante"
                                                    maxLength={60}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.viaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numFincaRepresentante">
                                                    Nº Finca
                                    </Label>
                                                <Input
                                                    id="numFincaRepresentante"
                                                    placeholder=""
                                                    name="numFincaRepresentante"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.numFincaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="portalRepresentante">
                                                    Portal
                                    </Label>
                                                <Input
                                                    id="portalRepresentante"
                                                    placeholder=""
                                                    name="portalRepresentante"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.portalRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="escaleraRepresentante">
                                                    ESC.
                                    </Label>
                                                <Input
                                                    id="escaleraRepresentante"
                                                    placeholder=""
                                                    name="escaleraRepresentante"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.escaleraRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="pisoRepresentante">
                                                    Piso
                                    </Label>
                                                <Input
                                                    id="pisoRepresentante"
                                                    placeholder=""
                                                    name="pisoRepresentante"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.pisoRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="puertaRepresentante">
                                                    Puerta
                                    </Label>
                                                <Input
                                                    id="puertaRepresentante"
                                                    placeholder=""
                                                    name="puertaRepresentante"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.puertaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="codigoPostalRepresentante">
                                                    C.Postal
                                    </Label>
                                                <Input
                                                    id="codigoPostalRepresentante"
                                                    placeholder=""
                                                    name="codigoPostalRepresentante"
                                                    onChange={this.onChangeFieldRepre}
                                                    maxLength={5}
                                                    value={formRepre.codigoPostalRepresentante}
                                                    type="text"
                                                />
                                                {this.state.codigoPostalRepresentanteError ? <FormText color="red">Por favor compruebe el código postal del Repre</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label">
                                                    Municipio
                                      </Label>
                                                <SipsOption
                                                    placeholder="Seleccionar localidad"
                                                    //                     invalid={forceUseLocality}
                                                    id="MunicipioRepresentante"
                                                    name="MunicipioRepresentante"
                                                    maxLength={60}
                                                    query="LIST_LOCALITIESNAME"
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.MunicipioRepresentante}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="ProvinciaRepresentante">
                                                    Provincia
                                    </Label>
                                                <Input
                                                    id="ProvinciaRepresentante"
                                                    placeholder=""
                                                    name="ProvinciaRepresentante"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.ProvinciaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="AutonomiaRepresentante">
                                                    C. Autónoma
                                    </Label>
                                                <Input
                                                    id="AutonomiaRepresentante"
                                                    placeholder=""
                                                    name="AutonomiaRepresentante"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldRepre}
                                                    value={formRepre.AutonomiaRepresentante}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            {this.state.alert}
{/*
                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>DOCUMENTOS ASOCIADOS AL REPRESENTANTE</CardTitle>
                                    <Row>

                                        <Col md="12" lg="5">
                                            <Form onSubmit={this.onSubmit}>

                                                <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl} multiple />

                                                <button disabled={this.state.uploadButtonDisabled} id="gpsProptooltip" type="submit" color="#2dce89" className="btn btn-primary btn-sm btn-circle" >
                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Subir Ficheros</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                        SUBIR FICHEROS AL SERVIDOR
                                                                 </UncontrolledTooltip>
                                                </button>
                                            </Form>
                                        </Col>
                                    </Row>

                                    <FormText color="muted">
                                        Seleccionar los fichero para subir al servidor
                                                                        </FormText>

                                    <Row id="contratos_resumen" className="card-deck mt-2">
                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE DOCUMENTOS"
                                                columns={columnsFiles(this.handleButtonClick)}
                                                data={this.state.filesContrato}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight

                                                customStyles={customStyles}

                                            />
                                        </Card>
                                    </Row>
                                </CardBody>
                            </Card>
        */}
                        </div>
                    </Row>
                </Container>

            </>
        );
        }
}


export default withApollo(RepresentanteConsulta);

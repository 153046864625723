import ComisionProductoFreeModal from "./comisionProductoModal";
import axios from "axios";
import React from "react";
import Reflux from "reflux";
//import FormData from 'form-data';
// reactstrap components
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import Header from "../../components/Headers/Header";
import moment from "moment";
import "moment/locale/es";
import { withApollo } from "react-apollo";

import ReactBSAlert from "react-bootstrap-sweetalert";

import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import { Upload } from "@progress/kendo-react-upload";

import { UserStore } from "../../store/UserStore";
//import memoize from 'memoize-one';
import CircularProgress from "@material-ui/core/CircularProgress";
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "./es.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

loadMessages(esMessages, "es-ES");

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require("js-file-download");
const formato = require("xml-formatter");
const spanishDayLabels = ["Dom", "Lu", "Ma", "Mi", "Ju", "Vi", "Sab"];
const spanishMonthLabels = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const sortIcon = <ArrowDownward />;

const uploadRef = React.createRef<Upload>();

const columnsDatosProductosLuz = [
  {
    selector: (row) => row.nombreOferta,
    name: "NOMBRE OFERTA",
    sortable: true,
    width: "220px",
    format: (row) => capitalize(row.nombreOferta),
  },
  {
    selector: (row) => row.nombreComercializadora,
    name: "COMERCIALIZADORA",
    sortable: true,
    width: "240px",
    center: true,
    format: (row) => capitalize(row.nombreComercializadora),
  },
  {
    selector: (row) => row.TarifaDesc,
    name: "TARIFA",
    sortable: true,
    width: "210px",
    center: true,
    format: (row) => capitalize(row.TarifaDesc),
  },
  {
    name: "ACTIVA",
    selector: (row) => row.esActiva,
    sortable: true,
    center: true,
    width: "100px",
    conditionalCellStyles: [
      {
        when: (row) => !row.esActiva,
        style: {
          backgroundImage: "url(/cancelar.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "30px",
          backgroundPosition: "center",
          //height: "50vh",
          //backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: "rgba(250, 250, 250, 0)",
          fontSize: "8px",
          //width: '48px',
          //width: `calc(100vw + 48px)`,
          borderRadius: "5px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) => row.esActiva,
        style: {
          backgroundImage: "url(/ok.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "30px",
          backgroundPosition: "center",
          //height: "50vh",
          //backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: "rgba(250, 250, 250, 0)",
          fontSize: "8px",
          //width: '48px',
          //width: `calc(100vw + 48px)`,
          borderRadius: "5px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ],
  },
  {
    selector: (row) => row.precioTEP1,
    name: "Precio E P1",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTEP2,
    name: "Precio E P2",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTEP3,
    name: "Precio E P3",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTEP4,
    name: "Precio E P4",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTEP5,
    name: "Precio E P5",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTEP6,
    name: "Precio E P6",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP1,
    name: "Precio P P1",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP2,
    name: "Precio P P2",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP3,
    name: "Precio P P3",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP4,
    name: "Precio P P4",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP5,
    name: "Precio P P5",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTP6,
    name: "Precio P P6",
    sortable: true,
    width: "200px",
    center: true,
  },
];

const columnsDatosProductosGas = [
  {
    selector: (row) => row.nombreOferta,
    name: "NOMBRE OFERTA",
    sortable: true,
    width: "220px",
    format: (row) => capitalize(row.nombreOferta),
  },
  {
    selector: (row) => row.nombreComercializadora,
    name: "COMERCIALIZADORA",
    sortable: true,
    width: "240px",
    center: true,
    format: (row) => capitalize(row.nombreComercializadora),
  },
  {
    selector: (row) => row.TarifaDesc,
    name: "TARIFA",
    sortable: true,
    width: "210px",
    center: true,
    format: (row) => capitalize(row.TarifaDesc),
  },
  {
    name: "ACTIVA",
    selector: (row) => row.esActiva,
    sortable: true,
    center: true,
    width: "100px",
    conditionalCellStyles: [
      {
        when: (row) => !row.esActiva,
        style: {
          backgroundImage: "url(/cancelar.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "30px",
          backgroundPosition: "center",
          //height: "50vh",
          //backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: "rgba(250, 250, 250, 0)",
          fontSize: "8px",
          //width: '48px',
          //width: `calc(100vw + 48px)`,
          borderRadius: "5px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) => row.esActiva,
        style: {
          backgroundImage: "url(/ok.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "30px",
          backgroundPosition: "center",
          //height: "50vh",
          //backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: "rgba(250, 250, 250, 0)",
          fontSize: "8px",
          //width: '48px',
          //width: `calc(100vw + 48px)`,
          borderRadius: "5px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ],
  },
  {
    selector: (row) => row.precioTE1,
    name: "Precio E P1",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTE2,
    name: "Precio E P2",
    sortable: true,
    width: "200px",
    center: true,
  },
  {
    selector: (row) => row.precioTFijo,
    name: "Precio T Fijo",
    sortable: true,
    width: "200px",
    center: true,
  },
];

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1);
};

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "12px",
      fontWeight: "bold",
      wordBreak: "break-word",
      paddingLeft: "8px",
      paddingRight: "8px",
      minWidth: "55px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      minWidth: "55px",
      wordBreak: "break-word",
      fontFamily: '"Open Sans", sans-serif;',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 250, 250)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
      fontSize: "12px",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: "24px" }}>
    <CircularProgress size={75} />
  </div>
);

const rowDisabledCriteria = (row) => row.esAprobada;

class activacionProductos extends Reflux.Component<any, any> {
  public maxFileSize = 3;
  public fileInput: any = null;
  public lecturasRef: any = [];
  public inputEl: any = null;
  public formRef: any = null;
  public validFileExtensions = ["jpg", "jpeg", "png"];
  lastFilters = {};
  state = {
    filters: {
      nombreProductoSearch: "",
      idSearch: "",
      cifSearch: "",
      tipoContratoSearch: "E",
      codeComercializadora: "",
      tipoEstadoSearch: "true",
      productoHasta: "",
      productoDesde: "",
    },
    UserStore: {},
    nombreFichero: "",
    isLoading: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    contrato: "",
    tipoContrato: "",
    productoDetailTotal: [],
    productoSeleccionado: false,
    idProducto: "",
    productoDetailSeleccionado: [],
    productoDetail: {
      TarifaDesc: "",
      atencionCliente: "",
      caracteristicas: "",
      coIncluido: false,
      companyId: 0,
      componentesRegulados: false,
      condicionesPenalizacion: "",
      condicionesRevisionPrecios: "",
      consumoMaximoElectricidad: 0,
      consumoMaximoGas: 0,
      consumoMinimoElectricidad: 0,
      consumoMinimoGas: 0,
      descuentoTE: 0,
      descuentoTP: 0,
      esActiva: false,
      facturacion: "",
      id: "",
      idComercializadora: "",
      infoAdicional: "",
      limitaciones: "",
      nombreComercializadora: "",
      nombreOferta: "",
      nombreDocumentoContrato: "",
      ofertaInternet: false,
      ofertaOficina: false,
      ofertaTel: false,
      peaje: "",
      penalizacionMaxElec: 0,
      penalizacionMaxGas: 0,
      periocidadRevisionPrecios: "",
      periodoValidez: "",
      periodoValidezDesde: "",
      periodoValidezHasta: "",
      potenciaMaximaElectricidad: 0,
      potenciaMinimaElectricidad: 0,
      precioAlquilerContadores: 0,
      precioServicios: 0,
      precioTEP1: 0,
      precioTEP2: 0,
      precioTEP3: 0,
      precioTEP4: 0,
      precioTEP5: 0,
      precioTEP6: 0,
      precioTP1: 0,
      precioTP2: 0,
      precioTP3: 0,
      precioTP4: 0,
      precioTP5: 0,
      precioTP6: 0,
      precioTPPeriodo: "0",
      precioTPTipo: "0",
      serviciosNoObligatorios: "",
      serviciosObligatorios: false,
      tarifaATR: "",
      verde: true,
      webContrato: "",
      webOferta: "",
      fechaAltaProducto: "",
      fechaBajaProducto: "",
      tipoProducto: "",
      precioTE1: 0,
      precioTE2: 0,
      precioTFijo: 0,
      factork: 0,
      comisionContratoNuevo: 0,
      comisionContratoRenovacion: 0,
      comisionConsumoAgente1: 0,
      comisionConsumoAgente2: 0,
      comisionConsumoAdmin: 0,
      comisionGOAgente1: 0,
      comisionGOAgente2: 0,
      energiaDesde: 0,
      energiaHasta: 0,
      potenciaDesde: 0,
      potenciaHasta: 0,
      nivel: "",
      importe: 0,
      idComision: 1,
      comisionTipoA: false,
      comisionTipoB: false,
      comisionTipoC: false,
      comisionTipoD: false,
      comisionTipoCOA: false,
      comisionTipoCOB: false,
      comisionTipoCOC: false,
      eurosMwh: false,
      tipoComisionProducto: "",
      comisionProducto: [],
      denominacionProducto: "",
      zona: "",
    },
    companyIdUser: "",
    companyNameGlobal: "",
    userGroupId: 1000,
    userId: "",
    esAgencia: false,
    esComercializadora: false,
    esAdFincas: false,
    saveDataProducto: false,
    updateDataProducto: false,
    saveProductoButtonDisabled: true,
    updateProductoButtonDisabled: true,
    saveXMLButtonDisabled: true,
    buscarButtonDisabled: false,
    CIFRepreError: false,
    inputDisabled: true,
    show: false,
    imgCollection: [],
    saveButtonDisabled: true,
    showFilters: true,
    toggledClearRows: false,
    cupsError: false,
    cifError: false,
    locale: "es",
    inputNombreProductoDisabled: false,
    inputCifDisabled: false,
    inputIdDisabled: true,
    productoDesde: "",
    productoHasta: "",
    fechaAltaProducto: "",
    fechaBajaProducto: "",
    periodoValidezHasta: "",
    periodoValidezDesde: "",
    tipoProducto: "E",
    alert: null,
    paginatedData: {
      items: [],
    },
    files: [],
    events: [],
    filePreviews: {},
    inputDisabledComision: true,
    openModalComparative: false,
    productosCargados: [],
    selectProductButtonDisabled: false,
    selectedRows: [],
    activarButtonDisabled: true,
    desactivarButtonDisabled: false,
  };

  sipsCSVData: any = [];
  viewSipsConsumoAnualCSVData: any = [];
  sipsConsumosCSVData: any = [];
  sipstotal: any = [];

  constructor(props) {
    super(props);
    this.store = UserStore;
    this.formRef = React.createRef();
    this.inputEl = React.createRef();
    this.showModal = this.showModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    const usuario: any = JSON.parse(
      JSON.stringify(this.state.UserStore["data"])
    );

    const companyNameGlobal = usuario.company;
    const companyIdUser = usuario.companyId;

    const userGroupId = usuario.user_group_id;
    const userId = usuario.id;
    const esComercializadora = usuario.comercializadora;
    const esAgencia = usuario.agencia;
    const esAdFincas = usuario.adFincas;

    if (userGroupId === 1000) {
      this.setState({
        activeUser: true,
      });
    }

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      esComercializadora,
      esAgencia,
      esAdFincas,
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  warningAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Error en la base de datos"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          borrar Existe un error en la Base de datos.
        </ReactBSAlert>
      ),
    });
  };

  warningAlertSeleccionado = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="No se han seleccionado ningún producto"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          No existen productos seleccionados para realizar la acción deseada.
        </ReactBSAlert>
      ),
    });
  };

  warningAlertNIF = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="No existen registros que cumplan los requisitos"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          No existen productos con comision asignadas que cumplan los criterios
          de búsqueda
        </ReactBSAlert>
      ),
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Borrada!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          La comisión se ha eliminado del producto.
        </ReactBSAlert>
      ),
    });
  };

  warningAlertFiltros = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Existen campos obligatorios sin información"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Revise los datos introducidos
        </ReactBSAlert>
      ),
    });
  };

  confirmAlertUpdate = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Desea actualizar los datos del producto?"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.onUpdate()}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          cancelBtnText="Actualizar"
          btnSize=""
        >
          No se podrá volver atrás!
        </ReactBSAlert>
      ),
    });
  };

  confirmAlertUpdateActivo = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Producto Activo sin comisión"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          No se puede activar un producto a los comerciales sin tener un tabla
          de comisión asignada
        </ReactBSAlert>
      ),
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Correcto"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Producto actualizado correctamente
        </ReactBSAlert>
      ),
    });
  };

  confirmAlertComision = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Estás seguro de eliminar la comisión del producto?"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.borrar()}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          cancelBtnText="Si, Borrar!"
          btnSize=""
        >
          La comisión se ha eliminado del producto
        </ReactBSAlert>
      ),
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////7

  onChangeFieldSearch = (event) => {
    event.persist();
    const name = event.target.name;
    let value = event.target.value;
    const filters = this.state.filters;

    if (
      event.target.name === "tipoContratoSearch" &&
      event.target.value === "G"
    ) {
      filters["codeComercializadora"] = "";
    } else if (
      event.target.name === "tipoContratoSearch" &&
      event.target.value === "E"
    ) {
      filters["codeComercializadora"] = "";
    }

    if (
      event.target.name === "tipoEstadoSearch" &&
      event.target.value === "true"
    ) {
      this.setState({
        activarButtonDisabled: true,
        desactivarButtonDisabled: false,
      });
    } else if (
      event.target.name === "tipoEstadoSearch" &&
      event.target.value === "false"
    ) {
      this.setState({
        activarButtonDisabled: false,
        desactivarButtonDisabled: true,
      });
    }

    if (
      event.target.name === "nombreProductoSearch" &&
      event.target.value.length == 0
    ) {
      this.setState({
        cupsError: false,
        buscarButtonDisabled: true,
        inputCifDisabled: false,
        inputNombreProductoDisabled: false,
        inputIdDisabled: false,
      });
    } else if (
      event.target.name === "nombreProductoSearch" &&
      event.target.value.length > 0
    ) {
      this.setState({
        inputCifDisabled: true,
        inputIdDisabled: true,
        buscarButtonDisabled: false,
      });
    }

    if (event.target.name === "idSearch" && event.target.value.length == 0) {
      this.setState({
        buscarButtonDisabled: true,
        inputCifDisabled: false,
        inputNombreProductoDisabled: false,
        inputIdDisabled: false,
      });
    } else if (
      event.target.name === "idSearch" &&
      event.target.value.length > 0
    ) {
      this.setState({
        inputCifDisabled: true,
        inputNombreProductoDisabled: true,
        buscarButtonDisabled: false,
      });
    }

    if (
      event.target.name == "tipoContratoSearch" ||
      event.target.name == "codeComercializadora" ||
      event.target.name == "tipoEstadoSearch" ||
      event.target.name == "productoDesde" ||
      event.target.name == "productoHasta"
    ) {
      this.setState({
        buscarButtonDisabled: false,
        inputCifDisabled: false,
        inputNombreProductoDisabled: false,
        inputIdDisabled: false,
      });
    }

    if (event.target.value == "Selecciona...") {
      value = "";
    }

    if (value === "true" || value == "false") {
      value = JSON.parse(value);
    }

    filters[name] = value;

    this.setState({ filters });
  };

  //////////////////////////////////////////////////////////////////7

  showFiltersToggle = () => {
    const showFilters = !this.state.showFilters;

    this.setState({ showFilters });
  };

  getFilterIcon = () => {
    return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
  };

  ///////////////////////////////////////////////////////////////////////////////

  toggle() {
    this.setState({
      openModalComparative: !this.state.openModalComparative,
    });
  }

  ///////////////////////////////////////////////////////////////////////////////

  showModal() {
    this.setState({
      openModalComparative: true,
    });
  }

  ////////////////////////////////////////////////////////////////////////////

  openModalComparativa = async (event) => {
    this.showModal();
    return;
  };

  //////////////////////////////////////////////////////////////////////////7

  deleteComision = async (event) => {
    this.confirmAlertComision();
  };

  //////////////////////////////////////////////////////////////////////////7

  borrar = async () => {
    var formData = { ...this.state.formProducto };
    let comision: any = [];
    formData["tipoComisionProducto"] = "";
    formData["comisionProducto"] = [];
    this.setState({
      formProducto: formData,
      dataComisiones: comision,
      updateProductoButtonDisabled: false,
      updateDataProducto: true,
    });
    this.confirmedAlert();
  };

  /////////////////////////////////////////////////////////////////////////

  clearForm = () => {
    const filters = {};
    this.setState({
      filters: {
        nombreProductoSearch: "",
        idSearch: "",
        cifSearch: "",
        tipoContratoSearch: "E",
        codeComercializadora: "",
        tipoEstadoSearch: "true",
        productoHasta: "",
        productoDesde: "",
      },
      inputCifDisabled: false,
      inputNombreProductoDisabled: false,
      inputIdDisabled: false,
      inputDisabled: false,
      buscarButtonDisabled: false,
      productoSeleccionado: false,
      productoDetailTotal: [],
      toggledClearRows: !this.state.toggledClearRows,
      saveProductoButtonDisabled: true,
      updateProductoButtonDisabled: true,
      productosCargados: [],
      selectProductButtonDisabled: false,
      selectedRows: [],
      activarButtonDisabled: true,
      desactivarButtonDisabled: false,
    });
    this.formRef.current.reset();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////7

  buscarProducto = async () => {

    var idComercializadoraAux = "";
    if (
      this.state.filters.codeComercializadora != "0" &&
      this.state.filters.codeComercializadora != ""
    ) {
      idComercializadoraAux = this.state.filters.codeComercializadora;
    }

    var dataFilters = {
      companyId: this.state.companyIdUser,
      idComercializadora: idComercializadoraAux,
      id: this.state.filters.idSearch,
      nombreOferta: this.state.filters.nombreProductoSearch,
      tipoContrato: this.state.filters.tipoContratoSearch,
      esActiva: this.state.filters.tipoEstadoSearch,
      periodoValidezDesde: this.state.filters.productoDesde,
      periodoValidezHasta: this.state.filters.productoHasta,
    };

    const valores = JSON.stringify(dataFilters);
    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };
    let direccionFile =
      "https://datapi.psgestion.es/producto/listado/estado?id=" +
      this.state.companyIdUser;

    axios
      .get(direccionFile, { headers, params: { valores } })
      .then((result) => {

        if (result.data.rows.length > 0) {
          let data = result.data.rows;

          this.setState({ productoDetailTotal: data });
        } else {
          this.setState({ productoDetailTotal: [] });
          this.warningAlertNIF();
        }
      })
      .catch((error) => {
        console.log("Failure");
        this.warningAlert();
      });
  };

  ////////////////////////////////////////////////////////////////////////////////////

  onActivacion = async (activacion) => {
    var booleanActivacion = true;

    if (activacion == "desactivar") {
      booleanActivacion = false;
    }

    const companyId = this.state.companyIdUser.toString();
    const userId = this.state.userId.toString();

    const productoDataUpdate = {
      esActiva: booleanActivacion,
      productos: this.state.productoDetailSeleccionado,
    };

    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
      "x-api-key":
        "$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W",
    };
    let direccionFile =
      "https://datapi.psgestion.es/producto/activacion?id=" +
      companyId +
      "&user=" +
      userId;

    axios
      .post(direccionFile, productoDataUpdate, {
        headers,
        responseType: "json",
      })
      .then((res) => {
        //console.log("Actualización correcta", res);
        //console.log("Dapos del registro: ", res.data);
        this.successAlert();
        this.clearForm();
      })
      .catch((error) => {
        console.log("Failure");
        console.log("Error en el proceso: ", error);
        this.warningAlert();
      });

    this.setState({
      updateDataProducto: false,
      updateProductoButtonDisabled: true,
    });
  };

  approvedHandler(props, activacion) {
    if (this.state.productoDetailSeleccionado.length > 0) {
      if (activacion == "activar") {
        this.onActivacion("activar");
      } else if (activacion == "desactivar") {
        this.onActivacion("desactivar");
      }
    } else {
      this.warningAlertSeleccionado();
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////

  handleChangeDate = (value, formattedValue, id) => {
    var formData = { ...this.state.formProducto };

    const name = id;
    var valueDay = formattedValue;

    if (name == "productoDesde") {
      this.setState({ productoDesde: value });
    }

    if (name == "productoHasta") {
      this.setState({ productoHasta: value });
    }

    formData[name] = valueDay;

    this.setState({ formProducto: formData });
  };

  //////////////////////////////////////////////////////////////////////////////

  handleChange = (state) => {
    // eslint-disable-next-line no-console
   
    if (state.selectedCount > 0) {
      this.setState({ productoDetailSeleccionado: state.selectedRows });
    } else {
      this.setState({ productoDetailSeleccionado: [] });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////

  tipoContrato = (props) => {
    const tipoContrato = props.tipoContrato;
    if (this.state.esAgencia == true || this.state.esAdFincas == true) {
      if (tipoContrato === "L" || tipoContrato === "E") {
        return (
          <React.Fragment>
            <Col md="12" lg="4">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="codigoComercializadora"
                >
                  Comercializadora
                </Label>
                <SipsOption
                  placeholder="Seleccionar comercializadora"
                  id="codeComercializadora"
                  name="codeComercializadora"
                  value={this.state.filters.codeComercializadora}
                  query="LIST_MARKETERS"
                  onChange={this.onChangeFieldSearch}
                />
              </FormGroup>
            </Col>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Col md="12" lg="4">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="codigoComercializadora"
                >
                  Comercializadora
                </Label>
                <SipsOption
                  placeholder="Seleccionar comercializadora"
                  id="codeComercializadora"
                  name="codeComercializadora"
                  value={this.state.filters.codeComercializadora}
                  query="LIST_MARKETERS_GAS"
                  onChange={this.onChangeFieldSearch}
                />
              </FormGroup>
            </Col>
          </React.Fragment>
        );
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  /////////////////////////////////////////////////////////////////////////////////

  tipoLecturaTable = (props) => {
    const tipoContrato = props.tipoContrato;

    if (tipoContrato === "G") {
      return (
        <React.Fragment>
          <Card className="shadow">
            <DataTable
              title="LISTADO DE PRODUCTOS GAS"
              columns={columnsDatosProductosGas}
              data={this.state.productoDetailTotal}
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
              sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
              noDataComponent={"Sin datos para mostrar"}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
              onSelectedRowsChange={this.handleChange}
              selectableRowDisabled={rowDisabledCriteria}
              clearSelectedRows={this.state.toggledClearRows}
              highlightOnHover
              striped
              pointerOnHover
              selectableRowsHighlight
              customStyles={customStyles}
              contextMessage={{
                singular: "producto",
                plural: "productos",
                message: "seleccionado",
              }}
              subHeader
              subHeaderComponent={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CSVLink
                    data={this.state.productoDetailTotal}
                    filename={"ListadoProductosGasDetalle.csv"}
                    className="btn btn-default btn-sm btn-circle"
                    color="default"
                    target="_blank"
                    separator={";"}
                  >
                    <span className="btn-inner--icon">
                      <i
                        className={"bi bi-file-earmark-spreadsheet"}
                        style={{ fontSize: 25 }}
                      ></i>
                    </span>
                    <span className="btn-inner--text">Fichero CSV</span>
                  </CSVLink>
                  <Button
                    disabled={this.state.activarButtonDisabled}
                    color="success"
                    size="m"
                    className="float-right"
                    onClick={() => {
                      this.approvedHandler(this.state.selectedRows, "activar");
                    }}
                  >
                    <i
                      className={"bi bi-check-square"}
                      style={{ fontSize: 20 }}
                    />{" "}
                    ACTIVAR
                  </Button>

                  <Button
                    disabled={this.state.desactivarButtonDisabled}
                    color="warning"
                    size="m"
                    className="float-right"
                    onClick={() => {
                      this.approvedHandler(
                        this.state.selectedRows,
                        "desactivar"
                      );
                    }}
                  >
                    <i className={"bi bi-x-circle"} style={{ fontSize: 20 }} />{" "}
                    DESACT.
                  </Button>
                </div>
              }
              progressComponent={<Circular />}
            />
          </Card>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Card className="shadow">
            <DataTable
              title="LISTADO DE PRODUCTOS ELECTRICIDAD"
              columns={columnsDatosProductosLuz}
              data={this.state.productoDetailTotal}
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
              sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
              noDataComponent={"Sin datos para mostrar"}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
              onSelectedRowsChange={this.handleChange}
              selectableRowDisabled={rowDisabledCriteria}
              clearSelectedRows={this.state.toggledClearRows}
              highlightOnHover
              striped
              pointerOnHover
              selectableRowsHighlight
              customStyles={customStyles}
              contextMessage={{
                singular: "producto",
                plural: "productos",
                message: "seleccionado",
              }}
              subHeader
              subHeaderComponent={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CSVLink
                    data={this.state.productoDetailTotal}
                    filename={"ListadoProductosGasDetalle.csv"}
                    className="btn btn-default btn-sm btn-circle"
                    color="default"
                    target="_blank"
                    separator={";"}
                  >
                    <span className="btn-inner--icon">
                      <i
                        className={"bi bi-file-earmark-spreadsheet"}
                        style={{ fontSize: 25 }}
                      ></i>
                    </span>
                    <span className="btn-inner--text">Fichero CSV</span>
                  </CSVLink>
                  <Button
                    disabled={this.state.activarButtonDisabled}
                    color="success"
                    size="m"
                    className="float-right"
                    onClick={() => {
                      this.approvedHandler(this.state.selectedRows, "activar");
                    }}
                  >
                    <i
                      className={"bi bi-check-square"}
                      style={{ fontSize: 20 }}
                    />{" "}
                    ACTIVAR
                  </Button>

                  <Button
                    disabled={this.state.desactivarButtonDisabled}
                    color="warning"
                    size="m"
                    className="float-right"
                    onClick={() => {
                      this.approvedHandler(
                        this.state.selectedRows,
                        "desactivar"
                      );
                    }}
                  >
                    <i className={"bi bi-x-circle"} style={{ fontSize: 20 }} />{" "}
                    DESACT.
                  </Button>
                </div>
              }
              progressComponent={<Circular />}
            />
          </Card>
        </React.Fragment>
      );
    }
  };

  //////////////////////////////////////////////////////////////////////////////////

  render() {
    const productoDesde = this.state.productoDesde;
    const productoHasta = this.state.productoHasta;

    var textoTipoContrato = "";
    if (this.state.tipoContrato == "G") {
      textoTipoContrato = "Gas";
    } else {
      textoTipoContrato = "Eléctrico";
    }

    if (this.state.userGroupId == 1000) {
      return (
        <>
          <LocalizationProvider language="es-ES">
            <IntlProvider locale="es">
              <Header title={"Activación Productos"} />
              <Container className=" mt--7" fluid>
                <Card className="mb-4">
                  <CardHeader>
                    <Button
                      onClick={this.showFiltersToggle}
                      size="sm"
                      className="float-right"
                    >
                      <i className={"ni " + this.getFilterIcon()} />
                    </Button>
                    <h3 className="mb-0">Filtrar</h3>
                  </CardHeader>
                  <Collapse isOpen={this.state.showFilters}>
                    <CardBody>
                      <Form innerRef={this.formRef}>
                        <Row>
                          <Col md="12" lg="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="productoRef"
                              >
                                NOMBRE PRODUCTO
                              </Label>
                              <Input
                                id="nombreProductoSearch"
                                placeholder="Ingresar nombre del producto"
                                name="nombreProductoSearch"
                                maxLength={60}
                                onChange={this.onChangeFieldSearch}
                                value={this.state.filters.nombreProductoSearch}
                                type="text"
                                disabled={
                                  this.state.inputNombreProductoDisabled
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col md="2" lg="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="tipoEnergia"
                              >
                                TIPO CONTRATO
                              </Label>
                              <Input
                                type="select"
                                name="tipoContratoSearch"
                                id="tipoContratoSearch"
                                onChange={this.onChangeFieldSearch}
                                value={this.state.filters.tipoContratoSearch}
                              >
                                <option value="E">ELECTRICIDAD</option>
                                <option value="G">GAS</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <this.tipoContrato
                            tipoContrato={this.state.filters.tipoContratoSearch}
                          />

                          <Col md="2" lg="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="tipoEstadoProducto"
                              >
                                ESTADO
                              </Label>
                              <Input
                                type="select"
                                name="tipoEstadoSearch"
                                id="tipoEstadoSearch"
                                onChange={this.onChangeFieldSearch}
                                value={this.state.filters.tipoEstadoSearch}
                              >
                                <option value="true">ACTIVO</option>
                                <option value="false">BAJA</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Label className="form-control-label">
                                Desde
                              </Label>
                              <DatePicker
                                id="productoDesde"
                                value={productoDesde}
                                onChange={(v, f) =>
                                  this.handleChangeDate(v, f, "productoDesde")
                                }
                                instanceCount={1}
                                dateFormat={"DD-MM-YYYY"}
                                dayLabels={spanishDayLabels}
                                monthLabels={spanishMonthLabels}
                                disabled={this.state.inputDisabled}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Label className="form-control-label">
                                Hasta
                              </Label>
                              <DatePicker
                                id="productoHasta"
                                value={productoHasta}
                                onChange={(v, f) =>
                                  this.handleChangeDate(v, f, "productoHasta")
                                }
                                instanceCount={1}
                                dateFormat={"DD-MM-YYYY"}
                                dayLabels={spanishDayLabels}
                                monthLabels={spanishMonthLabels}
                                disabled={this.state.inputDisabled}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="text-right">
                            <Button onClick={this.clearForm}>
                              <i className={"ni ni-fat-remove"} /> Limpiar
                            </Button>
                            <Button
                              color="primary"
                              onClick={this.buscarProducto}
                              disabled={this.state.buscarButtonDisabled}
                            >
                              <i className={"bi bi-search"} /> Buscar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Collapse>
                </Card>

                <Row>
                  <div className="col">
                    <Card className="shadow">
                      <CardBody>
                        <Row>
                          <Col>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={this.props.history.goBack}
                            >
                              Atrás
                            </button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Row>

                <Row id="contratos_resumen" className="card-deck mt-2">
                  <this.tipoLecturaTable
                    tipoContrato={this.state.filters.tipoContratoSearch}
                  />
                </Row>

                {this.state.alert}
              </Container>
            </IntlProvider>
          </LocalizationProvider>
        </>
      );
    } else {
      return (
        <>
          <Header
            title={
              "No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"
            }
          />
        </>
      );
    }
  }
}

export default withApollo(activacionProductos);

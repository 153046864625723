import axios from 'axios';
import { fromExcelDate } from 'js-excel-date-convert';
import memoize from 'memoize-one';
import React from "react";
import { CSVLink } from "react-csv";
import Reflux from "reflux";
import LoadingOverlay from "react-loading-overlay";

//import FormData from 'form-data';
// reactstrap components
import {
    Card, CardBody, CardTitle, FormGroup, Col, Label, Input, Container, Form, FormText, Row, UncontrolledTooltip
} from "reactstrap";

//import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";

import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import { UserStore } from "../../store/UserStore";
//import memoize from 'memoize-one';
const FileDownload = require('js-file-download');

const sortIcon = <ArrowDownward />;
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const columnsFiles = memoize(clickHandler => [
    { selector: row => row['id'], name: 'ID', sortable: true, width: '130px',  format: (row => capitalize(row.id))  },
    { selector: row => row.nombreOferta, name: 'NOMBRE', sortable: true, width: '150px', center: true, format: (row => capitalize(row.nombreOferta)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '180px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row['TarifaDesc'], name: 'TARIFA', sortable: true, width: '120px', center: true },
]);


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

class cargaProductos extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public inputEl: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        page: 1,
        limit: 10,
        sipsConsumosTotal: [],
        sipsConsumos: [],
        sipsDetail: [],
        contratoDetail: {
            fechaActivacion: "",
            fechaFirmaContrato: '',
            fechaVencimientoContrato: '',
            CIF: '',
            desMunicipioTitular: '',
            tipoViaTitular: '',
            viaTitular: '',
            numFincaTitular: '',
            portalTitular: '',
            escaleraTitular: '',
            pisoTitular: '',
            puertaTitular: '',
            cups: '',
            idContrato: '',
            idTarifaPeaje: '',
            nombreProducto: '',
            precioTEP1: 0,
            precioTEP3: 0,
            nombreTitular: '',
            apellido1Titular: '',
            apellido2Titular: '',
            desProvinciaTitular: '',
            iban: ''
        },
        isLoadingSips: true,
        isLoadingConsumos: true,
        isLoadingConsumosTotales: true,
        changeLogoLoading: false,
        updateProfileLoading: false,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        mibgasPrice: [],
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',

        fechaActivacion: '',
        fechaBajaContrato: '',
        fechaContrato: '',
        fechaFinContrato: '',
        fechaFirmaContrato: '',
        fechaInicioContrato: '',
        fechaVencimientoContrato: '',
        fechaActivacionFlag: 0,
        fechaFirmaContratoFlag: 0,
        fechaVencimientoContratoFlag: 0,
        saveData: false,
        saveDataRepre: false,
        saveProductButtonDisabled: true,
        contratoButtonDisabled: false,
        saveXMLButtonDisabled: true,
        idComercializadoraActualContrato: '',
        nombreComercializadoraActualContrato: '',
        cups: '',
        CIF: '',
        consumoAnualCup: '',
        idRepresentante: '',
        representanteDetail: [],
        formRepre: {
            CIFRepre: '',
            CargoRepre: '',
            apellido1Representante: '',
            apellido2Representante: '',
            companyIdRepresentante: '',
            correoRepresentante: '',
            idRepresentante: '',
            idRepresentanteInterno: 0,
            nombreRepresentante: '',
            telefonoRepresentante: ''
        },
        CIFRepreError: false,
        inputDisabled: false,
        imgCollection: [],
        saveButtonDisabled: true,
        ultimaRemesa: "",
        tipoEmpresaServicio:"",
        alert: null

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.fileInput = React.createRef();
        this.inputEl = React.createRef();
    }

    UNSAFE_componentWillReceiveProps() {

        console.log(this.props)
        console.log(this.state)

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALOR DE USUARIO ----------------->', usuario)



        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    };

    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        console.log('VALORES DE MATCH ENVIADOS POR GESTION CONTRATO', params)
        //this.setState({ contrato: params.cups,
        //    tipoContrato: params.tipo }, this.fetchItems);
        //this.fetchItems();
        this.setState({
            contrato: params.cups,
            tipoContrato: params.tipo
        });
        //this.fetchRepre();
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    faltaDatos= () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos en los campos de tipo de empresa y servicio asociado
        </ReactBSAlert>
            )
        });
        };

        successAlert = () => {
            this.setState({
                alert: (
                    <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Correcto"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        Datos cargados correctamente
                    </ReactBSAlert>
                )
            });
        };

        errorDB= () => {
            this.setState({
                alert: (
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Error en el proceso de inserción"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                    Se ha producido un error en el proceso de inserción. Si se vuelve a producir comunicar la incidencia al equipo de soporte
            </ReactBSAlert>
                )
            });
            };

    export = () => { };

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        //this.setState({ imgCollection: e.target.files })
        console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

        let tamañoFiles = e.target.files.length;
        console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }

    };

    onSubmit(e) {
        e.preventDefault()

        if( !this.state.tipoEmpresaServicio){

            this.faltaDatos()
    
           } else {

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }

        this.setState({

            isLoading: true,
        })

        const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
        const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""
        

        //var user: any = [];

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []

        const tipoEmpresa = this.state.tipoEmpresaServicio
             
        formData.append('tipo', tipoEmpresa);
        formData.append('usuario', userID);
       

        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/files/upload/productos?id=" + this.state.companyIdUser+ "&tipo=" + tipoEmpresa;


        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            console.log('resultado de la subida')
            console.log(result.data);
            this.setState({

                isLoading: false,
            })

            this.successAlert();

           // this.inputEl.current.value = {""}
            this.setState({ mibgasPrice: result.data })
            this.setState({ imgCollection: [] })
            this.setState({ saveButtonDisabled: true })

        }).catch( (error) => {
            console.log('Failure: ', error)
            this.errorDB()
            this.setState({
                isLoading: false,
              });
            
        });
    }
    };

/////////////////////////////////////////////////////////////////////////////////////

    readUploadedFileAsText = (inputFile) => {

        console.log('DENTRO DE LA FUNCION READUPLODADED-----> ', inputFile)
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            //temporaryFileReader.readAsText(inputFile);
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };




    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const id = state.target.id;
        const file = state.target.value
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/download?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato + "&file=" + file;

        console.log(direccionFileList)
        axios.get(direccionFileList,
            {
                headers,
                responseType: 'blob'
            }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch(function () {
                console.log('Failure')
            });



    };


    showFile = (blob) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "contratoGasCalordom.pdf";
        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    onChangeFieldEmpresaServicio = (event) => {

        event.persist()


        let value = event.target.value;
        let name = event.target.name

        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
        }

        if (value === "" || value === null) {
            //delete setFilters[name];
        }

        this.setState({ tipoEmpresaServicio: value });
    };


    render() {
        const isLoading = this.state.isLoading;
        const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
        const disableExportClass = disableExport ? " disabled" : "";

        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)



        return (
            <>
                <Header title={'Carga masiva de productos'} />
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>



                                    </Row>
                                </CardBody>
                            </Card>



                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>CARGA DE FICHERO DE PRODUCTOS</CardTitle>
                                    <Row>

                                        <Col md="12" lg="5" className ="pt-3">
                                            <Form onSubmit={this.onSubmit}>

                                                <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl} multiple />

                                                <button disabled={this.state.saveButtonDisabled} id="gpsProptooltip" type="submit" color="#2dce89" className="btn btn-primary btn-sm btn-circle" >
                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Subir Ficheros</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                        SUBIR FICHERO XLSX DE PRODUCTOS AL SERVIDOR
                                                    </UncontrolledTooltip>
                                                </button>
                                            </Form>
                                        </Col>

                                        <Col lg="auto">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoEmpresaServicio">Servicio</Label>
                                                    <Input type="select" name="tipoEmpresaServicio" id="tipoEmpresaServicio"
                                                        onChange={this.onChangeFieldEmpresaServicio}
                                                        value={this.state.tipoEmpresaServicio}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="E">ELECTRICIDAD</option>
                                                        <option value="G">GAS</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                    </Row>

                                    <FormText color="muted">
                                        Seleccionar los fichero XLSX para subir al servidor y procesar
                                    </FormText>
                                    <LoadingOverlay
                                        active={isLoading}
                                        spinner
                                        text="Cargando...">


                                    <Row id="contratos_resumen" className="card-deck mt-2">
                                        <Card className="shadow">
                                            <DataTable
                                                title="PRODUCTOS CARGADOS"
                                                columns={columnsFiles(this.handleButtonClick)}
                                                data={this.state.mibgasPrice}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight

                                                customStyles={customStyles}

                                                subHeader
                                                subHeaderComponent={
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <CSVLink
                                                            data={this.state.mibgasPrice}
                                                            filename={"PreciosMibGas.csv"}
                                                            className="btn btn-default btn-sm btn-circle"
                                                            color="default"
                                                            target="_blank"
                                                            separator={";"}
                                                        >

                                                            <span className="btn-inner--icon">
                                                                <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Fichero CSV</span>

                                                        </CSVLink>
                                                    </div>
                                                }

                                            />
                                        </Card>
                                    </Row>
                                    </LoadingOverlay>
                                </CardBody>
                            </Card>
                            {this.state.alert}
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}


export default withApollo(cargaProductos);

import ComisionProductoFreeModal from "./comisionProductoModal";

import React from "react";
import Reflux from "reflux";
import axios from 'axios';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";

import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "./es.json";
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(esMessages, "es-ES");


var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const sortIcon = <ArrowDownward />;

const columnsDatosProductosLuz = [
    { selector: row => row.nombreOferta, name: 'NOMBRE OFERTA', sortable: true, width: '220px', format: (row => capitalize(row.nombreOferta)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row.TarifaDesc, name: 'Tarifa', sortable: true, width: '210px', center: true, format: (row => capitalize(row.TarifaDesc)) },
    { selector: row => row.esActiva, name: 'ACTIVA', sortable: true, width: '100px', center: true },
    { selector: row => row.precioTEP1, name: 'Precio E P1', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTEP2, name: 'Precio E P2', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTEP3, name: 'Precio E P3', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTEP4, name: 'Precio E P4', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTEP5, name: 'Precio E P5', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTEP6, name: 'Precio E P6', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP1, name: 'Precio P P1', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP2, name: 'Precio P P2', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP3, name: 'Precio P P3', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP4, name: 'Precio P P4', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP5, name: 'Precio P P5', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTP6, name: 'Precio P P6', sortable: true, width: '200px', center: true },
];


const columnsDatosProductosGas = [
    { selector: row => row.nombreOferta, name: 'NOMBRE OFERTA', sortable: true, width: '220px', format: (row => capitalize(row.nombreOferta)) },
    { selector: row => row.nombreComercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreComercializadora)) },
    { selector: row => row.TarifaDesc, name: 'Tarifa', sortable: true, width: '210px', center: true, format: (row => capitalize(row.TarifaDesc)) },
    { selector: row => row.esActiva, name: 'ACTIVA', sortable: true, width: '100px', center: true },
    { selector: row => row.precioTE1, name: 'Precio E P1', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTE2, name: 'Precio E P2', sortable: true, width: '200px', center: true },
    { selector: row => row.precioTFijo, name: 'Precio T Fijo', sortable: true, width: '200px', center: true }
];

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

const columnsDatosComisionTipo1 = [
    { selector: row => row.energiaDesde, name: 'ENERGIA >', sortable: true, width: '130px' },
    { selector: row => row.energiaHasta, name: '< ENERGIA', sortable: true, width: '130px', center: true },
    { selector: row => row.potenciaDesde, name: 'POTENCIA >', sortable: true, width: '130px', center: true },
    { selector: row => row.potenciaHasta, name: '< POTENCIA', sortable: true, width: '130px', center: true },
    { selector: row => row.nivel, name: 'NIVEL', sortable: true, width: '80px', center: true },
    { selector: row => row.importe, name: 'COMISION', sortable: true, width: '130px', center: true }
];

class crearProducto extends Reflux.Component<any, any> {
    public maxFileSize = 1;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombreProductoSearch: "",
            idSearch: "",
            cifSearch: "",
            tipoContratoSearch: "",
            codeComercializadora: "",
            tipoEstadoSearch: "",
            productoHasta: "",
            productoDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        productoDetailTotal: [],
        productoSeleccionado: false,
        idProducto: '',
        productoDetail: {
            TarifaDesc: "",
            atencionCliente: "",
            caracteristicas: "",
            coIncluido: false,
            companyId: 0,
            componentesRegulados: false,
            condicionesPenalizacion: "",
            condicionesRevisionPrecios: "",
            consumoMaximoElectricidad: 0,
            consumoMaximoGas: 0,
            consumoMinimoElectricidad: 0,
            consumoMinimoGas: 0,
            descuentoTE: 0,
            descuentoTP: 0,
            esActiva: false,
            facturacion: "",
            id: "",
            idComercializadora: "",
            infoAdicional: "",
            limitaciones: "",
            nombreComercializadora: "",
            nombreOferta: "",
            nombreDocumentoContrato: "",
            ofertaInternet: false,
            ofertaOficina: false,
            ofertaTel: false,
            peaje: "",
            penalizacionMaxElec: 0,
            penalizacionMaxGas: 0,
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            potenciaMaximaElectricidad: 0,
            potenciaMinimaElectricidad: 0,
            precioAlquilerContadores: 0,
            precioServicios: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTPPeriodo: "0",
            precioTPTipo: "0",
            serviciosNoObligatorios: "",
            serviciosObligatorios: false,
            tarifaATR: "",
            verde: true,
            webContrato: "",
            webOferta: "",
            fechaAltaProducto: '',
            fechaBajaProducto: '',
            tipoProducto: '',
            precioTE1: 0,
            precioTE2: 0,
            precioTFijo: 0,
            factork: 0,
            comisionContratoNuevo: 0,
            comisionContratoRenovacion: 0,
            comisionConsumoAgente1: 0,
            comisionConsumoAgente2: 0,
            comisionConsumoAdmin: 0,
            comisionGOAgente1: 0,
            comisionGOAgente2: 0,
            energiaDesde: 0,
            energiaHasta: 0,
            potenciaDesde: 0,
            potenciaHasta: 0,
            nivel: "",
            importe: 0,
            idComision: 1,
            comisionTipoA: false,
            comisionTipoB: false,
            comisionTipoC: false,
            comisionTipoD: false,
            comisionTipoCOA: false,
            comisionTipoCOB: false,
            comisionTipoCOC: false,
            eurosMwh: false,
            tipoComisionProducto: "",
            comisionProducto: [],
            denominacionProducto: "", 
            zona:""
        },
        formProducto: {
            TarifaDesc: "",
            atencionCliente: "",
            caracteristicas: "",
            coIncluido: false,
            companyId: 0,
            componentesRegulados: false,
            condicionesPenalizacion: "",
            condicionesRevisionPrecios: "",
            consumoMaximoElectricidad: 0,
            consumoMaximoGas: 0,
            consumoMinimoElectricidad: 0,
            consumoMinimoGas: 0,
            descuentoTE: 0,
            descuentoTP: 0,
            esActiva: false,
            facturacion: "",
            id: "",
            idComercializadora: "",
            infoAdicional: "",
            limitaciones: "",
            nombreComercializadora: "",
            nombreOferta: "",
            nombreDocumentoContrato: "",
            ofertaInternet: false,
            ofertaOficina: false,
            ofertaTel: false,
            peaje: "",
            penalizacionMaxElec: 0,
            penalizacionMaxGas: 0,
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            potenciaMaximaElectricidad: 0,
            potenciaMinimaElectricidad: 0,
            precioAlquilerContadores: 0,
            precioServicios: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTPPeriodo: "0",
            precioTPTipo: "0",
            serviciosNoObligatorios: "",
            serviciosObligatorios: false,
            tarifaATR: "",
            verde: true,
            webContrato: "",
            webOferta: "",
            fechaAltaProducto: '',
            fechaBajaProducto: '',
            tipoProducto: '',
            precioTE1: 0,
            precioTE2: 0,
            precioTFijo: 0,
            factork: 0,
            comisionContratoNuevo: 0,
            comisionContratoRenovacion: 0,
            comisionConsumoAgente1: 0,
            comisionConsumoAgente2: 0,
            comisionConsumoAdmin: 0,
            comisionGOAgente1: 0,
            comisionGOAgente2: 0,
            energiaDesde: 0,
            energiaHasta: 0,
            potenciaDesde: 0,
            potenciaHasta: 0,
            nivel: "",
            importe: 0,
            idComision: 1,
            comisionTipoA: false,
            comisionTipoB: false,
            comisionTipoC: false,
            comisionTipoD: false,
            comisionTipoCOA: false,
            comisionTipoCOB: false,
            comisionTipoCOC: false,
            eurosMwh: false,
            tipoComisionProducto: "",
            comisionProducto: [],
            denominacionProducto:"", 
            zona:""
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataProducto: false,
        updateDataProducto: false,
        saveProductoButtonDisabled: true,
        updateProductoButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFRepreError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        productoDesde: '',
        productoHasta: '',
        fechaAltaProducto: '',
        fechaBajaProducto: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        tipoProducto: 'E',
        inputDisabledP3456: false,
        inputDisabledE456: false,
        alert: null,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        inputDisabledComision: true,
        openModalComparative: false,
        productosCargados: [],
        comisionProducto: [],
        comisionDisabled: true,
        comisionDisabledA: true,
        comisionDisabledB: true,
        comisionDisabledC: true,
        comisionDisabledD: true,
        comisionDisabledCOA: true,
        comisionDisabledCOB: true,
        comisionDisabledCOC: true,
        dataComisiones: [{
            id: "",
            comisionID: 1,
            energiaDesde: 0,
            energiaHasta: 0,
            coReferencia: 0,
            coReferenciaPorcentaje: 0,
            coStandard: 0,
            coStandardPorcentaje: 0
        }],
        editID: 1,
        selectedRows: [],
        selectProductoCopyButtonDisabled: true


    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.inputEl = React.createRef();
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        const filters = this.state.filters;

        filters['tipoContratoSearch'] = params.tipo 

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas,
            tipoProducto: params.tipo,
            filters

        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el producto?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmedAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Borrada!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    La comisión se ha eliminado del producto.
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Producto creado correctamente
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdateActivo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Producto Activo sin comisión"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    No se puede activar un producto a los comerciales sin tener un tabla de comisión asignada
                </ReactBSAlert>
            )
        });
    };

    confirmAlertComision = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de eliminar la comisión del producto?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.borrar()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Borrar!"
                    btnSize=""
                >
                    La comisión se ha eliminado del producto
                </ReactBSAlert>
            )
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////7

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        let tamañoFiles = e.target.files.length;

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    onSubmit(e) {
        e.preventDefault()

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }
        var ultimaRemesa = ""
        let tipoProducto = this.state.tipoProducto
        let userId = this.state.userId

        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/files/upload/productos?id=" + this.state.companyIdUser + "&user=" + userId + "&tipo=" + tipoProducto;

        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            this.setState({ productosCargados: result.data })
            this.setState({ imgCollection: [] })
            this.setState({ saveButtonDisabled: true })
            this.inputEl.current.value = ''
        }).catch(function () {
            console.log('Failure')
        });

    };


    //////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldProducto = (event) => {
        event.persist()

        var formData = { ...this.state.formProducto };
        var comisionProducto: any = this.state.comisionProducto
        let comisionDisabledA = this.state.comisionDisabledA
        let comisionDisabledB = this.state.comisionDisabledB
        let comisionDisabledC = this.state.comisionDisabledC
        let comisionDisabledD = this.state.comisionDisabledD
        let comisionDisabledCOA = this.state.comisionDisabledCOA
        let comisionDisabledCOB = this.state.comisionDisabledCOB
        let comisionDisabledCOC = this.state.comisionDisabledCOC
        let inputDisabledComision = this.state.inputDisabledComision

        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;


        const nameFilters = ['nombreComercializadora', 'nombreOferta', 'nombreDocumentoContrato', 'TarifaDesc', 'companyId', 'esActiva', 'fechaAltaProducto', 'fechaBajaProducto', 'id', 'idComercializadora', 'tarifaATR', 'peaje', 'coIncluido', 'verde', 'facturacion', 'precioTEP1', 'precioTEP2', 'precioTEP3', 'precioTEP5', 'precioTEP4', 'precioTEP6', 'precioTP1', 'precioTP2', 'precioTP3',
            'precioTP4', 'precioTP5', 'precioTP6', 'precioTPPeriodo', 'precioTPTipo', 'periocidadRevisionPrecios', 'periodoValidezDesde', 'periodoValidezHasta', 'ofertaInternet', 'ofertaOficina', 'ofertaTel',
            'serviciosObligatorios', 'infoAdicional', 'caracteristicas', 'comisionContratoNuevo', 'comisionContratoRenovacion', 'comisionConsumoAgente1', 'comisionConsumoAgente2', 'comisionConsumoAdmin', 'comisionGOAgente1', 'comisionGOAgente2'];


        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataProducto: true });
            this.setState({
                saveProductoButtonDisabled: false,
                updateProductoButtonDisabled: true
            })
        }

        if (event.target.name == 'idComercializadora') {
            this.setState({ saveDataProducto: true });
            this.setState({
                saveProductoButtonDisabled: false,
                updateProductoButtonDisabled: true,
            })

            comisionDisabledA= false
            comisionDisabledB= false
            comisionDisabledC= false
            comisionDisabledD= false
            comisionDisabledCOA= false
            comisionDisabledCOB= false
            comisionDisabledCOC= false
            const NombreComercializadora = event.target.selectedOptions[0].label

            formData['nombreComercializadora'] = NombreComercializadora;

            this.setState({ formProducto: formData });
        }

        if (event.target.name == 'tarifaATR') {
            this.setState({ saveDataProducto: true });
            this.setState({
                saveProductoButtonDisabled: false,
                updateProductoButtonDisabled: true
            })
            const NombreTarifa = event.target.selectedOptions[0].label

            if (event.target.name == 'tarifaATR' && event.target.value == '018') {
                this.setState({
                    inputDisabledP3456: true,
                    inputDisabledE456: true
                })
            } else if (event.target.name == 'tarifaATR' && event.target.value != '018') {
                this.setState({
                    inputDisabledP3456: false,
                    inputDisabledE456: false
                })
            }

            formData['TarifaDesc'] = NombreTarifa;

            this.setState({ formProducto: formData });
        }

        if (event.target.name == "comisionTipoA") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "A"
                comisionDisabledB = true
                comisionDisabledC = true
                comisionDisabledD = true
                if (formData['idComercializadora'] !== "") {

                    inputDisabledComision = false
                } else {

                    inputDisabledComision = true
                }


            } else {
                comisionDisabledB = false
                comisionDisabledC = false
                comisionDisabledD = false
                inputDisabledComision = true
                comisionProducto = []
            }
        } else if (event.target.name == "comisionTipoB") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "B"
                comisionDisabledA = true
                comisionDisabledC = true
                comisionDisabledD = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }

            } else {
                comisionDisabledA = false
                comisionDisabledC = false
                comisionDisabledD = false
                inputDisabledComision = true
                comisionProducto = []
            }
        } else if (event.target.name == "comisionTipoC") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "C"
                comisionDisabledA = true
                comisionDisabledB = true
                comisionDisabledD = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }

            } else {
                comisionDisabledA = false
                comisionDisabledB = false
                comisionDisabledD = false
                inputDisabledComision = true
                comisionProducto = []
            }
        } else if (event.target.name == "comisionTipoD") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "D"
                comisionDisabledA = true
                comisionDisabledB = true
                comisionDisabledC = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }

            } else {
                comisionDisabledA = false
                comisionDisabledB = false
                comisionDisabledC = false
                inputDisabledComision = true
                comisionProducto = []
            }
        };

        if (event.target.name == "comisionTipoCOA") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "E"
                comisionDisabledCOB = true
                comisionDisabledCOC = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }
            } else {
                comisionDisabledCOB = false
                comisionDisabledCOC = false
                inputDisabledComision = true
                comisionProducto = []
            }
        } else if (event.target.name == "comisionTipoCOB") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "F"
                comisionDisabledCOA = true
                comisionDisabledCOC = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }
            } else {
                comisionDisabledCOA = false
                comisionDisabledCOC = false
                inputDisabledComision = true
                comisionProducto = []
            }
        } else if (event.target.name == "comisionTipoCOC") {
            if (event.target.checked == true) {
                formData['tipoComisionProducto'] = "G"
                comisionDisabledCOA = true
                comisionDisabledCOB = true
                if (formData['idComercializadora'] !== "") {
                    inputDisabledComision = false
                } else {
                    inputDisabledComision = true
                }
            } else {
                comisionDisabledCOA = false
                comisionDisabledCOB = false
                inputDisabledComision = true
                comisionProducto = []
            }
        };

        if (value == 'Selecciona...') {
            value = ""
        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }

        formData[name] = value;

        this.setState({
            formProducto: formData,
            comisionDisabledA: comisionDisabledA,
            comisionDisabledB: comisionDisabledB,
            comisionDisabledC: comisionDisabledC,
            comisionDisabledD: comisionDisabledD,
            comisionDisabledCOA: comisionDisabledCOA,
            comisionDisabledCOB: comisionDisabledCOB,
            comisionDisabledCOC: comisionDisabledCOC,
            comisionProducto: comisionProducto,
            inputDisabledComision: inputDisabledComision
        });
    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ///////////////////////////////////////////////////////////////////////////////

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    };

    ////////////////////////////////////////////////////////////////////////////

    openModalComparativa = async (event) => {
        this.showModal();
        return;
    };

    //////////////////////////////////////////////////////////////////////////7

    deleteComision = async (event) => {
        this.confirmAlertComision();
    }

    //////////////////////////////////////////////////////////////////////////////////////////////

        onChangeFieldSearch = (event) => {
            event.persist()
            const name = event.target.name;
            let value = event.target.value;
            const filters = this.state.filters;
   
             if(event.target.name === 'tipoContratoSearch' &&    event.target.value === 'G'){
                    filters['codeComercializadora']=""
                 } else if (event.target.name === 'tipoContratoSearch' &&    event.target.value === 'E'){
                    filters['codeComercializadora']=""
                 } 
    
            if (event.target.name === 'nombreProductoSearch' && event.target.value.length == 0) {
                this.setState({
                    cupsError: false,
                    buscarButtonDisabled: true,
                    inputCifDisabled: false,
                    inputNombreProductoDisabled: false,
                    inputIdDisabled: false
                })
            } else if (event.target.name === 'nombreProductoSearch' && event.target.value.length > 0) {
                    this.setState({
                    inputCifDisabled: true,
                    inputIdDisabled: true,
                    buscarButtonDisabled: false
                })
            }
    
            if (event.target.name === 'idSearch' && event.target.value.length == 0) {
                this.setState({
                    buscarButtonDisabled: true,
                    inputCifDisabled: false,
                    inputNombreProductoDisabled: false,
                    inputIdDisabled: false
                })
            } else if (event.target.name === 'idSearch' && event.target.value.length > 0) {
                    this.setState({
                        inputCifDisabled: true,
                    inputNombreProductoDisabled: true,
                    buscarButtonDisabled: false
                })
            }
    
            if (event.target.name == 'tipoContratoSearch'  || event.target.name == 'codeComercializadora'  || event.target.name == 'tipoEstadoSearch' || event.target.name == 'productoDesde' || event.target.name == 'productoHasta') {
                this.setState({
                    buscarButtonDisabled: false,
                    inputCifDisabled: false,
                    inputNombreProductoDisabled: false,
                    inputIdDisabled: false
                })
            }
    
            if (event.target.value == 'Selecciona...') {
                value = ""
        }
    
            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            }
    
            filters[name] = value;
   
            this.setState({ filters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////

    borrar = async () => {
        var formData = { ...this.state.formProducto };
        let comision: any = []
        formData['tipoComisionProducto'] = ""
        formData['comisionProducto'] = []
        this.setState({
            formProducto: formData,
            dataComisiones: comision
        });
        this.confirmedAlert()
    };

    /////////////////////////////////////////////////////////////////////////


    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                nombreProductoSearch: '',
                cifSearch: '',
                idSearch: ''
            },
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            productoSeleccionado: false,
            productoDetailTotal: [],
            formProducto: {
                TarifaDesc: "",
                atencionCliente: "",
                caracteristicas: "",
                coIncluido: false,
                companyId: 0,
                componentesRegulados: false,
                condicionesPenalizacion: "",
                condicionesRevisionPrecios: "",
                consumoMaximoElectricidad: 0,
                consumoMaximoGas: 0,
                consumoMinimoElectricidad: 0,
                consumoMinimoGas: 0,
                descuentoTE: 0,
                descuentoTP: 0,
                esActiva: false,
                facturacion: "",
                id: "",
                idComercializadora: "",
                infoAdicional: "",
                limitaciones: "",
                nombreComercializadora: "",
                nombreOferta: "",
                nombreDocumentoContrato: "",
                ofertaInternet: false,
                ofertaOficina: false,
                ofertaTel: false,
                peaje: "",
                penalizacionMaxElec: 0,
                penalizacionMaxGas: 0,
                periocidadRevisionPrecios: "",
                periodoValidez: "",
                periodoValidezDesde: "",
                periodoValidezHasta: "",
                potenciaMaximaElectricidad: 0,
                potenciaMinimaElectricidad: 0,
                precioAlquilerContadores: 0,
                precioServicios: 0,
                precioTEP1: 0,
                precioTEP2: 0,
                precioTEP3: 0,
                precioTEP4: 0,
                precioTEP5: 0,
                precioTEP6: 0,
                precioTP1: 0,
                precioTP2: 0,
                precioTP3: 0,
                precioTP4: 0,
                precioTP5: 0,
                precioTP6: 0,
                precioTPPeriodo: "0",
                precioTPTipo: "0",
                serviciosNoObligatorios: "",
                serviciosObligatorios: false,
                tarifaATR: "",
                verde: true,
                webContrato: "",
                webOferta: "",
                fechaAltaProducto: '',
                fechaBajaProducto: '',
                tipoProducto: '',
                precioTE1: 0,
                precioTE2: 0,
                precioTFijo: 0,
                factork: 0,
                comisionContratoNuevo: 0,
                comisionContratoRenovacion: 0,
                comisionConsumoAgente1: 0,
                comisionConsumoAgente2: 0,
                comisionConsumoAdmin: 0,
                comisionGOAgente1: 0,
                comisionGOAgente2: 0,
                energiaDesde: 0,
                energiaHasta: 0,
                potenciaDesde: 0,
                potenciaHasta: 0,
                nivel: "",
                importe: 0,
                idComision: 1,
                comisionTipoA: false,
                comisionTipoB: false,
                comisionTipoC: false,
                comisionTipoD: false,
                comisionTipoCOA: false,
                comisionTipoCOB: false,
                comisionTipoCOC: false,
                eurosMwh: false,
                tipoComisionProducto: "",
                comisionProducto: [],
                denominacionProducto: "", 
               zona:""
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveProductoButtonDisabled: true,
            updateProductoButtonDisabled: true,
            fechaAltaProducto: '',
            fechaBajaProducto: '',
            inputDisabledP3456: false,
            inputDisabledE456: false,
            productosCargados: [],
            comisionProducto: [],
            comisionDisabled: false,
            comisionDisabledA: false,
            comisionDisabledB: false,
            comisionDisabledC: false,
            comisionDisabledD: false,
            comisionDisabledCOA: false,
            comisionDisabledCOB: false,
            comisionDisabledCOC: false,
            dataComisiones: [{
                id: "",
                comisionID: 1,
                energiaDesde: 0,
                energiaHasta: 0,
                coReferencia: 0,
                coReferenciaPorcentaje: 0,
                coStandard: 0,
                coStandardPorcentaje: 0
            }],
            editID: 1,
            selectedRows: [],
            selectProductoCopyButtonDisabled: true
        });
        this.formRef.current.reset();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    saveHandlerold(props) {

        if (this.state.saveDataProducto && (this.state.formProducto.nombreOferta && this.state.formProducto.nombreComercializadora)) {
            this.confirmAlertSave()
        } else {
            this.warningAlertFiltros()
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    saveHandler(props) {

        if (this.state.updateDataProducto && (this.state.formProducto.esActiva && this.state.dataComisiones.length == 0)) {
            this.confirmAlertUpdateActivo()
        } else {
            if (this.state.updateDataProducto && (this.state.formProducto.nombreOferta && this.state.formProducto.nombreComercializadora)) {
            } else {
                this.warningAlertFiltros()
            }
        }
    };

    onSave = async () => {
        const companyId = this.state.companyIdUser.toString();
        const userId = this.state.userId.toString();

        if (this.state.saveDataProducto == true) {

            var fechaAltaProducto = this.state.formProducto.fechaAltaProducto;
            if (!fechaAltaProducto) {
                const now = new Date().toISOString();
                fechaAltaProducto = moment().format("YYYY-MM-DDTHH:mm:ss");
            }
            var fechaBajaProducto = this.state.formProducto.fechaBajaProducto;

            if (!fechaBajaProducto) {

                fechaBajaProducto = ''
            }

            var productoDataUpdate = {}

            if (this.state.tipoProducto == 'E' || this.state.tipoProducto == 'L') {

                let peaje = '3'
                if (this.state.formProducto.tarifaATR == '018') {
                    peaje = '3'
                } else if (this.state.formProducto.tarifaATR == '019') {
                    peaje = '4'
                } else if (this.state.formProducto.tarifaATR != '019' && this.state.formProducto.tarifaATR != '018') {
                    peaje = '6'
                };

                productoDataUpdate = {
                    'TarifaDesc': this.state.formProducto.TarifaDesc ? this.state.formProducto.TarifaDesc.toUpperCase() : '',
                    'atencionCliente': this.state.formProducto.atencionCliente ? this.state.formProducto.atencionCliente.toUpperCase() : '',
                    'caracteristicas': this.state.formProducto.caracteristicas ? this.state.formProducto.caracteristicas.toUpperCase() : '',
                    'coIncluido': this.state.formProducto.coIncluido,
                    'companyId': this.state.companyIdUser,
                    'componentesRegulados': this.state.formProducto.componentesRegulados,
                    'condicionesPenalizacion': this.state.formProducto.condicionesPenalizacion ? this.state.formProducto.condicionesPenalizacion.toUpperCase() : '',
                    'condicionesRevisionPrecios': this.state.formProducto.condicionesRevisionPrecios ? this.state.formProducto.condicionesRevisionPrecios.toUpperCase() : '',
                    'consumoMaximoElectricidad': this.state.formProducto.consumoMaximoElectricidad,
                    'consumoMinimoElectricidad': this.state.formProducto.consumoMinimoElectricidad,
                    'descuentoTE': this.state.formProducto.descuentoTE,
                    'descuentoTP': this.state.formProducto.descuentoTP,
                    'esActiva': this.state.formProducto.esActiva,
                    'facturacion': this.state.formProducto.facturacion,
                    'id': '',
                    'idComercializadora': this.state.formProducto.idComercializadora,
                    'infoAdicional': this.state.formProducto.infoAdicional ? this.state.formProducto.infoAdicional.toUpperCase() : '',
                    'limitaciones': this.state.formProducto.limitaciones ? this.state.formProducto.limitaciones.toUpperCase() : '',
                    'nombreComercializadora': this.state.formProducto.nombreComercializadora.toUpperCase(),
                    'nombreOferta': this.state.formProducto.nombreOferta.toUpperCase(),
                    'nombreDocumentoContrato': this.state.formProducto.nombreDocumentoContrato.toUpperCase(),
                    'ofertaInternet': this.state.formProducto.ofertaInternet,
                    'ofertaOficina': this.state.formProducto.ofertaOficina,
                    'ofertaTel': this.state.formProducto.ofertaTel,
                    'peaje': peaje,
                    'penalizacionMaxElec': this.state.formProducto.penalizacionMaxElec,
                    'penalizacionMaxGas': this.state.formProducto.penalizacionMaxGas,
                    'periocidadRevisionPrecios': this.state.formProducto.periocidadRevisionPrecios,
                    'periodoValidez': this.state.formProducto.periodoValidez,
                    'periodoValidezDesde': this.state.formProducto.periodoValidezDesde,
                    'periodoValidezHasta': this.state.formProducto.periodoValidezHasta,
                    'potenciaMaximaElectricidad': this.state.formProducto.potenciaMaximaElectricidad,
                    'potenciaMinimaElectricidad': this.state.formProducto.potenciaMinimaElectricidad,
                    'precioAlquilerContadores': this.state.formProducto.precioAlquilerContadores,
                    'precioServicios': this.state.formProducto.precioServicios,
                    'precioTEP1': this.state.formProducto.precioTEP1,
                    'precioTEP2': this.state.formProducto.precioTEP2,
                    'precioTEP3': this.state.formProducto.precioTEP3,
                    'precioTEP4': this.state.formProducto.precioTEP4,
                    'precioTEP5': this.state.formProducto.precioTEP5,
                    'precioTEP6': this.state.formProducto.precioTEP6,
                    'precioTP1': this.state.formProducto.precioTP1,
                    'precioTP2': this.state.formProducto.precioTP2,
                    'precioTP3': this.state.formProducto.precioTP3,
                    'precioTP4': this.state.formProducto.precioTP4,
                    'precioTP5': this.state.formProducto.precioTP5,
                    'precioTP6': this.state.formProducto.precioTP6,
                    'precioTPPeriodo': this.state.formProducto.precioTPPeriodo,
                    'precioTPTipo': this.state.formProducto.precioTPTipo,
                    'serviciosObligatorios': this.state.formProducto.serviciosObligatorios,
                    'tarifaATR': this.state.formProducto.tarifaATR,
                    'verde': this.state.formProducto.verde,
                    'webContrato': this.state.formProducto.webContrato,
                    'webOferta': this.state.formProducto.webOferta,
                    'fechaAltaProducto': fechaAltaProducto,
                    'fechaBajaProducto': fechaBajaProducto,
                    'tipoProducto': this.state.tipoProducto,
                    'comisionContratoNuevo': this.state.formProducto.comisionContratoNuevo,
                    'comisionContratoRenovacion': this.state.formProducto.comisionContratoRenovacion,
                    'comisionConsumoAgente1': this.state.formProducto.comisionConsumoAgente1,
                    'comisionConsumoAgente2': this.state.formProducto.comisionConsumoAgente2,
                    'comisionConsumoAdmin': this.state.formProducto.comisionConsumoAdmin,
                    'comisionGOAgente1': this.state.formProducto.comisionGOAgente1,
                    'comisionGOAgente2': this.state.formProducto.comisionGOAgente2,
                    'eurosMwh': this.state.formProducto.eurosMwh,
                    'tipoComisionProducto': this.state.formProducto.tipoComisionProducto,
                    'comisionProducto': this.state.comisionProducto,
                    'denominacionProducto': this.state.formProducto.denominacionProducto, 
                    'zona':this.state.formProducto.zona

                };
            } else {

                let peaje = '1'
                if (this.state.formProducto.tarifaATR == '018') {
                    peaje = '3'
                } else if (this.state.formProducto.tarifaATR == '019') {
                    peaje = '4'
                } else if (this.state.formProducto.tarifaATR != '019' && this.state.formProducto.tarifaATR != '018') {
                    peaje = '1'
                };

                productoDataUpdate = {
                    'TarifaDesc': this.state.formProducto.TarifaDesc ? this.state.formProducto.TarifaDesc.toUpperCase() : '',
                    'atencionCliente': this.state.formProducto.atencionCliente ? this.state.formProducto.atencionCliente.toUpperCase() : '',
                    'caracteristicas': this.state.formProducto.caracteristicas ? this.state.formProducto.caracteristicas.toUpperCase() : '',
                    'coIncluido': this.state.formProducto.coIncluido,
                    'companyId': this.state.companyIdUser,
                    'componentesRegulados': this.state.formProducto.componentesRegulados,
                    'condicionesPenalizacion': this.state.formProducto.condicionesPenalizacion ? this.state.formProducto.condicionesPenalizacion.toUpperCase() : '',
                    'condicionesRevisionPrecios': this.state.formProducto.condicionesRevisionPrecios ? this.state.formProducto.condicionesRevisionPrecios.toUpperCase() : '',
                    'consumoMaximoElectricidad': this.state.formProducto.consumoMaximoElectricidad,
                    'consumoMaximoGas': this.state.formProducto.consumoMaximoGas,
                    'consumoMinimoGas': this.state.formProducto.consumoMinimoGas,
                    'descuentoTE': this.state.formProducto.descuentoTE,
                    'descuentoTP': this.state.formProducto.descuentoTP,
                    'esActiva': this.state.formProducto.esActiva,
                    'facturacion': this.state.formProducto.facturacion,
                    'id': '',
                    'idComercializadora': this.state.formProducto.idComercializadora,
                    'infoAdicional': this.state.formProducto.infoAdicional ? this.state.formProducto.infoAdicional.toUpperCase() : '',
                    'limitaciones': this.state.formProducto.limitaciones ? this.state.formProducto.limitaciones.toUpperCase() : '',
                    'nombreComercializadora': this.state.formProducto.nombreComercializadora.toUpperCase(),
                    'nombreOferta': this.state.formProducto.nombreOferta.toUpperCase(),
                    'nombreDocumentoContrato': this.state.formProducto.nombreDocumentoContrato.toUpperCase(),
                    'ofertaInternet': this.state.formProducto.ofertaInternet,
                    'ofertaOficina': this.state.formProducto.ofertaOficina,
                    'ofertaTel': this.state.formProducto.ofertaTel,
                    'peaje': peaje,
                    'penalizacionMaxGas': this.state.formProducto.penalizacionMaxGas,
                    'periocidadRevisionPrecios': this.state.formProducto.periocidadRevisionPrecios,
                    'periodoValidez': this.state.formProducto.periodoValidez,
                    'periodoValidezDesde': this.state.formProducto.periodoValidezDesde,
                    'periodoValidezHasta': this.state.formProducto.periodoValidezHasta,
                    'precioAlquilerContadores': this.state.formProducto.precioAlquilerContadores,
                    'precioServicios': this.state.formProducto.precioServicios,
                    'precioTPPeriodo': this.state.formProducto.precioTPPeriodo,
                    'precioTPTipo': this.state.formProducto.precioTPTipo,
                    'serviciosObligatorios': this.state.formProducto.serviciosObligatorios,
                    'tarifaATR': this.state.formProducto.tarifaATR,
                    'verde': this.state.formProducto.verde,
                    'webContrato': this.state.formProducto.webContrato,
                    'webOferta': this.state.formProducto.webOferta,
                    'fechaAltaProducto': fechaAltaProducto,
                    'fechaBajaProducto': fechaBajaProducto,
                    'tipoProducto': this.state.tipoProducto,
                    'precioTE1': this.state.formProducto.precioTE1,
                    'precioTE2': this.state.formProducto.precioTE2,
                    'precioTFijo': this.state.formProducto.precioTFijo,
                    'factork': this.state.formProducto.factork,
                    'comisionContratoNuevo': this.state.formProducto.comisionContratoNuevo,
                    'comisionContratoRenovacion': this.state.formProducto.comisionContratoRenovacion,
                    'comisionConsumoAgente1': this.state.formProducto.comisionConsumoAgente1,
                    'comisionConsumoAgente2': this.state.formProducto.comisionConsumoAgente2,
                    'comisionConsumoAdmin': this.state.formProducto.comisionConsumoAdmin,
                    'comisionGOAgente1': this.state.formProducto.comisionGOAgente1,
                    'comisionGOAgente2': this.state.formProducto.comisionGOAgente2,
                    'eurosMwh': this.state.formProducto.eurosMwh,
                    'tipoComisionProducto': this.state.formProducto.tipoComisionProducto,
                    'comisionProducto': this.state.dataComisiones,
                    'denominacionProducto': this.state.formProducto.denominacionProducto, 
                    'zona':this.state.formProducto.zona
                };
            }

            const companyId = this.state.companyIdUser.toString();
            const userId = this.state.userId.toString();
            const idContrato = this.state.contrato;

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/producto/create?id=" + this.state.companyIdUser + "&user=" + userId;


            axios.post(direccionFile, productoDataUpdate, { headers, responseType: 'blob' })
                .then(res => {
                    console.log('Actualización correcta')

                    this.successAlert();
                    this.clearForm();
                }).catch(error => {
                    console.log('Failure')
                    console.log('Error en el proceso: ', error)
                    this.warningAlert();
                });
        }

        this.setState({
            saveDataProducto: false,
            saveProductoButtonDisabled: true
        });
        this.clearForm();
    };

    /////////////////////////////////////////////////////////////////////////

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formProducto };

        const name = id;
        var valueDay = formattedValue

        if (name == 'productoDesde') {
            this.setState({ productoDesde: value });
        }

        if (name == 'productoHasta') {
            this.setState({ productoHasta: value });
        }

        if (name == 'fechaAltaProducto') {
            this.setState({ fechaAltaProducto: value });
        }

        if (name == 'fechaBajaProducto') {
            this.setState({ fechaBajaProducto: value });
        }

        if (name == 'periodoValidezDesde') {
            this.setState({ periodoValidezDesde: value });
        }

        if (name == 'periodoValidezHasta') {
            this.setState({ periodoValidezHasta: value });
        }

        formData[name] = valueDay;
        this.setState({ formProducto: formData });
    };

    //////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        this.setState({ selectedRows: state.selectedRows });

        if (state.selectedRows.length > 0) {
            this.setState({ selectProductoCopyButtonDisabled: false })
        } else {
            this.setState({ selectProductoCopyButtonDisabled: true })
        }
    };

    //////////////////////////////////////////////////////////////////////////////


    copyHandler = state => {
        // eslint-disable-next-line no-console

        let inputDisabledComision = true

        var producto = '';
        var eurosMwh=  false

        
            var existeProducto = ''

            var productoData:any = this.state.selectedRows[0]
            producto = productoData.id;
            eurosMwh = productoData.eurosMwh
            this.setState({ productoDetail: this.state.selectedRows[0] });

            var existeFechaAlta = productoData.fechaAltaProducto
            var FechaAltaAux = ''
            var existeFechaBaja = productoData.fechaBajaProducto
            var FechaBajaAux = ''

            existeProducto = productoData.id

            this.setState({
                fechaAltaProducto: productoData.fechaAltaProducto
            });

            if (existeFechaAlta && existeFechaBaja) {
                this.setState({
                    inputDisabled: true,
                    updateProductoButtonDisabled: false
                })
            } else if ((!existeFechaAlta && !existeFechaBaja) || (existeFechaAlta && !existeFechaBaja)) {
                this.setState({
                    inputDisabled: false,
                    updateProductoButtonDisabled: true
                })
            };

            if (existeProducto) {

                let tipoComisionProducto = productoData.tipoComisionProducto
                let comisionProducto = productoData.comisionProducto


                if (tipoComisionProducto == "") {
                    tipoComisionProducto = ""
                }

                if (comisionProducto == null) {
                    comisionProducto = []
                };

                let comisionDisabledA = false
                let comisionDisabledB = false
                let comisionDisabledC = false
                let comisionDisabledD = false
                let comisionDisabledCOA = false
                let comisionDisabledCOB = false
                let comisionDisabledCOC = false

                let comisionTipoA = false
                let comisionTipoB = false
                let comisionTipoC = false
                let comisionTipoD = false
                let comisionTipoCOA = false
                let comisionTipoCOB = false
                let comisionTipoCOC = false

                if ((productoData.idComercializadora !== "") && (tipoComisionProducto !== "")) {
                    inputDisabledComision = false
                };

                if (tipoComisionProducto == "A") {
                    comisionTipoA = true
                    comisionDisabledA = false
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else if (tipoComisionProducto == "B") {
                    comisionTipoB = true
                    comisionDisabledA = true
                    comisionDisabledB = false
                    comisionDisabledC = true
                    comisionDisabledD = true

                } else if (tipoComisionProducto == "C") {
                    comisionTipoC = true
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = false
                    comisionDisabledD = true
                } else if (tipoComisionProducto == "D") {
                    comisionTipoD = true
                    comisionDisabledA = true
                    comisionDisabledB = true
                    comisionDisabledC = true
                    comisionDisabledD = false
                } else if (tipoComisionProducto == "E") {
                    comisionTipoCOA = true
                    comisionDisabledCOA = false
                    comisionDisabledCOB = true
                    comisionDisabledCOC = true

                } else if (tipoComisionProducto == "F") {
                    comisionTipoCOB = true
                    comisionDisabledCOA = true
                    comisionDisabledCOB = false
                    comisionDisabledCOC = true
                } else if (tipoComisionProducto == "G") {
                    comisionTipoCOC = true
                    comisionDisabledCOA = true
                    comisionDisabledCOB = true
                    comisionDisabledCOC = false
                };

                this.setState({ idProducto: this.state.productoDetail.id });
                this.setState({
                    formProducto: {
                        TarifaDesc:productoData.TarifaDesc,
                        atencionCliente:productoData.atencionCliente,
                        caracteristicas:productoData.caracteristicas,
                        coIncluido:productoData.coIncluido,
                        companyId:productoData.companyId,
                        componentesRegulados:productoData.componentesRegulados,
                        condicionesPenalizacion:productoData.condicionesPenalizacion,
                        condicionesRevisionPrecios:productoData.condicionesRevisionPrecios,
                        consumoMaximoElectricidad:productoData.consumoMaximoElectricidad,
                        consumoMaximoGas:productoData.consumoMaximoGas,
                        consumoMinimoElectricidad:productoData.consumoMinimoElectricidad,
                        consumoMinimoGas:productoData.consumoMinimoGas,
                        descuentoTE:productoData.descuentoTE,
                        descuentoTP:productoData.descuentoTP,
                        esActiva:productoData.esActiva,
                        facturacion:productoData.facturacion,
                        id:"",
                        idComercializadora:productoData.idComercializadora,
                        infoAdicional:productoData.infoAdicional,
                        limitaciones:productoData.limitaciones,
                        nombreComercializadora:productoData.nombreComercializadora,
                        nombreOferta:productoData.nombreOferta,
                        nombreDocumentoContrato:productoData.nombreDocumentoContrato,
                        ofertaInternet:productoData.ofertaInternet,
                        ofertaOficina:productoData.ofertaOficina,
                        ofertaTel:productoData.ofertaTel,
                        peaje:productoData.peaje,
                        penalizacionMaxElec:productoData.penalizacionMaxElec,
                        penalizacionMaxGas:productoData.penalizacionMaxGas,
                        periocidadRevisionPrecios:productoData.periocidadRevisionPrecios,
                        periodoValidez:productoData.periodoValidez,
                        periodoValidezDesde:productoData.periodoValidezDesde,
                        periodoValidezHasta:productoData.periodoValidezHasta,
                        potenciaMaximaElectricidad:productoData.potenciaMaximaElectricidad,
                        potenciaMinimaElectricidad:productoData.potenciaMinimaElectricidad,
                        precioAlquilerContadores:productoData.precioAlquilerContadores,
                        precioServicios:productoData.precioServicios,
                        precioTEP1:productoData.precioTEP1,
                        precioTEP2:productoData.precioTEP2,
                        precioTEP3:productoData.precioTEP3,
                        precioTEP4:productoData.precioTEP4,
                        precioTEP5:productoData.precioTEP5,
                        precioTEP6:productoData.precioTEP6,
                        precioTP1:productoData.precioTP1,
                        precioTP2:productoData.precioTP2,
                        precioTP3:productoData.precioTP3,
                        precioTP4:productoData.precioTP4,
                        precioTP5:productoData.precioTP5,
                        precioTP6:productoData.precioTP6,
                        precioTPPeriodo:productoData.precioTPPeriodo,
                        precioTPTipo:productoData.precioTPTipo,
                        serviciosObligatorios:productoData.serviciosObligatorios,
                        tarifaATR:productoData.tarifaATR,
                        verde:productoData.verde,
                        webContrato:productoData.webContrato,
                        webOferta:productoData.webOferta,
                        fechaAltaProducto:productoData.fechaAltaProducto,
                        fechaBajaProducto:productoData.fechaBajaProducto,
                        tipoProducto:productoData.tipoProducto,
                        precioTE1:productoData.precioTE1,
                        precioTE2:productoData.precioTE2,
                        precioTFijo:productoData.precioTFijo,
                        factork:productoData.factork,
                        comisionContratoNuevo:productoData.comisionContratoNuevo ?productoData.comisionContratoNuevo : "0",
                        comisionContratoRenovacion:productoData.comisionContratoRenovacion ?productoData.comisionContratoRenovacion : "0",
                        comisionConsumoAgente1:productoData.comisionConsumoAgente1 ?productoData.comisionConsumoAgente1 : "0",
                        comisionConsumoAgente2:productoData.comisionConsumoAgente2 ?productoData.comisionConsumoAgente2 : "0",
                        comisionConsumoAdmin:productoData.comisionConsumoAdmin ?productoData.comisionConsumoAdmin : "0",
                        comisionGOAgente1:productoData.comisionGOAgente1 ?productoData.comisionGOAgente1 : "0",
                        comisionGOAgente2:productoData.comisionGOAgente2 ?productoData.comisionGOAgente2 : "0",
                        comisionTipoA: comisionTipoA,
                        comisionTipoB: comisionTipoB,
                        comisionTipoC: comisionTipoC,
                        comisionTipoD: comisionTipoD,
                        comisionTipoCOA: comisionTipoCOA,
                        comisionTipoCOB: comisionTipoCOB,
                        comisionTipoCOC: comisionTipoCOC,
                        eurosMwh: eurosMwh,
                        tipoComisionProducto: tipoComisionProducto,
                        comisionProducto: comisionProducto,
                        denominacionProducto:productoData.denominacionProducto, 
                    zona:productoData.zona
                    },
                    dataComisiones: comisionProducto,
                    productoSeleccionado: true,
                    comisionProducto: comisionProducto,
                    comisionDisabledA: comisionDisabledA,
                    comisionDisabledB: comisionDisabledB,
                    comisionDisabledC: comisionDisabledC,
                    comisionDisabledD: comisionDisabledD,
                    comisionDisabledCOA: comisionDisabledCOA,
                    comisionDisabledCOB: comisionDisabledCOB,
                    comisionDisabledCOC: comisionDisabledCOC,
                    inputDisabled: false,
                    inputDisabledComision: inputDisabledComision
                });

            } else {
                this.setState({
                    productoSeleccionado: false,
                    comisionProducto: [],
                    comisionDisabledA: false,
                    comisionDisabledB: false,
                    comisionDisabledC: false,
                    comisionDisabledD: false,
                    comisionDisabledCOA: false,
                    comisionDisabledCOB: false,
                    comisionDisabledCOC: false,
                    fechaAltaProducto: '',
                    fechaBajaProducto: '',
                    formProducto: {
                        TarifaDesc: "",
                        atencionCliente: "",
                        caracteristicas: "",
                        coIncluido: false,
                        companyId: 0,
                        componentesRegulados: false,
                        condicionesPenalizacion: "",
                        condicionesRevisionPrecios: "",
                        consumoMaximoElectricidad: 0,
                        consumoMaximoGas: 0,
                        consumoMinimoElectricidad: 0,
                        consumoMinimoGas: 0,
                        descuentoTE: 0,
                        descuentoTP: 0,
                        esActiva: false,
                        facturacion: "",
                        id: "",
                        idComercializadora: "",
                        infoAdicional: "",
                        limitaciones: "",
                        nombreComercializadora: "",
                        nombreOferta: "",
                        nombreDocumentoContrato: "",
                        ofertaInternet: false,
                        ofertaOficina: false,
                        ofertaTel: false,
                        peaje: "",
                        penalizacionMaxElec: 0,
                        penalizacionMaxGas: 0,
                        periocidadRevisionPrecios: "",
                        periodoValidez: "",
                        periodoValidezDesde: "",
                        periodoValidezHasta: "",
                        potenciaMaximaElectricidad: 0,
                        potenciaMinimaElectricidad: 0,
                        precioAlquilerContadores: 0,
                        precioServicios: 0,
                        precioTEP1: 0,
                        precioTEP2: 0,
                        precioTEP3: 0,
                        precioTEP4: 0,
                        precioTEP5: 0,
                        precioTEP6: 0,
                        precioTP1: 0,
                        precioTP2: 0,
                        precioTP3: 0,
                        precioTP4: 0,
                        precioTP5: 0,
                        precioTP6: 0,
                        precioTPPeriodo: "0",
                        precioTPTipo: "0",
                        serviciosNoObligatorios: "",
                        serviciosObligatorios: false,
                        tarifaATR: "",
                        verde: true,
                        webContrato: "",
                        webOferta: "",
                        fechaAltaProducto: '',
                        fechaBajaProducto: '',
                        tipoProducto: '',
                        precioTE1: 0,
                        precioTE2: 0,
                        precioTFijo: 0,
                        factork: 0,
                        comisionContratoNuevo: 0,
                        comisionContratoRenovacion: 0,
                        comisionConsumoAgente1: 0,
                        comisionConsumoAgente2: 0,
                        comisionConsumoAdmin: 0,
                        comisionGOAgente1: 0,
                        comisionGOAgente2: 0,
                        comisionTipoA: false,
                        comisionTipoB: false,
                        comisionTipoC: false,
                        comisionTipoD: false,
                        comisionTipoCOA: false,
                        comisionTipoCOB: false,
                        comisionTipoCOC: false,
                        eurosMwh: false,
                        tipoComisionProducto: "",
                        comisionProducto: [],
                        denominacionProducto: "", 
                    zona:""
                    }
                });
            }




        if(!productoData){ 

            console.log('sin producto')
        } else {
      
        if ((productoData.tipoProducto == 'L' ||productoData.tipoProducto == 'E') &&productoData.tarifaATR == '018') {
            this.setState({
                inputDisabledP3456: true,
                inputDisabledE456: true
            })
        } else if ((productoData.tipoProducto == 'L' ||productoData.tipoProducto == 'E') &&productoData.tarifaATR != '018') {
            this.setState({
                inputDisabledP3456: false,
                inputDisabledE456: false
            })
        }

    };
    };

    ///////////////////////////////////////////////////////////////////////////////////

    comision = (props) => {

        const tipoComision = this.state.formProducto.coIncluido;
        const formProducto: any = this.state.formProducto;

        if (tipoComision === false) {
            return (

                <React.Fragment>
                    <Row>
                        <Col lg="10">
                            <Card className="shadow">

                                <CardBody>
                                    <div>
                                        <CardTitle>TIPOS DE COMISIONES CON PRODUCTOS CON COSTE OPERATIVOS</CardTitle>
                                    </div>
                                    <Row>
                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoCOA"
                                                    name="comisionTipoCOA"
                                                    checked={formProducto.comisionTipoCOA}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledCOA}

                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoCOA">
                                                    COMISIÓN CO TIPO A. ENERGIA/POTENCIA CON NIVELES
                                                </label>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoCOB"
                                                    name="comisionTipoCOB"
                                                    checked={formProducto.comisionTipoCOB}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledCOB}
                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoCOB">
                                                    COMISIÓN TIPO B. ENERGIA/POTENCIA SIN NIVELES
                                                </label>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">
                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoCOC"
                                                    name="comisionTipoCOC"
                                                    checked={formProducto.comisionTipoCOC}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledCOC}
                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoCOC">
                                                    COMISIÓN TIPO C. ENERGIA CON PRODUCTOS
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="auto">
                            <Card lg="auto" className="shadow">

                                <CardBody>
                                    <div>
                                        <CardTitle>COMISIONES</CardTitle>
                                    </div>
                                    <Row>
                                        <Col lg="auto" className="align-self-center">

                                            <Button
                                                id="UpdateComisionProptooltip"
                                                className="mx-auto align-self-center mt-2"
                                                position="center"
                                                margin
                                                onClick={this.openModalComparativa}
                                                color="primary"
                                                type="button"
                                                disabled={this.state.inputDisabledComision}
                                            >
                                                <i className={"bi bi-person-plus"} style={{ fontSize: 15 }} />
                                                <UncontrolledTooltip delay={0} placement="top" target="UpdateComisionProptooltip">
                                                    AÑADE COMISION AL PRODUCTO
                                                </UncontrolledTooltip>
                                            </Button>

                                        </Col>


                                        <Col lg="auto" className="align-self-center">

                                            <Button
                                                id="deleteComisionProptooltip"
                                                className="mx-auto align-self-center mt-2 bi bi-person-x"
                                                position="center"
                                                margin
                                                onClick={this.deleteComision}
                                                color="danger"
                                                type="button"
                                                disabled={this.state.inputDisabledComision}
                                                style={{ fontSize: 15 }}
                                            >

                                                <UncontrolledTooltip delay={0} placement="top" target="deleteComisionProptooltip">
                                                    BORRA COMISION AL PRODUCTO
                                                </UncontrolledTooltip>
                                            </Button>

                                        </Col>



                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            )

        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col lg="10">
                            <Card className="shadow">

                                <CardBody>
                                    <div>
                                        <CardTitle>TIPOS DE COMISIONES</CardTitle>
                                    </div>
                                    <Row>
                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoA"
                                                    name="comisionTipoA"
                                                    checked={formProducto.comisionTipoA}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledA}

                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoA">
                                                    COMISIÓN TIPO A. ENERGIA/POTENCIA CON NIVELES
                                                </label>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoB"
                                                    name="comisionTipoB"
                                                    checked={formProducto.comisionTipoB}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledB}
                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoB">
                                                    COMISIÓN TIPO B. ENERGIA/POTENCIA SIN NIVELES
                                                </label>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">
                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoC"
                                                    name="comisionTipoC"
                                                    checked={formProducto.comisionTipoC}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledC}
                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoC">
                                                COMISIÓN TIPO C. ENERGIA/POTENCIA CON PRODUCTOS Y ZONA
                                                </label>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="auto" className="pt-3">
                                            <FormGroup className="custom-control custom-checkbox">
                                                <Input
                                                    className="custom-control-input"
                                                    id="comisionTipoD"
                                                    name="comisionTipoD"
                                                    checked={formProducto.comisionTipoD}
                                                    type="checkbox"
                                                    onChange={this.onChangeFieldProducto}
                                                    disabled={this.state.comisionDisabledD}
                                                />
                                                <label className="custom-control-label" htmlFor="comisionTipoD">
                                                    COMISIÓN TIPO D. ENERGIA CON PRODUCTOS
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col lg="auto">
                            <Card lg="auto" className="shadow">

                                <CardBody>
                                    <div>
                                        <CardTitle>COMISIONES</CardTitle>
                                    </div>
                                    <Row>
                                        <Col lg="auto" className="align-self-center">

                                            <Button
                                                id="UpdateComisionProptooltip"
                                                className="mx-auto align-self-center mt-2"
                                                position="center"
                                                margin
                                                onClick={this.openModalComparativa}
                                                color="primary"
                                                type="button"
                                                disabled={this.state.inputDisabledComision}
                                            >
                                                <i className={"bi bi-person-plus"} style={{ fontSize: 15 }} />
                                                <UncontrolledTooltip delay={0} placement="top" target="UpdateComisionProptooltip">
                                                    AÑADE COMISION AL PRODUCTO
                                                </UncontrolledTooltip>
                                            </Button>

                                        </Col>


                                        <Col lg="auto" className="align-self-center">

                                            <Button
                                                id="deleteComisionProptooltip"
                                                className="mx-auto align-self-center mt-2 bi bi-person-x"
                                                position="center"
                                                margin
                                                onClick={this.deleteComision}
                                                color="danger"
                                                type="button"
                                                disabled={this.state.inputDisabledComision}
                                                style={{ fontSize: 15 }}
                                            >

                                                <UncontrolledTooltip delay={0} placement="top" target="deleteComisionProptooltip">
                                                    BORRA COMISION AL PRODUCTO
                                                </UncontrolledTooltip>
                                            </Button>

                                        </Col>



                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </React.Fragment >
            )

        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    tipoLecturaTable = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>

                    <Card className="shadow">
                        <DataTable
                            title="LISTADO DE PRODUCTOS GAS"
                            columns={columnsDatosProductosGas}
                            data={this.state.productoDetailTotal}
                            selectableRows
                            //selectableRowsComponent={radio} // Pass the function only
                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                            selectableRowsSingle
                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                            noDataComponent={"Sin datos para mostrar"}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                            onSelectedRowsChange={this.handleChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            highlightOnHover
                            striped
                            pointerOnHover
                            selectableRowsHighlight
                            customStyles={customStyles}
                            contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                            subHeader
                            subHeaderComponent={
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <Button disabled={this.state.selectProductoCopyButtonDisabled} color="success" size="sm" className="float-right"
                                        onClick={() => { this.copyHandler(this.state.selectedRows) }}
                                    >
                                        <i className={"bi bi-check-square"} /> COPIAR PRODUCTO
                                    </Button>
                                </div>
                            }
                            progressComponent={<Circular />}
                        />
                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="shadow">
                        <DataTable
                            title="LISTADO DE PRODUCTOS ELECTRICIDAD"
                            columns={columnsDatosProductosLuz}
                            data={this.state.productoDetailTotal}
                            selectableRows
                            //selectableRowsComponent={radio} // Pass the function only
                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                            selectableRowsSingle
                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                            noDataComponent={"Sin datos para mostrar"}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                            onSelectedRowsChange={this.handleChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            highlightOnHover
                            striped
                            pointerOnHover
                            selectableRowsHighlight
                            customStyles={customStyles}
                            contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                            subHeader
                            subHeaderComponent={
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <Button disabled={this.state.selectProductoCopyButtonDisabled} color="success" size="sm" className="float-right"
                                        onClick={() => { this.copyHandler(this.state.selectedRows) }}
                                    >
                                        <i className={"bi bi-check-square"} /> COPIAR PRODUCTO
                                    </Button>
                                </div>
                            }
                            progressComponent={<Circular />}
                        />
                    </Card>

                </React.Fragment>

            )

        }
    };






    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    comisionTipologia = (props) => {

        const tipoComisionProducto = props.idComision;
        const idComision = props.idComision;

        var formProducto = { ...this.state.formProducto }

        if (tipoComisionProducto === "A") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA CON NIVEL</CardTitle>
                        </div>

                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                            >
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "B") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA SIN NIVEL</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                            >
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="coTarifa"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "C") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR RELACION ACTIVA/POTENCIA, PRODUCTOS Y ZONA</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                            >

                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaDesde"
                                    title="potenciaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="potenciaHasta"
                                    title="potenciaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="zona"
                                    title="Zona"
                                    editor="text"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "D") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES POR ACTIVA CON PRODUCTOS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                            >
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" editable={false} />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="modoTarifa1"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa1"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />

                                <Column
                                    field="modoTarifa2"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa2"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa3"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa3"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa4"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa4"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                                <Column
                                    field="modoTarifa5"
                                    title="Producto/Nivel"
                                    editor="text"
                                />
                                <Column
                                    field="coTarifa5"
                                    title="Importe"
                                    editor="numeric"
                                    format="{0:c2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "E") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES CO 2 REFERENCIAS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}

                            >
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                />
                                <Column
                                    field="coReferencia"
                                    title="CO Referencia"
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="coReferenciaPorcentaje"
                                    title="% Referencia"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="coStandard"
                                    title="CO Standard"
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="coStandardPorcentaje"
                                    title="% Referencia"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "F") {
            return (
                <React.Fragment>
                    <CardBody>
                        <div>
                            <CardTitle>COMISIONES CO +2 REFERENCIAS</CardTitle>
                        </div>
                        <Row>
                            <Grid
                                style={{
                                    height: "420px",
                                }}
                                data={this.state.dataComisiones.map((item: any) => ({
                                    ...item,
                                    inEdit: item.comisionID === this.state.editID,
                                }))}
                                size={"small"}
                            >
                                <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                                <Column field="comisionNombre" title="Nombre" />
                                <Column
                                    field="energiaDesde"
                                    title="energiaDesde"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="energiaHasta"
                                    title="energiaHasta"
                                    editor="numeric"
                                    className="text-primary"
                                />
                                <Column
                                    field="co1"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco1"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co2"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco2"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co3"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco3"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co4"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco4"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co5"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco5"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                                <Column
                                    field="co6"
                                    title="CO Nivel "
                                    editor="numeric"
                                    format="{0:c3}"
                                />
                                <Column
                                    field="porcentajeco6"
                                    title="% Nivel"
                                    editor="numeric"
                                    format="{0:n2}"
                                />
                            </Grid>
                        </Row>
                    </CardBody>
                </React.Fragment>
            )
        } else if (tipoComisionProducto === "G") {
            return (
                <React.Fragment>
                <CardBody>
                    <div>
                        <CardTitle>COMISIONES CO CON MARGEN LIBRE</CardTitle>
                    </div>
                    <Row>
                    <Grid
                            style={{
                                height: "420px",
                            }}
                            data={this.state.dataComisiones.map((item: any) => ({
                                ...item,
                                inEdit: item.comisionID === this.state.editID,
                            }))}
                            size={"small"}
                        >
                            <Column field="comisionID" title="comisionID" width="50px" editable={false} />
                            <Column field="comisionNombre" title="Nombre" />
                            <Column
                                field="energiaDesde"
                                title="energiaDesde"
                                editor="numeric"
                                className="text-primary"
                            />
                            <Column
                                field="energiaHasta"
                                title="energiaHasta"
                                editor="numeric"
                                className="text-primary"
                            />
                            <Column
                                field="coReferenciaMax"
                                title="CO REFERENCIA MAX"
                                editor="numeric"
                                format="{0:c3}"
                            />
                            <Column
                                field="coReferenciaPorcentaje"
                                title="% CO"
                                editor="numeric"
                                format="{0:n2}"
                            />

                        </Grid>
                    </Row>
                </CardBody>
            </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div></div>
                </React.Fragment>
            )
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoLecturaSelect = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>
                    <Col md="12" lg="4">
                        <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="idComercializadora"
                            >
                                Comercializadora
                            </Label>
                            <SipsOption
                                placeholder="Seleccionar comercializadora"
                                id="idComercializadora"
                                name="idComercializadora"
                                value={this.state.formProducto.idComercializadora}
                                query="LIST_MARKETERS_GAS"
                                onChange={this.onChangeFieldProducto}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>

                            <Label className="form-control-label" htmlFor="nombreComercializadora">
                                Comercializadora
                            </Label>
                            <Input
                                id="nombreComercializadora"
                                placeholder=""
                                name="nombreComercializadora"
                                onChange={this.onChangeFieldProducto}
                                value={this.state.formProducto.nombreComercializadora}
                                type="text"
                                required
                                disabled={true}
                            />
                        </FormGroup>
                    </Col>

                    <Col md="1" lg="1">
                        <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="tarifaATR"
                            >
                                Tarifas                                                                                    </Label>
                            <SipsGasOption
                                placeholder="Seleccionar tarifa"
                                id="tarifaATR"
                                name="tarifaATR"
                                value={this.state.formProducto.tarifaATR}
                                query="LIST_RATESGAS"
                                onChange={this.onChangeFieldProducto}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>

                            <Label className="form-control-label" htmlFor="TarifaDesc">
                                Tarifa Desc.
                            </Label>
                            <Input
                                id="TarifaDesc"
                                placeholder=""
                                name="TarifaDesc"
                                onChange={this.onChangeFieldProducto}
                                value={this.state.formProducto.TarifaDesc}
                                type="text"
                                required
                                disabled={this.state.inputDisabled}
                            />
                        </FormGroup>
                    </Col>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Col md="12" lg="4">
                        <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="idComercializadora"
                            >
                                Comercializadora
                            </Label>
                            <SipsOption
                                placeholder="Seleccionar comercializadora"
                                id="idComercializadora"
                                name="idComercializadora"
                                value={this.state.formProducto.idComercializadora}
                                query="LIST_MARKETERS"
                                onChange={this.onChangeFieldProducto}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>

                            <Label className="form-control-label" htmlFor="nombreComercializadora">
                                Comercializadora
                            </Label>
                            <Input
                                id="nombreComercializadora"
                                placeholder=""
                                name="nombreComercializadora"
                                onChange={this.onChangeFieldProducto}
                                value={this.state.formProducto.nombreComercializadora}
                                type="text"
                                required
                                disabled={true}
                            />
                        </FormGroup>
                    </Col>

                    <Col md="1" lg="1">
                        <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="tarifaATR"
                            >
                                Tarifas
                            </Label>
                            <SipsOption
                                placeholder="Seleccionar tarifa"
                                id="tarifaATR"
                                name="tarifaATR"
                                value={this.state.formProducto.tarifaATR}
                                query="LIST_RATES"
                                onChange={this.onChangeFieldProducto}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>

                            <Label className="form-control-label" htmlFor="TarifaDesc">
                                Tarifa Desc.
                            </Label>
                            <Input
                                id="TarifaDesc"
                                placeholder=""
                                name="TarifaDesc"
                                onChange={this.onChangeFieldProducto}
                                value={this.state.formProducto.TarifaDesc}
                                type="text"
                                required
                                disabled={this.state.inputDisabled}
                            />
                        </FormGroup>
                    </Col>

                </React.Fragment>

            )

        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    buscarProducto = async () => {

        var idComercializadoraAux = ''
        if (this.state.filters.codeComercializadora != '0' && this.state.filters.codeComercializadora != '') {
            idComercializadoraAux = this.state.filters.codeComercializadora
        }

        var dataFilters = {
            'companyId': this.state.companyIdUser,
            'idComercializadora': idComercializadoraAux,
            'id': this.state.filters.idSearch,
            'nombreOferta': this.state.filters.nombreProductoSearch,
            'tipoContrato': this.state.filters.tipoContratoSearch,
            'esActiva': this.state.filters.tipoEstadoSearch,
            'periodoValidezDesde': this.state.filters.productoDesde,
            'periodoValidezHasta': this.state.filters.productoHasta
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/producto/listado?id=" + this.state.companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows

                    this.setState({ productoDetailTotal: data });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                console.log('Failure')
                this.warningAlert();
            });

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////


    tipoContratoPeriodos = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
            return (

                <React.Fragment>

                    <Row>

                        <Col md="2" lg="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTE1">
                                    TERMINO VAR. ENERGIA P1
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTE1"
                                    placeholder=""
                                    name="precioTE1"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTE1}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="2" lg="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTE2">
                                    TERMINO VAR. ENERGIA P2
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTE2"
                                    placeholder=""
                                    name="precioTE2"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTE2}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="2" lg="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="factork">
                                    Factor K
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="factork"
                                    placeholder=""
                                    name="factork"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.factork}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="2" lg="2">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTFijo">
                                    TERMINO FIJO ENERGIA
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTFijo"
                                    placeholder=""
                                    name="precioTFijo"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTFijo}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>

                    </Row>


                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Row>

                        <Col>
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTEP1">
                                    TERMINO ENERGIA P1
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP1"
                                    placeholder=""
                                    name="precioTEP1"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP1}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTEP2">
                                    TERMINO ENERGIA P2
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP2"
                                    placeholder=""
                                    name="precioTEP2"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP2}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>


                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTEP3">
                                    TERMINO ENERGIA P3
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP3"
                                    placeholder=""
                                    name="precioTEP3"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP3}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTEP4">
                                    TERMINO ENERGIA P4
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP4"
                                    placeholder=""
                                    name="precioTEP4"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP4}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledE456}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTEP5">
                                    TERMINO ENERGIA P5
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP5"
                                    placeholder=""
                                    name="precioTEP5"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP5}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledE456}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTEP6">
                                    TERMINO ENERGIA P6
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTEP6"
                                    placeholder=""
                                    name="precioTEP6"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTEP6}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledE456}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTP1">
                                    TERMINO POTENCIA P1
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP1"
                                    placeholder=""
                                    name="precioTP1"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP1}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="precioTP2">
                                    TERMINO POTENCIA P2
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP2"
                                    placeholder=""
                                    name="precioTP2"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP2}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabled}
                                />
                            </FormGroup>
                        </Col>


                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTP3">
                                    TERMINO POTENCIA P3
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP3"
                                    placeholder=""
                                    name="precioTP3"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP3}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledP3456}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTP4">
                                    TERMINO POTENCIA P4
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP4"
                                    placeholder=""
                                    name="precioTP4"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP4}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledP3456}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTP5">
                                    TERMINO POTENCIA P5
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP5"
                                    placeholder=""
                                    name="precioTP5"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP5}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledP3456}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="precioTP6">
                                    TERMINO POTENCIA P6
                                </Label>
                                <Input
                                    className="text-primary"
                                    id="precioTP6"
                                    placeholder=""
                                    name="precioTP6"
                                    onChange={this.onChangeFieldProducto}
                                    value={this.state.formProducto.precioTP6}
                                    type="number"
                                    required
                                    disabled={this.state.inputDisabledP3456}
                                />
                            </FormGroup>
                        </Col>

                    </Row>


                </React.Fragment>

            )

        }
    }

        ///////////////////////////////////////////////////////////////////////////////////

        tipoContrato = (props) => {
            const tipoContrato = props.tipoContrato;
            if (this.state.esAgencia == true || this.state.esAdFincas == true) {
                if (tipoContrato === 'L' || tipoContrato === 'E') {
                    return (
                        <React.Fragment>
                            <Col md="12" lg="4">
                                <FormGroup>
                                    <Label
                                        className="form-control-label"
                                        htmlFor="codigoComercializadora"
                                    >
                                        Comercializadora
                                    </Label>
                                    <SipsOption
                                        placeholder="Seleccionar comercializadora"
                                        id="codeComercializadora"
                                        name="codeComercializadora"
                                        value={this.state.filters.codeComercializadora}
                                        query="LIST_MARKETERS"
                                        onChange={this.onChangeFieldSearch}
                                    />
                                </FormGroup>
                            </Col>
                        </React.Fragment>
                    )
    
                } else {
                    return (
                        <React.Fragment>
                            <Col md="12" lg="4">
                                <FormGroup>
                                    <Label
                                        className="form-control-label"
                                        htmlFor="codigoComercializadora"
                                    >
                                        Comercializadora
                                    </Label>
                                    <SipsOption
                                        placeholder="Seleccionar comercializadora"
                                        id="codeComercializadora"
                                        name="codeComercializadora"
                                        value={this.state.filters.codeComercializadora}
                                        query="LIST_MARKETERS_GAS"
                                        onChange={this.onChangeFieldSearch}
                                    />
                                </FormGroup>
                            </Col>
                        </React.Fragment>
                    )
    
                }
            } else {
                return (
                    <React.Fragment>
    
                    </React.Fragment>
                )
            }
        };

        /////////////////////////////////////////////////////////////////////////////////////////////////////

    comisionSeleccionada = async (event) => {

        var formData = { ...this.state.formProducto };

        let comision: any = event.comision.comisionProducto
        formData['eurosMwh'] = event.comision.eurosMwh

        formData['tipoComisionProducto'] = event.comision.tipoComisionProducto
        formData['comisionProducto'] = event.comision.comisionProducto

        this.setState({
            formProducto: formData,
            dataComisiones: comision
        });
      
    };

    //////////////////////////////////////////////////////////////////////////////////

    render() {

        const formProducto: any = this.state.formProducto;
        const fechaAltaProducto = this.state.fechaAltaProducto
        const fechaBajaProducto = this.state.fechaBajaProducto

        const productoDesde = this.state.productoDesde
        const productoHasta = this.state.productoHasta

        const periodoValidezHasta = this.state.periodoValidezHasta
        const periodoValidezDesde = this.state.periodoValidezDesde

        let freeModal = <></>;

        if (this.state.openModalComparative == true) {

            freeModal = (
                <ComisionProductoFreeModal
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    comercializadora={this.state.formProducto.idComercializadora}
                    tipoProducto={this.state.tipoProducto}
                    tipoComision={this.state.formProducto.tipoComisionProducto}
                    companyId={this.state.companyIdUser}
                    dataComisiones={this.comisionSeleccionada}
                ></ComisionProductoFreeModal>
            );
        };

        var textoTipoProducto = '';
        if (this.state.tipoProducto == 'G') {
            textoTipoProducto = 'Gas';
        } else {
            textoTipoProducto = 'Electricidad';
        }

        return (
            <>
                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es">
                        <Header title={'Nuevo Producto ' + textoTipoProducto} />
                        <Container className=" mt--7" fluid>

                            <Card className="mb-4">
                                <CardHeader>
                                    <Button
                                        onClick={this.showFiltersToggle}
                                        size="sm"
                                        className="float-right"
                                    >
                                        <i className={"ni " + this.getFilterIcon()} />
                                    </Button>
                                    <h3 className="mb-0">Filtrar</h3>
                                </CardHeader>
                                <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                            <Form innerRef={this.formRef}>
                                                <Row>
                                                    <Col md="12" lg="3">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="productoRef">
                                                                NOMBRE PRODUCTO
                                                            </Label>
                                                            <Input
                                                                id="nombreProductoSearch"
                                                                placeholder="Ingresar nombre del producto"
                                                                name="nombreProductoSearch"
                                                                maxLength={60}
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.nombreProductoSearch}
                                                                type="text"
                                                                disabled={this.state.inputNombreProductoDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" htmlFor="idSearch">
                                                                Identificador
                                                            </Label>
                                                            <Input
                                                                id="idSearch"
                                                                placeholder="Ingresar ID del producto"
                                                                name="idSearch"
                                                                maxLength={40}
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.idSearch}
                                                                type="text"
                                                                disabled={this.state.inputIdDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" for="tipoEnergia">TIPO CONTRATO</Label>
                                                            <Input type="select" name="tipoContratoSearch" id="tipoContratoSearch"
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.tipoContratoSearch}>
                                                                <option selected>Selecciona...</option>
                                                                <option value="E">ELECTRICIDAD</option>
                                                                <option value="G">GAS</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>

                                                    <this.tipoContrato tipoContrato={this.state.filters.tipoContratoSearch} />

                                                    <Col md="2" lg="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                            <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                                onChange={this.onChangeFieldSearch}
                                                                value={this.state.filters.tipoEstadoSearch}>
                                                                <option selected>Selecciona...</option>
                                                                <option value="true">ACTIVO</option>
                                                                <option value="false">BAJA</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label">Desde</Label>
                                                            <DatePicker id="productoDesde"
                                                                value={productoDesde}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "productoDesde")}
                                                                instanceCount={1}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label className="form-control-label">Hasta</Label>
                                                            <DatePicker id="productoHasta"
                                                                value={productoHasta}
                                                                onChange={(v, f) => this.handleChangeDate(v, f, "productoHasta")}
                                                                instanceCount={1}
                                                                dateFormat={"DD-MM-YYYY"}
                                                                dayLabels={spanishDayLabels}
                                                                monthLabels={spanishMonthLabels}
                                                                disabled={this.state.inputDisabled}

                                                            />
                                                        </FormGroup>
                                                    </Col>



                                                    <Col className="text-right">
                                                        <Button onClick={this.clearForm}>
                                                            <i className={"ni ni-fat-remove"} /> Limpiar
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            onClick={this.buscarProducto}
                                                            disabled={this.state.buscarButtonDisabled}
                                                        >
                                                            <i className={"bi bi-search"} /> Buscar
                                                        </Button>
                                                    </Col>
                                                </Row>


                                                <this.tipoLecturaTable tipoContrato={this.state.filters.tipoContratoSearch} />


                                            </Form>
                                        </CardBody>
                                </Collapse>
                            </Card>

                            <Row>
                                <div className="col">
                                    <Card className="shadow">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={this.props.history.goBack}
                                                    >
                                                        Atrás
                                                    </button>
                                                </Col>

                                                <Col lg = "auto" className="d-flex">
                                                    <Card className="p-3 bg-light">

                                                        <Form onSubmit={this.onSubmit}>

                                                            <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl} multiple />

                                                            <button disabled={this.state.saveButtonDisabled} id="gpsProptooltip" type="submit" color="#2dce89" className="btn btn-primary btn-sm btn-circle" >
                                                                <span className="btn-inner--icon">
                                                                    <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">  Importar</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                                    IMPORTAR PRODUCTOS AL SERVIDOR
                                                                </UncontrolledTooltip>
                                                            </button>
                                                        </Form>
                                                        <FormText color="muted">
                                                            Seleccionar el fichero excel con productos a importar
                                                        </FormText>
                                                    </Card>
                                                </Col>


                                                <Col className="col-auto d-flex">
                                                    <Card className="p-3 bg-light">
                                                        <FormGroup>

                                                            <Button disabled={this.state.saveProductoButtonDisabled} id="guardarRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-2"
                                                                onClick={() => { this.saveHandler(this.state.saveDataProducto) }}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-save" style={{ fontSize: 30 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">Guardar</span>
                                                                <UncontrolledTooltip delay={0} placement="top" target="guardarRepreProptooltip">
                                                                    CREAR PRODUCTO
                                                                </UncontrolledTooltip>
                                                            </Button>

                                                        </FormGroup>
                                                    </Card>
                                                </Col>

                                            </Row>


                                            <div>
                                                <CardTitle>PRODUCTO</CardTitle>
                                            </div>

                                            <Row>
                                                <Col>
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="nombreOferta">
                                                            Nombre Producto
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="nombreOferta"
                                                            placeholder=""
                                                            name="nombreOferta"
                                                            maxLength={80}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.nombreOferta}
                                                            type="text"
                                                            required
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>


                                                <this.tipoLecturaSelect tipoContrato={this.state.tipoProducto} />


                                                <Col md="2" className="pt-3">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="esActiva"
                                                            name="esActiva"
                                                            checked={formProducto.esActiva}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}


                                                        />
                                                        <label className="custom-control-label" htmlFor="esActiva">
                                                            ACTIVO
                                                        </label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="auto" className="pt-3">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="coIncluido"
                                                            name="coIncluido"
                                                            checked={formProducto.coIncluido}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}


                                                        />
                                                        <label className="custom-control-label" htmlFor="coIncluido">
                                                            CO INCLUIDO
                                                        </label>
                                                    </FormGroup>
                                                </Col>


                                                <Col lg="auto" className="pt-3">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="verde"
                                                            name="verde"
                                                            checked={formProducto.verde}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}

                                                        />
                                                        <label className="custom-control-label" htmlFor="verde">
                                                            VERDE
                                                        </label>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <FormGroup>

                                                        <Label className="form-control-label" htmlFor="fechaAltaProducto">
                                                            Fecha Alta Registro
                                                        </Label>

                                                        <DatePicker id="fechaAltaProducto"
                                                            value={fechaAltaProducto}
                                                            onChange={(v, f) => this.handleChangeDate(v, f, "fechaAltaProducto")}
                                                            instanceCount={1}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            dayLabels={spanishDayLabels}
                                                            monthLabels={spanishMonthLabels}
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="fechaBajaProducto">
                                                            Fecha Baja Registro
                                                        </Label>
                                                        <DatePicker id="fechaBajaProducto"
                                                            value={fechaBajaProducto}
                                                            onChange={(v, f) => this.handleChangeDate(v, f, "fechaBajaProducto")}
                                                            instanceCount={2}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            dayLabels={spanishDayLabels}
                                                            monthLabels={spanishMonthLabels}
                                                            disabled={this.state.inputDisabled}
                                                        />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <Card className="shadow">
                                        <CardBody>
                                            <div>
                                                <CardTitle>TARIFAS</CardTitle>
                                            </div>
                                            <Row>
                                                <Col md="2" lg="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" for="precioTPTipo">Periodo Precio</Label>
                                                        <Input type="select" name="precioTPTipo" id="precioTPTipo"
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.precioTPTipo}>
                                                            <option selected>Selecciona...</option>
                                                            <option value="DIARIO">DIARIO</option>
                                                            <option value="ANUAL">ANUAL</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2" lg="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" for="periocidadRevisionPrecios">Duración Contrato</Label>
                                                        <Input type="select" name="periocidadRevisionPrecios" id="periocidadRevisionPrecios"
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.periocidadRevisionPrecios}>
                                                            <option selected>Selecciona...</option>
                                                            <option value="ANUAL">12 MESES</option>
                                                            <option value="BIANUAL">24 MESES</option>
                                                            <option value="TRIANUAL">36 MESES</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="periodoValidezDesde">
                                                            Oferta Válida Desde
                                                        </Label>
                                                        <DatePicker id="periodoValidezDesde"
                                                            value={periodoValidezDesde}
                                                            onChange={(v, f) => this.handleChangeDate(v, f, "periodoValidezDesde")}
                                                            instanceCount={1}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            dayLabels={spanishDayLabels}
                                                            monthLabels={spanishMonthLabels}
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>


                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="periodoValidezHasta">
                                                            Oferta Válida Hasta
                                                        </Label>
                                                        <DatePicker id="periodoValidezHasta"
                                                            value={periodoValidezHasta}
                                                            onChange={(v, f) => this.handleChangeDate(v, f, "periodoValidezHasta")}
                                                            instanceCount={2}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            dayLabels={spanishDayLabels}
                                                            monthLabels={spanishMonthLabels}
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                            <this.tipoContratoPeriodos tipoContrato={this.state.tipoProducto} />

                                        </CardBody>
                                    </Card>

                                    <Card className="shadow">

                                        <CardBody>
                                            <div>
                                                <CardTitle>COMISIONES</CardTitle>
                                            </div>
                                            <Row>

                                        <Col md="2" sm="2" lg="2">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="denominacionProducto"
                                                        >
                                                            DENOMINACION PRODUCTO
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="denominacionProducto"
                                                            placeholder=""
                                                            name="denominacionProducto"
                                                            min={0}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.denominacionProducto}
                                                            type="text"
                                                            required
                                                        />

                                                        <FormText color="muted">
                                                            Introduzca denominación producto
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2" sm="2" lg="2">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="zona"
                                                        >
                                                            ZONA GEOGRAFICA
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="zona"
                                                            placeholder="0"
                                                            name="zona"
                                                            min={0}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.zona}
                                                            type="number"
                                                            required
                                                        />

                                                        <FormText color="muted">
                                                            Introduzca la zona GEOGRAFICA
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>


                                                <Col md="2" sm="2" lg="2">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="comisionContratoNuevo"
                                                        >
                                                            COMISION NUEVO CONT.
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="comisionContratoNuevo"
                                                            placeholder=""
                                                            name="comisionContratoNuevo"
                                                            min={0}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.comisionContratoNuevo}
                                                            type="text"
                                                            required
                                                        />

                                                        <FormText color="muted">
                                                            Introduzca la cantidad en euros
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2" sm="2" lg="2">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="comisionContratoRenovacion"
                                                        >
                                                            COMISION RENOVACION
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="comisionContratoRenovacion"
                                                            placeholder="0"
                                                            name="comisionContratoRenovacion"
                                                            min={0}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.comisionContratoRenovacion}
                                                            type="number"
                                                            required
                                                        />

                                                        <FormText color="muted">
                                                            Introduzca la cantidad en euros
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>

                                    <this.comision tipoComision={formProducto.coIncluido} />

                                    <Card className="shadow">
                                        <this.comisionTipologia tipoComision={formProducto.coIncluido} idComision={formProducto.tipoComisionProducto} />
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <div>
                                                <CardTitle>OTROS DATOS</CardTitle>
                                            </div>

                                            <Row>

                                                <Col lg="auto" className="pt-1">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="ofertaInternet"
                                                            name="ofertaInternet"
                                                            checked={formProducto.ofertaInternet}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}

                                                        />
                                                        <label className="custom-control-label" htmlFor="ofertaInternet">
                                                            OFERTA INTERNET
                                                        </label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="auto" className="pt-1">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="ofertaOficina"
                                                            name="ofertaOficina"
                                                            checked={formProducto.ofertaOficina}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}


                                                        />
                                                        <label className="custom-control-label" htmlFor="ofertaOficina">
                                                            OFERTA OFICINA
                                                        </label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="auto" className="pt-1">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="ofertaTel"
                                                            name="ofertaTel"
                                                            checked={formProducto.ofertaTel}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}


                                                        />
                                                        <label className="custom-control-label" htmlFor="ofertaTel">
                                                            OFERTA TELEFONICA
                                                        </label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="auto" className="pt-1">
                                                    <FormGroup className="custom-control custom-checkbox">

                                                        <Input
                                                            className="custom-control-input"
                                                            id="serviciosObligatorios"
                                                            name="serviciosObligatorios"
                                                            checked={formProducto.serviciosObligatorios}
                                                            type="checkbox"
                                                            onChange={this.onChangeFieldProducto}

                                                        />
                                                        <label className="custom-control-label" htmlFor="serviciosObligatorios">
                                                            SERVICIOS OBLIGATORIOS
                                                        </label>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>

                                                <Col md="3" sm="3" lg="3">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="nombreDocumentoContrato"
                                                        >
                                                            NOMBRE DOCUMENTO CONTRATO
                                                        </Label>
                                                        <Input
                                                            className="text-primary"
                                                            id="nombreDocumentoContrato"
                                                            placeholder=""
                                                            name="nombreDocumentoContrato"
                                                            maxLength={80}
                                                            onChange={this.onChangeFieldProducto}
                                                            value={formProducto.nombreDocumentoContrato}
                                                            type="text"
                                                            required
                                                        />

                                                        <FormText color="muted">
                                                            Introduzca el nombre del documento formato PDF necesario para generar el contrato
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>

                                                <Col md="6" sm="12" lg="6">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="infoAdicional"
                                                        >
                                                            INFORMACION ADICIONAL
                                                        </Label>
                                                        <Input
                                                            id="infoAdicional"
                                                            name="infoAdicional"
                                                            type="textarea"
                                                            style={{
                                                                resize: "none"
                                                            }}
                                                            value={formProducto.infoAdicional}
                                                            onChange={this.onChangeFieldProducto}
                                                        ></Input>
                                                        <FormText color="muted">
                                                            Introduzca la información que desee que se refleje como información para el agente o consumidor
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6" sm="12" lg="6">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="caracteristicas"
                                                        >
                                                            CARACTERISTICAS
                                                        </Label>
                                                        <Input
                                                            id="caracteristicas"
                                                            name="caracteristicas"
                                                            type="textarea"
                                                            style={{
                                                                resize: "none"
                                                            }}
                                                            value={formProducto.caracteristicas}
                                                            onChange={this.onChangeFieldProducto}
                                                        ></Input>
                                                        <FormText color="muted">
                                                            Introduzca las características que desee que se refleje como información para el agente o consumidor
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>

                                    {this.state.alert}

                                </div>
                            </Row>
                        </Container>
                        {freeModal}
                        </IntlProvider>
                    </LocalizationProvider>

                    </>
                    );
    }
}


                    export default withApollo(crearProducto);

import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { customAlphabet } from "nanoid";
// reactstrap components
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    CardTitle,
    CardHeader,
    CardFooter,
    Container,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import { Link } from 'react-router-dom';

import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import 'moment/locale/es'
//import { uuid } from "uuidv4";
//import { uuid } from 'uuidv4';
import { validateSpanishId, spainIdType } from 'spain-id'
import uuid from "react-uuid";
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate,
    registerTheme,
    Geom,
    Interaction
} from 'bizcharts';
import DataSet from "@antv/data-set";

// core components
import Header from "../../components/Headers/Header";
import SipsFilter from "../../components/Sips/SipsFilterMulti";
//import SipComparativeFreeModalMultips from "./SipComparativeFreeModalMultips";
import SaveModalMultips from "./SaveModalMultips";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/Pagination";
import { LIST_BUSINESS, LIST_SIPS, EXPORT_SIPS } from "../../queries/sips/sips.graphql";
import { LIST_SIPS_UBICACION_CIF } from "../../queries/ubicacion/sip-ubicacion";
import { LIST_COMERCIAL_PRODUCTOS } from "../../queries/comercial-producto/comercial-producto";
import Environment from "../../constants/environment";
import {
    CREATE_SIPS_COMPARATIVAS_LIBRE_MP,
    LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
} from "../../queries/sips-comparativa/sip-comparativa-libre";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import memoize from 'memoize-one';
import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import SipComparativeFreeMultiModal from "./SipComparativeFreeMultiModalps";
import ComercialMultiProducto from "./ComercialMultiProductops"
const alphabet = '0123456789';
const nanoid = customAlphabet(alphabet, 11);


//const COLORS = ['#0088FE', '#a177ef', '#e163cb', '#ff599d', '#ff656e','#ff8042'];


registerTheme('default', {
    defaultColor: '#505050',
    colors10: ['#0088FE', '#a177ef', '#e163cb', '#ff599d', '#ff656e', '#ff8042',
        "#e5cf0d",
        "#97b552",
        "#95706d",
        "#dc69aa"],
    geometries: {
        interval: {
            rect: {
                active: { style: { stroke: '#5AD8A6', lineWidth: 1 } },
                inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
                selected: {},
            }
        }
    }
});

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage || '{}');

const showIndexButtonCol = parsed.indexada;
const showFreeMarketButtonCol = parsed.mercadolibre;

const sortIcon = <ArrowDownward />;

const columns = [
    {
        // eslint-disable-next-line react/button-has-type
        //cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

        cell: row => <Link
            className="btn btn-primary btn-sm btn-circle"
            data-toggle="modal"
            to={{ pathname: "sip-details/" + row.cups }}
            id="buscartooltip"
        >
            <i className="pe-7s-search text-white"></i>
        </Link>,

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'INFO',
        minWidth: '55px'

    },
    {
        cell: row => <Link
            className="btn btn-light btn-sm btn-circle"
            data-toggle="modal"
            to={{ pathname: "sip-comparative-index/" + row.cups }}
            id="indextooltip"
        >
            <i className="pe-7s-date text-white"></i>
        </Link>,

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        omit: showIndexButtonCol,
        name: 'INDEX',
        minWidth: '55px'

    },
    {
        cell: row => <Link
            className="btn btn-info btn-sm btn-circle"
            data-toggle="modal"
            to={{ pathname: "sip-comparative-free/" + row.cups }}
            id="fijotooltip"
        >
            <i className="pe-7s-calculator text-white"></i>
        </Link>,

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        omit: showFreeMarketButtonCol,
        name: 'FIJO',
        minWidth: '55px'

    },


    {
        selector: row => row['ProblemaIdString'],
        //cell: row => <Icon style={{ fill: '#43a047' }} />,
        name: 'P',
        sortable: true,
        width: '40px',
        center: true,
        conditionalCellStyles: [
            {
                when: row => row.ProblemaIdString === "",

                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    color: 'white',
                    fontSize: '8px',
                    width: '20px',
                    borderRadius: '5px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },

            {
                when: row => row.ProblemaIdString !== "",
                style: {
                    backgroundImage: 'url(/error.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '30px',
                    backgroundPosition: 'center',
                    //height: "50vh",
                    //backgroundColor: 'rgba(248, 148, 6, 0.9)',
                    color: 'rgba(250, 250, 250, 0)',
                    fontSize: '8px',
                    //width: '48px',
                    //width: `calc(100vw + 48px)`,
                    borderRadius: '5px',
                    '&:hover': {
                        cursor: 'pointer',

                    },
                },
            },
        ]


    },


    {
        selector: row => row['cups'], name: 'CUPS', width: '190px', style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 500,

        },
    },
    { selector: row => row['tarifaATR'], name: 'TARIFA', sortable: true, },
    { selector: row => row['kWhAnual'], name: 'kWh ANUAL', sortable: true, },
    {
        selector: row => row['nombreCompletoTitular'], name: 'TITULAR', sortable: true, style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['CIF'], name: 'CIF', sortable: true, },
    { selector: row => row['nombreEmpresaDistribuidora'], name: 'DISTRIBUIDORA PRINCIPAL', sortable: true, center: true, wrap: true },
    { selector: row => row['empresaDistribuidora'], name: 'DISTRIBUIDORA', sortable: true, },
    { selector: row => row['comercializadora'], name: 'COMERCIALIZADORA', sortable: true, width: '210px', center: true, wrap: true },
    { selector: row => row['direccion_ps'], name: 'DIRECCION' },
    { selector: row => row['localidad_ps'], name: 'LOCALIDAD', sortable: true },
    { selector: row => row['codigoPostalPS'], name: 'CODIGO POSTAL', sortable: true, },
    { selector: row => row['nombreProvinciaPS'], name: 'PROVINCIA', sortable: true, },
    { selector: row => row['fechaUltimoMovimientoContrato'], name: 'ULTIMO MOVIMIENTO', sortable: true, format: (row => moment(row.fechaUltimoMovimientoContrato).isValid() ? moment(row.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "") },
    { selector: row => row['fechaUltimoCambioComercializador'], name: 'ULTIMO CAMBIO COMERCIAL', sortable: true, format: (row => moment(row.fechaUltimoCambioComercializador).isValid() ? moment(row.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "") }, //error supplyinfor


];

const columnsDatosR1 = [
    {
        selector: row => row['Id'], name: 'ATR', sortable: true, width: '120px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['TotalContador'], name: 'Nº Contratos', sortable: true, width: '120px', center: true },
    { selector: row => row['TotalConsumoAnual'], name: 'T. Energía Contratos MW', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual) / 1000)) },
];

const columnsDatosR3 = [
    {
        selector: row => row['nombreOferta'], name: 'Producto', width: '230px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['nombreComercializadora'], name: 'Comercializadora', width: '200px', center: true },
    { selector: row => row['tarifaATR'], name: 'ATR', width: '100px', center: true },
    { selector: row => row['verde'], name: 'E. Verde', width: '100px', center: true },
    { selector: row => row['precioTEP1'], name: 'P.E. P1', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP1)) },
    { selector: row => row['precioTEP2'], name: 'P.E. P2', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP2)) },
    { selector: row => row['precioTEP3'], name: 'P.E. P3', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP3)) },
    { selector: row => row['precioTEP4'], name: 'P.E. P4', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP4)) },
    { selector: row => row['precioTEP5'], name: 'P.E. P5', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP5)) },
    { selector: row => row['precioTEP6'], name: 'P.E. P6', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP6)) },
    { selector: row => row['precioTP1'], name: 'P.T. P1', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP1)) },
    { selector: row => row['precioTP2'], name: 'P.T. P2', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP2)) },
    { selector: row => row['precioTP3'], name: 'P.T. P3', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP3)) },
    { selector: row => row['precioTP4'], name: 'P.T. P4', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP4)) },
    { selector: row => row['precioTP5'], name: 'P.T. P5', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP5)) },
    { selector: row => row['precioTP6'], name: 'P.T. P6', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP6)) },
];

const columnsDatosR2 = [
    {
        selector: row => row['Id'], name: 'Comercializadora', sortable: true, width: '230px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['TotalContador'], name: 'Nº Contratos', sortable: true, width: '120px', center: true },
    { selector: row => row['TotalConsumoAnual'], name: 'T. Energía Contratos MW', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual) / 1000)) },
];


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};




interface IAtrState {
    form: {
        idProducto: any
        costeOperativo: number,
        costeOperativoPotencia: number,
    },
    producto: {
        productoSeleccionado: any
    };
    productoMulti: {
        active: boolean;
        productoSeleccionado: any[];
        costeOperativo: number;
        costeOperativoPotencia: number;
    };
    UserStore: any;
    electricidad: boolean;
    isLoading: boolean;
    isSearching: boolean;
    copiedText: string;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    count: number;
    page: number;
    limit: number;
    //producto: {productoSeleccionado:[{tarifaATR:'000'}]},
    productoVisual: any;
    user: any;
    paginatedDataTemp: {
        items: any[]
    };
    CIFTEMP: string;
    paginatedData: {
        items: any[]
    };
    showIndexButton: boolean;
    showFreeMarketButton: boolean;
    sumOfEnergy: number;
    sumOfEnergyLast: number;
    ubicacion: any;
    showList: boolean;
    cupsGroupedBydistri: any[];
    cupsGroupedBydistriEnergy: any[];
    cupsGroupedByATREnergy: any;
    cupsGroupedByComer: any[];
    cupsGroupedByATR: any[];
    cupsGroupedByProvincia: any[];
    cupsGroupedByComercialEnergy: any[];
    cupsGroupedByComercialEnergyCont: any[];
    EnergiaTotalATRCont: any[];
    datosEmpresa: any;
    itemsSelected: any[];
    openModalComparative: boolean;
    openModalMultiComparative: boolean;
    openModalMultiComparativeSave: boolean;

    numPeriods: number;
    numPeriodsPotencia: number;
    periodos: number[];
    saveButtonDisabled: boolean;
    selectProductButtonDisabled: boolean;
    saveModalOpened: boolean;
    titular: {
        data: {
            nombre_completo_titular: string,
            CIF: string,
            direccion_titular: string,
            codigo_postal_titular: string,
            telefono_fijo: string,
            telefono: string,
            email_contacto: string,
            tipo_cliente: string,
            observaciones: string,
        },
    };
    productSelected: any;
    productId: string;
    comercializadoraId: string;
    nombreComercializadora: string;
    tarifaATR: string;
    tarifaATRDescripcion: string,
    selectedRows: any[];
    rates2X: any[];
    rates3X: any[];
    rates2XP: any[];
    rates1XP: any[];
    rates3XP: any[];
    rates6XP: any[];
    rates1XE: any[];
    rates2XE: any[];
    rates3XE: any[];
    rates6XE: any[];
    tarifaATRContrato: any[];
    companyID: any;
    userId: any;
    nombreAgente:any;
    companyName:any;
    correoAgente:any;
    comercialProductos: any;
    toggledClearRows: boolean;
    toggledClearRowsPS: boolean;

    productosPrecios: any[];
    productosPreciosPeriodoDia: any[];
    periodosCupStringTemp: number;
    comparativeTemp: {
        producto: any,
        enabledSave: boolean,
        actual: any
    }
    enabledSave: boolean;
    isDisabled: boolean;
    companyIdUser: any;
    //userId: string;
    alert: any;
    //productosPrecios:{
    //  [[{producto:string,  precioEnergiaP1:number,  precioEnergiaP2:number, precioEnergiaP3:number,  precioEnergiaP4:number, precioEnergiaP5:number, precioEnergiaP6:number,precioPotenciaP1: number, precioPotenciaP2: number, precioPotenciaP3: number, precioPotenciaP4: number, precioPotenciaP5: number, precioPotenciaP6: number },
    //]]};

};



class MultipsNew extends Reflux.Component<any, any> {

    public lastFiltersMulti: any = null;

    public state: IAtrState = {
        form: {
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            idProducto: null
        },
        UserStore: {},
        electricidad: false,
        producto: { productoSeleccionado: [{ tarifaATR: '000' }] },
        productoMulti: {
            active: false,
            productoSeleccionado: [],
            costeOperativo: 0,
            costeOperativoPotencia: 0,
        },
        comparativeTemp: {
            producto: [{ tarifaATR: '000' }],
            enabledSave: false,
            actual: []
        },
        isLoading: false,
        isSearching: false,
        copiedText: '',
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        page: 1,
        limit: 200,
        //producto: {productoSeleccionado:[{tarifaATR:'000'}]},
        productoVisual: { productoSeleccionado: [] },
        user: { id: '' },
        paginatedDataTemp: { items: [] },
        CIFTEMP: '',
        paginatedData: { items: [{ CIF: '' }] },
        showIndexButton: false,
        showFreeMarketButton: false,
        ubicacion: {},
        showList: true,
        sumOfEnergy: 0,
        sumOfEnergyLast: 0,
        cupsGroupedBydistri: [],
        cupsGroupedBydistriEnergy: [],
        cupsGroupedByATREnergy: [{ Id: "SIN DATOS", TotalConsumoAnual: 0 },],
        cupsGroupedByComer: [],
        cupsGroupedByATR: [],
        cupsGroupedByProvincia: [],
        cupsGroupedByComercialEnergy: [],
        cupsGroupedByComercialEnergyCont: [],
        EnergiaTotalATRCont: [],
        datosEmpresa: {},
        itemsSelected: [],
        openModalComparative: false,
        openModalMultiComparative: false,
        openModalMultiComparativeSave: false,
        numPeriods: 0,
        numPeriodsPotencia: 0,
        saveButtonDisabled: true,
        selectProductButtonDisabled: true,
        saveModalOpened: false,
        titular: {
            data: {
                nombre_completo_titular: '',
                CIF: '',
                direccion_titular: '',
                codigo_postal_titular: '',
                telefono_fijo: '',
                telefono: '',
                email_contacto: '',
                tipo_cliente: '',
                observaciones: '',
            },
        },
        productSelected: null,
        productId: "",
        comercializadoraId: "",
        nombreComercializadora: "",
        tarifaATR: "",
        tarifaATRDescripcion: "",
        selectedRows: [],
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        tarifaATRContrato: [
            {
                id: "018",
                name: "2.0TD"
            },
            {
                id: "019",
                name: "3.0TD"
            },
            {
                id: "020",
                name: "6.1TD"
            },
            {
                id: "021",
                name: "6.2TD"
            },
            {
                id: "022",
                name: "6.3TD"
            },
            {
                id: "023",
                name: "6.4TD"
            },
            {
                id: "024",
                name: "3.0TDVE"
            },
            {
                id: "025",
                name: "6.1TDVE"
            },
        ],
        comercialProductos: [],
        companyID: "",
        //userId: "",
        nombreAgente:"",
        companyName:"",
        correoAgente:"",
        periodos: [],
        toggledClearRows: false,
        toggledClearRowsPS: false,
        productosPrecios: [{ producto: "", precioEnergiaP1: 0, precioEnergiaP2: 0, precioEnergiaP3: 0, precioEnergiaP4: 0, precioEnergiaP5: 0, precioEnergiaP6: 0, precioPotenciaP1: 0, precioPotenciaP2: 0, precioPotenciaP3: 0, precioPotenciaP4: 0, precioPotenciaP5: 0, precioPotenciaP6: 0 },
        ],
        //productosPreciosPeriodoDia:[{temporada:"",  mes:0,  hora:0, precioEnergía:0},]
        productosPreciosPeriodoDia: [],
        periodosCupStringTemp: 0,
        enabledSave: false,
        isDisabled: false,
        companyIdUser: 0,
        userId: "",
        alert: null
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.toggleMulti = this.toggleMulti.bind(this);
        this.toggleMultiSave = this.toggleMultiSave.bind(this);
        this.comparativeDataHandler = this.comparativeDataHandler.bind(this);
        this.showMultiModal = this.showMultiModal.bind(this);
        this.showMultiModalSave = this.showMultiModalSave.bind(this);
        this.changeSave = this.changeSave.bind(this);
        this.handleClearRows = this.handleClearRows.bind(this)
    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALOR DE USUARIO ----------------->', usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const electricidad = usuario.electricidad
        const nombreAgente = usuario.fullname
        const correoAgente = usuario.email

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            electricidad,
            companyName: companyNameGlobal,
            nombreAgente: nombreAgente,
            correoAgente: correoAgente,
            companyID: companyIdUser,

        });
    }

    UNSAFE_componentWillMount() {
        if (Object.keys(this.state.paginatedData.items[0]).length > 2) {
            const data2 = this.state.paginatedData.items
            this.setState({
                paginatedDataTemp: data2
            })

        } else {
            //console.log('ENTRA EN EL OBJETO y no TIENE DATOS en el componente')
            const data2: any[] = [];
        }

    };

    clearInvoices = () => {

        this.setState({
            productoMulti: {
                active: false,
                productoSeleccionado: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0
            },
            showList: true,
            productSelected: null,
            toggledClearRows: !this.state.toggledClearRows,
        });
    };

    handleClearRows = () => {
        this.setState({
            toggledClearRowsPS: !this.state.toggledClearRowsPS,
            selectProductButtonDisabled: true
        })
    }


    comparativeDataHandler(data) {

        this.state.comparativeTemp = data;
        this.state.enabledSave = this.state.comparativeTemp.enabledSave
        let enabledSaveTemp: boolean = this.state.comparativeTemp.enabledSave

    }


    componentDidMount() {
        let showIndexButton = false;
        let showFreeMarketButton = false;
        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);

                showIndexButton = parsed.indexada;
                showFreeMarketButton = parsed.mercadolibre;
            } catch (e) { }
        }
        this.setState({ showIndexButton, showFreeMarketButton });
    }


    onSubmit = (filters) => {
        this.lastFiltersMulti = filters;
        this.setState({ page: 1 }, () => {
            this.fetchItems(this.lastFiltersMulti);
        });
    };

    export = async () => {
        let isLoading = true;
        this.setState({ isLoading });
        const filters = this.lastFiltersMulti;
        let { page, limit } = this.state;

        if (filters["limit"]) {
            limit = parseInt(filters["limit"]);
            //delete filters["limit"];
        }
        //limit = this.state.pageSize;

        const sips = await this.props.client.query({
            query: EXPORT_SIPS,
            variables: { page, limit, filters },
        });
        const file = sips.data.sipsExportCSV;
        let tempLink = document.createElement("a");
        tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

        tempLink.click();
        isLoading = false;
        this.setState({ isLoading });
    };

    fetchItems = async (filters) => {
        let cif = ""
        let isLoading = true;
        const isSearching = true;
        var countQuery = 0;
        this.setState({ isLoading, isSearching });
        this.lastFiltersMulti = filters;

        if (this.lastFiltersMulti.hasOwnProperty("limit")) {
            this.state.limit = parseInt(filters.limit, 200);
        }
        if (
            (!this.state.limit || this.state.limit === 0) &&
            (this.state.pageSize !== null || this.state.pageSize > 1)
        ) {
            this.state.limit = this.state.pageSize;
        }

        let { page, limit } = this.state;

        if (filters["limit"]) {
            limit = parseInt(filters["limit"]);
            delete filters["limit"];
        }

        if (filters["CIF"]) {
            cif = filters["CIF"];
        }

        if (limit === 0 && this.state.pageSize > 1) {
            limit = this.state.pageSize;
        }

        let event: any = {
            allSelected: false,
            selectedCount: 0,
            selectedRows: []
        }

        this.handleClearRows()

        await this.props.client.query({
            query: LIST_SIPS,
            variables: { page, limit, filters },
        }).then(res => {

            const {
                currentPage,
                pageSize,
                pageCount,
                count,
                items,
            } = res.data.listSips;

            countQuery = count;
            const paginatedData = { items };


            let threePeriods = ['001', '004', '005', '006', '007', '008', '018'];
            let fourPeriods = ['003']
            var periodosenergia = '0'
            if (threePeriods.includes(paginatedData.items[0]['codigoTarifaATREnVigor'])) {
                periodosenergia = '3'
                this.setState({
                    numPeriods: '3',
                    numnumPeriodsPotencia: '2'
                });
            } else if (fourPeriods.includes(paginatedData.items[0]['codigoTarifaATREnVigor'])) {
                periodosenergia = '4'
                this.setState({
                    numPeriods: '4',
                    numnumPeriodsPotencia: '6'
                });
            } else {
                periodosenergia = '6'
                this.setState({
                    numPeriods: '6',
                    numnumPeriodsPotencia: '6'
                });
            }


            isLoading = false;
            if (count === 0) {

                this.setState({
                    paginatedData: { items: [{ CIF: '' }] },
                    paginatedDataTemp: { items: [] },
                    currentPage,
                    pageSize,
                    pageCount,
                    count,
                    isLoading,
                    numPeriods: periodosenergia,
                    numnumPeriodsPotencia: '2',
                    datosEmpresa: [],
                    showList: true,
                    selectedRows: [],
                    sumOfEnergy: 0,
                    sumOfEnergyLast: 0,
                    cupsGroupedBydistri: [],
                    cupsGroupedBydistriEnergy: [],
                    cupsGroupedByATREnergy: [{ Id: "SIN DATOS", TotalConsumoAnual: 0 },],
                    cupsGroupedByComer: [],
                    cupsGroupedByATR: [],
                    cupsGroupedByComercialEnergy: [],
                    EnergiaTotalATRCont: [],
                    cupsGroupedByComercialEnergyCont: [],
                    cupsGroupedByProvincia: [],
                })
            } else {

                let threePeriods = ['001', '004', '005', '006', '007', '008', '018'];
                let fourPeriods = ['003']
                let periodos = ''
                let periodosPotencia = ''
                if (threePeriods.includes(paginatedData.items[0]['codigoTarifaATREnVigor'])) {
                    periodos = '3'
                    periodosPotencia = '2'
                } else if (fourPeriods.includes(paginatedData.items[0]['codigoTarifaATREnVigor'])) {
                    periodos = '4'
                    periodosPotencia = '6'
                } else {
                    periodos = '6'
                    periodosPotencia = '6'
                }

                this.setState({
                    paginatedData: paginatedData,
                    paginatedDataTemp: paginatedData,
                    currentPage,
                    pageSize,
                    pageCount,
                    count,
                    isLoading,
                    numPeriods: periodos,
                    numnumPeriodsPotencia: periodosPotencia,
                    datosEmpresa: [],
                    showList: true,
                    selectedRows: [],
                    sumOfEnergy: 0,
                    sumOfEnergyLast: 0,
                    cupsGroupedBydistri: [],
                    cupsGroupedBydistriEnergy: [],
                    cupsGroupedByATREnergy: [{ Id: "SIN DATOS", TotalConsumoAnual: 0 },],
                    cupsGroupedByComer: [],
                    cupsGroupedByATR: [],
                    cupsGroupedByComercialEnergy: [],
                    EnergiaTotalATRCont: [],
                    cupsGroupedByComercialEnergyCont: [],
                    cupsGroupedByProvincia: [],
                });
            };
        })
            .catch(err => {
                isLoading = false;
                this.setState({ isLoading });
            })

    };

    toggleMulti() {
        this.setState({
            openModalMultiComparative: !this.state.openModalMultiComparative,
        });
    }

    toggleMultiSave() {
        this.setState({
            openModalMultiComparativeSave: !this.state.openModalMultiComparativeSave,
        });
    }

    showMultiModal() {
        this.setState({
            openModalMultiComparative: true,
        });
    }

    showMultiModalSave() {
        this.setState({
            openModalMultiComparativeSave: true,
        });
    }


    openModalMultiComparativa = async (event) => {

        await this.closeComparativeSection();
        if (this.state.selectedRows.length > 1) {

            this.showMultiModal();
            return;
        }
        alert("Seleccione contratos para realizar la comparativa");
    };

    openModalMultiSave = async (event) => {

        await this.closeComparativeSection();
        if (this.state.comparativeTemp.enabledSave == true) {
            this.showMultiModalSave();
            return;
        }
        alert("Seleccione un producto para poder salvar");
    };


    closeComparativeSection = async () => {

        this.setState({
            producto: {
                active: false,
                producto: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0
            },
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Propuestas comerciales guardadas correctamente
        </ReactBSAlert>
            )
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en CUPS"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Tienes seleccionados CUPS con tarifas incompatibles
       </ReactBSAlert>
            )
        });
    };

    onSave = async (modalData) => {

        //const idOferta = uuid();
        //const idOferta = nanoid();
        var saveMultiOk: any = []
        for (var i = 0; i < this.state.selectedRows.length; i++) {
            let potenciaP1 = 0;
            let potenciaP2 = 0;
            let potenciaP3 = 0;
            let potenciaP4 = 0;
            let potenciaP5 = 0;
            let potenciaP6 = 0;


            const item: any = this.state.selectedRows[i];
            const now = new Date().toISOString();
            const userCompany = this.state.companyIdUser;

            var usuario = this.state.userId;
            var compañia = this.state.companyIdUser;


            const user: any = this.state.userId;
            const sip: any = this.state;


            const producto = this.state.producto
                ? JSON.stringify(this.state.producto)
                : null;

            const datosContrato = JSON.stringify("");

            const titular = this.state.titular.data;

            const dataModal: any = {
                ...{

                },
                ...modalData,
            };

            var tarifaATRContrato = "";

            var seleccion = ''
            if (this.state.comparativeTemp.producto.producto.tarifaATR) {
                seleccion = this.state.comparativeTemp.producto.producto.tarifaATR

            }

            if (seleccion === "018") {
                tarifaATRContrato = '2.0TD'
            } else if (seleccion === "019") {
                tarifaATRContrato = '3.0TD'
            } else if (seleccion === "020") {
                tarifaATRContrato = '6.1TD'
            } else if (seleccion === "021") {
                tarifaATRContrato = '6.2TD'
            } else if (seleccion === "022") {
                tarifaATRContrato = '6.3TD'
            } else if (seleccion === "023") {
                tarifaATRContrato = '6.4TD'
            } else if (seleccion === "024") {
                tarifaATRContrato = '3.0TDVE'
            } else if (seleccion === "025") {
                tarifaATRContrato = '6.1TDVE'
            };


            if (this.state.rates1XP.includes(item.codigoTarifaATREnVigor) && seleccion === "018") {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);
                potenciaP2 += (Number(item.potenciasContratadasEnWP1) || 0);
            } else if (this.state.rates1XP.includes(item.codigoTarifaATREnVigor) && this.state.rates1XP.includes(seleccion)) {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);
            } else if (this.state.rates2XP.includes(item.codigoTarifaATREnVigor)) {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);
                potenciaP2 += (Number(item.potenciasContratadasEnWP2) || 0);
            } else if (this.state.rates3XP.includes(item.codigoTarifaATREnVigor) && (seleccion === "019" || seleccion === "020")) {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);
                potenciaP2 += (Number(item.potenciasContratadasEnWP2) || 0);
                potenciaP3 += (Number(item.potenciasContratadasEnWP2) || 0);
                potenciaP4 += (Number(item.potenciasContratadasEnWP2) || 0);
                potenciaP5 += (Number(item.potenciasContratadasEnWP2) || 0);
                potenciaP6 += (Number(item.potenciasContratadasEnWP3) || 0);
            } else if (this.state.rates3XP.includes(item.codigoTarifaATREnVigor) && this.state.rates3XP.includes(seleccion)) {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);
                potenciaP2 += (Number(item.potenciasContratadasEnWP2) || 0);
                potenciaP3 += (Number(item.potenciasContratadasEnWP3) || 0);
            } else {
                potenciaP1 += (Number(item.potenciasContratadasEnWP1) || 0);;
                potenciaP2 += (Number(item.potenciasContratadasEnWP2) || 0);;
                potenciaP3 += (Number(item.potenciasContratadasEnWP3) || 0);;
                potenciaP4 += (Number(item.potenciasContratadasEnWP4) || 0);;
                potenciaP5 += (Number(item.potenciasContratadasEnWP5) || 0);;
                potenciaP6 += (Number(item.potenciasContratadasEnWP6) || 0);;
            }

            var codigoPostal = dataModal.CodigoPostalTitular.padStart(5, "0")
            var codigoPostalPS = item.codigoPostalPS.padStart(5, "0")


            var [precioPotenciaAnualP1, precioPotenciaAnualP2, precioPotenciaAnualP3, precioPotenciaAnualP4, precioPotenciaAnualP5, precioPotenciaAnualP6, precioEnergiaAnualP1, precioEnergiaAnualP2, precioEnergiaAnualP3, precioEnergiaAnualP4, precioEnergiaAnualP5, precioEnergiaAnualP6] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

            if (seleccion === '018') {
                precioPotenciaAnualP1 = this.state.comparativeTemp.producto.producto.precioTP1 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP2 = this.state.comparativeTemp.producto.producto.precioTP2 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioEnergiaAnualP1 = this.state.comparativeTemp.producto.producto.precioTEP1 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP2 = this.state.comparativeTemp.producto.producto.precioTEP2 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP3 = this.state.comparativeTemp.producto.producto.precioTEP3 + this.state.comparativeTemp.producto.costeOperativo

            } else {
                precioPotenciaAnualP1 = this.state.comparativeTemp.producto.producto.precioTP1 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP2 = this.state.comparativeTemp.producto.producto.precioTP2 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP3 = this.state.comparativeTemp.producto.producto.precioTP3 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP4 = this.state.comparativeTemp.producto.producto.precioTP4 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP5 = this.state.comparativeTemp.producto.producto.precioTP5 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioPotenciaAnualP6 = this.state.comparativeTemp.producto.producto.precioTP6 + this.state.comparativeTemp.producto.costeOperativoPotencia
                precioEnergiaAnualP1 = this.state.comparativeTemp.producto.producto.precioTEP1 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP2 = this.state.comparativeTemp.producto.producto.precioTEP2 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP3 = this.state.comparativeTemp.producto.producto.precioTEP3 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP4 = this.state.comparativeTemp.producto.producto.precioTEP4 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP5 = this.state.comparativeTemp.producto.producto.precioTEP5 + this.state.comparativeTemp.producto.costeOperativo
                precioEnergiaAnualP6 = this.state.comparativeTemp.producto.producto.precioTEP6 + this.state.comparativeTemp.producto.costeOperativo
            }

            var [consumoPotenciaAnualP1, consumoPotenciaAnualP2, consumoPotenciaAnualP3, consumoPotenciaAnualP4, consumoPotenciaAnualP5, consumoPotenciaAnualP6, consumoEnergiaActivaAnualP1, consumoEnergiaActivaAnualP2, consumoEnergiaActivaAnualP3, consumoEnergiaActivaAnualP4, consumoEnergiaActivaAnualP5, consumoEnergiaActivaAnualP6] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

            if (seleccion === '018') {
                consumoPotenciaAnualP1 = (Number(item.potenciasContratadasEnWP1) || 0) * 12
                consumoPotenciaAnualP2 = (Number(item.potenciasContratadasEnWP2) || 0) * 12
                consumoEnergiaActivaAnualP1 = (Number(item.ConsumoAnualActivaP1) || 0)
                consumoEnergiaActivaAnualP2 = (Number(item.ConsumoAnualActivaP2) || 0)
                consumoEnergiaActivaAnualP3 = (Number(item.ConsumoAnualActivaP3) || 0)

            } else {
                consumoPotenciaAnualP1 = (Number(item.potenciasContratadasEnWP1) || 0) * 12
                consumoPotenciaAnualP2 = (Number(item.potenciasContratadasEnWP2) || 0) * 12
                consumoPotenciaAnualP3 = (Number(item.potenciasContratadasEnWP3) || 0) * 12
                consumoPotenciaAnualP4 = (Number(item.potenciasContratadasEnWP4) || 0) * 12
                consumoPotenciaAnualP5 = (Number(item.potenciasContratadasEnWP5) || 0) * 12
                consumoPotenciaAnualP6 = (Number(item.potenciasContratadasEnWP6) || 0) * 12
                consumoEnergiaActivaAnualP1 = (Number(item.ConsumoAnualActivaP1) || 0)
                consumoEnergiaActivaAnualP2 = (Number(item.ConsumoAnualActivaP2) || 0)
                consumoEnergiaActivaAnualP3 = (Number(item.ConsumoAnualActivaP3) || 0)
                consumoEnergiaActivaAnualP4 = (Number(item.ConsumoAnualActivaP4) || 0)
                consumoEnergiaActivaAnualP5 = (Number(item.ConsumoAnualActivaP5) || 0)
                consumoEnergiaActivaAnualP6 = (Number(item.ConsumoAnualActivaP6) || 0)
            }


            var [costePotenciaAnualP1, costePotenciaAnualP2, costePotenciaAnualP3, costePotenciaAnualP4, costePotenciaAnualP5, costePotenciaAnualP6, costeEnergiaActivaAnualP1, costeEnergiaActivaAnualP2, costeEnergiaActivaAnualP3, costeEnergiaActivaAnualP4, costeEnergiaActivaAnualP5, costeEnergiaActivaAnualP6, costeTotalPropuesta, costeTotalPropuestaPotencia, costeTotalPropuestaEnergia] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

            if (seleccion === '018') {
                costePotenciaAnualP1 = (precioPotenciaAnualP1 * (Number(item.potenciasContratadasEnWP1) || 0)) * 365
                costePotenciaAnualP2 = (precioPotenciaAnualP2 * (Number(item.potenciasContratadasEnWP2) || 0)) * 365
                costeEnergiaActivaAnualP1 = precioEnergiaAnualP1 + consumoEnergiaActivaAnualP1
                costeEnergiaActivaAnualP2 = precioEnergiaAnualP2 + consumoEnergiaActivaAnualP2
                costeEnergiaActivaAnualP3 = precioEnergiaAnualP3 + consumoEnergiaActivaAnualP3
                costeTotalPropuestaPotencia = costePotenciaAnualP1 + costePotenciaAnualP2
                costeTotalPropuestaEnergia = costeEnergiaActivaAnualP1 + costeEnergiaActivaAnualP2 + costeEnergiaActivaAnualP3
                costeTotalPropuesta = costeTotalPropuestaPotencia + costeTotalPropuestaEnergia

            } else {
                costePotenciaAnualP1 = (precioPotenciaAnualP1 * (Number(item.potenciasContratadasEnWP1) || 0)) * 365
                costePotenciaAnualP2 = (precioPotenciaAnualP2 * (Number(item.potenciasContratadasEnWP2) || 0)) * 365
                costePotenciaAnualP3 = (precioPotenciaAnualP3 * (Number(item.potenciasContratadasEnWP3) || 0)) * 365
                costePotenciaAnualP4 = (precioPotenciaAnualP4 * (Number(item.potenciasContratadasEnWP4) || 0)) * 365
                costePotenciaAnualP5 = (precioPotenciaAnualP5 * (Number(item.potenciasContratadasEnWP5) || 0)) * 365
                costePotenciaAnualP6 = (precioPotenciaAnualP6 * (Number(item.potenciasContratadasEnWP6) || 0)) * 365
                costeEnergiaActivaAnualP1 = precioEnergiaAnualP1 + consumoEnergiaActivaAnualP1
                costeEnergiaActivaAnualP2 = precioEnergiaAnualP2 + consumoEnergiaActivaAnualP2
                costeEnergiaActivaAnualP3 = precioEnergiaAnualP3 + consumoEnergiaActivaAnualP3
                costeEnergiaActivaAnualP4 = precioEnergiaAnualP4 + consumoEnergiaActivaAnualP4
                costeEnergiaActivaAnualP5 = precioEnergiaAnualP5 + consumoEnergiaActivaAnualP5
                costeEnergiaActivaAnualP6 = precioEnergiaAnualP6 + consumoEnergiaActivaAnualP6
                costeTotalPropuestaPotencia = costePotenciaAnualP1 + costePotenciaAnualP2 + costePotenciaAnualP3 + costePotenciaAnualP4 + costePotenciaAnualP5 + costePotenciaAnualP6
                costeTotalPropuestaEnergia = costeEnergiaActivaAnualP1 + costeEnergiaActivaAnualP2 + costeEnergiaActivaAnualP3 + costeEnergiaActivaAnualP4 + costeEnergiaActivaAnualP5 + costeEnergiaActivaAnualP6
                costeTotalPropuesta = costeTotalPropuestaPotencia + costeTotalPropuestaEnergia
            }


            var fechaPropuesta = moment
                .parseZone(now.split("T")[0])
                .format("YYYY-MM-DD");
            var check = (moment(fechaPropuesta, "YYYY-MM-DD"));
            console.log('VALOR DE CHECK -------------------> ', check)
            var monthPropuesta = Number(check.format('M'));
            var yearPropuesta = check.year();
            console.log('VALOR DE yearPropuesta -------------------> ', yearPropuesta)



            var fechaAprobacion = moment
                .parseZone(now.split("T")[0])
                .format("YYYY-MM-DD");
            check = (moment(fechaAprobacion, "YYYY-MM-DD"));
            var monthAprobacion = Number(check.format('M'));
            var yearAprobacion = check.year();

            var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

            console.log('VALOR DE FECHAS CURRENT DATE -------> ', currentDate)

            //var local_date= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');

            //console.log('VALOR DE FECHAS local_date -------> ', local_date)

            var partes = currentDate.slice(0).split('T');

            //const now = new Date().toISOString();

            var date = partes[0]
            var hour = partes[1]

            var currentDateAprobacion = moment().format("YYYY-MM-DDTHH:mm:ss");
            //var local_date_aprobacion= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');
            var partesAprobacion = currentDateAprobacion.slice(0).split('T');

            //const now = new Date().toISOString();

            var dateAprobacion = partesAprobacion[0]
            var hourAprobacion = partesAprobacion[1]

            console.log('VALOR DE TRHIS.STATE ANTES DE JSON ---------> ', this.state)
            console.log('VALOR DE DATA MODAL ------>', dataModal)
            var idOferta = nanoid()
            console.log('idOferta  -----> ',idOferta)

            var TipoID = "CIF"
            var result = false

            if(item.NIF){
            result = validateSpanishId(item.NIF)
            }

            if (result === true) {
                const resultTipo = spainIdType(item.NIF)
                console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
                TipoID = resultTipo.toUpperCase();
            }


            if (dataModal.esAprobada === false) {
                var datosContratoP = {
                    Usuario: usuario.toString(),
                    compania: this.state.companyID,
                    fechaPropuesta:date,
                    horaPropuesta: hour,
                    mesPropuesta:monthPropuesta,
                    anioPropuesta:yearPropuesta,
                    fechaAprobacion:'',
                    horaAprobacion:'',
                    mesAprobacion:0,
                    anioAprobacion:0,
                    esAprobada: false,
                    fechaDenegacion: "",
                    horaDenegacion: "",
                    mesDenegacion: 0,
                    anioDenegacion: 0,
                    esDenegada: false,
                    IdOferta: idOferta,
                    IdOfertaInterna: "",
                    cups:  item.cups,
                    tipoCliente: dataModal.TipoCliente,
                    tipoEnergia: "ELECTRICIDAD",
                    estado: "ALTA",
                    estadoId: "01",
                    OfertaTipo: "MP",
                    ofertaTipoId: i,
                    T_Titular: {
                        NombreTitular: item.nombreCompletoTitular,
                        Apellido1Titular: dataModal.apellido1Titular,
                        Apellido2Titular: dataModal.apellido2Titula,
                        NIF: item.CIF, //this.state.ubicacion.CIF,
                        TipoTitular: TipoID,
                        TipoViaTitular: dataModal.tipoViaTitular,
                        ViaTitular: dataModal.viaTitular,
                        NumFincaTitular: dataModal.numFincaTitular,
                        PortalTitular: dataModal.portalTitular,
                        EscaleraTitular: dataModal.escaleraTitular,
                        PisoTitular: dataModal.pisoTitular,
                        PuertaTitular: dataModal.puertaTitular,
                        CodigoPostalTitular: codigoPostal,
                        AutonomiaTitular: dataModal.desAutonomiaTitular,
                        ProvinciaTitular: dataModal.desProvinciaTitular,
                        MunicipioTitular: dataModal.desMunicipioTitular,
                        TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                        TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                        Email: dataModal.correoContacto, //this.state.ubicacion.email_contacto,
                        TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                        ObservacionesCliente: dataModal.ObservacionesCliente,
                        GDPR: dataModal.GDPR,
                        IBAN: dataModal.iban, //
                        CNAE: item.CNAE,
                        actividadCNAE: item.actividadCNAE,
                    },
                    T_Representante: {
                        nombreRepresentante: dataModal.nombreRepresentante.toUpperCase(),
                        apellido1Representante: dataModal.apellido1Representante.toUpperCase(),
                        apellido2Representante: dataModal.apellido2Representante.toUpperCase(),
                        NIFRepresentante: dataModal.NIFRepresentante.toUpperCase(),
                        TipoTitularRepresentante: dataModal.tipoTitularRepresentante,
                        idRepresentante: dataModal.idRepresentante,
                        idRepresentanteInterno: dataModal.idRepresentanteInterno,
                        CargoRepresentante: dataModal.CargoRepresentante.toUpperCase(),
                        telefonoRepresentante: dataModal.telefonoRepresentante,
                        correoRepresentante: dataModal.correoRepresentante,
                    },
                    T_PuntoSuministro: { //Pendiente
                        //Direccion: item.direccion_ps, //this.state.ubicacion.direccion_ps,
                        //CodigoPostal: codigoPostalPS, //this.state.ubicacion.codigo_postal_ps,
                        //Telefono: item.TelefonoPS, //this.state.ubicacion.telefono,
                        //ObservacionesPuntoSuministro: ""
                        TipoViaPS: dataModal.tipoViaPS,
                        ViaPS: item.direccion_ps,
                        NumFincaPS: dataModal.numFincaPS,
                        PortalPS: dataModal.portalPS,
                        EscaleraPS: dataModal.escaleraPS,
                        PisoPS: dataModal.pisoPS,
                        PuertaPS: dataModal.puertaPS,
                        CodigoPostalPS: codigoPostalPS,
                        NombreMunicipioPS: dataModal.nombreMunicipioPS,
                        codigoMunicipioPS: sip.codigoMunicipioPS,
                        NombreProvinciaPS: dataModal.nombreProvinciaPS,
                        codigoProvinciaPS: sip.codigoProvinciaPS,
                        AutonomiaPS: dataModal.desAutonomiaPS,
                        codigoAutonomiaPS:sip.codigoAutonomiaPS,
                        TelefonoPS: dataModal.TelefonoPS,
                        ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro
                    },

                    T_CUPs: {
                        Distribuidora: item.distribuidora,
                        CUPsEle: item.cups,
                        Tarifa: tarifaATRContrato,
                        P1: potenciaP1,
                        P2: potenciaP2,
                        P3: potenciaP3,
                        P4: potenciaP4,
                        P5: potenciaP5,
                        P6: potenciaP6,
                        PotMaxBie: item.potenciaMaximaBIEW,
                        TipoServicio: "1",
                        Consumo: item.ConsumoAnualTotalActiva.toString(),
                        esViviendaHabitual: item.esViviendaHabitual,
                        fechaCaducidadAPM: item.fechaCaducidadAPM,
                        fechaCaducidadBIE: item.fechaCaducidadBIE,
                        fechaEmisionAPM: item.fechaEmisionAPM,
                        fechaEmisionBIE: item.fechaEmisionBIE,
                        fechaLimiteDerechosReconocidos: item.fechaLimiteDerechosReconocidos,
                        fechaUltimaLectura: item.fechaUltimaLectura,
                        fechaUltimoCambioComercializador: item.fechaUltimoCambioComercializador,
                        fechaUltimoMovimientoContrato: item.fechaUltimoMovimientoContrato,
                        importeDepositoGarantiaEuros: item.importeDepositoGarantiaEuros,
                        informacionImpagos: item.informacionImpagos,
                        codigoAutoconsumo: item.codigoAutoconsumo,
                        descAutoconsumo: item.descAutoconsumo,
                        codigoTelegestion: item.codigoTelegestion,
                        tipoTelegestion: item.tipoTelegestion,
                        proEquipoMedida: item.proEquipoMedida,
                        propiedadICP: item.propiedadICP,
                        relacionTransformacionIntensidad: item.relacionTransformacionIntensidad,
                        tarifaATR: item.tarifaATR,
                        tensionEm: item.tensionEm,
                        tipoContrato: item.tipoContrato,
                        valorDerechosAccesoW: item.valorDerechosAccesoW,
                        valorDerechosExtensionW: item.valorDerechosExtensionW,
                        Tension: item.Tension,
                        accesoContador: item.accesoContador
                    },
                    T_PropuestaComercial: {
                        ImporteTotalEnergia: this.state.comparativeTemp.producto.totalComparacion.importeTotalEnergia.toString(),
                        ImporteTotalPotencia: this.state.comparativeTemp.producto.totalComparacion.importeTotalPotencia.toString(),
                        ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.importeTotal.toString(),
                        Estado: "P", //"P",
                        Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                        Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                        TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                        Tarifa: tarifaATRContrato,
                        IdProducto: this.state.comparativeTemp.producto.producto.id.toString(),
                        Anexo: "",
                        //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                        TipoPrecio: "0", //"0 Fijo 1 Indexado",
                        ObservacionesProducto: "",
                        //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                        GastoOperativo: this.state.comparativeTemp.producto.costeOperativo.toString(), //"0.00 SOLO CUANDO ES CORPORATE",
                        GastoOperativoPotencia: this.state.comparativeTemp.producto.costeOperativoPotencia.toString(),
                        idServicio: "",
                        idComercializadora: this.state.comparativeTemp.producto.producto.idComercializadora,
                        nombreServicio: "",
                        precioServicio: "0.00", //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                        ComercializadoraProcedencia: dataModal.idComercializadora,
                        idComercializadoraProcedencia: dataModal.nombreComercializadora
                    },
                    T_DatosAgenteComercial:{
                        usuario: this.state.userId,
                        nombreUsuario:this.state.nombreAgente,
                        compania: this.state.companyID,
                        nombreCompania:this.state.companyName,
                        correoAgente:this.state.correoAgente,
                        zonaGeografica:""
                    },
                    T_PropuestaComercialDatosIndividual: {
                        precioPotenciaAnualP1: precioPotenciaAnualP1,
                        precioPotenciaAnualP2: precioPotenciaAnualP2,
                        precioPotenciaAnualP3: precioPotenciaAnualP3,
                        precioPotenciaAnualP4: precioPotenciaAnualP4,
                        precioPotenciaAnualP5: precioPotenciaAnualP5,
                        precioPotenciaAnualP6: precioPotenciaAnualP6,
                        precioEnergiaAnualP1: precioEnergiaAnualP1,
                        precioEnergiaAnualP2: precioEnergiaAnualP2,
                        precioEnergiaAnualP3: precioEnergiaAnualP3,
                        precioEnergiaAnualP4: precioEnergiaAnualP4,
                        precioEnergiaAnualP5: precioEnergiaAnualP5,
                        precioEnergiaAnualP6: precioEnergiaAnualP6,
                        consumoPotenciaAnualP1: consumoPotenciaAnualP1,
                        consumoPotenciaAnualP2: consumoPotenciaAnualP2,
                        consumoPotenciaAnualP3: consumoPotenciaAnualP3,
                        consumoPotenciaAnualP4: consumoPotenciaAnualP4,
                        consumoPotenciaAnualP5: consumoPotenciaAnualP5,
                        consumoPotenciaAnualP6: consumoPotenciaAnualP6,
                        consumoEnergiaActivaAnualP1: consumoEnergiaActivaAnualP1,
                        consumoEnergiaActivaAnualP2: consumoEnergiaActivaAnualP2,
                        consumoEnergiaActivaAnualP3: consumoEnergiaActivaAnualP3,
                        consumoEnergiaActivaAnualP4: consumoEnergiaActivaAnualP4,
                        consumoEnergiaActivaAnualP5: consumoEnergiaActivaAnualP5,
                        consumoEnergiaActivaAnualP6: consumoEnergiaActivaAnualP6,
                        costePotenciaAnualP1: costePotenciaAnualP1,
                        costePotenciaAnualP2: costePotenciaAnualP2,
                        costePotenciaAnualP3: costePotenciaAnualP3,
                        costePotenciaAnualP4: costePotenciaAnualP4,
                        costePotenciaAnualP5: costePotenciaAnualP5,
                        costePotenciaAnualP6: costePotenciaAnualP6,
                        costeEnergiaActivaAnualP1: costeEnergiaActivaAnualP1,
                        costeEnergiaActivaAnualP2: costeEnergiaActivaAnualP2,
                        costeEnergiaActivaAnualP3: costeEnergiaActivaAnualP3,
                        costeEnergiaActivaAnualP4: costeEnergiaActivaAnualP4,
                        costeEnergiaActivaAnualP5: costeEnergiaActivaAnualP5,
                        costeEnergiaActivaAnualP6: costeEnergiaActivaAnualP6,
                        costeTotalPropuestaPotencia: costeTotalPropuestaPotencia,
                        costeTotalPropuestaEnergia: costeTotalPropuestaEnergia,
                        costeTotalPropuesta: costeTotalPropuesta
                    },
                    T_Propuesta: {
                        producto:{
                            precioTP1: precioPotenciaAnualP1,
                            precioTP2: precioPotenciaAnualP2,
                            precioTP3: precioPotenciaAnualP3,
                            precioTP4: precioPotenciaAnualP4,
                            precioTP5: precioPotenciaAnualP5,
                            precioTP6: precioPotenciaAnualP6,
                            precioTEP1: precioEnergiaAnualP1,
                            precioTEP2: precioEnergiaAnualP2,
                            precioTEP3: precioEnergiaAnualP3,
                            precioTEP4: precioEnergiaAnualP4,
                            precioTEP5: precioEnergiaAnualP5,
                            precioTEP6: precioEnergiaAnualP6,
                            consumoPotenciaAnualP1: consumoPotenciaAnualP1,
                            consumoPotenciaAnualP2: consumoPotenciaAnualP2,
                            consumoPotenciaAnualP3: consumoPotenciaAnualP3,
                            consumoPotenciaAnualP4: consumoPotenciaAnualP4,
                            consumoPotenciaAnualP5: consumoPotenciaAnualP5,
                            consumoPotenciaAnualP6: consumoPotenciaAnualP6,
                            consumoEnergiaActivaAnualP1: consumoEnergiaActivaAnualP1,
                            consumoEnergiaActivaAnualP2: consumoEnergiaActivaAnualP2,
                            consumoEnergiaActivaAnualP3: consumoEnergiaActivaAnualP3,
                            consumoEnergiaActivaAnualP4: consumoEnergiaActivaAnualP4,
                            consumoEnergiaActivaAnualP5: consumoEnergiaActivaAnualP5,
                            consumoEnergiaActivaAnualP6: consumoEnergiaActivaAnualP6,
                            costePotenciaAnualP1: costePotenciaAnualP1,
                            costePotenciaAnualP2: costePotenciaAnualP2,
                            costePotenciaAnualP3: costePotenciaAnualP3,
                            costePotenciaAnualP4: costePotenciaAnualP4,
                            costePotenciaAnualP5: costePotenciaAnualP5,
                            costePotenciaAnualP6: costePotenciaAnualP6,
                            costeEnergiaActivaAnualP1: costeEnergiaActivaAnualP1,
                            costeEnergiaActivaAnualP2: costeEnergiaActivaAnualP2,
                            costeEnergiaActivaAnualP3: costeEnergiaActivaAnualP3,
                            costeEnergiaActivaAnualP4: costeEnergiaActivaAnualP4,
                            costeEnergiaActivaAnualP5: costeEnergiaActivaAnualP5,
                            costeEnergiaActivaAnualP6: costeEnergiaActivaAnualP6,
                            costeTotalPropuestaPotencia: costeTotalPropuestaPotencia,
                            costeTotalPropuestaEnergia: costeTotalPropuestaEnergia,
                            costeTotalPropuesta: costeTotalPropuesta
                        }
                    },
                    T_Seguimiento:[{
                        usuario: this.state.userId,
                        nombreUsuario:this.state.nombreAgente,
                        compania: this.state.companyID,
                        fecha:date,
                        hora: hour,
                        mes:monthPropuesta,
                        anio:yearPropuesta,
                        accion: "ALTA",
                        idAccion: "01",
                        observacion: "ALTA EN EL MODULO MULTIPUNTO"
                    }]
                }

            } else {

                var datosContratoP = {
                    Usuario: usuario.toString(),
                    compania: this.state.companyID,
                    fechaPropuesta:date,
                    horaPropuesta: hour,
                    mesPropuesta:monthPropuesta,
                    anioPropuesta:yearPropuesta,
                    fechaAprobacion:dateAprobacion,
                    horaAprobacion:hourAprobacion,
                    mesAprobacion:monthAprobacion,
                    anioAprobacion:yearAprobacion,
                    esAprobada: true,
                    fechaDenegacion: "",
                    horaDenegacion: "",
                    mesDenegacion: 0,
                    anioDenegacion: 0,
                    esDenegada: false,
                    IdOferta: idOferta,
                    IdOfertaInterna: "",
                    cups:  item.cups,
                    tipoCliente: dataModal.TipoCliente,
                    tipoEnergia: "ELECTRICIDAD",
                    estado: "APROBADA",
                    estadoId: "50",
                    OfertaTipo: "MP",
                    ofertaTipoId: i,
                    T_Titular: {
                        NombreTitular: item.nombreCompletoTitular.toUpperCase(),
                        Apellido1Titular: dataModal.apellido1Titular.toUpperCase(),
                        Apellido2Titular: dataModal.apellido2Titular.toUpperCase(),
                        NIF: item.CIF, //this.state.ubicacion.CIF,
                        TipoTitular: TipoID,
                        TipoViaTitular: dataModal.tipoViaTitular.toUpperCase(),
                        ViaTitular: dataModal.viaTitular.toUpperCase(),
                        NumFincaTitular: dataModal.numFincaTitular.toUpperCase(),
                        PortalTitular: dataModal.portalTitular.toUpperCase(),
                        EscaleraTitular: dataModal.escaleraTitular.toUpperCase(),
                        PisoTitular: dataModal.pisoTitular.toUpperCase(),
                        PuertaTitular: dataModal.puertaTitular.toUpperCase(),
                        CodigoPostalTitular: codigoPostal,
                        AutonomiaTitular: dataModal.desAutonomiaTitular.toUpperCase(),
                        ProvinciaTitular: dataModal.desProvinciaTitular.toUpperCase(),
                        MunicipioTitular: dataModal.desMunicipioTitular.toUpperCase(),
                        TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                        TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                        Email: dataModal.correoContacto, //this.state.ubicacion.email_contacto,
                        TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                        ObservacionesCliente: dataModal.ObservacionesCliente,
                        GDPR: dataModal.GDPR,
                        IBAN: dataModal.iban, //
                        CNAE: item.CNAE,
                        actividadCNAE: item.actividadCNAE,
                    },
                    T_Representante: {
                        nombreRepresentante: dataModal.nombreRepresentante.toUpperCase(),
                        apellido1Representante: dataModal.apellido1Representante.toUpperCase(),
                        apellido2Representante: dataModal.apellido2Representante.toUpperCase(),
                        NIFRepresentante: dataModal.NIFRepresentante.toUpperCase(),
                        TipoTitularRepresentante: dataModal.tipoTitularRepresentante.toUpperCase(),
                        idRepresentante: dataModal.idRepresentante,
                        idRepresentanteInterno: dataModal.idRepresentanteInterno,
                        CargoRepresentante: dataModal.CargoRepresentante.toUpperCase(),
                        telefonoRepresentante: dataModal.telefonoRepresentante,
                        correoRepresentante: dataModal.correoRepresentante,
                    },
                    T_PuntoSuministro: { //Pendiente
                        //Direccion: item.direccion_ps, //this.state.ubicacion.direccion_ps,
                        //CodigoPostal: codigoPostalPS, //this.state.ubicacion.codigo_postal_ps,
                        //Telefono: item.TelefonoPS, //this.state.ubicacion.telefono,
                        //ObservacionesPuntoSuministro: ""
                        TipoViaPS: dataModal.tipoViaPS.toUpperCase(),
                        ViaPS: item.direccion_ps.toUpperCase(),
                        NumFincaPS: dataModal.numFincaPS.toUpperCase(),
                        PortalPS: dataModal.portalPS.toUpperCase(),
                        EscaleraPS: dataModal.escaleraPS.toUpperCase(),
                        PisoPS: dataModal.pisoPS.toUpperCase(),
                        PuertaPS: dataModal.puertaPS.toUpperCase(),
                        CodigoPostalPS: codigoPostalPS.toUpperCase(),
                        NombreMunicipioPS: dataModal.nombreMunicipioPS.toUpperCase(),
                        codigoMunicipioPS: sip.codigoMunicipioPS,
                        NombreProvinciaPS: dataModal.nombreProvinciaPS.toUpperCase(),
                        codigoProvinciaPS: sip.codigoProvinciaPS,
                        AutonomiaPS: dataModal.desAutonomiaPS.toUpperCase(),
                        codigoAutonomiaPS:sip.codigoAutonomiaPS,
                        TelefonoPS: dataModal.TelefonoPS,
                        ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro.toUpperCase()
                    },

                    T_CUPs: {
                        Distribuidora: item.distribuidora,
                        CUPsEle: item.cups,
                        Tarifa: tarifaATRContrato,
                        P1: potenciaP1,
                        P2: potenciaP2,
                        P3: potenciaP3,
                        P4: potenciaP4,
                        P5: potenciaP5,
                        P6: potenciaP6,
                        PotMaxBie: item.potenciaMaximaBIEW,
                        TipoServicio: "1",
                        Consumo: item.ConsumoAnualTotalActiva.toString(),
                        esViviendaHabitual: item.esViviendaHabitual,
                        fechaCaducidadAPM: item.fechaCaducidadAPM,
                        fechaCaducidadBIE: item.fechaCaducidadBIE,
                        fechaEmisionAPM: item.fechaEmisionAPM,
                        fechaEmisionBIE: item.fechaEmisionBIE,
                        fechaLimiteDerechosReconocidos: item.fechaLimiteDerechosReconocidos,
                        fechaUltimaLectura: item.fechaUltimaLectura,
                        fechaUltimoCambioComercializador: item.fechaUltimoCambioComercializador,
                        fechaUltimoMovimientoContrato: item.fechaUltimoMovimientoContrato,
                        importeDepositoGarantiaEuros: item.importeDepositoGarantiaEuros,
                        informacionImpagos: item.informacionImpagos,
                        codigoAutoconsumo: item.codigoAutoconsumo,
                        descAutoconsumo: item.descAutoconsumo,
                        codigoTelegestion: item.codigoTelegestion,
                        tipoTelegestion: item.tipoTelegestion,
                        proEquipoMedida: item.proEquipoMedida,
                        propiedadICP: item.propiedadICP,
                        relacionTransformacionIntensidad: item.relacionTransformacionIntensidad,
                        tarifaATR: item.tarifaATR,
                        tensionEm: item.tensionEm,
                        tipoContrato: item.tipoContrato,
                        valorDerechosAccesoW: item.valorDerechosAccesoW,
                        valorDerechosExtensionW: item.valorDerechosExtensionW,
                        Tension: item.Tension,
                        accesoContador: item.accesoContador
                    },
                    T_PropuestaComercial: {
                        ImporteTotalEnergia: this.state.comparativeTemp.producto.totalComparacion.importeTotalEnergia.toString(),
                        ImporteTotalPotencia: this.state.comparativeTemp.producto.totalComparacion.importeTotalPotencia.toString(),
                        ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.importeTotal.toString(),
                        Estado: "P", //"P",
                        Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                        Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                        TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                        Tarifa: tarifaATRContrato,
                        IdProducto: this.state.comparativeTemp.producto.producto.id.toString(),
                        Anexo: "",
                        //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                        TipoPrecio: "0", //"0 Fijo 1 Indexado",
                        ObservacionesProducto: "",
                        //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                        GastoOperativo: this.state.comparativeTemp.producto.costeOperativo.toString(), //"0.00 SOLO CUANDO ES CORPORATE",
                        GastoOperativoPotencia: this.state.comparativeTemp.producto.costeOperativoPotencia.toString(),
                        idServicio: "",
                        idComercializadora: this.state.comparativeTemp.producto.producto.idComercializadora,
                        nombreServicio: "",
                        precioServicio: "0.00", //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                        ComercializadoraProcedencia: dataModal.idComercializadora,
                        idComercializadoraProcedencia: dataModal.nombreComercializadora
                    },
                    T_DatosAgenteComercial:{
                        usuario: this.state.userId,
                        nombreUsuario:this.state.nombreAgente,
                        compania: this.state.companyID,
                        nombreCompania:this.state.companyName,
                        correoAgente:this.state.correoAgente,
                        zonaGeografica:""
                    },
                    T_PropuestaComercialDatosIndividual: {
                        precioPotenciaAnualP1: precioPotenciaAnualP1,
                        precioPotenciaAnualP2: precioPotenciaAnualP2,
                        precioPotenciaAnualP3: precioPotenciaAnualP3,
                        precioPotenciaAnualP4: precioPotenciaAnualP4,
                        precioPotenciaAnualP5: precioPotenciaAnualP5,
                        precioPotenciaAnualP6: precioPotenciaAnualP6,
                        precioEnergiaAnualP1: precioEnergiaAnualP1,
                        precioEnergiaAnualP2: precioEnergiaAnualP2,
                        precioEnergiaAnualP3: precioEnergiaAnualP3,
                        precioEnergiaAnualP4: precioEnergiaAnualP4,
                        precioEnergiaAnualP5: precioEnergiaAnualP5,
                        precioEnergiaAnualP6: precioEnergiaAnualP6,
                        consumoPotenciaAnualP1: consumoPotenciaAnualP1,
                        consumoPotenciaAnualP2: consumoPotenciaAnualP2,
                        consumoPotenciaAnualP3: consumoPotenciaAnualP3,
                        consumoPotenciaAnualP4: consumoPotenciaAnualP4,
                        consumoPotenciaAnualP5: consumoPotenciaAnualP5,
                        consumoPotenciaAnualP6: consumoPotenciaAnualP6,
                        consumoEnergiaActivaAnualP1: consumoEnergiaActivaAnualP1,
                        consumoEnergiaActivaAnualP2: consumoEnergiaActivaAnualP2,
                        consumoEnergiaActivaAnualP3: consumoEnergiaActivaAnualP3,
                        consumoEnergiaActivaAnualP4: consumoEnergiaActivaAnualP4,
                        consumoEnergiaActivaAnualP5: consumoEnergiaActivaAnualP5,
                        consumoEnergiaActivaAnualP6: consumoEnergiaActivaAnualP6,
                        costePotenciaAnualP1: costePotenciaAnualP1,
                        costePotenciaAnualP2: costePotenciaAnualP2,
                        costePotenciaAnualP3: costePotenciaAnualP3,
                        costePotenciaAnualP4: costePotenciaAnualP4,
                        costePotenciaAnualP5: costePotenciaAnualP5,
                        costePotenciaAnualP6: costePotenciaAnualP6,
                        costeEnergiaActivaAnualP1: costeEnergiaActivaAnualP1,
                        costeEnergiaActivaAnualP2: costeEnergiaActivaAnualP2,
                        costeEnergiaActivaAnualP3: costeEnergiaActivaAnualP3,
                        costeEnergiaActivaAnualP4: costeEnergiaActivaAnualP4,
                        costeEnergiaActivaAnualP5: costeEnergiaActivaAnualP5,
                        costeEnergiaActivaAnualP6: costeEnergiaActivaAnualP6,
                        costeTotalPropuestaPotencia: costeTotalPropuestaPotencia,
                        costeTotalPropuestaEnergia: costeTotalPropuestaEnergia,
                        costeTotalPropuesta: costeTotalPropuesta
                    },
                    T_Propuesta: {
                        producto:{
                            precioTP1: precioPotenciaAnualP1,
                            precioTP2: precioPotenciaAnualP2,
                            precioTP3: precioPotenciaAnualP3,
                            precioTP4: precioPotenciaAnualP4,
                            precioTP5: precioPotenciaAnualP5,
                            precioTP6: precioPotenciaAnualP6,
                            precioTEP1: precioEnergiaAnualP1,
                            precioTEP2: precioEnergiaAnualP2,
                            precioTEP3: precioEnergiaAnualP3,
                            precioTEP4: precioEnergiaAnualP4,
                            precioTEP5: precioEnergiaAnualP5,
                            precioTEP6: precioEnergiaAnualP6,
                            consumoPotenciaAnualP1: consumoPotenciaAnualP1,
                            consumoPotenciaAnualP2: consumoPotenciaAnualP2,
                            consumoPotenciaAnualP3: consumoPotenciaAnualP3,
                            consumoPotenciaAnualP4: consumoPotenciaAnualP4,
                            consumoPotenciaAnualP5: consumoPotenciaAnualP5,
                            consumoPotenciaAnualP6: consumoPotenciaAnualP6,
                            consumoEnergiaActivaAnualP1: consumoEnergiaActivaAnualP1,
                            consumoEnergiaActivaAnualP2: consumoEnergiaActivaAnualP2,
                            consumoEnergiaActivaAnualP3: consumoEnergiaActivaAnualP3,
                            consumoEnergiaActivaAnualP4: consumoEnergiaActivaAnualP4,
                            consumoEnergiaActivaAnualP5: consumoEnergiaActivaAnualP5,
                            consumoEnergiaActivaAnualP6: consumoEnergiaActivaAnualP6,
                            costePotenciaAnualP1: costePotenciaAnualP1,
                            costePotenciaAnualP2: costePotenciaAnualP2,
                            costePotenciaAnualP3: costePotenciaAnualP3,
                            costePotenciaAnualP4: costePotenciaAnualP4,
                            costePotenciaAnualP5: costePotenciaAnualP5,
                            costePotenciaAnualP6: costePotenciaAnualP6,
                            costeEnergiaActivaAnualP1: costeEnergiaActivaAnualP1,
                            costeEnergiaActivaAnualP2: costeEnergiaActivaAnualP2,
                            costeEnergiaActivaAnualP3: costeEnergiaActivaAnualP3,
                            costeEnergiaActivaAnualP4: costeEnergiaActivaAnualP4,
                            costeEnergiaActivaAnualP5: costeEnergiaActivaAnualP5,
                            costeEnergiaActivaAnualP6: costeEnergiaActivaAnualP6,
                            costeTotalPropuestaPotencia: costeTotalPropuestaPotencia,
                            costeTotalPropuestaEnergia: costeTotalPropuestaEnergia,
                            costeTotalPropuesta: costeTotalPropuesta
                        }
                    },
                    T_Seguimiento:[{
                        usuario: this.state.userId,
                        nombreUsuario:this.state.nombreAgente,
                        compania: this.state.companyID,
                        fecha:date,
                        hora: hour,
                        mes:monthPropuesta,
                        anio:yearPropuesta,
                        accion: "ALTA",
                        idAccion: "01",
                        observacion: ""
                    },{
                        usuario: this.state.userId,
                        nombreUsuario:this.state.nombreAgente,
                        compania: this.state.companyID,
                        fecha:dateAprobacion,
                        hora:hourAprobacion,
                        mes:monthAprobacion,
                        anio:yearAprobacion,
                        accion: "APROBADA",
                        idAccion: "50",
                        observacion: "ALTA Y APROBACION REALIZADA EN EL MODULO MULTIPUNTO"}]
                }

            }


            console.log('VALOR DE datosContratoP ----------------->', datosContratoP)
            console.log('VALOR DE sip -------> ', sip)
            console.log('VALOR DE item ------>', item)
            console.log('VALOR DE DATA MODAL ------>', dataModal)


            const datosContratoJSON: string = JSON.stringify(datosContratoP).toString();

            var data: any = []
            if (dataModal.esAprobada === false) {

                data = {
                    ...{
                        // ...this.dataToSave,
                        companyId: Number(compañia),
                        nombreOferta: "",
                        idComercializadora: dataModal.idComercializadora,
                        nombreComercializadora: dataModal.nombreComercializadora,
                        idComercializadoraActual: item.codigoComercializadora,
                        nombreComercializadoraActual: item.comercializadora,
                        // datosPreciosActual: actual,
                        userId: Number(usuario),
                        fechaPropuesta: moment
                            .parseZone(now.split("T")[0])
                            .format("YYYY-MM-DD"),
                        codigoPostalPS: item.codigoPostalPS,
                        codigoMunicipioPS: item.codigoMunicipioPS,
                        nombreMunicipioPS: item.nombreMunicipioPS,
                        codigoProvinciaPS: item.codigoProvinciaPS,
                        nombreProvinciaPS: item.nombreProvinciaPS,
                        codigoAutonomiaPS: item.codigoAutonomiaPS,
                        nombreAutonomiaPS: item.nombreAutonomiaPS,
                        DireccionPS: item.direccion_ps,
                        esAprobada: false,
                        datosPropuesta: producto,
                        correoContacto: dataModal.correoContacto,
                        TelefonoFijo: dataModal.TelefonoFijo,
                        TelefonMovil: dataModal.TelefonMovil,
                        Email: dataModal.Email,
                        cups: item.cups, //PASAR EL CUPS QUE TOQUE
                        tipo: "L",
                        datosContrato: datosContratoJSON,
                        tipoViaPS: "",
                        viaPS: "",
                        numFincaPS: "",
                        portalPS: "",
                        escaleraPS: "",
                        pisoPS: "",
                        puertaPS: ""

                    },
                    ...modalData,
                };
            } else {
                data = {
                    ...{
                        // ...this.dataToSave,
                        companyId: Number(compañia),
                        nombreOferta: "",
                        idComercializadora: dataModal.idComercializadora,
                        nombreComercializadora: dataModal.nombreComercializadora,
                        idComercializadoraActual: item.codigoComercializadora,
                        nombreComercializadoraActual: item.comercializadora,
                        // datosPreciosActual: actual,
                        userId: Number(usuario),
                        fechaPropuesta: moment
                            .parseZone(now.split("T")[0])
                            .format("YYYY-MM-DD"),
                        codigoPostalPS: item.codigoPostalPS,
                        codigoMunicipioPS: item.codigoMunicipioPS,
                        nombreMunicipioPS: item.nombreMunicipioPS,
                        codigoProvinciaPS: item.codigoProvinciaPS,
                        nombreProvinciaPS: item.nombreProvinciaPS,
                        codigoAutonomiaPS: item.codigoAutonomiaPS,
                        nombreAutonomiaPS: item.nombreAutonomiaPS,
                        DireccionPS: item.direccion_ps,
                        esAprobada: true,
                        datosPropuesta: producto,
                        correoContacto: dataModal.correoContacto,
                        TelefonoFijo: dataModal.TelefonoFijo,
                        TelefonMovil: dataModal.TelefonMovil,
                        Email: dataModal.Email,
                        cups: item.cups, //PASAR EL CUPS QUE TOQUE
                        tipo: "L",
                        datosContrato: datosContratoJSON,
                        tipoViaPS: "",
                        viaPS: "",
                        numFincaPS: "",
                        portalPS: "",
                        escaleraPS: "",
                        pisoPS: "",
                        puertaPS: ""

                    },
                    ...modalData,
                };

            }

            console.log('THIs DATA TO SAVE ANTES DEL SAVE');
            console.log(data);
            console.log('THIS STATE ANTES DEL SAVE');
            console.log(this.state)

            await this.props.client
                .mutate({
                    mutation: CREATE_SIPS_COMPARATIVAS_LIBRE_MP,
                    variables: { data: data },
                })
                .then((result) => {
                    console.log("guardado", result);
                    // this.clearInvoices();
                    saveMultiOk.push(result.data.createComparativaLibreMP)
                    if (i === this.state.selectedRows.length - 1) {
                        //notify();
                        //            alert("Ofertas guardadas correctamente.")
                    };
                    //this.fetchComparatives(item.cups); //PASAR EL CUPS QUE TOQUE
                }).catch((err) => {
                    console.log('ERROR')
                })
                .then(() => {
                    this.forceUpdate();
                });


                const companyIdAux = this.state.companyID.toString();
                const userIdAux = this.state.userId.toString();
                //const idContrato = this.state.contrato;


                const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar',
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
                };
                let direccionFile = "https://datapi.psgestion.es/propuesta/create?id="+companyIdAux+"&user="+userIdAux;


                await axios.post(direccionFile, datosContratoP, { headers, responseType: 'json'})
                     .then (res=> {
                                console.log('Actualización correcta', res)
                                console.log('Datos del registro: ', res.data)
                                //notify();
                                //this.clearForm();
                     }).catch (error =>{
                                console.log ('Failure')
                                console.log('Error en el proceso: ', error)
                                //notifyErrorDB();
                     });




        };
        this.successAlert()

        console.log('VALORES DEL TOTAL DE GRABACION ------->', saveMultiOk)
        // AQUI INCIALIZAMOS LOS VALORES DE SELECT ROW y otros.....


        this.setState({
            titular: {
                data: {
                    nombre_completo_titular: '',
                    CIF: '',
                    direccion_titular: '',
                    codigo_postal_titular: '',
                    telefono_fijo: '',
                    telefono: '',
                    email_contacto: '',
                    tipo_cliente: '',
                    observaciones: '',
                },
            }
        });


        this.setState({
            producto: {
                active: true,
                productoSeleccionado: [],
                costeOperativo: 0,
                costeOperativoPotencia: 0,
            },
            productoVisual: { productoSeleccionado: [] },
            showList: false,
            saveButtonDisabled: true,
            productSelected: "",
            toggledClearRows: !this.state.toggledClearRows,
            toggledClearRowsPS: !this.state.toggledClearRowsPS
        });
        this.forceUpdate();

    };



    openComparativeSection = async (event) => {

        this.setState({
            producto: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia
            },
            productoVisual: { productoSeleccionado: [event.productoSeleccionado] },
            showList: false,
            saveButtonDisabled: false,
            productSelected: event.productoSeleccionado.nombreOferta,
            codigoATR: event.productoSeleccionado.tarifaATR,
            productId: event.productoSeleccionado.id,
            comercializadoraId: event.productoSeleccionado.idComercializadora,
            nombreComercializadora: event.productoSeleccionado.nombreComercializadora

        });
        //console.log(this.state)
    };

    selectedItems = async (event) => {
        let items: any[] = [];
        event.map((id) => {
            items.push(
                this.state.paginatedData.items.map(item => item['cups']).filter((item) => item === id),
            );
        });
        this.setState({
            itemsSelected: items,
        });


    };

    toggle = () => {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    }

    toggleModal = (event, state) => {

        event.preventDefault();

        this.setState({
            [state]: !this.state[state],
        });

    };

    showModal = () => {
        this.setState({
            openModalComparative: true,
        });
    };

    fetchComparatives = async (cups) => {
        await this.props.client
            .query({
                query: LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
                fetchPolicy: "no-cache",
                variables: { cups: cups }, //
            })
            .then((result) => {
                let data = result.data.comparativasLibresByCups;
                console.log("Comparativas cargadas :::>", data);
                this.setState({ comparatives: data });
            });
    };

    setNumPeriods = () => {
        let threePeriods = ['001', '004', '005', '006', '007', '008', '018'];
        let fourPeriods = ['003']
        if (threePeriods.includes(this.state.paginatedData.items[0]['codigoTarifaATREnVigor'])) {
            this.setState({
                numPeriods: '3',
                numnumPeriodsPotencia: '2',
            });
        } else if (fourPeriods.includes(this.state.paginatedData.items[0]['codigoTarifaATREnVigor'])) {
            this.setState({
                numPeriods: '4',
                numnumPeriodsPotencia: '6',
            });
        } else {
            this.setState({
                numPeriods: '6',
                numnumPeriodsPotencia: '6',
            });
        }

        return 3;
    };


    openComparativeMultiSection = async (event) => {
        this.setState({
            productoMulti: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia
            },
            showList: false,
        });
        //console.log("comparativeMultiSection", this.state)
    };


    onChangeField = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        let inputs = {};

        if (value !== "" || value !== null) {
            inputs[name] = value;
            this.setState({
                form: {
                    ...this.state.form,
                    ...inputs
                }
            });
        }
    }

    onPageClick = (page) => {
        this.setState({ page }, () => {
            this.fetchItems(this.lastFiltersMulti);
            //  this.fetchTitular();

        });
    };

    beforeRenderItem = (index, item) => {
        if (index.includes("fecha") && item.value) {
            const dateValue = new Date(item.value);
            item.value = dateValue.toISOString().split("T")[0];
        }
        return true;
    };


    getMostFrequent = (arr) => {
        const hashmap = arr.reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1
            return acc
        }, {})
        return Object.keys(hashmap).reduce((a, b) => hashmap[a] > hashmap[b] ? a : b)
    }

    changeSave = (e) => {
        let s = true;
        if (e.target.value.length && this.state.enabledSave == true) {
            s = false;
            this.setState({ nombrePropuesta: e.target.value })
        } else {
            alert("Recuerde seleccionar un producto para guardar la propuesta comercial");
        }
        this.setState({ isDisabled: s });
    }

    handleChange = state => {
        // eslint-disable-next-line no-console

        var codigoATRList = ""
        var codigoATRListDescripcion = ""
        var resultadoHash = ""
        var resultadoHashDescripcion = ""
        var periodos: any = []
        if (state.selectedCount >= 1) {
            codigoATRList = state.selectedRows.map((item) => item.codigoTarifaATREnVigor);
            codigoATRListDescripcion = state.selectedRows.map((item) => item.tarifaATR) || ["0"];

            resultadoHash = this.getMostFrequent(codigoATRList)
            resultadoHashDescripcion = this.getMostFrequent(codigoATRListDescripcion)

            if (resultadoHash == '018') {
                periodos = [1, 2, 3]
            } else {
                periodos = [1, 2, 3, 4, 5, 6]
            }
        }


        if (state.selectedCount >= 1) {
            const sumOfEnergy2 = state.selectedRows.reduce((sum, currentValue) => {
                return sum + Number(currentValue.ConsumoAnualTotalActiva);
            }, 0);
        }
        this.setState({ selectedRows: state.selectedRows });
        //console.log("select",state.selectedRows)

        if (state.selectedCount > 1) {
            this.setState({ selectProductButtonDisabled: false })
        } else {
            this.setState({ selectProductButtonDisabled: true })
        }

        if (state.selectedCount >= 1) {
            const sumOfEnergy = state.selectedRows.reduce((sum, currentValue) => {
                return sum + Number(currentValue.ConsumoAnualTotalActiva);
            }, 0);


            const sumOfEnergyLast = state.selectedRows.reduce((sum, currentValue) => {
                return sum + Number(currentValue.ConsumoAnualTotalActivaAnterior);
            }, 0);


            const groupBy = (array, key) => {
                return array.reduce((result, currentValue) => {
                    (result[currentValue.distribuidora] = result[currentValue.distribuidora] || []).push(
                        currentValue
                    );
                    return result;
                }, {});
            };

            const groupByComer = (array, key) => {
                return array.reduce((result, currentValue) => {
                    (result[currentValue.comercializadora] = result[currentValue.comercializadora] || []).push(
                        currentValue
                    );
                    return result;
                }, {});
            };

            const groupByATR = (array, key) => {
                return array.reduce((result, currentValue) => {
                    (result[currentValue.tarifaATR] = result[currentValue.tarifaATR] || []).push(
                        currentValue
                    );
                    return result;
                }, {});
            };


            const cupsGroupedBydistri = groupBy(state.selectedRows, "distribuidora");
            const cupsGroupedByComer = groupByComer(state.selectedRows, "comercializadora");
            const cupsGroupedByATR = groupByATR(state.selectedRows, "tarifaATR");

            var EnergiaTotalDistribuidora: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.distribuidora]) {
                    res[value.distribuidora] = { Id: value.distribuidora, TotalConsumoAnual: 0 };
                    EnergiaTotalDistribuidora.push(res[value.distribuidora])
                }
                res[value.distribuidora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});

            var EnergiaTotalATR: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.tarifaATR]) {
                    res[value.tarifaATR] = { Id: value.tarifaATR, TotalConsumoAnual: 0 };
                    EnergiaTotalATR.push(res[value.tarifaATR])
                }
                res[value.tarifaATR].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});




            var EnergiaTotalComercial: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.comercializadora]) {
                    res[value.comercializadora] = { Id: value.comercializadora, TotalConsumoAnual: 0 };
                    EnergiaTotalComercial.push(res[value.comercializadora])
                }
                res[value.comercializadora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});


            var EnergiaTotalComercialCont: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.comercializadora]) {
                    res[value.comercializadora] = { Id: value.comercializadora, TotalContador: 0, TotalConsumoAnual: 0 };
                    EnergiaTotalComercialCont.push(res[value.comercializadora])
                }
                res[value.comercializadora].TotalContador += 1;
                res[value.comercializadora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});




            var EnergiaTotalATR: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.tarifaATR]) {
                    res[value.tarifaATR] = { Id: value.tarifaATR, TotalConsumoAnual: 0 };
                    EnergiaTotalATR.push(res[value.tarifaATR])
                }
                res[value.tarifaATR].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});

            var EnergiaTotalATRCont: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.tarifaATR]) {
                    res[value.tarifaATR] = { Id: value.tarifaATR, TotalContador: 0, TotalConsumoAnual: 0 };
                    EnergiaTotalATRCont.push(res[value.tarifaATR])
                }
                res[value.tarifaATR].TotalContador += 1;
                res[value.tarifaATR].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});


            var EnergiaTotalProvincia: string[] = [];
            state.selectedRows.reduce(function(res, value) {
                if (!res[value.nombreProvinciaPS]) {
                    res[value.nombreProvinciaPS] = { Id: value.nombreProvinciaPS, TotalContador: 0, TotalConsumoAnual: 0 };
                    EnergiaTotalProvincia.push(res[value.nombreProvinciaPS])
                }
                res[value.nombreProvinciaPS].TotalContador += 1;
                res[value.nombreProvinciaPS].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
                return res;
            }, {});

            var fixedArray = EnergiaTotalComercial.map(function(item) {

                if (item['Id'] === null) {
                    item['Id'] = "SIN DATOS";
                }
                return item;
            });


            var fixedArrayCont = EnergiaTotalComercialCont.map(function(item) {

                if (item['Id'] === null) {
                    item['Id'] = "SIN DATOS";
                }
                return item;
            });


            var fixedArrayProvincia = EnergiaTotalProvincia.map(function(item) {

                if (item['Id'] === null) {
                    item['Id'] = "SIN DATOS";
                }
                return item;
            });

            const cupsGroupedBydistriEnergy = EnergiaTotalDistribuidora
            const cupsGroupedByATREnergy = EnergiaTotalATR
            const cupsGroupedByComercialEnergy = EnergiaTotalComercial
            const cupsGroupedByComercialEnergyCont = EnergiaTotalComercialCont
            const cupsGroupedByProvincia = EnergiaTotalProvincia



            var periodosCupStringTemp = 0;
            var periodosCupStringActual = 0;
            if (state.selectedCount === 1) {
                if (['001', '004', '005', '006', '007', '008', '018'].indexOf(state.selectedRows[0].codigoTarifaATREnVigor) >= 0) {
                    periodosCupStringTemp = 3;
                } else if (state.selectedRows[0].codigoTarifaATREnVigor === '003' || state.selectedRows[0].codigoTarifaATREnVigor === '019') {
                    periodosCupStringTemp = 4;
                } else {
                    periodosCupStringTemp = 6;


                }

            } else if (state.selectedCount > 1) {
                if (['001', '004', '005', '006', '007', '008', '018'].indexOf(state.selectedRows[0].codigoTarifaATREnVigor) >= 0) {
                    periodosCupStringActual = 3;
                } else if (state.selectedRows[0].codigoTarifaATREnVigor === '003' || state.selectedRows[0].codigoTarifaATREnVigor === '019') {
                    periodosCupStringActual = 4;
                } else {
                    periodosCupStringActual = 6;
                }

            }

            if (state.selectedCount === 1) {

                console.log('Entra en warning Alert ---> ', this.state)
                console.log('VALOR DE state.selectedCount ', state.selectedCount)
                console.log('VALOR DE STATE --->', state)

                this.setState({
                    sumOfEnergy: sumOfEnergy,
                    sumOfEnergyLast: sumOfEnergyLast,
                    cupsGroupedBydistri: cupsGroupedBydistri,
                    cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
                    cupsGroupedByATREnergy: cupsGroupedByATREnergy,
                    cupsGroupedByComer: cupsGroupedByComer,
                    cupsGroupedByATR: cupsGroupedByATR,
                    cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
                    EnergiaTotalATRCont: EnergiaTotalATRCont,
                    cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
                    cupsGroupedByProvincia: cupsGroupedByProvincia,
                    periodosCupStringTemp: periodosCupStringTemp,
                    tarifaATR: resultadoHash,
                    tarifaATRDescripcion: resultadoHashDescripcion,
                    periodos: periodos
                });

                this.setState(
                    ({ periodosCupStringTemp }) => ({
                        periodosCupStringTemp: periodosCupStringTemp
                    }), () => {
                        this.setState(({ periodosCupStringTemp }) => ({
                            periodosCupStringTemp: periodosCupStringTemp
                        }))
                    })

            } else if (state.selectedCount > 1 && (this.state.periodosCupStringTemp === periodosCupStringActual)) {

                console.log('Entra en warning Alert ---> ', this.state)
                console.log('VALOR DE state.selectedCount ', state.selectedCount)
                console.log('VALOR DE STATE --->', state)

                this.setState({
                    sumOfEnergy: sumOfEnergy,
                    sumOfEnergyLast: sumOfEnergyLast,
                    cupsGroupedBydistri: cupsGroupedBydistri,
                    cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
                    cupsGroupedByATREnergy: cupsGroupedByATREnergy,
                    cupsGroupedByComer: cupsGroupedByComer,
                    cupsGroupedByATR: cupsGroupedByATR,
                    cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
                    EnergiaTotalATRCont: EnergiaTotalATRCont,
                    cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
                    cupsGroupedByProvincia: cupsGroupedByProvincia,
                    tarifaATR: resultadoHash,
                    tarifaATRDescripcion: resultadoHashDescripcion,
                    periodos: periodos

                });
                this.setState(
                    ({ periodosCupStringTemp }) => ({
                        periodosCupStringTemp: periodosCupStringTemp
                    }), () => {
                        this.setState(({ periodosCupStringTemp }) => ({
                            periodosCupStringTemp: periodosCupStringTemp
                        }))
                    })
            } else if (state.selectedCount === 0) {

                this.setState({
                    sumOfEnergy: 0,
                    sumOfEnergyLast: 0,
                    cupsGroupedBydistri: [],
                    cupsGroupedBydistriEnergy: [],
                    cupsGroupedByATREnergy: [{ Id: "SIN DATOS", TotalConsumoAnual: 0 },],
                    cupsGroupedByComer: [],
                    cupsGroupedByATR: [],
                    cupsGroupedByComercialEnergy: [],
                    EnergiaTotalATRCont: [],
                    cupsGroupedByComercialEnergyCont: [],
                    cupsGroupedByProvincia: [],
                    tarifaATR: "",
                    tarifaATRDescripcion: "",
                    periodos: []
                });


            } else {

                console.log('Entra en warning Alert ---> ', this.state)
                console.log('VALOR DE state.selectedCount ', state.selectedCount)
                console.log('VALOR DE STATE --->', state)

                this.warningAlert();

                this.setState({
                    sumOfEnergy: sumOfEnergy,
                    sumOfEnergyLast: sumOfEnergyLast,
                    cupsGroupedBydistri: cupsGroupedBydistri,
                    cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
                    cupsGroupedByATREnergy: cupsGroupedByATREnergy,
                    cupsGroupedByComer: cupsGroupedByComer,
                    cupsGroupedByATR: cupsGroupedByATR,
                    cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
                    EnergiaTotalATRCont: EnergiaTotalATRCont,
                    cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
                    cupsGroupedByProvincia: cupsGroupedByProvincia,
                    tarifaATR: resultadoHash,
                    tarifaATRDescripcion: resultadoHashDescripcion,
                    periodos: periodos,
                    selectProductButtonDisabled: true


                });


                //this.setState({selectProductButtonDisabled:true})

                //this.forceUpdate()
            }
        } else {

                console.log('Entra en vete a saber ---> ', this.state)
                    console.log('VALOR DE state.selectedCount ', state.selectedCount)

            this.setState({
                sumOfEnergy: 0,
                sumOfEnergyLast: 0,
                cupsGroupedBydistri: [],
                cupsGroupedBydistriEnergy: [],
                cupsGroupedByATREnergy: [{ Id: "SIN DATOS", TotalConsumoAnual: 0 },],
                cupsGroupedByComer: [],
                cupsGroupedByATR: [],
                cupsGroupedByComercialEnergy: [],
                EnergiaTotalATRCont: [],
                cupsGroupedByComercialEnergyCont: [],
                cupsGroupedByProvincia: [],
                tarifaATR: "",
                tarifaATRDescripcion: "",
                periodos: []
            });

        }
    };


    fetchOfertas = async () => {

        var periodosCupsPotencia = ''
        var periodosCupString = String(this.props.periods);

        if (['001', '004', '005', '006', '007', '008', '018'].indexOf(this.state.selectedRows[0].codigoTarifaATREnVigor) >= 0) {
            periodosCupString = '3';
            periodosCupsPotencia = '2';
        } else if (this.state.selectedRows[0].codigoTarifaATREnVigor === '003' || this.state.selectedRows[0].codigoTarifaATREnVigor === '019') {
            periodosCupString = '4';
            periodosCupsPotencia = '6';
        } else {
            periodosCupString = '6';
            periodosCupsPotencia = '6';
        }

        const companyUser = await JSON.parse(await localStorage.getItem("user")!);
        const companyIdUser = companyUser.companyId;

        var data: any[] = [];
        await this.props.client.query({
            query: LIST_COMERCIAL_PRODUCTOS,
            variables: { companyId: companyIdUser, periodos: periodosCupString, periodosPotencia: periodosCupsPotencia },
        })
            .then((result) => {
                data = result.data.comercialProductos;
                //  console.log('VALORES DE LA CONSULTA EN PRINCIPAL -------------------> ', data)

                this.setState({ comercialProductos: data })
            });


        let productosPrecios: any[] = [];

        for (let i = 0; i < data.length; i++) {

            console.log('ESTAMOS DENTRO DEL IF PARA AÑADIR COSTES OPERATIVOS ----> ', this.state)

            productosPrecios.push({ producto: data[i].nombreOferta, precioPotenciaP1: Number(data[i].precioTP1), precioPotenciaP2: Number(data[i].precioTP2), precioPotenciaP3: Number(data[i].precioTP3), precioPotenciaP4: Number(data[i].precioTP4), precioPotenciaP5: Number(data[i].precioTP5), precioPotenciaP6: Number(data[i].precioTP6), precioEnergiaP1: Number(data[i].precioTEP1), precioEnergiaP2: Number(data[i].precioTEP2), precioEnergiaP3: Number(data[i].precioTEP3), precioEnergiaP4: Number(data[i].precioTEP4), precioEnergiaP5: Number(data[i].precioTEP5), precioEnergiaP6: Number(data[i].precioTEP6) });
        }

        this.setState({
            productosPrecios: productosPrecios
        });
    }

    render() {

        console.log('VALOR DE THIS.STATE en render()')
        console.log(this.state)
        console.log('VALOR DE THIS en render()')
        console.log(this)

        const contratoSeleccion = this.state.selectedRows.length || 0

        const showList = !this.state.showList ? { display: "none" } : {};


        let noResults = <></>;
        if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
            if (this.state.isSearching) {
                noResults = (
                    <p className="alert alert-danger">No se encontraron resultados</p>
                );
            } else {
                noResults = (
                    <p className="alert alert-info">
                        Realice su busqueda utilizando el formulario de arriba
          </p>
                );
            }
        }

        if (Object.keys(this.state.paginatedData.items[0]).length > 2) {
            const data2 = this.state.paginatedData.items
        } else {
            const data2: any[] = [];
        }

        const contATR = this.state.EnergiaTotalATRCont
        const contComer = this.state.cupsGroupedByComercialEnergyCont
        const showComercialMultiProducto = !this.state.productoMulti.active
            ? { display: "none" }
            : {};

        let freeModal = <></>;

        if (this.state.openModalMultiComparative == true) {
            freeModal = (
                <SipComparativeFreeMultiModal
                    sip={this.state.selectedRows}
                    itemsSelected={this.state.itemsSelected}
                    isOpen={this.state.openModalMultiComparative}
                    toggleMulti={this.toggleMulti}
                    periods={this.state.periodos}
                    tarifaATR={this.state.tarifaATR}
                    tarifaATRDescripcion={this.state.tarifaATRDescripcion}
                    generateComparative={this.openComparativeMultiSection}
                ></SipComparativeFreeMultiModal>
            );

        }

        if (this.state.electricidad) {
            return (
                <>
                    <Header title="PROPUESTAS MULTIPUNTO" />

                    <Container className=" mt--7" fluid>

                        <div className="card-group">
                            <SipsFilter onSubmit={this.onSubmit}></SipsFilter>


                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Consumo Total
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {(this.state.sumOfEnergy / 1000).toLocaleString('es-ES', { maximumFractionDigits: 2 })}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-lightning-charge-fill text-yellow fa-3x" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap">Mw Año</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total Contratos
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {contratoSeleccion}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-files fa-3x" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos selecionados</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                        </div>




                        {this.state.alert}

                        <div className="row">
                            <div className="col-md-6 col-xl-6">
                                <div className="card-stats card">
                                    <div className="card-body">
                                        <div className="row">
                                            <DataTable
                                                title="DATOS GENERALES CONTRATOS"
                                                columns={columnsDatosR1}
                                                data={contATR}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                                                fixedHeader={true}
                                                keyField="cups"
                                                highlightOnHover
                                                striped
                                                pointerOnHover

                                                customStyles={customStyles}

                                                noDataComponent={"Sin datos para mostrar"}


                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-6">
                                <div className="card-stats card">
                                    <div className="card-body">
                                        <div className="row">

                                            <DataTable
                                                title="DATOS GENERALES COMERCIALIZADORA"
                                                columns={columnsDatosR2}
                                                data={contComer}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                                                fixedHeader={true}

                                                keyField="cups"
                                                highlightOnHover
                                                striped
                                                pointerOnHover

                                                customStyles={customStyles}
                                                noDataComponent={"Sin datos para mostrar"}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row style={showList}>
                            <div className="col">
                                <Card className="shadow">
                                    <LoadingOverlay
                                        active={this.state.isLoading}
                                        spinner
                                        text="Cargando sips..."
                                    >
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col md="4">
                                                    <h2 className="mb-0">PUNTOS DE SUMINISTRO</h2>
                                                </Col>
                                                <Col md="8" className="text-right">
                                                    <Button
                                                        disabled={this.state.selectProductButtonDisabled}
                                                        onClick={this.openModalMultiComparativa}

                                                        id="elegirProtooltip" color="primary"
                                                        size="sm" type="button" className="btn-icon btn-sm btn-circle"
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-file-earmark-check" style={{ fontSize: 20 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Producto</span>


                                                        <UncontrolledTooltip delay={0} placement="top" target="elegirProtooltip">
                                                            ELEGIR PRODUCTO
                </UncontrolledTooltip>
                                                    </Button>

                                                    <Button id="buttonXselect" color="warning" size="sm" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={this.handleClearRows}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-x-square" style={{ fontSize: 20 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Deselec.</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="buttonXselect">
                                                            DESELECCIONAR CUPS
          </UncontrolledTooltip>

                                                    </Button>


                                                </Col>
                                            </Row>
                                        </CardHeader>

                                        <DataTable
                                            columns={columns}
                                            data={this.state.paginatedDataTemp.items}
                                            theme="solarized"
                                            selectableRows
                                            selectableRowsComponent={<Checkbox/>} // Pass the function only
                                            selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                                            fixedHeader={true}
                                            onSelectedRowsChange={this.handleChange}
                                            keyField="cups"
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            contextMessage={{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                                            noDataComponent={"Sin datos para mostrar"}
                                            clearSelectedRows={this.state.toggledClearRowsPS}


                                        />
                                        <CardFooter className="py-4">
                                            {noResults}

                                            <nav aria-label="...">
                                                <Pagination
                                                    {...this.state}
                                                    onPageClick={this.onPageClick}
                                                ></Pagination>
                                            </nav>
                                        </CardFooter>

                                    </LoadingOverlay>
                                </Card>
                            </div>

                        </Row>

                        <div className="card-deck" style={showComercialMultiProducto}>

                            <Card className="col bg-light d-flex justify-content-end">
                                <CardBody>

                                    <FormGroup>

                                        <Button id="gpsProptooltip" color="primary" size="sm" type="button" className="btn-icon btn-sm btn-circle"
                                            onClick={this.clearInvoices}
                                        >
                                            <span className="btn-inner--icon">
                                                <i className="bi bi-arrow-return-left" style={{ fontSize: 20 }}></i>
                                            </span>
                                            <span className="btn-inner--text">Regresar</span>

                                        </Button>
                                    </FormGroup>

                                </CardBody>
                            </Card>

                            <Card className="col bg-light d-flex justify-content-end" style={showComercialMultiProducto}>
                                <CardBody>


                                    <Row>

                                        <Col>
                                            <Button id="gpsProptooltip" color="primary" size="sm" type="button" className="btn-icon btn-sm btn-circle"
                                                disabled={this.state.isDisabled}
                                                onClick={this.openModalMultiSave}
                                            >
                                                <span className="btn-inner--icon">
                                                    <i className="bi bi-save" style={{ fontSize: 20 }}></i>
                                                </span>
                                                <span className="btn-inner--text">Guardar</span>

                                            </Button>

                                        </Col>
                                    </Row>




                                </CardBody>
                            </Card>

                        </div>

                        <Row className="card-deck" style={showComercialMultiProducto}>
                            <ComercialMultiProducto
                                sip={this.state.selectedRows}
                                ubi={this.state.ubicacion}
                                productoMulti={this.state.productoMulti}
                                periods={this.state.periodos}
                                itemsConsumo={this.state.selectedRows}
                                output={this.comparativeDataHandler}
                                tarifaATR={this.state.tarifaATR}
                                tarifaATRDescripcion={this.state.tarifaATRDescripcion}
                            >
                            </ComercialMultiProducto>
                        </Row>

                    </Container>
                    {freeModal}
                    <SaveModalMultips
                        isOpen={this.state.openModalMultiComparativeSave}
                        toggle={this.toggleMultiSave}
                        onSave={this.onSave}
                        sip={this.state}
                        ubi={this.state.titular.data} //titular
                        nombrePropuesta={""}
                        companyId={this.state.companyIdUser}
                        userId={this.state.userId}
                    ></SaveModalMultips>


                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }

    }
}

export default withApollo(MultipsNew);
